import { LabelCheckbox } from '@/components/inputs/labelCheckbox';
import { styled } from '@mui/material';

export const StatisticDistributionOfInstallationByConctrorFiltersWrapper = styled('div')(
  ({ isMobile }: { isMobile: boolean }) => ({
    padding: '0 0 0 5vw',
    paddingBottom: 30,
    position: 'relative',
    display: 'flex',
    flexWrap: isMobile ? 'wrap' : 'nowrap',
    justifyContent: isMobile ? 'center' : 'flex-start',
    alignItems: isMobile ? 'center' : 'flex-end',
    gap: 16,
    marginRight: isMobile ? 10 : 50,
  })
);

export const StatisticDistributionOfInstallationByConctrorCheckBoxWrapper = styled('div')({
  disaplay: 'flex',
  flexWrap: 'wrap',
});

export const StatisticDistributionOfInstallationByConctrorBarWrapper = styled('div')({
  display: 'grid',
  gap: 15,
  width: '95%',
  margin: 'auto',
  overflowX: 'auto',
  marginTop: 15,
  paddingBottom: 10,
  height: 600,
});

export const MonthBarWrapper = styled('div')({
  minWidth: '100%',
  marginBottom: 15,
});
export const LabelCheckboxStyled = styled(LabelCheckbox)({
  marginRight: 20,
});
