import { PhonebookDataResponseDto } from '@/services/PhonebookService/dto/PhonebookServiceResponseDto';
import { CircularProgress, Collapse, IconButton, TextField } from '@mui/material';
import React, { useEffect, useState } from 'react';
import EditRoundedIcon from '@mui/icons-material/EditRounded';
import SaveRoundedIcon from '@mui/icons-material/SaveRounded';
import {
  PhonebookIconButtonWrapper,
  PhonebookIconsRow,
  PhonebookRowContainer,
  PhonebookRowWrapper,
} from './Phonebook.styled';
import { Controller, FormProvider, useForm } from 'react-hook-form';
import { PhonebookCreateForm } from './PhonebookCreate';
import { REQUIRED_TEXT } from './utils';
import CloseIcon from '@mui/icons-material/Close';
import { formatPhoneNumberMask } from '@/utils/heplers';
import { usePhonebookStore } from './store';
import { addSuccessActionLog, fetchCatch } from '@/hooks/ActionLogHook';
import { ApiUpdatePhonebookData } from '@/services/PhonebookService/PhonebookService';

export type PhonebookRowProps = PhonebookDataResponseDto;

const PhonebookRow = ({ id, additional, name, telNumber }: PhonebookRowProps) => {
  const methods = useForm<PhonebookCreateForm>();
  const { handleSubmit, reset, setValue, register, formState, control } = methods;
  const [editMode, setEditMode] = useState(false);
  const [hover, setHover] = useState(false);
  const [loading, setLoading] = useState(false);

  const setSelectedPhonebookItem = usePhonebookStore((state) => state.setSelectedPhonebookItem);
  const updatePhonebookDataItem = usePhonebookStore((state) => state.updatePhonebookDataItem);

  const handleButtonClick = () => {
    setEditMode(!editMode);
  };

  const onSubmit = (data: PhonebookCreateForm) => {
    if (editMode) {
      setLoading(true);

      ApiUpdatePhonebookData(data, id)
        .then(({ data }) => {
          addSuccessActionLog('Успешное обновление контакта');
          updatePhonebookDataItem(data, id);
          setEditMode(false);
        })
        .catch(fetchCatch)
        .finally(() => setLoading(false));

      return;
    }
    setEditMode(true);
  };

  useEffect(() => {
    if (editMode) {
      setHover(false);
      reset();
      name && setValue('name', name);
      telNumber && setValue('telNumber', telNumber);
      setValue('additional', additional);
    }
  }, [editMode]);

  const handleClick = () => {
    if (!hover) return;
    setSelectedPhonebookItem({
      id,
      name,
      telNumber,
      additional,
    });
  };

  return (
    <FormProvider {...methods}>
      <PhonebookRowContainer loading={loading}>
        <PhonebookRowWrapper
          onMouseEnter={() => {
            if (!editMode) setHover(true);
          }}
          onMouseLeave={() => setHover(false)}
          hover={hover}
          onClick={handleClick}
        >
          <div style={{ width: '30%', flexShrink: 0, wordBreak: 'break-word' }}>
            {editMode ? (
              <TextField
                {...register('name', { required: REQUIRED_TEXT })}
                variant='standard'
                placeholder='ФИО'
                sx={{ width: '80%' }}
                helperText={formState.errors.name?.message}
                error={!!formState.errors.name}
              />
            ) : (
              <div style={{ paddingTop: 4 }}>{name}</div>
            )}
          </div>
          <div style={{ width: '15%', flexShrink: 0, wordBreak: 'break-word' }}>
            {editMode ? (
              <Controller
                name='telNumber'
                control={control}
                rules={{ required: REQUIRED_TEXT }}
                render={({ field: { onChange, value }, fieldState }) => (
                  <TextField
                    onChange={(e) => onChange(formatPhoneNumberMask(e.target.value))}
                    value={value}
                    sx={{ width: '80%' }}
                    placeholder='Телефон'
                    variant='standard'
                    error={!!fieldState.error}
                    helperText={fieldState.error?.message}
                  />
                )}
              />
            ) : (
              <div style={{ paddingTop: 4 }}>{telNumber}</div>
            )}
          </div>
          <div style={{ wordBreak: 'break-word', whiteSpace: 'pre-wrap', flexGrow: 1 }}>
            {editMode ? (
              <TextField
                {...register('additional')}
                variant='standard'
                multiline
                maxRows={4}
                placeholder='Дополнительная информация'
                sx={{ width: '100%' }}
              />
            ) : (
              <div style={{ paddingTop: 4 }}>{additional}</div>
            )}
          </div>
          <PhonebookIconsRow>
            <PhonebookIconButtonWrapper onClick={(e) => e.stopPropagation()}>
              {loading ? (
                <CircularProgress size={15} />
              ) : (
                <IconButton
                  onClick={handleSubmit(onSubmit)}
                  color='primary'
                >
                  {editMode ? <SaveRoundedIcon /> : <EditRoundedIcon />}
                </IconButton>
              )}
            </PhonebookIconButtonWrapper>
            <Collapse
              in={editMode}
              orientation='horizontal'
            >
              <IconButton onClick={handleButtonClick}>
                <CloseIcon />
              </IconButton>
            </Collapse>
          </PhonebookIconsRow>
        </PhonebookRowWrapper>
      </PhonebookRowContainer>
    </FormProvider>
  );
};

export default React.memo(PhonebookRow);
