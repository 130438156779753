import { color } from '@/styles/mixins';
import { Box, styled } from '@mui/material';

export const TaskCreationResultWrapper = styled('div')({
  padding: '25px 0 25px 25px',
  display: 'flex',
  flexDirection: 'column',
  gap: 16,
  flexGrow: 1,
});

export const TaskCreationResultsHeader = styled('h3')({
  fontSize: '20px',
  fontWeight: '500',
});

export const TaskCreationResultItemWrapper = styled('div')({
  display: 'flex',
  gap: 16,
  paddingRight: 25,
  alignItems: 'flex-end',
});

export const TaskCreationResultItem = styled('div')({
  overflow: 'hidden',
  textOverflow: 'ellipsis',
  whiteSpace: 'nowrap',
  fontSize: 18,
  fontWeight: 500,
  width: 200,
});

export const TaskCreationResultItemAnchor = styled('span')({
  cursor: 'pointer',
  color: color('muiBlue'),
});

export const TaskCreationResultButtonsWrapper = styled(Box)({
  display: 'flex',
  flexDirection: 'row',
  gap: 14,
  marginTop: '30px',
});
