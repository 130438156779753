import { TableRow } from '@mui/material';
import { getMaxMonth } from '@/components/statistics/CustomerInteractionStatistics/CallCenterStatistics/utils';
import {
  CallCenterStatisticsTableCell,
  CELL_DEFAULT_STYLES,
} from './CallCenterStatisticsTable.styled';
import { color } from '@/styles/mixins';
import { CallCenterStatisticsDto } from '@/services/StatisticsService/CustomerInteractionStatisticsService/dto/CustomerInteractionStatisticsServiceResponseDto';
import { CallCenterStatisticsFilter } from '@/services/StatisticsService/CustomerInteractionStatisticsService/dto/CustomerInteractionStatisticsServiceRequestDto';

const HEADER_CELL_STYLE = { top: 45 };
const FIRST_HEADER_CLASS = 'firstHeaderRegularCell';
const SECOND_HEADER_CLASS = 'secondHeaderRegularCell';

export const getFirstHeaderLine = (data: CallCenterStatisticsDto[]) => {
  const headers = ['', '', ''];

  const maxMonth = getMaxMonth(data);
  for (let i = 0; i < maxMonth; i++) {
    if (maxMonth >= 3 && i === 2) {
      headers.push('3 или более месяцев');
      break;
    }
    headers.push(`${i + 1}-й месяц`);
  }

  headers.push('');
  headers.push('');
  headers.push(''); // дабавляем третью пустую ячейку для первого заголовка

  return headers.map((header, index) => (
    <CallCenterStatisticsTableCell
      key={`first-header-${index}`}
      colSpan={index > 2 && index < headers.length - 3 ? 3 : 1} //учитывем 3 новые колонки в конце
      align='center'
      className={FIRST_HEADER_CLASS}
    >
      {header}
    </CallCenterStatisticsTableCell>
  ));
};

export const getSecondHeaderLine = (data: CallCenterStatisticsDto[], isMobile = false) => {
  const headers = ['', 'Кол-во звонков', 'Кол-во заявок'];

  for (let i = 0; i < getMaxMonth(data); i++) {
    headers.push('ПУ');
    headers.push('ТКО');
    headers.push('Карта обзвона');
  }

  headers.push('Кол-во необработанных заявок');
  headers.push('Кол-во потенциальных замен');
  headers.push('Кол-во звонков после установки'); // дабавляем третью колонку в конце

  return headers.map((header, index) => (
    <CallCenterStatisticsTableCell
      onClick={() => console.log(header, 'header')}
      style={HEADER_CELL_STYLE}
      key={`second-header-${index}`}
      align='center'
      className={
        isMobile
          ? ''
          : index > 2 && index < headers.length - 2
          ? SECOND_HEADER_CLASS
          : FIRST_HEADER_CLASS
      }
    >
      {header}
    </CallCenterStatisticsTableCell>
  ));
};

export const getDataLines = ({
  data,
  showOnlyTotals,
  setFilter,
  setIsReadyToDownload,
  filter,
  isMobile,
}: {
  data: CallCenterStatisticsDto[];
  showOnlyTotals: boolean;
  setFilter?: (filter: CallCenterStatisticsFilter) => void;
  setIsReadyToDownload?: (flag: boolean) => void;
  filter?: CallCenterStatisticsFilter;
  isMobile?: boolean;
}) => {
  const filteredData = showOnlyTotals
    ? data.filter((item) => item.itemType === 'TOTAL' || item.itemType === 'MONTH_TOTAL')
    : data;

  return filteredData.map((dataItem) => {
    const isTotalRow = dataItem?.itemType === 'TOTAL' || dataItem?.itemType === 'MONTH_TOTAL';
    const rowData = [
      dataItem.name,
      dataItem.callsCount + '',
      dataItem.tasksCount + '',
      ...Array.from({ length: getMaxMonth(data) }, (_, monthIndex) => {
        const item = dataItem.items[monthIndex] || {
          meterChangeCount: 0,
          tkoCount: 0,
          installationCallLogCount: 0,
        };
        return [item.meterChangeCount + '', item.tkoCount + '', item.installationCallLogCount + ''];
      }).flat(),
      dataItem.pendingTasksCount + '',
      dataItem.potentialReplacementsCount + '',
      dataItem.callsCountAfterMeterChange + '',
    ];

    return (
      <TableRow key={`data-row-${dataItem.name}`}>
        {rowData.map((cellData, index) => (
          <CallCenterStatisticsTableCell
            onClick={() => {
              if (index === rowData.length - 2 && !!dataItem?.pendingTaskIds?.length) {
                setIsReadyToDownload && setIsReadyToDownload(true);
                let upadateFilter = { ...filter };
                if (dataItem.name !== 'Итого') {
                  const { startOn, endOn } = getStartAndEndOfDay(dataItem.name);
                  upadateFilter = {
                    ...filter,
                    dateRange: {
                      startOn,
                      endOn,
                    },
                  };
                }

                setFilter &&
                  setFilter({ ...upadateFilter, pendingTaskIds: dataItem?.pendingTaskIds });
              }
            }}
            key={`data-cell-${dataItem.name}-${index}`}
            align='center'
            className={
              isMobile
                ? ''
                : index > 2 && index < rowData.length - 3 && !!dataItem?.pendingTaskIds?.length //для стилей
                ? SECOND_HEADER_CLASS
                : FIRST_HEADER_CLASS
            }
            style={{
              ...CELL_DEFAULT_STYLES,
              backgroundColor: isTotalRow ? color('lightGreen') : 'inherit',
              cursor:
                index === rowData.length - 2 && !!dataItem?.pendingTaskIds.length ? 'pointer' : '',
              color:
                index === rowData.length - 2 && !!dataItem?.pendingTaskIds.length
                  ? color('blue')
                  : 'inherit',
            }}
          >
            {cellData}
          </CallCenterStatisticsTableCell>
        ))}
      </TableRow>
    );
  });
};

enum Months {
  'Январь' = 0,
  'Февраль' = 1,
  'Март' = 2,
  'Апрель' = 3,
  'Май' = 4,
  'Июнь' = 5,
  'Июль' = 6,
  'Август' = 7,
  'Сентябрь' = 8,
  'Октябрь' = 9,
  'Ноябрь' = 10,
  'Декабрь' = 11,
}

type DateString = string;
type MonthString = keyof typeof Months;

interface DateRange {
  startOn: string;
  endOn: string;
}

export function getStartAndEndOfDay(dateString: DateString | MonthString): DateRange {
  // Создаем объект Date, если строка имеет формат "YYYY-MM-DD"
  if (/^\d{4}-\d{2}-\d{2}$/.test(dateString)) {
    const date = new Date(dateString);

    const startOn = new Date(date.setUTCHours(0, 0, 0, 0)).toISOString();
    const endOn = new Date(date.setUTCHours(23, 59, 59, 999)).toISOString();

    return {
      startOn,
      endOn,
    };
  } else {
    // Обрабатываем строки, представляющие месяц
    const monthName = dateString.split(' ')[0] as MonthString;
    const year = new Date().getFullYear(); // Вы можете изменить год, если это необходимо
    const monthIndex = Months[monthName];

    if (monthIndex !== undefined) {
      const startOfMonth = new Date(Date.UTC(year, monthIndex, 1));
      const endOfMonth = new Date(Date.UTC(year, monthIndex + 1, 0, 23, 59, 59, 999));

      return {
        startOn: startOfMonth.toISOString(),
        endOn: endOfMonth.toISOString(),
      };
    } else {
      throw new Error('Invalid date or month string');
    }
  }
}
