import { createStore } from '@/utils/createStore';
import { ManagementCompanyResponseDto } from './api/dto/ManagementCompanyResponseDto';

export interface IOdpuManagementCompanyStore {
  companiesData: ManagementCompanyResponseDto[];
  modalOpen: boolean;
  selectedCompany: ManagementCompanyResponseDto | null;
  loadingCompaniesData: boolean;
  updatingCompanyData: boolean;
  page: number;
  rowsPerPage: number;
  count: number;
}
export interface IOdpuManagementCompanyFunc {
  setCompaniesData: (companiesData: ManagementCompanyResponseDto[]) => void;
  closeModal: () => void;
  openModal: () => void;
  setSelectedCompany: (company: ManagementCompanyResponseDto | null) => void;
  editCompany: (company: ManagementCompanyResponseDto) => void;
  setLoadingCompaniesData: (loadingCompaniesData: boolean) => void;
  setUpdatingCompanyData: (updatingCompanyData: boolean) => void;
  setPage: (page: number) => void;
  setRowsPerPage: (rowsPerPage: number) => void;
  setCount: (count: number) => void;
  updateCompanyData(companyId: number, newData: ManagementCompanyResponseDto): void;
  addNewCompany: (company: ManagementCompanyResponseDto) => void;
}

export const useOdpuManagementCompanyStore = createStore<
  IOdpuManagementCompanyStore & IOdpuManagementCompanyFunc
>(
  (set, get) => ({
    companiesData: [],
    selectedCompany: null,
    modalOpen: false,
    loadingCompaniesData: false,
    updatingCompanyData: false,
    page: 0,
    rowsPerPage: 5,
    count: 0,
    setCompaniesData: (companiesData) => set({ companiesData }),
    closeModal: () => set({ modalOpen: false, selectedCompany: null }),
    openModal: () => set({ modalOpen: true }),
    setSelectedCompany: (company) => set({ selectedCompany: company }),
    editCompany: (company) => set({ selectedCompany: company, modalOpen: true }),
    setUpdatingCompanyData: (updatingCompanyData) => set({ updatingCompanyData }),

    setLoadingCompaniesData: (loadingCompaniesData) => set({ loadingCompaniesData }),

    setPage: (page) => set({ page }),
    setRowsPerPage: (rowsPerPage) => set({ rowsPerPage }),
    setCount: (count) => set({ count }),

    updateCompanyData: (companyId, newData) => {
      const { companiesData } = get();
      const updatedCompanies = companiesData.map((company) => {
        if (company.id === companyId) {
          return newData;
        }
        return company;
      });
      set({ companiesData: updatedCompanies });
    },

    addNewCompany: (company) => {
      const { companiesData } = get();
      set({ companiesData: [company, ...companiesData] });
    },
  }),

  'OdpuManagementCompany'
);
