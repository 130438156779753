import { useState, useEffect } from 'react';
import ButtonsOdpu from './ButtonsOdpu';
import { IComplaintItem } from '../types';
import { useCatalog } from '@/hooks/CatalogHook';
import { Grid, TextField } from '@mui/material';
import { AutocompleteValue } from '@/components/inputs/AutocompleteValue/AutocompleteValue';
import ButtonsEnergy from './ButtonsEnergy';
import { ComplaintStage } from '@/services/ComplaintService/ComplaintServiceDto';
import { useComplaintsEditAccess } from '../roles';
import { isTypeEnergyService, isTypeODPU } from '../utils';

const CommonTextFields = ({ selectedCompliant }: { selectedCompliant: IComplaintItem }) => {
  const hasAccessToEdit = useComplaintsEditAccess();
  const [executorId, setExecutorId] = useState('');
  const handleExecutorId = (id: string) => {
    setExecutorId(id);
  };

  const [responsibleId, setResponsibleId] = useState(
    () => selectedCompliant.complaint.responsibleId
  );

  const [response, setResponse] = useState(selectedCompliant.complaint?.response ?? '');
  const [error, setError] = useState(false);

  const { getGroupCatalog } = useCatalog((state) => ({
    getGroupCatalog: state.getGroupCatalog,
  }));

  const handleResponsible = (id: string) => {
    setResponsibleId(id);
  };

  const isReturnedODPU =
    selectedCompliant?.complaint.stage.name === ComplaintStage.RETURNED ||
    (selectedCompliant?.complaint.stage.name === ComplaintStage.DUPLICATED &&
      isTypeODPU(selectedCompliant?.complaint?.type));

  useEffect(() => {
    if (selectedCompliant.complaint?.response) {
      setResponse(selectedCompliant.complaint?.response);
    } else {
      setResponse('');
    }
  }, [selectedCompliant.complaint?.response]);

  return (
    <>
      {isTypeEnergyService(selectedCompliant?.complaint?.type) && (
        <Grid
          item
          xs={12}
        >
          <TextField
            fullWidth
            label='Текст ответа на жалобу'
            multiline
            rows={2}
            variant='standard'
            value={response}
            onChange={(e) => setResponse(e.target.value)}
            disabled={!hasAccessToEdit}
            // error={!!fieldState.error}
            // helperText={fieldState.error?.message}
          />
        </Grid>
      )}
      {!isReturnedODPU && (
        <Grid
          item
          xs={12}
          container
          sx={{ gap: '10px' }}
        >
          <Grid
            item
            xs={isTypeEnergyService(selectedCompliant?.complaint?.type) ? 5.8 : 12}
          >
            <AutocompleteValue
              sx={{ width: '99%' }}
              title='Ответственный подрядчик'
              required={
                // selectedCompliant.complaint.stage.name === ComplaintStage.MODERATED_AFTER_CC ||
                // selectedCompliant.complaint.stage.name === ComplaintStage.MODERATED ||
                error
              }
              fieldName={'responsibleId'}
              defaultValue={responsibleId}
              values={getGroupCatalog()}
              optionOnChangeValue={handleResponsible}
              disabled={!hasAccessToEdit}
            />
          </Grid>
          {isTypeODPU(selectedCompliant?.complaint?.type) && (
            <Grid
              item
              xs={12}
            >
              <AutocompleteValue
                sx={{ width: '99%' }}
                title='Исполнитель'
                fieldName={'executorId'}
                defaultValue={executorId}
                values={getGroupCatalog()}
                optionOnChangeValue={handleExecutorId}
              />
            </Grid>
          )}
        </Grid>
      )}

      {isTypeEnergyService(selectedCompliant?.complaint?.type) && (
        <ButtonsEnergy
          responsibleId={responsibleId}
          response={response}
          workType={selectedCompliant?.workType?.name}
          setError={setError}
        />
      )}
      {isTypeODPU(selectedCompliant?.complaint?.type) &&
        selectedCompliant?.complaint.stage.name !== ComplaintStage.DUPLICATED &&
        selectedCompliant?.complaint.stage.name !== ComplaintStage.COMPLETED && (
          <ButtonsOdpu
            responsibleId={responsibleId}
            executor={executorId}
            setError={setError}
          />
        )}
    </>
  );
};

export default CommonTextFields;
