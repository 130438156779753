import { styled, Button } from '@mui/material';

export const MeterChangeForRevisionFiltersWrapper = styled('div')(
  ({ isMobile }: { isMobile: boolean }) => ({
    margin: '5px 0',
    padding: 5,
    position: 'relative',
    display: 'flex',
    flexWrap: isMobile ? 'wrap' : 'nowrap',
    justifyContent: isMobile ? 'center' : 'flex-start',
    alignItems: isMobile ? 'center' : 'flex-end',
    gap: 10,
  })
);

export const MeterChangeForRevisionButton = styled(Button)({
  minWidth: 211,
});
