import React, { useEffect, useMemo, useState } from 'react';
import {
  Grid,
  Stack,
  TextField,
  Typography,
  Skeleton,
  Button,
  Autocomplete,
  IconButton,
} from '@mui/material';
import { color } from '@/styles/mixins';

import { useComplaintStore } from '../store';
import shallow from 'zustand/shallow';

import { Controller, useForm } from 'react-hook-form';
import { DateRange } from '@/components/inputs/DateRange';
import ClearRoundedIcon from '@mui/icons-material/ClearRounded';
import { format } from 'date-fns';
import { UpdateComplaintActRequest } from '@/services/ComplaintService/ComplaintServiceDto';
import { useComplaintValidationAct } from '../utils';
import { EActStatus } from '../types';
import { DateRangeWrapper } from './ComplaintActivation.styled';
import { useComplaintActStore } from './store';

const ComplaintActivationComponent = ({
  handleClickActivation,
}: {
  handleClickActivation: () => void;
}) => {
  interface ComplaintActivationFormData {
    status?: { value: string; label: string } | null;
    classification?: { value: string; label: string } | null;
    registerDate?: number | null;
    ksDate?: number | null;
    text?: string;
    statusReason?: { value: string; label: string } | null;
    paymentRequestNumber?: string;
  }
  const [registerDate, setRegisterDate] = useState<number[] | null | string | string[] | number>(
    null
  );
  const [ksDate, setKsDate] = useState<number[] | null | string | string[] | number>(null);
  const { selectedCompliant } = useComplaintStore();

  const {
    compalintAct,
    compalintActStatuses,
    compalintActStatusReasons,
    compalintActClassifications,
    isActLoading,
    allowActStatuses,
    updateAct,
  } = useComplaintActStore(
    (state) => ({
      compalintActStatuses: state.compalintActStatuses,
      compalintAct: state.compalintAct,
      compalintActClassifications: state.compalintActClassifications,
      compalintActStatusReasons: state.compalintActStatusReasons,
      isActLoading: state.isActLoading,
      allowActStatuses: state.allowActStatuses,
      updateAct: state.updateAct,
    }),
    shallow
  );

  const actStatusReasonsOptions = useMemo(() => {
    return compalintActStatusReasons.map((el) => ({ value: el.id, label: el.title }));
  }, [compalintActStatusReasons]);
  const actClassificationsOptions = useMemo(() => {
    return compalintActClassifications.map((el) => ({ value: el.id, label: el.title }));
  }, [compalintActClassifications]);

  const {
    handleSubmit,
    control,
    watch,
    setValue,
    formState: { errors },
  } = useForm();

  const selectedStatus = watch('status');
  const isClosedByKS = selectedStatus?.value === EActStatus.CLOSED_BY_KS;
  const isNoClosure = selectedStatus?.value === EActStatus.NO_CLOSURE;
  const validationRules = useComplaintValidationAct(selectedStatus);

  const onSubmit = (data: ComplaintActivationFormData) => {
    const formattedData: UpdateComplaintActRequest = {
      status: data?.status?.value,
      ksDate: data.ksDate ? format(new Date(data.ksDate), 'yyyy-MM-dd') : null,
      paymentRequestNumber: data.paymentRequestNumber,
      registerDate: data.registerDate ? format(new Date(data.registerDate), 'yyyy-MM-dd') : null,
      classificationId: data.classification ? +data.classification.value : null,
      statusReasonId: data.statusReason ? +data.statusReason.value : null,
    };
    if (compalintAct && selectedCompliant?.complaint.base.id) {
      updateAct(selectedCompliant?.complaint.base.id, formattedData);
    }
  };
  useEffect(() => {
    if (compalintAct) {
      setValue('ksDate', compalintAct?.ksDate);
      setValue('registerDate', compalintAct?.registerDate);
      setValue(
        'classification',
        actClassificationsOptions.find((option) => option.value === compalintAct.classification?.id)
      );
      setValue(
        'statusReason',
        actStatusReasonsOptions.find((option) => option.value === compalintAct.statusReason?.id)
      );
      setValue(
        'paymentRequestNumber',
        compalintAct?.paymentRequestNumber ? String(compalintAct.paymentRequestNumber) : ''
      );
      setValue(
        'status',
        compalintActStatuses.find((option) => option.value === compalintAct.status)
      );
      compalintAct?.registerDate && setRegisterDate(compalintAct.registerDate);
      compalintAct?.ksDate && setKsDate(compalintAct.ksDate);
    }
  }, [compalintAct]);

  const onHandleRegisterDate = (newValue: number[] | null) => {
    if (newValue && newValue[0]) {
      setRegisterDate(newValue[0]);
      setValue('registerDate', newValue[0]);
    }
  };
  const onHandleksDate = (newValue: number[] | null) => {
    if (newValue && newValue[0]) {
      setKsDate(newValue[0]);
      setValue('ksDate', newValue[0]);
    }
  };
  if (isActLoading) {
    return (
      <Stack
        spacing={2}
        p={2}
      >
        <Skeleton
          variant='text'
          width='40%'
          height={30}
        />
        <Skeleton
          variant='rectangular'
          width='100%'
          height={40}
        />
      </Stack>
    );
  }

  return (
    <Stack
      component='form'
      onSubmit={handleSubmit(onSubmit)}
    >
      <Stack
        flexDirection='row'
        justifyContent='space-between'
        alignItems='baseline'
      >
        <Typography
          variant='subtitle1'
          color={color('trueBlack')}
        >
          Актирование
        </Typography>
        <IconButton onClick={handleClickActivation}>
          <ClearRoundedIcon />
        </IconButton>
      </Stack>

      <Stack
        mt={1}
        spacing={1}
      >
        <Grid
          container
          spacing={1}
        >
          {/* Поле статуса */}
          <Grid
            item
            xs={6}
          >
            <Controller
              name='status'
              control={control}
              rules={{ required: validationRules.status.required }}
              defaultValue={null}
              render={({ field }) => (
                <Autocomplete
                  {...field}
                  options={allowActStatuses}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      label='Статус актирования'
                      variant='standard'
                      error={!!errors.status}
                      helperText={errors.status?.message}
                    />
                  )}
                  onChange={(_, value) => field.onChange(value)}
                />
              )}
            />
          </Grid>

          {/* Поле классификации (отображается для CLOSED_BY_KS) */}
          {isClosedByKS && (
            <Grid
              item
              xs={6}
            >
              <Controller
                name='classification'
                control={control}
                rules={{ required: validationRules.classification.required }}
                render={({ field }) => (
                  <Autocomplete
                    {...field}
                    options={actClassificationsOptions}
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        label='Классификация'
                        variant='standard'
                        error={!!errors.classification}
                        helperText={errors.classification?.message}
                      />
                    )}
                    onChange={(_, value) => field.onChange(value)}
                  />
                )}
              />
            </Grid>
          )}

          {/* Поле причины статуса (отображается для NO_CLOSURE) */}
          {isNoClosure && (
            <Grid
              item
              xs={6}
            >
              <Controller
                name='statusReason'
                control={control}
                rules={{ required: validationRules.statusReason.required }}
                render={({ field }) => (
                  <Autocomplete
                    {...field}
                    options={actStatusReasonsOptions}
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        label='Причина статуса'
                        variant='standard'
                        error={!!errors.statusReason}
                        helperText={errors.statusReason?.message}
                      />
                    )}
                    onChange={(_, value) => field.onChange(value)}
                  />
                )}
              />
            </Grid>
          )}

          {/* Поля дат для CLOSED_BY_KS */}
          {isClosedByKS && (
            <>
              <Grid
                item
                xs={6}
                mt={1}
              >
                <Controller
                  name='registerDate'
                  control={control}
                  rules={{ required: validationRules.registerDate.required }}
                  render={({ field, fieldState }) => (
                    <DateRangeWrapper>
                      <DateRange
                        id='registerDate'
                        label='Дата реестра'
                        onChange={onHandleRegisterDate}
                        value={[registerDate]}
                        argValue={2}
                        showSelect={false}
                        showCalenderIcon={true}
                        style={{
                          width: '100%',
                          borderBottomWidth: '1px',
                          borderBottomColor: fieldState.error ? color('muiError') : '',
                        }}
                        showClearButton={false}
                        labelstyle={{ color: fieldState.error ? color('muiError') : '' }}
                        errorText={fieldState.error?.message}
                      />
                    </DateRangeWrapper>
                  )}
                />
              </Grid>
              <Grid
                item
                xs={6}
                mt={1}
              >
                <Controller
                  name='ksDate'
                  control={control}
                  rules={{ required: validationRules.ksDate.required }}
                  render={({ field, fieldState }) => (
                    <DateRangeWrapper>
                      <DateRange
                        id='ksDate'
                        label='Дата КС'
                        onChange={onHandleksDate}
                        value={[ksDate]}
                        argValue={2}
                        showSelect={false}
                        showCalenderIcon={true}
                        style={{
                          width: '100%',
                          borderBottomWidth: '1px',
                          borderBottomColor: fieldState.error ? color('muiError') : '',
                        }}
                        showClearButton={false}
                        labelstyle={{ color: fieldState.error ? color('muiError') : '' }}
                        errorText={fieldState.error?.message}
                      />
                    </DateRangeWrapper>
                  )}
                />
              </Grid>
            </>
          )}

          {/* Текстовое поле (только для CLOSED_BY_KS) */}
          {isClosedByKS && (
            <Grid
              item
              xs={12}
            >
              <Controller
                name='paymentRequestNumber'
                control={control}
                rules={{ required: validationRules.text.required }}
                render={({ field, fieldState }) => (
                  <TextField
                    {...field}
                    fullWidth
                    label='Номер заявки на оплату'
                    variant='standard'
                    error={!!fieldState.error}
                    helperText={fieldState.error?.message}
                  />
                )}
              />
            </Grid>
          )}
        </Grid>

        <Grid alignSelf='end'>
          <Button
            type='submit'
            disabled={isActLoading}
            variant='outlined'
          >
            Сохранить
          </Button>
        </Grid>
      </Stack>
    </Stack>
  );
};

export default ComplaintActivationComponent;
