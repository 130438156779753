import { TaskFilterParam } from '@/dto/taskmap/Dto';
import { ApiResponse } from '@/types/api';
import { HOST, YodaRestClient } from '@/services/YodaAuthService';
import {
  AsumbTaskStatisticsType,
  GetASUMBTaskStatisticsForDateResponse,
} from './dto/AsumbStatisticServiceResponseDto';

export async function GetASUMBTaskStatisticsByFilter(
  filter: TaskFilterParam
): ApiResponse<AsumbTaskStatisticsType[]> {
  const url = HOST + `/rest/statistics/asumb/task`;
  return YodaRestClient.post(url, filter);
}

export async function GetASUMBTaskStatisticsForDate(
  dateFrom: string,
  dateTo: string
): ApiResponse<GetASUMBTaskStatisticsForDateResponse> {
  const url =
    HOST + `/rest/statistics/asumb/date` + '?dateFrom=' + dateFrom + '&' + 'dateTo=' + dateTo;
  return YodaRestClient.get(url);
}
