import React, { useEffect, useMemo } from 'react';
import { Grid, Paper } from '@mui/material';
import { FormProvider, useForm } from 'react-hook-form';
import { useActionLog } from '@/hooks/ActionLogHook';
import { DateRange } from '@/components/inputs/DateRange';
import LoadSpinner from '@/components/ui/LoadSpinner/LoadSpinner';
import SaveAltIcon from '@mui/icons-material/SaveAlt';
import { useCatalog } from '@/hooks/CatalogHook';
import { MultiSelect } from '@/components/inputs/MultiSelectNew';
import { getDataFromFilter } from '../../inputs/MultiSelectNew/utils';
import FilterButtons from '@/components/filter/FilterButtons/FilterButtons';
import { taskMapToArray } from '../../features/tasksBrowse/utils';
import moment from 'moment';
import { FilterStatisticDistribution, DEFAULT_FILTER, aggregatedDataNew } from './utils';
import { StatisticDistributionDto } from '@/dto/taskmap/Dto';
import { downLoadExcel } from '@/utils/heplers';
import { DownloadStatisticDistributionExel } from '@/services/YodaRestService/YodaRestService';
import {
  LabelCheckboxStyled,
  StatisticDistributionOfInstallationByConctrorCheckBoxWrapper,
  StatisticDistributionOfInstallationByConctrorFiltersWrapper,
} from './StatisticDistributionOfInstallationByConctror.styled';
import { color } from '@/styles/mixins';

import MonthChart from './MonthChart';
import { MainText } from '../StatisticMain.styled';
import { useMobileSize } from '@/hooks/useMediaQuery/useMobileSize';
interface StatisticDistributionOfInstallationByConctrorProps {
  header: string;
  load: boolean;
  data: StatisticDistributionDto[];
  updateFunction: (filter: FilterStatisticDistribution) => void;
}

export const StatisticDistributionOfInstallationByConctror: React.FC<
  StatisticDistributionOfInstallationByConctrorProps
> = ({ header, load, data, updateFunction }) => {
  const methods = useForm();
  const { fetchCatch } = useActionLog();
  const { getCatalogMapWithErr, montagePlaceType } = useCatalog((state) => ({
    getCatalogMapWithErr: state.getCatalogMapWithErr,
    montagePlaceType: state.montagePlaceType,
  }));

  const [montagePlaces, setMontagePlaces] = React.useState([]);

  const [dataInRange, setDateInRange] = React.useState<number[] | Date[]>([
    new Date(Date.now() - 86400000 * 90),
    new Date(),
  ]);
  const { getContractCatalog } = useCatalog((state) => ({
    getContractCatalog: state.getContractCatalog,
  }));
  const contractListResponse = getContractCatalog();
  const [contract, setContract] = React.useState(DEFAULT_FILTER.contractId);
  const [onePhase, setOnePhase] = React.useState(DEFAULT_FILTER.onlyOnePhase);
  const [threePhase, setThreePhase] = React.useState(DEFAULT_FILTER.onlyThreePhase);
  const [loading, setLoading] = React.useState(false);
  const [chartData, setChartData] = React.useState<StatisticDistributionDto[]>([]);
  const [tko, setTko] = React.useState(DEFAULT_FILTER.onlyTko);
  const [filter, setFilter] = React.useState<FilterStatisticDistribution>(DEFAULT_FILTER);

  const isInRange = dataInRange.length !== 2;
  const disableSearch = dataInRange.length < 2 || !contract;
  const disableLoad = !Object.values(contract)?.length;

  const onSubmitSearch = () => updateFunction(filter);

  const onResetClick = () => {
    setContract({});
    setOnePhase(false);
    setTko(false);
    setThreePhase(false);
    setChartData([]);
  };

  useEffect(() => {
    setFilter({
      ...filter,
      contractId: contract?.value,
      dateFrom: moment(dataInRange[0]).format('yyyy-MM-DD'),
      dateTo: moment(dataInRange[1]).format('yyyy-MM-DD'),
      montagePlaceSet: getDataFromFilter(montagePlaces, 'value'),
      onlyOnePhase: onePhase,
      onlyThreePhase: threePhase,
      onlyTko: tko,
    });
  }, [tko, threePhase, onePhase, contract, dataInRange, montagePlaces]);

  useEffect(() => {
    if (data.length) setChartData(data);
  }, [data]);

  const handleLoding = (isLoading: boolean) => setLoading(isLoading);

  const handeLDownLoadExcel = () => {
    if (disableLoad || loading) return;
    downLoadExcel(DownloadStatisticDistributionExel, filter, fetchCatch, handleLoding);
  };

  const montagePlaceOptions = useMemo(
    () => taskMapToArray(getCatalogMapWithErr('montagePlaceType')),
    [montagePlaceType]
  );

  const result = aggregatedDataNew(chartData);

  const length = Object.entries(result)?.length;
  const allDistributionLists = data.flatMap((entry) => entry.distributionList);

  // Поиск максимального replacementCount
  const maxReplacementCount = Math.max(
    ...allDistributionLists.map((item) => (tko ? item.tkoCount : item.replacementCount))
  );

  let lengthCategory = 6;

  if (length === 2) {
    lengthCategory = 6;
  } else if (length >= 3) {
    lengthCategory = 4;
  }
  const isMobile = useMobileSize();
  return (
    <>
      <Paper
        elevation={6}
        className={'footPaper'}
      >
        <MainText className='MainTextStatistc'>{header}</MainText>
        <Grid style={{ marginTop: 15 }}>
          <FormProvider {...methods}>
            <StatisticDistributionOfInstallationByConctrorFiltersWrapper isMobile={isMobile}>
              <MultiSelect
                label={'Контракт'}
                value={contract}
                options={contractListResponse}
                onChange={setContract}
                isOnlySingleValue={true}
                sx={{ minWidth: '300px' }}
              />
              <MultiSelect
                label={'Места установки'}
                value={montagePlaces}
                options={montagePlaceOptions}
                onChange={setMontagePlaces}
                sx={{ minWidth: isMobile ? '300px' : '200px' }}
              />

              <DateRange
                label={'выбрать диапазон'}
                isInRange={isInRange}
                onChange={setDateInRange}
                value={dataInRange}
                argValue={8}
                showSelect={false}
                style={{ minWidth: isMobile ? '300px' : '200px' }}
              />

              <StatisticDistributionOfInstallationByConctrorCheckBoxWrapper>
                <LabelCheckboxStyled
                  value={onePhase}
                  disabled={threePhase}
                  onChange={() => setOnePhase(!onePhase)}
                  label={'1ф'}
                  style={{ paddingBottom: 0, paddingTop: 0 }}
                />
                <LabelCheckboxStyled
                  value={threePhase}
                  disabled={onePhase}
                  onChange={() => setThreePhase(!threePhase)}
                  label={'3ф'}
                  style={{ paddingBottom: 0, paddingTop: 0 }}
                />
                <LabelCheckboxStyled
                  value={tko}
                  onChange={() => setTko(!tko)}
                  label={'ТКО'}
                  style={{ paddingBottom: 0, paddingTop: 0 }}
                />
              </StatisticDistributionOfInstallationByConctrorCheckBoxWrapper>

              <SaveAltIcon
                onClick={handeLDownLoadExcel}
                style={{
                  fontSize: 32,
                  color: disableLoad || loading ? color('trueGray') : color('blue'),
                  cursor: disableLoad || loading ? 'not-allowed' : 'pointer',
                }}
              />
              <FilterButtons
                disable={disableLoad || disableSearch}
                onSearchClick={() => onSubmitSearch()}
                onCloseClick={onResetClick}
              />
            </StatisticDistributionOfInstallationByConctrorFiltersWrapper>
          </FormProvider>
        </Grid>
        {load ? (
          <LoadSpinner />
        ) : (
          <Grid
            container
            spacing={1}
            p={2}
            minHeight={600}
            maxHeight={700}
            overflow={'auto'}
          >
            {Object.entries(result).map(([month, data]) => (
              <MonthChart
                maxX={maxReplacementCount}
                key={month}
                month={month}
                data={data}
                tko={tko}
                xs={isMobile ? 12 : lengthCategory}
              />
            ))}
          </Grid>
        )}
      </Paper>
    </>
  );
};
