import { useMemo } from 'react';

const REQUIRED = (flag = true) => ({
  required: flag ? 'Обязательно для заполнения' : false,
});
export const useExsistingEquipmentValidation = () => {
  return useMemo(() => {
    return {
      puType: { ...REQUIRED() },
      ttType: { ...REQUIRED() },
      puNumber: { ...REQUIRED() },
      puEditionYear: { ...REQUIRED() },
      puStampNumber: { ...REQUIRED() },
      puKkiStamp: { ...REQUIRED() },
      ktt: { ...REQUIRED() },
      ttCheckDate: { ...REQUIRED() },
      ttNextCheckDate: { ...REQUIRED() },
      ttSerial: { ...REQUIRED() },
      ttYear: { ...REQUIRED() },
      ttStamp: { ...REQUIRED() },
      tnKtn: { ...REQUIRED() },
      tnType: { ...REQUIRED() },
      tnNumber: { ...REQUIRED() },
      tnYear: { ...REQUIRED() },
    };
  }, []);
};
