import {
  DefaultStatistic,
  DisplayManufacturer,
  ManufacturerStatisticSegment,
  NamedStatisticInvestShowInfo,
  TooltipBarChart,
} from '../../types/types';
import React from 'react';
import { StringBudgetStatisticTooltip } from './StringBudgetStatisticTooltip';
import { TitleGroupShowInfo } from './BudgetStatisticTooltip.styled';

interface BudgetStatisticTooltipProps {
  tooltip: TooltipBarChart;
  tooltipRef: React.RefObject<HTMLDivElement>;
  displayManufacturer?: DisplayManufacturer;
  displayTechDesicion?: boolean;
}

export const BudgetStatisticTooltip = ({
  tooltip,
  tooltipRef,
  displayManufacturer,
  displayTechDesicion,
}: BudgetStatisticTooltipProps) => {
  const tooltipData = Array.isArray(tooltip.tooltipData)
    ? { default: tooltip.tooltipData }
    : tooltip.tooltipData && typeof tooltip.tooltipData === 'object'
    ? tooltip.tooltipData
    : {};

  const lengthTitle = Object.values(tooltipData).reduce((maxLength, group) => {
    if (Array.isArray(group)) {
      const groupMaxLength = Math.max(...group.map((itemValue) => itemValue.title.length), 0);
      return Math.max(maxLength, groupMaxLength);
    }
    return maxLength;
  }, 0);

  return (
    <div
      style={{
        visibility: tooltip.display === 'block' ? 'visible' : 'hidden',
        opacity: tooltip.display === 'block' ? 1 : 0,
        position: 'fixed',
        top: tooltip.top,
        left: tooltip.left,
        transition: 'opacity 0.3s ease',
        pointerEvents: 'none',
        zIndex: 1,
      }}
      ref={tooltipRef}
    >
      {tooltipData && !!Object.values(tooltipData)?.length && (
        <div className='show-info-bar'>
          {Object.entries(tooltipData).map(([key, item], index) => {
            if (Array.isArray(item)) {
              const max = Math.max(...item.map((it) => it.count ?? 0));
              return (
                <>
                  {key === 'techDecision' && <TitleGroupShowInfo>Тех решения</TitleGroupShowInfo>}
                  {item.map((itemValue, idx: number) => {
                    return (
                      <StringBudgetStatisticTooltip
                        key={idx}
                        itemValue={itemValue}
                        max={max}
                        indexTooltipData={index}
                        indexDataItem={idx}
                        lengthTitle={lengthTitle}
                      />
                    );
                  })}
                </>
              );
            } else {
              const max = Math.max(
                ...Object.values(item as NamedStatisticInvestShowInfo)
                  .filter((it): it is DefaultStatistic[] | ManufacturerStatisticSegment[] =>
                    Array.isArray(it)
                  )
                  .map((it: DefaultStatistic[] | ManufacturerStatisticSegment[]) =>
                    'count' in it[0] ? it[0].count : it[0].amount
                  )
              );
              return (
                <>
                  <TitleGroupShowInfo>Производители</TitleGroupShowInfo>
                  {Object.entries(item).map(([key, value], idx) => {
                    const itemArray = value as DefaultStatistic[];
                    return (
                      <StringBudgetStatisticTooltip
                        key={idx}
                        itemValue={itemArray[0]}
                        max={max}
                        indexTooltipData={index}
                        indexDataItem={idx}
                        lengthTitle={lengthTitle}
                      />
                    );
                  })}
                </>
              );
            }
          })}
        </div>
      )}
    </div>
  );
};
