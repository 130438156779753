import React, { useMemo, useState } from 'react';
import { DataGrid, GridCellParams, GridEventListener, ruRU } from '@mui/x-data-grid';
import { MultiSelect } from '@/components/inputs/MultiSelectNew';
import { Button, Grid, Paper, Stack, Typography } from '@mui/material';
import FilterButtons from '@/components/filter/FilterButtons/FilterButtons';
import { FormProvider, useForm } from 'react-hook-form';
import {
  StatisticWithoutActivationFiltersWrapper,
  StatisticWithoutActivationTableWrapper,
} from './StatisticWithoutActivationю.styled';
import { useCatalog } from '@/hooks/CatalogHook';
import { SelectFilterOptType } from '@/components/filter/MultySelectFilter';
import { taskMapToArray } from '@/components/features/tasksBrowse/utils';
import { PERIODS, columns, columnsExtra, useTransformedCatalogData } from './utils';
import DraggablePopup from '@/components/popups/DraggablePopup';
import { downloadExcel } from '../CustomerInteractionStatistics/CallCenterStatistics/utils';
import { fetchCatch } from '@/hooks/ActionLogHook';
import { GetTasksIdsForStatisticActivation } from '@/services/StatisticsService/RequestStatisticService/RequestStatisticService';
import { getDataFromFilter } from '@/components/inputs/MultiSelectNew/utils';
import { generateDetailedInfo } from '@/components/button/DetailedInfo/utils';
import {
  StatisticActivationFilter,
  StatisticWithoutActivationDTO,
  StatisticWithoutActivationFilter,
} from '@/services/StatisticsService/RequestStatisticService/dto/RequestStatisticServiceRequestDto';
import { CallCenterStatisticsFilter } from '@/services/StatisticsService/CustomerInteractionStatisticsService/dto/CustomerInteractionStatisticsServiceRequestDto';
import { MainText } from '../StatisticMain.styled';
import { useMobileSize } from '@/hooks/useMediaQuery/useMobileSize';

const StatisticWithoutActivation = ({
  data,
  header,
  updateFunction,
  loading,
}: {
  data: StatisticWithoutActivationDTO[];
  header: string;
  loading: boolean;
  updateFunction: (filter: StatisticWithoutActivationFilter) => void;
}) => {
  const methods = useForm();
  const { handleSubmit } = methods;
  const [selectedContractList, setSelectedContractList] = useState<SelectFilterOptType[]>([]);
  const [selectedResponsables, setSelectedResponsables] = useState<SelectFilterOptType[]>([]);
  const [isReadyToDownload, setIsReadyToDownload] = useState(false);
  const [fetchingTasksIds, setFetchingTaskIds] = useState(false);
  const [isExcelLoading, setIsExcelLoading] = useState(false);
  const [filter, setFilter] = useState<CallCenterStatisticsFilter | null>(null);

  const hasAllFilterParams = selectedContractList.length || selectedResponsables.length;

  const onSubmitSearch = () => {
    if (hasAllFilterParams) {
      const filter: StatisticWithoutActivationFilter = {
        contractIds: getDataFromFilter(selectedContractList, 'value'),
        contractorIds: getDataFromFilter(selectedResponsables, 'value'),
        periods: PERIODS,
      };
      updateFunction(filter);
    }
  };
  const { getContractCatalog, getGroupCatalog, taskGroupMap } = useCatalog((state) => ({
    getContractCatalog: state.getContractCatalog,
    getGroupCatalog: state.getGroupCatalog,
    taskGroupMap: state.taskGroupMap,
  }));

  const contractListResponse = getContractCatalog();
  const responsables = useMemo(() => taskMapToArray(getGroupCatalog()), [taskGroupMap]);

  const mappedData = useTransformedCatalogData(data);

  const handleCellClick: GridEventListener<'cellClick'> = (params: GridCellParams): void => {
    const { field, row, value } = params;
    console.log(params);

    if (!value || field === 'contractId') return;
    const [daysFromStr, daysToStr] = field.split('-');
    setFetchingTaskIds(true);
    setIsReadyToDownload(true);
    const daysFrom = Number(daysFromStr);
    const daysTo: number | null = daysToStr === 'null' ? null : Number(daysToStr);
    const resultObject: StatisticActivationFilter = {
      daysFrom,
      daysTo,
      contractId: row.id.split('/')[0],
      contractorIds: getDataFromFilter(selectedResponsables, 'value').length
        ? getDataFromFilter(selectedResponsables, 'value')
        : null,
      type: row.id.split('/')[1],
    };
    GetTasksIdsForStatisticActivation(resultObject)
      .then(({ data }) => {
        setFilter({ pendingTaskIds: data });
      })
      .catch(fetchCatch)
      .finally(() => setFetchingTaskIds(false));
  };
  const onResetClick = () => {
    setSelectedContractList([]);
    setSelectedResponsables([]);
  };
  const detailedInfo = generateDetailedInfo(
    [selectedContractList, 'Контракт'],
    [selectedResponsables, 'Ответственный']
  );

  const isMobile = useMobileSize();

  return (
    <>
      <Paper elevation={6}>
        <DraggablePopup
          maxWidth='sm'
          isOpen={isReadyToDownload}
          onClose={() => setIsReadyToDownload(false)}
        >
          <Stack alignItems={'center'}>
            <Typography variant='h6'>
              {fetchingTasksIds
                ? 'Идет загрузка ids... '
                : 'Вы точно хотите выгрузить заявки в Эксель?'}
            </Typography>
            <Stack
              flexDirection={'row'}
              mt={2}
            >
              <Button
                disabled={isExcelLoading || fetchingTasksIds}
                onClick={() =>
                  downloadExcel({
                    filter,
                    fetchCatch,
                    setIsExcelLoading,
                    setIsReadyToDownload,
                  })
                }
              >
                да
              </Button>
              <Button onClick={() => setIsReadyToDownload(false)}>нет</Button>
            </Stack>
          </Stack>
        </DraggablePopup>
        <MainText className='MainTextStatistc'>{header}</MainText>

        <Grid>
          <FormProvider {...methods}>
            <form onSubmit={handleSubmit(onSubmitSearch)}>
              <StatisticWithoutActivationFiltersWrapper isMobile={isMobile}>
                <MultiSelect
                  label={'Контракт'}
                  value={selectedContractList}
                  onlyInListArgument
                  options={contractListResponse ?? []}
                  onChange={setSelectedContractList}
                  withoutBullAnswerReset
                  sx={{ minWidth: 200 }}
                />
                <MultiSelect
                  label={'Ответственный'}
                  value={selectedResponsables}
                  onlyInListArgument
                  options={responsables}
                  onChange={setSelectedResponsables}
                  withoutBullAnswerReset
                  sx={{ minWidth: 200 }}
                />
                <FilterButtons
                  disable={!hasAllFilterParams}
                  onSearchClick={() => handleSubmit(onSubmitSearch)}
                  onCloseClick={onResetClick}
                  info={detailedInfo}
                />
              </StatisticWithoutActivationFiltersWrapper>
            </form>
          </FormProvider>
        </Grid>

        <StatisticWithoutActivationTableWrapper>
          <DataGrid
            rows={mappedData}
            columns={columns}
            loading={loading}
            experimentalFeatures={{ columnGrouping: true }}
            columnGroupingModel={columnsExtra}
            localeText={ruRU.components.MuiDataGrid.defaultProps.localeText}
            onCellClick={handleCellClick}
            hideFooterPagination
            disableColumnFilter
            disableSelectionOnClick
          />
        </StatisticWithoutActivationTableWrapper>
      </Paper>
    </>
  );
};

export default StatisticWithoutActivation;
