import { TabProps } from '@mui/material';
import React, { useLayoutEffect } from 'react';
import { useIncomingClaim } from './store';
import shallow from 'zustand/shallow';
import { getIncomingCallCount } from '@/services/CallingMapService/CallingMapService';
import { fetchCatch } from '@/hooks/ActionLogHook';
import CallingMapTab from '@/components/maps/callingMap/components/CallingMapTab/CallingMapTab';

export type CallingMapIncomingTasksTabProps = TabProps;

const CallingMapIncomingTasksTab = (props: CallingMapIncomingTasksTabProps) => {
  const { incomingClaimCount, setIncomingClaimCount, filterIncommingTasks } = useIncomingClaim(
    (state) => ({
      incomingClaimCount: state.incomingClaimCount,
      setIncomingClaimCount: state.setIncomingClaimCount,
      filterIncommingTasks: state.filterIncommingTasks,
    }),
    shallow
  );

  const fetchIncomingClaimCount = () => {
    setIncomingClaimCount('pending');

    getIncomingCallCount(filterIncommingTasks)
      .then(({ data }) => {
        setIncomingClaimCount(data);
      })
      .catch(fetchCatch);
  };

  useLayoutEffect(() => {
    fetchIncomingClaimCount();
  }, []);

  return (
    <CallingMapTab
      {...props}
      label={'Календарь входящих заявок'}
      count={incomingClaimCount}
    />
  );
};

export default CallingMapIncomingTasksTab;
