import { getAllPuWithMacApartments } from '@/components/features/pnr/HouseEntranceEdit/utils';
import { HouseDataDto } from '@/services/HouseDataService/dto/HouseDataServiceResponseDto';

export const getAllEntranceMacedApartmentsCount = (
  floorToFlats: Map<number, Map<number, string[]>>,
  houseData?: HouseDataDto
) => {
  const allMacedPu = getAllPuWithMacApartments(houseData);

  const allEntranceApartments = new Map();
  for (const [entranceNumber, floors] of floorToFlats) {
    const allApartments: string[][] = [];
    for (const floor of floors.values()) {
      if (floor) {
        allApartments.push(floor);
      }
    }
    allEntranceApartments.set(
      entranceNumber,
      allApartments.flat()?.filter((x) => allMacedPu.includes(x))?.length
    );
  }
  return allEntranceApartments;
};
