import { Clusterer, Placemark } from 'react-yandex-maps';
import { TaskPlacemark } from '../../../pnrMap/component/placemark/TaskPlacemark';
import { ZoningTaskPoint } from '@/components/maps/GeoPoint';
import { useSearch } from '../../hook/SearchHook';
import { GeoPoint } from '@/services/ZoningService/dto/ZoningServiceResponseDto';

export const ZoningCluster = () => {
  const { getUniqueAssignableList, zoningBoundResponse, getCount } = useSearch();

  const getCentroid = (geoPoint?: GeoPoint) => {
    return geoPoint ? [geoPoint.longitudeX, geoPoint.latitudeY] : undefined;
  };

  if (getCount() < 1000) {
    return (
      <Clusterer options={{ minClusterSize: 10 }}>
        {zoningBoundResponse &&
          getUniqueAssignableList().map((t) => {
            return (
              <TaskPlacemark
                key={`geo-task-${t.taskId}`}
                geoPoint={new ZoningTaskPoint(t)}
              ></TaskPlacemark>
            );
          })}
      </Clusterer>
    );
  } else {
    return (
      <>
        {zoningBoundResponse?.map((t, item) => {
          return (
            <Placemark
              key={`zoning-geo-cluster-${item}`}
              geometry={getCentroid(t.centroid)}
              properties={{
                balloonPanelMaxMapArea: 0,
                iconContent: t?.geoTaskList?.length,
              }}
              options={{
                preset: 'islands#blueStretchyIcon',
                iconColor: 'blue',
              }}
            />
          );
        })}
      </>
    );
  }
};
