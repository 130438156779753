import React, { useEffect, useMemo, useState } from 'react';
import {
  PnrOrderBoxShaddow,
  PnrOrderDataGrid,
  PnrOrderInputWrapper,
  PnrOrderRow,
  PnrOrderTitle,
} from '../PnrOrderTab.styled';
import FilterButtons from '@/components/filter/FilterButtons/FilterButtons';
import { MultiSelect } from '@/components/inputs/MultiSelectNew';
import { FilterArgEnum, SelectFilterOptType } from '@/components/filter/MultySelectFilter';
import { SingleLine } from '@/components/inputs/SingleLineNew';
import { ruRU } from '@mui/x-data-grid';
import { useHouseEquipmentOs, useNetworkEquipmentOptions } from '../utils';
import { usePNRStore } from '../../store';
import { MKDNetworkElementHouseDto } from '@/services/HouseDataService/dto/HouseDataServiceResponseDto';
import PnrOsOrderModal from './PnrOsOrderModal';
import { v4 as uuid } from 'uuid';
import { updateSelection } from '../../utils';

export interface PnrOrderItem {
  tmpId: string;
  comment?: string | null;
}
export type NetworkElementRow = MKDNetworkElementHouseDto & PnrOrderItem;
enum NetworkElementStatus {
  INSTALLED = 'INSTALLED',
}

const PnrOsOrder = () => {
  const networkEquipmentOptions = useNetworkEquipmentOptions();
  const osColumns = useHouseEquipmentOs();

  const osTasks = usePNRStore((state) => state.houseData?.osTasks);
  const [filteredElements, setFilteredElements] = useState<NetworkElementRow[]>([]);
  const [types, setTypes] = useState<SelectFilterOptType[]>([]);
  const [macImeiText, setMacImeiText] = useState('');
  const [filter, setFilter] = useState<{ types: SelectFilterOptType[]; macImeiText: string }>();
  const [selectedRowsData, setSelectedRowsData] = useState<NetworkElementRow[]>([]);

  const networkElements = useMemo(
    () =>
      osTasks
        ?.map((osTask) => osTask.networkElements)
        ?.flat()
        .filter((el) => !!el && el.status === NetworkElementStatus.INSTALLED)
        .map((el) => ({ ...el, tmpId: uuid() })),
    [osTasks]
  );

  const selectedRowsIds = useMemo(
    () => selectedRowsData.map((row) => row.tmpId),
    [selectedRowsData]
  );

  useEffect(() => {
    if (!networkElements) return;
    if (filter?.types.length || filter?.macImeiText) {
      const filteredElements = networkElements.filter((el) => {
        return (
          (!filter?.types.length || filter?.types.some((type) => el.typeId === type.value)) &&
          (!filter?.macImeiText ||
            (el.smrMac && el.smrMac.includes(filter?.macImeiText)) ||
            (el.smrImei && el.smrImei.includes(filter?.macImeiText)))
        );
      });

      setFilteredElements(filteredElements);
    } else {
      setFilteredElements(networkElements);
    }
  }, [networkElements, filter]);

  const handleSelectionChange = (newSelection: string[]) => {
    const finalRows = updateSelection(newSelection, selectedRowsData, filteredElements, 'tmpId');
    setSelectedRowsData(finalRows);
  };

  const handleType = (value: SelectFilterOptType[]) => setTypes(value);
  const handleSearch = () => setFilter({ types, macImeiText });
  const handleChangeText = (value: string) => setMacImeiText(value);

  const clearSearch = () => {
    setTypes([]);
    setMacImeiText('');
  };

  const rowsWithSelectedRows = [
    ...selectedRowsData,
    ...(filteredElements
      ? filteredElements.filter((el) => !selectedRowsData?.find((f) => el.tmpId === f.tmpId))
      : []),
  ];

  return (
    <PnrOrderBoxShaddow>
      <PnrOrderTitle>ОС на доме</PnrOrderTitle>

      <PnrOrderRow>
        <PnrOrderInputWrapper>
          <MultiSelect
            label='Тип оборудования'
            onChange={handleType}
            argList={[FilterArgEnum.IN_LIST]}
            argValue={FilterArgEnum.IN_LIST}
            value={types}
            options={networkEquipmentOptions}
          />
        </PnrOrderInputWrapper>

        <PnrOrderInputWrapper>
          <SingleLine
            argList={[FilterArgEnum.EQUAL]}
            onChange={handleChangeText}
            label={'MAC/IMEI'}
            value={macImeiText}
            argValue={FilterArgEnum.EQUAL}
          />
        </PnrOrderInputWrapper>

        <div style={{ marginLeft: 'auto' }}>
          <FilterButtons
            onSearchClick={handleSearch}
            onCloseClick={clearSearch}
          />
        </div>
      </PnrOrderRow>

      <PnrOrderDataGrid
        checkboxSelection
        columns={osColumns}
        getRowId={(row) => row.tmpId}
        disableColumnFilter={true}
        localeText={ruRU.components.MuiDataGrid.defaultProps.localeText}
        hideFooter
        disableColumnMenu
        rows={rowsWithSelectedRows}
        selectionModel={selectedRowsIds}
        onSelectionModelChange={(newSelection) => handleSelectionChange(newSelection as string[])}
      />

      <PnrOsOrderModal selectedRowsData={selectedRowsData} />
    </PnrOrderBoxShaddow>
  );
};

export default PnrOsOrder;
