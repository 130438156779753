import { IDuplicateFileResponseItem } from '@/dto/taskmap/task/TaskResultDto';

// Рекурсивная функция поиска всех элементов duplicateFileResponseList
export const findDuplicateList = (data: unknown): IDuplicateFileResponseItem[] => {
  let results: IDuplicateFileResponseItem[] = [];

  if (Array.isArray(data)) {
    data.forEach((item) => {
      results = results.concat(findDuplicateList(item));
    });
  } else if (data !== null && typeof data === 'object') {
    // Проверяем, что data содержит нужное поле и оно является массивом
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    if ('duplicateFileResponseList' in data && Array.isArray(data.duplicateFileResponseList)) {
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore
      results = results.concat(data.duplicateFileResponseList as IDuplicateFileResponseItem[]);
    }
    // Рекурсивно обходим вложенные объекты
    Object.values(data).forEach((value) => {
      results = results.concat(findDuplicateList(value));
    });
  }

  // Фильтруем дубликаты по fileId
  const uniqueResults = results.reduce<IDuplicateFileResponseItem[]>((acc, item) => {
    if (!acc.some((existing) => existing.fileId === item.fileId)) {
      acc.push(item);
    }
    return acc;
  }, []);

  return uniqueResults;
};
