import { MultiSelect } from '@/components/inputs/MultiSelectNew';
import { CircularProgress, Divider, Grid, Stack, Typography } from '@mui/material';
import { DateRange } from '@/components/inputs/DateRange';
import React, { useMemo } from 'react';
import FilterButtons from '@/components/filter/FilterButtons/FilterButtons';
import { useCatalog } from '@/hooks/CatalogHook';
import { taskMapToArray } from '@/components/features/tasksBrowse/utils';
import { generateDetailedInfo } from '@/components/button/DetailedInfo/utils';
import { defaultList } from '@/components/inputs/ArgumentSelectNew';
import { FilterArgEnum } from '@/components/inputs/ArgumentSelectNew/types';
import { useCallingMapState } from '../store';
import CloseFullscreenIcon from '@mui/icons-material/CloseFullscreen';
import shallow from 'zustand/shallow';

import { useSaveFilterButton } from '../../../filter/FilterButtons/utils';
import { SelectFilterOptType } from '@/components/filter/MultySelectFilter';
import moment from 'moment';
import { isNumberInRange } from '../utils/CallingMapUtils';
import { useCallingMapTabCount } from '@/components/maps/callingMap/components/CallingMapTab/store';
const SAVE_FILTER_KEY = 'CallingMap';

const argList = [FilterArgEnum.IN_LIST, FilterArgEnum.NOT_IN_LIST];
const argListEqual = [FilterArgEnum.EQUAL];
const argListOnlyInList = [
  FilterArgEnum.IN_LIST,
  FilterArgEnum.NOT_IN_LIST,
  FilterArgEnum.NOT_EQUAL,
];

export const CallingMapForm = ({ onSubmit, onClose, show, isTableSearch }: any) => {
  const {
    getCatalogMapWithErr,
    taskTypeMap,
    phaseMap,
    montagePlaceCatalog,
    callCenterRequestCatalog,
    callStatusMap,
    installationCallType,
    tkoPeriodDefiner,
  } = useCatalog(
    (state) => ({
      getCatalogMapWithErr: state.getCatalogMapWithErr,
      taskTypeMap: state.taskType,
      phaseMap: state.phase,
      montagePlaceCatalog: state.montagePlaceType,
      callCenterRequestCatalog: state.callCenterRequestType,
      callStatusMap: state.callStatus,
      installationCallType: state.installationCallType,
      tkoPeriodDefiner: state.tkoPeriodDefiner,
    }),
    shallow
  );

  const {
    taskType,
    setMultiValue,
    setMultiValueArg,
    setDateValue,
    setDateValueArg,
    callPeriod,
    requestPeriod,
    installationCallStatus,
    montagePlaceType,
    phase,
    callCenterRequestType,
    geoPoints,
    urgentGeoPoints,
    toggleUrgentGeoPoins,
    setDefaultValue,
    mapZoom,
    taskId,
    phoneNumber,
    tkoPeriod,
    setSingleValue,
    setSingleValueArg,
  } = useCallingMapState(
    (state) => ({
      taskType: state.taskType,
      setMultiValue: state.setMultiValue,
      setMultiValueArg: state.setMultiValueArg,
      setDateValue: state.setDateValue,
      setDateValueArg: state.setDateValueArg,
      setSingleValue: state.setSingleValue,
      setSingleValueArg: state.setSingleValueArg,
      callPeriod: state.callPeriod,
      requestPeriod: state.requestPeriod,
      installationCallStatus: state.installationCallStatus,
      montagePlaceType: state.montagePlaceType,
      phase: state.phase,
      callCenterRequestType: state.callCenterRequestType,
      setDefaultValue: state.setDefaultValue,
      geoPoints: state.geoPoints,
      urgentGeoPoints: state.urgentGeoPoints,
      toggleUrgentGeoPoins: state.toggleUrgentGeoPoins,
      mapZoom: state.mapZoom,
      taskId: state.termTaskId,
      phoneNumber: state.phoneNumber,
      tkoPeriod: state.tkoPeriodDefiner,
    }),
    shallow
  );
  const { mapCount, taskWithoutCoordinatesCount } = useCallingMapTabCount(
    (state) => ({
      mapCount: state.mapCount,
      taskWithoutCoordinatesCount: state.taskWithoutCoordinatesCount,
    }),
    shallow
  );
  const isInRange =
    callPeriod.value.length > 0 &&
    callPeriod.value.length !== 2 &&
    callPeriod.argValue === FilterArgEnum.RANGE;
  const isInRangeRequestPeriod =
    requestPeriod.value.length > 0 &&
    requestPeriod.value.length !== 2 &&
    requestPeriod.argValue === FilterArgEnum.RANGE;

  const taskTypeOptions = useMemo(
    () => taskMapToArray(getCatalogMapWithErr('taskType')),
    [taskTypeMap]
  );
  const callCenterRequestTypeOptions = useMemo(
    () => taskMapToArray(getCatalogMapWithErr('callCenterRequestType')),
    [callCenterRequestCatalog]
  );
  const callStatusOptions = useMemo(
    () => taskMapToArray(getCatalogMapWithErr('callStatus')),
    [callStatusMap]
  );
  const phaseOptions = useMemo(() => taskMapToArray(getCatalogMapWithErr('phase')), [phaseMap]);

  const placeOptions = useMemo(
    () => taskMapToArray(getCatalogMapWithErr('montagePlaceType')),
    [montagePlaceCatalog]
  );
  const installationCallTypeOptions = useMemo(
    () => taskMapToArray(getCatalogMapWithErr('installationCallType')),
    [installationCallType]
  );
  const tkoPeriodDefinerOptions = useMemo(
    () => taskMapToArray(getCatalogMapWithErr('tkoPeriodDefiner')),
    [tkoPeriodDefiner]
  );

  const renderTotal = (togle: boolean) => {
    if (mapCount == 'pending') {
      return (
        <>
          всего найдено заявок:
          <CircularProgress
            size={10}
            style={{ marginBottom: 3, marginLeft: 2 }}
          />
        </>
      );
    }
    if (typeof mapCount == 'number') {
      return `всего найдено заявок: ${mapCount}`;
    }

    return '';
    // if (geoPoints.length || urgentGeoPoints.length) {
    //   return `всего найдено заявок: ${togle ? urgentGeoPoints.length : geoPoints.length}`;
    // } else {
    //   return '';
    // }
  };

  const handleSubmit = () => {
    onSubmit();
  };

  const convertTimeToShowInEye = (time: any) => {
    if (time.value.length) {
      return `${moment(time.value[0]).format('DD.MM.YYYY')} ~ ${moment(time.value[1]).format(
        'DD.MM.YYYY'
      )}`;
    }
    return '';
  };

  const detailedInfo = generateDetailedInfo(
    [callCenterRequestType.value, 'Тип обращения'],
    [taskId.value, 'Номер id'],
    [phoneNumber.value, 'Номер телефона'],
    [convertTimeToShowInEye(callPeriod), 'Период звонка'],
    [convertTimeToShowInEye(requestPeriod), 'Период по дате обрашения'],
    [installationCallStatus.value, 'Результат звонка'],
    [taskType.value, 'Тип заявки'],
    [[montagePlaceType.value] as unknown as SelectFilterOptType[], 'Место установки'],
    [phase.value, 'Фазность'],
    [[tkoPeriod.value] as unknown as SelectFilterOptType[], 'ТКО']
  );

  const { getFilters } = useSaveFilterButton(SAVE_FILTER_KEY);

  const onSelectSavedFilter = (filterKey: string) => {
    const savedFilters = getFilters<SelectFilterOptType[] | any>(filterKey);
    savedFilters.callCenterRequestType &&
      (() => {
        setMultiValue('callCenterRequestType')(savedFilters.callCenterRequestType.value);
        setMultiValueArg('callCenterRequestType')(savedFilters.callCenterRequestType.argValue);
      })();
    savedFilters.installationCallStatus &&
      (() => {
        setMultiValue('installationCallStatus')(savedFilters.installationCallStatus.value);
        setMultiValueArg('installationCallStatus')(savedFilters.installationCallStatus.argValue);
      })();
    savedFilters.taskType &&
      (() => {
        setMultiValue('taskType')(savedFilters.taskType.value);
        setMultiValueArg('taskType')(savedFilters.taskType.argValue);
      })();
    savedFilters.montagePlaceType &&
      (() => {
        setMultiValue('montagePlaceType')(savedFilters.montagePlaceType.value);
        setMultiValueArg('montagePlaceType')(savedFilters.montagePlaceType.argValue);
      })();
    savedFilters.tkoPeriod &&
      (() => {
        setMultiValue('tkoPeriodDefiner')(savedFilters.tkoPeriod.value);
        setMultiValueArg('tkoPeriodDefiner')(savedFilters.tkoPeriod.argValue);
      })();
    savedFilters.phase &&
      (() => {
        setMultiValue('phase')(savedFilters.phase.value);
        setMultiValueArg('phase')(savedFilters.phase.argValue);
      })();
    savedFilters.callPeriod &&
      (() => {
        setDateValueArg('callPeriod')(savedFilters.callPeriod.argValue);
        setDateValue('callPeriod')(savedFilters.callPeriod.value);
      })();
    savedFilters.requestPeriod &&
      (() => {
        setDateValueArg('requestPeriod')(savedFilters.requestPeriod.argValue);
        setDateValue('requestPeriod')(savedFilters.requestPeriod.value);
      })();
  };

  const handleEquals = (fieldArgNum: number) => {
    if (fieldArgNum === FilterArgEnum.EQUAL) return { isOnlySingleValue: true };
    return {};
  };

  const diableField = !!phoneNumber.value || !!taskId.value;

  const { setGeoPoints } = useCallingMapState(
    (state) => ({
      setGeoPoints: state.setGeoPoints,
    }),
    shallow
  );

  return (
    <Stack
      position={'relative'}
      style={{ display: show ? 'block' : 'none' }}
    >
      <Divider />
      <Stack>
        <CloseFullscreenIcon
          onClick={onClose}
          style={{ alignSelf: 'flex-end', cursor: 'pointer' }}
        />
      </Stack>
      <Grid
        container
        xs={12}
      >
        <Grid
          item
          xs={6}
        >
          <MultiSelect
            label={'Тип обращения'}
            argList={argList}
            options={callCenterRequestTypeOptions}
            onChange={setMultiValue('callCenterRequestType')}
            onArgChange={setMultiValueArg('callCenterRequestType')}
            {...callCenterRequestType}
            {...handleEquals(callCenterRequestType.argValue)}
            disabled={diableField}
          />
        </Grid>
        <Grid
          item
          xs={6}
        >
          <DateRange
            isInRange={isInRangeRequestPeriod}
            label={'Период по дате обрашения'}
            onChange={setDateValue('requestPeriod')}
            onArgChange={setDateValueArg('requestPeriod')}
            {...requestPeriod}
            style={{ width: '100%' }}
            isDisable={diableField}
          />
        </Grid>

        <Grid
          item
          xs={6}
        >
          <MultiSelect
            label={'Результат звонка'}
            argList={argList}
            options={installationCallTypeOptions}
            onChange={setMultiValue('installationCallStatus')}
            onArgChange={setMultiValueArg('installationCallStatus')}
            {...installationCallStatus}
            {...handleEquals(installationCallStatus.argValue)}
            disabled={diableField}
          />
        </Grid>
        <Grid
          item
          xs={6}
        >
          <DateRange
            isInRange={isInRange}
            label={'Период звонка'}
            onChange={setDateValue('callPeriod')}
            onArgChange={setDateValueArg('callPeriod')}
            {...callPeriod}
            style={{ width: '100%' }}
            isDisable={diableField}
          />
        </Grid>
        <Grid
          item
          xs={6}
        >
          <MultiSelect
            label={'Место установки'}
            argList={argListOnlyInList}
            options={placeOptions}
            onChange={setMultiValue('montagePlaceType')}
            onArgChange={setMultiValueArg('montagePlaceType')}
            {...montagePlaceType}
            disabled={diableField}
          />
        </Grid>
        <Grid
          item
          xs={6}
        >
          <MultiSelect
            label={'Фазность'}
            argList={argList}
            options={phaseOptions}
            onChange={setMultiValue('phase')}
            onArgChange={setMultiValueArg('phase')}
            {...phase}
            {...handleEquals(phase.argValue)}
            disabled={diableField}
          />
        </Grid>
        <Grid
          item
          xs={6}
        >
          <MultiSelect
            label={'Тип заявки'}
            argList={argList}
            options={taskTypeOptions}
            onChange={setMultiValue('taskType')}
            onArgChange={setMultiValueArg('taskType')}
            {...taskType}
            {...handleEquals(taskType.argValue)}
            disabled={diableField}
          />
        </Grid>
        <Grid
          item
          xs={6}
        >
          <MultiSelect
            label={'Отображение заявок с отказными ТКО'}
            argList={argListEqual}
            options={tkoPeriodDefinerOptions}
            onChange={setMultiValue('tkoPeriodDefiner')}
            onArgChange={setMultiValueArg('tkoPeriodDefiner')}
            {...tkoPeriod}
            disabled={diableField}
            isOnlySingleValue
          />
        </Grid>
        <Grid
          item
          justifyContent={'space-between'}
          display={'flex'}
          alignItems={'center'}
          xs={12}
          mt={2}
          p={1}
        >
          <Typography>{renderTotal(toggleUrgentGeoPoins)}</Typography>
          <FilterButtons
            info={detailedInfo}
            // disable={!isNumberInRange(mapZoom, diableField) && !isTableSearch}
            saveFilter={{
              filterType: SAVE_FILTER_KEY,
              infoToSave: {
                callPeriod,
                requestPeriod,
                installationCallStatus,
                montagePlaceType,
                phase,
                callCenterRequestType,
                taskType,
              },
              selectCallback: onSelectSavedFilter,
            }}
            onSearchClick={handleSubmit}
            onCloseClick={setDefaultValue}
          />
        </Grid>
      </Grid>
    </Stack>
  );
};
