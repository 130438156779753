import { GRID_CHECKBOX_SELECTION_COL_DEF, GridRenderCellParams } from '@mui/x-data-grid';
import { CellWithTooltip } from '../../tasksBrowse/constants';
import { useCatalog } from '@/hooks/CatalogHook';
import { useMemo } from 'react';
import { taskMapToArray } from '../../tasksBrowse/utils';
import { useHouseLevelFloorType } from '../HouseEntranceEdit/utils';
import {
  MKDNetworkElementHouseDto,
  ReformaGkhTaskDto,
} from '@/services/HouseDataService/dto/HouseDataServiceResponseDto';

export const getElementEntrance = (element: MKDNetworkElementHouseDto) => {
  return element?.houseLevel?.entranceNumber ?? '';
};

export const getPuApartment = (pu: ReformaGkhTaskDto) => {
  return pu?.apartment ?? '-';
};

export const getPuNumber = (pu: ReformaGkhTaskDto) => {
  return pu?.number ?? '';
};

export const getPuMac = (pu: ReformaGkhTaskDto) => {
  return pu?.mac ?? '';
};

export const getImeiOrMac = (networkElement: MKDNetworkElementHouseDto) => {
  let result = '-';

  if (networkElement?.smrMac?.length) {
    result = networkElement?.smrMac;
  }
  if (networkElement?.smrImei?.length) {
    result = networkElement?.smrImei;
  }
  return result;
};

const sortMontagePlace = (a: string, b: string) => {
  const getFloorNumber = (value: string) => {
    const match = value.match(/^(\d+)\s+этаж/); // Ищем число перед "этаж"
    return match ? parseInt(match[1], 10) : null;
  };

  const floorA = getFloorNumber(a);
  const floorB = getFloorNumber(b);

  if (floorA !== null && floorB !== null) {
    return floorA - floorB; // Числовая сортировка этажей
  }
  return a.localeCompare(b, 'ru', { numeric: true }); // Обычная строковая сортировка
};

export const useHouseEquipmentOs = () => {
  const { getMontagePlace, floorTypes } = useHouseLevelFloorType();
  const { getCatalogMapWithErr } = useCatalog((state) => ({
    getCatalogMapWithErr: state.getCatalogMapWithErr,
  }));

  return useMemo(
    () => [
      {
        ...GRID_CHECKBOX_SELECTION_COL_DEF,
        hide: true,
      },
      {
        field: 'entrance',
        headerName: 'Подъезд',
        width: 80,
        valueGetter: (params: GridRenderCellParams) => {
          return getElementEntrance(params.row) || null;
        },
        renderCell: (params: GridRenderCellParams) => {
          return <CellWithTooltip params={params} />;
        },
      },
      {
        field: 'montagePlace',
        headerName: 'Место установки',
        width: 150,
        valueGetter: (params: GridRenderCellParams) => {
          return getMontagePlace(params.row) || null;
        },
        renderCell: (params: GridRenderCellParams) => {
          return <CellWithTooltip params={params} />;
        },
        sortComparator: sortMontagePlace,
      },
      {
        field: 'installPlace',
        headerName: 'MAC/IMEI',
        flex: 1,
        valueGetter: (params: GridRenderCellParams) => {
          return getImeiOrMac(params.row) || null;
        },
        renderCell: (params: GridRenderCellParams) => {
          return <CellWithTooltip params={params} />;
        },
      },
      {
        field: 'equipmentType',
        headerName: 'Тип оборудования',
        width: 170,
        valueGetter: (params: GridRenderCellParams) => {
          return getCatalogMapWithErr('networkEquipmentType').get(params.row?.typeId) || null;
        },
        renderCell: (params: GridRenderCellParams) => {
          return <CellWithTooltip params={params} />;
        },
      },
    ],
    [floorTypes]
  );
};

export const useHouseEquipmentPu = () => {
  return useMemo(
    () => [
      {
        ...GRID_CHECKBOX_SELECTION_COL_DEF,
        hide: true,
      },
      {
        field: 'apartment',
        headerName: '№ кв.',
        width: 80,
        valueGetter: (params: GridRenderCellParams) => {
          return getPuApartment(params?.row) || null;
        },
        renderCell: (params: GridRenderCellParams) => {
          return <CellWithTooltip params={params} />;
        },
        sortComparator: (a: string, b: string) => {
          return parseInt(a) - parseInt(b);
        },
      },
      {
        field: 'number',
        headerName: '№ ПУ',
        width: 150,
        valueGetter: (params: GridRenderCellParams) => {
          return getPuNumber(params?.row) || null;
        },
        renderCell: (params: GridRenderCellParams) => {
          return <CellWithTooltip params={params} />;
        },
        sortComparator: (a: string, b: string) => {
          return parseInt(a) - parseInt(b);
        },
      },
      {
        field: 'mac',
        headerName: 'MAC',
        flex: 1,
        valueGetter: (params: GridRenderCellParams) => {
          return getPuMac(params?.row) || null;
        },
        renderCell: (params: GridRenderCellParams) => {
          return <CellWithTooltip params={params} />;
        },
      },
      {
        field: 'meterType',
        headerName: 'Тип оборудования',
        width: 170,
        renderCell: (params: GridRenderCellParams) => {
          return <CellWithTooltip params={params} />;
        },
      },
    ],
    []
  );
};

export const useNetworkEquipmentOptions = () => {
  const getCatalogMapWithErr = useCatalog((state) => state.getCatalogMapWithErr);
  const networkEquipmentType = useCatalog((state) => state.networkEquipmentType);

  const memoizedOptions = useMemo(() => {
    return taskMapToArray(getCatalogMapWithErr('networkEquipmentType'));
  }, [networkEquipmentType]);

  return memoizedOptions;
};
