import React, { useEffect, useRef, useState } from 'react';
import { LinearProgress, Pagination } from '@mui/material';
import { SupplyBillBrowseDto } from '@/dto/taskmap/Dto';
import { useActionLog } from '@/hooks/ActionLogHook';
import SupplyBillBrowseItem from '../SupplyBillBrowseItem/SupplyBillBrowseItem';
import {
  SupplyBillApiGetSupplyBills,
  SupplyBillApiGetSupplyBillsCount,
} from '@/services/SupplyBillService/SupplyBillService';
import {
  SupplyBillBrowseItemWrapper,
  SupplyBillBrowseLinearProgressWrapper,
  SupplyBillBrowsePaginationWrapper,
} from './SupplyBillBrowse.styled';
import { useMutation } from 'react-query';
import { SupplyBillFilterParam, SupplyBillSearchFilter } from '@/dto/SupplyBillServiceDto';
import { PAGE_SIZE, processFilterParams } from './utils';

import SupplyBillBrowseForm from './SupplyBillBrowseForm';
import { useNavigate, useSearchParams } from 'react-router-dom';
import shallow from 'zustand/shallow';
import { useSupplyBillBrowseFilter } from './store';
import { IQureFilter } from '@/components/statistics/StatisticWithDateForRP/types';
import useSupplyStore from '../SupplyStore';
import { SupplyConstant } from '../SupplyConstant';

export const SupplyBillBrowse = () => {
  const { fetchCatch } = useActionLog();
  const navigate = useNavigate();
  const [filterParamsToSend, setFilterParamsToSend] = useState<IQureFilter[]>([]);

  const [result, setResult] = useState<SupplyBillBrowseDto[]>([]);
  const [count, setCount] = useState<number>(0);

  const { page, setPage, releasedWithoutProjectId } = useSupplyBillBrowseFilter(
    (state) => ({
      page: state.page,
      setPage: state.setPage,
      releasedWithoutProjectId: state.releasedWithoutProjectId,
    }),
    shallow
  );

  const { billType } = useSupplyStore(
    (state) => ({
      billType: state.billType,
    }),
    shallow
  );
  const [searchParams, setSearchParams] = useSearchParams();

  const isFirstRender = useRef(true);

  const updateData = (filt?: SupplyBillFilterParam) => {
    const filterValues = processFilterParams(filterParamsToSend, releasedWithoutProjectId);
    const tmpFilter = filt ? filt : filterValues;
    const pageNumber = page - 1;

    mutGetSupplyBillsCount.mutate(tmpFilter);
    mutGetSupplyBills.mutate({ filter: tmpFilter, page: pageNumber, size: PAGE_SIZE });
  };

  const mutGetSupplyBillsCount = useMutation<void, any, SupplyBillFilterParam>((filter) => {
    return SupplyBillApiGetSupplyBillsCount(filter)
      .then(({ data }) => setCount(data))
      .catch((error) => fetchCatch(error, 'Возникла ошибка при загрузке количества накладных'));
  });

  const mutGetSupplyBills = useMutation<void, any, SupplyBillSearchFilter>((filter) => {
    return SupplyBillApiGetSupplyBills(filter)
      .then(({ data }) => setResult(data))
      .catch((error) => fetchCatch(error, 'Возникла ошибка при загрузке накладных'));
  });

  const handleChange = (event: React.ChangeEvent<unknown>, value: number) => setPage(value);

  useEffect(() => {
    if (isFirstRender.current) {
      isFirstRender.current = false;
      return;
    }
    updateData();
  }, [page]);

  const handleDoubleClick = (billId: any, returnBill: boolean) => {
    const navigateUrl = returnBill
      ? `/storehouse/${billId}?returnBill=${returnBill}`
      : `/storehouse/${billId}`;

    navigate(navigateUrl);
  };

  const isReturnBill = (billType: number) => {
    return (
      billType === SupplyConstant.RETURNED_TYPE ||
      billType === SupplyConstant.DRAFT_TYPE_RETURN ||
      billType === SupplyConstant.RETURNED_TYPE_WAITING_APPROVAL_PROJECT ||
      billType === SupplyConstant.RETURNED_TYPE_AGREED_PROJECT
    );
  };

  return (
    <>
      <SupplyBillBrowseForm
        updateData={updateData}
        isFetching={mutGetSupplyBills.isLoading}
        filterParamsToSend={filterParamsToSend}
        setFilterParamsToSend={setFilterParamsToSend}
      />
      <SupplyBillBrowseItemWrapper disabled={mutGetSupplyBills.isLoading}>
        <SupplyBillBrowseLinearProgressWrapper>
          {mutGetSupplyBills.isLoading && <LinearProgress />}
        </SupplyBillBrowseLinearProgressWrapper>
        {result?.map((elem) => (
          <SupplyBillBrowseItem
            key={elem.id}
            onDoubleClick={() => handleDoubleClick(elem.id, isReturnBill(elem.billTypeId))}
            updateData={updateData}
            {...elem}
          />
        ))}
      </SupplyBillBrowseItemWrapper>
      <SupplyBillBrowsePaginationWrapper>
        <Pagination
          count={Math.ceil(count / PAGE_SIZE)}
          shape='rounded'
          color='primary'
          sx={{ margin: '15px' }}
          page={page}
          onChange={handleChange}
          disabled={mutGetSupplyBills.isLoading}
        />
      </SupplyBillBrowsePaginationWrapper>
    </>
  );
};
