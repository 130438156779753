import React from 'react';
import { NavWrapper } from '@/components/widgets/Navbar/NavBar.styled';
import { useMediaQuery } from '@mui/material';
import { NavbarLinks } from './NavbarLinks/NavbarLinks';
import { MobileNavbar } from './MobileNavbar/MobileNavbar';
import NavbarActionButtons from './NavbarActionButtons';
import NavbarLogoWithBolt from './NavbarLogoWithBolt';

export default function Navbar() {
  const isMobile = useMediaQuery(`(max-width:1300px)`);
  return (
    <>
      <NavWrapper>
        <NavbarLogoWithBolt />
        {isMobile ? <MobileNavbar /> : <NavbarLinks />}
        {!isMobile && <NavbarActionButtons />}
      </NavWrapper>
    </>
  );
}
