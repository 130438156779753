import React, { useState } from 'react';
import {
  PnrOrderCommentWrapper,
  PnrOrderRowHeader,
  PnrOrderRowsWrapper,
  PnrOrderRowWrapper,
} from '../PnrOrderTab.styled';
import { NetworkElementRow } from './PnrOsOrder';
import { Button, TextField, Tooltip, Typography } from '@mui/material';
import { PnrPlanningModalIconButton } from '@/components/blocks/PnrPlanningModalSteps/PnrPlanningModalSteps.styled';
import DeleteForeverOutlinedIcon from '@mui/icons-material/DeleteForeverOutlined';

import { TransitionGroup } from 'react-transition-group';
import { Collapse } from '@mui/material';
import { getElementEntrance, getImeiOrMac } from '../utils';
import { useHouseLevelFloorType } from '../../HouseEntranceEdit/utils';
import { useCatalog } from '@/hooks/CatalogHook';
import { Popup } from '@/components/popups';
import {
  SupplyPUCommentModalWrapper,
  SupplyPUCommentModalButtonsWrapper,
} from '@/components/storehouse/SupplyPUComponent/SupplyPUComponent.styled';
import { CommentModal, INITIAL_COMMENT_MODAL } from '../PnrPuOrder/PnrPuOrderRows';
export interface PnrOsOrderRowsProps {
  userEditedElements: NetworkElementRow[];
  setUserEditedElements: React.Dispatch<React.SetStateAction<NetworkElementRow[]>>;
}

const PnrOsOrderRows = ({ userEditedElements, setUserEditedElements }: PnrOsOrderRowsProps) => {
  const { getCatalogMapWithErr } = useCatalog((state) => ({
    getCatalogMapWithErr: state.getCatalogMapWithErr,
  }));
  const { getMontagePlace } = useHouseLevelFloorType();

  const [commentModal, setCommentModal] = useState<CommentModal>(() => INITIAL_COMMENT_MODAL);

  const saveCommentModal = () => {
    setUserEditedElements((prev) =>
      prev.map((el) =>
        el.tmpId === commentModal.rowTpmId ? { ...el, comment: commentModal.text } : el
      )
    );
    closeCommentModal();
  };
  const closeCommentModal = () => setCommentModal(INITIAL_COMMENT_MODAL);
  const setCommentModalText = (text: string) => setCommentModal((prev) => ({ ...prev, text }));
  const openCommentModal = (text: string, rowTpmId: string) => {
    setCommentModal({ open: true, text, rowTpmId });
  };

  const handleDelete = (tmpId: string) => {
    setUserEditedElements((prev) => prev.filter((el) => el.tmpId !== tmpId));
  };

  return (
    <PnrOrderRowsWrapper>
      <div>
        <div style={{ display: 'flex', alignItems: 'center' }}>
          <div style={{ width: 50 }} />
          <PnrOrderRowWrapper>
            <PnrOrderRowHeader>Подъезд</PnrOrderRowHeader>
            <PnrOrderRowHeader>Место установки</PnrOrderRowHeader>
            <PnrOrderRowHeader>МАС/IMEI</PnrOrderRowHeader>
            <PnrOrderRowHeader>Тип оборудования</PnrOrderRowHeader>
            <PnrOrderRowHeader>Комментарий</PnrOrderRowHeader>
          </PnrOrderRowWrapper>
        </div>

        <TransitionGroup>
          {userEditedElements?.map((element) => (
            <Collapse key={element.tmpId}>
              <div style={{ display: 'flex', alignItems: 'center' }}>
                <div style={{ width: 50 }}>
                  <PnrPlanningModalIconButton onClick={() => handleDelete(element.tmpId)}>
                    <DeleteForeverOutlinedIcon />
                  </PnrPlanningModalIconButton>
                </div>

                <PnrOrderRowWrapper>
                  <div style={{ width: 200 }}>{getElementEntrance(element)}</div>
                  <div style={{ width: 200 }}>{getMontagePlace(element)}</div>
                  <div style={{ width: 200 }}>{getImeiOrMac(element)}</div>
                  <div style={{ width: 200 }}>
                    {element?.typeId
                      ? getCatalogMapWithErr('networkEquipmentType').get(element.typeId) ?? '-'
                      : '-'}
                  </div>

                  <Tooltip title={element.comment ?? ''}>
                    <PnrOrderCommentWrapper
                      onClick={() => openCommentModal(element.comment ?? '', element.tmpId)}
                    >
                      <span>{element.comment}</span>
                    </PnrOrderCommentWrapper>
                  </Tooltip>
                </PnrOrderRowWrapper>
              </div>
            </Collapse>
          ))}
        </TransitionGroup>
      </div>

      <Popup
        isOpen={commentModal.open}
        onClose={closeCommentModal}
      >
        <SupplyPUCommentModalWrapper>
          <Typography variant='h6'>Комментарий</Typography>

          <TextField
            variant='standard'
            value={commentModal.text}
            sx={{ width: '75%' }}
            multiline
            autoFocus
            maxRows={6}
            onChange={(event) => setCommentModalText(event.target.value)}
          />

          <SupplyPUCommentModalButtonsWrapper>
            <Button
              onClick={saveCommentModal}
              variant='contained'
            >
              Сохранить
            </Button>

            <Button onClick={closeCommentModal}>Отменить</Button>
          </SupplyPUCommentModalButtonsWrapper>
        </SupplyPUCommentModalWrapper>
      </Popup>
    </PnrOrderRowsWrapper>
  );
};

export default PnrOsOrderRows;
