import { Grid, styled } from '@mui/material';

export const StatisticByInstallPuDataWrapper = styled(Grid)({
  height: '600px',
  width: '89%',
  margin: 'auto',
  overflowY: 'scroll',
  paddingBottom: 60,
  // overflowX: 'auto',
});
export const StatisticByInstallPuDataNoDataWrapper = styled('div')({
  display: 'flex',
  width: '100%',
  height: '100%',
  alignItems: 'center',
  justifyContent: 'center',
});
