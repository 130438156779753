import { GeoTransStation } from '@/components/maps/ppoMap/dto';

export const isChangeInput = new Map<string, string>([
  ['true', 'да'],
  ['false', 'нет'],
]);

// 1- есть smrTaskId нет meterChangeIdList и нет tkoIdList - не проведено
// 2- нет smrTaskId - отсутствует
// 3- smrTaskId и есть tkoIdList - ТКО
// 4- smrTaskId и есть meterChangeIdList - проведено

export const getSMRText = (
  smrTaskId: string | undefined | null,
  meterChangeIdList: string[] | undefined | null,
  tkoIdList: string[] | undefined | null,
  isChangeNeeded: boolean | null
): string => {
  if (!isChangeNeeded) {
    return 'монтаж не планируется';
  }
  if (smrTaskId == null) {
    return 'отсутствует';
  }
  if (tkoIdList && tkoIdList.length > 0) {
    return 'ТКО';
  }
  if (meterChangeIdList && meterChangeIdList.length > 0) {
    return 'проведено';
  }
  return 'не проведено';
};

export const convertStringToBooleanOrNull = (value: string | null): boolean | null | string => {
  if (value === null) return null;
  switch (value) {
    case 'true':
      return true;
    case 'false':
      return false;
    case 'null':
      return null;
    default:
      return value;
  }
};
export const removeDeliveryPoint = (
  geoTransStations: Record<string, GeoTransStation>,
  deliveryPointId: string
) => {
  const updatedStations = { ...geoTransStations };
  const modifiedKeys: string[] = []; // Массив для отслеживания измененных станций

  Object.keys(updatedStations).forEach((key) => {
    const station = updatedStations[key];
    if (station.deliveryPoints) {
      const initialLength = station.deliveryPoints.length;
      station.deliveryPoints = station.deliveryPoints.filter((dp) => dp.id !== deliveryPointId);
      if (station.deliveryPoints.length !== initialLength) {
        modifiedKeys.push(key); // Сохраняем ключ измененной станции
      }
    }
  });

  return {
    newStations: updatedStations,
    modifiedKeys, // Возвращаем ключи измененных станций
  };
};

type Parentable = Record<string, any> | Array<Record<string, any>>;

export const addParentId = <T extends Parentable>(obj: T, parentId: string): T => {
  const processObject = (item: Record<string, any>): void => {
    item.parentId = parentId;

    for (const key in item) {
      if (Object.prototype.hasOwnProperty.call(item, key)) {
        const value = item[key];
        if (Array.isArray(value)) {
          value.forEach((element) => {
            if (typeof element === 'object' && element !== null) {
              processObject(element);
            }
          });
        }
      }
    }
  };

  if (Array.isArray(obj)) {
    obj.forEach((item) => {
      if (typeof item === 'object' && item !== null) {
        processObject(item);
      }
    });
  } else if (typeof obj === 'object' && obj !== null) {
    processObject(obj);
  }

  return obj;
};

export const getPropertyByTargetId = <T extends keyof GeoTransStation>(
  data: Record<string, GeoTransStation>,
  targetId: string,
  searchField: 'deliveryPoints' | 'pillars',
  returnField: T
): GeoTransStation[T] | null => {
  if (!Object.values(data).length) return null;
  for (const key in data) {
    if (Object.prototype.hasOwnProperty.call(data, key)) {
      const item = data[key];
      const itemsToSearch = item[searchField] as Array<{ id: string }> | undefined;
      if (itemsToSearch && itemsToSearch.find((dp) => dp.id === targetId)) {
        return item[returnField];
      }
    }
  }
  return null;
};

export const getFeedersByDeliveryPointId = (
  data: Record<string, GeoTransStation>,
  targetId: string
) => getPropertyByTargetId(data, targetId, 'deliveryPoints', 'feeders');

export const getParentIdInDeliveryPoints = (
  data: Record<string, GeoTransStation>,
  targetId: string
) => getPropertyByTargetId(data, targetId, 'deliveryPoints', 'parentId');

export const getParentIdInPillar = (data: Record<string, GeoTransStation>, targetId: string) =>
  getPropertyByTargetId(data, targetId, 'pillars', 'parentId');
