import { HOST, YodaRestClient } from '@/services/YodaAuthService';
import { ApiResponse } from '@/types/api';
import {
  TransformerAmperageRequestDto,
  TransformerVoltageRequest,
} from './dto/TransformerRequestDto';
import { TransformerAmperage, TransformerVoltageDto } from './dto/TransformerResponseDto';

export async function getTransformerVoltage(id: string): ApiResponse<TransformerVoltageDto> {
  const url = HOST + `/rest/task/${id}/transformer-voltage`;
  return YodaRestClient.get(url);
}

export async function putTransformerVoltage({
  id,
  data,
}: TransformerVoltageRequest): ApiResponse<any> {
  const url = HOST + `/rest/task/${id}/transformer-voltage`;
  return YodaRestClient.put(url, data);
}

export async function getTransformerAmperage(
  taskId: string,
  type: 'task' | 'vru-input'
): ApiResponse<TransformerAmperage> {
  const url = HOST + `/rest/${type}/${taskId}/task-transformer`;
  return YodaRestClient.get(url);
}

export async function putTransformerAmperage(
  taskId: string,
  type: 'task' | 'vru-input',
  data: TransformerAmperageRequestDto
): ApiResponse<any> {
  const url =
    HOST +
    (type == 'task'
      ? `/rest/${type}/${taskId}/transformer-amperage`
      : `/rest/task/${taskId}/transformer-amperage/vru`);
  return YodaRestClient.put(url, data);
}
