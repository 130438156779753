import { NetworkPlanningTypes } from '@/components/blocks/PnrElementsInfo/utils';
import { FilterArgEnum } from '@/components/filter/MultySelectFilter';
import { SupplyPUItemConfirmState } from '@/components/storehouse/SupplyPUComponent/utils';
import { SupplyNetworkElementScanField } from '../SupplyBillServiceDto';
import { IImage } from './task/TaskResultDto';
import { SimChangesResponse } from '@/services/GSMService/dto/GSMServiceResponseDto';
import {
  ExPuInfoResponse,
  PhaseImages,
  Trans,
} from '@/services/TransformerService/dto/TransformerResponseDto';
import { GeoBoundsNew } from '@/services/ZoningService/dto/ZoningServiceResponseDto';
import {
  HouseDataDto,
  HouseLevel,
} from '@/services/HouseDataService/dto/HouseDataServiceResponseDto';
import { CallCenterWorkType } from '@/services/CallCenterService/dto/CallCenterServiceResponseDto';

export type GeoTask = {
  taskId: string;
  number: number;
  customerId: string;
  contractorId: string;
  executorId: string;
  longitudeX: number;
  latitudeY: number;
  status: string;
  type: string;
  zoningGroupId: string;
  reassignable: boolean;
  tpName: string;
  electricNetworksEnterpriseName: string;
  resName: string;
  isAllComplete: boolean;
};

export type TaskElement = {
  number: string | null;
  type: string | null;
  mac: string | null;
  imei: string | null;
};

export type GeoResponse = {
  count: number;
  geoTaskInBoundsList: GeoTaskInBounds[];
};

export type GeoRequest = {
  taskFilterParam?: TaskFilterParam;
  geoBounds?: GeoBounds | GeoBoundsNew;
  additionalParam?: AdditionalParam;
};

export type AdditionalParam = {
  type: string;
  value: string | number;
};

export interface PnrGeoRequest extends GeoRequest {
  pnrTag: string;
  period: number;
}

export type TaskFilterParam = {
  contractIdsArg?: FilterArgEnum;
  contractIds?: string[];
  contractorIdsArg?: FilterArgEnum;
  contractorIds?: string[];
  statusesArg?: FilterArgEnum;
  statuses?: string[];
  typesArg?: FilterArgEnum;
  types?: string[];
  mcStatusesArg?: FilterArgEnum;
  mcStatuses?: string[];
  customerIdArg?: FilterArgEnum;
  customerId?: string;
  accountingNumArg?: FilterArgEnum;
  accountingNum?: string;
  networkResAreaArg?: FilterArgEnum;
  networkResArea?: string;
  taskNumArg?: FilterArgEnum;
  taskNum?: string;
};

export type GeoTaskInBounds = {
  geoBounds: GeoBounds;
  geoCentroid: GeoCentroid;
  geoTaskList: Array<GeoTask>;
  networkResArea: string;
};

export type GeoBounds = {
  minX: number;
  minY: number;
  maxX: number;
  maxY: number;
};

export type GeoCentroid = {
  x: number;
  y: number;
};

export type PnrTransformerStation = {
  functionalLocationId: string;
  dispatcherName: string;
  networkCompanyBranch: string;
  networkCompanyUnit: string;
  latitude: number;
  longitude: number;
};

export enum TaskStatus {
  new = 'Новая',
  in_work = 'В работе',
  assigned = 'Назначенная',
  check = 'На проверке',
  returned = 'Возврат исполнителю',
  completed = 'Закрыта',
  not_relevant = 'Не актуальна',
  disput = 'Disput',
}

export enum SendToAsumbStatus {
  sended = 'АСУМБ принял SENDED',
  sended_to_billing = 'АСУМБ принял SENDED_TO_BILLING',
  send_error = 'Ошибка отправки',
  review = 'Проблемы с монтажом',
  refused = 'Монтаж не нужен',
  assigned = 'Необходимость монтажа подтверждена',
  unready = 'Не завершен',
  reworked = 'Отработана МЭС',
  billing_error = 'АСУМБ прислал ошибки из биллинга',
}

export const PnrTag: any = {
  ZB: 'ПУ в одной ZB сети',
  WHITE_LIST: 'ПУ в одном белом списке',
  PYRAMID_PU: 'ПУ с одним маршрутом в Пирамиде',
  PYRAMID_OO: 'ПУ сегодня в опросе в Пирамиде',
  COORDINATOR: 'ПУ в сети координатора',
};

export const PnrInfoType: any = {
  mac: 'MAC',
  number_pu: 'Номер ПН',
  mac_number_pu: 'MAC и Номер ПУ',
};

export type AttributeItem = {
  clazz: string;
  name: string;
  translate: string;
  parent: string;
};

export const TaskHintAttributeList: any = {
  newPuNumber: 'Номер ПУ',
  newPuType: 'Тип ПУ',
  newPuMac: 'MAC',
  uspdNumber: 'УСПД',
  uspdInfo: 'инфа УСПД',
  lastDatePyramid: 'посл дата Пирамида',
  surRoutePyramid: 'путь Пирамида',
  valuePyramid: 'показание Пирамида',
  numberTaskInfo: 'номер заявки',
  contractorTaskInfo: 'подрядчик',
  dogovorTaskInfo: 'договор',
  abonentNumTaskInfo: 'номер абонента',
  sdtoTaskInfo: 'СДТО???',
  linkType: 'тип связи из enerstroymain_meter_type',
  inPyramid: 'собирает данные в Пирамиде, имхо дублирует',
};

export const TaskNetworkEquipmentHintAttributeList: any = {
  dateLastPing: 'дата последней связи с ОО',
  serialNumber: ' зав. номер ОО',
  vpoVersion: ' версии ВПО',
  imei: 'IMEI модема ОО',
  route: ' IP и порт',
  zbCount: 'Кол-во ПУ за координатором',
  wlCount: 'Кол-во ПУ в белом списке',
};

export type TaskCC = {
  id: string;
  address: string;
  apartment: string;
  accountNumber: string;
  customerName: string;
  phoneNumber: string;
  abonentsList: TaskCCAbonent[];
  taskId: string;
};
export type TaskCCAbonent = {
  id: number;
  contactPhone: string;
  fio: string;
  isActive: boolean;
  taskId: string;
};

export type TaskCCTko = {
  id: string;
  reason: string;
  date: string;
};

export type RequredNetworkElementHouse = {
  id: string;
  entrance?: number;
  type?: string;
  montagePlace?: string;
  count?: number;
  comment?: string;
};

export type NetworkEquipmentTypeDto = {
  id: string;
  title: string;
};

export type HouseOrderDto = {
  taskId: string;
  taskNum: number;
  plannedDate?: Date;
  typeId: string;
  houseLevel?: HouseLevel;
};

export type CreateHouseOrderDto = {
  isMultiple: boolean;
  contractId: string;
  contractorId: string;
  plannedDate: Date;
  plannings: SaveHousePlanningsDto;
  taskType: string;
};

export type SaveHousePlanningsDto = {
  id: string | null;
  comment: string | null;
  equipmentTypeId: string | null;
  entranceNumber?: number | null;
  floorNumber?: number | null;
  floorType?: string | null;
}[];

export type CreateHouseOrderResponseDto = {
  taskId: string;
  taskNum: number;
  plannedDate: Date;
  contractId: string;
  contractorId: string;
}[];

export interface PlannedInstalationsDto {
  comment?: string | null;
  conflictComment: string | null;
  equipmentTypeId: string | null;
  hasConflict: boolean;
  houseLevel: HouseLevel;
  id: string | null;
  type: NetworkPlanningTypes;
  userEdited: boolean;
  entrance?: string;
}

export enum HouseSchemaType {
  DEFAULT = 'DEFAULT',
  TWO_GIS = 'TWO_GIS',
  TWO_GIS_INCORRECT = 'TWO_GIS_INCORRECT',
  CUSTOM = 'CUSTOM',
}
export interface HouseLayoutDto {
  entrances: HouseEntrance[];
  schemaType: HouseSchemaType;
}

export interface TwoGisLayoutResponseDto {
  layout: HouseLayoutDto;
  houseData: HouseDataDto;
}

export interface HouseEntrance {
  number: number;
  floors: HouseFloor[];
}

export interface HouseFloor {
  number: number;
  flats: HouseFlat[];
  isNonResidential?: boolean;
}

export interface HouseFlat {
  number: string;
}

export type HouseDataBrowseDto = {
  houseId: string;
  floorCount?: number;
  entranceCount?: number;
  flatCount?: number;
  address?: string;
  fias?: string;
  odpuManagementCompanyName?: string;
  smrTasksSize?: number;
  addressTableId: string;
  ipuCount?: number;
};

export interface HouseDataOdpuReplacement {
  taskId: string;
  taskNumber: number;
  newMeterNumber: string;
  newMeterMac: string;
  newMeterMeterType: string;
  vruTaskNum: number;
  installPlace: null | string;
}

export type CallCenterRequestTableItem = {
  id: string;
  taskId: string;
  taskNumber: string;
  taskStatus: string;
  sendToAsumbStatus?: SendToAsumbStatus;
  accountNum: string;
  fullName: string;
  address: string;
  planDate: string;
  phoneNumber: string;
  workType: string;
  workTypeId: string;
  appartment: string;
  comment: string;
  executor: string;
  contractorId: string;
  contractorName: string;
  callType: CallCenterWorkType;
  callDate: string;
  contractId: string;
  contractName: string;
  operator: string;
  status: string;
  exPuType: string;
  scheduledDate: string;
};

export type CallCenterScheduledDateDto = {
  requestId: number;
  scheduledOn: string;
  callStatus: string;
  comment: string;
};

export type CCContractDto = {
  id: string;
  title: string;
};

export type GuaranteeBodyDto = {
  oldTaskId: string;
  executorId: string;
  planDate: string;
  comment?: string;
  requestId: string;
  resultJobLog: string;
};

export type TkoStatusDto = {
  id: string;
  title: string;
};

export type TkoToSaveDto = {
  taskId: string;
  tkoStatusId: string;
  description: string;
  requestId: string;
  resultJobLog: string;
};

export type CallCenterWorkTypeForSelect = {
  value: string;
  label: string;
};

export type SupplyStamp = {
  id: number;
  from?: string;
  to?: string;
  count?: number;
  stampLetter?: string;
  stampNumbers?: string[];
  stampItems?: SupplyStampItem[];
  backendItem?: boolean;
  confirmed?: boolean;
};

export interface SupplyStampItem {
  id: number | null;
  stampNumber: string;
}

export type SupplyPU = {
  tmpId: number;
  meterId?: string;
  meterName?: string;
  meterTypeId?: number;
  meterTypeName?: string;
  puNumRegexExpr?: string;
  declaredCount: string;
  puItems: SupplyPUItem[];
  backendItem?: boolean;
  isRim?: boolean;
};

export type SupplyNetworkElement = {
  tmpId: number;
  typeId?: number;
  typeName?: string;
  name?: string;
  typeRegex?: string;
  declaredCount: string;
  addedCount: string;
  networkElementItems: SupplyNetworkElementItem[];
  backendItem?: boolean;
};

export interface SupplyServiceCatalogOption {
  id: number;
  name: string;
}
export type SupplyPUItem = {
  puTmpId?: number;
  id: string | null;
  tmpId: number;
  rowNum: number;
  number?: string | null;
  mac: string | null;
  meterId?: string;
  meterName?: string;
  puNumRegexExpr?: string;
  meterTypeId: number;
  meterTypeName: string;
  parentTypeName?: string;
  backendItem?: boolean;
  savedItem?: boolean;
  fullName?: string;

  taskNumber?: string | number | null;
  completeness?: SupplyServiceCatalogOption | null;
  manufactureYear?: number | null;
  ownerId?: string | null;
  state?: SupplyServiceCatalogOption | null;
  defectCause?: SupplyServiceCatalogOption | null;
  confirmed?: SupplyPUItemConfirmState;
  returnStorehouseAdded?: boolean;

  address?: string | null;
  completenessComment?: string | null;
  generalComment?: string | null;
  supplyComment?: string | null;
};

export type SupplyNetworkElementItem = {
  osTmpId?: number;
  id: number | null;
  rowNum: number;
  imei: string | null;
  mac: string | null;
  name?: string;
  typeRegex?: string;
  typeId: number;
  typeName: string;
  tmpId: number;
  backendItem?: boolean;
  typeScanField?: SupplyNetworkElementScanField;
  savedItem?: boolean;
  confirmed?: SupplyPUItemConfirmState;
  fullName?: string;

  taskNumber?: string | number | null;
  completeness?: SupplyServiceCatalogOption | null;
  manufactureYear?: number | null;
  ownerId?: string | null;
  state?: SupplyServiceCatalogOption | null;
  defectCause?: SupplyServiceCatalogOption | null;
  returnStorehouseAdded?: boolean;
  number?: string;

  address?: string | null;
  completenessComment?: string | null;
  generalComment?: string | null;
  supplyComment?: string | null;
};

export type SupplyMeterType = {
  id: number;
  name: string;
  puNumRegexExpr?: string | null;
  meterTypeScanField: string;
};

export type SupplyNetworkElementType = {
  id: number;
  title: string;
  typeRegex: string;
  typeScanField: SupplyNetworkElementScanField;
};

export type SupplyBill = {
  id?: number;
  contractorId: string;
  billNumber: string;
  billTypeId: number;
  billDate?: string;
  stampList?: SupplyStamp[];
  puList?: SupplyPU[];
  puItemList?: SupplyPU[];
  networkElementList?: SupplyNetworkElement[];
};

export interface BackendFile {
  id: string;
  name: string;
}
export type SupplyBillBrowseDto = {
  id?: string;
  contractorId: string;
  billNumber: string;
  billTypeId: number;
  billTypeName: string;
  billDate: string;
  stampList?: SupplyStamp[];
  puList?: SupplyPU[];
  puItemList?: SupplyPUItem[];
  networkElementList?: SupplyNetworkElement[];
  networkElementItemList?: SupplyNetworkElementItem[];
  fileList?: BackendFile[];
  fileIdList?: string[];
  supplyId?: string;
  relatedBillId?: string;
  m15FormNumber?: string;
  projectId?: string;

  createdAt?: string;
  createdBy?: string;
  updatedAt?: string;
  updatedBy?: string;
  releaseDate?: string;
};

export interface SupplyBillTaskNumberCheckDto {
  number?: number;
  address?: string;
}

export type SupplyBillCreateDto = {
  fileList: File[];
  billRequest: SupplyBillBrowseDto;
};

export type CheckSupplyItemExistsResponse = {
  billNumber?: string;
  id?: string;
};

export type NetworkElementTaskComment = {
  entrance: string;
  type: string;
  montagePlace: string;
  comment: string;
};

export type NetworkElementsTaskWithComment = {
  networkElementTaskComments: NetworkElementTaskComment[];
  houseId: string;
  contractId: string;
  contractorId: string;
  executorId?: string;
  plannedOn: number;
};

export type BindResult = {
  fileName: string;
  mcId: string | null;
};

export type TaskAddressDetail = {
  id: string;
  areaName?: string;
  regionName?: string;
  localityNameMob?: string;
  streetNameMob?: string;
  house?: string;
  korpus?: string;
  stroenie?: string;
  addrobj?: string;
  readOnlyAddress?: string;
  apartment?: string | null;
  cadaster?: string | null;
  districtName?: string;
  addressTableId?: string;
  litera?: string | null;
};

export type PnrAddressDetail = {
  houseAddressInfoId: string;
  areaName?: string;
  regionName?: string;
  localityNameMob?: string;
  streetNameMob?: string;
  house?: string;
  korpus?: string;
  stroenie?: string;
  readOnlyAddress?: string;
  apartment?: string | null;
  districtName?: string;
  fias?: string;
  latitudeY?: string;
  longitudeX?: string;
  cadaster?: string | null;
  addressTableId?: string;
  litera?: string | null;
};

export type DadataResponseDto = {
  id: number;
  address?: string;
  fias?: string;
  responseBody?: string;
  longitudeX?: string;
  latitudeY?: string;
};

export type WarrantyWorkType = {
  mac?: string;
  kkiStamp?: string;
  workDoneAt?: Date;
  measurementStatus?: string | null;
  simStatus?: string | null;
  stampNumber?: string | null;
  puNumber?: string;
  automaticBreakerId?: string | null;
  status: WarantyStatus;
  // t1?: string;
  // t2?: string;
  // t3?: string;
  // tariff?: number;
  measurement?: WarrantyMeasurement;
  oldMeasurement?: WarrantyMeasurement;
  type?: string;
  types: string[];
  typeIds?: string[];
  executor?: string;
  vldMessages?: string[];
  ICCD?: string | null;
  stampStatus?: string | null;
  id: string;
  imageList?: IImage[];
  transferToAccounting?: {
    previousAccountingPuNumber?: string | null;
    previousAccountingPuType?: string | null;
    newAccountingPuNumber?: string | null;
    newAccountingPuType?: string | null;
  } | null;

  simChangeList: SimChangesResponse[] | null;

  longitude?: string | null;
  latitude?: string | null;
  comment?: string | null;
  taskTransformator?: TaskTransformator | null;
};

export type WarrantyMeasurement = {
  id?: string;
  tariff?: string;
  t1?: string;
  t2?: string;
  t3?: string;
};

interface CustomerScheduleDateFilter {
  contractIdList: string[];
  taskTypeList: string[];
  taskPriorityList: string[];
}

export interface FilterStatisticUrgentAndSuperUrgent {
  customerScheduleDateFilter: CustomerScheduleDateFilter;
  customerScheduleDateList: Date[];
}
export interface FilterStatisticReportByContractPerDay {
  taskTypes: string[];
  contractId: string;
  dateFrom: number;
  dateTo: number;
}
export interface StatisticUrgentAndSuperUrgentTasksDto {
  customerScheduleDate: Date;
  priority: string;
  taskUrgentStatisticList: TaskUrgentStatisticListDto[];
}
export interface StatisticReportByContractPerDayDto {
  contractor: string;
  taskPriority: string;
  taskType: string;
  apartmentCount: number;
  ladderCount: number;
  hallCount: number;
  total: number;
}

export interface StatisticUrgentAndSuperUrgentCommonDto {
  installedPercent: number;
  installedQuantity: number;
  remainderPercent: number;
  remainderQuantity: number;
  tkoPercent: number;
  tkoQuantity: number;
  total: number;
}

export interface TaskUrgentStatisticListDto extends StatisticUrgentAndSuperUrgentCommonDto {
  contract: string;
  montagePlaceStatistic: MontagePlaceStatisticListDto[] | null;
}

export interface MontagePlaceStatisticListDto extends StatisticUrgentAndSuperUrgentCommonDto {
  montagePlace: string;
}

export interface DataColTypePu {
  title: string;
  plan: number;
  fact: number;
}

export interface ContactTypePu {
  name: string;
  items: DataColTypePu[];
}
export interface filterStatisticByInstallPu {
  contractIdList: string[];
  contractIdListArg: number;
}

export interface StatisticByInstallPuDto {
  regionName: string;
  firstContract: ContactTypePu;
  secondContract: ContactTypePu;
}

export interface Contractor {
  contractorName: string;
  montagePlace: string;
  phase: number;
  replacementCount: number;
  tkoCount: number;
}

export interface StatisticDistributionDto {
  month: number;
  year: number;
  distributionList: Contractor[];
  totalReplacementCount?: number;
  totalTkoCount?: number;
}
export interface StatisticCallingMapDto {
  count: number;
  date: number;
  status: string | null;
}

export interface TaskSibling {
  id: string;
  number: number;
  taskType: string;
}
export interface Siblings {
  parentTask: TaskSibling | null;
  childTaskList: TaskSibling[] | null;
}
export interface ActDateHistoryResponse {
  ksDateType: string;
  actOn: Date;
  status: string;
  description: string;
  contractor: string;
}
export interface TaskTransformator {
  montageName?: string | null;
  smrDate?: string | null;
  type?: string | null;
  phaseList?: Phase[];
  exPuInfoResponse?: null | ExPuInfoResponse;
  isOnlyPuReplacement?: null | boolean;
  ktt?: string | null;
  mount?: Trans;
  unmount?: Trans;
  transformerImageList: PhaseImages[];

  // ktt: string;
  // montageName: string;
  // smrDate: Date;
  // exPuInfoResponse: ExPuInfoResponse;
  // mount: Trans;
  // unmount: Trans;
  // transformerImageList: PhaseImages[];
}
export interface Phase {
  id?: string | null;
  phase?: string | null;
  newYear?: number | null;
  stamp?: string | null;
  serial?: string | null;
}

export enum WarantyStatus {
  CANCELED = 'CANCELED',
  NOT_VALID = 'NOT_VALID',
  VALID = 'VALID',
}

export enum ExistingEquipmentEnum {
  presentPu = 'presentPu',
  presentTransformer = 'presentTransformer',
  presentTransformerVoltage = 'presentTransformerVoltage',
}
