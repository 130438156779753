import { Stack, Typography } from '@mui/material';
import { TextField } from '@/components/fixUI';
import { Box } from '@mui/system';
import { FC, useEffect } from 'react';
import { useFormContext } from 'react-hook-form';
import { Phase } from '@/services/TransformerService/dto/TransformerResponseDto';

interface VolatagePhaseProps {
  phase: Phase;
  index: number;
  keyString: 'mount' | 'unmount' | 'unexpected';
}

export const VolatagePhase: FC<VolatagePhaseProps> = ({ phase, index, keyString }) => {
  const { register, setValue } = useFormContext();
  useEffect(() => {
    setValue(`${keyString}.phaseList.${index}.id`, phase?.id);
  }, []);

  return (
    <Box sx={{ p: 2 }}>
      {phase && (
        <Stack
          spacing={2}
          direction='row'
          justifyContent='center'
          alignItems='flex-start'
        >
          <Typography variant='inherit'>{phase?.phase}</Typography>
          <TextField
            hidden
            {...register(`${keyString}.phaseList.${index}.phase`)}
          />
          <TextField
            id='tf-number-tt'
            label='Номер ТН'
            variant='standard'
            sx={{ width: '100%' }}
            {...register(`${keyString}.phaseList.${index}.serial`, {
              value: phase?.serial,
            })}
          />
          <TextField
            hidden={keyString === 'unmount'}
            id='tf-stamp-number'
            label='Пломба ТН'
            variant='standard'
            sx={{ width: '100%' }}
            {...register(`${keyString}.phaseList.${index}.stamp`, {
              value: phase?.stamp,
            })}
          />
          <TextField
            id='tf-prev-factory-year'
            label='Год выпуска'
            variant='standard'
            sx={{ width: '100%' }}
            {...register(`${keyString}.phaseList.${index}.year`, {
              value: phase?.year,
            })}
            disabled
          />
        </Stack>
      )}
    </Box>
  );
};
