import { Grid } from '@mui/material';
import { Box } from '@mui/system';
import React, { FC } from 'react';
import shallow from 'zustand/shallow';
import { useStatus } from '@/components/features/taskEditor/panel/store';
import { AutocompleteValue } from '@/components/inputs/AutocompleteValue/AutocompleteValue';
import { useCatalog } from '@/hooks/CatalogHook';
import { TransformerAmperage } from '@/services/TransformerService/dto/TransformerResponseDto';

interface TransInfoProps {
  data: Partial<TransformerAmperage>;
}

export const TransInfo: FC<TransInfoProps> = ({ data }) => {
  const { getCatalogMapWithErr } = useCatalog((state) => ({
    getCatalogMapWithErr: state.getCatalogMapWithErr,
  }));
  const { isDisabledAll } = useStatus(
    (state) => ({
      isDisabledAll: state.isDisabledAll,
    }),
    shallow
  );

  return (
    <Box sx={{ p: 2 }}>
      <Grid
        container
        spacing={2}
      >
        <Grid
          item
          xs={2}
        >
          <AutocompleteValue
            title='КТТ'
            sx={{ width: '100%' }}
            fieldName={`kttId`}
            defaultValue={data?.kttId}
            values={getCatalogMapWithErr('kttEnum')}
            disabled={isDisabledAll()}
          />
        </Grid>
      </Grid>
    </Box>
  );
};
