import React, { useEffect, useState } from 'react';
import {
  GetRegistryADPUFiles,
  GetRegistryADPUFolders,
} from '@/services/RegistryADPUService/RegistryADPUService';
import FolderItem from './FolderItem';
import FileItem from './FileItem';
import {
  RegistryADPUBox,
  RegistryADPUDivider,
  RegistryADPUPopupOpenButton,
  RegistryADPUTitle,
  RegistryADPUWrapper,
  VerticalLineWrapper,
  VerticalLineWrapper75,
} from '@/components/RegistryADPU/RegistryADPU.styled';
import AddRoundedIcon from '@mui/icons-material/AddRounded';
import { useActionLog } from '@/hooks/ActionLogHook';
import { BoxShadow } from '../ui/BoxShadow';
import { Grid } from '@mui/material';
import FilterButtons from '@/components/filter/FilterButtons/FilterButtons';
import { FilterArgEnum } from '@/components/inputs/ArgumentSelectNew/types';
import { SingleLine } from '@/components/inputs/SingleLineNew';
import NewGalleryBlock from '@/components/features/NewGallery/NewGalleryBlock';
import { RegistryADPUPopup } from '@/components/RegistryADPU/RegistryADPUPopup';
import {
  RegistryADPUFileResponse,
  RegistryADPUFolderResponse,
} from '@/services/RegistryADPUService/dto/RegistryADPUServiceResponseDto';
import { stringToArray } from '@/utils/stringFunctions';

export const RegistryADPU = () => {
  const [folders, setFolders] = useState<RegistryADPUFolderResponse[]>([]);
  const [files, setFiles] = useState<RegistryADPUFileResponse>();
  const { fetchCatch } = useActionLog();
  const [filterNumber, setFilterNumber] = useState<string>('');
  const [filterNumberArg, setFilterNumberArg] = useState<FilterArgEnum>(FilterArgEnum.EQUAL);
  const [filterTaskId, setFilterTaskId] = useState<string>('');
  const [filterTaskIdArg, setFilterTaskIdArg] = useState<FilterArgEnum>(FilterArgEnum.EQUAL);
  const [isOpen, setIsOpen] = useState(false);

  const handleSearch = () => {
    GetRegistryADPUFolders({
      numberList: stringToArray(filterNumber),
      numberListArg: filterNumberArg,
      taskIdList: [filterTaskId].filter((e: string) => e != ''),
      taskIdListArg: filterTaskIdArg,
    }).then(({ data }) => setFolders(data));
  };
  const handleClear = () => {
    setFilterNumber('');
    setFilterNumberArg(FilterArgEnum.EQUAL);
    setFilterTaskId('');
    setFilterTaskIdArg(FilterArgEnum.EQUAL);
  };

  const getFolders = () => {
    GetRegistryADPUFolders()
      .then(({ data }) => setFolders(data))
      .catch((err) => {
        fetchCatch(err, 'Ошибка получения списка архивов');
      });
  };

  useEffect(getFolders, []);

  const handleClickFolder = (id: string) => {
    GetRegistryADPUFiles(id)
      .then((res) => {
        setFiles(res.data);
      })
      .catch((err) => {
        fetchCatch(err, 'Ошибка загрузки списка файлов');
      });
  };

  return (
    <>
      <Grid
        container
        spacing={2}
      >
        <Grid
          sx={{ m: 2 }}
          item
          xs={12}
        >
          <BoxShadow>
            <Grid
              container
              direction='row'
              justifyContent='space-between'
              alignItems='center'
              spacing={2}
            >
              <Grid
                item
                xs={3}
              >
                <SingleLine
                  argList={[
                    FilterArgEnum.EQUAL,
                    FilterArgEnum.IN_LIST,
                    FilterArgEnum.NOT_EQUAL,
                    FilterArgEnum.NOT_IN_LIST,
                  ]}
                  onChange={setFilterNumber}
                  label={'№ реестра'}
                  onArgChange={setFilterNumberArg}
                  value={filterNumber}
                  argValue={filterNumberArg}
                />
              </Grid>
              <Grid
                item
                xs={3}
              >
                <SingleLine
                  argList={[FilterArgEnum.EQUAL]}
                  onChange={setFilterTaskId}
                  label={'id заявки'}
                  onArgChange={setFilterTaskIdArg}
                  value={filterTaskId}
                  argValue={filterTaskIdArg}
                />
              </Grid>
              <Grid
                item
                xs={3}
              >
                <FilterButtons
                  onSearchClick={handleSearch}
                  onCloseClick={handleClear}
                />
              </Grid>
            </Grid>
          </BoxShadow>
        </Grid>
      </Grid>
      <RegistryADPUWrapper>
        <RegistryADPUBox width={'100%'}>
          {folders.map((elem, index) => (
            <React.Fragment key={elem.id}>
              <FolderItem
                onClick={() => handleClickFolder(elem.id)}
                {...elem}
              />
              {index !== folders.length - 1 && <RegistryADPUDivider variant='middle' />}
            </React.Fragment>
          ))}
        </RegistryADPUBox>
        {files?.taskIdList && files.taskIdList.length > 0 && <VerticalLineWrapper />}
        <RegistryADPUBox width={'50%'}>
          {files?.taskIdList && (
            <>
              <RegistryADPUTitle>Список заявок</RegistryADPUTitle>
              {files.taskIdList.map((elem, index) => (
                <FileItem
                  id={elem}
                  key={elem}
                />
              ))}
            </>
          )}
        </RegistryADPUBox>
        {files?.imageList && files.imageList.length > 0 && <VerticalLineWrapper75 />}
        <RegistryADPUBox width={'50%'}>
          {files?.imageList && files.imageList.length > 0 && (
            <NewGalleryBlock
              galleryTitle={'Прикрепленные файлы'}
              images={files?.imageList ?? []}
            />
          )}
        </RegistryADPUBox>

        <RegistryADPUPopup
          openDialog={isOpen}
          updateFunction={handleSearch}
          onClose={() => setIsOpen(false)}
        />
        <RegistryADPUPopupOpenButton onClick={() => setIsOpen(true)}>
          <AddRoundedIcon
            fontSize={'large'}
            sx={{ ml: 2 }}
          />
          <span>Добавить реестр</span>
        </RegistryADPUPopupOpenButton>
      </RegistryADPUWrapper>
    </>
  );
};
