import React, { FC, useEffect, useState } from 'react';
import {
  MailNotificationsFileResponse,
  MailNotificationsFolderResponse,
} from '@/services/MailNotificationsService/dto/MailNotificationsResponseDto';
import {
  GetADPUFiles,
  GetADPUFolders,
  GetMailNotificationsFiles,
  GetMailNotificationsFolders,
  UploadZipFile,
  UploadZipFileADPU,
} from '@/services/MailNotificationsService/MailNotificationsService';
import FolderItem from './FolderItem';
import FileItem from './FileItem';
import {
  ButtonWrapper,
  MailNotBoxShadow,
  MailNotDivider,
  MailNotificationsWrapper,
  VerticalLineWrapper,
} from '@/components/MailNotifications/MailNotifications.styled';
import AddRoundedIcon from '@mui/icons-material/AddRounded';
import { ActionLogType, useActionLog } from '@/hooks/ActionLogHook';

interface IMailNotifications {
  type?: 'MAIL' | 'ADPU';
}

export const MailNotifications: FC<IMailNotifications> = ({ type = 'MAIL' }) => {
  const [folders, setFolders] = useState<MailNotificationsFolderResponse[]>([]);
  const [files, setFiles] = useState<MailNotificationsFileResponse[]>([]);
  const { fetchCatch, addActionLog } = useActionLog();

  const getFolders = () => {
    const GetFolders = type === 'ADPU' ? GetADPUFolders : GetMailNotificationsFolders;

    GetFolders()
      .then((res) => {
        setFolders(res.data);
      })
      .catch((err) => {
        fetchCatch(err, 'Ошибка получения списка архивов');
      });
  };

  useEffect(getFolders, []);

  const handleClickFolder = (id: string) => {
    const GetFiles = type === 'ADPU' ? GetADPUFiles : GetMailNotificationsFiles;
    GetFiles(id)
      .then((res) => {
        setFiles(res.data);
      })
      .catch((err) => {
        fetchCatch(err, 'Ошибка загрузки списка файлов');
      });
  };

  return (
    <MailNotificationsWrapper>
      <MailNotBoxShadow type={'left'}>
        {folders.map((elem, index) => (
          <React.Fragment key={elem.id}>
            <FolderItem
              getFolders={getFolders}
              onClick={() => handleClickFolder(elem.id)}
              {...elem}
              rowIndex={index + 1}
            />
            {index !== folders.length - 1 && <MailNotDivider variant='middle' />}
          </React.Fragment>
        ))}
      </MailNotBoxShadow>
      <MailNotBoxShadow type={'right'}>
        {files.map((elem, index) => (
          <React.Fragment key={elem.id}>
            <FileItem
              {...elem}
              key={elem.id}
            />
            {index !== files.length - 1 && <MailNotDivider variant='middle' />}
          </React.Fragment>
        ))}
      </MailNotBoxShadow>
      <VerticalLineWrapper />

      <label htmlFor='input__file'>
        <input
          type='file'
          accept='.zip'
          id='input__file'
          className='input input__file'
          hidden={true}
          onChange={(e) => {
            const UploadZip = type === 'ADPU' ? UploadZipFileADPU : UploadZipFile;
            e.target.files &&
              UploadZip(e.target.files[0])
                .then(() => addActionLog(ActionLogType.SUCCESS, 'Успешная загрузка архива'))
                .catch((err) => {
                  fetchCatch(err, 'Ошибка загрузки архива');
                })
                .finally(getFolders);
          }}
          required={true}
          multiple={false}
        />

        <ButtonWrapper>
          <AddRoundedIcon />
          Загрузить архив
        </ButtonWrapper>
      </label>
    </MailNotificationsWrapper>
  );
};
