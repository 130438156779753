import React, { useEffect, useState } from 'react';
import { Button, Grid } from '@mui/material';
import { useParams } from 'react-router-dom';
import MonitoringEditTable from './MonitoringEditTable';
import { ResultTable } from './ResultTable';
import { EditTable } from './EditTable';
import { AutocompleteValue } from '@/components/inputs/AutocompleteValue/AutocompleteValue';
import { FormProvider, useForm } from 'react-hook-form';
import { useCatalog } from '@/hooks/CatalogHook';
import { TextField } from '@/components/fixUI';
import IconButton from '@mui/material/IconButton';
import SearchRoundedIcon from '@mui/icons-material/SearchRounded';
import {
  findAllRegistryTasksByParams,
  findRegistryById,
  sendRegistry,
} from '@/services/RegistryService/RegistryService';
import { useRegistryEditStore } from './EditTable/store';
import { Months } from '@/components/features/registryBrowse/constants';
import { ActionLogType, syncErrorCatch, useActionLog } from '@/hooks/ActionLogHook';
import { useRegistryResultStore } from './ResultTable/store';
import { StatusItem, StatusItemTypes } from '@/components/features/registryBrowse/StatusItem';
import { useRegistryBrowseStore } from '@/components/features/registryBrowse/store';
import { Item } from './constants';
import { IdPopup } from '@/components/features/taskEditor/panel/IdPopup';
import { useMetaTitle } from '@/hooks/useMetaTitle';
import { RegistryHeaderTitle } from '@/components/features/registryEditor/Registry.styled';

export const RegistryEditor = () => {
  const { id } = useParams();
  const [loading, setLoading] = useState<boolean>(false);
  const { getGroupCatalog } = useCatalog((state) => ({
    getGroupCatalog: state.getGroupCatalog,
  }));
  const { setRegistryItem, registryItem } = useRegistryBrowseStore((state) => ({
    setRegistryItem: state.setRegistryItem,
    registryItem: state.registryItem,
  }));
  const { setRegistryResultTable, clear } = useRegistryEditStore((state) => ({
    setRegistryResultTable: state.setRegistryResultTable,
    clear: state.clear,
  }));
  const { registryResultTable } = useRegistryResultStore((state) => ({
    registryResultTable: state.registryResultTable,
  }));
  const statistic = registryResultTable
    .filter(
      (val, ind, arr) => ind === arr.findIndex((elem) => elem.responsible === val.responsible)
    )
    .map((elem) => ({
      name: getGroupCatalog()?.get(elem.responsible) || '-',
      count: registryResultTable.filter((e) => e.responsible === elem.responsible).length,
    }));

  const { addActionLog, fetchCatch } = useActionLog();

  const findById = (id?: string) => {
    if (id) {
      findRegistryById(id)
        .then((res) => {
          setRegistryItem(res.data);
        })
        .catch((res) => fetchCatch(res, 'Ошибка получения данных реестра'));
    } else {
      syncErrorCatch('Ошибка получения данных реестра: отсутствует ID');
    }
  };

  useEffect(() => {
    clear();
    findById(id);
  }, []);

  const methods = useForm<any>();
  const { register } = methods;
  const { getCatalogMapWithErr } = useCatalog((state) => ({
    getCatalogMapWithErr: state.getCatalogMapWithErr,
  }));

  const onSearch = (data: any) => {
    setLoading(true);
    findAllRegistryTasksByParams({ ...data }).then(({ data }) => {
      setRegistryResultTable(
        data.map((elem) => ({
          id: elem.id,
          adress: elem.addressTitle,
          type: elem.type,
          contractorId: elem.contractorId,
          data: elem.scheduleOn,
          executor: elem.executorId,
          managementCompany: elem.managementCompanyId,
        }))
      );
      setLoading(false);
    });
  };
  const handleSaveClick = () => {
    sendRegistry({ id: registryItem.id, data: { ...registryItem } })
      .then((res) => {
        addActionLog(ActionLogType.SUCCESS, 'Реестр успешно отправлен');
        findById(id);
      })
      .catch((res) => fetchCatch('Ошибка отправки реестра', res));
  };
  useMetaTitle(`Реестр ${registryItem.id ?? ''}`);

  return (
    registryItem && (
      <div>
        <RegistryHeaderTitle>
          <Item>
            {Months[registryItem.month]} {registryItem.year}
          </Item>
          <Item>№{registryItem.id}</Item>
          <StatusItem
            type={registryItem.completed ? StatusItemTypes.MESSAGE : StatusItemTypes.EDIT}
            countAll={registryItem.fileCount}
            countTmp={registryItem.fileAttachedCount}
          />
        </RegistryHeaderTitle>

        <Grid
          container
          spacing={2}
          p={4}
        >
          {!registryItem.completed && (
            <>
              <Grid
                item
                xs={12}
                container
                spacing={2}
              >
                <FormProvider {...methods}>
                  <Grid
                    item
                    xs={12}
                    sm={3.5}
                    lg={2}
                  >
                    <AutocompleteValue
                      title='Тип'
                      fieldName={`type`}
                      values={getCatalogMapWithErr('taskType')}
                    />
                  </Grid>
                  <Grid
                    item
                    xs={12}
                    sm={3.5}
                    lg={2}
                  >
                    <AutocompleteValue
                      title='Управляющая компания'
                      fieldName={`managementCompany`}
                      values={getCatalogMapWithErr('odpuManagementCompany')}
                    />
                  </Grid>
                  <Grid
                    item
                    xs={12}
                    sm={3.5}
                    lg={2}
                  >
                    <TextField
                      fullWidth
                      label='Адрес'
                      variant='standard'
                      {...register(`address`)}
                    />
                  </Grid>

                  <Grid item>
                    <IconButton onClick={methods.handleSubmit(onSearch)}>
                      <SearchRoundedIcon />
                    </IconButton>
                  </Grid>
                </FormProvider>
                <Grid
                  item
                  flexGrow={1}
                />
                <Grid item>
                  <IdPopup title={'Распределение по ответственным'}>
                    {statistic.map((elem) => (
                      <div key={elem.name}>
                        {elem.name}: {elem.count}
                      </div>
                    ))}
                  </IdPopup>
                </Grid>
              </Grid>

              <Grid
                item
                xs={12}
                md={6}
              >
                <EditTable
                  id={registryItem.id}
                  loading={loading}
                />
              </Grid>
            </>
          )}

          {registryItem.completed && (
            <Grid
              item
              xs={12}
              md={6}
            >
              <MonitoringEditTable id={registryItem.id} />
            </Grid>
          )}

          <Grid
            item
            xs={12}
            md={6}
          >
            <ResultTable id={registryItem.id} />
            {!registryItem.completed && (
              <Grid
                item
                xs={12}
                sx={{ display: 'flex', justifyContent: 'flex-end' }}
              >
                {registryItem.sendable && (
                  <Button onClick={handleSaveClick}>Отправить реестр</Button>
                )}
              </Grid>
            )}
          </Grid>
        </Grid>
      </div>
    )
  );
};
