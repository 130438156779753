import { ApiResponse } from '@/types/api';
import { HOST, YodaRestClient } from '@/services/YodaAuthService';
import { SMROdpuStatisticsTable } from './dto/OdpuStatisticServiceResponseDto';
import { SMROdpuStatisticsFilter } from './dto/OdpuStatisticServiceRequestDto';

export async function GetSMROdpuStatisticsTableByFilter(
  filter: SMROdpuStatisticsFilter
): ApiResponse<SMROdpuStatisticsTable> {
  const url = HOST + '/rest/statistics/smr/odpu';
  return YodaRestClient.post(url, filter);
}
