import * as React from 'react';
import { FC, useEffect, useState } from 'react';
import {
  Autocomplete,
  Button,
  Grid,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableRow,
  TextField,
} from '@mui/material';
import moment from 'moment';
import TableHead from '@mui/material/TableHead';
import { TableCells } from './TableCells';
import { ActionLogType, syncErrorCatch, useActionLog } from '@/hooks/ActionLogHook';
import { FormProvider, useForm } from 'react-hook-form';
import {
  changeStatus,
  createRegistry,
  getMissingReport,
  getRegistryEnforseTable,
  getReport,
} from '@/services/RegistryService/RegistryService';
import { TransferListPopup } from '@/components/popups/TransferListPopup';
import { headCells, statusArray } from './constants';
import { DateRange } from '@/components/inputs/DateRange';
import { useRegistryEnforseFilter } from './store';
import { FilterArgEnum } from '@/components/inputs/ArgumentSelectNew/types';
import FilterButtons from '@/components/filter/FilterButtons/FilterButtons';
import LoadSpinner from '@/components/ui/LoadSpinner/LoadSpinner';
import { PopupForNewDate } from './PopupForNewDate';
import { GetRegistryEnforseTableResponse } from '@/services/RegistryService/dto/RegistryServiceResponseDto';
import { downLoadExcel } from '../complaints/ComplaintReportButtons/utils';
import ReportTaskWithAddresDownload from './ReportTaskWithAddresDownload';
import shallow from 'zustand/shallow';

export const EditTable: FC = () => {
  const { fetchCatch, addActionLog } = useActionLog();
  const { date, setDateValue, setDateValueArg, setDefaultValue } = useRegistryEnforseFilter(
    (state) => ({
      date: state.date,
      setDateValue: state.setDateValue,
      setDateValueArg: state.setDateValueArg,
      setDefaultValue: state.setDefaultValue,
    }),
    shallow
  );

  const [isOpen, setIsOpen] = useState<boolean>(false);
  const [isNewDatePopupOpen, setIsNewDatePopupOpen] = useState<boolean>(false);
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [rows, setRows] = useState<GetRegistryEnforseTableResponse[]>([]);

  const getRegistryEnforse = (type?: FilterArgEnum, dateFrom?: number, dateTo?: number) => {
    setIsLoading(true);
    getRegistryEnforseTable({ type, dateFrom, dateTo })
      .then((res) => setRows(res.data))
      .finally(() => setIsLoading(false));
  };
  const handleSearch = () => {
    if (date.argValue == FilterArgEnum.RANGE && date.value.length > 1) {
      return getRegistryEnforse(date.argValue, date.value[0], date.value[1]);
    }
    if (date.value.length > 0 && date.argValue !== FilterArgEnum.RANGE) {
      return getRegistryEnforse(date.argValue, date.value[0]);
    }
    return getRegistryEnforse();
  };
  useEffect(() => {
    getRegistryEnforse();
  }, []);

  const [selectedId, setSelectedId] = useState<string>('');
  const methods = useForm<any>();

  const handleClick = (event: any, id: string, index: any) => {
    setSelectedId(id);
  };

  const handleEnforseUpload = () => {
    if (!selectedId.length) {
      syncErrorCatch('Необходимо выбрать строку в таблице');
      return Promise.resolve();
    } else {
      setIsLoading(true);
      const onlyToTasks = useRegistryEnforseFilter.getState().onlyToTasks;
      return getReport(selectedId, onlyToTasks)
        .then((data) => downLoadExcel(data, 'report'))
        .catch((err) => {
          // Возвращается Blob, поэтому есть доп логика при обработке ошибки
          if (err.response && err.response.data instanceof Blob) {
            err.response.data.text().then((text: string) => {
              try {
                const parsedData = JSON.parse(text);
                err.response.data = parsedData;
              } catch (e) {
                err.response.data = { message: text };
              }
              fetchCatch(err);
            });
          } else {
            fetchCatch(err);
          }
        })
        .finally(() => {
          setIsLoading(false);
        });
    }
  };
  const handleEnforseMissingUpload = () => {
    if (!selectedId.length) {
      syncErrorCatch('Необходимо выбрать строку в таблице');
    } else {
      setIsLoading(true);
      getMissingReport(selectedId)
        .then((data) => downLoadExcel(data, 'report'))
        .catch((err) => fetchCatch(err))
        .finally(() => {
          setIsLoading(false);
        });
    }
  };
  const handleUpdateContracts = () => {
    setIsOpen(true);
  };
  const handleCreateRegistry = () => {
    setIsLoading(true);
    createRegistry()
      .then(() => addActionLog(ActionLogType.SUCCESS, 'Реестр создан'))
      .catch((res) => fetchCatch(res))
      .finally(() => {
        setIsLoading(false);
      });
  };
  const extraDate = (row: GetRegistryEnforseTableResponse) => {
    if (row.manual) {
      if (row.dateFrom && row.dateTo) {
        return `c ${moment(row.dateFrom).format('DD.MM.YYYY')} по ${moment(row.dateTo).format(
          'DD.MM.YYYY'
        )}`;
      } else {
        return '';
      }
    }
    return '';
  };
  const checkDate = (row: GetRegistryEnforseTableResponse) => {
    return (
      (row.processingAt && moment(row.processingAt).format('DD.MM.yyyy HH:mm:ss')) ||
      'дата неизвестна'
    );
  };

  return (
    <>
      <Grid
        item
        xs={12}
        container
        spacing={2}
        style={{ alignItems: 'center' }}
      >
        <Grid
          item
          xs={2}
        >
          <DateRange
            isDisable={isLoading}
            label={'Статус акта'}
            onChange={setDateValue('date')}
            onArgChange={setDateValueArg('date')}
            {...date}
            showClearButton={false}
          />
        </Grid>
        <Grid
          item
          xs={1}
        >
          <FilterButtons
            disable={isLoading}
            onSearchClick={handleSearch}
            onCloseClick={setDefaultValue}
          />
        </Grid>
        <Grid
          item
          xs={9}
        >
          <ReportTaskWithAddresDownload
            isLoading={isLoading}
            handleEnforseUpload={handleEnforseUpload}
          />
          <Button
            disabled={isLoading}
            onClick={handleEnforseMissingUpload}
          >
            Отчет по заявкам без адреса
          </Button>
          <Button
            disabled={isLoading}
            onClick={handleUpdateContracts}
          >
            Изменить контракты
          </Button>
          <Button
            disabled={isLoading}
            onClick={handleCreateRegistry}
          >
            Сформировать реестр
          </Button>
          <Button
            disabled={isLoading}
            onClick={() => setIsNewDatePopupOpen(true)}
          >
            сформировать реестр от даты
          </Button>
          <Button
            disabled={isLoading}
            onClick={handleSearch}
          >
            Обновить
          </Button>
        </Grid>
      </Grid>
      <Grid
        item
        xs={12}
      >
        <FormProvider {...methods}>
          {isLoading ? (
            <LoadSpinner />
          ) : (
            <Paper>
              <TableContainer sx={{ maxHeight: '600px', overflow: 'scroll' }}>
                <Table
                  aria-labelledby='tableTitle'
                  size={'small'}
                >
                  <TableHead>
                    <TableRow>
                      <TableCells headCells={headCells} />
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {rows.map((row, index) => (
                      <TableRow
                        hover
                        onClick={(event) => handleClick(event, row.enforceImportId, index)}
                        role='checkbox'
                        aria-checked={selectedId === row.enforceImportId}
                        tabIndex={-1}
                        key={row.enforceImportId}
                        selected={selectedId === row.enforceImportId}
                        sx={{ cursor: 'pointer' }}
                      >
                        <TableCell>{`${checkDate(row)} ${extraDate(row)}`}</TableCell>
                        <TableCell>{row.taskCount}</TableCell>
                        <TableCell>{row.taskWithoutAddressCount}</TableCell>
                        <TableCell>
                          <Autocomplete
                            disabled={!!row.loadAt}
                            disableClearable
                            options={statusArray}
                            id={`${row.enforceImportId}-autocomplete-value`}
                            value={row.loadAt ? statusArray[0] : statusArray[1]}
                            onChange={(event: any, newValue: any) => {
                              if (newValue === statusArray[0]) {
                                changeStatus(row.enforceImportId)
                                  .then(() => getRegistryEnforse())
                                  .catch((res) => fetchCatch(res));
                              }
                            }}
                            renderInput={(params) => (
                              <TextField
                                {...params}
                                variant='standard'
                              />
                            )}
                          />
                        </TableCell>
                        <TableCell>{row.contracts}</TableCell>
                        <TableCell align='center'>{row.manual ? 'вручную' : '---'}</TableCell>
                      </TableRow>
                    ))}
                  </TableBody>
                </Table>
              </TableContainer>
            </Paper>
          )}
        </FormProvider>
      </Grid>
      <TransferListPopup
        isOpen={isOpen}
        onClose={() => setIsOpen(false)}
        onSuccess={() => setIsOpen(false)}
      />
      <PopupForNewDate
        isOpen={isNewDatePopupOpen}
        onClose={() => setIsNewDatePopupOpen(false)}
        handleSearch={handleSearch}
      />
    </>
  );
};
