import React, { useRef, useEffect, useState, MutableRefObject } from 'react';
import * as d3 from 'd3';
import { Tooltip } from '../../types/types';

import shallow from 'zustand/shallow';

import BudgetStatisticDoughnutForm from './BudgetStatisticDoughnutForm';
import { ChartWrapper, InvestStatisticTitle } from '../InvestStatistic.styled';
import { useInvestStatisticStore } from '../../store';
import { DoughnutLegend } from './DoughnutLegend';
import {
  adjustSvgSizeForGroup,
  createPie,
  createSvg,
  drawSectorsDoughnut,
  getMouseCordsWithGap,
  normalizeData,
} from './utils';
import { DoughnutTooltip } from './DoughnutTooltip';
import moment from 'moment';
import { StatisticSummaryResponse } from '../../api/dto/response';

export const BudgetStatisticDoughnut = () => {
  const { showInfo, setActiveIndex, equipmentsData, displayManufacturer, setDisplayManufacturer } =
    useInvestStatisticStore(
      (state) => ({
        showInfo: state.showInfo,
        setActiveIndex: state.setActiveIndex,
        equipmentsData: state.equipmentsData,
        displayManufacturer: state.displayManufacturer,
        setDisplayManufacturer: state.setDisplayManufacturer,
      }),
      shallow
    );
  const [loading, setLoading] = useState(false);

  const svgRef = useRef<SVGSVGElement | null>(null);
  const refWrapper = useRef() as MutableRefObject<HTMLDivElement>;

  const [tooltip, setTooltip] = useState<Tooltip>({
    display: 'none',
    tooltipData: null,
    top: 0,
    left: 0,
  });

  const handleMouseover = (event: MouseEvent, d: d3.PieArcDatum<StatisticSummaryResponse>) => {
    if (!showInfo) {
      setTooltip({
        display: 'block',
        tooltipData: d.data,
        ...getMouseCordsWithGap(event),
      });
    }
  };

  const handleMousemove = (event: MouseEvent) => {
    setTooltip((prev) => ({
      ...prev,
      ...getMouseCordsWithGap(event),
    }));
  };

  const handleMouseout = (event: MouseEvent) => {
    setTooltip({ display: 'none', tooltipData: null, top: 0, left: 0 });
  };

  const handleOnClick = (
    event: React.MouseEvent<HTMLDivElement, MouseEvent>,
    d: d3.PieArcDatum<StatisticSummaryResponse> | { index: number }
  ) => {
    setActiveIndex(d.index);
  };

  const [height, setHeight] = useState(window.innerHeight);

  useEffect(() => {
    const handleResize = () => {
      setHeight(window.innerHeight);
    };

    window.addEventListener('resize', handleResize);

    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  useEffect(() => {
    refWrapper?.current?.scrollIntoView({ behavior: 'smooth' });
    d3.select(svgRef.current).selectAll('*').remove();

    const divBounds = d3.select('#chartBox');

    const divElement = divBounds.node();

    if (!(divElement instanceof Element)) {
      return;
    }

    const divRect = divElement.getBoundingClientRect();

    const width = divRect.height - 70 > divRect.width / 2 ? divRect.width / 2 : divRect.height - 70;

    const radius = Math.min(width, width) / 2 - 10;

    const svg = createSvg(svgRef, width);

    const pie = createPie();

    const normalizedData = normalizeData(equipmentsData, 2);

    const arcs = pie(normalizedData);
    // FIXME: на случай отката
    // const arcs = pie(equipmentsData);

    drawSectorsDoughnut(
      arcs,
      svg,
      equipmentsData,
      handleMouseover,
      handleMousemove,
      handleMouseout,
      handleOnClick,
      radius
    );

    adjustSvgSizeForGroup(svgRef);
  }, [equipmentsData, height]);

  return (
    <>
      <div ref={refWrapper}>
        <InvestStatisticTitle>ПЛАН СБЫТЫ {moment().format('YYYY')}</InvestStatisticTitle>

        <ChartWrapper>
          <BudgetStatisticDoughnutForm
            displayManufacturer={displayManufacturer}
            setDisplayManufacturer={setDisplayManufacturer}
          />

          <div
            id='chartBox'
            style={{ display: 'flex', flex: 1 }}
          >
            <div
              style={{
                paddingTop: '60px',
                display: 'flex',
                justifyContent: 'center',
                flex: 1,
                height: '100%',
              }}
            >
              <svg ref={svgRef}></svg>
            </div>

            <DoughnutLegend
              displayManufacturer={displayManufacturer}
              onClick={handleOnClick}
            />
          </div>
          <DoughnutTooltip
            tooltip={tooltip}
            displayManufacturer={displayManufacturer}
          />
        </ChartWrapper>
      </div>
    </>
  );
};
