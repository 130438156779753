import { Grid, Stack, Typography } from '@mui/material';
import React, { useEffect, useState } from 'react';
import { useFormContext } from 'react-hook-form';
import { AutocompleteValue } from '@/components/inputs/AutocompleteValue/AutocompleteValue';
import { useCatalog } from '@/hooks/CatalogHook';
import moment from 'moment';
import { TaskResponse } from '@/dto/taskmap/task/TasksDto';
import { Loading } from '@/components/ui/Loading';
import { HeaderTitle } from './HeaderTitle';
import { filterMap } from './utils';
import { useStatus } from './store';
import AddressEditLabelDialog from '../../../../components/features/taskEditor/dialog/AddressEditLabelDialog';
import { DateRange } from '@/components/inputs/DateRange';
import { AccessEnum } from '@/app/auth/roles';
import { AccessController } from '@/components/common/AccessController';
import { useActionLog } from '@/hooks/ActionLogHook';
import { findKidsAndParents, getActDateHistory } from '@/services/TaskService/TaskService';
import { ActDateHistoryResponse, Siblings } from '@/dto/taskmap/Dto';
import TaskHeaderPanelSiblings from './TaskHeaderPanelSiblings/TaskHeaderPanelSiblings';
import { useKidsAndParents } from '@/hooks/useQuery/useKidsAndParents';
import CopyButton from '@/components/button/CopyButton/CopyButton';
import { color } from '@/styles/mixins';
import { useDisabledMesManButtons } from '@/components/features/tasksBrowse/TasksBrowseToolbar/utils';
import { IdPopup } from './IdPopup';
import { TaskActHistoryItem } from './TaskActHistoryItem';

interface TaskHeaderPanelProps {
  taskResponse: TaskResponse;
}

const col_5 = 2;

export default function TaskHeaderPanel(props: TaskHeaderPanelProps) {
  const { fetchCatch } = useActionLog();
  const isDisabled = useDisabledMesManButtons();
  const { register, setValue } = useFormContext();
  const { editable, setEditable } = useStatus((state) => ({
    setEditable: state.setEditable,
    editable: state.editable,
  }));
  const { getCatalogMapWithErr, getGroupCatalog } = useCatalog((state) => ({
    getCatalogMapWithErr: state.getCatalogMapWithErr,
    getGroupCatalog: state.getGroupCatalog,
  }));
  const [taskResponse, setTaskResponse] = useState<TaskResponse>();
  const [actDateHistory, setActDateHistory] = useState<ActDateHistoryResponse[]>([]);
  const [scheduledDate, setScheduledDate] = useState<Date | null | Date[] | number>([]);
  const [completionDate, setCompletionDate] = useState<Date | null | Date[] | number>([]);
  const [actDate, setactDate] = useState<Date | null | Date[] | number>([]);
  const [headerTitle, setHeaderTitle] = useState<JSX.Element>();
  const [addressElement, setAddressElement] = useState<JSX.Element>();
  const [loadingHeader, setLoadingHeader] = useState(true);
  const [enabled, setEnabled] = useState(false);

  const handleClick = () => {
    setEnabled(true);
  };

  useEffect(() => {
    if (props.taskResponse) {
      setTaskResponse(props.taskResponse);
      setEditable(props.taskResponse.editable);
      if (props.taskResponse.scheduledDate != null) {
        const scheduled = moment(props.taskResponse.scheduledDate).valueOf();
        setScheduledDate(new Date(scheduled));
        setValue('scheduledDate', scheduled);
      }
      if (props.taskResponse.completionDate != null) {
        const completion = moment(props.taskResponse.completionDate).valueOf();
        setCompletionDate(new Date(completion));
        setValue('completionDate', completion);
      }
      if (props.taskResponse.actDate != null) {
        const actDate = moment(props.taskResponse.actDate).valueOf();
        setactDate(new Date(actDate));
        setValue('actDate', actDate);
      }
    }
  }, [props.taskResponse]);

  useEffect(() => {
    // console.log(props.taskResponse.id);
    props.taskResponse &&
      getActDateHistory(props.taskResponse.id)
        .then(({ data }) => {
          setActDateHistory(data);
        })
        .catch((res) => {
          fetchCatch(res, 'Ошибка загрузки дат актирования');
        });
  }, [props.taskResponse]);

  useEffect(() => {
    setHeaderTitle(
      <HeaderTitle
        type={getCatalogMapWithErr('taskType')?.get(taskResponse?.type || '')}
        contractTitle={props?.taskResponse?.contractResponse?.contractTitle}
        number={props?.taskResponse?.number}
        status={getCatalogMapWithErr('taskStatus')?.get(taskResponse?.status || '')}
      />
    );
    if (taskResponse) {
      setAddressElement(<AddressEditLabelDialog taskResponse={taskResponse} />);
    }
    setLoadingHeader(false);
  }, [taskResponse]);

  useEffect(() => {
    register('scheduledDate');
    register('completionDate');
    register('actDate');
  }, [register]);

  const onHandleScheduledDate = (newValue: number[] | null) => {
    if (newValue && newValue[0]) {
      setScheduledDate(newValue[0]);
      setValue('scheduledDate', newValue[0]);
    }
  };
  const onHandleActDate = (newValue: number[] | null) => {
    if (newValue && newValue[0]) {
      setactDate(newValue[0]);
      setValue('actDate', newValue[0]);
    }
  };

  const onHandleCompletionDate = (newValue: number[] | null) => {
    if (newValue && newValue[0]) {
      setCompletionDate(newValue[0]);
      setValue('completionDate', newValue[0]);
    }
  };
  const { data: siblings, isFetching } = useKidsAndParents(taskResponse?.id, enabled);

  const isOdpu = (value: string | undefined) =>
    value === 'SMR_ODPU' || value === 'SMR_ODPU_MOEK' || value === 'SMR_ODPU_ENTITY';

  return (
    <>
      <div
        style={{
          display: 'flex',
          justifyContent: 'flex-end',
          position: 'absolute',
          right: '60px',
        }}
      >
        <span>Дата актирования:</span>
        {actDateHistory.length > 0 ? (
          <IdPopup
            title={'Показать'}
            sx={{ color: color('muiBlue'), ml: 1 }}
          >
            <Grid
              container
              spacing={4}
              sx={{ minWidth: '70vw' }}
            >
              {actDateHistory
                .filter((elem) => elem.ksDateType == 'SMR')
                .map((el, ind) => (
                  <TaskActHistoryItem
                    {...el}
                    key={ind}
                  />
                ))}
              {actDateHistory
                .filter((elem) => elem.ksDateType == 'PNR')
                .map((el, ind) => (
                  <TaskActHistoryItem
                    {...el}
                    key={ind}
                  />
                ))}
            </Grid>
          </IdPopup>
        ) : (
          <span style={{ marginLeft: 2 }}>отсутствует</span>
        )}
      </div>
      <Grid
        container
        spacing={3}
        // sx={{ position: 'relative' }}
      >
        <Grid
          item
          xs={6}
          container
        >
          <Grid
            item
            sx={{ display: 'flex', alignItems: 'center' }}
          >
            <Stack
              onClick={() => {
                handleClick();
              }}
            >
              <TaskHeaderPanelSiblings
                isFetching={isFetching}
                siblings={siblings}
                isParent={taskResponse?.id === siblings?.parentTask?.id}
              />
            </Stack>

            <Loading loading={loadingHeader}>
              <Typography variant='h5'>{headerTitle}</Typography>
            </Loading>
          </Grid>
        </Grid>
        <Grid
          item
          xs={5}
          sx={{ display: 'flex', justifyContent: 'flex-end' }}
        >
          {addressElement}
        </Grid>

        <Grid
          item
          xs={col_5}
        >
          <AutocompleteValue
            disabled={!editable}
            title='Приоритет'
            defaultValue={taskResponse?.priority}
            fieldName={'priority'}
            values={getCatalogMapWithErr('taskPriority')}
          />
        </Grid>
        <Grid
          item
          xs={col_5}
        >
          <AccessController access={[AccessEnum.CastomPskClose]}>
            <AutocompleteValue
              disabled={!editable}
              title='Ответственный подрядчик'
              defaultValue={taskResponse?.contractorId}
              fieldName={'contractorId'}
              values={getGroupCatalog()}
            />
          </AccessController>
        </Grid>
        <Grid
          item
          xs={col_5}
        >
          <AccessController access={[AccessEnum.CastomPskClose]}>
            <AutocompleteValue
              disabled={isDisabled || !editable}
              title='Бригада исполнителя'
              defaultValue={taskResponse?.executorId}
              fieldName={'executorId'}
              values={getGroupCatalog()}
            />
          </AccessController>
        </Grid>
        {isOdpu(taskResponse?.type) && (
          <Grid
            item
            xs={col_5}
          >
            <AutocompleteValue
              disabled={!editable}
              title='Тип заявки'
              defaultValue={taskResponse?.type}
              fieldName={'type'}
              values={filterMap(getCatalogMapWithErr('taskType'), (k, v) => isOdpu(k))}
            />
          </Grid>
        )}
        {/*{taskResponse?.type != 'NETWORKELEMENTS' && (*/}
        {/*  <Grid*/}
        {/*    item*/}
        {/*    xs={isOdpu(taskResponse?.type) ? 1.5 : col_5}*/}
        {/*    mt={0.4}*/}
        {/*  >*/}
        {/*    <DateRange*/}
        {/*      label={'Дата актирования'}*/}
        {/*      onChange={onHandleActDate}*/}
        {/*      value={[actDate]}*/}
        {/*      argValue={2}*/}
        {/*      showSelect={false}*/}
        {/*      showCalenderIcon={true}*/}
        {/*      style={{ width: '100%' }}*/}
        {/*      isDisable={true} /// read only for now change to  !editable*/}
        {/*      showClearButton={false}*/}
        {/*      showPalceHolder={false}*/}
        {/*    />*/}
        {/*  </Grid>*/}
        {/*)}*/}
        <Grid
          item
          xs={col_5}
          mt={0.4}
        >
          <>
            <DateRange
              label={'Плановая дата исполнения'}
              onChange={onHandleScheduledDate}
              value={[scheduledDate]}
              argValue={2}
              showSelect={false}
              showCalenderIcon={true}
              style={{ width: '100%' }}
              isDisable={isDisabled || !editable}
              showClearButton={false}
              showPalceHolder={false}
            />
          </>
        </Grid>
        <Grid
          item
          xs={col_5}
          mt={0.4}
        >
          <DateRange
            label={'Дата выполнения'}
            onChange={onHandleCompletionDate}
            value={[completionDate]}
            argValue={2}
            showSelect={false}
            showCalenderIcon={true}
            style={{ width: '100%' }}
            isDisable={isDisabled || !editable}
            showClearButton={false}
            showPalceHolder={false}
          />
        </Grid>
      </Grid>
    </>
  );
}
