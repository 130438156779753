import { PhonebookDataResponseDto } from '@/services/PhonebookService/dto/PhonebookServiceResponseDto';
import { createStore } from '@/utils/createStore';

export interface IPhonebookStore {
  isLoading: boolean;
  searchTerm: string;
  phonebookData: PhonebookDataResponseDto[];
  currentPage: number;
  pageSize: number;
  count: number;
  selectedPhonebookItem: PhonebookDataResponseDto | null;
  open: boolean;
}
export interface IPhonebookFunc {
  setIsLoading: (isLoading: boolean) => void;
  setSearchTerm: (searchTerm: string) => void;
  setPhonebookData: (phonebookData: PhonebookDataResponseDto[]) => void;
  setCurrentPage: (currentPage: number) => void;
  setPageSize: (pageSize: number) => void;
  setCount: (count: number) => void;
  addPhonebookData: (phonebookData: PhonebookDataResponseDto) => void;
  updatePhonebookDataItem: (
    newPhonebookItem: PhonebookDataResponseDto,
    updateItemId: string
  ) => void;
  setSelectedPhonebookItem: (phonebookItem: PhonebookDataResponseDto | null) => void;
  setOpen: (open: boolean) => void;
}

export const usePhonebookStore = createStore<IPhonebookStore & IPhonebookFunc>(
  (set, get) => ({
    isLoading: false,
    searchTerm: '',
    phonebookData: [],
    currentPage: 0,
    pageSize: 5,
    count: 0,
    selectedPhonebookItem: null,
    open: false,

    setOpen: (open) => set(() => ({ open })),
    setSelectedPhonebookItem: (phonebookItem) =>
      set(() => ({ selectedPhonebookItem: phonebookItem, open: false })),
    setCount: (count) => set(() => ({ count })),
    setIsLoading: (isLoading) => set(() => ({ isLoading })),
    setSearchTerm: (searchTerm) => set(() => ({ searchTerm })),
    setPhonebookData: (phonebookData) => set(() => ({ phonebookData })),
    setCurrentPage: (currentPage) => set(() => ({ currentPage })),
    setPageSize: (pageSize) => set(() => ({ pageSize })),
    addPhonebookData: (phonebookData) =>
      set((state) => {
        const newPhonebookData = [phonebookData, ...state.phonebookData];
        return { phonebookData: newPhonebookData };
      }),

    updatePhonebookDataItem: (newPhonebookItem, updateItemId) =>
      set((state) => {
        const newPhonebookData = state.phonebookData.map((item) => {
          if (item.id === updateItemId) {
            return newPhonebookItem;
          }
          return item;
        });
        return { phonebookData: newPhonebookData };
      }),
  }),
  'Phonebook'
);
