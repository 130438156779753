import { FilterArgEnum } from '@/components/inputs/ArgumentSelectNew/types';
import { ComplaintTypeReturn, EComplaintSource, EComplaintType, IComplaintItem } from './types';
import imgMas from '@/images/icons/issues/mas.svg';
import imgCallCenter from '@/images/icons/issues/call-center.svg';
import admin from '@/images/icons/issues/admin.svg';

import { useCallback, useEffect, useMemo, useState } from 'react';
import { getWaranty } from '@/services/WarantyService';
import { fetchCatch } from '@/hooks/ActionLogHook';
import { useComplaintStore } from './store';
import { findAllResultByTaskIdAndType } from '@/services/TaskService/TaskService';
import { IImage, TaskResultType } from '@/dto/taskmap/task/TaskResultDto';
import { collectUniqueImages } from '../taskEditor/panel/utils';
import { useHasAnyRoleSet } from '@/hooks/useAccess';
import { RolesEnum } from '@/app/auth/roles';

type FILTERS_LABELS_TYPE = {
  [key: string]: string;
};

export const SAVE_FILTER_KEY_ISSUES = 'Complaints';

export const FILTERS_LABELS: FILTERS_LABELS_TYPE = {
  address: 'Адрес',
  PU: '№ ПУ',
  fio: 'ФИО',
  account: 'Лицевой счет',
  stage: 'Этап',
  text: 'Текст комментария',
  meterNumber: 'Номер пу',
  letterDetails: 'Детали письма',
  declarerFio: 'Фио заявителя',
  phoneNumber: 'Номер телефона',
  sapNumber: 'Номер САП',
  type: 'Тип',
  res: 'РЭС',
  id: 'ID жалобы',
  inputId: 'ID ввода',
  pes: 'ПЭС',
  responsibleId: 'Ответственный',
  executorId: 'Исполнитель',
  source: 'Источник жалобы',
  contractId: 'Контракт',
  createAt: 'Дата создания',
  classification: 'Классификация',
  actLowKsDate: 'Дата КС',
  actLowPaymentRequestNumber: 'Номер заявки на оплату',
  actLowRegisterDate: 'Дата реестра',
  actLowClassificationId: 'Классификация акта',
  actLowStatus: 'Статус акта',
  actLowStatusReasonId: 'Причина статуса акта',
};

const data: { id: string; dType: string }[] = [
  {
    id: 'stage',
    dType: 'TAG',
  },
  {
    id: 'address',
    dType: 'TEXT',
  },
  {
    id: 'text',
    dType: 'TEXT',
  },
  {
    id: 'meterNumber',
    dType: 'TEXT',
  },
  {
    id: 'letterDetails',
    dType: 'TEXT',
  },
  {
    id: 'account',
    dType: 'TEXT',
  },
  {
    id: 'declarerFio',
    dType: 'TEXT',
  },
  {
    id: 'type',
    dType: 'TAG',
  },
  {
    id: 'res',
    dType: 'TAG',
  },
  {
    id: 'pes',
    dType: 'TAG',
  },
  {
    id: 'responsibleId',
    dType: 'TAG',
  },
  {
    id: 'executorId',
    dType: 'TAG',
  },
  {
    id: 'contractId',
    dType: 'TAG',
  },
  {
    id: 'source',
    dType: 'TAG',
  },
  {
    id: 'id',
    dType: 'EQUEL_TEXT',
  },
  {
    id: 'sapNumber',
    dType: 'TEXT',
  },
  {
    id: 'phoneNumber',
    dType: 'TEXT',
  },
  {
    id: 'inputId',
    dType: 'EQUEL_TEXT',
  },
  {
    id: 'createAt',
    dType: 'DATE',
  },
  {
    id: 'classification',
    dType: 'TAG',
  },

  {
    id: 'actLowKsDate',
    dType: 'DATE',
  },
  {
    id: 'actLowPaymentRequestNumber',
    dType: 'TEXT',
  },
  {
    id: 'actLowRegisterDate',
    dType: 'DATE',
  },
  {
    id: 'actLowClassificationId',
    dType: 'TAG',
  },
  {
    id: 'actLowStatus',
    dType: 'TAG',
  },
  {
    id: 'actLowStatusReasonId',
    dType: 'TAG',
  },
];

export const useComplaintFilerData = () => {
  const isNotComplaintCustomer = useHasAnyRoleSet([
    [RolesEnum.ComplaintManager, RolesEnum.ComplaintEnergo],
  ]);

  const HIDDEN_FIELDS_FOR_CUSTOMER = new Set([
    'actLowKsDate',
    'actLowPaymentRequestNumber',
    'actLowRegisterDate',
    'actLowClassificationId',
    'actLowStatus',
  ]);

  const filterByRoleFields = (
    data: { id: string; dType: string }[],
    isNotComplaintCustomer: boolean
  ) => {
    //  задача 1536 времено закомментировано
    return data.filter(
      (elem) => !(HIDDEN_FIELDS_FOR_CUSTOMER.has(elem.id) && !isNotComplaintCustomer)
    );
  };
  return useMemo(() => {
    const filteredData = filterByRoleFields(data, isNotComplaintCustomer);

    return filteredData
      .map((elem) => ({
        ...elem,
        value: elem.id,
        label: FILTERS_LABELS[elem.id],
        isActive: false,
      }))
      .sort((a, b) => a.label.localeCompare(b.label));
  }, [isNotComplaintCustomer, data]);
};

export const typeCatalogOptions = [
  { value: 'COMPLAINT', label: 901 },
  { value: 'REPEATED_COMPLAINT', label: 902 },
  { value: 'APPEAL', label: 903 },
];

export const tariffOptions = [
  { value: 1, label: 1 },
  { value: 2, label: 2 },
  { value: 3, label: 3 },
];

export const defaultArgInput = {
  TAG: {
    argList: [FilterArgEnum.IN_LIST, FilterArgEnum.NOT_IN_LIST],
    defaultArgNum: 0,
  },
  TEXT: {
    argList: [
      FilterArgEnum.EQUAL,
      FilterArgEnum.NOT_EQUAL,
      FilterArgEnum.IN_LIST,
      FilterArgEnum.NOT_IN_LIST,
      FilterArgEnum.CONTAIN,
    ],
    defaultArgNum: 4,
  },
  EQUEL_TEXT: {
    argList: [FilterArgEnum.EQUAL, FilterArgEnum.NOT_EQUAL],
    defaultArgNum: 2,
  },
  DATE: {
    argList: [
      FilterArgEnum.LESS,
      FilterArgEnum.MORE,
      FilterArgEnum.EQUAL,
      FilterArgEnum.NOT_EQUAL,
      FilterArgEnum.RANGE,
    ],
    defaultArgNum: 2,
  },
  DOUBLE: {
    argList: [
      FilterArgEnum.LESS,
      FilterArgEnum.MORE,
      FilterArgEnum.IN_LIST,
      FilterArgEnum.NOT_IN_LIST,
    ],
    defaultArgNum: 0,
  },
  LONG: {
    argList: [
      FilterArgEnum.LESS,
      FilterArgEnum.MORE,
      FilterArgEnum.IN_LIST,
      FilterArgEnum.NOT_IN_LIST,
    ],
    defaultArgNum: 0,
  },
};

// FIXME:типизация
export const getComplaintsWithFilesFix = (data: any) => {
  return data?.content?.map((complaint: any) => ({
    ...complaint,
    files: complaint.files
      ? complaint.files.map((file: any) => ({
          ...file,
          id: file.base.id,
        }))
      : null,
  }));
};
export const getComplaintsWithFilesFixSingle = (complaintDTO: IComplaintItem) => {
  return {
    ...complaintDTO,
    files: complaintDTO.files
      ? complaintDTO.files.map((file: any) => ({
          ...file,
          id: file.base.id,
        }))
      : null,
  };
};

export const useComplaintIcon = (source?: EComplaintSource) => {
  return useMemo(() => {
    switch (source) {
      case EComplaintSource.ROS_SETI_MR:
        return imgMas;

      case EComplaintSource.CALL_CENTER:
        return imgCallCenter;

      default:
        return admin;
    }
  }, [source]);
};

export const useGalleryDataFromTask = () => {
  const selectedCompliant = useComplaintStore((state) => state.selectedCompliant);
  const [photosList, setPhotosList] = useState<IImage[]>([]);
  const [isGalleryLoading, setIsGalleryLoading] = useState(false);

  const loadGalleryData = useCallback(async () => {
    const taskId = selectedCompliant?.complaint.resultTaskId;
    if (!taskId) return;
    setPhotosList([]);
    setIsGalleryLoading(true);
    try {
      if (selectedCompliant.complaint.type !== 'ODPU') {
        const { data: replacementData } = await findAllResultByTaskIdAndType(
          taskId,
          TaskResultType.REPLACEMENT
        );
        setPhotosList((prev) => [...prev, ...collectUniqueImages(replacementData).filter(Boolean)]);
        // загрузка гарантии
        const { data: warrantyData } = await getWaranty(taskId);
        setPhotosList((prev) => [...prev, ...collectUniqueImages(warrantyData).filter(Boolean)]);
      } else {
        // тип ODPU — загружаем только соответствующие данные
        const { data: odpuData } = await findAllResultByTaskIdAndType(taskId, TaskResultType.ODPU);
        setPhotosList((prev) => [
          ...prev,
          ...collectUniqueImages({ data: odpuData }).filter(Boolean),
        ]);
      }
    } catch (error) {
      fetchCatch(error, 'Ошибка при загрузке информации о замене или гарантии');
    } finally {
      setIsGalleryLoading(false);
    }
  }, [selectedCompliant]);

  useEffect(() => {
    if (selectedCompliant?.complaint.base.id) {
      loadGalleryData();
    }
  }, [selectedCompliant?.complaint.base.id]);

  return { photosList, isGalleryLoading };
};

export const DEFAULT_ACCEPTED_EXTENSIONS_COMPLAINTS =
  '.pdf, .doc, .docs, .jpeg, .jpg, .png, .xls, .xlsx, .jfif, .jpe, .xlsm, .xlsb, .xlt';
export const NO_CALL_COUNT = 3;

const urlAPI = {
  [EComplaintType.ODPU]: 'odpu',
  [EComplaintType.ENERGY_SERVICE]: 'energy',
  [EComplaintType.UNKNOWN]: '',
};

export const getComplaintType = (complaintType: string | undefined | null): ComplaintTypeReturn => {
  switch (complaintType) {
    case EComplaintType.ODPU:
      return { url: urlAPI[EComplaintType.ODPU], type: EComplaintType.ODPU };
    case EComplaintType.ENERGY_SERVICE:
      return { url: urlAPI[EComplaintType.ENERGY_SERVICE], type: EComplaintType.ENERGY_SERVICE };
    default:
      console.error(`Unknown complaint type: ${complaintType}`);
      return { url: urlAPI[EComplaintType.UNKNOWN], type: EComplaintType.UNKNOWN };
  }
};
export const isTypeEnergyService = (type: EComplaintType | undefined) =>
  type === EComplaintType.ENERGY_SERVICE;
export const isTypeODPU = (type: EComplaintType | undefined) => type === EComplaintType.ODPU;

export const consumerTypeOptions = [
  { value: 'FIZ', label: 'Физическое лицо' },
  { value: 'YUR', label: 'Юридическое лицо' },
];

export const useComplaintValidationAct = (
  selectedStatus?: { value: string; label: string } | null
) => {
  const isClosedByKS = selectedStatus?.value === 'CLOSED_BY_KS';
  const isNoClosure = selectedStatus?.value === 'NO_CLOSURE';

  return useMemo(
    () => ({
      status: { required: 'Это поле обязательно' },
      classification: { required: isClosedByKS ? 'Это поле обязательно' : false },
      registerDate: { required: isClosedByKS ? 'Это поле обязательно' : false },
      ksDate: { required: isClosedByKS ? 'Это поле обязательно' : false },
      text: { required: isClosedByKS ? 'Это поле обязательно' : false },
      statusReason: { required: isNoClosure ? 'Это поле обязательно' : false },
    }),
    [isClosedByKS, isNoClosure]
  );
};
