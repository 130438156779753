import React, { useRef, useState } from 'react';
import LoadSpinner from '@/components/ui/LoadSpinner/LoadSpinner';
import { MultiSelect } from '../../../inputs/MultiSelectNew';
import { Tab, Tabs, Grid, Stack } from '@mui/material';
import { FormProvider, useForm } from 'react-hook-form';
import { SelectFilterOptType } from '../../../filter/MultySelectFilter';
import { ActionLogType, useActionLog } from '@/hooks/ActionLogHook';
import { getElementAtEvent, Chart } from 'react-chartjs-2';
import FilterButtons from '@/components/filter/FilterButtons/FilterButtons';
import {
  Chart as ChartJS,
  LinearScale,
  CategoryScale,
  BarElement,
  PointElement,
  LineElement,
  Legend,
  Tooltip,
  LineController,
  BarController,
} from 'chart.js';
import TableComponentResponsebleStatistic from './TableComponentResponsebleStatistic';
import SmallExtraTable from './SmallExtraTable/SmallExtraTable';
import { getDataFromFilter } from '../../../inputs/MultiSelectNew/utils';
import { generateDetailedInfo } from '../../../button/DetailedInfo/utils';
import { useSaveFilterButton } from '../../../filter/FilterButtons/utils';
import {
  STATISTICS_RESPONSEBLE_COLUMNS,
  STATISTICS_RESPONSEBLE_EXTRA_COLUMNS,
  STATISTICS_RESPONSEBLE_COLUMN_GROUPING_MODEL,
  STATISTICS_RESPONSEBLE_CHART_OPTIONS,
  getDataBar,
  PATH,
} from './utils';
import {
  StatisticsResponsebleFiltersWrapper,
  StatisticsResponsebleWrapper,
} from './StatisticsResponseble.styled';
import { useCatalog } from '@/hooks/CatalogHook';
import {
  ApiFindSingleContractor,
  GetCallCenterStatisticsXlsxByFilter,
} from '@/services/StatisticsService/CustomerInteractionStatisticsService/CustomerInteractionStatisticsService';
import {
  StatisticsContractorDto,
  StatisticsResponsebleDto,
} from '@/services/StatisticsService/CustomerInteractionStatisticsService/dto/CustomerInteractionStatisticsServiceResponseDto';
import { MainText } from '../../StatisticMain.styled';
import { useMobileSize } from '@/hooks/useMediaQuery/useMobileSize';
import { DateRange } from '@/components/inputs/DateRange';
import { LoadingButton } from '@mui/lab';
import { ApiGetWorkTypes } from '@/services/CallCenterService/CallCenterService';
import { CallCenterWorkTypeForSelect } from '@/dto/taskmap/Dto';
import { convertIntoFilter, convertTimeToShowInEye } from '../CallCenterStatistics/utils';
import moment from 'moment';
import { downLoadExcel } from '@/components/features/complaints/ComplaintReportButtons/utils';

ChartJS.register(
  LinearScale,
  CategoryScale,
  BarElement,
  PointElement,
  LineElement,
  Legend,
  Tooltip,
  LineController,
  BarController
);

interface Filter {
  contractIds?: string[];
  contractorIds?: string[] | null;
  path?: string;
  callRequestTypeIds?: string[];
  dateRange?: {
    startOn?: Date | string;
    endOn?: Date | string;
  };
}
interface StatisticsResponsebleProps {
  header: string;
  load: boolean;
  data: StatisticsResponsebleDto[];
  updateFunction: (filter: Filter) => void;
}

const SAVE_FILTER_KEY = 'StatisticsResponseble';

export const StatisticsResponseble: React.FC<StatisticsResponsebleProps> = ({
  header,
  load,
  data,
  updateFunction,
}) => {
  const { getFilters } = useSaveFilterButton(SAVE_FILTER_KEY);

  const barDataFiltered = data.filter((d) => d.contractorName !== 'Итого');
  const labels = barDataFiltered.map((c) => c.contractorName);
  const dataBar = getDataBar(labels, barDataFiltered);

  const chartRef: any = useRef();
  const [activeTab, setActiveTab] = React.useState(0);

  const handleTabChange = (event: any, newValue: number) => {
    setActiveTab(newValue);
    setShowExtraTable(false);
  };

  const onClickBar = (event: any) => {
    try {
      const elem = getElementAtEvent(chartRef?.current, event);
      if (!elem[0]?.datasetIndex) return; // 0 индекс для Liner ничего не делать
      fetchContractorTable(data[elem[0].index], formatPath(elem[0]?.datasetIndex.toString()));
      setShowExtraTable(true);
    } catch (error) {
      setShowExtraTable(false);
    }
  };

  const methods = useForm();

  const { handleSubmit } = methods;
  const { getContractCatalog } = useCatalog((state) => ({
    getContractCatalog: state.getContractCatalog,
  }));
  const contractListResponse = getContractCatalog();
  const [selectedContractList, setSelectedContractList] = React.useState<SelectFilterOptType[]>([]);
  const [contractorTableData, setContractorTableData] = React.useState<StatisticsContractorDto[]>(
    []
  );

  const [showExtraTable, setShowExtraTable] = React.useState<boolean>(false);
  const [isloadinTable, setIsLodingTable] = React.useState<boolean>(false);
  const [dataInRange, setDateInRange] = React.useState<number[] | Date[]>([
    new Date(Date.now() - 86400000 * 14),
    new Date(),
  ]);
  const [selectedWorkTypes, setSelectedWorkTypes] = React.useState<SelectFilterOptType[]>([]);

  const [isExcelLoading, setIsExcelLoading] = React.useState(false);
  const [workTypes, setWorkTypes] = React.useState<CallCenterWorkTypeForSelect[]>([]);
  const [filter, setFilter] = useState<Filter>({});

  const subTileText = useRef<null | string>(null);
  const { fetchCatch, addActionLog } = useActionLog();

  const onSubmitSearch = () => {
    const filter: Filter = {};
    if (selectedContractList) {
      filter.contractIds = getDataFromFilter(selectedContractList, 'value');
    }
    if (selectedWorkTypes) {
      filter.callRequestTypeIds = getDataFromFilter(selectedWorkTypes, 'value');
    }
    if (dataInRange?.length) {
      filter.dateRange = {};
      if (dataInRange[0]) {
        filter.dateRange.startOn = moment(dataInRange[0]).format('YYYY-MM-DD');
      }
      if (dataInRange[1]) {
        filter.dateRange.endOn = moment(dataInRange[1]).format('YYYY-MM-DD');
      }
    }
    setFilter(filter);
    updateFunction(filter);
  };

  const fetchContractorTable = (contractor: StatisticsResponsebleDto, path: string) => {
    const contractorFilter: Filter = {};
    if (contractor?.contractorName === 'Итого') {
      contractorFilter.contractorIds = data
        .filter((contractor) => contractor.contractorId)
        .map((c) => c.contractorId);
    }
    if (contractor.contractorName !== 'Итого' && !contractor.contractorId) {
      contractorFilter.contractorIds = null;
    }
    if (contractor.contractorName !== 'Итого' && contractor.contractorId) {
      contractorFilter.contractorIds = [contractor.contractorId];
    }
    if (selectedContractList.length) {
      contractorFilter.contractIds = getDataFromFilter(selectedContractList, 'value');
    }
    if (selectedWorkTypes) {
      contractorFilter.callRequestTypeIds = getDataFromFilter(selectedWorkTypes, 'value');
    }
    if (dataInRange?.length) {
      contractorFilter.dateRange = {};
      if (dataInRange[0]) {
        contractorFilter.dateRange.startOn = moment(dataInRange[0]).format('YYYY-MM-DD');
      }
      if (dataInRange[1]) {
        contractorFilter.dateRange.endOn = moment(dataInRange[1]).format('YYYY-MM-DD');
      }
    }

    if (path) {
      contractorFilter.path = path;
    }

    setIsLodingTable(true);
    ApiFindSingleContractor(contractorFilter)
      .then(({ data }) => setContractorTableData(() => [data]))
      .catch((error) => fetchCatch(error))
      .finally(() => setIsLodingTable(false));
  };

  React.useEffect(() => {
    ApiGetWorkTypes(['301,101,201,402'])
      .then(({ data }) => setWorkTypes(convertIntoFilter(data, { catalog: true })))
      .catch((err) => fetchCatch(err, 'Ошибка получения типов обращения'));
  }, []);

  const formatPath = (str: string) => {
    if (str === 'Всего получено заявок') {
      subTileText.current = 'Всего получено заявок';
      return 'all';
    }
    if (str === 'Установлено' || str === '1') {
      subTileText.current = 'Установлено';
      return 'installed';
    }
    if (str === 'ТКО после обращения' || str === '2') {
      subTileText.current = 'ТКО после обращения';
      return 'tkos';
    }
    if (str === 'Остаток' || str === '3') {
      subTileText.current = 'Остаток';
      return 'balance';
    }
    subTileText.current = '';
    return '';
  };

  function onCellClick(param: any) {
    if (param.colDef.groupPath[0].trim()) {
      const title: string = param?.colDef?.groupPath[0];
      const contractor: StatisticsResponsebleDto = param?.row;
      fetchContractorTable(contractor, formatPath(title));
      setShowExtraTable(true);
    }
  }

  const onResetClick = () => {
    setSelectedContractList([]);
    setSelectedWorkTypes([]);
  };

  const detailedInfo = generateDetailedInfo(
    [selectedContractList, 'Контракт'],
    [convertTimeToShowInEye(dataInRange), 'Время'],
    [selectedWorkTypes, 'Тип обращения']
  );

  const onSelectSavedFilter = (filterKey: string) => {
    const savedFilters = getFilters<SelectFilterOptType[]>(filterKey);
    savedFilters.selectedContractList && setSelectedContractList(savedFilters.selectedContractList);
    savedFilters.selectedWorkTypes && setSelectedWorkTypes(savedFilters.selectedWorkTypes);
  };

  const isMobile = useMobileSize();
  const isInRange = dataInRange.length !== 2;

  const hadleDownloadExcelByFilter = () => {
    setIsExcelLoading(true);
    GetCallCenterStatisticsXlsxByFilter(filter, PATH)
      .then((res) => {
        downLoadExcel(res, 'Отчет');
        addActionLog(ActionLogType.SUCCESS, `Отчет сгенерирован успешно`);
      })
      .catch((err) => fetchCatch(err))
      .finally(() => setIsExcelLoading(false));
  };
  const disableSearch = !selectedContractList.length || !selectedWorkTypes.length;

  return (
    <StatisticsResponsebleWrapper elevation={6}>
      <MainText className='MainTextStatistc'>{header}</MainText>
      <Tabs
        value={activeTab}
        onChange={handleTabChange}
        centered
      >
        <Tab label='Таблица' />
        <Tab
          label='Графики'
          disabled={!data.length}
        />
      </Tabs>

      <>
        <Grid style={{ display: activeTab === 0 ? 'block' : 'none' }}>
          <FormProvider {...methods}>
            <form onSubmit={handleSubmit(onSubmitSearch)}>
              <StatisticsResponsebleFiltersWrapper isMobile={isMobile}>
                <MultiSelect
                  label={'Контракт'}
                  value={selectedContractList}
                  onlyInListArgument
                  options={contractListResponse}
                  onChange={(sel: SelectFilterOptType[]) => setSelectedContractList(sel)}
                  sx={{ width: isMobile ? 300 : 200 }}
                />
                <MultiSelect
                  label={'Тип обращения'}
                  value={selectedWorkTypes}
                  onlyInListArgument
                  options={workTypes}
                  sx={{ width: isMobile ? 300 : 200 }}
                  onChange={(sel: SelectFilterOptType[]) => setSelectedWorkTypes(sel)}
                />
                <DateRange
                  label={'выбрать диапазон'}
                  isInRange={isInRange}
                  onChange={setDateInRange}
                  value={dataInRange}
                  argValue={8} // set inteval by default
                  showSelect={false} // hide select option
                  style={{ width: isMobile ? 300 : 200 }}
                />
                <Stack>
                  <LoadingButton
                    loading={isExcelLoading}
                    sx={{ width: isMobile ? 300 : 150 }}
                    disabled={isExcelLoading || disableSearch}
                    variant='outlined'
                    onClick={hadleDownloadExcelByFilter}
                  >
                    скачать отчет
                  </LoadingButton>
                </Stack>

                <FilterButtons
                  info={detailedInfo}
                  onSearchClick={() => handleSubmit(onSubmitSearch)}
                  onCloseClick={onResetClick}
                  disable={load || disableSearch}
                  saveFilter={{
                    filterType: SAVE_FILTER_KEY,
                    infoToSave: { selectedContractList },
                    selectCallback: onSelectSavedFilter,
                  }}
                />
              </StatisticsResponsebleFiltersWrapper>
            </form>
          </FormProvider>

          {load ? (
            <LoadSpinner />
          ) : (
            <TableComponentResponsebleStatistic
              data={data}
              columns={STATISTICS_RESPONSEBLE_COLUMNS}
              setShowExtraTable={setShowExtraTable}
              showExtraTable={showExtraTable}
              onCellClick={onCellClick}
              isloadinTable={isloadinTable}
              subTileText={subTileText}
              columnGroupingModel={STATISTICS_RESPONSEBLE_COLUMN_GROUPING_MODEL}
              extraColumns={STATISTICS_RESPONSEBLE_EXTRA_COLUMNS}
              contractorTableData={contractorTableData}
            />
          )}
        </Grid>
      </>

      {activeTab === 1 && (
        <>
          {showExtraTable && (
            <SmallExtraTable
              subTileText={subTileText?.current}
              setShowExtraTable={setShowExtraTable}
              contractorTableData={contractorTableData}
              extraColumns={STATISTICS_RESPONSEBLE_EXTRA_COLUMNS}
              loading={isloadinTable}
            />
          )}
          {!!data.length && (
            <div style={{ width: '95%', overflowX: 'auto', margin: 'auto' }}>
              <div
                style={
                  isMobile
                    ? { width: 600 }
                    : { width: labels.length > 25 ? '95%' : '75%', margin: 'auto' }
                }
              >
                <Chart
                  options={STATISTICS_RESPONSEBLE_CHART_OPTIONS}
                  type='bar'
                  data={dataBar}
                  onClick={onClickBar}
                  ref={chartRef}
                />
              </div>
            </div>
          )}
        </>
      )}
    </StatisticsResponsebleWrapper>
  );
};
