import React from 'react';
import { Box, Stack, Typography } from '@mui/material';
import { formatPhoneNumber, renderBorderColor } from '../utils/CallingMapUtils';
import { useState } from 'react';
import CallLogItem from './CallLogItem';
import { InstallationCallLogType } from '../types/types';
import { color } from '@/styles/mixins';
import PermPhoneMsgIcon from '@mui/icons-material/PermPhoneMsg';
import { SubscriberResponse } from '@/services/SubscribersService/dto/SubscribersServiceResponseDto';

type AbonentListItemProps = {
  abonent: SubscriberResponse;
  onClick: () => void;
  installationCallLogsForAbonent: InstallationCallLogType[];
};

const AbonentListItemNew = ({
  abonent,
  onClick,
  installationCallLogsForAbonent,
}: AbonentListItemProps) => {
  const [isOpenInstallCallLog, setIsOpenInstallCallLog] = useState(false);

  return (
    <>
      <Stack>
        <Box
          display={'flex'}
          gap={1}
          alignItems={'center'}
          justifyContent={'space-between'}
        >
          <Stack
            display={'flex'}
            flexDirection={'row'}
            gap={1}
          >
            <PermPhoneMsgIcon
              style={{
                color: renderBorderColor(
                  installationCallLogsForAbonent[installationCallLogsForAbonent?.length - 1]?.status
                ),
              }}
            />

            <Typography
              variant='body1'
              gutterBottom
              color={color('blue')}
              style={{ cursor: 'pointer' }}
              onClick={onClick}
            >
              {formatPhoneNumber(abonent.contactPhone, false)}
            </Typography>
          </Stack>

          <Typography
            variant='subtitle1'
            gutterBottom
          >
            {abonent.fio ?? 'Нет данных'}
          </Typography>
          <Typography
            variant='subtitle1'
            gutterBottom
            color={color('blue')}
            style={{ cursor: 'pointer' }}
            onClick={() => {
              setIsOpenInstallCallLog(!isOpenInstallCallLog);
            }}
          >
            Вызовы ({installationCallLogsForAbonent.length})
          </Typography>
        </Box>
        <Stack>
          {isOpenInstallCallLog &&
            [...installationCallLogsForAbonent].reverse().map((call) => {
              return (
                <CallLogItem
                  key={call.abonentId}
                  call={call}
                />
              );
            })}
        </Stack>
      </Stack>
    </>
  );
};

export default AbonentListItemNew;
