import React, { FC, useEffect, useState } from 'react';
import { FormProvider, useForm } from 'react-hook-form';
import {
  getTransformerVoltage,
  putTransformerVoltage,
} from '@/services/TransformerService/TransformerService';
import { useParams } from 'react-router-dom';
import { VoltageBlock } from './VoltageBlock';
import { Grid, Stack, Typography } from '@mui/material';
import SaveIcon from '@mui/icons-material/Save';
import { BoxShadow } from '../../ui/BoxShadow';
import { ActionLogType, useActionLog } from '@/hooks/ActionLogHook';
import { ExpandButton } from '@/components/button/ExpandButton/ExpandButton';
import { useCatalog } from '@/hooks/CatalogHook';
import { useTaskEditorOnlyShow } from '@/components/features/taskEditor/useTaskEditorOnlyShow';
import moment from 'moment';
import { ShortTextVoltage } from './Voltage.styled';
import { useGalleryData } from '@/components/Gallery/store';
import { getTransImageGroup } from '@/components/features/taskEditor/panel/utils';
import { TransformerVoltageDto } from '@/services/TransformerService/dto/TransformerResponseDto';
import FileDownloadRoundedIcon from '@mui/icons-material/FileDownloadRounded';
import { DownloadPopup, DownloadType } from '@/components/popups/DownloadPopup/DownloadPopup';

interface ITransformerVoltage {
  downloadId?: string;
  downloadType: DownloadType;
}

const TransformerVoltage: FC<ITransformerVoltage> = ({ downloadId = '', downloadType }) => {
  const onlyShow = useTaskEditorOnlyShow();
  const methods = useForm<TransformerVoltageDto>();
  const [open, setOpen] = useState<boolean>(false);
  const [isExist, setIsExist] = useState<boolean>(false);

  const [isOpenDownload, setIsOpenDownload] = useState<boolean>(false);

  const { fetchCatch, addActionLog } = useActionLog();
  const { handleSubmit } = methods;

  const { taskId } = useParams();

  const [data, setData] = useState<TransformerVoltageDto>();

  useEffect(() => {
    taskId &&
      getTransformerVoltage(taskId)
        .then((res) => {
          setData(res?.data);
          setIsExist(!!Object.values(res?.data).filter((el) => el)?.length);
        })
        .catch(({ response }) => {
          setIsExist(false);
        });
  }, []);
  const { getCatalogMapWithErr } = useCatalog((state) => ({
    getCatalogMapWithErr: state.getCatalogMapWithErr,
  }));

  const onSave = (e: TransformerVoltageDto) => {
    const { type, ...mountWithoutType } = data?.mount || {};
    const dataToSend = {
      ...data,
      ktnId: e.ktnId,
      mount: {
        ...mountWithoutType,
        ...e.mount,
        type:
          getCatalogMapWithErr('voltageTransformerType').get(e.mount.type || '') || e.mount.type,
      },
      unmount: {
        ...data?.unmount,
        ...e.unmount,
        type:
          getCatalogMapWithErr('voltageTransformerType').get(e.unmount.type || '') ||
          e.unmount.type,
      },
    };

    if (!onlyShow && taskId) {
      putTransformerVoltage({ id: taskId, data: dataToSend })
        .then(() => addActionLog(ActionLogType.SUCCESS, 'Успешно сохранено'))
        .catch((err) => fetchCatch(err));
    }
  };
  const setImageGroups = useGalleryData(({ setImageGroups }) => setImageGroups);

  const handleClick = (e: any) => {
    e.stopPropagation();
    setImageGroups(getTransImageGroup(data));
  };

  return isExist ? (
    <FormProvider {...methods}>
      <BoxShadow
        sx={{ marginTop: 2 }}
        onDoubleClick={handleClick}
      >
        <DownloadPopup
          type={downloadType}
          id={downloadId}
          isOpen={isOpenDownload}
          onClose={() => setIsOpenDownload(false)}
        />
        <Stack
          direction='row'
          justifyContent='space-between'
          alignItems='center'
        >
          <Typography>
            <ShortTextVoltage>
              {' '}
              {data?.smrDate ? moment(data.smrDate).format('DD.MM.yyyy') : 'Дата не указана'}{' '}
            </ShortTextVoltage>
            <ShortTextVoltage> {data?.montageName && data.montageName} </ShortTextVoltage>{' '}
            <ShortTextVoltage> ТН: {data?.mount?.type}</ShortTextVoltage>
            <ShortTextVoltage>
              {' '}
              KТН: {getCatalogMapWithErr('ktnType').get(data?.ktnId || '')}
            </ShortTextVoltage>
          </Typography>
          {!open ? (
            <div>
              <span style={{ fontWeight: 'bold' }}>Замена/Установка ТН</span>
              <ExpandButton
                onAction={() => setOpen(!open)}
                expand={open}
              />
            </div>
          ) : (
            <div style={{ marginLeft: 'auto', display: 'flex', alignItems: 'center' }}>
              <FileDownloadRoundedIcon
                fontSize={'small'}
                sx={{ mr: 1, color: 'gray', cursor: 'pointer' }}
                onClick={() => setIsOpenDownload(true)}
              />
            </div>
          )}
        </Stack>
        {open && (
          <Grid
            mt={2}
            container
            spacing={2}
          >
            {data && <VoltageBlock data={data} />}
            <Grid sx={{ ml: 'auto' }}>
              <SaveIcon
                onClick={handleSubmit(onSave)}
                sx={{
                  color: 'gray',
                  fontSize: 22,
                  cursor: onlyShow ? '' : 'pointer',
                  ml: 2,
                }}
              />
              <ExpandButton
                onAction={() => setOpen(!open)}
                expand={open}
              />
            </Grid>
          </Grid>
        )}
      </BoxShadow>
    </FormProvider>
  ) : null;
};

export default TransformerVoltage;
