import React from 'react';
import { Grid } from '@mui/material';

import { PopupOpenButton } from '@/components/popups/PopupOpenButton';
import { GroupButtons } from '@/components/TableMenu';
import { useDisabledMesManButtons } from '@/components/features/tasksBrowse/TasksBrowseToolbar/utils';
import { usePillarMapState } from '@/components/maps/pillarMap/store';
import shallow from 'zustand/shallow';
import { TagName, TagWrapper } from '@/components/Tags/Tag.styled';
import { RemoveTagIcon } from '@/components/Tags/RemoveTagIcon';

interface ToolbarProps {
  checkPopupError: boolean;
  // setShowCopyPopup: React.Dispatch<React.SetStateAction<boolean>>;
  // setShowCopyPopup1: React.Dispatch<React.SetStateAction<boolean>>;
  // setShowCopyPopup2: React.Dispatch<React.SetStateAction<boolean>>;
  // setShowCopyPopup3: React.Dispatch<React.SetStateAction<boolean>>;
  setShowAssignContractorPopup: React.Dispatch<React.SetStateAction<boolean>>;
  // setShowAssignContractPopup: React.Dispatch<React.SetStateAction<boolean>>;
  setShowReturnBackPopup: React.Dispatch<React.SetStateAction<boolean>>;
  setShowReAssignContractPopup: React.Dispatch<React.SetStateAction<boolean>>;
  setShowExecAndDatePopup: React.Dispatch<React.SetStateAction<boolean>>;
  setShowCancelPopup: React.Dispatch<React.SetStateAction<boolean>>;
  // setShowLoadingVectorDiagrams: React.Dispatch<React.SetStateAction<boolean>>;
  // setShowshowCreateColumn: React.Dispatch<React.SetStateAction<boolean>>;
  // setShowChiperPopup: React.Dispatch<React.SetStateAction<boolean>>;
  // setShowAddTagPopup: React.Dispatch<React.SetStateAction<boolean>>;
}

const Toolbar = ({
  checkPopupError,
  // setShowCopyPopup,
  // setShowCopyPopup1,
  // setShowCopyPopup2,
  // setShowCopyPopup3,
  setShowAssignContractorPopup,
  // setShowAssignContractPopup,
  setShowReturnBackPopup,
  setShowReAssignContractPopup,
  setShowCancelPopup,
  // setShowLoadingVectorDiagrams,
  // setShowshowCreateColumn,
  // setShowChiperPopup,
  // setShowAddTagPopup,
  setShowExecAndDatePopup,
}: ToolbarProps) => {
  const isDisabled = useDisabledMesManButtons();
  const { activePoint, geoPointsWithoutCoordinateCount, geoPointsCount, setActivePoint } =
    usePillarMapState(
      (state) => ({
        activePoint: state.activePoint,
        setActivePoint: state.setActivePoint,
        geoPointsCount: state.geoPointsCount,
        geoPointsWithoutCoordinateCount: state.geoPointsWithoutCoordinateCount,
      }),
      shallow
    );

  const clickHandler = () => {
    setActivePoint(undefined);
  };

  return (
    <div style={{ margin: '8px 10px -8px 10px' }}>
      <Grid
        container
        columnSpacing={2}
        alignItems='center'
      >
        <Grid item>
          <GroupButtons
            groupName={'работа с заявкой'}
            disabled={isDisabled}
          >
            <PopupOpenButton
              openHandler={setShowCancelPopup}
              checkError={!!checkPopupError}
            >
              отменить заявку
            </PopupOpenButton>
            {/*<PopupOpenButton*/}
            {/*  openHandler={setShowAssignContractPopup}*/}
            {/*  checkError={!!checkPopupError}*/}
            {/*>*/}
            {/*  Назначить контракт*/}
            {/*</PopupOpenButton>*/}
            <PopupOpenButton
              openHandler={setShowReturnBackPopup}
              checkError={!!checkPopupError}
            >
              возврат исполнителю
            </PopupOpenButton>
            {/*<PopupOpenButton*/}
            {/*  openHandler={setShowChiperPopup}*/}
            {/*  checkError={!!checkPopupError}*/}
            {/*>*/}
            {/*  Присвоить шифр*/}
            {/*</PopupOpenButton>*/}
            {/*<PopupOpenButton*/}
            {/*  checkError={!!checkPopupError}*/}
            {/*  openHandler={setShowCopyPopup1}*/}
            {/*>*/}
            {/*  создать жалобу*/}
            {/*</PopupOpenButton>*/}
            {/*<PopupOpenButton openHandler={setShowCopyPopup2}>*/}
            {/*  создать заявку на перепрограмирование*/}
            {/*</PopupOpenButton>*/}
            {/*<PopupOpenButton openHandler={setShowCopyPopup3}>*/}
            {/*  создать заявку в рамках сбсд*/}
            {/*</PopupOpenButton>*/}
            {/*<PopupOpenButton openHandler={setShowLoadingVectorDiagrams}>*/}
            {/*  загрузка векторных диаграмм*/}
            {/*</PopupOpenButton>*/}
            {/*<PopupOpenButton openHandler={setShowshowCreateColumn}>*/}
            {/*  создать заявку ФЗ-522 / Тех.Прис.*/}
            {/*</PopupOpenButton>*/}
            {/*<PopupOpenButton*/}
            {/*  openHandler={setShowAddTagPopup}*/}
            {/*  checkError={!!checkPopupError}*/}
            {/*>*/}
            {/*  Добавить тег*/}
            {/*</PopupOpenButton>*/}
          </GroupButtons>
        </Grid>
        <Grid item>
          <GroupButtons
            groupName={'назначить ответственных'}
            disabled={isDisabled}
          >
            <PopupOpenButton
              openHandler={setShowAssignContractorPopup}
              checkError={!!checkPopupError}
            >
              Назначить подрядчика
            </PopupOpenButton>
            <PopupOpenButton
              openHandler={setShowExecAndDatePopup}
              checkError={!!checkPopupError}
            >
              Назначить бригаду и плановую дату
            </PopupOpenButton>
            <PopupOpenButton
              openHandler={setShowReAssignContractPopup}
              checkError={!!checkPopupError}
            >
              Снять подрядчика
            </PopupOpenButton>
          </GroupButtons>
        </Grid>

        {activePoint?.number && (
          <Grid
            item
            sx={{ display: 'flex', alignItems: 'center' }}
          >
            Выбрана заявка №:
            <TagWrapper>
              <TagName>{activePoint.number}</TagName>
              <RemoveTagIcon onClick={clickHandler} />
            </TagWrapper>
          </Grid>
        )}

        <Grid
          item
          mr={'auto'}
        />
        {geoPointsCount != undefined && <Grid item>Найдено заявок: {geoPointsCount}</Grid>}
        {geoPointsWithoutCoordinateCount != undefined && (
          <Grid item>Без координат: {geoPointsWithoutCoordinateCount}</Grid>
        )}
      </Grid>
    </div>
  );
};

export default React.memo(Toolbar);
