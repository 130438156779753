import React, { useEffect, useState } from 'react';
import { Box, Button, CircularProgress, Grid, Stack } from '@mui/material';
import { IPopup } from '@/components/popups/Popup';
import SingleAbonentNumber from './SingleAbonentNumber';
import ActiveTabForm from '../form/ActiveTabForm';
import { CALL_TYPES, WEB_RTC_CALL_STATUS } from '../types/types';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import CancelIcon from '@mui/icons-material/Cancel';
import PhoneMissedIcon from '@mui/icons-material/PhoneMissed';
import PhoneForwardedIcon from '@mui/icons-material/PhoneForwarded';
import AddIcon from '@mui/icons-material/Add';
import HomeIcon from '@mui/icons-material/Home';
import { FormProvider, useForm } from 'react-hook-form';
import { useCallingMapState } from '../store';
import {
  addInstallationCallLogs,
  getSingleGeoTask,
  getSingleRrquestHisoryCallMap,
  getSingleTKOCallMap,
} from '@/services/CallingMapService/CallingMapService';
import { ActionLogType, useActionLog } from '@/hooks/ActionLogHook';
import { addSub } from '@/services/SubscribersService/SubscribersService';
import TkoForCallingMapContainer from './TkoForCallingMap';
import shallow from 'zustand/shallow';
import {
  ErrorTextPopupEnum,
  TKO_FIELDS,
  TKO_REQUIRED_RESULTS,
  fetchData,
  formatPhoneNumber,
} from '../utils/CallingMapUtils';
import { useWebRTCClient } from '@/components/maps/callingMap/hooks/useWebRTCClient';
import { useScreenHoldHook } from '@/hooks/ScreenHoldHook';
import RequestHistory from './RequestHisory';
import HistoryIcon from '@mui/icons-material/History';
import ConstructionIcon from '@mui/icons-material/Construction';
import { hardCancel } from '../utils/vats';
import DraggablePopup from '@/components/popups/DraggablePopup';
import TextSnippetOutlinedIcon from '@mui/icons-material/TextSnippetOutlined';
import CommonTaskInfo from './CommonTaskInfo';
import { IdPopup } from '@/components/features/taskEditor/panel/IdPopup';
import CreatTko from './CreatTko';
import moment from 'moment';
import { addTKO } from '@/services/TaskService/TaskService';
import { SubscriberResponse } from '@/services/SubscribersService/dto/SubscribersServiceResponseDto';

interface CallingMapPopupProps extends IPopup {
  abonent: SubscriberResponse | null;
}

export const activeTabValues: Record<string, string> = {
  AGREEMENT: 'Согласие',
  REFUSAL: 'Отказ',
  MISSED_CALL: 'Недозвон',
  CALLBACK: 'Перезвонить',
  ADD_NUMBER: 'Добавить номер',
  WRONG_ADDRESS: 'Ложная информация',
};

type FromTypes = {
  montageTime: string;
  executorId: string;
  comment: string;
  fio: string;
  contactPhone: string;
  missedCallReason: string;
  callTime?: number | Date | Date[] | null;
  tkoEnumId: string;
  commentTKO: string;
  executor: string | null;
  tkoDate: string;
};

const renderButtonIcon = (vallue: string) => {
  if (vallue === activeTabValues.AGREEMENT) return <CheckCircleIcon />;
  if (vallue === activeTabValues.REFUSAL) return <CancelIcon />;
  if (vallue === activeTabValues.MISSED_CALL) return <PhoneMissedIcon />;
  if (vallue === activeTabValues.CALLBACK) return <PhoneForwardedIcon />;
  if (vallue === activeTabValues.WRONG_ADRESS) return <HomeIcon />;
  if (vallue === activeTabValues.ADD_NUMBER) return <AddIcon />;
};

export const CallingMapPopup: React.FC<CallingMapPopupProps> = (props) => {
  useWebRTCClient();
  const { abonent } = props;
  const {
    singleGeoPoint,
    singleAbonent,
    taskId,
    setSingleGeoPoint,
    setOpenPopup,
    setOpenSnackBar,
    isOpenSnackBar,
    geoPoints,
    setGeoPoints,
    setUrgentPoints,
    toggleUrgentGeoPoins,
    urgentGeoPoints,
    webRtcCallStatus,
    setWebRtcCallStatus,
    tko,
    setTKO,
    requestHistory,
    setRequestHistory,
    setErrorMessegeTextPopup,
    currentUser,
  } = useCallingMapState(
    (state) => ({
      singleGeoPoint: state.singleGeoPoint,
      taskId: state.taskId,
      singleAbonent: state.singleAbonent,
      setSingleGeoPoint: state.setSingleGeoPoint,
      setOpenPopup: state.setOpenPopup,
      setOpenSnackBar: state.setOpenSnackBar,
      isOpenSnackBar: state.isOpenSnackBar,
      setGeoPoints: state.setGeoPoints,
      geoPoints: state.geoPoints,
      urgentGeoPoints: state.urgentGeoPoints,
      toggleUrgentGeoPoins: state.toggleUrgentGeoPoins,
      setUrgentPoints: state.setUrgentPoints,
      webRtcCallStatus: state.webRtcCallStatus,
      setWebRtcCallStatus: state.setWebRtcCallStatus,
      tko: state.tko,
      setTKO: state.setTKO,
      requestHistory: state.requestHistory,
      setRequestHistory: state.setRequestHistory,
      setErrorMessegeTextPopup: state.setErrorMessegeTextPopup,
      currentUser: state.currentUser,
    }),
    shallow
  );

  const [activeTab, setActiveTab] = React.useState<string | null>(null);
  const [isLoadingTKO, setIsLoadingTKO] = useState(false);
  const [isLoadingRequstHisoty, setIsLoadingRequstHisoty] = useState(false);
  const [openTkoForm, setOpenTkoForm] = useState(false);

  const methods = useForm<FromTypes>();
  const { addActionLog, fetchCatch } = useActionLog();
  const { setIsInProgress } = useScreenHoldHook();

  const handleAbonentAdding = async (sendData: any) => {
    const { data: addLogData } = await addSub(sendData.abonentInfo);
    const { data: logData } = await addInstallationCallLogs(sendData.filter);
    const { data: geoStatus } = await getSingleGeoTask(taskId);
    if (singleGeoPoint) {
      const updateGeoPoint = {
        ...singleGeoPoint,
        installationCallLogs: [...singleGeoPoint.installationCallLogs, logData],
        abonents: [...singleGeoPoint.abonents, ...addLogData],
        installationCallGeneralStatus: geoStatus,
      };
      setSingleGeoPoint(updateGeoPoint);

      const newGeoPoints = geoPoints.map((g) => {
        if (g.taskId === taskId) return { ...g, installationCallGeneralStatus: geoStatus };
        return g;
      });
      setGeoPoints(newGeoPoints);
    }
  };
  const handleLogAdding = async (sendData: any) => {
    const { data: logData } = await addInstallationCallLogs(sendData.filter);
    const { data: geoStatus } = await getSingleGeoTask(taskId);
    if (singleGeoPoint) {
      const updateGeoPoint = {
        ...singleGeoPoint,
        installationCallLogs: [...singleGeoPoint.installationCallLogs, logData],
        installationCallGeneralStatus: geoStatus,
      };
      setSingleGeoPoint(updateGeoPoint);
    }

    if (!toggleUrgentGeoPoins) {
      const newGeoPoints = geoPoints.map((g) => {
        if (g.taskId === taskId) return { ...g, installationCallGeneralStatus: geoStatus };
        return g;
      });
      setGeoPoints(newGeoPoints);
    } else {
      const newGeoPoints = urgentGeoPoints.map((g) => {
        if (g.taskId === taskId) return { ...g, installationCallGeneralStatus: geoStatus };
        return g;
      });
      setUrgentPoints(newGeoPoints);
    }
  };

  const onSubmit = (formData: any) => {
    const {
      montageTime,
      executorId,
      comment,
      fio,
      contactPhone,
      missedCallReason,
      montageDate,
      wrongInfo,
    } = formData;
    let filter;

    if (activeTab !== activeTabValues.ADD_NUMBER) {
      //  different types of calls
      switch (activeTab) {
        case CALL_TYPES.AGREEMENT:
          filter = {
            callAt: Date.now(),
            scheduledOn: montageDate,
            executorId,
            note: comment,
            status: activeTab,
            taskId,
            abonentId: singleAbonent?.id,
            scheduledPeriod: montageTime,
          };
          break;

        case CALL_TYPES.REFUSAL:
          filter = {
            note: comment,
            status: activeTab,
            taskId,
            abonentId: singleAbonent?.id,
            callAt: Date.now(),
          };

          break;

        case CALL_TYPES.CALLBACK:
          filter = {
            note: comment,
            status: activeTab,
            taskId,
            abonentId: singleAbonent?.id,
            scheduledPeriod: montageTime,
            scheduledOn: montageDate,
            callAt: Date.now(),
          };
          break;

        case CALL_TYPES.WRONG_ADDRESS:
          filter = {
            status: activeTab,
            wrongInfo,
            taskId,
            abonentId: singleAbonent?.id,
            callAt: Date.now(),
            note: comment ? (wrongInfo ? `${comment}&${wrongInfo}` : comment) : wrongInfo,
          };
          break;
        case CALL_TYPES.ADD_NUMBER:
          filter = {
            status: activeTab,
            taskId,
            contactPhone,
            fio,
            note: `${fio} номер: ${formatPhoneNumber(contactPhone)} `,
            abonentId: singleAbonent?.id,
            callAt: Date.now(),
          };
          break;

        case CALL_TYPES.MISSED_CALL:
          filter = {
            status: activeTab,
            taskId,
            abonentId: singleAbonent?.id,
            note: missedCallReason,
            callAt: Date.now(),
          };
          break;
        default:
          break;
      }

      if (filter && activeTab !== CALL_TYPES.ADD_NUMBER) {
        // Add installation call logs
        handleLogAdding({ filter })
          .then(() => {
            addActionLog(ActionLogType.SUCCESS, 'Успешно');
            setOpenPopup(false);
            setWebRtcCallStatus('default');
            methods.reset();
            setActiveTab('');
          })
          .catch((error) => {
            fetchCatch(error, 'Ошибка добавления в спискок звонков');
          })
          .finally(() => {
            setIsInProgress(false);
          });
      }
    }
    if (activeTab === CALL_TYPES.ADD_NUMBER) {
      // Handle ADD_NUMBER case
      let abonentInfo;
      if (taskId) {
        abonentInfo = {
          contactPhone,
          fio,
          taskId,
        };
        setIsInProgress(true);
        handleAbonentAdding({ abonentInfo, filter })
          .then(() => {
            addActionLog(ActionLogType.SUCCESS, 'Успешно');
            setOpenPopup(false);
            setWebRtcCallStatus('default');
            methods.reset();
            setActiveTab('');
          })
          .catch((err) => {
            fetchCatch(err, 'Ошибка добавления в спискок звонков');
          })
          .finally(() => {
            setIsInProgress(false);
          });
      }
    }
    methods.reset();
  };

  const handleButtonClick = async () => {
    // Добавляем async для использования await
    const formData = methods.getValues();

    const requiresTKO = activeTab && TKO_REQUIRED_RESULTS.includes(activeTab);
    const tkoCreated = formData?.tkoEnumId;

    if (requiresTKO && !tkoCreated) {
      setErrorMessegeTextPopup(ErrorTextPopupEnum.TKO_REQUIRED);
      setOpenSnackBar(true);
      return;
    }

    const filteredFormData = Object.fromEntries(
      Object.entries(formData).filter(([key]) => !TKO_FIELDS.includes(key))
    );

    if (!Object.keys(filteredFormData).length && webRtcCallStatus === 'ended') {
      setErrorMessegeTextPopup(ErrorTextPopupEnum.DEFAULT);
      setOpenSnackBar(true);
      return;
    }

    if (webRtcCallStatus === 'default') {
      setOpenPopup(false);
    }

    try {
      if (activeTab && showCreatTko) {
        // Ждем завершения handleTkoCreating
        await handleTkoCreating({
          executor: currentUser
            ? `${currentUser} - оператор карты обзвона`
            : 'оператор карты обзвона',
          tkoEnumId: formData.tkoEnumId,
          comment: formData.commentTKO,
          tkoDate: moment().format('YYYY-MM-DD'),
        });
        //  отправляем форму
        await methods.handleSubmit(onSubmit)();
      } else {
        //  сразу отправляем форму
        await methods.handleSubmit(onSubmit)();
      }
    } catch (error) {
      fetchCatch(error, 'Ошибка при обработке формы');
    } finally {
      hardCancel();
      setErrorMessegeTextPopup('');
    }
  };

  const renderButtonText = (webRtcCallStatus: WEB_RTC_CALL_STATUS) => {
    if (webRtcCallStatus === 'ended') return 'Сохранить и выйти';
    if (webRtcCallStatus === 'connected') return 'идет разговор';
    if (webRtcCallStatus === 'connecting') return 'идет соединение';
    if (webRtcCallStatus === 'default') return 'закрыть';
  };

  const disableSaveButton = webRtcCallStatus === 'connecting' || webRtcCallStatus === 'connected';
  const disableActiveTab = webRtcCallStatus === 'default';
  const showCreatTko = webRtcCallStatus === 'ended';

  useEffect(() => {
    let timeoutId: any;
    if (isOpenSnackBar) {
      timeoutId = setTimeout(() => {
        setOpenSnackBar(false);
      }, 3000);
    }
    return () => clearTimeout(timeoutId);
  }, [isOpenSnackBar]);

  const getTKO = async () => {
    await fetchData(getSingleTKOCallMap, setTKO, setIsLoadingTKO, tko, fetchCatch, taskId);
  };

  const getRequestHistory = async () => {
    await fetchData(
      getSingleRrquestHisoryCallMap,
      setRequestHistory,
      setIsLoadingRequstHisoty,
      requestHistory,
      fetchCatch,
      taskId
    );
  };

  useEffect(() => {
    setTKO([]);
    setRequestHistory([]);
  }, [taskId]);

  const handleTkoCreating = async (data: any) => {
    if (data.tkoEnumId && taskId && showCreatTko) {
      return addTKO(data, taskId).catch((err) => {
        fetchCatch(err, 'Ошибка создания ТКО');
        throw err;
      });
    }
    return Promise.resolve();
  };

  useEffect(() => {
    setOpenTkoForm(false);
  }, [activeTab]);

  return (
    <DraggablePopup {...props}>
      <Stack
        flexDirection={'row'}
        gap={1}
      >
        <IdPopup
          title={
            <sup onClick={() => getTKO()}>
              {isLoadingTKO ? <CircularProgress size={20} /> : <ConstructionIcon />}
            </sup>
          }
        >
          {tko.length ? (
            <TkoForCallingMapContainer
              techInspections={singleGeoPoint ? [...tko].sort((a, b) => b.eventOn - a.eventOn) : []}
            />
          ) : (
            <>нет ТКО</>
          )}
        </IdPopup>
        <IdPopup
          title={
            <sup onClick={() => getRequestHistory()}>
              {isLoadingRequstHisoty ? <CircularProgress size={20} /> : <HistoryIcon />}
            </sup>
          }
        >
          {requestHistory.length ? (
            <Stack
              overflow={'auto'}
              maxHeight={300}
            >
              {[...requestHistory]
                .sort((a, b) => b.createAt - a.createAt)
                .map((r, index) => {
                  return (
                    <RequestHistory
                      key={index}
                      history={r}
                    />
                  );
                })}
            </Stack>
          ) : (
            <>нет истории обращения</>
          )}
        </IdPopup>
        <IdPopup
          horizontalPosition={'left'}
          title={
            <sup>
              <TextSnippetOutlinedIcon />
            </sup>
          }
        >
          <Stack
            overflow={'auto'}
            maxHeight={300}
            minWidth={400}
          >
            <CommonTaskInfo
              taskId={taskId}
              singleGeoPoint={singleGeoPoint}
              showAllContent={false}
            />
          </Stack>
        </IdPopup>
      </Stack>
      <Box>
        <SingleAbonentNumber abonent={abonent} />
      </Box>
      <Box
        sx={{
          marginTop: 3,
          minWidth: 350,
        }}
      >
        <Grid
          container
          spacing={2}
        >
          {Object.entries(activeTabValues).map(([key, value]) => (
            <Grid
              item
              xs={4}
              key={key}
            >
              <Button
                disabled={disableActiveTab}
                onClick={() => setActiveTab(key)}
                fullWidth
                variant={activeTab === key ? 'contained' : 'outlined'}
                startIcon={renderButtonIcon(value)}
              >
                {value}
              </Button>
            </Grid>
          ))}

          <FormProvider {...methods}>
            {showCreatTko && activeTab && (
              <Grid
                item
                xs={12}
              >
                <CreatTko
                  openTkoForm={openTkoForm}
                  setOpenTkoForm={setOpenTkoForm}
                />
              </Grid>
            )}

            <Grid
              item
              xs={12}
            >
              {activeTab && <ActiveTabForm type={activeTab} />}
            </Grid>

            <Grid
              item
              xs={12}
            >
              <Button
                variant='contained'
                fullWidth
                type='submit'
                disabled={disableSaveButton}
                onClick={handleButtonClick}
              >
                {renderButtonText(webRtcCallStatus)}
              </Button>
            </Grid>
          </FormProvider>
        </Grid>
      </Box>
    </DraggablePopup>
  );
};
