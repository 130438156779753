import { MultiSelect } from '@/components/inputs/MultiSelectNew';
import { font } from '@/styles/mixins';
import { Accordion, AccordionSummary, styled } from '@mui/material';

export const DoughnutLegendWrapper = styled('div')({
  display: 'flex',
  flexDirection: 'row',
  flexWrap: 'wrap',
  gap: '40px',
  justifyContent: 'flex-start',
  marginTop: '60px',
  overflowY: 'auto',
  maxHeight: 'calc(100vh - 230px)',
  width: '100%',
  padding: 5,
  paddingLeft: '20px',

  '.MuiAccordion-root': {
    margin: 0,
    boxShadow: 'none',

    '&.Mui-expanded': {
      margin: 0,
    },

    '&::before': {
      backgroundColor: 'transparent',
    },
  },
});

interface LegendItemHeaderProps {
  background: string;
}

export const LegendItem = styled(Accordion)<LegendItemHeaderProps>(({ background }) => ({
  borderRadius: '20px',
  flex: 1,
  minWidth: '47%',
  maxWidth: '400px',
  display: 'flex',
  flexDirection: 'column',
  transition: 'border-color 0.3s ease',
  border: `1px solid ${background}`,
  fontFamily: 'Roboto',
  color: '#000000',
  lineHeight: '18px',
  alignItems: 'center',

  '&:first-of-type': {
    borderRadius: '20px',
  },

  '&:last-of-type': {
    borderRadius: '20px',
  },

  '&.Mui-expanded': {
    border: '1px solid #2C3E50',
    borderTopColor: 'transparent',
    borderRadius: '20px 20px 10px 10px',
  },
  '.MuiAccordionSummary-content': {
    margin: 0,
    display: 'flex',
    justifyContent: 'space-between',
    gap: '10px',

    '&.Mui-expanded': {
      margin: 0,
    },

    '.MuiAccordionSummary-root': {
      minHeight: 'unset !important',
    },
  },
  '.MuiCollapse-vertical': {
    width: '100%',
  },
}));

export const LegendItemHeader = styled(AccordionSummary)<LegendItemHeaderProps>(
  ({ background }) => ({
    padding: '20px 30px',
    backgroundColor: background,
    borderRadius: '20px',
    color: '#FFFFFF',
    fontWeight: 500,
    whiteSpace: 'nowrap',
    marginTop: '-1px',
    width: 'calc(100% + 2px)',
    gap: '10px',
  })
);

export const LegendItemTitle = styled('p')`
  ${font('title')}
  margin: 0
`;

export const ShowInfoTable = styled('table')({
  width: '100%',
});

export const ShowInfoTableBody = styled('tbody')({
  width: '100%',
});

export const ShowInfoBody = styled('div')({
  padding: '12px',
  paddingRight: '0px',
  background: 'white',
  paddingTop: '10px',
  borderBottomLeftRadius: '10px',
  borderBottomRightRadius: '10px',
});

export const ShowInfoTableTr = styled('tr')({
  paddingRight: '10px',
  whiteSpace: 'nowrap',
});

export const ShowInfoTableTd = styled('td')`
  ${font('mainText')}
  padding-right: 10px;
  white-space: nowrap;
  padding-bottom: 5px;
`;

export const StatisticMultiSelect = styled(MultiSelect)`
  width: 260px;
`;

export const DoughnutTooltipHeader = styled('td')({
  backgroundColor: 'white',
  width: '100%',
  display: 'flex',
  justifyContent: 'space-between',
  padding: '12px',
  borderTopLeftRadius: '10px',
  borderTopRightRadius: '10px',
  fontWeight: '600',
});

export const SwitchWrapper = styled('div')({
  display: 'flex',
  marginLeft: '100px',
  gap: '50px',
});

export const SelectWrapper = styled('div')({
  display: 'flex',
  flex: 1,

  '& > *': {
    width: '50%',
  },
});
