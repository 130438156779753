import { TablePagination } from '@mui/material';
import { GridRowParams, ruRU } from '@mui/x-data-grid';
import React from 'react';
import { arrowLabel } from '../../tasksBrowse/utils';
import { useOdpuManagmentCompanyColumns } from './utils';
import { OdpuManagementCompanyDataGrid } from './OdpuManagementCompanyCatalog.styled';
import { useOdpuManagementCompanyStore } from '../store';
import shallow from 'zustand/shallow';
import { ManagementCompanyResponseDto } from '../api/dto/ManagementCompanyResponseDto';

const OdpuManagementCompanyCatalogTable = () => {
  const {
    editCompany,
    companiesData,
    loadingCompaniesData,
    page,
    rowsPerPage,
    setPage,
    setRowsPerPage,
    count,
  } = useOdpuManagementCompanyStore(
    (state) => ({
      count: state.count,

      editCompany: state.editCompany,
      companiesData: state.companiesData,
      loadingCompaniesData: state.loadingCompaniesData,
      page: state.page,
      rowsPerPage: state.rowsPerPage,
      setPage: state.setPage,
      setRowsPerPage: state.setRowsPerPage,
    }),
    shallow
  );

  const columns = useOdpuManagmentCompanyColumns();
  const handleDoubleClick = (gridRow: GridRowParams<ManagementCompanyResponseDto>) => {
    editCompany(gridRow.row);
  };

  const handleRowPerPageChange = (
    event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
  ) => {
    setPage(0);
    setRowsPerPage(event.target.value as unknown as number);
  };

  const handlePageChange = (_event: React.MouseEvent<HTMLButtonElement> | null, page: number) => {
    setPage(page);
  };
  return (
    <>
      <div style={{ height: '70svh', width: '100%' }}>
        <OdpuManagementCompanyDataGrid
          checkboxSelection
          localeText={ruRU.components.MuiDataGrid.defaultProps.localeText}
          disableColumnFilter
          onRowDoubleClick={handleDoubleClick}
          disableSelectionOnClick
          disableColumnMenu
          loading={loadingCompaniesData}
          rowsPerPageOptions={[5, 10, 25, 50, 100]}
          columns={columns}
          rows={companiesData}
          components={{
            Pagination: () => (
              <>
                <TablePagination
                  count={count}
                  page={page}
                  rowsPerPage={rowsPerPage}
                  onRowsPerPageChange={handleRowPerPageChange}
                  onPageChange={handlePageChange}
                  rowsPerPageOptions={[5, 10, 25, 50, 100]}
                  labelRowsPerPage={'Заявок на странице:'}
                  getItemAriaLabel={arrowLabel}
                />
              </>
            ),
          }}
        />
      </div>
    </>
  );
};

export default OdpuManagementCompanyCatalogTable;
