import { Button, Typography } from '@mui/material';
import React, { FC, useState } from 'react';
import { usePNRStore } from '../../store';
import { ApiUpdateHouseEntranceEnumeration } from '@/services/YodaRestService/YodaRestService';
import { addSuccessActionLog, syncErrorCatch, useActionLog } from '@/hooks/ActionLogHook';
import CommonModal from '@/components/common/CommonModal/CommonModal';
import { LoadingButton } from '@mui/lab';
import {
  EntranceEnumerationModalButtonsWrapper,
  EntranceEnumerationModalInner,
  EntranceEnumerationModalInputLabel,
  EntranceEnumerationModalInputRow,
  EntranceEnumerationModalTextField,
} from './EntranceEnumerationModal.styled';
import shallow from 'zustand/shallow';
import { generateRange } from './utils';

interface IEntranceEnumerationModal {
  open: boolean;
  setOpen: (open: boolean) => void;
}

export const EntranceEnumerationModal: FC<IEntranceEnumerationModal> = ({ open, setOpen }) => {
  const [isLoading, setIsLoading] = useState(false);

  const {
    houseData,
    entranceIdx,
    setLayoutDataFromBackend,
    selectedIndicator,
    setSelectedIndicator,
  } = usePNRStore(
    (state) => ({
      houseData: state.houseData,
      entranceIdx: state.entranceIdx,
      setLayoutDataFromBackend: state.setLayoutDataFromBackend,
      selectedIndicator: state.selectedIndicator,
      setSelectedIndicator: state.setSelectedIndicator,
    }),

    shallow
  );

  const { fetchCatch } = useActionLog();

  const [startWithNumber, setStartWithNumber] = React.useState<string>('');
  const [endWithNumber, setEndWithNumber] = React.useState<string>('');

  const clearAndCloseModal = () => {
    setOpen(false);
  };

  React.useEffect(() => {
    if (entranceIdx && entranceIdx.length > 0) {
      updateValues(Math.min(...entranceIdx).toString());
    }
  }, [entranceIdx]);

  const saveAndCloseModal = () => {
    if (startWithNumber && houseData) {
      setIsLoading(true);

      ApiUpdateHouseEntranceEnumeration(houseData.houseId, startWithNumber)
        .then(({ data }) => {
          const newEntrances = generateRange(startWithNumber, endWithNumber);
          const entranceIdxPosition = entranceIdx.indexOf(selectedIndicator);
          setSelectedIndicator(newEntrances[entranceIdxPosition]);
          setOpen(false);
          setLayoutDataFromBackend(data);
          addSuccessActionLog('Успешная смена нумерации подъездов');
        })
        .catch((error) => fetchCatch(error, 'Ошибка сохранения данных'))
        .finally(() => setIsLoading(false));
    } else {
      syncErrorCatch('Введите значение');
    }
  };

  const updateValues = (startWithValue: string) => {
    if (parseInt(startWithValue) < 1) startWithValue = '1';
    setStartWithNumber(startWithValue);
    const firstNumber = parseInt(startWithValue);
    if (!isNaN(firstNumber)) {
      if (houseData && houseData.entranceCount) {
        setEndWithNumber((firstNumber + (houseData.entranceCount - 1)).toString());
      } else {
        syncErrorCatch('Нет данных о количестве подъездов');
      }
    } else {
      syncErrorCatch('Введите числовое значение');
    }
  };

  return (
    <>
      <CommonModal
        open={open}
        onClose={clearAndCloseModal}
      >
        <EntranceEnumerationModalInner>
          <Typography variant='h6'>Внесите изменения</Typography>

          <EntranceEnumerationModalInputLabel>Номера подъездов</EntranceEnumerationModalInputLabel>
          <EntranceEnumerationModalInputRow>
            <EntranceEnumerationModalInputLabel>с</EntranceEnumerationModalInputLabel>
            <EntranceEnumerationModalTextField
              value={startWithNumber}
              variant='standard'
              onChange={(e) => updateValues(e.target.value)}
              type='number'
            />
            <EntranceEnumerationModalInputLabel>по</EntranceEnumerationModalInputLabel>
            <EntranceEnumerationModalTextField
              disabled
              value={endWithNumber}
              variant='standard'
            />
          </EntranceEnumerationModalInputRow>

          <EntranceEnumerationModalButtonsWrapper>
            <LoadingButton
              variant='contained'
              onClick={saveAndCloseModal}
              disabled={isNaN(parseInt(startWithNumber))}
              loading={isLoading}
            >
              Сохранить
            </LoadingButton>
            <Button onClick={clearAndCloseModal}>Отмена</Button>
          </EntranceEnumerationModalButtonsWrapper>
        </EntranceEnumerationModalInner>
      </CommonModal>
    </>
  );
};
