import React, { useEffect, useState } from 'react';
import { MainFilter } from '@/components/features/tasksBrowse/MainFilter/MainFilterDynamic';
import { DataTypeConvert } from '@/components/inputs/DynamicFilter/utils';
import { converToNumber } from '@/components/statistics/StatisticWithDateForRP/utils';
import { IQureFilter } from '@/components/statistics/StatisticWithDateForRP/types';
import { usePillarMapFilter } from '@/components/maps/pillarMap/filter/store';
import { FilterArgEnum } from '@/components/inputs/ArgumentSelectNew/types';
import { getPillarGeoPoints } from '@/services/PillarMapService/PillarMapService';
import { usePillarMapState } from '@/components/maps/pillarMap/store';
import { useScreenHoldHook } from '@/hooks/ScreenHoldHook';
import { ApiGetRequestsCount } from '@/services/YodaRestService/YodaRestService';
import { fetchCatch } from '@/hooks/ActionLogHook';
import { converter, FilterVariants } from '@/components/features/tasksBrowse/utils';
import { HelpFilter } from '@/components/features/tasksBrowse/HelpFilter/HelpFilter';

const Filter = () => {
  const [activeFilters, setActiveFilters] = useState<any>({});
  const [first, setFirst] = useState(true);
  const { setIsInProgress } = useScreenHoldHook();

  const [anchorEl, setAnchorEl] = React.useState<HTMLButtonElement | null>(null);
  const [filterParamsToSend, setFilterParamsToSend] = useState<IQureFilter[]>([]);
  const callBackFn = (queryFields: any, filterSetting: DataTypeConvert[]) => {
    const objectKeys = Object.keys(queryFields);
    const kyes = filterSetting.map((el: any) => el.value);
    objectKeys.forEach((key) => {
      if (!kyes.includes(key)) {
        delete queryFields[key];
      }
    });
    const result = Object.keys(queryFields)
      .filter(
        (item) =>
          queryFields[item]?.values?.length && queryFields[item]?.values.some((el: any) => el)
      )
      .map((el) => ({
        name: el,
        arg: queryFields[el]?.arg,
        values: queryFields[el]?.values.map((val: string) =>
          converToNumber(queryFields[el]?.type) ? +val : val
        ),
        type: queryFields[el]?.type,
      }));
    setFilterParamsToSend(result);
  };

  const getFiltreItems = () => {
    const { polygon, setGeoPoints, setGeoPointsCount, setGeoPointsWithoutCoordinateCount } =
      usePillarMapState.getState();

    getPillarGeoPoints({ filter: activeFilters, polygon: polygon })
      .then(({ data }) => {
        setGeoPoints(
          data.filter(
            (elem) =>
              elem.status == 'NEW' ||
              elem.status == 'ASSIGNED' ||
              elem.status == 'RETURNED' ||
              elem.status == 'IN_WORK' ||
              elem.status == 'CHECK' ||
              elem.status == 'COMPLETED'
          )
        );
      })
      .catch((error) => {
        fetchCatch(error, 'Ошибка поиска');
        setGeoPoints([]);
      })
      .finally(() => {
        const { setActivePoint } = usePillarMapState.getState();
        setActivePoint();
        setIsInProgress(false);
      });
    ApiGetRequestsCount(activeFilters)
      .then(({ data }) => {
        setGeoPointsCount(data);
      })
      .catch((error) => fetchCatch(error));
    ApiGetRequestsCount({ ...activeFilters, isGeoPointNotExists: true })
      .then(({ data }) => {
        setGeoPointsWithoutCoordinateCount(data);
      })
      .catch((error) => fetchCatch(error));
  };

  useEffect(() => {
    if (!first) {
      setIsInProgress(true);
      getFiltreItems();
    }
  }, [activeFilters]);

  const onSubmit = (variant: FilterVariants) => {
    let fixedValues;
    const o = usePillarMapFilter.getState();

    if (variant === FilterVariants.SECOND) {
      fixedValues = converter({ o, variant });
    }
    if (variant === FilterVariants.MAIN) {
      fixedValues = filterParamsToSend?.reduce(
        (res, current) => {
          if (current.name === 'accountingNum') {
            if (
              current.arg === FilterArgEnum.IN_LIST ||
              current.arg === FilterArgEnum.NOT_IN_LIST
            ) {
              // eslint-disable-next-line @typescript-eslint/ban-ts-comment
              // @ts-ignore
              const str = current.values[0] as string;
              return {
                ...res,
                accountingNumList: str.split(','),
                [`${current.name}Arg`]: current.arg,
              };
            }
            return {
              ...res,
              accountingNumList: current.values,
              [`${current.name}Arg`]: current.arg,
            };
          } else {
            let tmp;
            if (current.type === 'DATE') {
              tmp = current.values;
            } else if (current.type === 'TAG') {
              tmp = current.values.map((elem) => elem.value);
            } else if (
              (current.type === 'TEXT' &&
                (current.arg === FilterArgEnum.NOT_IN_LIST ||
                  current.arg === FilterArgEnum.IN_LIST)) ||
              current.name === 'existingPuNum'
            ) {
              // eslint-disable-next-line @typescript-eslint/ban-ts-comment
              // @ts-ignore
              const str = current.values[0] as string;
              tmp = str.split(',');
            } else if (current.name === 'tpName' || current.name === 'cipher') {
              tmp = [current.values[0]];
            } else {
              tmp = current.values[0];
            }

            if (
              current.name === 'electricNetworksEnterpriseId' ||
              current.name === 'cipher' ||
              current.name === 'tpName' ||
              current.name === 'odpuStage' ||
              current.name === 'regionalElectricNetworkId' ||
              current.name === 'ppoState'
            ) {
              return {
                ...res,
                [`${current.name}List`]: tmp,
                [`${current.name}Arg`]: current.arg,
              };
            }
            if (current.name === 'tagList') {
              return {
                ...res,
                [`${current.name}`]: tmp,
                [`tagsArg`]: current.arg,
              };
            }
            return {
              ...res,
              [current.name]: tmp,
              [`${current.name}Arg`]: current.arg,
            };
          }
        },
        {
          isShowingNotRelevant: o.isShowingNotRelevant,
          onlyWithCallCenter: o.onlyWithCallCenter,
          onlyWithChanges: o.onlyWithChanges,
          onlyWithCreationConfirmation: o.onlyWithCreationConfirmation,
          onlyWithTko: o.onlyWithTko,
        }
      );
    }
    setActiveFilters(fixedValues);
    setFirst(false);
  };

  return (
    <div>
      <MainFilter
        // onSubmit={onSubmit}
        onSubmit={() => onSubmit(FilterVariants.MAIN)}
        isLocked={Boolean(anchorEl)}
        callBackFn={callBackFn}
        zustandStoreHook={usePillarMapFilter}
        saveFilterKey={'PillarMapFilter'}
      >
        <HelpFilter
          anchorEl={anchorEl}
          setAnchorEl={setAnchorEl}
          onSubmit={() => onSubmit(FilterVariants.SECOND)}
        />
      </MainFilter>{' '}
    </div>
  );
};

export default Filter;
