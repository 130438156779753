import { Grid, Stack, Skeleton } from '@mui/material';

const ComplaintCommentSkeleton = () => {
  return (
    <>
      <Stack sx={{ mb: 2 }}>
        <Grid
          container
          spacing={1}
        >
          <Grid
            item
            xs={6}
          >
            <Skeleton
              variant='text'
              width='60%'
              height={24}
            />
          </Grid>
          <Grid
            item
            xs={6}
          >
            <Skeleton
              variant='text'
              width='60%'
              height={24}
              sx={{ ml: 'auto' }}
            />
          </Grid>
          <Grid
            item
            xs={12}
          >
            <Skeleton
              variant='text'
              height={24}
            />
            <Skeleton
              variant='text'
              width='80%'
              height={24}
            />
          </Grid>
        </Grid>
      </Stack>
    </>
  );
};
export default ComplaintCommentSkeleton;
