import { Box, Grid, Stack, Typography } from '@mui/material';
import { BoxShadow } from '@/components/ui/BoxShadow';
import { ExpandButton } from '@/components/button/ExpandButton/ExpandButton';
import { ModeViewerResult } from '@/components/features/taskEditor/types';
import React, { FC, useState } from 'react';
import { useNetworkStore } from './store';
import { TextField } from '@/components/fixUI';
import { useFormContext } from 'react-hook-form';

interface DiscontinuedProps {
  networkId: string;
  hookFormStr: string;
}

export const Discontinued: FC<DiscontinuedProps> = ({ networkId, hookFormStr }) => {
  const { register } = useFormContext();
  const { getConsumableEquipment, consumableEquipment, networkData } = useNetworkStore((store) => ({
    networkData: store.networkDataN,
    getConsumableEquipment: store.getConsumableEquipment,
    consumableEquipment: store.consumableEquipment,
    loading: store.loading,
  }));
  const [mode, setMode] = useState<ModeViewerResult>(ModeViewerResult.SMALL);
  const handleMode = (forMode: ModeViewerResult) => (isExpanded: boolean) => {
    if (forMode === ModeViewerResult.SMALL) {
      !consumableEquipment[networkId] && getConsumableEquipment(networkId);
      setMode(isExpanded ? ModeViewerResult.LARGE : ModeViewerResult.SMALL);
    } else if (forMode === ModeViewerResult.LARGE) {
      setMode(isExpanded ? ModeViewerResult.LARGE : ModeViewerResult.SMALL);
    }
  };

  return (
    <BoxShadow>
      {mode === ModeViewerResult.SMALL && (
        <Grid
          container
          alignItems='center'
          width='100%'
        >
          <Grid
            item
            xs={'auto'}
          >
            <Typography variant='h6'>Снятое с эксплуатации ОС</Typography>
          </Grid>
          <Grid
            item
            sx={{ ml: 'auto' }}
          >
            <Box sx={{ display: 'flex', justifyContent: 'flex-end', width: '100%' }}>
              <ExpandButton onAction={handleMode(ModeViewerResult.SMALL)} />
            </Box>
          </Grid>
        </Grid>
      )}
      {mode === ModeViewerResult.LARGE && (
        <Stack>
          <Grid
            container
            item
            direction='row'
            justifyContent='center'
            alignItems='center'
          >
            <Grid
              item
              xs={12}
              display='flex'
              justifyContent='space-between'
              alignItems='center'
              mb={2}
            >
              <Typography variant='h6'>Снятое с эксплуатации ОС</Typography>
            </Grid>
            <Grid
              container
              spacing={2}
              xs={12}
            >
              <Grid
                item
                xs={3}
              >
                <TextField
                  id='network-ex-mac'
                  label='MAC'
                  // disabled
                  variant='standard'
                  sx={{ width: '100%' }}
                  {...register(`${hookFormStr}.unMountInfo.mac`, {
                    value: networkData && networkData[networkId]?.unMountInfo?.mac,
                  })}
                />
              </Grid>
              <Grid
                item
                xs={3}
              >
                <TextField
                  id='network-ex-factory-num'
                  label='Заводской номер'
                  // disabled
                  variant='standard'
                  sx={{ width: '100%' }}
                  {...register(`${hookFormStr}.unMountInfo.number`, {
                    value: networkData && networkData[networkId]?.unMountInfo?.number,
                  })}
                />
              </Grid>
              <Grid
                item
                xs={3}
              >
                <TextField
                  id='network-ex-imei'
                  label='IMEI'
                  // disabled
                  variant='standard'
                  sx={{ width: '100%' }}
                  {...register(`${hookFormStr}.unMountInfo.imei`, {
                    value: networkData && networkData[networkId]?.unMountInfo?.imei,
                  })}
                />
              </Grid>
            </Grid>
            <Grid
              gap={1}
              item
              display='flex'
              ml={'auto'}
            >
              <ExpandButton
                expand={mode === ModeViewerResult.LARGE}
                onAction={handleMode(ModeViewerResult.LARGE)}
              />
            </Grid>
          </Grid>
        </Stack>
      )}
    </BoxShadow>
  );
};
