import FilterButtons from '@/components/filter/FilterButtons/FilterButtons';
import {
  FilterArgEnum,
  SelectFilterOptType,
  SingleLineFilter,
} from '@/components/filter/MultySelectFilter';
import { BoxShadow } from '@/components/ui/BoxShadow';
import { useCatalog } from '@/hooks/CatalogHook';
import React, { useEffect, useMemo, useRef, useState } from 'react';
import { FormProvider, useForm } from 'react-hook-form';
import { taskMapToArray } from '../../tasksBrowse/utils';
import {
  OdpuManagementCompanyFilterForm,
  OdpuManagementCompanySelect,
} from './OdpuManagementCompanyCatalog.styled';
import { useKeyPress } from '@/hooks/usePressKey';
import { useOdpuManagementCompanyStore } from '../store';
import shallow from 'zustand/shallow';
import { useActionLog } from '@/hooks/ActionLogHook';
import {
  getOdpuManagementCompanies,
  getOdpuManagementCompaniesCount,
} from '../api/OdpuManagementCompanyService';
import { ManagementCompanyFilterRequestDto } from '../api/dto/ManagementCompanyRequestDto';

const DEFAULT_ARGS = [FilterArgEnum.EQUAL, FilterArgEnum.CONTAIN, FilterArgEnum.INSTALLED];
const FILTERS_LABELS = {
  legalAddress: 'Адрес',
  director: 'Руководитель',
  phoneNumber: 'Телефон',
  title: 'Наименование',
};

const OdpuManagementCompanyCatalogFilter = () => {
  const { fetchCatch } = useActionLog();

  const methods = useForm<ManagementCompanyFilterRequestDto>();
  const firstRenderRef = useRef(false);
  const getCatalogMapWithErr = useCatalog((state) => state.getCatalogMapWithErr);
  const odpuManagementCompany = useCatalog((state) => state.odpuManagementCompany);

  const {
    setCompaniesData,
    setLoadingCompaniesData,
    loadingCompaniesData,
    rowsPerPage,
    page,
    setCount,
  } = useOdpuManagementCompanyStore(
    (state) => ({
      setCompaniesData: state.setCompaniesData,
      setLoadingCompaniesData: state.setLoadingCompaniesData,
      loadingCompaniesData: state.loadingCompaniesData,
      rowsPerPage: state.rowsPerPage,
      page: state.page,
      setCount: state.setCount,
    }),
    shallow
  );

  const managingOrganizationOptions = useMemo(
    () => taskMapToArray(getCatalogMapWithErr('odpuManagementCompany')),
    [odpuManagementCompany]
  );

  const [selectedMangementCompany, setSelectedManagementCompany] = useState<SelectFilterOptType[]>(
    []
  );
  const { handleSubmit, reset } = methods;

  const onSubmit = (data: ManagementCompanyFilterRequestDto) => {
    const offset = page * rowsPerPage;
    const limit = rowsPerPage;
    const filterData = {
      ...data,
      ids: selectedMangementCompany.map((item) => parseInt(item.value)),
      idsArg: 0,
      offset,
      limit,
    };

    setLoadingCompaniesData(true);
    getOdpuManagementCompanies(filterData)
      .then(({ data }) => setCompaniesData(data))
      .catch(fetchCatch)
      .finally(() => setLoadingCompaniesData(false));

    getOdpuManagementCompaniesCount(filterData)
      .then(({ data }) => setCount(data))
      .catch(fetchCatch);
  };

  useKeyPress('Enter', handleSubmit(onSubmit));
  const setDefaultValue = () => {
    setSelectedManagementCompany([]);
    reset();
  };

  useEffect(() => {
    if (!firstRenderRef.current) return;
    handleSubmit(onSubmit)();
  }, [rowsPerPage, page]);

  useEffect(() => {
    firstRenderRef.current = true;
  }, [rowsPerPage, page]);

  return (
    <BoxShadow>
      <FormProvider {...methods}>
        <OdpuManagementCompanyFilterForm onSubmit={handleSubmit(onSubmit)}>
          <OdpuManagementCompanySelect
            label={FILTERS_LABELS.title}
            options={managingOrganizationOptions ?? []}
            value={selectedMangementCompany}
            argList={[FilterArgEnum.IN_LIST]}
            argValue={FilterArgEnum.IN_LIST}
            onChange={(value: SelectFilterOptType[]) => setSelectedManagementCompany(value)}
          />
          <div style={{ width: 400 }}>
            <SingleLineFilter
              registerName='legalAddress'
              defaultArg={FilterArgEnum.CONTAIN}
              label={FILTERS_LABELS.legalAddress}
              params={{ type: 'text' }}
              argList={DEFAULT_ARGS}
            />
          </div>
          <div>
            <SingleLineFilter
              registerName='director'
              defaultArg={FilterArgEnum.CONTAIN}
              label={FILTERS_LABELS.director}
              params={{ type: 'text' }}
              argList={DEFAULT_ARGS}
            />
          </div>
          <SingleLineFilter
            registerName='phoneNumber'
            defaultArg={FilterArgEnum.EQUAL}
            label={FILTERS_LABELS.phoneNumber}
            params={{ type: 'text' }}
            argList={DEFAULT_ARGS}
          />

          <FilterButtons
            onSearchClick={() => handleSubmit(onSubmit)}
            onCloseClick={setDefaultValue}
            disable={loadingCompaniesData}
          />
        </OdpuManagementCompanyFilterForm>
      </FormProvider>
    </BoxShadow>
  );
};

export default OdpuManagementCompanyCatalogFilter;
