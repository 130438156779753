import { Typography, Button } from '@mui/material';
import React, { FC, useState } from 'react';
import { usePNRStore } from '../store';
import { ApiResetHouseLayout } from '@/services/YodaRestService/YodaRestService';
import { addSuccessActionLog, useActionLog } from '@/hooks/ActionLogHook';
import { LoadingButton } from '@mui/lab';
import CommonModal from '@/components/common/CommonModal/CommonModal';
import { useParams } from 'react-router-dom';

interface IEntranceResetAlertModal {
  open: boolean;
  setOpen: (open: boolean) => void;
}

export const EntranceResetAlertModal: FC<IEntranceResetAlertModal> = ({ open, setOpen }) => {
  const { houseDtoId } = useParams();

  const [isLoading, setIsLoading] = useState(false);
  const setLayoutDataFromBackend = usePNRStore((state) => state.setLayoutDataFromBackend);

  const { fetchCatch } = useActionLog();

  const closeModal = () => setOpen(false);

  const resetEntranceData = () => {
    if (houseDtoId) {
      setIsLoading(true);
      ApiResetHouseLayout(houseDtoId)
        .then(({ data }) => {
          setOpen(false);
          setLayoutDataFromBackend(data);
          addSuccessActionLog('Успешный сброс распределения');
        })
        .catch((error) => fetchCatch(error, 'Ошибка сброса распределения'))
        .finally(() => setIsLoading(false));
    }
  };

  return (
    <CommonModal
      open={open}
      onClose={closeModal}
    >
      <Typography
        variant='h6'
        textAlign='center'
      >
        Ранее заполненные данные по подъездам будут удалены. Продолжить?
      </Typography>

      <div
        style={{
          display: 'flex',
          justifyContent: 'center',
          marginTop: 40,
          gap: 20,
        }}
      >
        <LoadingButton
          variant='contained'
          onClick={resetEntranceData}
          loading={isLoading}
        >
          OK
        </LoadingButton>
        <Button onClick={closeModal}>Отмена</Button>
      </div>
    </CommonModal>
  );
};
