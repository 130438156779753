import { useEffect, useState } from 'react';
import { Placemark, Polygon } from 'react-yandex-maps';
import { useCatalog } from '@/hooks/CatalogHook';
import { useZoning } from '../../hook/ZoningHook';
import { geoCoordinateToCoordinate, toColor, calculateCenter } from '../../utils/GeoUtils';
import shallow from 'zustand/shallow';
import { ZoningGroupResponse } from '@/services/ZoningService/dto/ZoningServiceResponseDto';

interface ZoningPolygonProps {
  zoningGroupResponse: ZoningGroupResponse;
}

export const ZoningPolygon = (props: ZoningPolygonProps) => {
  const { zoningGroupResponse } = props;
  const { updateItem, editingGroup } = useZoning();
  const { getGroupCatalog } = useCatalog(
    (state) => ({ getGroupCatalog: state.getGroupCatalog }),
    shallow
  );
  const [polygonRef, setPolygonRef] = useState<any>();
  const [polygonCenter, setPolygonCenter] = useState<number[]>();

  useEffect(() => {
    if (polygonRef?.editor) {
      update(polygonRef);
      addPolygonEvents(polygonRef);
      addKeyEvents(polygonRef);
    }
  }, [polygonRef]);

  useEffect(() => {
    if (editingGroup) {
      editingGroup.polygonRef.editor.startEditing();
      editingGroup.polygonRef.editor.startDrawing();
    } else if (zoningGroupResponse) {
      zoningGroupResponse.polygonRef?.editor.stopDrawing();
      zoningGroupResponse.polygonRef?.editor.stopEditing();
    }
  }, [editingGroup]);

  const addPolygonEvents = (polygonRef: any) => {
    // ref.editor.events.add("statechange", (event: any) => { });
    // polygonRef.editor.events.add('drawingstop', (event: any) => { });

    polygonRef.editor.events.add('change', (event: any) => {
      update(polygonRef);
    });
    polygonRef.editor.events.add('vertexdraw', (event: any) => {
      update(polygonRef);
    });
    polygonRef.editor.events.add('editingstop', (event: any) => {
      update(polygonRef);
    });
    polygonRef.editor.events.add('vertexadd', (event: any) => {
      update(polygonRef);
    });

    polygonRef.editor.events.add('vertexdragend', (event: any) => {
      update(polygonRef);
    });

    polygonRef.events.add('dragend', (event: any) => {
      polygonRef.editor.stopDrawing();
      update(polygonRef);
    });
  };

  const addKeyEvents = (polygonRef: any) => {
    function setPolygonEdirtable(ev: KeyboardEvent) {
      if (ev.key === 'Escape') {
        polygonRef.editor.stopDrawing();
        polygonRef?.editor.stopEditing();
      }
    }
    document.addEventListener('keydown', setPolygonEdirtable, false);
    return function cleanup() {
      document.removeEventListener('keydown', setPolygonEdirtable, false);
    };
  };

  const update = (polygonRef: any) => {
    updateItem(zoningGroupResponse?.groupId, polygonRef);
    const center = calculateCenter(polygonRef.geometry.getCoordinates());
    setPolygonCenter(center);
  };

  return (
    <>
      <Polygon
        instanceRef={(ref: any) => setPolygonRef(ref)}
        // instanceRef={(ref: any) => ref && setPolygon(ref)}
        geometry={geoCoordinateToCoordinate(zoningGroupResponse?.coordinateList) || []}
        properties={{
          iconCaption: getGroupCatalog()?.get(zoningGroupResponse?.groupId) || 'none',
        }}
        options={{
          editorDrawingCursor: 'crosshair',
          // editorMaxPoints: 5,
          fillColor: toColor(getGroupCatalog()?.get(zoningGroupResponse?.groupId) || 'none'),
          fillOpacity: 0.3,
          // Цвет обводки.
          strokeColor: toColor('#00000000'),
          // strokeColor: "#0000FF",
          drawOver: false,
          // Ширина обводки.
          strokeWidth: 2,
          draggable: false,
          // opacity: 0.4,
          outline: true,
          seAutoPanInDrawing: true,
        }}
      />
      {polygonCenter && polygonCenter.length > 0 && (
        <Placemark
          geometry={polygonCenter}
          properties={{
            iconContent: getGroupCatalog()?.get(zoningGroupResponse?.groupId) || 'none',
          }}
          options={{
            preset: 'islands#blueStretchyIcon',
            iconColor: 'yellow',
          }}
        />
      )}
    </>
  );
};
