import {
  filterStatisticByInstallPu,
  FilterStatisticReportByContractPerDay,
  FilterStatisticUrgentAndSuperUrgent,
  StatisticByInstallPuDto,
  StatisticDistributionDto,
  StatisticReportByContractPerDayDto,
  StatisticUrgentAndSuperUrgentTasksDto,
  TaskFilterParam,
} from '@/dto/taskmap/Dto';
import { ApiResponse } from '@/types/api';
import { HOST, YodaRestClient } from '@/services/YodaAuthService';
import {
  AsumbTaskStatisticsForCheckChartType,
  MeterChangeForRevisionData,
  SMRStatisticsTableItem,
  StatisticByInstallationLocationData,
} from './dto/RequestStatisticServiceResponseDto';
import {
  MeterChangeForRevisionFilterParam,
  SMRStatisticsFilter,
  StatisticActivationFilter,
  StatisticWithoutActivationDTO,
  StatisticWithoutActivationFilter,
} from './dto/RequestStatisticServiceRequestDto';

export async function GetASUMBTaskStatisticsCheckStatusByFilter(
  filter: TaskFilterParam
): ApiResponse<AsumbTaskStatisticsForCheckChartType> {
  const url = HOST + `/rest/statistics/asumb/check`;
  return YodaRestClient.post(url, filter);
}

export async function GetAttributesRp(): ApiResponse<any> {
  const url = HOST + '/rest/statistics/rp-report/attributes';
  return YodaRestClient.get(url);
}

export async function GetMeterChangeForRevisionByFilter(
  filter: MeterChangeForRevisionFilterParam
): ApiResponse<MeterChangeForRevisionData> {
  const url = HOST + `/rest/statistics/meter-change/revision`;
  return YodaRestClient.post(url, filter);
}

export async function GetSMRStatisticsTableByFilter(
  filter: SMRStatisticsFilter
): ApiResponse<SMRStatisticsTableItem[]> {
  const url = HOST + '/rest/statistics/smr';
  return YodaRestClient.post(url, filter);
}

export async function GetStatisticByInstallationLocation(
  contractIdList: any
): ApiResponse<StatisticByInstallationLocationData[]> {
  const url = HOST + '/rest/statistics/montage-place';
  return YodaRestClient.post(url, contractIdList);
}

export async function GetStatisticByInstallPu(
  filter: filterStatisticByInstallPu
): ApiResponse<StatisticByInstallPuDto[]> {
  const url = HOST + '/rest/statistics/montage-places-compare';
  return YodaRestClient.post(url, filter);
}

export async function GetStatisticDistribution(
  filter: any
): ApiResponse<StatisticDistributionDto[]> {
  const url = HOST + '/rest/statistics/distribution/contract/month';
  return YodaRestClient.post(url, filter);
}

export async function GetStatisticReportByContract(
  filter: FilterStatisticReportByContractPerDay
): ApiResponse<StatisticReportByContractPerDayDto[]> {
  const url = HOST + '/rest/statistics/execution/contract/day';
  return YodaRestClient.post(url, filter);
}

export async function GetStatisticUrgentAndSuperUrgentTasks(
  filter: FilterStatisticUrgentAndSuperUrgent
): ApiResponse<StatisticUrgentAndSuperUrgentTasksDto[]> {
  const url = HOST + '/rest/statistics/task-urgent';
  return YodaRestClient.post(url, filter);
}
export async function GetStatisticWithoutActivation(
  filter: StatisticWithoutActivationFilter
): ApiResponse<StatisticWithoutActivationDTO[]> {
  const url = HOST + '/rest/statistics/task/without-ks/breakdown';
  return YodaRestClient.post(url, filter);
}
export async function GetTasksIdsForStatisticActivation(
  filter: StatisticActivationFilter
): ApiResponse<string[]> {
  const url = HOST + '/rest/statistics/task/without-ks';
  return YodaRestClient.post(url, filter);
}
