import React, { FC } from 'react';
import fileFolder from '@/images/file/fileFolder.png';
import { FolderImg, FolderWrapper } from '@/components/features/drive/folder/Folder.styled';
import DownloadAllButton from '@/components/button/DownloadAllButton/DownloadAllButton';
import { useNavigate, useParams } from 'react-router-dom';
import { DownloadFiles, GetFiles } from '@/components/features/drive/api/DriveService';
import FileSaver from 'file-saver';
import { convertStr } from '../utils';
import { IconButton } from '@mui/material';
import DownloadIcon from '@mui/icons-material/Download';
import { useActionLog } from '@/hooks/ActionLogHook';
import { DriveItem } from '@/components/features/drive/utils';
import { useIsTouchDevice } from '@/hooks/useIsTouchDevice';

interface IFolder {
  item: DriveItem;
}

const Folder: FC<IFolder> = ({ item }) => {
  const isTouch = useIsTouchDevice();
  const { groupId, resGroupId } = useParams();
  const navigate = useNavigate();
  const { fetchCatch } = useActionLog();
  const handleClick = () =>
    navigate(`/registry/drive/${resGroupId}/${groupId}/${item.name}_${item.id}`);

  const clickHandler = () => {
    document.body.style.cursor = 'wait';
    groupId &&
      item.name &&
      GetFiles({ id: groupId.split('_')[1], sqlDate: convertStr(item.name) })
        .then((res) => {
          const ids = Object.keys(res.data).filter((elem) => elem != '');
          return DownloadFiles({
            energyCompanyId: groupId.split('_')[1],
            sqlDate: convertStr(item.name),
            meterChangeIds: ids,
          });
        })
        .then((r) => {
          const type = r.headers['content-type'];
          return new Blob([r.data], { type: type });
        })
        .then((file) => FileSaver.saveAs(file, `${item.name}.zip`))
        .catch((err) => {
          fetchCatch(err, 'Ошибка скачивания');
        })
        .finally(() => {
          document.body.style.cursor = 'default';
        });
  };

  return (
    <FolderWrapper onDoubleClick={!isTouch ? handleClick : undefined}>
      <FolderImg
        src={fileFolder}
        onClick={isTouch ? handleClick : undefined}
      />
      <div onClick={isTouch ? handleClick : undefined}>{item.name}</div>
      <IconButton
        onClick={clickHandler}
        title={'Скачать'}
        size='large'
      >
        <DownloadIcon color={'primary'} />
      </IconButton>
    </FolderWrapper>
  );
};

export default Folder;
