import React, { useState, useMemo } from 'react';
import LoadSpinner from '@/components/ui/LoadSpinner/LoadSpinner';
import { MultiSelect } from '../../../inputs/MultiSelectNew';
import { Button, Paper, Stack, Typography } from '@mui/material';
import { FormProvider, useForm } from 'react-hook-form';
import { SelectFilterOptType } from '../../../filter/MultySelectFilter';
import { CallCenterWorkTypeForSelect } from '@/dto/taskmap/Dto';
import { DateRange } from '@/components/inputs/DateRange';
import FilterButtons from '@/components/filter/FilterButtons/FilterButtons';
import { generateDetailedInfo } from '../../../button/DetailedInfo/utils';
import { getDataFromFilter } from '@/components/inputs/MultiSelectNew/utils';
import { useSaveFilterButton } from '../../../filter/FilterButtons/utils';
import { useCatalog } from '@/hooks/CatalogHook';
import { taskMapToArray } from '@/components/features/tasksBrowse/utils';
import { convertIntoFilter, convertTimeToShowInEye, downloadExcel } from './utils';
import { useActionLog } from '@/hooks/ActionLogHook';
import { downLoadExcel } from '@/utils/heplers';

import { CallCenterStatisticsFiltersWrapper } from './CallCenterStatistics.styled';
import CallCenterStatisticsTable, {
  CallCenterStatisticsTableProps,
} from '@/components/statistics/CustomerInteractionStatistics/CallCenterStatistics/CallCenterStatisticsTable/CallCenterStatisticsTable';
import DraggablePopup from '@/components/popups/DraggablePopup';
import { useStatisticTeamManagerVoormanAndExecutorAcces } from '../../hooks/useStatisticTeamManagerVoormanAndExecutorAcces';
import moment from 'moment';
import { CallCenterStatisticsFilter } from '@/services/StatisticsService/CustomerInteractionStatisticsService/dto/CustomerInteractionStatisticsServiceRequestDto';
import { ApiGetWorkTypes } from '@/services/CallCenterService/CallCenterService';
import { MainText } from '../../StatisticMain.styled';
import { useMobileSize } from '@/hooks/useMediaQuery/useMobileSize';
import { GetCallCenterStatisticsXlsxByFilter } from '@/services/StatisticsService/CustomerInteractionStatisticsService/CustomerInteractionStatisticsService';
import { LoadingButton } from '@mui/lab';
interface CallCenterStatisticsProps extends CallCenterStatisticsTableProps {
  header: string;
  load: boolean;

  updateFunction: (filter: CallCenterStatisticsFilter) => void;
}

const SAVE_FILTER_KEY = 'CallCenterStatistics';

export const CallCenterStatistics: React.FC<CallCenterStatisticsProps> = ({
  header,
  load,
  data,
  updateFunction,
}) => {
  const methods = useForm();
  const { getFilters } = useSaveFilterButton(SAVE_FILTER_KEY);
  const { fetchCatch } = useActionLog();

  const isTeamManagerVoormanAndExecutor = useStatisticTeamManagerVoormanAndExecutorAcces();
  const { handleSubmit } = methods;
  const { getContractCatalog } = useCatalog((state) => ({
    getContractCatalog: state.getContractCatalog,
  }));
  const contractListResponse = getContractCatalog();
  const [selectedContractList, setSelectedContractList] = React.useState<SelectFilterOptType[]>([]);
  const [selectedTaskTypeList, setSelectedTaskTypeList] = React.useState<SelectFilterOptType[]>([]);
  const [dataInRange, setDateInRange] = useState<number[] | Date[]>([
    new Date(Date.now() - 86400000 * 14),
    new Date(),
  ]);
  const [showOnlyTotals, setShowOnlyTotals] = useState(false);

  const { taskGroupMap, getGroupCatalog, taskType, getCatalogMapWithErr } = useCatalog((state) => ({
    getGroupCatalog: state.getGroupCatalog,
    taskGroupMap: state.taskGroupMap,
    taskType: state.taskType,
    getCatalogMapWithErr: state.getCatalogMapWithErr,
  }));
  const responsables = useMemo(() => taskMapToArray(getGroupCatalog()), [taskGroupMap]);

  const taskTypeOptions = useMemo(
    () => taskMapToArray(getCatalogMapWithErr('taskType')),
    [taskType]
  );

  const [selectedResponsables, setSelectedResponsables] = React.useState<SelectFilterOptType[]>([]);
  const [workTypes, setWorkTypes] = useState<CallCenterWorkTypeForSelect[]>([]);
  const [selectedWorkTypes, setSelectedWorkTypes] = React.useState<SelectFilterOptType[]>([]);
  const [filter, setFilter] = useState<CallCenterStatisticsFilter>({});
  const [isReadyToDownload, setIsReadyToDownload] = useState(false);
  const [isExcelLoading, setIsExcelLoading] = useState(false);

  React.useEffect(() => {
    ApiGetWorkTypes(['301,101,201,402'])
      .then(({ data }) => setWorkTypes(convertIntoFilter(data, { catalog: true })))
      .catch((err) => fetchCatch(err, 'Ошибка получения типов обращения'));
  }, []);

  const onSubmitSearch = () => {
    const filter: CallCenterStatisticsFilter = { contractorIds: null };

    if (selectedContractList) {
      filter.contractIds = getDataFromFilter(selectedContractList, 'value');
    }
    if (dataInRange?.length) {
      filter.dateRange = {};
      if (dataInRange[0]) {
        filter.dateRange.startOn = moment(dataInRange[0]).format('YYYY-MM-DD');
      }
      if (dataInRange[1]) {
        filter.dateRange.endOn = moment(dataInRange[1]).format('YYYY-MM-DD');
      }
    }
    if (selectedResponsables.length) {
      filter.contractorIds = getDataFromFilter(selectedResponsables, 'value');
    }
    if (selectedWorkTypes) {
      filter.callRequestTypeIds = getDataFromFilter(selectedWorkTypes, 'value');
    }
    if (selectedTaskTypeList) {
      filter.taskTypeList = getDataFromFilter(selectedTaskTypeList, 'value');
    }
    setFilter(filter);

    updateFunction(filter);
  };

  const onResetClick = () => {
    setSelectedContractList([]);
    setSelectedWorkTypes([]);
    setSelectedResponsables([]);
    setSelectedTaskTypeList([]);
    updateFunction({});
  };

  const isInRange = dataInRange.length !== 2;

  const detailedInfo = generateDetailedInfo(
    [selectedContractList, 'Контракт'],
    [convertTimeToShowInEye(dataInRange), 'Время'],
    [selectedWorkTypes, 'Тип обращения'],
    [selectedResponsables, 'Ответственный подрядчик'],
    [selectedContractList, 'Тип заявки']
  );

  const onSelectSavedFilter = (filterKey: string) => {
    const savedFilters = getFilters<SelectFilterOptType[]>(filterKey);
    savedFilters.selectedContractList && setSelectedContractList(savedFilters.selectedContractList);
    savedFilters.selectedResponsables && setSelectedResponsables(savedFilters.selectedResponsables);
    savedFilters.selectedWorkTypes && setSelectedWorkTypes(savedFilters.selectedWorkTypes);
  };
  const disableForTeamManagerVoormanAndExecutor = () => {
    if (isTeamManagerVoormanAndExecutor && !selectedResponsables.length) {
      return true;
    }
    return false;
  };
  const isMobile = useMobileSize();

  const hadleDownloadExcelByFilter = () => {
    downLoadExcel(GetCallCenterStatisticsXlsxByFilter, filter, fetchCatch, setIsExcelLoading);
  };
  return (
    <Paper elevation={6}>
      <MainText className='MainTextStatistc'>{header}</MainText>
      <DraggablePopup
        maxWidth='sm'
        isOpen={isReadyToDownload}
        onClose={() => setIsReadyToDownload(false)}
      >
        <Stack alignItems={'center'}>
          <Typography variant='h6'>Вы точно хотите выгрузить заявки в Эксель?</Typography>
          <Stack
            flexDirection={'row'}
            mt={2}
          >
            <Button
              disabled={isExcelLoading}
              onClick={() =>
                downloadExcel({
                  filter,
                  fetchCatch,
                  setIsExcelLoading,
                  setIsReadyToDownload,
                })
              }
            >
              да
            </Button>
            <Button onClick={() => setIsReadyToDownload(false)}>нет</Button>
          </Stack>
        </Stack>
      </DraggablePopup>
      <FormProvider {...methods}>
        <form onSubmit={handleSubmit(onSubmitSearch)}>
          <CallCenterStatisticsFiltersWrapper isMobile={isMobile}>
            <MultiSelect
              label={'Контракт'}
              value={selectedContractList}
              onlyInListArgument
              options={contractListResponse}
              onChange={(sel: SelectFilterOptType[]) => setSelectedContractList(sel)}
            />
            <MultiSelect
              label={'Тип заявки'}
              value={selectedTaskTypeList}
              onlyInListArgument
              options={taskTypeOptions}
              onChange={(sel: SelectFilterOptType[]) => setSelectedTaskTypeList(sel)}
            />
            <MultiSelect
              label={'Ответственный подрядчик'}
              value={selectedResponsables}
              onlyInListArgument
              options={responsables}
              onChange={(sel: SelectFilterOptType[]) => setSelectedResponsables(sel)}
            />
            <MultiSelect
              label={'Тип обращения'}
              value={selectedWorkTypes}
              onlyInListArgument
              options={workTypes}
              onChange={(sel: SelectFilterOptType[]) => setSelectedWorkTypes(sel)}
            />
            <DateRange
              label={'выбрать диапазон'}
              isInRange={isInRange}
              onChange={setDateInRange}
              value={dataInRange}
              argValue={8} // set inteval by default
              showSelect={false} // hide select option
            />
            <Stack>
              <Button
                sx={{ width: 150 }}
                disabled={!data.length}
                variant='outlined'
                onClick={() => setShowOnlyTotals(!showOnlyTotals)}
              >
                {showOnlyTotals ? 'все' : 'только итоги'}
              </Button>
            </Stack>
            <Stack>
              <LoadingButton
                loading={isExcelLoading}
                sx={{ width: 150 }}
                disabled={isInRange || isExcelLoading}
                variant='outlined'
                onClick={hadleDownloadExcelByFilter}
              >
                скачать отчет
              </LoadingButton>
            </Stack>

            <FilterButtons
              disable={
                !selectedWorkTypes.length ||
                !selectedContractList.length ||
                disableForTeamManagerVoormanAndExecutor()
              }
              info={detailedInfo}
              onSearchClick={() => handleSubmit(onSubmitSearch)}
              onCloseClick={onResetClick}
              saveFilter={{
                filterType: SAVE_FILTER_KEY,
                infoToSave: { selectedContractList, selectedResponsables, selectedWorkTypes },
                selectCallback: onSelectSavedFilter,
              }}
            />
          </CallCenterStatisticsFiltersWrapper>
        </form>
      </FormProvider>
      {load ? (
        <LoadSpinner />
      ) : (
        <CallCenterStatisticsTable
          data={data}
          showOnlyTotals={showOnlyTotals}
          setFilter={setFilter}
          filter={filter}
          isReadyToDownload={isReadyToDownload}
          setIsReadyToDownload={setIsReadyToDownload}
        />
      )}
    </Paper>
  );
};
