import { styled } from '@mui/material';

export const StatisticUrgentAndSuperUrgentTasksFiltersWrapper = styled('div')(
  ({ isMobile }: { isMobile: boolean }) => ({
    margin: '5px 0',
    padding: '0 0 0 5vw',
    position: 'relative',
    display: 'flex',
    flexWrap: isMobile ? 'wrap' : 'nowrap',
    justifyContent: isMobile ? 'center' : 'flex-start',
    alignItems: isMobile ? 'center' : 'flex-end',
    gap: 16,
    marginRight: isMobile ? 10 : 50,
  })
);

export const StatisticUrgentAndSuperUrgentTasksTableWrapper = styled('div')(
  ({ isMobile }: { isMobile?: boolean }) => ({
    display: 'flex',
    width: '89%',
    margin: 'auto',
    overflowX: 'auto',
    marginTop: 15,
    minHeight: 300,
    overflowY: 'auto',
    height: 'auto',
    gap: isMobile ? 200 : 5,
  })
);

export const StatisticUrgentAndSuperUrgentTasksEmpty = styled('p')({
  minHeight: 250,
  border: '1px solid #F0F0F0',
  width: '100%',
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
});
