import { styled } from '@mui/material/styles';
import { Box } from '@mui/material';
import { color, font } from '@/styles/mixins';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';

export const SMRBox = styled(Box)({
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'space-between',
  marginBottom: 15,
  flexWrap: 'wrap',

  padding: '8px 14px',
  border: `0.5px solid ${color('chronologyLine')}`,
  borderRadius: 5,
  backgroundColor: color('trueWhite'),
});

export const SMRCollapse = styled('div')`
  display: flex;
  justify-content: flex-end;
  align-items: flex-end;
  flex-direction: column;
`;

interface IconProps {
  hide: boolean;
}
export const SMRExpandIcon = styled(ExpandMoreIcon)<IconProps>(({ hide }) => ({
  transform: `rotate(${hide ? 0 : 180}deg)`,
  transition: '0.3s',
  color: color('errorGrayNavbar'),
  fontSize: 20,
  cursor: 'pointer',
}));

export const SMRSmallText = styled('p')<{ hide?: boolean }>`
  ${font('text5')}
  margin: 0;
  transition: color 0.3s ease-in-out;
  user-select: none;
  color: ${({ hide }) => (hide ? color('muiBlue') : color('fontGray'))};
  cursor: pointer;
`;
