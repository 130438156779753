import pdfPatch from '@/images/patch/PdfPatсh.png';
import excelPatch from '@/images/patch/ExcelPatch.png';
import videoPatch from '@/images/patch/VideoPatch.png';
import { IImage } from '@/dto/taskmap/task/TaskResultDto';
import { useState } from 'react';
import axios from 'axios';
import { format } from 'date-fns';
import { IImageGroup } from '@/components/Gallery/store';
import { ImageItemTypes, isExcelFile } from '../taskEditor/item/ImageItem/utils';

export const NOT_DEFINED = 'NOT_DEFINED';

export const getImageUrl = (url?: string, contentType?: string | null) => {
  if (contentType?.includes(ImageItemTypes.PDF)) {
    return pdfPatch;
  }
  if (isExcelFile(contentType)) {
    return excelPatch;
  }

  if (contentType?.includes(ImageItemTypes.VIDEO)) {
    return videoPatch;
  }

  return url;
};

export const GALLERY_KEY = 'gallery';

interface OpenGalleryProps {
  images?: IImage[] | IImageGroup[];
  title?: string;
}

export const useGallery = () => {
  const [galleryData, setGalleryData] = useState(() => {
    const data = localStorage.getItem(GALLERY_KEY);
    if (!data) return null;

    try {
      return JSON.parse(data) as OpenGalleryProps;
    } catch (error) {
      return null;
    }
  });

  const openGallery = ({ images, title }: OpenGalleryProps) => {
    localStorage.setItem(GALLERY_KEY, JSON.stringify({ title, images: images || [] }));
    window.open('/gallery');
  };

  return { openGallery, galleryData };
};

export const getUrlHeaders = async (url: string) => {
  return axios.head(url);
};

export const getFileName = (image: IImage | null): string => {
  let defaultName = 'Имя не указано';
  if (image?.fileName?.length) {
    defaultName = image.fileName;
  }

  return defaultName;
};

export const formatDate = (timestamp?: number | Date): string => {
  if (!timestamp) return '';
  return format(new Date(timestamp), 'dd.MM.yyyy HH:mm');
};

export const getFilePromises = (images?: IImage[] | IImageGroup[]) => {
  if (!images) return [];

  // Определяем тип первого элемента массива
  if (images.length > 0) {
    const firstItem = images[0];

    // Type guard для IImageGroup
    if (isImageGroup(firstItem)) {
      return processImageGroups(images as IImageGroup[]);
    }

    // Type guard для IImage
    if (isImage(firstItem)) {
      return processImages(images as IImage[]);
    }
  }

  return [];
};

// Проверка на IImageGroup
export const isImageGroup = (item: IImage | IImageGroup): item is IImageGroup => {
  return 'title' in item && 'imageList' in item;
};

// Проверка на IImage
export const isImage = (item: IImage | IImageGroup): item is IImage => {
  return 'id' in item && 'createdOn' in item && !('title' in item);
};

// Пример обработки для групп изображений
const processImageGroups = (imageGroups: IImageGroup[]) => {
  return imageGroups
    ?.map((group) =>
      group?.imageList?.map((image, index) => ({
        url: image.url,
        folder: group.title,
        fileName: `${index + 1}_${group.title}_${image.id}`,
      }))
    )
    .flat();
};

// Пример обработки для отдельных изображений
const processImages = (images: IImage[], galleryTitle?: string) => {
  return images
    ?.map((image, index) => ({
      url: image.url,
      folder: galleryTitle ?? 'Фото',
      fileName: `${galleryTitle ?? 'Фото'}_${index + 1}`,
    }))
    .flat();
};
