import { BoxShadow } from '@/components/ui/BoxShadow';
import { bounce } from '@/components/features/pnr/HouseEntranceHeader/HouseEntranceHeader.styled';
import { color, mediaBreakpointDown } from '@/styles/mixins';
import { IconButton, styled } from '@mui/material';

interface ComplaintCommentContainerProps {
  open?: boolean;
  isMobile?: boolean;
}
interface ComplaintCommentButtonProps {
  open?: boolean;
}

export const ComplaintCommentWrapper = styled('div')<ComplaintCommentContainerProps>(
  ({ open }) => ({
    position: 'absolute',
    left: -10,
    top: 55,
    transform: 'translate(-100%,0)',
    display: 'flex',
    transition: 'gap .3s',
    flexDirection: 'column',
    gap: 10,
    [mediaBreakpointDown('lg')]: {
      position: 'fixed',
      top: -70,
      left: '42%',
      transform: 'translateX(-50%)',
      width: '100%',
      maxWidth: 600,
      padding: 10,
      zIndex: 1000,
    },
    [mediaBreakpointDown('md')]: {
      position: 'fixed',
      top: -70,
      left: '47%',
      transform: 'translateX(-50%)',
      width: '100%',
      maxWidth: 400,
      padding: 10,
      zIndex: 1000,
    },
  })
);

export const ComplaintCommentButton = styled('div')<ComplaintCommentButtonProps>(({ open }) => ({
  '&:hover': { svg: { animation: `${bounce} 1s 1 both` } },
  color: open ? color('muiBlue') : 'black',
}));

export const ComplaintActionButtonsWrapper = styled(BoxShadow)({
  padding: 10,
  cursor: 'pointer',
  transition: 'background .3s',
  width: 'fit-content',
  height: 'fit-content',
  backgroundColor: '#F5F9FF',
  position: 'absolute',
  top: -55,
  right: 5,
  display: 'flex',
  gap: 10,
  [mediaBreakpointDown('sm')]: {
    position: 'relative',
    top: 15,
    left: '1%',
  },
});

export const ComplaintCommentsWrapper = styled(BoxShadow)<ComplaintCommentContainerProps>(
  ({ isMobile }) => ({
    padding: 8,
    width: isMobile ? 325 : 500,
    transformOrigin: isMobile ? 'left' : 'right',
    overflow: 'visible',
    backgroundColor: '#F5F9FF',
  })
);

export const ComplaintCommentIconButton = styled(IconButton)({
  svg: {
    transition: '0.5s ease',
  },
});
