import { YMaps, Map, Clusterer, Placemark } from 'react-yandex-maps';
import { usePillarMapState } from '../store';
import React, { useEffect, useRef, useState } from 'react';
import shallow from 'zustand/shallow';
import {
  makePolygon,
  MOSCOW_CENTER_COORDINATES,
  splitGeoJSONByGrid,
} from '../../callingMap/utils/CallingMapUtils';
import { PillarPlacemark } from '@/components/maps/pillarMap/placemark/Placemark';

const PillarMapInner = ({
  setMapAPI,
  setZoomState,
  setDiffGeojson,
  diffGeojson,
  singleSearch,
  onSubmit,
}: any) => {
  const { geoPoints, setBounds, mapZoom, bounds, setMapZoom, setPolygon } = usePillarMapState(
    (state) => ({
      geoPoints: state.geoPoints,
      setBounds: state.setBounds,
      bounds: state.bounds,
      mapZoom: state.mapZoom,
      setMapZoom: state.setMapZoom,
      setPolygon: state.setPolygon,
    }),
    shallow
  );

  const [prevZoom, setPrevZoom] = useState(null);
  const [polygons, setPolygons] = useState([]);

  const mapRef = useRef<any>(null);

  const handleBoundsChange = (event: any) => {
    const map = mapRef.current;
    if (!map) return;
    setMapZoom(map._zoom);
    const bounds: number[][] = map?.getBounds();
    setPolygon(makePolygon(bounds));
  };

  useEffect(() => {
    const res = splitGeoJSONByGrid(diffGeojson, prevZoom || 5);
    setPolygons(res);
  }, [diffGeojson]);

  return (
    <YMaps
      query={{
        ns: 'use-load-option',
        load: 'Map,Placemark,control.ZoomControl,geoObject.addon.balloon,geoObject.addon.hint,layout.PieChart', // тут менять кластеры  layout.PieChart
        coordorder: 'longlat',
      }}
    >
      <Map
        id={'callingMap'}
        instanceRef={(instance) => {
          mapRef.current = instance;
          setMapAPI(instance);
        }}
        modules={['geoQuery']}
        defaultState={{
          center: MOSCOW_CENTER_COORDINATES,
          zoom: 5,
          controls: ['zoomControl'],
        }}
        onBoundsChange={handleBoundsChange}
        width={'100%'}
        height={'65vh'}
        options={{
          autoFitToViewport: 'always',
        }}
      >
        <Clusterer options={{ maxZoom: 15, minClusterSize: 4 }}>
          {geoPoints.map((n, i: number) => (
            <PillarPlacemark
              geoObject={n}
              key={n.id}
            />
          ))}
          {/*TODO: стандартный балун*/}
          {/*{geoPoints.map((n, i: number) => (*/}
          {/*  <Placemark*/}
          {/*    key={n.id}*/}
          {/*    geometry={[n.longitudeX, n.latitudeY]}*/}
          {/*    options={{*/}
          {/*      balloonPanelMaxMapArea: 0,*/}
          {/*      preset: 'islands#redIcon',*/}
          {/*    }}*/}
          {/*  />*/}
          {/*))}*/}
        </Clusterer>
      </Map>
    </YMaps>
  );
};
export default PillarMapInner;
