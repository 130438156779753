import { css } from '@mui/material';
import { rgba } from 'polished';
import colors, { Colors } from './colors';
import breakpoints, { Breakpoints } from './breakpoints';
import variables, { Offset } from './variables';
import typography, { Typography } from './typography';
import { SerializedStyles } from '@emotion/react';

/**
 * @param value - Цвет (название из списка констант);
 * @param opacity - Прозрачность;
 * @example
 * color: ${color('white')};
 * color: ${color('black', 0.5)};
 */
export const color: (value: Colors, opacity?: number) => string = (value, opacity = 1) => {
  return rgba(colors[value], opacity);
};

/**
 * @return Добавление тонкого скроллбара элементу
 * @example
    export const PnrDndEditModalInner = styled('div')`
        ${scrollbarThin};
    `;
 */
export const scrollbarThin = css`
  scrollbar-width: thin;
`;

/**
 * @param breakpoint - целевой Breakpoint
 * @return Медиа запрос активный для заданного Breakpoint и выше
    * @example
      export const NavWrapper = styled('nav')({
      background: 'red',
      [mediaBreakpointUp('lg')]: {
       background: 'blue',
      },
      });
     * @example
     export const NavWrapper = styled('nav')`
      background: red;
      ${mediaBreakpointUp('lg')} {
        background: blue;
      }
     `;
 */
export const mediaBreakpointUp: (breakpoint: Breakpoints) => string = (breakpoint) => {
  return `@media(min-width: ${breakpoints[breakpoint]}px)`;
};

/**
 * @param breakpoint - целевой Breakpoint
 * @return Медиа запрос активный для заданного Breakpoint и ниже
 * @example
 * ${mediaBreakpointDown('xl')} {
 *     // styles
 * }
 */
export const mediaBreakpointDown: (breakpoint: Breakpoints) => string = (breakpoint) => {
  return `@media(max-width: ${breakpoints[breakpoint] - 1}px)`;
};

/**
 * @param value - Значение на целевом экране
 * @param screenWidth - Ширина целевого экрана (число или Breakpoint)
 * @returns Относительное значение в формате vw. Результат изменяется вместе с шириной экрана
 * @example
 * width: ${vw(480)};
 *
 * ${mediaBreakpointDown('md')} {
 *     width: ${vw(240, 'md')};
 * }
 */
export const vw: (value: number, screenWidth?: Breakpoints | number) => string = (
  value,
  screenWidth: Breakpoints | number = 1920
) => {
  const widthInPx = typeof screenWidth === 'number' ? screenWidth : breakpoints[screenWidth];
  return `${(value / widthInPx) * 100}vw`;
};

/**
 * @param value - Значение на целевом экране
 * @param screenHeight - Высота целевого экрана
 * @returns Относительное значение в формате vh. Результат изменяется вместе с высотой экрана
 * @example
 * height: ${vh(480)};
 *
 * ${mediaBreakpointDown('md')} {
 *     height: ${vw(240, 'md')};
 * }
 */
export const vh: (value: number, screenHeight?: number) => string = (
  value,
  screenHeight = 1080
) => {
  return `${(value / screenHeight) * 100}vh`;
};

/**
 * @param type - Тип устройства
 * @returns Относительное значение в формате vw. Результат изменяется вместе с шириной экрана
 * @example
 * padding: 0 ${offset('desktop')};
 *
 * ${mediaBreakpointDown('lg')} {
 *     padding: 0 ${offset('tablet')};
 * }
 *
 * ${mediaBreakpointDown('xs')} {
 *     padding: 0 ${offset('mobile')};
 * }
 */
export const offset: (type: Offset) => string = (type) => {
  const size = {
    mobile: 375,
    tablet: 768,
    desktop: 1920,
  };
  return vw(variables.offset[type], size[type]);
};

/**
 * @param name - Название типа текста
 * @returns Стандартные стили для указанного типа текста
 * @example
 * styled('p')`
  ${font('text1')}
 `;
 */
export const font: (name: Typography) => SerializedStyles = (name) => {
  return typography[name];
};

/**
 * @returns возвращает property для всех Breakpoints
 */
export const allBreakpointValue = (
  property: string,
  desktop1920: number,
  desktop1440: number,
  tablet768?: number,
  mobile375?: number
) => {
  let result = '';
  if (mobile375) {
    result += ` ${property}: ${vw(mobile375, 375)};`;
  }
  if (tablet768) {
    result += ` ${mediaBreakpointUp('md')} {
            ${property}: ${vw(tablet768, 768)};
        }; `;
  }

  result += `
        ${mediaBreakpointUp('xxl')} {
            ${property}: ${vw(desktop1440, 1440)};
        }

        ${mediaBreakpointUp('fhd')} {
            ${property}: ${vw(desktop1920, 1920)};
        }
    `;
  return result;
};

/**
 * @return Убрать отображение селекта с ячеек DataGrid
 * @example
    export const PnrDndEditModalInner = styled('div')`
        ${removeDataGridCellOutline};
    `;
 */
export const removeDataGridCellOutline = css`
  .MuiDataGrid-cell:focus {
    outline: none;
  }

  .MuiDataGrid-cell:focus-within {
    outline: none;
  }
`;
