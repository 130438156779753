import { Order } from '../constants';
import { createStore } from '@/utils/createStore';

export interface IRegistryEditItem {
  id: string;
  type: string;
  adress: string;
  data?: string;
  executor?: string;
  contractorId?: string;
  managementCompany?: string;
}

interface IRegistryStore {
  registryResultTable: IRegistryEditItem[];
  order: Order;
  orderBy: keyof IRegistryEditItem;
  selected: string[];
  oldIndex: number;
}

interface IRegistryStoreFunc {
  setRegistryResultTable: (registryResultTable: IRegistryEditItem[]) => void;
  setOrder: (order: Order) => void;
  setOrderBy: (orderBy: keyof IRegistryEditItem) => void;
  setSelected: (selected: string[]) => void;
  addDataAndExecutorByIds: (ids: string[], data?: string, executor?: string) => void;
  addDataByIds: (ids: string[], data?: string) => void;
  addExecutorByIds: (ids: string[], executor?: string) => void;
  clear: () => void;
  setOldIndex: (oldIndex: number) => void;
}

export const useRegistryEditStore = createStore<IRegistryStore & IRegistryStoreFunc>(
  (set, get) => ({
    registryResultTable: [],
    order: 'asc',
    orderBy: 'type',
    selected: [],
    oldIndex: 0,

    clear: () =>
      set(() => ({ registryResultTable: [], order: 'asc', orderBy: 'type', selected: [] })),

    addDataAndExecutorByIds: (ids, data, executor) =>
      set(() => ({
        registryResultTable: get().registryResultTable.map((elem) => ({
          ...elem,
          data: ids.findIndex((el) => el === elem.id) >= 0 ? data : elem.data,
          executor: ids.findIndex((el) => el === elem.id) >= 0 ? executor : elem.executor,
        })),
      })),
    addDataByIds: (ids, data) =>
      set(() => ({
        registryResultTable: get().registryResultTable.map((elem) => ({
          ...elem,
          data: ids.findIndex((el) => el === elem.id) >= 0 ? data : elem.data,
        })),
      })),
    addExecutorByIds: (ids, executor) =>
      set(() => ({
        registryResultTable: get().registryResultTable.map((elem) => ({
          ...elem,
          executor: ids.findIndex((el) => el === elem.id) >= 0 ? executor : elem.executor,
        })),
      })),

    setRegistryResultTable: (registryResultTable) => set(() => ({ registryResultTable })),
    setOrder: (order) => set(() => ({ order })),
    setOrderBy: (orderBy) => set(() => ({ orderBy })),
    setSelected: (selected) => set(() => ({ selected })),
    setOldIndex: (oldIndex) => set(() => ({ oldIndex })),
  }),
  'Registry edit'
);
