import { toFormateDate } from '@/utils/heplers';
import { Button, Divider, Grid, IconButton, Typography } from '@mui/material';
import React, { useState } from 'react';
import {
  TaskCommentDeleteModalButtonsWrapper,
  TaskCommentDeleteModalInner,
  TaskCommentDeleteModalTitle,
  TaskCommentGridGray,
  TaskCommentGridGrayRight,
} from './TaskCommentPanel.styled';
import DeleteOutlineIcon from '@mui/icons-material/DeleteOutline';
import { TaskCommentResponse, TaskCollCenterCommentResponse } from '@/dto/taskmap/task/TasksDto';
import CommonModal from '@/components/common/CommonModal/CommonModal';
import { LoadingButton } from '@mui/lab';
import { deleteTaskComment } from '@/services/TaskService/TaskService';
import { useParams } from 'react-router-dom';
import { addSuccessActionLog, fetchCatch, syncErrorCatch } from '@/hooks/ActionLogHook';

export interface TaskCommentProps {
  comment: TaskCommentResponse | TaskCollCenterCommentResponse;
  withDeletion?: boolean;
  filterComment?: () => void;
}

const TaskComment = ({ comment, withDeletion, filterComment }: TaskCommentProps) => {
  const [isDeleting, setIsDeleting] = useState(false);
  const [openModal, setOpenModal] = useState(false);
  const { taskId } = useParams();

  const handleCloseModal = () => {
    setOpenModal(false);
  };

  const handleDelete = () => {
    if (!taskId || !comment.id) {
      syncErrorCatch('Ошибка при удалении комментария', {
        taskId: taskId ?? null,
        commentId: comment.id ?? null,
      });
      return;
    }
    setIsDeleting(true);

    deleteTaskComment(taskId, comment.id)
      .then(() => {
        addSuccessActionLog('Комментарий успешно удалён');
        filterComment?.();
      })
      .catch((error) => fetchCatch(error))
      .finally(() => {
        setIsDeleting(false);
        handleCloseModal();
      });
  };

  return (
    <Grid
      container
      item
      xs={12}
      spacing={1}
    >
      <Grid
        item
        xs={12}
      >
        <Divider />
      </Grid>
      <TaskCommentGridGray
        item
        xs={10}
      >
        {comment.author || comment.createdBy}
      </TaskCommentGridGray>

      {withDeletion && (
        <Grid
          item
          xs={2}
          sx={{ display: 'flex', justifyContent: 'flex-end' }}
        >
          <IconButton
            disabled={!comment?.isDeletable}
            onClick={() => setOpenModal(true)}
          >
            <DeleteOutlineIcon color={comment?.isDeletable ? 'error' : 'disabled'} />
          </IconButton>
        </Grid>
      )}
      <Grid
        item
        xs={12}
      >
        <Typography style={{ wordBreak: 'break-word' }}>
          {comment.message || comment.value}
        </Typography>
      </Grid>
      <TaskCommentGridGrayRight
        item
        xs={12}
        sx={{ paddingRight: 1 }}
      >
        {toFormateDate(comment.eventAt) || toFormateDate(comment.createdOn)}
      </TaskCommentGridGrayRight>

      <CommonModal
        open={openModal}
        onClose={handleCloseModal}
      >
        <TaskCommentDeleteModalInner>
          <TaskCommentDeleteModalTitle>
            Вы уверены, что хотите удалить данный комментарий?
          </TaskCommentDeleteModalTitle>
          <TaskCommentDeleteModalButtonsWrapper>
            <LoadingButton
              variant='contained'
              onClick={handleDelete}
              loading={isDeleting}
            >
              Да
            </LoadingButton>
            <Button
              variant='contained'
              onClick={handleCloseModal}
            >
              Нет
            </Button>
          </TaskCommentDeleteModalButtonsWrapper>
        </TaskCommentDeleteModalInner>
      </CommonModal>
    </Grid>
  );
};

export default TaskComment;
