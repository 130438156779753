import { AccessEnum } from '@/app/auth/roles';
import OdpuManagementCompanyCatalog from '@/components/features/odpuManagementCompanyCatalog/ui/OdpuManagementCompanyCatalog';
import { useAccess } from '@/hooks/useAccess';
import { useMetaTitle } from '@/hooks/useMetaTitle';
import React from 'react';

const OdpuManagementCompanyCatalogPage = () => {
  const hasAccess = useAccess({
    access: [AccessEnum.MapUspd],
  });
  useMetaTitle('Управляющие компании');

  return <>{hasAccess && <OdpuManagementCompanyCatalog />}</>;
};

export default OdpuManagementCompanyCatalogPage;
