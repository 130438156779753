import React, { FC, useState } from 'react';
import { ActionLogType, useActionLog } from '@/hooks/ActionLogHook';
import { Button, Grid } from '@mui/material';
import { AccessPopup } from '@/components/popups/AccessPopup/AccessPopup';
import { pillarDeliveryPointToSmr, sendPpoToIsur } from '@/services/TaskService/TaskService';

interface SendPpoToIsurButtonProps {
  id: string;
}

export const SendPpoToIsurButton: FC<SendPpoToIsurButtonProps> = ({ id }) => {
  const [isOpenPopup, setIsOpenPopup] = useState<boolean>(false);
  const [isLoading, setIsLoading] = useState(false);
  const { fetchCatch, addActionLog } = useActionLog();

  const onSubmit = () => {
    setIsLoading(true);
    sendPpoToIsur(id) // TODO: change CableDescentService
      .then(({ data }) => {
        addActionLog(ActionLogType.SUCCESS, `ППО успешно отправлено в ИСУР`);
      })
      .catch((err) => fetchCatch(err, `Ошибка отправки ППО в ИСУР`))
      .finally(() => {
        setIsOpenPopup(false);
        setIsLoading(false);
      });
  };

  return (
    <Grid
      item
      xs={12}
      sx={{ textAlign: 'right' }}
    >
      <Button onClick={() => setIsOpenPopup(true)}>{`Отправить ППО в ИСУР`}</Button>
      <AccessPopup
        onSuccess={onSubmit}
        isOpen={isOpenPopup}
        onClose={() => setIsOpenPopup(false)}
        successText={'Да'}
        headerText={`Отправить ППО в ИСУР?`}
        // titleText={'Действие невозможно отменить. Продолжить?'}
        isLoading={isLoading}
      />
    </Grid>
  );
};
