import { CircularProgress, Tab, TabProps } from '@mui/material';
import React from 'react';

export type CallingMapTabProps = TabProps & {
  count: number | 'pending' | null;
};

const CallingMapTab = (props: CallingMapTabProps) => {
  const { count } = props;

  return (
    <Tab
      {...props}
      label={
        <span style={{ display: 'flex', gap: 5, alignItems: 'center' }}>
          <span>{props.label}</span>
          {count === 'pending' ? (
            <CircularProgress
              size={10}
              style={{ marginBottom: 3 }}
            />
          ) : (
            <span style={{ color: 'red' }}>{typeof count == 'number' && `(${count})`}</span>
          )}
        </span>
      }
    />
  );
};

export default CallingMapTab;
