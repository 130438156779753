import React, { useMemo } from 'react';
import { filterShowInfo } from '../utils';
import { ShowInfoBody, ShowInfoTable, ShowInfoTableBody } from './BudgetStatisticDoughnut.styled';
import { StatisticSummaryResponse } from '../../api/dto/response';
import { useInvestStatisticStore } from '../../store';
import { DisplayManufacturer } from '../../types/types';
import { StringDoughnutShowInfo } from './StringDoughnutShowInfo';

interface DoughnutShowInfoProps {
  item: StatisticSummaryResponse;
  displayManufacturer: DisplayManufacturer;
}

export const DoughnutShowInfo = ({ item, displayManufacturer }: DoughnutShowInfoProps) => {
  const foundItem = item.showInfo.default?.find((it) => it.title === 'МОНТАЖ');
  const amount = foundItem ? foundItem.amount : 0;
  const filteredShowInfo = useMemo(
    () => filterShowInfo(displayManufacturer, amount, item.showInfo),
    [item.showInfo, displayManufacturer]
  );

  const countAsMoney = useInvestStatisticStore((state) => state.countAsMoney);

  return (
    <ShowInfoBody>
      <ShowInfoTable>
        <ShowInfoTableBody>
          {filteredShowInfo &&
            typeof filteredShowInfo === 'object' &&
            Object.entries(filteredShowInfo).map(([key, items], index) => {
              if (!items) return;
              return (
                <React.Fragment key={key}>
                  {index !== 0 && item.title !== 'РОУТЕР' && item.title !== 'ШЛЮЗ' && (
                    <tr>
                      <td style={{ height: '20px' }} />
                    </tr>
                  )}
                  {Array.isArray(items) ? (
                    <>
                      {items?.map((item, index) => {
                        return (
                          <StringDoughnutShowInfo
                            key={index}
                            item={item}
                            countAsMoney={countAsMoney}
                          />
                        );
                      })}
                    </>
                  ) : (
                    <>
                      {item.title !== 'РОУТЕР' && item.title !== 'ШЛЮЗ' && (
                        <>
                          {Object.entries(items)?.map(([key, item], index) => {
                            if (!item?.length) return;
                            return (
                              <StringDoughnutShowInfo
                                key={index}
                                item={item[0]}
                                countAsMoney={countAsMoney}
                              />
                            );
                          })}
                        </>
                      )}
                    </>
                  )}
                </React.Fragment>
              );
            })}
        </ShowInfoTableBody>
      </ShowInfoTable>
    </ShowInfoBody>
  );
};
