import { useAccess } from '@/hooks/useAccess';
import { useMetaTitle } from '@/hooks/useMetaTitle';
import { AccessEnum } from '@/app/auth/roles';
import React from 'react';
import { PillarMap } from '@/components/maps/pillarMap/PillarMap';

const PillarMapPage = () => {
  useAccess({
    access: [AccessEnum.CallingMap, AccessEnum.CallingMapOperator],
  });
  useMetaTitle('Карта Заявки');

  return <PillarMap />;
};

export default PillarMapPage;
