import axios from 'axios';
import {
  SupplyBillFilterParam,
  SupplyBillSearchFilter,
  SupplyBillTypeResponse,
  SupplyNetworkElementTypeCreateDto,
  SupplyNetworkElementTypeResponse,
  SupplyOsFilterParam,
  SupplyPuFilterParam,
  SupplyPuTypeCreateDto,
  SupplyPuTypeResponse,
} from '@/dto/SupplyBillServiceDto';
import {
  SupplyBillBrowseDto,
  CheckSupplyItemExistsResponse,
  SupplyMeterType,
  SupplyBillTaskNumberCheckDto,
} from '@/dto/taskmap/Dto';
import { removeTokens, yodaJwt } from '@/app/auth/AuthProvider';
import { ApiResponse } from '@/types/api';
import { BillRequestFormData } from '@/components/storehouse/IssuedStamp/utils';
import { SAVE_SUPPLY_JWT_TOKEN } from '@/components/blocks/StorehouseUserName/utils';
import { SupplyStatisticResponseDTO } from '@/services/SupplyBillService/dto/SupplyBillServiceResponse';
import { SupplyStatisticRequestDTO } from './dto/SupplyBillServiceRequest';
import { GetJWT } from '../YodaRestService/YodaRestService';
import { ApiFindAllContractResponse } from '../YodaRestService/dto/YodaRestServiceResponseDto';

interface MapCatalog {
  [key: string]: string;
}

function getHost() {
  const HOST = import.meta.env.VITE_SUPPLY_BILL_SERVICE_BASE_URL;

  if (typeof HOST == 'undefined') {
    alert('Адрес сервера для запросов к SupplyBillService API не задан');
  } else return HOST as string;
}
export const getYodaJWTLocal = () => localStorage.getItem(yodaJwt);
const saveYodaJWTLocal = (token: string) => localStorage.setItem(yodaJwt, token);

const HOST = getHost();
const UNAUTHORIZED_CODE = 401;

export const SupplyBillClient = axios.create({
  baseURL: HOST,
  headers: {
    'Content-Type': 'application/json',
  },
});

const MULTIPART_HEADER = { 'Content-Type': 'multipart/form-data' };

let refreshTokenPromise: ApiResponse<string> | null = null;
// Проброс JWT токена во все запросы SupplyBillApi
SupplyBillClient.interceptors.request.use(
  async function (config) {
    config.headers = config.headers ?? {};
    const jwt = getYodaJWTLocal();
    if (jwt) {
      config.headers['Authorization'] = `Bearer ${jwt}`;
    } else {
      refreshTokenPromise ??= GetJWT();
      const { data } = await refreshTokenPromise;
      refreshTokenPromise = null;
      saveYodaJWTLocal(data);
      window.dispatchEvent(new Event(SAVE_SUPPLY_JWT_TOKEN));
      config.headers['Authorization'] = `Bearer ${data}`;
    }
    return config;
  },
  (error) => Promise.reject(error)
);

// Переполучение JWT при 401 ошибке
SupplyBillClient.interceptors.response.use(
  (response) => {
    return response;
  },
  async function (error) {
    const originalRequest = error.config;
    if (error.response.status === UNAUTHORIZED_CODE) {
      if (!originalRequest._retry) {
        originalRequest._retry = true;
        refreshTokenPromise ??= GetJWT();
        const { data } = await refreshTokenPromise;
        refreshTokenPromise = null;
        saveYodaJWTLocal(data);
        window.dispatchEvent(new Event(SAVE_SUPPLY_JWT_TOKEN));
        return SupplyBillClient(originalRequest);
      } else {
        removeTokens();
      }
    }
    return Promise.reject(error);
  }
);

export const SupplyBillApiGetBillTypes = async (): Promise<SupplyBillTypeResponse[]> => {
  const url = `/supply-bill-type/`;
  const { data } = await SupplyBillClient.get(url);
  return data;
};

export const SupplyBillApiGetSupplyBillsCount = (
  filter: SupplyBillFilterParam
): ApiResponse<number> => {
  const url = `/supply-bill/count`;
  return SupplyBillClient.post(url, filter);
};

export const SupplyBillApiGetSupplyBills = ({
  filter,
  page,
  size,
}: SupplyBillSearchFilter): ApiResponse<SupplyBillBrowseDto[]> => {
  const url = `/supply-bill/search` + '?page=' + page + '&size=' + size;
  return SupplyBillClient.post(url, filter);
};

export const SupplyBillApiGetSupplyBillById = (
  billId: string
): ApiResponse<SupplyBillBrowseDto> => {
  const url = `/supply-bill/` + billId;
  return SupplyBillClient.get(url);
};

export const SupplyBillApiGetReturnBillById = (
  billId: string
): ApiResponse<SupplyBillBrowseDto> => {
  const url = `/supply-bill/${billId}/return`;
  return SupplyBillClient.get(url);
};

export const SupplyBillApiGetFileById = (fileId: string | number): ApiResponse<Blob> => {
  const url = `/bill-file/download/` + fileId;
  return SupplyBillClient.get(url, { responseType: 'blob' });
};

export const SupplyBillApiSaveNewSupplyBill = (
  supplyBill: BillRequestFormData
): ApiResponse<string> => {
  const url = `/supply-bill/`;
  return SupplyBillClient.post(url, supplyBill, { headers: MULTIPART_HEADER });
};

export const SupplyBillApiSaveNewReturnBill = (
  supplyBill: BillRequestFormData
): ApiResponse<string> => {
  const url = `/supply-bill/return`;
  return SupplyBillClient.post(url, supplyBill, { headers: MULTIPART_HEADER });
};

export const SupplyBillApiUpdateSupplyBill = (
  supplyBill: BillRequestFormData
): ApiResponse<string> => {
  const url = `/supply-bill/`;
  return SupplyBillClient.put(url, supplyBill);
};

export const SupplyBillApiUpdateReturnBill = (
  supplyBill: BillRequestFormData
): ApiResponse<string> => {
  const url = `/supply-bill/return`;
  return SupplyBillClient.put(url, supplyBill);
};

export const SupplyBillApiCheckSupplyPuExists = (
  pu: SupplyPuFilterParam
): ApiResponse<CheckSupplyItemExistsResponse> => {
  const url = `/supply-pu/exists`;
  return SupplyBillClient.post(url, pu);
};

export const SupplyBillApiCheckSupplyBillExists = (
  billNumber: string,
  billDate: string
): ApiResponse<any> => {
  // Кодируем строку billNumber
  const encodedBillNumber = encodeURIComponent(billNumber);
  const url = `/supply-bill/exists?billNumber=${encodedBillNumber}&billDate=${billDate}`;
  return SupplyBillClient.get(url);
};

export const SupplyBillApiGetPuTypes = (): ApiResponse<SupplyPuTypeResponse[]> => {
  const url = `/supply-pu-type/`;
  return SupplyBillClient.get(url);
};

export const SupplyBillApiGetPuTypesParents = (): ApiResponse<SupplyPuTypeResponse[]> => {
  const url = `/supply-pu-type/parents`;
  return SupplyBillClient.get(url);
};

export const SupplyBillApiDeleteBill = (billId?: string): ApiResponse<SupplyMeterType[]> => {
  const url = `/supply-bill/` + billId;
  return SupplyBillClient.delete(url);
};

export const SupplyBillApiGetCatalog = async (
  catalogName: string
): Promise<ApiFindAllContractResponse | MapCatalog> => {
  const url = `/catalog/` + `${catalogName}`;
  const { data } = await SupplyBillClient.get(url);
  return data;
};

export const SupplyBillApiCheckTaskNumber = (
  taskNumber?: string | null
): ApiResponse<SupplyBillTaskNumberCheckDto> | undefined => {
  if (!taskNumber?.length) return;
  const url = `/yoda/task/` + taskNumber;
  return SupplyBillClient.get(url);
};

export const SupplyBillApiCheckTaskNumberList = (
  taskNumbers: string[]
): ApiResponse<SupplyBillTaskNumberCheckDto[]> | undefined => {
  const url = `/yoda/task`;

  return SupplyBillClient.post(url, taskNumbers);
};

export const SupplyBillApiGetNetworkElementTypes = (): ApiResponse<
  SupplyNetworkElementTypeResponse[]
> => {
  const url = `/supply-network-element-type/`;
  return SupplyBillClient.get(url);
};

export const SupplyBillApiGetNetworkElementTypesParents = (): ApiResponse<
  SupplyNetworkElementTypeResponse[]
> => {
  const url = `/supply-network-element-type/parents`;
  return SupplyBillClient.get(url);
};

export const SupplyBillApiCheckNetworkElementExists = (
  os: SupplyOsFilterParam
): ApiResponse<CheckSupplyItemExistsResponse> => {
  const url = `/supply-network-element/exists`;
  return SupplyBillClient.post(url, os);
};

export const SupplyBillApiCreatePuType = (
  puType: SupplyPuTypeCreateDto
): ApiResponse<CheckSupplyItemExistsResponse> => {
  const url = `/supply-pu-type/`;
  return SupplyBillClient.post(url, puType);
};

export const SupplyBillApiEditPuType = (
  puType: SupplyPuTypeCreateDto
): ApiResponse<CheckSupplyItemExistsResponse> => {
  const url = `/supply-pu-type/`;
  return SupplyBillClient.put(url, puType);
};

export const SupplyBillApiDeletePuType = (
  id: number
): ApiResponse<CheckSupplyItemExistsResponse> => {
  const url = `/supply-pu-type/`;
  return SupplyBillClient.delete(`${url}${id}`);
};

export const SupplyBillApiCreateNetworkElementType = (
  osType: SupplyNetworkElementTypeCreateDto
): ApiResponse<CheckSupplyItemExistsResponse> => {
  const url = `/supply-network-element-type/`;
  return SupplyBillClient.post(url, osType);
};

export const SupplyBillApiEditNetworkElementType = (
  osType: SupplyNetworkElementTypeCreateDto
): ApiResponse<CheckSupplyItemExistsResponse> => {
  const url = `/supply-network-element-type/`;
  return SupplyBillClient.put(url, osType);
};

export const SupplyBillApiDeleteNetworkElementType = (
  id: number
): ApiResponse<CheckSupplyItemExistsResponse> => {
  const url = `/supply-network-element-type/`;
  return SupplyBillClient.delete(`${url}${id}`);
};

export const SupplyBillApiCacheInvalidate = (
  cacheNames: string[]
): ApiResponse<CheckSupplyItemExistsResponse> => {
  const url = `/cache/invalidate`;
  return SupplyBillClient.post(url, cacheNames);
};

export const SupplyApiGetSupplyStatistic = ({
  filter,
  offset,
  limit,
}: SupplyStatisticRequestDTO): ApiResponse<SupplyStatisticResponseDTO[]> => {
  const url = `/statistic/equipment?offset=${offset}&limit=${limit}`;
  return SupplyBillClient.post(url, filter);
};

export const SupplyStatisticApiGenerateExcel = (
  filter: SupplyBillFilterParam
): ApiResponse<SupplyStatisticResponseDTO[]> => {
  const url = `/statistic/equipment/excel`;
  return SupplyBillClient.post(url, filter, {
    responseType: 'blob',
  });
};
