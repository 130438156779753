import shallow from 'zustand/shallow';
import { StateSelector } from 'zustand';
import { createStore } from '@/utils/createStore';
import { SimChanges, SimChangesView } from './types';
import { fetchCatch } from '@/hooks/ActionLogHook';
import { getGsmByTaskId } from '@/services/GSMService/GSMService';

interface IGsmStore {
  loading: boolean;
  gsmData: SimChangesView[];
  getGsmData: (taskId: string) => void;
}

export const convertData = (data: SimChanges[]): SimChangesView[] => {
  const imeis: string[] = [];
  data.forEach((elem) => {
    if (!imeis.find((el) => el == elem.imei)) {
      imeis.push(elem.imei);
    }
  });
  return imeis.map((elem) => ({
    imei: elem,
    sims: data.filter((el) => el.imei == elem),
  }));
};

const useGsmStoreZus = createStore<IGsmStore>(
  (set, get) => ({
    loading: false,
    gsmData: [],
    getGsmData: (taskId) => {
      set(() => ({ loading: true }));
      getGsmByTaskId(taskId)
        .then(({ data }) => {
          set(() => ({ gsmData: convertData(data) }));
        })
        .catch((err) => {
          fetchCatch(err, 'Данны блока GSM не получены');
        })
        .finally(() => set(() => ({ loading: false })));
    },
  }),
  'GSM'
);

export const useGsmStore: <T>(selector: StateSelector<IGsmStore, T>) => T = (selector) =>
  useGsmStoreZus(selector, shallow);
