import { HOST, YodaRestClient } from '@/services/YodaAuthService';
import { ApiResponse } from '@/types/api';
import { NetworkElementRequest } from './dto/NetworkRequestDto';
import { ConsumableEquipment, NetworkElementResponse } from './dto/NetworkResponseDto';

export async function getNetworkElements(taskId: string): ApiResponse<NetworkElementResponse[]> {
  const url = HOST + `/rest/task/${taskId}/network-element`;
  return YodaRestClient.get(url);
}

export async function updateNetworkElement(
  id: string,
  data: NetworkElementRequest
): ApiResponse<NetworkElementResponse> {
  const url = HOST + `/rest/network-element/${id}`;
  return YodaRestClient.put(url, data);
}

export async function repeatNetworkElement(
  id: string,
  data: NetworkElementRequest
): ApiResponse<NetworkElementResponse> {
  const url = HOST + `/rest/network-element/${id}/execute`;
  return YodaRestClient.post(url, data);
}

export async function deleteEquippedSimNetworkElement(
  simId: string
): ApiResponse<NetworkElementResponse> {
  const url = HOST + `/rest/network-element-equipped-sim/${simId}`;
  return YodaRestClient.delete(url);
}
export async function cancelNetworkElement(id: string): ApiResponse<NetworkElementResponse> {
  const url = HOST + `/rest/network-element/${id}/cancel`;
  return YodaRestClient.post(url, null);
}
export async function getConsumableEquipment(id: string): ApiResponse<ConsumableEquipment[]> {
  const url = HOST + `/rest/network-element-expendables/${id}`;
  return YodaRestClient.get(url);
}
export async function updateConsumableEquipment(
  data: { id: string; quantity: number }[]
): ApiResponse<NetworkElementResponse> {
  const url = HOST + `/rest/network-element-expendables/`;
  return YodaRestClient.put(url, data);
}
///
export async function moveGsmToMeterChange(
  networkElementId: string,
  meterChangeId: string
): ApiResponse<NetworkElementResponse> {
  const url = HOST + `/rest/network-element/${networkElementId}/move/meter-change/${meterChangeId}`;
  return YodaRestClient.post(url, {});
}
export async function swapNetworkElemennt(
  networkElementId: string,
  toSwapnetworkElementId: string
): ApiResponse<NetworkElementResponse> {
  const url = HOST + `/rest/network-element/${networkElementId}/swap/${toSwapnetworkElementId}`;
  return YodaRestClient.post(url, {});
}
export async function getNetworkById(
  networkElementId: string
): ApiResponse<NetworkElementResponse> {
  const url = HOST + `/rest/network-element/${networkElementId}`;
  return YodaRestClient.get(url);
}
