import { SupplyNetworkElementItem, SupplyPUItem, SupplyStamp } from '@/dto/taskmap/Dto';
import { SupplyConstant } from './SupplyConstant';
import useSupplyStore from './SupplyStore';

export const generateScanId = (puListItems: (SupplyPUItem | SupplyNetworkElementItem)[]) => {
  let maxId = 0;
  if (puListItems.length > 0) {
    maxId = Math.max(...puListItems.map((item) => item.tmpId));
  }
  return maxId + 1;
};

export const generateRowNum = (puListItems: (SupplyPUItem | SupplyNetworkElementItem)[]) => {
  let maxRowNum = 0;
  if (puListItems.length > 0) {
    maxRowNum = Math.max(...puListItems.map((item) => item.rowNum));
  }
  return maxRowNum + 1;
};

export const checkLetter = (value: any) => {
  if (
    (value.length != 0 && getLetterFromString(convertValue(value)).length == 0) ||
    value.match(/[a-z.!@?#""%:;()*+,/;\-=]/gi)
  ) {
    return false;
  }
  return true;
};

export const checkStampRegexp = (value: any) => {
  if (value.length > 1 && !value.match(/^[а-яА-Я]\d*$/)) {
    return false;
  }
  return true;
};
export const getLetterFromString = (val: string) => {
  val = val.replace(/[0-9]/g, '');
  return val;
};

export const convertValue = (value: any) => {
  const res = value ? value.replaceAll('T', 'Т').replaceAll('P', 'Р') : value;
  return res;
};

export const checkLettersEquals = (value: SupplyStamp) => {
  if (value.from && value.to) {
    return (
      getLetterFromString(convertValue(value.from)) === getLetterFromString(convertValue(value.to))
    );
  }
  return true;
};

export const checkValueFromTo = (value: SupplyStamp) => {
  if (value.from && value.to && value.to.length > 1) {
    return getNumberFromString(value.from) <= getNumberFromString(value.to);
  }
  return true;
};

export const validateDataStamp = (
  items: SupplyStamp[],
  wholeCount: string,
  declaredCount: string
) => {
  let result = true;
  for (let i = 0; i < items.length; i++) {
    const isLimitCorrect = items[i].count
      ? items[i].count! <= SupplyConstant.stampLimit && items[i].count! > 0
      : false;
    result =
      checkLetter(convertValue(items[i].from)) &&
      checkLetter(convertValue(items[i].to)) &&
      checkStampRegexp(convertValue(items[i].from)) &&
      checkStampRegexp(convertValue(items[i].to)) &&
      checkLettersEquals(items[i]) &&
      isLimitCorrect;
    if (!result) {
      break;
    }
  }
  if (result) {
    result = parseInt(wholeCount) === parseInt(declaredCount);
  }
  return result;
};

export const getNumberFromString = (val: string) => {
  val = val.replace(/[a-zа-яё]/gi, '');
  val = val.replace(/^0+/, '');
  return val.length > 0 ? parseInt(val) : 0;
};

export const getSuccessText = (billTypeId: number, successMessage?: string) => {
  if (successMessage) {
    return successMessage;
  }
  switch (billTypeId) {
    case SupplyConstant.DRAFT_TYPE_ISSUE:
    case SupplyConstant.DRAFT_TYPE_RETURN:
    case SupplyConstant.DRAFT_TYPE_RELOCATION_POST:
    case SupplyConstant.DRAFT_TYPE_FACTORY_TYPE_POST:
      return 'Накладная успешно сохранена в разделе "Архив".';

    case SupplyConstant.RETURNED_TYPE:
      return 'Возврат принят успешно';

    case SupplyConstant.ISSUED_TYPE:
      return 'Накладная успешно выдана подрядчику';

    case SupplyConstant.RELOCATION_TYPE_GET:
      return 'Успешное перемещение на склад';

    case SupplyConstant.RELOCATION_TYPE_POST:
      return 'Успешное отправка внутреннего перемещения';

    case SupplyConstant.FACTORY_TYPE_POST:
      return 'Успешная отправка на завод';

    default:
      return 'Успешно';
  }
};

export enum EquipmentTypes {
  OS = 'network-equipment',
  PU = 'pu',
}

export const isReturnBill = () => {
  const billType = useSupplyStore((state) => state.billType);
  const returnBill =
    billType === SupplyConstant.RETURNED_TYPE ||
    billType === SupplyConstant.DRAFT_TYPE_RETURN ||
    billType === SupplyConstant.RETURNED_TYPE_WAITING_APPROVAL_PROJECT ||
    billType === SupplyConstant.RETURNED_TYPE_AGREED_PROJECT;

  return returnBill;
};
