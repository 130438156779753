import { BoxShadow } from '@/components/ui/BoxShadow';
import { color } from '@/styles/mixins';
import { IconButton, styled } from '@mui/material';

export const HouseCommentsContainer = styled('div')({
  padding: 6,
  position: 'relative',
});

interface HouseCommentsBoxShadowProps {
  open: boolean;
}

export const HouseCommentsBoxShadow = styled(BoxShadow)<HouseCommentsBoxShadowProps>(
  ({ open }) => ({
    position: 'absolute',
    top: 0,
    right: 0,
    width: 'auto',
    padding: 0,
    paddingRight: open ? 10 : 50,
    paddingTop: 50,
    zIndex: 1200,
    transition: 'padding-right 300ms',
  })
);

export const HouseCommentsInner = styled('div')({
  padding: '0px 0px 42px 4px',
  minWidth: 511,
  display: 'flex',
  flexDirection: 'column',
  fontFamily: 'Roboto',
});

export const HouseCommentsTitle = styled('h5')({
  fontSize: 14,
  marginBottom: 24,
  paddingLeft: 6,
});

export const HouseCommentsScrollContainer = styled('div')({
  overflowY: 'auto',
  maxHeight: 400,
  paddingRight: 4,
});

export const HouseCommentsRowColumn = styled('div')({
  display: 'flex',
  flexDirection: 'column',
  gap: 4,
  width: '100%',
  fontStyle: 'italic',
});

export const HouseCommentsRowWrapper = styled('div')({
  display: 'flex',
  gap: 4,
  marginTop: 12,
});

export const HouseCommentsSmallText = styled('div')({
  fontSize: 14,
  color: color('muiBlue'),
});

export const HouseCommentsButton = styled(IconButton)({
  zIndex: 1201,
});

export const HouseCommentsIconButtonWrapper = styled('div')({
  width: 40,
  height: 40,
  margin: 'auto',
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
});

export const HouseCommentsFormIconButtonWrapper = styled(HouseCommentsIconButtonWrapper)({
  flexShrink: 0,
  margin: 'unset',
  marginLeft: 4,
});

export const HouseCommentsInfoRow = styled('div')({
  display: 'flex',
  gap: 10,
  justifyContent: 'space-between',
});

export const HouseCommentsRowCallMessage = styled('div')({
  fontSize: 14,
  color: color('errorGrayNavbar'),
});

interface HouseCommentsFormWrapperProps {
  loading: boolean;
}

export const HouseCommentsFormWrapper = styled('div')<HouseCommentsFormWrapperProps>(
  ({ loading }) => ({
    marginTop: 8,
    paddingLeft: 6,
    display: 'flex',
    flexDirection: 'column',
    gap: 6,
    opacity: loading ? 0.5 : 1,
    pointerEvents: loading ? 'none' : 'auto',
  })
);

export const HouseCommentsFormRow = styled('div')({
  display: 'flex',
  gap: 20,
  alignItems: 'flex-end',
});
