import { Grid, Typography } from '@mui/material';
import { TextField } from '@/components/fixUI';
import { Box } from '@mui/system';
import React, { FC } from 'react';
import { useFormContext } from 'react-hook-form';
import shallow from 'zustand/shallow';
import { useStatus } from '@/components/features/taskEditor/panel/store';
import DateField from '@/components/DateField';
import { TransPhase } from '@/components/Transformer/TransformerAmperage/TransPhase';
import { Trans } from '@/services/TransformerService/dto/TransformerResponseDto';

export enum TTMount {
  MOUNT,
  UNMOUNT,
  NOTMOUNT,
}

interface TransTTProps {
  transformer: Trans;
  mountType: TTMount;
}

export const TransTT: FC<TransTTProps> = ({ transformer, mountType }) => {
  const { register } = useFormContext();
  const { isDisabledAll } = useStatus(
    (state) => ({
      isDisabledAll: state.isDisabledAll,
    }),
    shallow
  );
  const str =
    mountType === TTMount.MOUNT
      ? 'mount'
      : mountType === TTMount.UNMOUNT
      ? 'unmount'
      : 'unexpected';

  return (
    <Box sx={{ p: 2 }}>
      <Grid
        container
        spacing={2}
      >
        <Grid
          item
          xs={12}
          sx={{ display: 'flex', alignItems: 'center' }}
        >
          <Typography
            variant='inherit'
            sx={{ paddingRight: '10px' }}
          >
            {mountType === TTMount.MOUNT
              ? 'Устанавливаемый ТТ:'
              : mountType === TTMount.UNMOUNT
              ? ' Информация о снятом с эксплуатации ТТ:'
              : 'Устанавливаемый ТТ:'}
          </Typography>
          <TextField
            id='tr-type'
            variant='standard'
            sx={{ width: '200px' }}
            {...register(`${str}.type`, {
              value: mountType == TTMount.NOTMOUNT ? 'замена не производилась' : transformer?.type,
            })}
            disabled={mountType == TTMount.NOTMOUNT || isDisabledAll()}
          />
        </Grid>
        {mountType !== TTMount.NOTMOUNT && (
          <>
            <Grid
              item
              xs={4}
            >
              <DateField
                label={'Дата поверки'}
                defaultValue={transformer?.checkDate}
                disabled={isDisabledAll() || mountType === TTMount.UNMOUNT}
                hookFormString={`${str}.checkDate`}
              />
            </Grid>
            <Grid
              item
              xs={4}
            >
              <DateField
                label={'Дата следующей поверки'}
                defaultValue={transformer?.nextCheckDate}
                disabled={isDisabledAll() || mountType === TTMount.UNMOUNT}
                hookFormString={`${str}.nextCheckDate`}
              />
            </Grid>
            <Grid
              item
              xs={12}
            >
              {!!transformer?.phaseList?.length &&
                transformer?.phaseList
                  .sort((a, b) => a.phase.localeCompare(b.phase))
                  .map((elem, index) => (
                    <TransPhase
                      key={elem.id}
                      phase={elem}
                      index={index}
                      keyString={str}
                    />
                  ))}
            </Grid>
          </>
        )}
      </Grid>
    </Box>
  );
};
