import React, { FC } from 'react';
import { Grid, Typography } from '@mui/material';
import { TextField } from '@/components/fixUI';
import { AutocompleteValue } from '@/components/inputs/AutocompleteValue/AutocompleteValue';
import { FormProvider, useForm } from 'react-hook-form';
import { useCatalog } from '@/hooks/CatalogHook';
import { ActionLogType, useActionLog } from '@/hooks/ActionLogHook';
import { CableDescentBox, SaveIconWrapper } from '@/components/CableDescent/CableDescent.styled';
import SaveIcon from '@mui/icons-material/Save';
import { CableDescentResponse } from '@/services/CableDescentService/dto/CableDescentDtoResponse';
import { CableDescentRequest } from '@/services/CableDescentService/dto/CableDescentDtoRequest';
import { saveCableDescent } from '@/services/CableDescentService/CableDescentService';

interface CableDescentForm {
  [x: string]: CableDescentRequest;
}

const yesNoMap = new Map([
  ['true', 'Да'],
  ['false', 'Нет'],
]);

type CableDescentProps = CableDescentResponse;

export const CableDescent: FC<CableDescentProps> = ({
  id,
  cableLength,
  cableTypeId,
  automaticBreakerId,
  type,
  isBoxInstalled,
}) => {
  const methods = useForm<CableDescentForm>();
  const { register, handleSubmit } = methods;

  const { getCatalogMapWithErr } = useCatalog((state) => ({
    getCatalogMapWithErr: state.getCatalogMapWithErr,
  }));
  const { fetchCatch, addActionLog } = useActionLog();

  const onSave = (data: CableDescentForm) => {
    saveCableDescent(id, data[id])
      .then(() => {
        addActionLog(ActionLogType.SUCCESS, 'Информация об опуске успешно сохранена');
      })
      .catch((e) => fetchCatch(e, 'Ошибка при сохранении информации об опуске'));
  };

  return (
    <CableDescentBox>
      <FormProvider {...methods}>
        <Typography
          variant={'h4'}
          mb={2}
        >
          Информация об Опуске
        </Typography>
        <Grid
          container
          spacing={3}
        >
          <Grid
            item
            xs={10 / 3}
          >
            <AutocompleteValue
              title='Вид Опуска'
              defaultValue={type}
              fieldName={`${id}.type`}
              values={getCatalogMapWithErr('cableDescentType')}
            />
          </Grid>
          <Grid
            item
            xs={10 / 3}
          >
            <AutocompleteValue
              title='Марка провода'
              defaultValue={`${cableTypeId}`}
              fieldName={`${id}.cableTypeId`}
              values={getCatalogMapWithErr('cableType')}
            />
          </Grid>
          <Grid
            item
            xs={10 / 3}
          >
            <TextField
              id='cable-length'
              label='Длинна (м)'
              variant='standard'
              defaultValue={cableLength}
              {...register(`${id}.cableLength`, {
                value: cableLength,
              })}
            />
          </Grid>
          <Grid
            item
            xs={10 / 3}
          >
            <AutocompleteValue
              title='Автоматический выключатель'
              defaultValue={automaticBreakerId}
              fieldName={`${id}.automaticBreakerId`}
              values={getCatalogMapWithErr('automaticBreaker')}
            />
          </Grid>
          <Grid
            item
            xs={10 / 3}
          >
            <AutocompleteValue
              title='Установлен шкаф'
              defaultValue={`${isBoxInstalled}`}
              fieldName={`${id}.isBoxInstalled`}
              values={yesNoMap}
            />
          </Grid>
        </Grid>
        <SaveIconWrapper>
          <SaveIcon
            onClick={handleSubmit(onSave)}
            sx={{
              color: 'gray',
              fontSize: 22,
              cursor: 'pointer',
              ml: 2,
            }}
          />
        </SaveIconWrapper>
      </FormProvider>
    </CableDescentBox>
  );
};
