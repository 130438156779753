import { HOST, YodaRestClient } from '@/services/YodaAuthService';
import { ApiResponse } from '@/types/api';
import { TagsResponse } from './dto/TagsDto';

export async function getTags(taskId: string): ApiResponse<TagsResponse[]> {
  const url = HOST + `/rest/task/${taskId}/tag`;
  return YodaRestClient.get(url);
}
export async function createTag(tagName: string): ApiResponse<TagsResponse> {
  const url = HOST + `/rest/tag`;
  return YodaRestClient.post(url, { title: tagName });
}
export async function addTagToTasks(
  taskIdList: string[],
  tagId: string
): ApiResponse<TagsResponse[]> {
  const url = HOST + `/rest/task-tag`;
  return YodaRestClient.post(url, { taskIdList, tagId });
}
export async function deleteTag(taskTagId: string): ApiResponse<TagsResponse> {
  const url = HOST + `/rest/task-tag/${taskTagId}`;
  return YodaRestClient.delete(url);
}
