import { styled } from '@mui/material';

export const SupplyPUCommentModalWrapper = styled('div')({
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  justifyContent: 'center',
  gap: 40,
});

export const SupplyPUCommentModalButtonsWrapper = styled('div')({
  display: 'flex',
  gap: 16,
});
