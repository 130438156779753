import React, { createContext, useContext, ReactNode } from 'react';
import { analytics } from './analytics';

export interface YaMetricsContextProps {
  trackEvent: (eventName: string, payload?: Record<string, unknown>) => void;
}

const YaMetricsContext = createContext<YaMetricsContextProps>({
  trackEvent: analytics.trackEvent,
});

const environment = import.meta.env.MODE || 'development';

interface YaMetricsProviderProps {
  children: ReactNode;
}

export const YaMetricsProvider: React.FC<YaMetricsProviderProps> = ({ children }) => {
  const isOnProd = (): boolean => {
    return environment === 'prod';
  };

  const trackEvent = (eventName: string, payload: Record<string, unknown> = {}): void => {
    if (isOnProd()) {
      analytics.trackEvent(eventName, payload);
    }
  };

  return <YaMetricsContext.Provider value={{ trackEvent }}>{children}</YaMetricsContext.Provider>;
};

export const useYaMetrics = (): YaMetricsContextProps => useContext(YaMetricsContext);
