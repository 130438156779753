import { useEffect } from 'react';

export const REMOVE_KEY = `//div[contains(text(), "MUI X: Missing license key")]`;
/**
 * Находит элемент по XPath (с проверкой на наличие текста)
 */
const getElementByXpath = (xp: string): Node | null => {
  try {
    return document.evaluate(xp, document, null, XPathResult.FIRST_ORDERED_NODE_TYPE, null)
      .singleNodeValue;
  } catch {
    return null;
  }
};
/**
 * Улучшенный хук для удаления элементов лицензионного сообщения MUI X
 */
export const useHackedDataGridPro = () => {
  useEffect(() => {
    const checkAndRemove = () => {
      const element = getElementByXpath(REMOVE_KEY);
      if (element?.parentNode) {
        element.parentNode.removeChild(element);
        return true;
      }
      return false;
    };
    // проверка при монтировании
    checkAndRemove();
    // настройка observer для динамического контента
    const observer = new MutationObserver((mutations) => {
      mutations.forEach(() => {
        checkAndRemove();
      });
    });

    observer.observe(document.body, {
      childList: true,
      subtree: true,
      attributes: false,
      characterData: false,
    });

    return () => {
      observer.disconnect();
    };
  }, []);

  return null;
};
