import DateField from '@/components/DateField';
import { AutocompleteValue } from '@/components/inputs/AutocompleteValue/AutocompleteValue';
import { useCatalog } from '@/hooks/CatalogHook';
import { Grid, Stack, Typography } from '@mui/material';
import { VolatagePhase } from './VoltagePhase';
import { color } from '@/styles/mixins';
import { Trans } from '@/services/TransformerService/dto/TransformerResponseDto';

export enum TNMount {
  MOUNT,
  UNMOUNT,
}
interface VoltageTnProps {
  transformerVoltage?: Trans;
  mountType?: TNMount;
}

const VoltageTn = ({ mountType, transformerVoltage }: VoltageTnProps) => {
  const { getCatalogMapWithErr } = useCatalog((state) => ({
    getCatalogMapWithErr: state.getCatalogMapWithErr,
  }));
  const str = mountType === TNMount.MOUNT ? 'mount' : 'unmount';
  const typeStr = 'type';
  const labelType = mountType === TNMount.MOUNT ? 'Тип установленого ТН' : 'Тип снятого ТН';

  return (
    <Stack>
      <Typography>
        {mountType !== TNMount.MOUNT
          ? 'Информация о снятом с эксплуатации ТН'
          : 'Информация о устанавливаемом ТН'}
      </Typography>
      {transformerVoltage ? (
        <Grid
          item
          container
          spacing={1}
          mt={1}
        >
          <Grid
            item
            xs={4}
          >
            <AutocompleteValue
              title={labelType}
              defaultValue={transformerVoltage?.[typeStr]}
              sx={{ minWidth: '130px' }}
              fieldName={`${str}.${typeStr}`}
              values={getCatalogMapWithErr('voltageTransformerType')}
            />
          </Grid>

          {mountType === TNMount.MOUNT && (
            <>
              <Grid
                item
                xs={4}
              >
                <DateField
                  label={'Дата поверки'}
                  defaultValue={transformerVoltage?.checkDate || undefined}
                  hookFormString={`${str}.checkDate`}
                  roundTime={false}
                />
              </Grid>
              <Grid
                item
                xs={4}
              >
                <DateField
                  label={'Дата следующей поверки'}
                  defaultValue={transformerVoltage?.nextCheckDate || undefined}
                  hookFormString={`${str}.nextCheckDate`}
                  roundTime={false}
                />
              </Grid>
            </>
          )}
          <Grid
            item
            xs={12}
          >
            {!!transformerVoltage?.phaseList.length &&
              transformerVoltage?.phaseList
                .sort((a, b) => a.phase.localeCompare(b.phase))
                .map((elem, index) => (
                  <VolatagePhase
                    key={elem.id}
                    phase={elem}
                    index={index}
                    keyString={str}
                  />
                ))}
          </Grid>
        </Grid>
      ) : (
        <>
          <Stack
            mt={3}
            pl={3}
          >
            <Typography
              color={color('gray2')}
              variant='h6'
            >
              Замена ТН не производилась
            </Typography>
          </Stack>
        </>
      )}
    </Stack>
  );
};

export default VoltageTn;
