import {
  TaskCreationResultWrapper,
  TaskCreationResultsHeader,
  TaskCreationResultItemWrapper,
  TaskCreationResultItem,
  TaskCreationResultItemAnchor,
  TaskCreationResultButtonsWrapper,
} from './TaskCreationResult.styled';
import { toFormateDate } from '@/utils/heplers';
import React from 'react';
import { Button, Tooltip } from '@mui/material';
import { CreateHouseOrderResponseDto } from '@/dto/taskmap/Dto';
import { useCatalog } from '@/hooks/CatalogHook';
import { BaseUI } from '@/types/ui';
export interface TaskCreationResultProps extends BaseUI {
  closeCallback: () => void;
  tasksCreationResults: CreateHouseOrderResponseDto;
  title?: string;
}

const TaskCreationResult = ({
  closeCallback,
  tasksCreationResults,
  title,
  className,
}: TaskCreationResultProps) => {
  const { getGroupCatalog, getContractCatalog } = useCatalog((state) => ({
    getGroupCatalog: state.getGroupCatalog,
    getContractCatalog: state.getContractCatalog,
  }));
  const contractIdsMap = getContractCatalog('convertIntoMap');

  return (
    <TaskCreationResultWrapper className={className}>
      {title && <TaskCreationResultsHeader>{title}</TaskCreationResultsHeader>}

      {tasksCreationResults?.map((order, index) => (
        <TaskCreationResultItemWrapper key={index}>
          <TaskCreationResultItem>
            {'№ '}
            <TaskCreationResultItemAnchor onClick={() => window.open(`/task/edit/${order.taskId}`)}>
              {order.taskNum}
            </TaskCreationResultItemAnchor>
          </TaskCreationResultItem>
          <TaskCreationResultItem>
            <Tooltip title={contractIdsMap.get(order.contractId) || ''}>
              <span>{contractIdsMap.get(order.contractId) || ''}</span>
            </Tooltip>
          </TaskCreationResultItem>
          <TaskCreationResultItem>
            <Tooltip title={getGroupCatalog()?.get(order.contractorId) || ''}>
              <span>{getGroupCatalog()?.get(order.contractorId) || ''}</span>
            </Tooltip>
          </TaskCreationResultItem>
          <TaskCreationResultItem>{toFormateDate(order.plannedDate)}</TaskCreationResultItem>
        </TaskCreationResultItemWrapper>
      ))}
      <TaskCreationResultButtonsWrapper>
        <Button
          onClick={closeCallback}
          variant='outlined'
        >
          Ок
        </Button>
      </TaskCreationResultButtonsWrapper>
    </TaskCreationResultWrapper>
  );
};

export default TaskCreationResult;
