import { Colors } from '@/styles/colors';
import { HouseOrderDto } from '@/dto/taskmap/Dto';
import { EmptyHouseEntranceCube, HouseEntranceShemeFlat } from './HouseEntranceSheme.styled';
import { color } from '@/styles/mixins';
import { PlannedInstalationsSheme } from '../PnrPlanning/store';
import { MONTAGE_PLACE_VALUES } from '@/components/blocks/PnrPlanningModalSteps/util';
import {
  HouseLevelFloorType,
  NOT_DEFINED_MONTAGE_PLACE,
} from '@/components/blocks/PnrElementsInfo/utils';
import {
  SHEME_LEGEND_PLANNING,
  ShemeLayerType,
  SHEME_LEGEND_MONTAGE,
  SCHEME_LAYER_HERMES_OPTION,
  SHEME_LEGEND_HERMES,
  SCHEME_LAYER_MONTAGE_OPTION,
  SCHEME_LAYER_PLANNING_OPTION,
} from '@/components/features/pnr/constants';
import {
  MKDNetworkElementHouseDto,
  ReformaGkhTaskDto,
} from '@/services/HouseDataService/dto/HouseDataServiceResponseDto';

type FLAT_FLOR_STATE =
  | 'empty'
  | 'puWithMAC'
  | 'puWithoutMAC'
  | 'empty_relevant_current'
  | 'puWithMAC_relevant_current'
  | 'puWithoutMAC_relevant_current'
  | 'empty_relevant_previous'
  | 'puWithMAC_relevant_previous'
  | 'puWithoutMAC_relevant_previous';
type FLAT_FLOR_STATE_HERMES = 'empty' | 'puWithMAC_hit' | 'puWithMAC_missed' | 'puWithoutMAC';

export type FLAT_STYLES = {
  background: Colors;
  color?: Colors;
  opacity?: number;
  border?: string;
  backgroundGradient?: string;
};

const NETWORK_PLANNING = {
  ...SHEME_LEGEND_PLANNING,
  order_planning_gateway: {
    background: 'trueWhite',
    text: 'есть заявка Роутер',
    backgroundGradient: `linear-gradient(135deg, ${color('gatewayGreen')} 50%, ${color(
      'trueWhite'
    )} 50%)`,
    narrow: true,
    border: `3px solid ${color('gatewayGreen2')}`,
  },
  order_planning_router: {
    background: 'trueWhite',
    text: 'есть заявка Шлюз',
    backgroundGradient: `linear-gradient(135deg, ${color('trueGray')} 50%, ${color(
      'trueWhite'
    )} 50%)`,
    narrow: true,
    border: `3px solid ${color('darkGray')}`,
  },
} as const;

export const FLAT_STATE_STYLE: { [key in FLAT_FLOR_STATE]: FLAT_STYLES } = {
  empty: { background: 'trueWhite', color: 'textGray', opacity: 1 },
  puWithMAC: { background: 'muiBlue', color: 'trueWhite', opacity: 1 },
  puWithoutMAC: { background: 'muiBlue', color: 'textGray', opacity: 0.12 },

  empty_relevant_current: {
    background: 'trueWhite',
    color: 'textGray',
    border: `2px solid ${color('pink')}`,
  },
  puWithMAC_relevant_current: {
    background: 'muiBlue',
    color: 'trueWhite',
    border: `2px solid ${color('pink')}`,
  },
  puWithoutMAC_relevant_current: {
    background: 'muiBlue',
    color: 'textGray',
    opacity: 0.12,
    border: `2px solid ${color('pink')}`,
  },

  empty_relevant_previous: {
    background: 'trueWhite',
    color: 'textGray',
    border: `2px solid ${color('returnedTaskColorMap')}`,
  },
  puWithMAC_relevant_previous: {
    background: 'muiBlue',
    color: 'trueWhite',
    border: `2px solid ${color('returnedTaskColorMap')}`,
  },
  puWithoutMAC_relevant_previous: {
    background: 'muiBlue',
    color: 'textGray',
    opacity: 0.12,
    border: `2px solid ${color('returnedTaskColorMap')}`,
  },
} as const;

export const FLAT_STATE_STYLE_HERMES: { [key in FLAT_FLOR_STATE_HERMES]: FLAT_STYLES } = {
  empty: { background: 'trueWhite', color: 'textGray', opacity: 1 },
  puWithoutMAC: { background: 'muiBlue', color: 'textGray', opacity: 0.12 },
  puWithMAC_hit: { background: 'darkGreen', color: 'trueWhite', opacity: 1 },
  puWithMAC_missed: { background: 'pink', color: 'trueWhite', opacity: 1 },
} as const;

export const NO_DATA_MAC_VALUE = 'нет данных';

export const getFlatStyles = (
  flatData?: ReformaGkhTaskDto,
  relevantTasksCurrentYear?: string[],
  relevantTasksPreviousYears?: string[],
  flatNumber?: string
) => {
  const getFlatState = (
    flatData?: ReformaGkhTaskDto,
    flatNumber?: string
  ): keyof typeof FLAT_STATE_STYLE => {
    const flatNum = flatNumber ?? '-1';

    if (flatData?.meterType || flatData?.mac) {
      if (flatData?.mac?.toLocaleLowerCase() !== NO_DATA_MAC_VALUE) {
        if (relevantTasksCurrentYear?.includes(flatNum)) {
          return 'puWithMAC_relevant_current';
        } else {
          if (relevantTasksPreviousYears?.includes(flatNum)) {
            return 'puWithMAC_relevant_previous';
          } else {
            return 'puWithMAC';
          }
        }
      } else {
        if (relevantTasksCurrentYear?.includes(flatNum)) {
          return 'puWithoutMAC_relevant_current';
        } else {
          if (relevantTasksPreviousYears?.includes(flatNum)) {
            return 'puWithoutMAC_relevant_previous';
          } else {
            return 'puWithoutMAC';
          }
        }
      }
    }
    if (relevantTasksCurrentYear?.includes(flatNum)) {
      return 'empty_relevant_current';
    }
    if (relevantTasksPreviousYears?.includes(flatNum)) {
      return 'empty_relevant_previous';
    }

    return 'empty';
  };

  const flatState = getFlatState(flatData, flatNumber);
  return FLAT_STATE_STYLE[flatState];
};

export const getHermesFlatStyles = (flatData?: ReformaGkhTaskDto, positiveMacs?: Set<string>) => {
  const getHermesFlatState = (
    flatData?: ReformaGkhTaskDto
  ): keyof typeof FLAT_STATE_STYLE_HERMES => {
    if (flatData?.meterType || flatData?.mac) {
      if (flatData?.mac?.toLowerCase() !== NO_DATA_MAC_VALUE) {
        if (positiveMacs?.has(flatData?.mac ?? '1')) {
          return 'puWithMAC_hit';
        } else {
          return 'puWithMAC_missed';
        }
      } else {
        return 'puWithoutMAC';
      }
    }
    return 'empty';
  };
  const flatState = getHermesFlatState(flatData);
  return FLAT_STATE_STYLE_HERMES[flatState];
};

export const getNetworkElementsCube = (
  networkElementsSheme: Map<number, Map<string, MKDNetworkElementHouseDto[]>>,
  entrance: number,
  floor: number | string | null,
  type: 'router' | 'gateway',
  shemeLayer: ShemeLayerType,
  positiveMacs: Set<string>,
  empty?: boolean,
  hideEmpty?: boolean
) => {
  if (empty) return <EmptyHouseEntranceCube />;

  const neededElements = networkElementsSheme
    .get(entrance)
    ?.get((floor ?? NOT_DEFINED_MONTAGE_PLACE)?.toString())
    ?.filter((el) => el?.type?.toLocaleLowerCase() === SHEME_LEGEND_MONTAGE[type].text);

  if (neededElements?.length) {
    if (shemeLayer === SCHEME_LAYER_HERMES_OPTION) {
      if (type === 'router') {
        const positiveElements = neededElements?.filter((el) =>
          positiveMacs.has(el.smrMac ?? '-1')
        );

        let routerState: keyof typeof SHEME_LEGEND_HERMES = 'router_missed';
        let routerText = neededElements.length.toString();
        if (positiveElements.length === neededElements.length) {
          routerState = 'router_hit_full';
        }
        if (positiveElements.length < neededElements.length) {
          routerState = 'router_hit_partly';
          routerText = `${positiveElements?.length}/${neededElements.length}`;
        }
        if (!positiveElements.length) {
          routerState = 'router_missed';
          routerText = neededElements.length.toString();
        }

        return (
          <HouseEntranceShemeFlat
            sideWidth
            flatStyles={SHEME_LEGEND_HERMES[routerState]}
          >
            {routerText}
          </HouseEntranceShemeFlat>
        );
      } else {
        return (
          <HouseEntranceShemeFlat
            sideWidth
            flatStyles={SHEME_LEGEND_MONTAGE[type]}
          >
            {neededElements?.length}
          </HouseEntranceShemeFlat>
        );
      }
    }
    if (shemeLayer === SCHEME_LAYER_MONTAGE_OPTION) {
      return (
        <HouseEntranceShemeFlat
          sideWidth
          flatStyles={SHEME_LEGEND_MONTAGE[type]}
        >
          {neededElements?.length}
        </HouseEntranceShemeFlat>
      );
    }
    if (shemeLayer === SCHEME_LAYER_PLANNING_OPTION) {
      return (
        <HouseEntranceShemeFlat
          sideWidth
          flatStyles={SHEME_LEGEND_PLANNING[type]}
        >
          {neededElements?.length}
        </HouseEntranceShemeFlat>
      );
    }
  } else
    return (
      <HouseEntranceShemeFlat
        sideWidth
        empty={hideEmpty}
      />
    );
};

const getMontagePlace = (order: HouseOrderDto) => {
  const montagePlace = order?.houseLevel?.floorType as MONTAGE_PLACE_VALUES;
  const montageFloor = order?.houseLevel?.floorNumber?.toString() ?? '';
  switch (montagePlace) {
    case HouseLevelFloorType.ATTIC:
      return montagePlace;
    case HouseLevelFloorType.BASEMENT:
      return montagePlace;
    case null:
      return montagePlace;
    case HouseLevelFloorType.FLOOR:
      return montageFloor;

    default:
      return NOT_DEFINED_MONTAGE_PLACE;
  }
};

const getNetworkElementStatus = (
  type: 'router' | 'gateway',
  planningsCount: number,
  ordersCount: number
): keyof typeof NETWORK_PLANNING => {
  if (planningsCount && ordersCount) {
    if (type === 'router') return 'order_planning_router';
    return 'order_planning_gateway';
  }
  if (planningsCount && !ordersCount) {
    return type;
  }
  if (!planningsCount && ordersCount) {
    if (type === 'router') return 'order_router';
    return 'order_gateway';
  }
  return 'empty';
};

export enum NetworkEquipmentIds {
  router = '2592166e-96de-6b80-57c6-172c5fb63068',
  gateway = '8034dd00-f1fb-8f52-eed7-13b2724854bb',
}
export const getPlanningNetworkElementsCube = (
  entrance: number,
  floor: number | string | null,
  type: 'router' | 'gateway',
  plannedInstalationsSheme: PlannedInstalationsSheme,
  plannedOrders: Map<number, HouseOrderDto[]>,
  hideEmpty?: boolean
) => {
  const plannedElements =
    plannedInstalationsSheme
      .get(entrance)
      ?.get((floor ?? -2).toString())
      ?.filter(
        (el) => el?.equipmentTypeFilter?.label?.toLowerCase() === SHEME_LEGEND_MONTAGE[type].text
      )?.length ?? 0;

  const plannedOrdersElements =
    plannedOrders
      .get(entrance)
      ?.filter(
        (order) =>
          getMontagePlace(order) === (floor ?? -2).toString() &&
          order.typeId === NetworkEquipmentIds[type]
      )?.length ?? 0;

  const networkElementStatus = getNetworkElementStatus(
    type,
    plannedElements,
    plannedOrdersElements
  );

  if (plannedElements > 0 || plannedOrdersElements > 0) {
    return (
      <HouseEntranceShemeFlat
        sideWidth
        flatStyles={NETWORK_PLANNING[networkElementStatus]}
      >
        {plannedElements + plannedOrdersElements}
      </HouseEntranceShemeFlat>
    );
  } else
    return (
      <HouseEntranceShemeFlat
        sideWidth
        empty={hideEmpty}
      />
    );
};

export const SCHEME_OPEN_QUERY = 'scheme_open';
