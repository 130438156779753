import {
  Button,
  Grid,
  List,
  Card,
  ListItem,
  ListItemText,
  ListItemIcon,
  Checkbox,
} from '@mui/material';
import React, { useEffect } from 'react';
import styles from './Popup.module.scss';
import { IPopup, Popup } from './Popup';
import {
  addToContractList,
  getContractList,
  getContractListForAdd,
  removeFromContractList,
} from '@/services/RegistryService/RegistryService';

interface IAccessPopup extends IPopup {
  successText?: string;
  closeText?: string;
}

const TransferList = () => {
  const [checkedId, setCheckedId] = React.useState<string>('');

  const [list, setList] = React.useState<any[]>([]);
  const [addList, setAddList] = React.useState<any[]>([]);

  const update = () => {
    getContractList().then((res) => setList(res.data));
    getContractListForAdd().then((res) => setAddList(res.data));
  };

  useEffect(() => update(), []);

  const handleCheckedRight = () => {
    addToContractList(checkedId).then(() => {
      update();
    });
  };

  const handleCheckedLeft = () => {
    removeFromContractList(checkedId).then(() => {
      update();
    });
  };

  const customList = (title: React.ReactNode, items: any[]) => (
    <Card>
      <List
        sx={{
          width: 220,
          height: 630,
          bgcolor: 'background.paper',
          overflow: 'auto',
        }}
        dense
        component='div'
        role='list'
      >
        {items.map((value) => {
          const labelId = `transfer-list-all-item-${value.id}-label`;

          return (
            <ListItem
              key={value.id}
              role='listitem'
              button
              onClick={() => setCheckedId(value.id)}
            >
              <ListItemIcon>
                <Checkbox
                  checked={checkedId === value.id}
                  tabIndex={-1}
                  disableRipple
                  inputProps={{
                    'aria-labelledby': labelId,
                  }}
                />
              </ListItemIcon>
              <ListItemText
                id={labelId}
                primary={value.contractName}
              />
            </ListItem>
          );
        })}
      </List>
    </Card>
  );

  return (
    <Grid
      container
      spacing={2}
      justifyContent='center'
      alignItems='center'
    >
      <Grid item>{customList('Choices', addList)}</Grid>
      <Grid item>
        <Grid
          container
          direction='column'
          alignItems='center'
        >
          <Button
            sx={{ my: 0.5 }}
            variant='outlined'
            size='small'
            onClick={handleCheckedRight}
            disabled={addList.findIndex((e) => e.id === checkedId) === -1}
            aria-label='move selected right'
          >
            &gt;
          </Button>
          <Button
            sx={{ my: 0.5 }}
            variant='outlined'
            size='small'
            onClick={handleCheckedLeft}
            disabled={list.findIndex((e) => e.id === checkedId) === -1}
            aria-label='move selected left'
          >
            &lt;
          </Button>
        </Grid>
      </Grid>
      <Grid item>{customList('Chosen', list)}</Grid>
    </Grid>
  );
};

export const TransferListPopup: React.FC<IAccessPopup> = ({
  isOpen,
  onClose,
  onSuccess,
  successText,
}) => {
  return (
    <Popup
      isOpen={isOpen}
      onClose={onClose}
      onSuccess={onSuccess}
    >
      <Grid
        container
        spacing={2}
      >
        <TransferList />

        <Grid
          item
          xs={12}
        >
          <div className={styles['modal-buttons']}>
            <Button
              onClick={onSuccess}
              variant='contained'
            >
              {successText ? successText : 'OK'}
            </Button>
          </div>
        </Grid>
      </Grid>
    </Popup>
  );
};
