import { Box, Grid, Stack, Typography } from '@mui/material';
import React, { ReactNode, useEffect, useState } from 'react';
import { toFormateDate } from '@/utils/heplers';
import { ReplacementResponseList, VruInputResponse } from '@/dto/taskmap/task/TaskResultDto';
import { GeneralReplacement } from './GeneralReplacement';
import { getEnRuStatus } from '@/components/ChangeActStatus/constants';
import { useCatalog } from '@/hooks/CatalogHook';
import { ExpandButton } from '@/components/button/ExpandButton/ExpandButton';
import { ModeViewerResult } from '@/components/features/taskEditor/types';
import TransformerVoltage from '@/components/Transformer/TransformerVoltage';
import { ReplacementStateIcon } from '@/components/ReplacementStateIcon/ReplacementStateIcon';
import { DownloadType } from '@/components/popups/DownloadPopup/DownloadPopup';

interface OdpuItemProps {
  vruInput: VruInputResponse;
  odpu: ReplacementResponseList;
  ids?: {
    executorId?: string;
    id?: string;
    contractorId?: string;
    vruId?: string;
    contractId?: string;
  };
  updateReplacementResponse?: () => void;
  downloadId?: string;
}

export const OdpuItem = (props: OdpuItemProps) => {
  const { vruInput, odpu, updateReplacementResponse, downloadId = '' } = props;
  const { getCatalogMapWithErr } = useCatalog((state) => ({
    getCatalogMapWithErr: state.getCatalogMapWithErr,
  }));
  useEffect(() => {
    getCatalogMapWithErr('amperageTransformerType');
    getCatalogMapWithErr('stampInstallStatus');
    getCatalogMapWithErr('actStatus');
    getCatalogMapWithErr('changeStatus');
  }, []);
  const [innerMode, setInnerMode] = useState<ModeViewerResult>(ModeViewerResult.SMALL);
  const handleMode = () => (isExpanded: boolean) => {
    setInnerMode(isExpanded ? ModeViewerResult.LARGE : ModeViewerResult.SMALL);
  };

  const getPreview = (): ReactNode => {
    return (
      <Grid
        container
        direction='row'
        justifyContent='space-between'
        alignItems='center'
        spacing={1}
      >
        <Grid item>
          <Stack
            direction={'row'}
            spacing={5}
          >
            <ReplacementStateIcon status={odpu?.replacementState} />
            <Typography>ПУ: {odpu?.mounted?.number}</Typography>
            <Typography>
              {toFormateDate(odpu?.replacedOn)}{' '}
              {(odpu?.smrLockerId &&
                getCatalogMapWithErr('smrLocker').get(`${odpu.smrLockerId}`)) ||
                ''}
            </Typography>
            <Typography>Статус акта: {getEnRuStatus(odpu?.actStatus)}</Typography>
          </Stack>
        </Grid>
        <Grid item>
          <Stack
            direction={'row'}
            justifyContent='flex-end'
          >
            <Typography>Замена</Typography>
            <ExpandButton
              onAction={handleMode()}
              sx={{ marginLeft: 1 }}
            />
          </Stack>
        </Grid>
      </Grid>
    );
  };
  return (
    <>
      {odpu && (
        <Box sx={{ backgroundColor: '#FCFCFC' }}>
          <GeneralReplacement
            preview={getPreview()}
            replacementResponse={odpu}
            taskType='SMR_ODPU'
            ids={{ ...props.ids, vruInputId: vruInput.id }}
            updateReplacementResponse={updateReplacementResponse}
            modeCallback={handleMode}
            mode={innerMode}
          >
            <TransformerVoltage
              downloadId={vruInput.id}
              downloadType={DownloadType.ADPU_VRU}
            />
          </GeneralReplacement>
        </Box>
      )}
    </>
  );
};
