import { Typography } from '@mui/material';
import React, { useEffect, useState } from 'react';
import { useFormContext } from 'react-hook-form';
import { ConfirmationDialog } from '@/components/common/ConfirmationDialog';
import { SaveButton } from '@/components/ui/SaveButton/SaveButton';
import { TaskResponse } from '@/dto/taskmap/task/TasksDto';
import { ActionLogType, syncErrorCatch, useActionLog } from '@/hooks/ActionLogHook';
import { useScreenHoldHook } from '@/hooks/ScreenHoldHook';
import {
  checkTask,
  createConfirm,
  updateTask,
  toChangeTaskType,
  toCloseTask,
  toCloseTaskIgnoreYunga,
  toReturnNotRelevantTask,
} from '@/services/TaskService/TaskService';
import { TaskExecutorSelect } from '@/components/features/taskEditor/dialog/TaskExecutorSelect';
import { useStatus } from '../store';
import { IdPopup } from '../IdPopup';
import { useCatalog } from '@/hooks/CatalogHook';
import CopyButton from '@/components/button/CopyButton/CopyButton';
import { PopupOpenButton } from '@/components/popups/PopupOpenButton';
import { GroupButtons } from '@/components/TableMenu';

import { getLastYungaAnalyzeValidationMessage } from '@/services/ReplacementService/ReplacementService';
import { useLastComment } from '@/components/features/taskEditor/hooks/useLastComment';
import { TASK_STATUS, TASK_TYPE } from './utils';
import { useDisabledAll } from '@/hooks/useAccess';
import { TaskControlPanelButtonWrapper, TaskControlPanelStack } from './TaskControlPanel.styled';
import { useTaskEditorOnlyShow } from '../../useTaskEditorOnlyShow';

export enum screenPosition {
  TOP,
  LEFT,
}

interface TaskEditorPanelProps {
  taskResponse?: TaskResponse;
  handleChangeTask(taskResponse: TaskResponse): void;
  positionOnScreen?: screenPosition;
  reload: () => void;
}

const isOdpu = (value: string | undefined) =>
  value === 'SMR_ODPU' ||
  value === 'SMR_ODPU_MOEK' ||
  value === 'SMR_ODPU_ENTITY' ||
  value === 'CLAIM_ODPU';

export const TaskControlPanel = (props: TaskEditorPanelProps) => {
  const onlyShow = useTaskEditorOnlyShow();
  const disabledAll = useDisabledAll();
  const { taskResponse, handleChangeTask, positionOnScreen, reload } = props;
  const { handleSubmit } = useFormContext();
  const { getCatalogMapWithErr } = useCatalog((state) => ({
    getCatalogMapWithErr: state.getCatalogMapWithErr,
  }));
  const { lastComment } = useLastComment((state) => ({ lastComment: state.lastComment }));
  const { addActionLog, fetchCatch } = useActionLog();
  const { setIsInProgress } = useScreenHoldHook();
  const [openTaskCloseDialog, setOpenTaskCloseDialog] = useState(false);
  const [openChangeType, setOpenChangeType] = useState(false);
  const [openTaskCloseIgnoreYungaDialog, setOpenTaskCloseIgnoreYungaDialog] = useState(false);
  const [openTaskExecutorSelectDialog, setOpenTaskExecutorSelectDialog] = useState(false);
  const [validationMessage, setValidationMessage] = useState<string | null>(null);

  const { editable, setEditable } = useStatus((state) => ({
    setEditable: state.setEditable,
    editable: state.editable,
  }));

  useEffect(() => {
    const taskId = taskResponse?.id;
    taskId &&
      getLastYungaAnalyzeValidationMessage(taskId).then((res) => setValidationMessage(res.data));
  }, [taskResponse]);

  const onHandleSave = (data: any) => {
    data.type = data.type ? data.type : taskResponse?.type;
    const taskId = taskResponse?.id;

    if (
      taskId &&
      data &&
      (!isOdpu(data.type) || data.odpuInfoRequest.odpuManagementCompanyRequest.id)
    ) {
      setIsInProgress(true);
      updateTask(taskId, data)
        .then(() => {
          addActionLog(ActionLogType.SUCCESS, `Заявка №${taskResponse?.number} успешно сохранена`);
        })
        .catch((err) => fetchCatch(err, `Ошибка при сохранении заявки №${taskResponse?.number}`))
        .finally(() => setIsInProgress(false));
    } else {
      addActionLog(
        ActionLogType.ERROR,
        'Ошибка при сохранении заявки, данные некорректны',
        'Поле "Управляющая компания" должно быть заполнено'
      );
    }
  };

  const checkBeforEdit = () => {
    const taskId = taskResponse?.id;
    if (taskId) {
      checkTask(taskId)
        .then(({ data }) => {
          handleChangeTask(data);
          setEditable(true);
          addActionLog(ActionLogType.SUCCESS, `Заявка №${taskResponse?.number} на проверке`);
        })
        .catch((err) => {
          fetchCatch(err, `Заявку №${taskResponse?.number} нельзя редактировать`);
          setEditable(false);
        });
    }
  };
  const confirmation = () => {
    const taskId = taskResponse?.id;
    if (taskId) {
      createConfirm(taskId)
        .then(({ data }) => {
          handleChangeTask(data);
          setEditable(true);
          addActionLog(ActionLogType.SUCCESS, `Заявка №${taskResponse?.number} подтверждена`);
        })
        .catch((err) => {
          fetchCatch(err, `Заявку №${taskResponse?.number} нельзя подтвердить`);
          setEditable(false);
        });
    }
  };

  const handleCLoseTask = () => {
    setOpenTaskCloseDialog(true);
  };

  const handleReturnTask = () => {
    setOpenTaskExecutorSelectDialog(true);
  };

  const handleReturnNotRelevantTask = (data: any) => {
    data.type = data.type ? data.type : taskResponse?.type;
    const taskId = taskResponse?.id;
    if (taskId) {
      setIsInProgress(true);
      toReturnNotRelevantTask(taskId, data)
        .then(({ data }) => {
          addActionLog(
            ActionLogType.SUCCESS,
            `Заявка №${data?.number} переведена в статус: ${
              getCatalogMapWithErr('taskStatus')?.get(data?.status) ?? ''
            }`
          );
          handleChangeTask(data);
        })
        .catch((err) => fetchCatch(err, `Ошибка при переводе заявки в актуальный статус`))
        .finally(() => setIsInProgress(false));
    }
  };

  const handleChangeType = () => {
    if (lastComment?.length === 0) {
      syncErrorCatch('Для смены типа заявки, заявка должна иметь комментарий');
    } else {
      setOpenChangeType(true);
    }
  };

  const closeTask = () => {
    const taskId = taskResponse?.id;
    if (taskId) {
      if (!validationMessage || validationMessage == '') {
        setIsInProgress(true);
        toCloseTask(taskId)
          .then((res) => {
            addActionLog(ActionLogType.SUCCESS, `Заявка №${taskResponse?.number} закрыта`);
            handleChangeTask(res.data);
          })
          .catch((err) => {
            fetchCatch(err, `Ошибка при закрытии заявки №${taskResponse?.number}`);
          })
          .finally(() => setIsInProgress(false));
      } else {
        setOpenTaskCloseIgnoreYungaDialog(true);
      }
    }
    setOpenTaskCloseDialog(false);
  };

  const closeTaskIgnoreYunga = () => {
    const taskId = taskResponse?.id;
    if (taskId) {
      setIsInProgress(true);
      toCloseTaskIgnoreYunga(taskId)
        .then((res) => {
          addActionLog(ActionLogType.SUCCESS, `Заявка №${taskResponse?.number} закрыта`);
          handleChangeTask(res.data);
        })
        .catch((err) => fetchCatch(err, `Ошибка при закрытии заявки №${taskResponse?.number}`))
        .finally(() => setIsInProgress(false));
    }
    setOpenTaskCloseIgnoreYungaDialog(false);
  };

  const changeTaskType = () => {
    const taskId = taskResponse?.id;
    const taskType = taskResponse?.type;
    setIsInProgress(true);
    if (taskId && taskType) {
      toChangeTaskType(taskId, TASK_TYPE.COMPLAINT_FZ_522, lastComment)
        .then((res) => {
          addActionLog(
            ActionLogType.SUCCESS,
            `Заявка №${taskResponse?.number} переведена в жалобу`
          );
          reload();
        })
        .catch((err) =>
          fetchCatch(err, `Ошибка при переводе заявки №${taskResponse?.number} в жалобу`)
        )
        .finally(() => setIsInProgress(false));
    }
    setOpenChangeType(false);
  };

  const isConfirmed: boolean =
    !!getCatalogMapWithErr('fzOrTechConnectionTaskType').get(taskResponse?.type || '') &&
    !!taskResponse?.needCreateConfirmation;

  return (
    <>
      <TaskControlPanelStack>
        {positionOnScreen === screenPosition.TOP && (
          <IdPopup title={'id'}>
            <div>
              <CopyButton textToCopy={taskResponse?.id} />
              id заявки {taskResponse?.id}
            </div>
          </IdPopup>
        )}
        <TaskControlPanelButtonWrapper>
          <SaveButton
            onSave={handleSubmit(onHandleSave)}
            disabled={
              disabledAll ||
              (onlyShow ? true : editable ? false : taskResponse?.status === TASK_STATUS.COMPLETED)
            }
          />
          {positionOnScreen === screenPosition.TOP && (
            <GroupButtons
              groupName={'работа с заявкой'}
              disabled={disabledAll || onlyShow}
            >
              <PopupOpenButton
                onClick={checkBeforEdit}
                disabled={editable}
              >
                Редактировать заявку
              </PopupOpenButton>
              <PopupOpenButton
                disabled={
                  taskResponse?.status === TASK_STATUS.RETURNED ||
                  taskResponse?.status === TASK_STATUS.COMPLETED ||
                  taskResponse?.status === TASK_STATUS.NOT_RELEVANT
                }
                onClick={handleReturnTask}
              >
                Возврат исполнителю
              </PopupOpenButton>
              <PopupOpenButton
                disabled={
                  !(
                    taskResponse?.status === TASK_STATUS.NEW ||
                    taskResponse?.status === TASK_STATUS.ASSIGNED
                  )
                }
              >
                Взять в работу
              </PopupOpenButton>
              <PopupOpenButton
                disabled={
                  taskResponse?.status === TASK_STATUS.COMPLETED ||
                  ((taskResponse?.status === TASK_STATUS.NEW ||
                    taskResponse?.status === TASK_STATUS.ASSIGNED) &&
                    !isConfirmed)
                }
                onClick={handleCLoseTask}
              >
                Закрыть заявку
              </PopupOpenButton>
              {(taskResponse?.type == TASK_TYPE.FZ_522 ||
                taskResponse?.type == TASK_TYPE.COMPLAINT_FZ_522 ||
                taskResponse?.type == TASK_TYPE.MASS_INSTALLATION_FZ_522 ||
                taskResponse?.type == TASK_TYPE.TECH_CONNECTION) && (
                <PopupOpenButton
                  disabled={
                    (taskResponse?.status === TASK_STATUS.NEW ||
                      taskResponse?.status === TASK_STATUS.ASSIGNED) &&
                    isConfirmed
                  }
                  onClick={confirmation}
                >
                  Подтвердить заявку
                </PopupOpenButton>
              )}
              {(taskResponse?.type == TASK_TYPE.FZ_522 ||
                taskResponse?.type == TASK_TYPE.MASS_INSTALLATION_FZ_522) && (
                <PopupOpenButton
                  disabled={
                    taskResponse?.status === TASK_STATUS.NOT_RELEVANT ||
                    taskResponse?.status === TASK_STATUS.COMPLETED
                  }
                  onClick={handleChangeType}
                >
                  Перевести в жалобу
                </PopupOpenButton>
              )}
              <PopupOpenButton
                disabled={taskResponse?.status !== TASK_STATUS.NOT_RELEVANT}
                onClick={handleSubmit(handleReturnNotRelevantTask)}
              >
                Заявка актуальна
              </PopupOpenButton>
            </GroupButtons>
          )}
        </TaskControlPanelButtonWrapper>
      </TaskControlPanelStack>
      <ConfirmationDialog
        title={`Закрытие заявки № ${taskResponse?.number}`}
        handleOk={closeTask}
        handleCancel={() => setOpenTaskCloseDialog(false)}
        open={openTaskCloseDialog}
      >
        <Typography variant='body1'>
          После закрытия заявки отменить, действие будет невозможно!
        </Typography>
        <Typography variant='body1'>Продолжить?</Typography>
      </ConfirmationDialog>
      <ConfirmationDialog
        title={`Перевод заявки № ${taskResponse?.number} в жалобу`}
        handleOk={changeTaskType}
        handleCancel={() => setOpenChangeType(false)}
        open={openChangeType}
      >
        <Typography variant='body1'>Продолжить?</Typography>
      </ConfirmationDialog>
      <ConfirmationDialog
        title={`Закрытие заявки № ${taskResponse?.number}`}
        handleOk={closeTaskIgnoreYunga}
        handleCancel={() => setOpenTaskCloseIgnoreYungaDialog(false)}
        open={openTaskCloseIgnoreYungaDialog}
        textForOk={'закрыть принудительно'}
      >
        <Typography variant='body1'>
          В результате проверки системой выявлены следующие ошибки:
        </Typography>
        <Typography variant='body1'>{validationMessage}</Typography>
        <Typography variant='body1'>Вы уверены что хотите закрыть заявку?</Typography>
      </ConfirmationDialog>
      <TaskExecutorSelect
        taskResponse={taskResponse!}
        openDialog={openTaskExecutorSelectDialog}
        handleCloseDialog={() => setOpenTaskExecutorSelectDialog(false)}
        handleChangeTask={handleChangeTask}
      />
    </>
  );
};
