import { GeoBounds, GeoRequest, GeoTask, PnrTransformerStation } from '@/dto/taskmap/Dto';
import {
  ApiFindAllPnrTag,
  ApiFindAllTaskIdAroundTaskByPnrTag,
  ApiFindAlPnrTransformerStation,
} from '@/services/YodaRestService/YodaRestService';
import { createStore } from '@/utils/createStore';

interface PnrStore {
  pnrTagCount: number;
  taskTaggedCount: number;
  pnrTagItems: Map<GeoTask, PnrTagItem>;
  pnrTransformerStationItems: PnrTransformerStation[];
  addPnrTag: (geoTask: GeoTask) => void;
  setCurrentPnrTag: (geoTask: GeoTask | undefined, pnrTag: string) => void;
  isPnrTagAvailable: (geoTask: GeoTask | undefined, pnrTag: string) => boolean;
  isTaskTagged: (taskId: string) => boolean;
  getCurrentPnrTag: (geoTask: GeoTask | undefined) => string | undefined;
  setTaskIdList: (geoTask: GeoTask | undefined, taskIdList: string[]) => void;
  getTaskIdList: (geoTask: GeoTask | undefined) => string[] | undefined;
  removePnrTagByGeoTask: (geoTask: GeoTask | undefined) => void;
  setAllTaskCount: () => void;
  removeAllPnrTag: () => void;
  loadTaskIdListByPnrTag: (
    geoTask: GeoTask | undefined,
    geoRequest: GeoRequest | undefined
  ) => Promise<string>;
  loadPnrTransformerStation: (geoBounds: GeoBounds) => Promise<string>;
  getPnrTagItem: (geoTask: GeoTask) => PnrTagItem | undefined;
}

export type PnrTagItem = {
  currentPnrTag: string | undefined;
  taskIdList: string[] | undefined;
  availablePnrTagList: string[];
  resultMessage: string | undefined;
  errorMessage: string | undefined;
};

export const usePnr = createStore<PnrStore>(
  (set, get) => ({
    pnrTagCount: 0,
    taskTaggedCount: 0,
    pnrTagItems: new Map(),
    isLoading: false,
    loadingError: null,
    pnrTransformerStationItems: [],

    addPnrTag(geoTask: GeoTask) {
      if (geoTask?.taskId) {
        const items = get().pnrTagItems as Map<GeoTask, PnrTagItem>;
        const item: PnrTagItem = items.get(geoTask) || {
          currentPnrTag: undefined,
          taskIdList: undefined,
          availablePnrTagList: [],
          resultMessage: undefined,
          errorMessage: undefined,
        };
        ApiFindAllPnrTag(geoTask?.taskId)
          .then((res: any) => {
            item.availablePnrTagList = res.data;
            items.set(geoTask, item);
            set({ pnrTagItems: items });
          })
          .catch((err: any) => {
            items.delete(geoTask);
            set({ pnrTagItems: items });
          });
      }
    },
    setCurrentPnrTag(geoTask: GeoTask | undefined, pnrTag: string) {
      if (geoTask) {
        const map = get().pnrTagItems as Map<GeoTask, PnrTagItem>;
        const item: PnrTagItem | undefined = map.get(geoTask);
        if (item) {
          item.currentPnrTag = pnrTag;
          map.set(geoTask, item);
          set({ pnrTagItems: map });
        }
      }
      set({ pnrTagCount: get().pnrTagCount + 1 });
    },

    isPnrTagAvailable(geoTask: GeoTask | undefined, pnrTag: string): boolean {
      if (geoTask) {
        const map = get().pnrTagItems as Map<GeoTask, PnrTagItem>;
        const item: PnrTagItem | undefined = map.get(geoTask);
        if (item) {
          return item?.availablePnrTagList?.indexOf(pnrTag) == -1;
        }
      }
      return true;
    },

    getCurrentPnrTag(geoTask: GeoTask | undefined) {
      if (geoTask) {
        const map = get().pnrTagItems as Map<GeoTask, PnrTagItem>;
        const item: PnrTagItem | undefined = map.get(geoTask);
        return item?.currentPnrTag;
      }
    },

    setTaskIdList(geoTask: GeoTask | undefined, taskIdList: string[]) {
      if (geoTask) {
        const items = get().pnrTagItems as Map<GeoTask, PnrTagItem>;
        const item: PnrTagItem | undefined = items.get(geoTask);
        if (item) {
          item.taskIdList = taskIdList;
          items.set(geoTask, item);
          set({ pnrTagItems: items });
        }
      }
      get().setAllTaskCount();
    },

    getTaskIdList(geoTask: GeoTask | undefined) {
      if (geoTask) {
        const map = get().pnrTagItems as Map<GeoTask, PnrTagItem>;
        const item: PnrTagItem | undefined = map.get(geoTask);
        if (item) {
          return item.taskIdList;
        }
      }
    },

    isTaskTagged(taskId: string): boolean {
      const pnrTagItems = get().pnrTagItems;
      if (pnrTagItems) {
        for (const item of pnrTagItems.values()) {
          if (item.taskIdList && item.taskIdList.indexOf(taskId) > -1) {
            return true;
          }
        }
      }
      return false;
    },

    removePnrTagByGeoTask(geoTask: GeoTask | undefined) {
      if (geoTask) {
        const pnrTagItems = get().pnrTagItems;
        pnrTagItems.delete(geoTask);
        set({ pnrTagItems: pnrTagItems });
      }
      get().setAllTaskCount();
    },

    setAllTaskCount() {
      const pnrTagItems = get().pnrTagItems;
      let count = 0;
      if (pnrTagItems) {
        for (const item of pnrTagItems.values()) {
          if (item.taskIdList) {
            count = count + item.taskIdList?.length;
          }
        }
      }
      set({ taskTaggedCount: count });
    },

    removeAllPnrTag() {
      set({ pnrTagItems: undefined, pnrTagCount: 0 });
    },

    loadTaskIdListByPnrTag: async (
      geoTask: GeoTask | undefined,
      geoRequest: GeoRequest | undefined
    ) => {
      if (geoRequest && geoTask?.taskId) {
        let isOk;
        let msg = '';
        await ApiFindAllTaskIdAroundTaskByPnrTag(geoTask?.taskId, geoRequest)
          .then((res) => {
            get().setTaskIdList(geoTask, res.data);
            isOk = true;
            msg = `Найдено: ${res.data.length}  шт.`;
          })
          .catch((err) => {
            if (err?.response?.status === 400) {
              get().setTaskIdList(geoTask, []);
              msg = 'Ошибка: ' + err.response?.data;
            } else {
              msg = 'Ошибка! Обратитесь к администратору';
            }
            isOk = false;
          });

        const item = get().getPnrTagItem(geoTask);
        if (item && isOk) {
          item.resultMessage = msg;
        } else if (item && !isOk) {
          item.errorMessage = msg;
        }
        if (isOk) {
          return Promise.resolve(msg);
        } else {
          return Promise.reject(msg);
        }
      }
      return Promise.reject('');
    },

    loadPnrTransformerStation: async (geoBounds: GeoBounds) => {
      if (geoBounds) {
        let msg = undefined;
        await ApiFindAlPnrTransformerStation(geoBounds)
          .then((res) => {
            set({ pnrTransformerStationItems: res.data });
          })
          .catch((err) => {
            msg = err.response.data;
          });
        if (msg) {
          return Promise.reject(msg);
        } else {
          return Promise.resolve('');
        }
      }
      return Promise.reject('GeoBounds not defined');
    },

    getPnrTagItem: (geoTask: GeoTask) => {
      const items: Map<GeoTask, PnrTagItem> = get().pnrTagItems;
      return items.get(geoTask);
    },
  }),
  ' Pnr'
);
