import React from 'react';
import LoadSpinner from '@/components/ui/LoadSpinner/LoadSpinner';
import { Stack, Grid, Paper, Typography } from '@mui/material';
import { Table } from 'antd';
import { Pie } from 'react-chartjs-2';
import { StatisticByInstallPuDto, filterStatisticByInstallPu } from '@/dto/taskmap/Dto';
import { aggregatePieData, TABLE_OPTIONS, pieOption, shortText } from './utils';
import {
  StatisticByInstallPuDataNoDataWrapper,
  StatisticByInstallPuDataWrapper,
} from './StatisticByInstallPu.styled';
import { MultiSelect } from '@/components/inputs/MultiSelectNew';
import { SelectFilterOptType } from '@/components/filter/MultySelectFilter';
import FilterButtons from '@/components/filter/FilterButtons/FilterButtons';
import { generateDetailedInfo } from '@/components/button/DetailedInfo/utils';
import { getDataFromFilter } from '@/components/inputs/MultiSelectNew/utils';
import { FormProvider, useForm } from 'react-hook-form';
import { useCatalog } from '@/hooks/CatalogHook';
import { MainText } from '../StatisticMain.styled';

interface StatisticByInstallPuProps {
  header: string;
  load: boolean;
  data: StatisticByInstallPuDto[];
  updateFunction: (param: filterStatisticByInstallPu) => void;
}

export const StatisticByInstallPu: React.FC<StatisticByInstallPuProps> = ({
  header,
  load,
  data,
  updateFunction,
}) => {
  const methods = useForm();
  const { handleSubmit } = methods;
  const { getContractCatalog } = useCatalog((state) => ({
    getContractCatalog: state.getContractCatalog,
  }));
  const contractListResponse = getContractCatalog();
  const [selectedContractList, setSelectedContractList] = React.useState<SelectFilterOptType[]>([]);
  const [contractListArg, setContractListArg] = React.useState(0);
  const detailedInfo = generateDetailedInfo([selectedContractList, 'Контракт']);
  let filterParam: any = {};

  const onSubmitSearch = (data: any) => {
    filterParam = data;
    if (selectedContractList.length) {
      filterParam.contractIdList = getDataFromFilter(selectedContractList, 'value');
      filterParam.contractIdListArg = contractListArg;
    }
    updateFunction(filterParam);
  };

  const onResetClick = () => {
    setSelectedContractList([]);
  };

  return (
    <>
      <Paper elevation={6}>
        <MainText className='MainTextStatistc'>{header}</MainText>
        <FormProvider {...methods}>
          <form onSubmit={handleSubmit(onSubmitSearch)}>
            <Grid
              container
              alignItems={'center'}
              paddingX={3}
              position={'relative'}
            >
              <Grid
                item
                xs={12}
                md={2}
              >
                <MultiSelect
                  label={'Контракт'}
                  options={contractListResponse ?? []}
                  value={selectedContractList}
                  argValue={contractListArg}
                  onChange={(value: SelectFilterOptType[]) => setSelectedContractList(value)}
                  onArgChange={(arg: number) => setContractListArg(arg)}
                />
              </Grid>
              <Grid
                item
                pt={2}
                xs={12}
                md={1}
              >
                <FilterButtons
                  info={detailedInfo}
                  onSearchClick={() => handleSubmit(onSubmitSearch)}
                  onCloseClick={onResetClick}
                  disable={load}
                />
              </Grid>
            </Grid>
          </form>
        </FormProvider>

        {load ? (
          <LoadSpinner />
        ) : (
          <StatisticByInstallPuDataWrapper>
            {!!data.length &&
              data.map((item) => {
                return (
                  <Stack key={item.regionName}>
                    <MainText className='MainTextStatistc'>{item.regionName}</MainText>
                    <Grid container>
                      <Grid
                        item
                        xs={12}
                        md={3}
                      >
                        <Pie
                          data={aggregatePieData(item.firstContract.items)}
                          options={pieOption}
                        />
                      </Grid>
                      <Grid
                        item
                        xs={12}
                        md={6}
                        style={{ alignSelf: 'center' }}
                      >
                        <Grid style={{ display: 'flex', justifyContent: 'center' }}>
                          <Table
                            {...TABLE_OPTIONS}
                            dataSource={item.firstContract.items}
                            title={() => shortText(item, 'firstContract')}
                          />
                          <Table
                            {...TABLE_OPTIONS}
                            dataSource={item.secondContract.items}
                            title={() => shortText(item, 'secondContract')}
                          />
                        </Grid>
                      </Grid>
                      <Grid
                        item
                        xs={12}
                        md={3}
                      >
                        <Pie
                          data={aggregatePieData(item.secondContract.items)}
                          options={pieOption}
                        />
                      </Grid>
                    </Grid>
                  </Stack>
                );
              })}
            {!data.length && (
              <StatisticByInstallPuDataNoDataWrapper>
                <Typography variant='subtitle1'> нет данных для отоброжения</Typography>
              </StatisticByInstallPuDataNoDataWrapper>
            )}
          </StatisticByInstallPuDataWrapper>
        )}
      </Paper>
    </>
  );
};
