import { Paper, styled } from '@mui/material';

export const StatisticReportByContractPerDayFiltersWrapper = styled('div')(
  ({ isMobile }: { isMobile: boolean }) => ({
    margin: '5px 0',
    padding: '0 0 0 5vw',
    position: 'relative',
    display: 'flex',
    flexWrap: isMobile ? 'wrap' : 'nowrap',
    justifyContent: isMobile ? 'center' : 'flex-start',
    alignItems: isMobile ? 'center' : 'flex-end',
    gap: 16,
    marginRight: isMobile ? 10 : 50,
  })
);

export const StatisticReportByContractPerDayWrapper = styled(Paper)({
  paddingBottom: 20,
});
