import {
  Button,
  CircularProgress,
  Collapse,
  Divider,
  IconButton,
  TextField,
  Typography,
} from '@mui/material';
import React, { useEffect, useState } from 'react';
import {
  PhonebookCreateWrapper,
  PhonebookIconButtonWrapper,
  PhonebookRowWrapper,
} from './Phonebook.styled';
import SaveRoundedIcon from '@mui/icons-material/SaveRounded';
import { Controller, FormProvider, useForm } from 'react-hook-form';
import { formatPhoneNumberMask } from '@/utils/heplers';
import { addSuccessActionLog, fetchCatch } from '@/hooks/ActionLogHook';
import { ApiCreatePhonebookData } from '@/services/PhonebookService/PhonebookService';
import { usePhonebookStore } from './store';
import { REQUIRED_TEXT } from './utils';

export interface PhonebookCreateForm {
  name: string;
  telNumber: string;
  additional?: string;
}

const PhonebookCreate = () => {
  const methods = useForm<PhonebookCreateForm>();
  const { handleSubmit, register, control, reset, formState } = methods;
  const [open, setOpen] = useState(false);
  const [loading, setLoading] = useState(false);

  const addPhonebookData = usePhonebookStore((state) => state.addPhonebookData);

  const handleButtonClick = () => {
    setOpen(!open);
  };

  useEffect(() => {
    reset();
  }, [open]);

  const onSubmit = (data: PhonebookCreateForm) => {
    setLoading(true);
    ApiCreatePhonebookData(data)
      .then(({ data }) => {
        addSuccessActionLog('Успешное создание контакта');
        addPhonebookData(data);
        reset();
      })
      .catch(fetchCatch)
      .finally(() => setLoading(false));
  };

  return (
    <PhonebookCreateWrapper loading={loading}>
      <Button onClick={handleButtonClick}>{open ? 'Отмена' : '+ Добавить'}</Button>

      <Collapse
        in={open}
        unmountOnExit
        mountOnEnter
      >
        <Typography
          variant='subtitle2'
          sx={{ mb: 1, mt: 2, pl: 1 }}
        >
          Новый контакт
        </Typography>
        <FormProvider {...methods}>
          <form onSubmit={handleSubmit(onSubmit)}>
            <PhonebookRowWrapper style={{ alignItems: 'flex-start' }}>
              <div style={{ width: '20%', flexShrink: 0 }}>
                <TextField
                  {...register('name', { required: REQUIRED_TEXT })}
                  variant='standard'
                  placeholder='ФИО'
                  sx={{ width: '80%' }}
                  autoFocus
                  autoComplete='off'
                  helperText={formState.errors.name?.message}
                  error={!!formState.errors.name}
                />
              </div>
              <div style={{ width: '20%', flexShrink: 0 }}>
                <Controller
                  name='telNumber'
                  control={control}
                  rules={{ required: REQUIRED_TEXT }}
                  render={({ field: { onChange, value }, fieldState }) => (
                    <TextField
                      onChange={(e) => onChange(formatPhoneNumberMask(e.target.value))}
                      value={value}
                      sx={{ width: '80%' }}
                      placeholder='Телефон'
                      variant='standard'
                      autoComplete='off'
                      error={!!fieldState.error}
                      helperText={fieldState.error?.message}
                    />
                  )}
                />
              </div>
              <div style={{ wordBreak: 'break-word', whiteSpace: 'pre-wrap', flexGrow: 1 }}>
                <TextField
                  {...register('additional')}
                  variant='standard'
                  multiline
                  maxRows={4}
                  placeholder='Дополнительная информация'
                  sx={{ width: '100%' }}
                />
              </div>
              <PhonebookIconButtonWrapper>
                {loading ? (
                  <CircularProgress size={15} />
                ) : (
                  <IconButton
                    color='primary'
                    type='submit'
                    disabled={loading}
                  >
                    <SaveRoundedIcon />
                  </IconButton>
                )}
              </PhonebookIconButtonWrapper>
            </PhonebookRowWrapper>

            <Divider sx={{ mt: 2 }} />
          </form>
        </FormProvider>
      </Collapse>
    </PhonebookCreateWrapper>
  );
};

export default PhonebookCreate;
