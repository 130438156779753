import { ApiFindAllPnrHermes } from '@/services/YodaRestService/YodaRestService';
import { useQuery } from 'react-query';
import { KEYS } from './Keys';

export function useAllHermes() {
  const { data: axiosResponse, isFetching } = useQuery({
    queryKey: [...KEYS.allHermes],
    staleTime: Infinity,
    queryFn: ApiFindAllPnrHermes,
  });

  return {
    hermesList: axiosResponse?.data,
    isLoadingAllHermes: isFetching,
  };
}
