import { Popper, styled } from '@mui/material';

export const FilterButtonWrapper = styled('div')({
  display: 'flex',
  marginLeft: 'auto',
});

export const StyledPopper = styled(Popper)({
  width: '250px !important',
});
