import { useQuery } from 'react-query';
import { KEYS } from './Keys';
import { useActionLog } from '@/hooks/ActionLogHook';

import { SupplyBillApiGetCatalog } from '@/services/SupplyBillService/SupplyBillService';
import { useMemo } from 'react';
import {
  convertIntoFilter,
  convertIntoMap,
  convertMapIntoFilter,
} from '@/components/storehouse/SupplyPUComponent/utils';
import { useAdminAccess } from '@/components/storehouse/SupplyPUComponent/utils';

export function useAllResponsables() {
  const { fetchCatch } = useActionLog();
  const { data, isFetching } = useQuery({
    queryKey: KEYS.allResponsables,
    staleTime: Infinity,
    placeholderData: [],
    queryFn: () => SupplyBillApiGetCatalog('group-type'),
    onError: (error) => fetchCatch(error),
  });

  const responsablesOptions = useMemo(() => convertIntoFilter(data ?? []), [data]);
  const responsablesMap = useMemo(() => convertIntoMap(data ?? []), [data]);

  return { responsablesOptions, responsablesMap, isFetching };
}

export function useAllCreators() {
  const { fetchCatch } = useActionLog();
  const isAdmin = useAdminAccess();
  return useQuery({
    queryKey: KEYS.allCreators,
    staleTime: Infinity,
    placeholderData: [],
    queryFn: () => SupplyBillApiGetCatalog('bill-creators'),
    onError: (error) => fetchCatch(error),
    enabled: isAdmin,
  });
}

export function useAllPuCompleteness(enabled?: boolean) {
  const { fetchCatch } = useActionLog();
  const { data, isFetching } = useQuery({
    queryKey: KEYS.puCompleteness,
    staleTime: Infinity,
    placeholderData: [],
    enabled: !!enabled,
    queryFn: () => SupplyBillApiGetCatalog('pu-completeness'),
    onError: (error) => fetchCatch(error),
  });
  const puCompletenessOptions = useMemo(() => convertIntoFilter(data ?? []), [data]);

  return { puCompletenessOptions, isFetching };
}

export function useAllEquipmentState(enabled?: boolean) {
  const { fetchCatch } = useActionLog();
  const { data, isFetching } = useQuery({
    queryKey: KEYS.equipmentState,
    staleTime: Infinity,
    placeholderData: [],
    enabled: !!enabled,
    queryFn: () => SupplyBillApiGetCatalog('equipment-state'),
    onError: (error) => fetchCatch(error),
  });
  const equipmentStateOptions = useMemo(() => convertIntoFilter(data ?? []), [data]);

  return { equipmentStateOptions, isFetching };
}

export function useAllPuDefectCause(enabled?: boolean) {
  const { fetchCatch } = useActionLog();
  const { data, isFetching } = useQuery({
    queryKey: KEYS.puDefectCause,
    staleTime: Infinity,
    placeholderData: [],
    enabled: !!enabled,
    queryFn: () => SupplyBillApiGetCatalog('pu-defect-cause'),
    onError: (error) => fetchCatch(error),
  });
  const puDefectOptions = useMemo(() => convertIntoFilter(data ?? []), [data]);

  return { puDefectOptions, isFetching };
}

export function useAllOsDefectCause(enabled?: boolean) {
  const { fetchCatch } = useActionLog();
  const { data, isFetching } = useQuery({
    queryKey: KEYS.neDefectCause,
    staleTime: Infinity,
    placeholderData: [],
    enabled: !!enabled,
    queryFn: () => SupplyBillApiGetCatalog('ne-defect-cause'),
    onError: (error) => fetchCatch(error),
  });
  const osDefectOptions = useMemo(() => convertIntoFilter(data ?? []), [data]);

  return { osDefectOptions, isFetching };
}

export function useAllAcceptedTransitBills() {
  const { fetchCatch } = useActionLog();
  const { data, isFetching } = useQuery({
    queryKey: KEYS.storehouseAcceptedTransitBills,
    staleTime: 1000 * 30, // 30 сек
    placeholderData: [],
    queryFn: () => SupplyBillApiGetCatalog('accepted-transit-bills'),
    onError: (error) => fetchCatch(error),
  });
  const acceptedTransitBills = useMemo(() => convertIntoFilter(data ?? []), [data]);

  return { acceptedTransitBills, isFetching };
}

export function useAllPuOwner(enabled?: boolean) {
  const { fetchCatch } = useActionLog();
  const { data, isFetching } = useQuery({
    queryKey: KEYS.puOwner,
    staleTime: Infinity,
    placeholderData: [],
    enabled: !!enabled,
    queryFn: () => SupplyBillApiGetCatalog('pu-owner'),
    onError: (error) => fetchCatch(error),
  });
  const puOwnerOptions = useMemo(() => convertIntoFilter(data ?? []), [data]);

  return { puOwnerOptions, isFetching };
}

export function useAllStorehouses() {
  const { fetchCatch } = useActionLog();
  const { data } = useQuery({
    queryKey: KEYS.allStorehouses,
    staleTime: Infinity,
    queryFn: () => SupplyBillApiGetCatalog('supply'),
    onError: (error) => fetchCatch(error, 'Ошибка загрузки складов'),
  });

  const storehousesOptions = useMemo(() => convertIntoFilter(data ?? []), [data]);

  return { storehousesOptions };
}

export function useAllContractors() {
  const { fetchCatch } = useActionLog();
  const { data } = useQuery({
    queryKey: KEYS.allContractors,
    staleTime: Infinity,
    queryFn: () => SupplyBillApiGetCatalog('contractors'),
    onError: (error) => fetchCatch(error, 'Ошибка загрузки каталога "Кому"'),
  });

  const contractorsOptions = useMemo(() => convertIntoFilter(data ?? []), [data]);

  return { contractorsOptions };
}

export function useAllProjects() {
  const { fetchCatch } = useActionLog();
  const { data } = useQuery({
    queryKey: KEYS.allProjects,
    staleTime: Infinity,
    queryFn: () => SupplyBillApiGetCatalog('project'),
    onError: (error) => fetchCatch(error, 'Ошибка загрузки каталога "Проект"'),
  });

  const projectsOptions = useMemo(() => convertIntoFilter(data ?? []), [data]);

  return { projectsOptions };
}

export function useAllPuScanFields() {
  const { fetchCatch } = useActionLog();
  const { data } = useQuery({
    queryKey: KEYS.allStorehouseScanFields,
    staleTime: Infinity,
    queryFn: () => SupplyBillApiGetCatalog('meter-type-scan-field'),
    onError: (error) => fetchCatch(error, 'Ошибка загрузки типов сканирования'),
  });

  const scanFieldsOptions = useMemo(() => convertIntoFilter(data ?? []), [data]);

  return { scanFieldsOptions };
}

export function useAllOsScanFields() {
  const { fetchCatch } = useActionLog();
  const { data } = useQuery({
    queryKey: KEYS.allStorehouseOsScanFields,
    staleTime: Infinity,
    queryFn: () => SupplyBillApiGetCatalog('os-type-scan-field'),
    onError: (error) => fetchCatch(error, 'Ошибка загрузки типов сканирования'),
  });

  const scanFieldsOptions = useMemo(() => convertIntoFilter(data ?? []), [data]);

  return { scanFieldsOptions };
}

export function useAllCacheNames() {
  const { fetchCatch } = useActionLog();
  const { data, isFetching } = useQuery({
    queryKey: KEYS.storehouseCacheNames,
    staleTime: Infinity,
    placeholderData: [],
    queryFn: () => SupplyBillApiGetCatalog('cache-values'),
    onError: (error) => fetchCatch(error),
  });
  const cacheNamesOptions = useMemo(() => convertIntoFilter(data ?? []), [data]);

  return { cacheNamesOptions, isFetching };
}

export function useAllSupplyGroups(enabled?: boolean) {
  const { fetchCatch } = useActionLog();
  const { data, isFetching } = useQuery({
    queryKey: KEYS.storehouseSupplyGroups,
    staleTime: Infinity,
    placeholderData: [],
    enabled: enabled,
    queryFn: () => SupplyBillApiGetCatalog('supply-group'),
    onError: (error) => fetchCatch(error),
  });
  const supplyGroupsOptions = useMemo(() => convertIntoFilter(data ?? []), [data]);

  return { supplyGroupsOptions, isFetching };
}

export function useUserSupplyGroup(enabled?: boolean) {
  const { fetchCatch } = useActionLog();
  const { data, isFetching } = useQuery({
    queryKey: KEYS.storehouseUserSupplyGroup,
    staleTime: Infinity,
    enabled: enabled,
    queryFn: () => SupplyBillApiGetCatalog('supply-group-for-user'),
    onError: (error) => fetchCatch(error),
  });
  const userSupplyGroupOptions = useMemo(() => convertIntoFilter(data ?? []), [data]);

  return { userSupplyGroupOptions, isFetching };
}

export function useAllTransitBills(enabled?: boolean) {
  const { fetchCatch } = useActionLog();
  const { data, isFetching } = useQuery({
    queryKey: KEYS.storehouseTransitBills,
    placeholderData: [],
    enabled: enabled,
    queryFn: () => SupplyBillApiGetCatalog('transit-bills'),
    onError: (error) => fetchCatch(error),
  });
  const transitBillsOptions = useMemo(() => convertIntoFilter(data ?? []), [data]);

  return { transitBillsOptions, isFetching };
}

export function useAllFactories(enabled?: boolean) {
  const { fetchCatch } = useActionLog();
  const { data, isFetching } = useQuery({
    queryKey: KEYS.storehouseFactories,
    staleTime: Infinity,
    enabled: enabled,
    queryFn: () => SupplyBillApiGetCatalog('factory'),
    onError: (error) => fetchCatch(error),
  });
  const factoriesOptions = useMemo(() => convertIntoFilter(data ?? []), [data]);

  return { factoriesOptions, isFetching };
}

export function useAllStatisticStates() {
  const { fetchCatch } = useActionLog();
  const { data, isFetching } = useQuery({
    queryKey: KEYS.storehouseStatisticStates,
    staleTime: Infinity,
    queryFn: () => SupplyBillApiGetCatalog('equipment-statistic-life-cycle'),
    onError: (error) => fetchCatch(error),
  });
  const statisticStatesOptions = useMemo(() => convertMapIntoFilter(data ?? {}), [data]);
  return { statisticStatesOptions, statisticStatesMap: data, isFetching };
}
