import { IconButton, ImageListItem, Button, Typography } from '@mui/material';
import { useEffect, useState } from 'react';
import { deleteFile, GetBase64File } from '@/services/FileService';
import ZoomInIcon from '@mui/icons-material/ZoomIn';
import DeleteIcon from '@mui/icons-material/Delete';
import { Dialog, DialogActions, DialogTitle } from '@material-ui/core';
import { useRole } from '@/hooks/useRole';
import { RolesEnum } from '@/app/auth/roles';
import FormatShapesIcon from '@mui/icons-material/FormatShapes';
import { YungaViewDialog } from '@/components/features/Yunga/ui/YungaViewDialog';
import { useImageDrawerStore } from '@/components/ImageDrawer/store';
import { useGalleryData } from '@/components/Gallery/store';
import {
  ApiGetFileById,
  ApiGetFileHeadersByUrlS3,
} from '@/services/YodaRestService/YodaRestService';
import pdfPatch from '@/images/patch/PdfPatсh.png';
import excelPatch from '@/images/patch/ExcelPatch.png';
import videoPatch from '@/images/patch/VideoPatch.png';
import errorPatch from '@/images/patch/ErrorPatch.png';
import { useStatus } from '../../panel/store';
import { IImage } from '@/dto/taskmap/task/TaskResultDto';
import ImageItemTitleBar from './ImageItemTitleBar';
import { ImageItemBarRow, ImageItemListItemBar } from './ImageItemTitleBar.styled';
import { useDisabledAll } from '@/hooks/useAccess';
import { VideoPlayer } from '@/components/common/VideoPlayer/VideoPlayer';
import { useTaskEditorOnlyShow } from '../../useTaskEditorOnlyShow';
import { ImageItemTypes, isExcelFile } from './utils';
import shallow from 'zustand/shallow';
import CopyButton from '@/components/button/CopyButton/CopyButton';
import { color } from '@/styles/mixins';
import { useAnchorElement } from '@/hooks/useAnchorElement';
import ImageItemDuplicateInfo from './ImageItemDuplicateInfo';

interface ImageViewItemProps {
  imageId: string;
  titles: string[];
  // afterDelete: () => void;
  withDelete: boolean;
  ids?: IImage[];
  index?: number;
  obj: string;
  createdOn: Date;
  url?: string;
}

const NOT_DEFINED = 'NOT_DEFINED';

const getImageUrl = (
  props: ImageViewItemProps,
  getImageUrlById: (id: string) => string,
  contentType?: string
) => {
  if (!contentType || contentType === NOT_DEFINED)
    return props.url ? props.url : getImageUrlById(props.imageId);
  if (contentType.includes(ImageItemTypes.PDF)) {
    return pdfPatch;
  }
  if (isExcelFile(contentType)) {
    return excelPatch;
  }
  if (contentType.includes(ImageItemTypes.VIDEO)) {
    return videoPatch;
  }
  return props.url ? props.url : getImageUrlById(props.imageId);
};

const download = (url?: string) => {
  if (!url) return;
  const element = document.createElement('a');
  element.setAttribute('href', url);
  element.setAttribute('target', '_blank');
  element.setAttribute('download', 'file');
  document.body.appendChild(element);
  element.click();
  document.body.removeChild(element);
};

const showItem = (
  ids: IImage[] = [],
  index = 0,
  onOpen: (ids: IImage[], index: number) => void,
  setShowVideo: (show: boolean) => void,
  contentType?: string
) => {
  if (isExcelFile(contentType) || contentType?.includes(ImageItemTypes.PDF)) {
    ids[index]?.url
      ? download(ids[index].url)
      : GetBase64File(ids[index]?.id).then((res) => download(res));
  } else if (contentType?.includes(ImageItemTypes.VIDEO)) {
    setShowVideo(true);
  } else {
    onOpen(ids, index);
  }
};

export const ImageViewItem = (props: ImageViewItemProps) => {
  const onlyShow = useTaskEditorOnlyShow();
  const disabledAll = useDisabledAll();
  const { anchorEl, handleOpen, handleClose, open } = useAnchorElement();

  const { imageId, titles, withDelete, ids, index, obj, createdOn } = props;
  const [removeDialogOpen, setRemoveDialogOpen] = useState(false);
  const [showCopyButton, setShowCopyButton] = useState<boolean>(false);
  const [showYungaDialog, setShowYungaDialog] = useState<boolean>(false);
  const [contentType, setContentType] = useState<string>('');
  const { role } = useRole();
  const { isDisabledAll } = useStatus((state) => ({
    isDisabledAll: state.isDisabledAll,
  }));
  const [showVideo, setShowVideo] = useState(false);
  const { onOpen } = useImageDrawerStore(
    (state) => ({
      onOpen: state.onOpen,
    }),
    shallow
  );
  const { getImageUrlById, removeImageToGroupById, duplicateFileList } = useGalleryData(
    (state) => ({
      getImageUrlById: state.getImageUrlById,
      removeImageToGroupById: state.removeImageToGroupById,
      duplicateFileList: state.duplicateFileList,
    }),
    shallow
  );

  useEffect(() => {
    if (role) {
      setShowCopyButton(role.includes(RolesEnum.Administrators));
    }
  }, [role]);

  useEffect(() => {
    const apiGetFile = props?.url
      ? ApiGetFileHeadersByUrlS3(props.url)
      : ApiGetFileById(props.imageId);
    apiGetFile
      .then((res) => {
        setContentType(res.headers['content-type']);
      })
      .catch((err) => {
        setContentType(NOT_DEFINED);
      });
  }, [props?.imageId, props?.url]);

  const handleRemoveClick = () => {
    setRemoveDialogOpen(true);
  };

  const handleRemoveDialogClose = () => {
    setRemoveDialogOpen(false);
  };

  const removeImage = (id: string) => {
    !disabledAll &&
      !onlyShow &&
      deleteFile(imageId)
        .then(() => {
          removeImageToGroupById(id, imageId);
          setRemoveDialogOpen(false);
        })
        .catch((error) => {
          console.log(error);
          alert('Ошибка удаления документа');
        });
  };

  const duplicateData = duplicateFileList.find((e) => e.fileId === imageId);

  const handleEnterDuplicate = (event: React.MouseEvent<HTMLLIElement>) => {
    if (!duplicateData) return;
    handleOpen(event);
  };

  return (
    <>
      {(props.url || getImageUrlById(imageId)) && contentType && (
        <>
          <ImageListItem
            style={{ border: duplicateData ? '3px solid red' : '', borderRadius: 5 }}
            onMouseEnter={handleEnterDuplicate}
            onMouseLeave={handleClose}
          >
            <img
              src={getImageUrl(props, getImageUrlById, contentType)}
              srcSet={getImageUrl(props, getImageUrlById, contentType)}
              alt={titles[0]}
              loading='lazy'
              height={'150'}
              style={{ minHeight: 150 }}
              onClick={(e) => {
                e.ctrlKey = true;
                e.isTrusted = false;
                showItem(ids, index, onOpen, setShowVideo, contentType);
              }}
              onError={(e) => {
                e.currentTarget.src = errorPatch;
                e.currentTarget.srcset = errorPatch;
              }}
            />
            <ImageItemTitleBar
              titles={titles}
              createdOn={createdOn}
            />
            <ImageItemListItemBar
              title={
                <ImageItemBarRow>
                  {withDelete && (
                    <IconButton
                      style={{ color: color('trueWhite') }}
                      aria-label={`info about ${titles[0]}`}
                      onClick={handleRemoveClick}
                      disabled={disabledAll || onlyShow || isDisabledAll()}
                    >
                      <DeleteIcon />
                    </IconButton>
                  )}
                  <IconButton
                    style={{ color: color('trueWhite') }}
                    aria-label={`info about ${titles[0]}`}
                    onClick={() => {
                      if (contentType?.includes(ImageItemTypes.VIDEO)) {
                        setShowVideo(true);
                      } else {
                        onOpen(ids ? ids : [], index ? index : 0);
                      }
                    }}
                  >
                    <ZoomInIcon />
                  </IconButton>
                  {!disabledAll && !onlyShow && showCopyButton && (
                    <>
                      <IconButton style={{ color: color('trueWhite') }}>
                        <CopyButton
                          onlyIcon
                          textToCopy={imageId}
                          tooltipText='Копировать id файла'
                          iconColor={null}
                        />
                      </IconButton>
                      <IconButton
                        style={{ color: color('trueWhite') }}
                        onClick={() => setShowYungaDialog(true)}
                      >
                        <FormatShapesIcon />
                      </IconButton>
                      <YungaViewDialog
                        open={showYungaDialog}
                        id={imageId}
                        close={() => setShowYungaDialog(false)}
                      />
                    </>
                  )}
                </ImageItemBarRow>
              }
            />

            <ImageItemDuplicateInfo
              {...duplicateData}
              id={imageId}
              open={open}
              anchorEl={anchorEl}
            />
          </ImageListItem>

          <Dialog
            open={removeDialogOpen}
            onClose={handleRemoveDialogClose}
          >
            <DialogTitle disableTypography>
              <Typography style={{ alignItems: 'center' }}>
                Вы действительно хотите удалить документ?
              </Typography>
            </DialogTitle>
            <DialogActions>
              <Button onClick={() => removeImage(obj)}>Да, удалить</Button>
              <Button onClick={handleRemoveDialogClose}>Отмена</Button>
            </DialogActions>
          </Dialog>
          <VideoPlayer
            isOpen={showVideo}
            onClose={() => setShowVideo(false)}
            title={titles[0]}
            videoUrl={props.url ? props.url : getImageUrlById(props.imageId)}
          />
        </>
      )}
    </>
  );
};
