import React, { useMemo, useRef, useState } from 'react';
import {
  HouseCommentsContainer,
  HouseCommentsBoxShadow,
  HouseCommentsInner,
  HouseCommentsTitle,
  HouseCommentsScrollContainer,
  HouseCommentsButton,
} from './HouseComments.styled';
import { Collapse } from '@mui/material';
import CommentOutlinedIcon from '@mui/icons-material/CommentOutlined';
import { HouseCommentResponseDto } from '@/services/HouseDataService/dto/HouseDataServiceResponseDto';
import HouseCommentRow from './HouseCommentRow';
import { TransitionGroup } from 'react-transition-group';
import { useQuery } from 'react-query';
import { fetchCatch } from '@/hooks/ActionLogHook';
import { ApiFindHouseComments } from '@/services/HouseDataService/HouseDataService';
import { useParams } from 'react-router-dom';
import HouseCommentsSkeleton from './HouseCommentsSkeleton';
import HouseCommentsForm from './HouseCommentsForm';

const HouseComments = () => {
  const { houseDtoId } = useParams();

  const [open, setOpen] = useState(false);
  const [comments, setComments] = useState<HouseCommentResponseDto[]>([]);

  const hasFetched = useRef(false);

  const shouldFetch = useMemo(() => {
    if (open && !hasFetched.current) {
      hasFetched.current = true; // Ставим флаг перед первым запросом
      return true;
    }
    return false;
  }, [open]);

  const deleteComment = (id: string) => {
    setComments(comments.filter((comment) => comment.id !== id));
  };

  const { isFetching } = useQuery({
    queryKey: ['HouseComments'],
    staleTime: Infinity,
    retry: 0,
    enabled: shouldFetch,
    refetchOnWindowFocus: false,
    queryFn: () => ApiFindHouseComments(houseDtoId ?? ''),
    onError: fetchCatch,
    onSuccess: ({ data }) => setComments(data),
  });

  const addComment = (comment: HouseCommentResponseDto) => {
    setComments([...comments, comment]);
  };

  return (
    <HouseCommentsContainer>
      <HouseCommentsButton onClick={() => setOpen(!open)}>
        <CommentOutlinedIcon color={open ? 'primary' : undefined} />
      </HouseCommentsButton>

      <HouseCommentsBoxShadow open={open}>
        <Collapse
          in={open}
          orientation='horizontal'
        >
          <Collapse
            in={open}
            orientation='vertical'
          >
            <HouseCommentsInner>
              <HouseCommentsTitle>Комментарии</HouseCommentsTitle>
              <HouseCommentsScrollContainer>
                {isFetching ? (
                  <HouseCommentsSkeleton />
                ) : (
                  <TransitionGroup>
                    {comments?.map((comment) => (
                      <Collapse key={comment?.id}>
                        <HouseCommentRow
                          comment={comment}
                          deleteComment={deleteComment}
                        />
                      </Collapse>
                    ))}
                  </TransitionGroup>
                )}
              </HouseCommentsScrollContainer>
              <HouseCommentsForm addComment={addComment} />
            </HouseCommentsInner>
          </Collapse>
        </Collapse>
      </HouseCommentsBoxShadow>
    </HouseCommentsContainer>
  );
};

export default HouseComments;
