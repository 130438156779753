import {
  DynamicStatisticSwitchesWrapper,
  DynamicStatisticTimePeriodWrapper,
  HeaderDynamicStatistic,
  TimePeriodButton,
} from './DynamicStatistic.styled';

import { IconButton } from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import { useInvestStatisticStore } from '../../store';
import shallow from 'zustand/shallow';
import { timePeriod } from '../utils';
import { useState } from 'react';
import { DisplayManufacturer } from '../../types/types';
import { SwitchOptionsPopover } from '../SwitchOptionsPopover/SwitchOptionsPopover';

interface DynamicStatisticControlProps {
  displayManufacturer: DisplayManufacturer;
  setDisplayManufacturer: (updater: (prev: DisplayManufacturer) => DisplayManufacturer) => void;
}

export const DynamicStatisticControl = ({
  displayManufacturer,
  setDisplayManufacturer,
}: DynamicStatisticControlProps) => {
  const {
    setSelectedColumn,
    setDataDynamicStatistic,
    selectedTimePeriod,
    setSelectedTimePeriod,
    activeIndex,
    equipmentsData,
  } = useInvestStatisticStore(
    (state) => ({
      setSelectedColumn: state.setSelectedColumn,
      setDataDynamicStatistic: state.setDataDynamicStatistic,
      selectedTimePeriod: state.selectedTimePeriod,
      setSelectedTimePeriod: state.setSelectedTimePeriod,
      activeIndex: state.activeIndex,
      equipmentsData: state.equipmentsData,
    }),
    shallow
  );

  const closeHandler = () => {
    setSelectedColumn(null);
    setDataDynamicStatistic([]);
  };

  const [anchorEl, setAnchorEl] = useState<HTMLButtonElement | null>(null);
  const handleClose = () => {
    setAnchorEl(null);
  };

  const type = activeIndex !== null ? equipmentsData[activeIndex].title : '';
  const dysplaySwitch = type === 'РОУТЕР' || type === 'ШЛЮЗ' ? false : true;

  return (
    <HeaderDynamicStatistic>
      <DynamicStatisticTimePeriodWrapper>
        {timePeriod.map((item, idx) => (
          <TimePeriodButton
            key={idx}
            variant={selectedTimePeriod.name === item.name ? 'contained' : 'outlined'}
            onClick={() => setSelectedTimePeriod(item)}
          >
            {item.title}
          </TimePeriodButton>
        ))}
      </DynamicStatisticTimePeriodWrapper>
      <DynamicStatisticSwitchesWrapper>
        <div>
          <SwitchOptionsPopover />
        </div>
        <IconButton onClick={closeHandler}>
          <CloseIcon />
        </IconButton>
      </DynamicStatisticSwitchesWrapper>
    </HeaderDynamicStatistic>
  );
};
