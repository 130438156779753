import { Autocomplete, CircularProgress, Collapse, IconButton, TextField } from '@mui/material';
import React, { useEffect, useState } from 'react';
import Phonebook from '../Phonebook/Phonebook';
import ArrowCircleUpRoundedIcon from '@mui/icons-material/ArrowCircleUpRounded';
import { color } from '@/styles/mixins';
import { HouseCommentResponseDto } from '@/services/HouseDataService/dto/HouseDataServiceResponseDto';
import { ApiCreatedHouseComment } from '@/services/HouseDataService/HouseDataService';
import { useParams } from 'react-router-dom';
import { fetchCatch } from '@/hooks/ActionLogHook';
import { HouseCommentCreateRequestDto } from '@/services/HouseDataService/dto/HouseDataServiceRequestDto';
import {
  HouseCommentsFormIconButtonWrapper,
  HouseCommentsFormRow,
  HouseCommentsFormWrapper,
  HouseCommentsRowCallMessage,
} from './HouseComments.styled';
import { usePhonebookStore } from '../Phonebook/store';
import CloseIcon from '@mui/icons-material/Close';

export interface HouseCommentsFormProps {
  addComment: (comment: HouseCommentResponseDto) => void;
}

export enum ECommentType {
  CALL = 'CALL',
  GENERAL = 'GENERAL',
}

const COMMENT_TYPES = new Map([
  [ECommentType.CALL, 'Звонок'],
  [ECommentType.GENERAL, 'Общий'],
]);

const COMMENT_VALUES = Array.from(COMMENT_TYPES.keys());

const HouseCommentsForm = ({ addComment }: HouseCommentsFormProps) => {
  const { houseDtoId } = useParams();

  const selectedPhonebookItem = usePhonebookStore((state) => state.selectedPhonebookItem);
  const setSelectedPhonebookItem = usePhonebookStore((state) => state.setSelectedPhonebookItem);

  const resetPhonebookItem = () => {
    setSelectedPhonebookItem(null);
  };

  const [loading, setLoading] = useState(false);
  const [type, setType] = useState<ECommentType | null>(null);
  const [comment, setComment] = useState('');

  const disablePhonebook = type !== ECommentType.CALL;
  const checkIsValid = () => {
    const isValidText = comment?.length > 0;

    if (!isValidText || !type?.length) return false;
    if (type === ECommentType.CALL) {
      return !!selectedPhonebookItem?.id;
    }
    if (type === ECommentType.GENERAL) {
      return true;
    }
  };
  const isValidComment = checkIsValid();
  const saveComment = () => {
    if (!houseDtoId || !type) return;
    setLoading(true);

    const newComment: HouseCommentCreateRequestDto = {
      comment,
      type,
      ...((selectedPhonebookItem?.id && { tpPhoneBookId: selectedPhonebookItem?.id }) || {}),
    };

    ApiCreatedHouseComment(houseDtoId, newComment)
      .then(({ data }) => {
        addComment(data);
        setComment('');
        setType(null);
        resetPhonebookItem();
      })
      .catch(fetchCatch)
      .finally(() => setLoading(false));
  };

  useEffect(() => {
    resetPhonebookItem();
  }, [type]);

  return (
    <HouseCommentsFormWrapper loading={loading}>
      <div>
        <HouseCommentsFormRow>
          <Autocomplete
            value={type}
            onChange={(e, value) => setType(value)}
            sx={{ width: '100%' }}
            renderInput={(params) => {
              return (
                <TextField
                  {...params}
                  label='Тип комментария'
                  variant='standard'
                />
              );
            }}
            options={COMMENT_VALUES}
            getOptionLabel={(option) => COMMENT_TYPES.get(option) ?? ''}
          />

          <Phonebook disabled={disablePhonebook} />
        </HouseCommentsFormRow>

        <Collapse in={!!selectedPhonebookItem?.id}>
          <HouseCommentsRowCallMessage
            sx={{
              mt: 1,
              ml: 'auto',
              display: 'flex',
              justifyContent: 'flex-end',
              alignItems: 'center',
              gap: '4px',
            }}
          >
            {`${selectedPhonebookItem?.name ?? ''} ${selectedPhonebookItem?.telNumber ?? ''}`}

            <IconButton
              onClick={resetPhonebookItem}
              size='small'
            >
              <CloseIcon
                color='inherit'
                fontSize='inherit'
              />
            </IconButton>
          </HouseCommentsRowCallMessage>
        </Collapse>
      </div>

      <TextField
        variant='standard'
        label='Комментарий'
        value={comment}
        onChange={(e) => setComment(e.target.value)}
        onKeyDown={(e) => {
          if (e.key === 'Enter' && isValidComment) {
            saveComment();
          }
        }}
        InputProps={{
          endAdornment: (
            <HouseCommentsFormIconButtonWrapper>
              {loading ? (
                <CircularProgress size={16} />
              ) : (
                <IconButton
                  onClick={saveComment}
                  sx={{ color: isValidComment ? color('muiBlue') : undefined }}
                  disabled={!isValidComment}
                >
                  <ArrowCircleUpRoundedIcon color='inherit' />
                </IconButton>
              )}
            </HouseCommentsFormIconButtonWrapper>
          ),
        }}
      />
    </HouseCommentsFormWrapper>
  );
};

export default HouseCommentsForm;
