import { GeoBounds } from '@/dto/taskmap/Dto';
import { FilterArgEnum } from '@/components/filter/MultySelectFilter';

export enum Subscriber {
  NO = 'NO', // Точка поставки без потребителя
  FROM_DB = 'FROM_DB', // Точка поставки с потребителем из адресного списка
  CREATED_BY_USER = 'CREATED_BY_USER', // Точка поставки с потребителем созданным через МП во время ППО
}
export enum ResultType {
  NO_TASK = 'NO_TASK',
  SMR_NOT_COMPLETE = 'SMR_NOT_COMPLETE',
  SMR_DONE_WITH_ACT = 'SMR_DONE_WITH_ACT',
  SMR_DONE_WITHOUT_ACT = 'SMR_DONE_WITHOUT_ACT',
  TKO = 'TKO',
}

export type PpoFilterRequest = {
  dispatcherName?: string | null;
  dispatcherNameArg?: FilterArgEnum;
  contractorIdList?: string[];
  contractorIdArg?: FilterArgEnum;
  executorIdList?: string[];
  executorIdArg?: FilterArgEnum;
  resIdList?: string[];
  resIdArg?: FilterArgEnum;
  electricNetworksEnterpriseIdList?: string[];
  electricNetworksEnterpriseIdArg?: FilterArgEnum;
  geoBounds?: GeoBounds;
};

export type PpoMapResponse = {
  taskId: string;
  longitudeX: number;
  latitudeY: number;
};
export type RouterType = {
  taskId: string;
  taskStatus: string;
  longitude: number;
  latitude: number;
  taskNumber: number;
};

export type GeoTransStation = {
  id: string;
  contractId?: string;
  contractorId?: string;
  resId?: string;
  pillars?: PillarGeo[];
  deliveryPoints?: DeliveryPointGeo[];
  feeders?: { id: string; num: string }[];
  uspd: USPD | null;
  routerList?: RouterType[];
  uspdList?: USPD[];
  parentId?: string;
};

export type GeoObject = {
  id: string;
  longitudeX: number;
  latitudeY: number;
  type?: string;
  description?: string;
  linkedObject?: GeoObject;
  feederId?: string;
  pillarId?: string;
  subscriber?: Subscriber;
  result?: ResultType;
};
export type PillarGeo = {
  id: string;
  longitude: number;
  latitude: number;
  number: string;
  type: string;
  typeId: string;
  parentId?: string;
};

export type DeliveryPointGeo = {
  id: string;
  longitude: number;
  latitude: number;
  feederId?: string;
  pillarId?: string;
  subscriberSource?: Subscriber;
  result?: ResultType;
  isChangeNeeded?: boolean | null;
  newPuTypeMarker: string;
  parentId?: string;
};

export type USPD = {
  id: string;
  taskId: string;
  taskStatus: string;
  longitude: number;
  latitude: number;
  taskNumber: number;
  parentId?: string;
};
