import { HOST, YodaRestClient } from '@/services/YodaAuthService';
import { ApiResponse } from '@/types/api';
import { SimChangesResponse } from '@/services/GSMService/dto/GSMServiceResponseDto';

export async function PutSimChange(
  id: string,
  data: {
    iccid: string;
    type: string;
  }
): ApiResponse<SimChangesResponse> {
  const url = HOST + `/rest/simChange/${id}`;
  return YodaRestClient.put(url, data);
}

export async function СancelSimChange(id: string): ApiResponse<string> {
  const url = HOST + `/rest/simChange/${id}/canceled`;
  return YodaRestClient.post(url, {});
}

export async function getGsmByTaskId(taskId: string): ApiResponse<SimChangesResponse[]> {
  const url = HOST + `/rest/task/${taskId}/sim-change`;
  return YodaRestClient.get(url);
}
