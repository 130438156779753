import React from 'react';
import { useAccess } from '@/hooks/useAccess';
import { AccessEnum } from '@/app/auth/roles';
import { MailNotifications } from '@/components/MailNotifications/MailNotifications';
import { useMetaTitle } from '@/hooks/useMetaTitle';

const MassLoadingADPUPage = () => {
  // useAccess({
  // 	access: [AccessEnum.DeliveryPointScreen],
  // });

  useMetaTitle(`Массовая прогрузка АДПУ`);

  return <MailNotifications type={'ADPU'} />;
};
export default MassLoadingADPUPage;
