import {
  Box,
  Button,
  Grid,
  Snackbar,
  SnackbarContent,
  Stack,
  Tabs,
  Typography,
} from '@mui/material';
import React, { useEffect, useState } from 'react';
import CallingMapInner from './map';
import { CallingMapFilter } from './filter/FilterCalligMap';
import { useCallingMapState } from './store';
import {
  findAllInstallationCallMap,
  getSingleTaskfromCallMap,
} from '@/services/CallingMapService/CallingMapService';
import ErrorOutlineIcon from '@mui/icons-material/ErrorOutline';
import { CallingMapPopup } from './components/CallingMapPopup';
import shallow from 'zustand/shallow';
import InfoBlockRebuild from './components/InfoBlockRebuild';
import { color } from '@/styles/mixins';
import {
  applyFilterAndFetchData,
  divideBounds,
  handleCombinedData,
  isNumberInRange,
  makeFilter,
  makePolygon,
  ONE_LOAD_POINTS_LIMIT,
  POINTS_LIMIT,
  POLIGON_COLS,
  POLIGON_ROWS,
  splitGeoJSONByGrid,
} from './utils/CallingMapUtils';
import { useScreenHoldHook } from '@/hooks/ScreenHoldHook';
import { GeolessGetDataPayload, IgeoPointCallingMap } from './types/types';
import useDebounce from '../zoningMap/hook/useDebounce';
import { ActionLogType, syncErrorCatch, useActionLog } from '@/hooks/ActionLogHook';
import EventCalandarPopup from './components/EventCalandarPopup';
import { AccessPopup } from '@/components/popups/AccessPopup/AccessPopup';
import { SubscriberForm } from '@/components/SubscriberForm';
import { FormProvider, useForm } from 'react-hook-form';
import { addSub } from '@/services/SubscribersService/SubscribersService';
import TabPanel from './components/TabPanel';
import { getGeoLessPoints } from '@/services/CallingMapService/CallingMapService';
import GeoLessPointsTable from './components/GeoLessPointsTable';
import { geojsonToWKT } from '@terraformer/wkt';
import { BoxShadow } from '@/components/ui/BoxShadow';
import CallingMapIncomingTasks from './components/CallingMapIncomingTasks/CallingMapIncomingTasks';
import CallingMapIncomingTasksTab from './components/CallingMapIncomingTasksTab/CallingMapIncomingTasksTab';
import { SubscriberParam } from '@/services/SubscribersService/dto/SubscribersServiceRequestDto';
import { useCallingMapTabCount } from '@/components/maps/callingMap/components/CallingMapTab/store';
import CallingMapTab from '@/components/maps/callingMap/components/CallingMapTab/CallingMapTab';
import { useIncomingClaim } from './components/CallingMapIncomingTasksTab/store';
import { GetCurrentUser } from '@/services/YodaRestService/YodaRestService';
// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-ignore
const turf = window?.turf;

type ZoomStateType = 'SAME' | 'OUT' | 'IN';

export interface GeoBounds {
  maxX: number;
  maxY: number;
  minY: number;
  minX: number;
}
export interface GeoData {
  polygon: string;
  formatedFilter: any;
  limit?: number;
}

export const CallingMap = () => {
  const {
    setGeoPoints,
    isOpenPopUp,
    singleAbonent,
    isOpenSnackBar,
    bounds,
    mapZoom,
    toggleUrgentGeoPoins,
    isOpenEventCalendarPopUp,
    setIsOpenEventCalendarPopUp,
    taskId,
    isOpenAddAbonentPopUp,
    setOpenAddAbonentPopup,
    setSingleGeoPoint,
    setGeoLess,
    setGeoLessCount,
    existingGeoPoints,
    limit,
    currentPage,
    pageSize,
    errorMessageTextPopup,
    callCenterRequestType,
    isFilterChange,
    setIsFilterChange,
    setCurrentUser,
  } = useCallingMapState(
    (state) => ({
      setGeoPoints: state.setGeoPoints,
      isOpenPopUp: state.isOpenPopUp,
      singleAbonent: state.singleAbonent,
      setExecutors: state.setExecutors,
      isOpenSnackBar: state.isOpenSnackBar,
      bounds: state.bounds,
      taskId: state.taskId,
      mapZoom: state.mapZoom,
      toggleUrgentGeoPoins: state.toggleUrgentGeoPoins,
      isAuthorized: state.isAuthorized,
      isOpenEventCalendarPopUp: state.isOpenEventCalendarPopUp,
      setIsOpenEventCalendarPopUp: state.setIsOpenEventCalendarPopUp,
      isOpenAddAbonentPopUp: state.isOpenAddAbonentPopUp,
      setOpenAddAbonentPopup: state.setOpenAddAbonentPopup,
      setSingleGeoPoint: state.setSingleGeoPoint,
      setGeoLess: state.setGeoLess,
      setGeoLessCount: state.setGeoLessCount,
      existingGeoPoints: state.geoPoints,
      limit: state.limit,
      currentPage: state.currentPage,
      pageSize: state.pageSize,
      setCurrentPage: state.setCurrentPage,
      setPageSize: state.setPageSize,
      errorMessageTextPopup: state.errorMessageTextPopup,
      callCenterRequestType: state.callCenterRequestType,
      isFilterChange: state.isFilterChange,
      setIsFilterChange: state.setIsFilterChange,
      setCurrentUser: state.setCurrentUser,
    }),
    shallow
  );
  const {
    mapCount,
    taskWithoutCoordinatesCount,
    updateMapCount,
    updateTaskWithoutCoordinatesCount,
  } = useCallingMapTabCount(
    (state) => ({
      mapCount: state.mapCount,
      taskWithoutCoordinatesCount: state.taskWithoutCoordinatesCount,
      updateMapCount: state.updateMapCount,
      updateTaskWithoutCoordinatesCount: state.updateTaskWithoutCoordinatesCount,
    }),
    shallow
  );

  const setFilterIncommingTasks = useIncomingClaim((state) => state.setFilterIncommingTasks);

  const deboundceBounds = useDebounce(bounds, 100);
  const { fetchCatch, addActionLog } = useActionLog();
  const [mapAPI, setMapAPI] = useState(null);
  const { setIsInProgress } = useScreenHoldHook();
  const [value, setValue] = useState(0);

  const [isTableSearch, setIsTableSearch] = useState(false);
  const [isIncommingTaskSearch, setIsIncommingTaskSearch] = useState(false);
  const [isMapReady, setIsMapReady] = useState(false);
  const [zoomState, setZoomState] = useState<ZoomStateType>('SAME');
  const [diffGeojson, setDiffGeojson] = useState<any>(null);
  const [activeTab, setActiveTab] = useState(4);

  const notSingleSearch = activeTab === 4;
  const singleSearch = activeTab === 3;
  // const isTableMode = Boolean(value);

  const getGeoLessTableData = (formatedFilter: any) => {
    getGeoLessPoints({
      body: { filterItemList: formatedFilter.filterItemList },
      page: currentPage,
      pageSize: pageSize,
    })
      .then(({ data }) => {
        setGeoLess(data);
      })
      .catch((err) => {
        fetchCatch(err);
      })
      .finally(() => setIsInProgress(false));
  };

  const handleChange = (event: React.SyntheticEvent, newValue: number) => {
    setValue(newValue);
    // Сбросим оба флага
    setIsTableSearch(false);
    setIsIncommingTaskSearch(false);
    setFilterIncommingTasks({ filterItemList: [] });

    if (newValue === 1) {
      setIsTableSearch(true);
    } else if (newValue === 2) {
      setIsIncommingTaskSearch(true);
    }
  };

  const updateCount = () => {
    const formatedFilter = makeFilter(deboundceBounds, undefined);

    updateMapCount(formatedFilter);
    updateTaskWithoutCoordinatesCount(formatedFilter);
  };

  const onSubmit = () => {
    isFilterChange && updateCount();

    if (value === 1) {
      const formatedFilter = makeFilter([], undefined);
      getGeoLessTableData(formatedFilter);
    }

    if (value === 0) {
      const deboundceBoundsArr = divideBounds(deboundceBounds);

      const polygons = deboundceBoundsArr.map((boundsInside) => {
        const polygon = turf?.polygon([boundsInside]);
        return geojsonToWKT(polygon.geometry);
      });

      loadPointsByPolygonArray(polygons);
    }
  };

  const loadPointsByPolygonArray = (polygon: any) => {
    const formatedFilter = makeFilter(bounds, ONE_LOAD_POINTS_LIMIT);
    setIsInProgress(true);
    findAllInstallationCallMap({ formatedFilter, polygon })
      .then(({ data }: any) => {
        handleCombinedData(data, setGeoPoints, isFilterChange ? [] : existingGeoPoints, zoomState);
        setIsFilterChange(false);
      })
      .catch((error: any) => {
        fetchCatch(error);
        setGeoPoints([]);
      })
      .finally(() => setIsInProgress(false));
  };

  useEffect(() => {
    if (deboundceBounds.length && !toggleUrgentGeoPoins && notSingleSearch) {
      if (isNumberInRange(mapZoom)) {
        applyFilterAndFetchData({
          bounds,
          accuracy: undefined,
          setIsInProgress,
          polygon: [makePolygon(deboundceBounds)],
        })
          .then(({ data }: any) => {
            setGeoPoints(data);
            setIsFilterChange(false);
          })
          .catch((error: any) => {
            fetchCatch(error);
            setGeoPoints([]);
          })
          .finally(() => setIsInProgress(false));
      } else {
        if (diffGeojson?.geometry) {
          isFilterChange && updateCount();
          let splitPolygons = splitGeoJSONByGrid(diffGeojson?.geometry, mapZoom).map((el: any) =>
            geojsonToWKT(el.geometry)
          );
          if (splitPolygons.length > 2 * POLIGON_ROWS * POLIGON_COLS) {
            const deboundceBoundsArr = divideBounds(deboundceBounds);
            splitPolygons = deboundceBoundsArr.map((boundsInside) => {
              const polygon = turf?.polygon([boundsInside]);
              return geojsonToWKT(polygon.geometry);
            });
          }

          if (splitPolygons.length) {
            loadPointsByPolygonArray(splitPolygons);
          }
        } else {
          isFilterChange && updateCount();
          const deboundceBoundsArr = divideBounds(deboundceBounds);

          const polygons = deboundceBoundsArr.map((boundsInside) => {
            const polygon = turf?.polygon([boundsInside]);
            return geojsonToWKT(polygon.geometry);
          });

          loadPointsByPolygonArray(polygons);
        }
      }
    }
  }, [deboundceBounds]);

  useEffect(() => {
    if (mapAPI) {
      setIsMapReady(true);
    }
  }, [mapAPI]);

  const methods = useForm<SubscriberParam>({ mode: 'onChange' });

  const handleAddNumber = (data: SubscriberParam) => {
    if (!taskId) {
      return;
    }
    setIsInProgress(true);
    data.taskId = taskId;
    addSub(data)
      .then(({ data }) => {
        if (data.length) {
          addActionLog(ActionLogType.SUCCESS, 'Абонет добавлен');
        } else {
          syncErrorCatch('Ошибка добавления абонента');
        }
        methods.reset();
        return getSingleTaskfromCallMap(taskId);
      })
      .then(({ data }) => {
        setSingleGeoPoint(data);
      })
      .catch((err) => fetchCatch(err, 'Ошибка добавления абонента'))
      .finally(() => setIsInProgress(false));
  };

  useEffect(() => {
    GetCurrentUser()
      .then(({ data }) => {
        setCurrentUser(data?.fullName ?? null);
      })
      .catch((err) => fetchCatch(err, 'Ошибка получения данных о пользователе'));
  }, []);

  return (
    <Box m={1}>
      <Grid
        container
        spacing={4}
        mt={1}
      >
        <Grid
          item
          xs={8}
          height={'78vh'}
        >
          {isMapReady && (
            <Stack
              display={'flex'}
              alignItems={'center'}
            >
              <Tabs
                value={value}
                onChange={handleChange}
                variant='scrollable'
                scrollButtons='auto'
              >
                <CallingMapTab
                  label='карта'
                  count={mapCount}
                />
                <CallingMapTab
                  label='заявки без координат'
                  count={taskWithoutCoordinatesCount}
                />
                <CallingMapIncomingTasksTab />
              </Tabs>
            </Stack>
          )}
          <TabPanel
            value={value}
            index={0}
          >
            <CallingMapInner
              setMapAPI={setMapAPI}
              setZoomState={setZoomState}
              setDiffGeojson={setDiffGeojson}
              diffGeojson={diffGeojson}
            />
          </TabPanel>
          <TabPanel
            value={value}
            index={1}
          >
            <GeoLessPointsTable getGeoLessTableData={getGeoLessTableData} />
          </TabPanel>

          <TabPanel
            value={value}
            index={2}
          >
            <CallingMapIncomingTasks />
          </TabPanel>
        </Grid>
        <Grid
          item
          xs={4}
          mt={6}
        >
          <CallingMapFilter
            mapAPI={mapAPI}
            onSubmit={onSubmit}
            isTableMode={value}
            isTableSearch={isTableSearch}
            isIncommingTaskSearch={isIncommingTaskSearch}
            activeTab={activeTab}
            setActiveTab={setActiveTab}
          />
          {!isNumberInRange(mapZoom) && !singleSearch && (
            <BoxShadow
              sx={{ marginTop: 1 }}
              background={color('sandy')}
            >
              <Typography>Для работы с заявкой необходимо "приблизить" карту</Typography>
            </BoxShadow>
          )}
          <InfoBlockRebuild />
        </Grid>
        <CallingMapPopup
          isOpen={isOpenPopUp}
          abonent={singleAbonent}
          onClose={() => false}
        />
        <FormProvider {...methods}>
          <AccessPopup
            headerText={'Добавить абонента'}
            isOpen={isOpenAddAbonentPopUp}
            onClose={() => {
              setOpenAddAbonentPopup(false);
              methods.reset();
            }}
            onSuccess={() => {
              methods.handleSubmit(handleAddNumber)();
              setOpenAddAbonentPopup(false);
            }}
          >
            <SubscriberForm />
          </AccessPopup>
        </FormProvider>

        <EventCalandarPopup
          isOpen={isOpenEventCalendarPopUp}
          onClose={() => setIsOpenEventCalendarPopUp(false)}
          maxWidth={'lg'}
        />

        <Snackbar
          anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
          open={isOpenSnackBar}
        >
          <SnackbarContent
            sx={{ backgroundColor: '#FBDEDB' }}
            message={
              <Stack
                display={'flex'}
                flexDirection={'row'}
                gap={2}
                p={3}
              >
                <ErrorOutlineIcon style={{ color: color('trueRed') }} />
                <Typography style={{ color: color('typographyColorMap1') }}>
                  {errorMessageTextPopup}
                </Typography>
              </Stack>
            }
          />
        </Snackbar>
      </Grid>
    </Box>
  );
};
