import { createStore } from '@/utils/createStore';
import { useActionLog } from '@/hooks/ActionLogHook';
import {
  getAllowComplaintActStatuses,
  getComplaintAct,
  getComplaintActClassifications,
  getComplaintActStatusReasons,
  getComplaintActStatuses,
  updateComplaintAct,
} from '@/services/ComplaintService/ComplaintService';
import {
  ComplaintActClassificationsResponse,
  ComplaintActResponseNullable,
  ComplaintActStatusReasonsResponse,
  UpdateComplaintActRequest,
} from '@/services/ComplaintService/ComplaintServiceDto';

interface ComplaintActStore {
  compalintActStatuses: { label: string; value: string }[];
  allowActStatuses: { label: string; value: string }[];
  compalintActStatusReasons: ComplaintActStatusReasonsResponse;
  compalintActClassifications: ComplaintActClassificationsResponse;
  compalintAct: ComplaintActResponseNullable;
  isActLoading: boolean;
  fetchActStatuses: () => void;
  fetchActStatusReasons: () => void;
  fetchActClasssification: () => void;
  fetchAllowActStatuses: (actStatus: string) => void;
  fetchAct: (complaintId: number) => void;
  updateAct: (complaintId: number, body: UpdateComplaintActRequest) => void;
}

export const useComplaintActStore = createStore<ComplaintActStore>(
  (set, get) => ({
    compalintActStatuses: [],
    compalintActStatusReasons: [],
    compalintActClassifications: [],
    allowActStatuses: [],
    compalintAct: null,
    isActLoading: false,

    fetchActStatuses: () => {
      if (get().compalintActStatuses.length === 0) {
        getComplaintActStatuses()
          .then(({ data }) => {
            set(() => ({
              compalintActStatuses: Object.entries(data).map(([key, label]) => ({
                value: key,
                label,
              })),
            }));
          })
          .catch((error) => {
            useActionLog.getState().fetchCatch(error);
          });
      }
    },

    fetchActStatusReasons: () => {
      if (get().compalintActStatusReasons.length === 0) {
        getComplaintActStatusReasons()
          .then(({ data }) => {
            set(() => ({
              compalintActStatusReasons: data,
            }));
          })
          .catch((error) => {
            useActionLog.getState().fetchCatch(error);
          });
      }
    },

    fetchActClasssification: () => {
      if (get().compalintActClassifications.length === 0) {
        getComplaintActClassifications()
          .then(({ data }) => {
            set(() => ({
              compalintActClassifications: data,
            }));
          })
          .catch((error) => {
            useActionLog.getState().fetchCatch(error);
          });
      }
    },

    fetchAct: (complaintId: number) => {
      set(() => ({ isActLoading: true }));
      getComplaintAct(complaintId)
        .then(({ data }) => {
          set(() => ({
            compalintAct: data ?? null,
          }));
          if (data?.status) {
            get().fetchAllowActStatuses(data?.status);
          }
        })
        .catch((error) => {
          useActionLog.getState().fetchCatch(error);
        })
        .finally(() => set(() => ({ isActLoading: false })));
    },

    updateAct: (complaintId: number, body: UpdateComplaintActRequest) => {
      const oldAct = structuredClone(get().compalintAct);
      set(() => ({ isActLoading: true }));
      updateComplaintAct(complaintId, body)
        .then(({ data }) => {
          if (data?.status) {
            get().fetchAllowActStatuses(data?.status);
          }
          set(() => ({
            compalintAct: data,
          }));
        })
        .catch((error) => {
          set(() => ({
            compalintAct: oldAct,
          }));
          useActionLog.getState().fetchCatch(error);
        })
        .finally(() => set(() => ({ isActLoading: false })));
    },
    fetchAllowActStatuses: (actStatus: string) => {
      set(() => ({ isActLoading: true }));
      getAllowComplaintActStatuses(actStatus)
        .then(({ data }) => {
          set(() => ({
            allowActStatuses: Object.entries(data).map(([key, label]) => ({
              value: key,
              label,
            })),
          }));
        })
        .catch((error) => {
          useActionLog.getState().fetchCatch(error);
        })
        .finally(() => set(() => ({ isActLoading: false })));
    },
  }),
  'ComplaintAct store'
);
