import { Box, Grid } from '@mui/material';
import { Filter } from './filter';
import { PpoMapPane } from './mapPage';
import ResCard from '@/components/maps/ppoMap/resCard';
import ConsumerCard from '@/components/maps/ppoMap/consumerCard';
import { usePpoMap } from '@/components/maps/ppoMap/store';
import { useMemo } from 'react';
import { DeliveryPointCard } from '@/components/maps/ppoMap/placemark/delivery-point/DeliveryPointCard';
import { PillarCard } from './placemark/pillars/PillarCard';

export const PpoMap = () => {
  const { geoTaskInBounds, tmpTaskId, selectedTaskIds, geoTransStations } = usePpoMap();

  const selectedTasks = useMemo(() => {
    return (
      geoTaskInBounds
        ?.flatMap((elem) => elem.geoTaskList)
        .filter((task) => selectedTaskIds.includes(task.taskId)) || []
    );
  }, [selectedTaskIds, geoTaskInBounds]);

  return (
    <Box sx={{ m: 2 }}>
      <Grid
        container
        spacing={2}
      >
        <Grid
          item
          xs={12}
          md={selectedTaskIds.length ? 8 : 12}
          container
          direction='column'
          height='100%'
          spacing={2}
        >
          <Grid item>
            <Filter />
          </Grid>
          <Grid
            item
            xs={10}
            sx={{ overflow: 'hidden', minHeight: 100 }}
            width='100%'
            height='100%'
          >
            <PpoMapPane />
          </Grid>
        </Grid>
        {selectedTaskIds.length > 0 && (
          <Grid
            item
            xs={12}
            md={4}
            sx={{ height: '94vh', overflow: 'auto' }}
          >
            {selectedTasks.map((geoTask) => (
              <Box
                key={geoTask.taskId}
                sx={{ mb: 2 }}
              >
                <ResCard
                  {...geoTask}
                  t={geoTask}
                  deliveryPoints={
                    geoTransStations && geoTransStations[geoTask.taskId]?.deliveryPoints
                  }
                />
              </Box>
            ))}
            {selectedTasks.find((t) => t?.taskId === tmpTaskId) && <ConsumerCard />}
            {selectedTasks.find((t) => t?.taskId === tmpTaskId) && <PillarCard />}
            {selectedTasks.find((t) => t?.taskId === tmpTaskId) && <DeliveryPointCard />}
          </Grid>
        )}
      </Grid>
    </Box>
  );
};
