import { Box, Button, Grid, Stack, Tooltip, Typography } from '@mui/material';
import DoneIcon from '@mui/icons-material/Done';
import HighlightOffIcon from '@mui/icons-material/HighlightOff';
import { useCatalog } from '@/hooks/CatalogHook';
import moment from 'moment';
import { CombinedDto, SearchEnam } from './ReplacemetnViewDialog';
import { GSMItemNew } from '@/components/GSM/GSMItemNew';
import { NetworkElementResponse } from '@/services/NetworkService/dto/NetworkResponseDto';

interface ReplacementViewItemProps {
  dataResponse: CombinedDto;
  toSwap?(id?: string): void;
  handleMoveNetworkElement?(id?: string): void;
  handleSwapNetworkElement?(swapId?: string): void;
  searchResult: SearchEnam;
  query: string;
  swapByTaskId?(taskId?: string): void;
}

export const ReplacementViewItem = (props: ReplacementViewItemProps) => {
  const { getCatalogMapWithErr, getGroupCatalog } = useCatalog((state) => ({
    getCatalogMapWithErr: state.getCatalogMapWithErr,
    getGroupCatalog: state.getGroupCatalog,
  }));
  const isNetworkResponse = (data: CombinedDto): data is NetworkElementResponse => {
    return (data as NetworkElementResponse).mountInfo?.imei !== undefined;
  };
  const {
    toSwap,
    searchResult,
    dataResponse,
    query,
    swapByTaskId,
    handleMoveNetworkElement,
    handleSwapNetworkElement,
  } = props;

  const handleSwapOrMoveNetwork = () => {
    if (toSwap) {
      toSwap(dataResponse?.id);
    } else if (handleMoveNetworkElement) {
      handleMoveNetworkElement(dataResponse?.id);
    }
  };
  const handelMove = () => {
    if (swapByTaskId) {
      swapByTaskId(query);
    }
  };
  const handelSwapNetwork = () => {
    if (handleSwapNetworkElement) {
      handleSwapNetworkElement(query);
    }
  };

  return (
    <Box>
      <Grid
        container
        alignItems='center'
        spacing={3}
      >
        {searchResult === SearchEnam.SEARCH_BY_TASK_ID && !isNetworkResponse(dataResponse) && (
          <>
            <Grid
              item
              xs={1}
            >
              {(dataResponse?.status && <DoneIcon color='success'></DoneIcon>) || (
                <HighlightOffIcon color='error'>{dataResponse?.status}</HighlightOffIcon>
              )}
            </Grid>
            {dataResponse?.actDate ||
              (dataResponse?.completionDate && (
                <Grid
                  item
                  xs={2}
                >
                  <Typography variant='caption'>
                    {moment(dataResponse?.actDate || dataResponse?.completionDate).format(
                      'HH:mm:ss / DD:MM:yyyy'
                    )}
                  </Typography>
                </Grid>
              ))}

            <Grid
              item
              xs={8}
            >
              <Tooltip title={dataResponse?.actAgentName || ''}>
                <Typography noWrap> адресс: {dataResponse?.addressTitle || '-'} </Typography>
              </Tooltip>
              <Tooltip title={dataResponse?.actAgentName || ''}>
                <Typography noWrap>
                  {' '}
                  бригада: {getGroupCatalog()?.get(dataResponse?.executorId || '')}{' '}
                </Typography>
              </Tooltip>
            </Grid>

            <Grid
              item
              xs={12}
              container
              justifyContent={'center'}
            >
              <Grid>
                <Button
                  variant='text'
                  onClick={handelMove}
                >
                  выбрать
                </Button>
              </Grid>
            </Grid>
          </>
        )}
        {searchResult === SearchEnam.SEARCH_WITHOUT_TASK && !isNetworkResponse(dataResponse) && (
          <>
            <Grid item>
              {(dataResponse?.actual && <DoneIcon color='success'></DoneIcon>) || (
                <HighlightOffIcon color='error'></HighlightOffIcon>
              )}
            </Grid>
            <Grid
              item
              xs={2}
            >
              <Typography mb={-1}>
                <strong>
                  {getCatalogMapWithErr('changeStatus').get(dataResponse?.status || '')}
                </strong>
              </Typography>
              <Typography variant='caption'>
                {moment(dataResponse?.replacedOn).format('HH:mm:ss / DD:MM:yyyy')}
              </Typography>
            </Grid>
            <Grid
              item
              xs={3}
            >
              <Tooltip title={dataResponse?.actAgentName || ''}>
                <Typography noWrap> ФИО: {dataResponse?.actAgentName || '-'} </Typography>
              </Tooltip>
              <Tooltip title={dataResponse?.mounted?.montagePlace || ''}>
                <Typography
                  noWrap
                  variant='subtitle2'
                >
                  Место установки: {dataResponse?.mounted?.montagePlace || '-'}
                </Typography>
              </Tooltip>
            </Grid>
            <Grid
              item
              xs={2}
            >
              <Typography
                noWrap
                variant='subtitle2'
              >
                Смонтированный ПУ: {dataResponse?.mounted?.mac || '-'}
              </Typography>
              <Typography
                noWrap
                variant='subtitle2'
              >
                Демонтированный ПУ: {dataResponse?.unmounted?.mac || '-'}
              </Typography>
            </Grid>
            <Grid
              item
              xs={4}
            >
              <Typography variant='subtitle2'>
                Комментарий: {dataResponse?.lastValidMessage || '-'}
              </Typography>
            </Grid>
            <Grid
              item
              xs={12}
              container
              justifyContent={'center'}
            >
              <Grid>
                <Button
                  variant='text'
                  onClick={handleSwapOrMoveNetwork}
                >
                  выбрать
                </Button>
              </Grid>
            </Grid>
          </>
        )}
        {searchResult === SearchEnam.SEARCH_BY_NETWORK_ID && isNetworkResponse(dataResponse) && (
          <Stack
            p={5}
            alignItems={'center'}
            justifyContent={'space-around'}
          >
            <Grid
              mb={5}
              container
            >
              <Grid
                item
                xs={6}
              >
                <Typography variant='h6'>IMEI: {dataResponse.mountInfo?.imei}</Typography>
                <Typography variant='h6'>
                  Тип оборудования:{' '}
                  {getCatalogMapWithErr('networkEquipmentType').get(dataResponse.networkTypeId)}
                </Typography>
              </Grid>
              <Grid
                item
                xs={6}
              >
                {dataResponse.simChangeResponseList.map((el) => (
                  <GSMItemNew
                    {...el}
                    key={el.id}
                    showBtns={false}
                  />
                ))}
              </Grid>
            </Grid>

            <Grid
              item
              xs={12}
              container
              justifyContent={'center'}
            >
              <Grid>
                <Button
                  variant='text'
                  onClick={handelSwapNetwork}
                >
                  выбрать
                </Button>
              </Grid>
            </Grid>
          </Stack>
        )}
      </Grid>
    </Box>
  );
};
