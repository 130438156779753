import CommonModal from '@/components/common/CommonModal/CommonModal';
import { LoadingButton } from '@mui/lab';
import { Button, Checkbox, FormControlLabel, Typography } from '@mui/material';
import React, { useState } from 'react';
import { useRegistryEnforseFilter } from './store';
import shallow from 'zustand/shallow';

export interface ReportTaskWithAddresDownloadProps {
  isLoading: boolean;
  handleEnforseUpload: () => Promise<void>;
}

const ReportTaskWithAddresDownload = ({
  isLoading,
  handleEnforseUpload,
}: ReportTaskWithAddresDownloadProps) => {
  const { onlyToTasks, setOnlyToTasks } = useRegistryEnforseFilter(
    (state) => ({
      onlyToTasks: state.onlyToTasks,
      setOnlyToTasks: state.setOnlyToTasks,
    }),
    shallow
  );
  const [reportTaskWithAdressOpenModal, setReportTaskWithAdressOpenModal] = useState(false);
  const handleOpenModal = () => setReportTaskWithAdressOpenModal(true);
  const handleCloseModal = () => setReportTaskWithAdressOpenModal(false);

  const handleFetch = () => {
    handleEnforseUpload().finally(() => handleCloseModal());
  };
  return (
    <>
      <Button
        disabled={isLoading}
        onClick={handleOpenModal}
      >
        Отчет по заявкам с адресом
      </Button>
      <CommonModal
        open={reportTaskWithAdressOpenModal}
        onClose={handleCloseModal}
      >
        <Typography
          variant='h6'
          textAlign='center'
        >
          Параметры выгрузки
        </Typography>

        <FormControlLabel
          control={
            <Checkbox
              checked={onlyToTasks}
              onChange={(event, checked) => setOnlyToTasks(checked)}
            />
          }
          label='Выгружать только заявки ТО'
          sx={{ mt: 2 }}
        />

        <div
          style={{
            display: 'flex',
            justifyContent: 'center',
            marginTop: 40,
            gap: 20,
          }}
        >
          <LoadingButton
            variant='contained'
            onClick={handleFetch}
            loading={isLoading}
          >
            OK
          </LoadingButton>
          <Button onClick={handleCloseModal}>Отмена</Button>
        </div>
      </CommonModal>
    </>
  );
};

export default ReportTaskWithAddresDownload;
