import { color, scrollbarThin } from '@/styles/mixins';
import { IconButton, styled, Typography, keyframes, Autocomplete, Box } from '@mui/material';
import { TextField } from '@/components/fixUI';
import { AutocompleteValue } from '@/components/inputs/AutocompleteValue/AutocompleteValue';
import WarningAmberRoundedIcon from '@mui/icons-material/WarningAmberRounded';
import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined';
import { Loading } from '@/components/ui/Loading';

export const PnrPlanningModalStepsError = styled(Typography)({
  position: 'relative',
  display: 'flex',
  alignItems: 'center',
  gap: 4,
  lineHeight: 1,
});

interface PnrPlanningModalFirstStepColumnProps {
  gray?: boolean;
}
export const PnrPlanningIconWrapper = styled('div')<PnrPlanningModalFirstStepColumnProps>(
  ({ gray }) => ({
    fontSize: 22,

    svg: {
      ...(gray && { fill: color('textGray') }),
    },
  })
);

export const PnrPlanningModalStepsHeader = styled('p')({
  margin: 0,
  fontSize: '20px',
  fontWeight: '500',
});

interface PnrPlanningModalFirstStepColumnProps {
  marginTop?: boolean;
}
export const PnrPlanningModalFirstStepColumn = styled('div')<PnrPlanningModalFirstStepColumnProps>(
  ({ marginTop }) => ({
    display: 'flex',
    flexDirection: 'column',
    gap: 16,
    marginTop: marginTop ? 24 : 0,
    flexGrow: marginTop ? 1 : 0,
  })
);

export const PnrPlanningModalStepsAutosizerWrapper = styled('div')`
  flex-grow: 1;

  .virtualizedList {
    ${scrollbarThin}
  }
`;

interface PnrPlanningsRowLoadingProps {
  secondStep?: boolean;
}
export const PnrPlanningsRowLoading = styled(Loading)<PnrPlanningsRowLoadingProps>(
  ({ secondStep }) => ({
    height: '40px !important',
    marginLeft: secondStep ? 0 : 100,
  })
);

export const PnrPlanningModalFirstStepRowWrapper = styled('div')({
  display: 'flex',
  alignItems: 'center',
  gap: 16,
  paddingRight: 25,
});

export const PnrPlanningModalFirstStepDeleteIconButton = styled(IconButton)({
  padding: '5px',
});

interface PnrPlanningModalFirstStepCommentWrapperProps {
  disabled?: boolean;
}
export const PnrPlanningModalFirstStepCommentWrapper = styled(
  'div'
)<PnrPlanningModalFirstStepCommentWrapperProps>(({ disabled }) => ({
  cursor: 'pointer',
  pointerEvents: disabled ? 'none' : 'auto',
}));

interface PnrPlanningModalFirstStepRowBackgroundProps {
  isEdited?: boolean;
}

export const PnrPlanningModalFirstStepRowBackground = styled(
  PnrPlanningModalFirstStepRowWrapper
)<PnrPlanningModalFirstStepRowBackgroundProps>(({ isEdited }) => ({
  padding: '6px 16px 6px 32px',
  flexGrow: '1',
  justifyContent: 'space-between',
  transition: '0.4s',
  borderRadius: 6,
  input: {
    color: isEdited ? color('trueBlack') : color('muiBlue'),
  },
}));

export const PnrPlanningModalStepWrapper = styled('div')({
  padding: '25px 0 25px 25px',
  display: 'flex',
  flexDirection: 'column',
  gap: 16,
  flexGrow: 1,
});

interface PnrPlanningModalIconButtonProps {
  deleted?: boolean;
}

const rotate = keyframes`
  50% {
  transform: rotate(310deg);
  }
  75% {
  transform: rotate(260deg);
  }
  100% {
  transform: rotate(280deg);
  }

`;
export const PnrPlanningModalIconButton = styled(IconButton)<PnrPlanningModalIconButtonProps>(
  ({ deleted }) => ({
    svg: { transition: '0.4s' },
    ...(deleted
      ? { '&:hover': { svg: { animation: `${rotate} 1s 1 both` } } }
      : { '&:hover': { svg: { fill: color('muiError') } } }),
  })
);

export const PnrPlanningModalTextField = styled(TextField)({
  width: 200,
  '.MuiInput-root::before': {
    display: 'none',
  },
});

export const PnrPlanningModalAutocomplete = styled(Autocomplete)({
  width: 200,
  height: 48,
  display: 'flex',
  alignItems: 'flex-end',
});

export const PnrPlanningModalAutocompleteValue = styled(AutocompleteValue)({
  '.MuiInputBase-root::before': {
    borderBottom: 'none !important',
  },
});

export const PnrPlanningModalEquipmentTypeWrapper = styled('div')({
  display: 'flex',
  gap: 16,
  alignItems: 'flex-end',
  width: 240,
  justifyContent: 'flex-end',
});

export const PnrPlanningModalCommentTextField = styled(PnrPlanningModalTextField)({
  '& .MuiInputBase-input': {
    overflow: 'hidden',
    textOverflow: 'ellipsis',
  },
  pointerEvents: 'none',
});

const pulse = keyframes`
  0%, 100% {
  transform: scale3d(1, 1, 1);
  }
  50% {
  transform: scale3d(1.3, 1.3, 1.3);
  }

`;

export const PnrPlanningModalConflictIcon = styled(WarningAmberRoundedIcon)({
  animation: `${pulse} 0.7s 1 both`,
});

export const PnrPlanningModalInfoIcon = styled(InfoOutlinedIcon)({
  animation: `${pulse} 0.7s 1 both`,
  fill: color('textGray'),
});

interface PnrPlanningModalEmptyFieldProps {
  label: string;
  value?: string;
}
export const PnrPlanningModalInfoField = ({
  label,
  value = '',
}: PnrPlanningModalEmptyFieldProps) => (
  <PnrPlanningModalTextField
    label={label}
    value={value}
    disabled
    variant='standard'
    InputLabelProps={{ shrink: true }}
  />
);

export const PnrPlanningModalSecondStepInfoWrapper = styled('div')({
  borderRadius: 6,
  padding: '5px 0',
  display: 'flex',
  flexDirection: 'column',
  gap: 4,
  flexGrow: 1,
});

export const PnrPlanningModalSecondStepFormWrapper = styled('div')({
  display: 'flex',
  gap: 40,
  alignItems: 'flex-end',
});

export const PnrPlanningModalDateWrapper = styled('div')({
  input: { borderBottom: `1px solid ${color('trueBlack', 0.42)}!important`, fontSize: 'inherit' },

  '.parent': {
    padding: 0,
  },

  '.data-range-input': {
    width: 240,
  },
});

export const PnrPlanningModalButtonsWrapper = styled(Box)({
  display: 'flex',
  flexDirection: 'row',
  gap: 14,
  marginTop: '30px',
});

export const PnrPlanningModalConflictIconWrapper = styled('span')({
  display: 'flex',
  alignItems: 'center',
  fontSize: '12px',
  gap: 10,
});
