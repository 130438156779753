import React, { useEffect, useState } from 'react';
import { SelectFilterOptType } from '@/components/filter/MultySelectFilter';
import { FormProvider, useForm } from 'react-hook-form';
import FilterButtons from '@/components/filter/FilterButtons/FilterButtons';
import { MultiSelect } from '@/components/inputs/MultiSelectNew';
import { FILTERS_LABELS } from '../utils';
import { LabelSwitch, ShowInfoControllLabel, WrapperFilters } from '../InvestStatistic.styled';
import { Switch } from '@mui/material';
import { generateDetailedInfo } from '@/components/button/DetailedInfo/utils';
import { useSaveFilterButton } from '@/components/filter/FilterButtons/utils';
import { getDataFromFilter } from '@/components/inputs/MultiSelectNew/utils';
import { GetStatistiSummary } from '../../api/InvestStatisticService';
import { useInvestStatisticStore } from '../../store';
import shallow from 'zustand/shallow';
import { SelectWrapper, SwitchWrapper } from './BudgetStatisticDoughnut.styled';
import { useBudgetStatisticEnergyCompany } from '../../hooks/useBudgetStatisticEnergyCompany';
import { useBudgetStatisticContracts } from '../../hooks/useBudgetStatisticContracts';
import { fetchCatch } from '@/hooks/ActionLogHook';
import { DisplayManufacturer } from '../../types/types';
import { SwitchOptionsPopover } from '../SwitchOptionsPopover/SwitchOptionsPopover';

interface BudgetStatisticDoughnutFormProps {
  displayManufacturer: DisplayManufacturer;
  setDisplayManufacturer: (updater: (prev: DisplayManufacturer) => DisplayManufacturer) => void;
}

const BudgetStatisticDoughnutForm = ({
  displayManufacturer,
  setDisplayManufacturer,
}: BudgetStatisticDoughnutFormProps) => {
  const methods = useForm();
  const { handleSubmit } = methods;

  const { catalogContracts } = useBudgetStatisticContracts();
  const { catalogEnergyCompany } = useBudgetStatisticEnergyCompany();

  const {
    showInfo,
    selectedContract,
    setSelectedContract,
    setEquipmentsData,
    setShowInfo,
    setActiveIndex,
    setSelectedColumn,
    selectedEnergyCompany,
    setSelectedEnergyCompany,
    setCheckedEnergyCompany,
    countAsMoney,
    setCountAsMoney,
  } = useInvestStatisticStore(
    (state) => ({
      showInfo: state.showInfo,
      selectedContract: state.selectedContract,
      setSelectedContract: state.setSelectedContract,
      setEquipmentsData: state.setEquipmentsData,
      setShowInfo: state.setShowInfo,
      setActiveIndex: state.setActiveIndex,
      setSelectedColumn: state.setSelectedColumn,
      selectedEnergyCompany: state.selectedEnergyCompany,
      setSelectedEnergyCompany: state.setSelectedEnergyCompany,
      setCheckedEnergyCompany: state.setCheckedEnergyCompany,
      countAsMoney: state.countAsMoney,
      setCountAsMoney: state.setCountAsMoney,
    }),
    shallow
  );

  const onResetClick = () => {
    setSelectedContract([]);
    setSelectedEnergyCompany([]);
  };

  const detailedInfo = generateDetailedInfo(
    [selectedContract, FILTERS_LABELS.contract],
    [selectedEnergyCompany, FILTERS_LABELS.energyCompany]
  );

  const onSubmit = () => {
    // setEquipmentsData(SUMMURY_DATA);
    setCheckedEnergyCompany(selectedContract.length ? false : true);
    GetStatistiSummary({
      contractIds: getDataFromFilter(selectedContract, 'value'),
      energyCompanyId: getDataFromFilter(selectedEnergyCompany, 'value'),
      countAsMoney,
    })
      .then(({ data }) => {
        setEquipmentsData(data);
        setActiveIndex(null);
        setSelectedColumn(null);
      })
      .catch((err) => fetchCatch(err, 'Ошибка получения данных статистики'));
  };

  useEffect(() => {
    onSubmit();
  }, [countAsMoney]);

  const SAVE_FILTER_KEY = 'InvestStatistic';

  const { getFilters } = useSaveFilterButton(SAVE_FILTER_KEY);

  const onSelectSavedFilter = (filterKey: string) => {
    const savedFilters = getFilters<SelectFilterOptType[]>(filterKey);
    savedFilters.selectedContract && setSelectedContract(savedFilters.selectedContract);
    savedFilters.selectedEnergyCompany &&
      setSelectedEnergyCompany(savedFilters.selectedEnergyCompany);
  };

  const [anchorEl, setAnchorEl] = useState<HTMLButtonElement | null>(null);
  const handleClose = () => {
    setAnchorEl(null);
  };

  return (
    <FormProvider {...methods}>
      <form
        onSubmit={handleSubmit(onSubmit)}
        style={{ position: 'relative' }}
      >
        <WrapperFilters>
          <div
            style={{
              display: 'flex',
              alignItems: 'flex-end',
              width: '40%',
              justifyContent: 'space-between',
            }}
          >
            <SelectWrapper>
              {catalogContracts && (
                <MultiSelect
                  label={FILTERS_LABELS.contract}
                  options={catalogContracts}
                  value={selectedContract}
                  disabled={selectedEnergyCompany.length !== 0}
                  withoutBullAnswerReset
                  onChange={(value: SelectFilterOptType[]) => setSelectedContract(value)}
                />
              )}
              {catalogEnergyCompany && (
                <MultiSelect
                  label={FILTERS_LABELS.energyCompany}
                  options={catalogEnergyCompany}
                  value={selectedEnergyCompany}
                  disabled={selectedContract.length !== 0}
                  withoutBullAnswerReset
                  onChange={(value: SelectFilterOptType[]) => setSelectedEnergyCompany(value)}
                />
              )}
            </SelectWrapper>
            <div>
              <FilterButtons
                info={detailedInfo}
                onSearchClick={() => handleSubmit(onSubmit)}
                onCloseClick={onResetClick}
                saveFilter={{
                  filterType: SAVE_FILTER_KEY,
                  infoToSave: { selectedContract, selectedEnergyCompany },
                  selectCallback: onSelectSavedFilter,
                }}
              />
            </div>
          </div>
          <div style={{ display: 'flex', width: '60%', justifyContent: 'space-between' }}>
            <SwitchWrapper>
              <div style={{ display: 'flex', alignItems: 'center' }}>
                <LabelSwitch
                  checked={!countAsMoney}
                  sx={{ cursor: 'pointer' }}
                  onClick={() => setCountAsMoney(false)}
                >
                  ШТ
                </LabelSwitch>
                <Switch
                  checked={countAsMoney}
                  onChange={(e) => setCountAsMoney(e.target.checked)}
                />
                <LabelSwitch
                  checked={countAsMoney}
                  sx={{ cursor: 'pointer' }}
                  onClick={() => setCountAsMoney(true)}
                >
                  ₽
                </LabelSwitch>
              </div>

              <ShowInfoControllLabel
                active={showInfo}
                control={
                  <Switch
                    checked={showInfo}
                    onChange={(e) => setShowInfo(e.target.checked)}
                  />
                }
                label={'Показать инфо'}
              />
            </SwitchWrapper>
            <SwitchOptionsPopover />
          </div>
        </WrapperFilters>
      </form>
    </FormProvider>
  );
};

export default BudgetStatisticDoughnutForm;
