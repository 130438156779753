import { Box, Grid } from '@mui/material';
import React, { useState } from 'react';
import Filter from '@/components/maps/pillarMap/filter/Filter';
import PillarMapInner from '@/components/maps/pillarMap/map';
import Toolbar from '@/components/maps/pillarMap/toolbar/Toolbar';
import { CancelPopup } from '@/components/popups/CancelPopup';
import {
  AssignContractorPopup,
  AssignExecAndDatePopup,
  ReAssignContractorPopup,
} from '@/components/popups';
import { ReturnTaskBackPopup } from '@/components/popups/ReturnTaskBackPopup';
import { ApiCancelRequest } from '@/services/YodaRestService/YodaRestService';
import { usePillarMapState } from '@/components/maps/pillarMap/store';
import { addSuccessActionLog, fetchCatch, syncErrorCatch } from '@/hooks/ActionLogHook';

type ZoomStateType = 'SAME' | 'OUT' | 'IN';

export const PillarMap = () => {
  const [zoomState, setZoomState] = useState<ZoomStateType>('SAME');
  const [diffGeojson, setDiffGeojson] = useState<any>(null);
  const [mapAPI, setMapAPI] = useState(null);

  // const [showCopyPopup, setShowCopyPopup] = useState(false);
  // const [showCopyPopup1, setShowCopyPopup1] = useState(false);
  // const [showCopyPopup2, setShowCopyPopup2] = useState(false);
  // const [showCopyPopup3, setShowCopyPopup3] = useState(false);
  const [showAssignContractorPopup, setShowAssignContractorPopup] = useState(false);
  // const [showAssignContractPopup, setShowAssignContractPopup] = useState(false);
  const [showReturnBackPopup, setShowReturnBackPopup] = useState(false);
  // const [showChiperPopup, setShowChiperPopup] = useState(false);
  const [showReAssignContractPopup, setShowReAssignContractPopup] = useState(false);
  const [showExecAndDatePopup, setShowExecAndDatePopup] = useState(false);
  const [showCancelPopup, setShowCancelPopup] = useState(false);
  // const [showLoadingVectorDiagrams, setShowLoadingVectorDiagrams] = useState(false);
  // const [showCreateColumn, setShowshowCreateColumn] = useState(false);
  // const [showSelectAllPopup, setShowSelectAllPopup] = useState(false);
  // const [showAddTagPopup, setShowAddTagPopup] = useState(false);
  const { activePoint } = usePillarMapState.getState();

  const checkedRows = activePoint != undefined ? [activePoint.id] : [];

  const onSuccessCancel = () => {
    !activePoint && syncErrorCatch('Не выбрана заявка');
    activePoint &&
      ApiCancelRequest([activePoint.id])
        .then(() => {
          setShowCancelPopup(false);
          addSuccessActionLog(`Заявка №${activePoint.number} отменена`);
        })
        .catch((error) => fetchCatch(error));
  };

  return (
    <Box sx={{ m: 2 }}>
      <Grid
        container
        spacing={2}
      >
        <Grid
          item
          xs={12}
          md={12}
        >
          <Filter />
        </Grid>
        <Grid
          item
          xs={12}
          md={12}
        >
          <Toolbar
            // checkPopupError={!!checkedRows.length}
            checkPopupError={true}
            // checkPopupError={activePoint != undefined}
            setShowAssignContractorPopup={setShowAssignContractorPopup}
            setShowCancelPopup={setShowCancelPopup}
            setShowExecAndDatePopup={setShowExecAndDatePopup}
            setShowReAssignContractPopup={setShowReAssignContractPopup}
            setShowReturnBackPopup={setShowReturnBackPopup}
          />
        </Grid>
        <Grid
          item
          xs={12}
          md={12}
        >
          <PillarMapInner
            setMapAPI={setMapAPI}
            setZoomState={setZoomState}
            setDiffGeojson={setDiffGeojson}
            diffGeojson={diffGeojson}
          />
        </Grid>
      </Grid>

      <CancelPopup
        onSuccess={onSuccessCancel}
        isOpen={showCancelPopup}
        onClose={() => setShowCancelPopup(false)}
      />
      {/*{showCopyPopup && (*/}
      {/*  <CopyPopup*/}
      {/*    onSuccess={getItems}*/}
      {/*    taskIds={checkedRows}*/}
      {/*    isOpen={showCopyPopup}*/}
      {/*    contractId={getFirstCheckedRowValueByKey()}*/}
      {/*    onClose={() => setShowCopyPopup(false)}*/}
      {/*  />*/}
      {/*)}*/}
      {/*{showCopyPopup1 && (*/}
      {/*  <CopyPopup*/}
      {/*    onSuccess={getItems}*/}
      {/*    taskIds={checkedRows}*/}
      {/*    isOpen={showCopyPopup1}*/}
      {/*    taskType={'COMPLAINT_MKD'}*/}
      {/*    contractId={getFirstCheckedRowValueByKey()}*/}
      {/*    onClose={() => setShowCopyPopup1(false)}*/}
      {/*  />*/}
      {/*)}*/}
      {/*{showCopyPopup2 && (*/}
      {/*  <CopyPopup*/}
      {/*    onSuccess={getItems}*/}
      {/*    taskIds={checkedRows}*/}
      {/*    isOpen={showCopyPopup2}*/}
      {/*    taskType={'REPROGRAMMING'}*/}
      {/*    contractId={getFirstCheckedRowValueByKey()}*/}
      {/*    onClose={() => setShowCopyPopup2(false)}*/}
      {/*  />*/}
      {/*)}*/}
      {/*{showCopyPopup3 && (*/}
      {/*  <CopyPopup*/}
      {/*    onSuccess={getItems}*/}
      {/*    taskIds={checkedRows}*/}
      {/*    isOpen={showCopyPopup3}*/}
      {/*    contractId={getFirstCheckedRowValueByKey()}*/}
      {/*    taskType={'SBSD'}*/}
      {/*    onClose={() => setShowCopyPopup3(false)}*/}
      {/*  />*/}
      {/*)}*/}
      <AssignContractorPopup
        // onSuccess={getItems}
        taskIds={checkedRows}
        isOpen={showAssignContractorPopup}
        onClose={() => setShowAssignContractorPopup(false)}
      />
      <ReAssignContractorPopup
        // onSuccess={getItems}
        taskIds={checkedRows}
        isOpen={showReAssignContractPopup}
        onClose={() => setShowReAssignContractPopup(false)}
      />
      {/*{showAssignContractPopup && (*/}
      {/*  <AssignContractPopup*/}
      {/*    onSuccess={getItems}*/}
      {/*    taskIds={checkedRows}*/}
      {/*    isOpen={showAssignContractPopup}*/}
      {/*    onClose={() => setShowAssignContractPopup(false)}*/}
      {/*  />*/}
      {/*)}*/}
      <AssignExecAndDatePopup
        // onSuccess={getItems}
        taskIds={checkedRows}
        isOpen={showExecAndDatePopup}
        onClose={() => setShowExecAndDatePopup(false)}
      />
      {/*{showLoadingVectorDiagrams && (*/}
      {/*  <LoadingVectorDiagramsPopup*/}
      {/*    onSuccess={getItems}*/}
      {/*    isOpen={showLoadingVectorDiagrams}*/}
      {/*    onClose={() => setShowLoadingVectorDiagrams(false)}*/}
      {/*  />*/}
      {/*)}*/}
      {/*<CreateColumnPopup*/}
      {/*  isOpen={showCreateColumn}*/}
      {/*  headerText={'Внесите данные'}*/}
      {/*  successText='Сохранить'*/}
      {/*  onClose={onCloseCreateColumn}*/}
      {/*/>*/}

      {/*<SelectAllPopup*/}
      {/*  isOpen={showSelectAllPopup}*/}
      {/*  onClose={onCloseShowAllPopup}*/}
      {/*  filters={activeFilters}*/}
      {/*  onSuccess={handleShowAllPopupSuccess}*/}
      {/*/>*/}
      {/*<SelectAllCipherPopup*/}
      {/*  isOpen={showChiperPopup}*/}
      {/*  onClose={onCloseShowAllChiperPopup}*/}
      {/*  taskIds={checkedRows}*/}
      {/*  onSuccess={handleShowAllPopupSuccess}*/}
      {/*/>*/}
      <ReturnTaskBackPopup
        // onSuccess={getItems}
        taskIds={checkedRows}
        isOpen={showReturnBackPopup}
        // singleExecutorId={getFirstCheckedRowValueByKey('executorId')}
        singleExecutorId={null}
        onClose={() => setShowReturnBackPopup(false)}
      />
      {/*<AddTagPopup*/}
      {/*  isOpen={showAddTagPopup}*/}
      {/*  onClose={() => setShowAddTagPopup(false)}*/}
      {/*  taskIds={checkedRows}*/}
      {/*/>*/}
    </Box>
  );
};
