import { styled } from '@mui/material';

export const CallCenterStatisticsFiltersWrapper = styled('div')(
  ({ isMobile }: { isMobile: boolean }) => ({
    padding: '0 0 0 5vw',
    paddingBottom: 30,
    position: 'relative',
    display: 'flex',
    flexWrap: isMobile ? 'wrap' : 'nowrap',
    justifyContent: isMobile ? 'center' : 'flex-start',
    alignItems: isMobile ? 'center' : 'flex-end',
    gap: 6,
    marginTop: 16,
    marginRight: 30,
  })
);
