import { TaskInfoItem } from '@/services/SupplyBillService/dto/SupplyBillServiceResponse';
import { SupplyNetworkElementTypeResponse, SupplyPuTypeResponse } from '@/dto/SupplyBillServiceDto';
import {
  SupplyBillBrowseDto,
  SupplyNetworkElement,
  SupplyPU,
  SupplyStamp,
} from '@/dto/taskmap/Dto';
import { createStore } from '@/utils/createStore';

export interface SupplyState {
  billType: number;
  billNumber: string;
  m15FormNumber: string;
  contractorId: string;
  supplyId: string;
  billDate: string;
  supplyBillDto?: SupplyBillBrowseDto;
  stampList: SupplyStamp[];
  wholeCount: string;
  declaredCount: string;
  puList: SupplyPU[];
  osList: SupplyNetworkElement[];
  setStampList: (stampList: SupplyStamp[]) => void;
  resetStampList: () => void;
  setSupplyBillDto: (supplyBillDto: SupplyBillBrowseDto) => void;
  setPUList: (puList: SupplyPU[]) => void;
  setOSList: (osList: SupplyNetworkElement[]) => void;
  setWholeCount: (wholeCount: string) => void;
  setDeclaredCount: (declaredCount: string) => void;
  setBillType: (billType: number) => void;
  setBillNumber: (billNumber: string) => void;
  setM15FormNumber: (m15FormNumber: string) => void;
  setContractorId: (contractorId: string) => void;
  setSupplyId: (supplyId: string) => void;
  setBillDate: (billDate: string) => void;
  resetState: () => void;
  checkedTaskNumbers: Map<string, 'success' | 'checking' | 'error'>;
  supplyMeterTypes: Map<number, SupplyPuTypeResponse>;
  setSupplyMeterTypes: (supplyMeterTypes: Map<number, SupplyPuTypeResponse>) => void;
  supplyOsTypes: Map<number, SupplyNetworkElementTypeResponse>;
  setSupplyOsTypes: (supplyOsTypes: Map<number, SupplyNetworkElementTypeResponse>) => void;
  returnNewItemsMode: boolean;
  setReturnNewItemsMode: (returnNewItemsMode: boolean) => void;
  forcedTaskNumberInfo: Map<string, TaskInfoItem | string>;
  setForcedTaskNumberInfo: (forcedTaskNumberInfo: Map<string, TaskInfoItem | string>) => void;
  projectId: string;
  setProjectId: (projectId: string) => void;
  filters: Record<string, string[]>;
  setFilters: (updater: (prev: Record<string, string[]>) => Record<string, string[]>) => void;
}

export const initialPuList = [
  {
    tmpId: 1,
    meterTypeId: -1,
    meterTypeName: '',
    puItems: [],
    declaredCount: '',
  },
];

export const initialStampList = [
  {
    id: 1,
    from: '',
    to: '',
    count: 0,
    stampLetter: '',
    stampItems: [],
  },
];

export const initialOsList: SupplyNetworkElement[] = [
  {
    typeId: -1,
    typeName: '',
    networkElementItems: [],
    declaredCount: '',
    addedCount: '',
    tmpId: 1,
  },
];

const initialState = {
  billType: -1,
  billNumber: '',
  m15FormNumber: '',
  contractorId: '',
  supplyId: '',
  billDate: '',
  lastActiveTab: undefined,
  supplyBillDto: undefined,
  stampList: [...initialStampList],
  puList: [...initialPuList],
  osList: [...initialOsList],
  wholeCount: '',
  declaredCount: '',
  checkedTaskNumbers: new Map(),
  supplyMeterTypes: new Map(),
  supplyOsTypes: new Map(),
  returnNewItemsMode: false,
  forcedTaskNumberInfo: new Map(),
  projectId: '',
  filters: {},
};

export const useSupplyStore = createStore<SupplyState>(
  (set, get) => ({
    ...initialState,
    setStampList: (stampList) =>
      set((state) => ({
        ...state,
        stampList,
      })),
    setPUList: (puList) =>
      set((state) => ({
        ...state,
        puList,
      })),
    setOSList: (osList) =>
      set((state) => ({
        ...state,
        osList,
      })),

    setWholeCount: (wholeCount) =>
      set((state) => ({
        ...state,
        wholeCount,
      })),
    setDeclaredCount: (declaredCount) =>
      set((state) => ({
        ...state,
        declaredCount,
      })),
    setBillType: (billType) =>
      set((state) => ({
        ...state,
        billType,
      })),
    setBillNumber: (billNumber) =>
      set((state) => ({
        ...state,
        billNumber,
      })),
    setM15FormNumber: (m15FormNumber) =>
      set((state) => ({
        ...state,
        m15FormNumber,
      })),
    setContractorId: (contractorId) =>
      set((state) => ({
        ...state,
        contractorId,
      })),
    setSupplyId: (supplyId) =>
      set((state) => ({
        ...state,
        supplyId,
      })),
    setBillDate: (billDate) =>
      set((state) => ({
        ...state,
        billDate,
      })),

    setSupplyBillDto: (supplyBillDto) =>
      set((state) => ({
        ...state,
        supplyBillDto,
      })),

    setSupplyMeterTypes: (supplyMeterTypes) => set(() => ({ supplyMeterTypes })),
    setReturnNewItemsMode: (returnNewItemsMode) => set(() => ({ returnNewItemsMode })),
    setForcedTaskNumberInfo: (forcedTaskNumberInfo) => set(() => ({ forcedTaskNumberInfo })),

    setSupplyOsTypes: (supplyOsTypes) => set(() => ({ supplyOsTypes })),

    setProjectId: (projectId) =>
      set((state) => ({
        ...state,
        projectId,
      })),

    resetState: () =>
      set((state) => ({
        ...initialState,
        billType: state.billType,
      })),

    resetStampList: () =>
      set((state) => ({
        ...state,
        stampList: [...initialStampList],
      })),

    setFilters: (updater: (prev: Record<string, string[]>) => Record<string, string[]>) => {
      set((state) => ({
        filters: updater(state.filters),
      }));
    },
  }),
  'Supply'
);

export default useSupplyStore;
