import React, { FC } from 'react';
import { Button } from '@mui/material';
import { TaskSibling } from '@/dto/taskmap/Dto';
import { Link } from 'react-router-dom';
import { useCatalog } from '@/hooks/CatalogHook';

export const SmrButton: FC<TaskSibling> = ({ taskType, number, id }) => {
  const { getCatalog } = useCatalog((state) => ({
    getCatalog: state.getCatalogMapWithErr,
  }));
  return (
    <Button>
      <Link
        to={`/task/edit/${id}`}
        style={{ textDecoration: 'none', color: 'inherit' }}
        target={'_blank'}
      >
        {`${getCatalog('taskType').get(taskType) ?? 'Заявка с неизвесным типом'} №${number}`}
      </Link>
    </Button>
  );
};
