import { mainIcon, topIcon, TopIconType } from './icons';

export interface IDeliveryPointLayout {
  id: string;
  ymaps: any;
  status: string;
}

// new
// assigned and returned
// inWork and chek
// completed

export const pillarLayout = ({ id, ymaps, status }: IDeliveryPointLayout): any => {
  const width = 34;
  const iconStatus =
    status == 'NEW'
      ? TopIconType.NEW
      : status == 'ASSIGNED' || status == 'RETURNED'
      ? TopIconType.RETURN
      : status == 'IN_WORK' || status == 'CHECK'
      ? TopIconType.IN_WORK
      : status == 'COMPLETED'
      ? TopIconType.CLOSED
      : TopIconType.NEW;
  return {
    iconLayout: ymaps.templateLayoutFactory
      .createClass(`<div class="pillar-balloon_id_${id}" style="position: relative">
            <div style="
              position: absolute; 
              width: ${width}px; 
              top: ${(width * 19) / 30}px"
            >
                ${mainIcon()}
            </div>
            <div style="
              position: absolute; 
              width: ${width * (5 / 6)}px; 
              left: ${width * (1 / 12)}px"
            >
                ${topIcon(iconStatus)}
            </div>
          </div>`),
    iconShape: {
      type: 'Circle',
      // Круг описывается в виде центра и радиуса
      coordinates: [0.5 * width, width],
      radius: 0.8 * width,
    },
    iconOffset: [-0.5 * width, -1.95 * width],
  };
};
