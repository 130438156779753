import { IStore } from './store';
import { CatalogTypes } from './constants';
import {
  KooBrowseFilterParam,
  PUBrowseFilterParam,
  SimBrowseFilterParam,
  StampBrowseFilterParam,
} from '@/services/CatalogService/dto/CatalogsDtoRequest';

export const arrowLabel = (type: 'first' | 'last' | 'next' | 'previous'): string => {
  switch (type) {
    case 'first':
      return 'первая';
    case 'last':
      return 'последняя';
    case 'next':
      return 'следующая';
    case 'previous':
      return 'предыдущая';
  }
};

interface IConverter {
  o: IStore;
  type: CatalogTypes;
}

export const converter = ({
  o,
  type,
}: IConverter):
  | KooBrowseFilterParam
  | SimBrowseFilterParam
  | PUBrowseFilterParam
  | StampBrowseFilterParam => {
  const store =
    type === CatalogTypes.STAMP
      ? {
          numberArg: o.number.argValue,
          numberList: o.number.value,
          idArg: o.id.argValue,
          idList: o.id.value,
        }
      : type === CatalogTypes.KOO
      ? {
          numberArg: o.number.argValue,
          numberList: o.number.value,
          idArg: o.id.argValue,
          idList: o.id.value,
          macArg: o.mac.argValue,
          macList: o.mac.value,
          imeiArg: o.imei.argValue,
          imeiList: o.imei.value,
          typeIdArg: o.typeId.argValue,
          typeIdList: o.typeId.value.map((elem: any) => elem.value),

          simNumArg: o.simNum.argValue,
          simNum: o.simNum.value,
          simIccidArg: o.simIccid.argValue,
          simIccid: o.simIccid.value,
        }
      : type === CatalogTypes.SIM
      ? {
          numberArg: o.number.argValue,
          numberList: o.number.value,
          idArg: o.id.argValue,
          idList: o.id.value,
          ipArg: o.ip.argValue,
          ipList: o.ip.value,
          iccidArg: o.simIccid.argValue,
          iccidList: o.simIccid.value,
        }
      : // type === CatalogTypes.PU
        {
          typeIdArg: o.typeId.argValue,
          typeIdList: o.typeId.value.map((elem: any) => elem.value),
          imeiArg: o.imei.argValue,
          imeiList: o.imei.value,
          phaseArg: o.phase.argValue,
          phaseList: o.phase.value.map((elem: any) => elem.value),
          numberArg: o.number.argValue,
          numberList: o.number.value,
          macArg: o.mac.argValue,
          macList: o.mac.value,
          idArg: o.id.argValue,
          idList: o.id.value,
        };

  return Object.entries(store).reduce((acc, [key, value]) => {
    if (key.includes('Arg')) {
      return {
        ...acc,
        [key]: value,
      };
    }
    if (key.includes('sim')) {
      return {
        ...acc,
        [key]: value,
      };
    }
    if (value || value === 0) {
      if (Array.isArray(value)) {
        if (value.length) {
          return {
            ...acc,
            [key]: value.map((item) => item),
          };
        } else {
          return {
            ...acc,
            [key]: [],
          };
        }
      } else {
        return {
          ...acc,
          [key]: value.toString().split(', ').join(',').split(','),
          // .map((elem: any) => elem.split(' ').join('')),
        };
      }
    }
    return { ...acc, [key]: [] };
  }, {} as KooBrowseFilterParam | SimBrowseFilterParam | PUBrowseFilterParam | StampBrowseFilterParam);
};
