import React, { FC, useEffect, useMemo, useState } from 'react';
import { Loading } from '@/components/ui/Loading';
import { Grid } from '@mui/material';
import { HistoryResponse, TaskResultType } from '@/dto/taskmap/task/TaskResultDto';
import { useParams } from 'react-router-dom';
import { findAllResultByTaskIdAndType } from '@/services/TaskService/TaskService';
import { syncErrorCatch, useActionLog } from '@/hooks/ActionLogHook';
import { HistoryGroup } from './HistoryGroup';

interface IRes {
  [x: string]: HistoryResponse[];
}

export const History: FC = () => {
  const { taskId } = useParams();
  const { fetchCatch } = useActionLog();
  const [loading, setLoading] = useState<boolean>(false);
  const [response, setResponse] = useState<HistoryResponse[]>([]);

  const loadHistory = () => {
    if (taskId) {
      setLoading(true);
      findAllResultByTaskIdAndType(taskId, TaskResultType.HISTORY)
        .then((res) => setResponse(res.data))
        .catch((err) => fetchCatch(err, 'Ошибка при загрузке истории'))
        .finally(() => setLoading(false));
    } else {
      syncErrorCatch('Отсутствует ID заявки');
    }
  };
  useEffect(loadHistory, [taskId]);

  const historyResponsesGroupArr: HistoryResponse[][] = useMemo(() => {
    const result = response.reduce((resArr: IRes, elem) => {
      if (resArr[`${elem.eventAt}_${elem.fullName}`]) {
        return {
          ...resArr,
          [`${elem.eventAt}_${elem.fullName}`]: [
            ...resArr[`${elem.eventAt}_${elem.fullName}`],
            elem,
          ],
        };
      } else {
        return { ...resArr, [`${elem.eventAt}_${elem.fullName}`]: [elem] };
      }
    }, {});
    let tmp: HistoryResponse[][] = [];
    Object.keys(result).forEach((key) => {
      tmp = [...tmp, result[key]];
    });
    return tmp.sort((a, b) => b[0].eventAt - a[0].eventAt);
  }, [response]);

  return (
    <Grid
      container
      spacing={2}
    >
      <Loading loading={loading}>
        {response &&
          historyResponsesGroupArr.map((t) => (
            <HistoryGroup
              key={`${t[0].eventAt}_${t[0].fullName}`}
              group={t}
            />
          ))}
      </Loading>
    </Grid>
  );
};
