import React from 'react';
import shallow from 'zustand/shallow';
import { usePhonebookStore } from './store';
import {
  PhonebookBackdrop,
  PhonebookScrollContainer,
  PhonebookTablePagination,
} from './Phonebook.styled';
import PhonebookRow from './PhonebookRow';
import { CircularProgress } from '@mui/material';

const PhonebookDataTable = () => {
  const { isLoading, phonebookData, currentPage, pageSize, setCurrentPage, setPageSize, count } =
    usePhonebookStore(
      (state) => ({
        isLoading: state.isLoading,
        phonebookData: state.phonebookData,
        currentPage: state.currentPage,
        pageSize: state.pageSize,
        setCurrentPage: state.setCurrentPage,
        setPageSize: state.setPageSize,
        count: state.count,
      }),
      shallow
    );
  return (
    <div style={{ width: '100%', position: 'relative' }}>
      <PhonebookBackdrop open={isLoading}>
        <CircularProgress />
      </PhonebookBackdrop>
      <PhonebookScrollContainer>
        {phonebookData?.map((item) => (
          <PhonebookRow
            key={item.id}
            {...item}
          />
        ))}
      </PhonebookScrollContainer>

      {!!phonebookData.length && !!count && (
        <PhonebookTablePagination
          count={count}
          rowsPerPage={pageSize}
          onRowsPerPageChange={(newPageSize) => {
            setPageSize(Number(newPageSize.target.value));
          }}
          page={currentPage}
          onPageChange={(ev, newPage) => {
            setCurrentPage(newPage);
          }}
          labelRowsPerPage={null}
          rowsPerPageOptions={[5, 10, 20, 50, 100]}
          SelectProps={{
            MenuProps: {
              sx: {
                zIndex: 2000, // Поднимаем `Popover`
              },
            },
          }}
        />
      )}
    </div>
  );
};

export default PhonebookDataTable;
