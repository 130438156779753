import { Button, Tooltip } from '@mui/material';
import React, { useState } from 'react';
import {
  PnrOrderAutocompleteValue,
  PnrOrderCommonModal,
  PnrOrderFormRow,
  PnrOrderTitle,
  PnrOsOrderModalButtonWrapper,
} from '../PnrOrderTab.styled';
import { NetworkElementRow } from './PnrOsOrder';
import { PnrPlanningModalDateWrapper } from '@/components/blocks/PnrPlanningModalSteps/PnrPlanningModalSteps.styled';
import {
  MULTIPLE_PLANNING_ORDER_ITEM,
  PLANNING_RADIO_ITEMS,
  SINGLE_PLANNING_RADIO_ITEM,
} from '@/components/blocks/PnrPlanningModalSteps/util';
import { FilterArgEnum } from '@/components/filter/MultySelectFilter';
import { DateRange } from '@/components/inputs/DateRange';
import { useCatalog } from '@/hooks/CatalogHook';
import { RadioGroupForm } from '@/components/RadioGroupForm';
import { FormProvider, useForm } from 'react-hook-form';
import PnrOsOrderRows from './PnrOsOrderRows';
import { fetchCatch, syncErrorCatch } from '@/hooks/ActionLogHook';
import { ApiCreateHouseOsOrders } from '@/services/HouseDataService/HouseDataService';
import { useParams } from 'react-router-dom';
import { CreateHouseOrderOsDto } from '@/services/HouseDataService/dto/HouseDataServiceRequestDto';
import moment from 'moment';
import { CreateHouseOrderResponseDto } from '@/dto/taskmap/Dto';
import { LoadingButton } from '@mui/lab';
import TaskCreationResult from '../../ui/TaskCreationResult/TaskCreationResult';

export interface PnrOsOrderModalProps {
  selectedRowsData: NetworkElementRow[];
}

export interface PnrOrderBaseForm {
  contractId: string;
  contractorId: string;
  priority: string;
}

const PnrOsOrderModal = ({ selectedRowsData }: PnrOsOrderModalProps) => {
  const { houseDtoId } = useParams();
  const { getCatalogMapWithErr, getContractCatalog, getGroupCatalog } = useCatalog((state) => ({
    getCatalogMapWithErr: state.getCatalogMapWithErr,
    getContractCatalog: state.getContractCatalog,
    getGroupCatalog: state.getGroupCatalog,
  }));
  const contractorIds = getGroupCatalog();
  const contractIdsMap = getContractCatalog('convertIntoMap');

  const methods = useForm<PnrOrderBaseForm>();
  const { handleSubmit, reset } = methods;

  const [isOpen, setIsOpen] = useState(false);
  const [plannedDate, setPlannedDate] = useState<Date | null>(null);
  const [valueRadio, setValueRadio] = useState('');
  const [userEditedElements, setUserEditedElements] = useState<NetworkElementRow[]>([]);
  const [isLoading, setIsLoading] = useState(false);
  const [createdOrdersResponse, setCreatedOrdersResponse] =
    useState<CreateHouseOrderResponseDto | null>(null);

  const onHandlePlannedDate = (newValue: Date[] | null) => {
    if (newValue && newValue[0]) {
      setPlannedDate(newValue[0]);
    }
  };

  const handleRadioChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setValueRadio(event.target.value);
  };

  const closeModal = () => {
    reset();
    setPlannedDate(null);
    setIsOpen(false);
    setValueRadio('');
    setCreatedOrdersResponse(null);
  };

  const onSubmit = (data: PnrOrderBaseForm) => {
    if (!houseDtoId) return;
    if (!userEditedElements.length) {
      syncErrorCatch('Отсутствуют элементы для создания наряда');
      return;
    }
    if (!data.contractId || !data.contractorId || !data.priority || !plannedDate || !valueRadio) {
      syncErrorCatch('Необходимо заполнить все поля');
      return;
    }
    const isSeparate = valueRadio === MULTIPLE_PLANNING_ORDER_ITEM.value;

    const orderData: CreateHouseOrderOsDto = {
      contractId: data.contractId,
      contractorId: data.contractorId,
      plannedDate: moment(plannedDate).format('YYYY-MM-DD'),
      taskPriority: data.priority,
      taskType: 'OS_ORDER',
      isSeparate,
      orderType: 'CLAIM',
      equipmentList: userEditedElements.map((el) => ({
        typeId: el.typeId,
        houseLevel: el.houseLevel,
        mac: el.smrMac,
        imei: el.smrImei,
        comment: el.comment || null,
        networkElementId: el.id,
      })),
    };

    setIsLoading(true);
    ApiCreateHouseOsOrders(houseDtoId, orderData)
      .then(({ data }) => setCreatedOrdersResponse(data))
      .catch(fetchCatch)
      .finally(() => setIsLoading(false));
  };

  const handleOpenModal = () => {
    setIsOpen(true);
    setUserEditedElements(structuredClone(selectedRowsData));
  };

  return (
    <>
      <Tooltip
        title={!selectedRowsData.length ? 'Выберите оборудование для создания наряда' : ''}
        placement='top'
      >
        <PnrOsOrderModalButtonWrapper>
          <Button
            variant='outlined'
            disabled={!selectedRowsData.length}
            onClick={handleOpenModal}
          >
            Создать наряд на ОС
          </Button>
        </PnrOsOrderModalButtonWrapper>
      </Tooltip>
      <PnrOrderCommonModal
        open={isOpen}
        onClose={closeModal}
      >
        {createdOrdersResponse ? (
          <TaskCreationResult
            closeCallback={closeModal}
            tasksCreationResults={createdOrdersResponse}
            title='В результате успешно созданы заявки:'
          />
        ) : (
          <>
            <PnrOrderTitle>Выберете необходимые параметры для создания наряда</PnrOrderTitle>

            <FormProvider {...methods}>
              <PnrOrderFormRow>
                <PnrOrderAutocompleteValue
                  title='Контракт'
                  fieldName={'contractId'}
                  values={contractIdsMap}
                />

                <PnrOrderAutocompleteValue
                  title='Ответственный подрядчик'
                  fieldName={'contractorId'}
                  values={contractorIds}
                />

                <PnrOrderAutocompleteValue
                  title='Приоритет'
                  fieldName={'priority'}
                  values={getCatalogMapWithErr('taskPriority')}
                />

                <PnrPlanningModalDateWrapper>
                  <DateRange
                    label={'Плановая дата'}
                    onChange={onHandlePlannedDate}
                    value={[plannedDate]}
                    argValue={FilterArgEnum.EQUAL}
                    showSelect={false}
                    showCalenderIcon={true}
                    showClearButton={false}
                    showPalceHolder={false}
                  />
                </PnrPlanningModalDateWrapper>
              </PnrOrderFormRow>

              <div style={{ display: 'flex', marginTop: '20px' }}>
                <RadioGroupForm
                  value={valueRadio}
                  onChange={handleRadioChange}
                  items={PLANNING_RADIO_ITEMS}
                  labelPlacement='end'
                  columnDirection
                />
              </div>

              <PnrOsOrderRows
                userEditedElements={userEditedElements}
                setUserEditedElements={setUserEditedElements}
              />

              <PnrOrderFormRow>
                <LoadingButton
                  loading={isLoading}
                  variant='outlined'
                  onClick={handleSubmit(onSubmit)}
                >
                  Создать наряд на ОС
                </LoadingButton>
                <Button
                  variant='outlined'
                  onClick={closeModal}
                >
                  Отмена
                </Button>
              </PnrOrderFormRow>
            </FormProvider>
          </>
        )}
      </PnrOrderCommonModal>
    </>
  );
};

export default PnrOsOrderModal;
