import {
  IncomingCallDateResponseDto,
  IncommingCallClaimResponseDto,
} from '@/services/CallingMapService/dto/CallingMapServiceResponseDto';
import { createStore } from '@/utils/createStore';
import { FilterConfig } from '../../types/types';

interface IncomingClaimData {
  incomingClaimCount: number | 'pending' | null;
  incomingClaimRows: IncommingCallClaimResponseDto[];
  incomingClaimRowsLoading: boolean;
  totalRows: number | 'pending';
  pageSize: number;
  currentPage: number;
  filterIncommingTasks: FilterConfig;
  selectedDate: Date | null;
  selectedMonth: Date;
  availableDates: IncomingCallDateResponseDto[];
  isLoadingAvailableDates: boolean;
}

interface IncomingClaimFunctions {
  setIncomingClaimCount: (incomingClaimCount: number | 'pending' | null) => void;
  setPageSize: (pageSize: number) => void;
  setCurrentPage: (currentPage: number) => void;
  setIncomingClaimRows: (incomingClaimRows: IncommingCallClaimResponseDto[]) => void;
  setTotalRows: (totalRows: number | 'pending') => void;
  setIncomingClaimRowsLoading: (incomingClaimRowsLoading: boolean) => void;
  setFilterIncommingTasks: (incomingClaimRowsLoading: FilterConfig) => void;
  setSelectedDate: (selectedDate: Date | null) => void;
  setSelectedMonth: (selectedMonth: Date) => void;
  setAvailableDates: (availableDates: IncomingCallDateResponseDto[]) => void;
  setIsLoadingAvailableDates: (isLoadingAvailableDates: boolean) => void;
}

type IncomingClaimStore = IncomingClaimData & IncomingClaimFunctions;

export const useIncomingClaim = createStore<IncomingClaimStore>(
  (set) => ({
    incomingClaimCount: 0,
    incomingClaimRows: [],
    totalRows: 0,
    incomingClaimRowsLoading: false,
    pageSize: 25,
    currentPage: 0,
    filterIncommingTasks: {
      filterItemList: [],
    },
    selectedDate: null,
    selectedMonth: new Date(),
    availableDates: [],
    isLoadingAvailableDates: false,

    setPageSize: (pageSize) => set({ pageSize }),
    setCurrentPage: (currentPage) => set({ currentPage }),

    setIncomingClaimCount: (incomingClaimCount) => set({ incomingClaimCount }),
    setIncomingClaimRows: (incomingClaimRows) => set({ incomingClaimRows }),
    setTotalRows: (totalRows) => set({ totalRows }),
    setIncomingClaimRowsLoading: (incomingClaimRowsLoading) => set({ incomingClaimRowsLoading }),
    setFilterIncommingTasks: (filterIncommingTasks) => set({ filterIncommingTasks }),
    setSelectedDate: (selectedDate) => set({ selectedDate }),
    setSelectedMonth: (selectedMonth) => set({ selectedMonth }),
    setAvailableDates: (availableDates) => set({ availableDates }),
    setIsLoadingAvailableDates: (isLoadingAvailableDates) => set({ isLoadingAvailableDates }),
  }),
  'useIncomingClaim'
);
