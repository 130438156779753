import { Grid, Typography } from '@mui/material';
import { FC, useState } from 'react';
import BorderColorRoundedIcon from '@mui/icons-material/BorderColorRounded';
import DeleteRoundedIcon from '@mui/icons-material/DeleteRounded';
import { AccessPopup } from '@/components/popups/AccessPopup/AccessPopup';
import { SubscriberForm } from '@/components/SubscriberForm';
import { FormProvider, useForm } from 'react-hook-form';
import { deleteSub, editSub } from '@/services/SubscribersService/SubscribersService';
import { useActionLog } from '@/hooks/ActionLogHook';
import { formatPhoneNumberMask } from '@/utils/heplers';
import { SubscriberParam } from '@/services/SubscribersService/dto/SubscribersServiceRequestDto';
import { SubscriberResponse } from '@/services/SubscribersService/dto/SubscribersServiceResponseDto';

export const SubscriberItem: FC<
  SubscriberResponse & {
    setSubList: (list: SubscriberResponse[]) => void;
    subList: SubscriberResponse[];
  }
> = ({ id, fio, contactPhone, taskId, setSubList, subList }) => {
  const [isOpen, setIsOpen] = useState(false);
  const [tmpFio, setTmpFio] = useState<string>(fio);
  const [tmpContactPhone, setTmpContactPhone] = useState<string>(contactPhone);
  const [isOpenDel, setIsOpenDel] = useState(false);
  const { fetchCatch } = useActionLog();
  const methods = useForm<SubscriberParam & { id: string | number }>({
    mode: 'onChange',
    defaultValues: {
      fio: tmpFio,
      contactPhone: tmpContactPhone,
    },
  });
  const onEdit = (data: SubscriberParam) => {
    editSub(id, data)
      .then(({ data }) => {
        setTmpFio(data.fio);
        setTmpContactPhone(data.contactPhone);
      })
      .catch((err) => fetchCatch(err, 'Ошибка редактирования абонента'));
  };
  const onDelete = () => {
    setIsOpenDel(false);
    deleteSub(id)
      .then(({ data }) => {
        setSubList(subList.filter((elem) => elem.id !== id));
      })
      .catch((err) => fetchCatch(err, 'Ошибка удаления абонента'));
  };

  methods.setValue('id', id);
  methods.setValue('taskId', taskId);

  const isDisabled = () =>
    methods?.formState?.errors?.contactPhone?.type === 'required' ||
    methods?.formState?.errors?.fio?.type === 'required' ||
    methods?.formState?.errors?.fio?.type === 'pattern' ||
    methods?.formState?.errors?.contactPhone?.type === 'pattern';

  return (
    <div
      key={id}
      style={{ marginBottom: '8px' }}
    >
      <FormProvider {...methods}>
        <Grid
          container
          spacing={1}
        >
          <Grid
            item
            xs={12}
          >
            <Typography>{tmpFio}</Typography>
          </Grid>
          <Grid
            item
            xs={8}
          >
            <Typography>{formatPhoneNumberMask(tmpContactPhone)}</Typography>
          </Grid>
          <Grid
            item
            xs={2}
          >
            <DeleteRoundedIcon
              onClick={() => setIsOpenDel(true)}
              sx={{ color: 'gray', fontSize: 22, cursor: 'pointer' }}
            />
          </Grid>
          <Grid
            item
            xs={2}
          >
            <BorderColorRoundedIcon
              onClick={() => setIsOpen(true)}
              sx={{ color: 'gray', fontSize: 22, cursor: 'pointer' }}
            />
          </Grid>
        </Grid>
        <AccessPopup
          isOpen={isOpenDel}
          onClose={() => setIsOpenDel(false)}
          onSuccess={onDelete}
          headerText={'Удалить абонента?'}
          successText={'Да'}
        />
        <AccessPopup
          headerText={'Редактировать абонента'}
          disabledSuccess={isDisabled()}
          isOpen={isOpen}
          onClose={() => setIsOpen(false)}
          onSuccess={() => {
            methods.handleSubmit(onEdit)();
            setIsOpen(false);
          }}
        >
          <SubscriberForm />
        </AccessPopup>
      </FormProvider>
    </div>
  );
};
