import { BoxShadow } from '@/components/ui/BoxShadow';
import { shake } from '@/components/features/pnr/HouseEntranceHeader/HouseEntranceHeader.styled';
import { color, mediaBreakpointDown } from '@/styles/mixins';
import { IconButton, styled } from '@mui/material';
import { DateRange } from '@/components/inputs/DateRange';

interface ComplaintActivationButtonProps {
  open?: boolean;
}
interface ComplaintActivationWrapperProps {
  isMobile: boolean;
}

export const ComplaintActivationButton = styled('div')<ComplaintActivationButtonProps>(
  ({ open }) => ({
    '&:hover': { svg: { animation: `${shake} 1s 1 both` } },
    color: open ? color('muiBlue') : 'black',
  })
);

export const ComplaintActivationWrapper = styled(BoxShadow)<ComplaintActivationWrapperProps>(
  ({ isMobile }) => ({
    transition: 'transform 0.3s ease-out, padding 0.3s, max-height 0.3s ease-out',
    padding: 8,
    width: isMobile ? 325 : 500,
    transformOrigin: isMobile ? 'left' : 'right',
    overflow: 'visible',
    backgroundColor: '#F5F9FF',
    maxHeight: 450,
  })
);
export const DateRangeWrapper = styled('div')({
  '& .parent': {
    padding: '0 !important',
  },
});
