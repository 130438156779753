import { CalendarPicker, LocalizationProvider, PickersDay } from '@mui/x-date-pickers';
import { Badge, CircularProgress } from '@mui/material';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import { parse, isSameDay, format } from 'date-fns';
import { ru } from 'date-fns/locale';
import React, { useEffect, useState } from 'react';
import {
  CallingMapIncomingTasksBackdrop,
  CallingMapIncomingTasksWrapper,
} from './CallingMapIncomingTasks.styled';
import {
  getIncomingCallDates,
  getIncomingCallRequestsPerDay,
} from '@/services/CallingMapService/CallingMapService';
import { getMonthAndYear } from './utils';
import { fetchCatch } from '@/hooks/ActionLogHook';
import shallow from 'zustand/shallow';
import { useIncomingClaim } from '../CallingMapIncomingTasksTab/store';
import CallingMapIncomingClaimTable from './CallingMapIncomingClaimTable';
import { useHackedDataGridPro } from '@/hooks/useHackedDataGridPro';

const CallingMapIncomingTasks = () => {
  useHackedDataGridPro();
  const {
    pageSize,
    currentPage,
    filterIncommingTasks,
    selectedDate,
    selectedMonth,
    availableDates,
    isLoadingAvailableDates,
    setIncomingClaimRows,
    setIncomingClaimRowsLoading,
    setTotalRows,
    setCurrentPage,
    setSelectedDate,
    setSelectedMonth,
    setAvailableDates,
    setIsLoadingAvailableDates,
  } = useIncomingClaim(
    (state) => ({
      pageSize: state.pageSize,
      currentPage: state.currentPage,
      filterIncommingTasks: state.filterIncommingTasks,
      selectedDate: state.selectedDate,
      selectedMonth: state.selectedMonth,
      availableDates: state.availableDates,
      isLoadingAvailableDates: state.isLoadingAvailableDates,
      setIncomingClaimRows: state.setIncomingClaimRows,
      setIncomingClaimRowsLoading: state.setIncomingClaimRowsLoading,
      setTotalRows: state.setTotalRows,
      setCurrentPage: state.setCurrentPage,
      setSelectedDate: state.setSelectedDate,
      setSelectedMonth: state.setSelectedMonth,
      setAvailableDates: state.setAvailableDates,
      setIsLoadingAvailableDates: state.setIsLoadingAvailableDates,
    }),
    shallow
  );

  const fetchAvailableDates = (month: number, year: number) => {
    setIsLoadingAvailableDates(true);

    getIncomingCallDates(month, year, filterIncommingTasks)
      .then(({ data }) => setAvailableDates(data))
      .catch(fetchCatch)
      .finally(() => setIsLoadingAvailableDates(false));
  };

  const handleMonthChange = (date: Date) => {
    setSelectedDate(null);
    setSelectedMonth(date);
  };

  useEffect(() => {
    const { month, year } = getMonthAndYear(selectedMonth);

    fetchAvailableDates(month, year);
  }, [selectedMonth]);

  useEffect(() => {
    if (!selectedDate) return;
    const formattedDate = format(selectedDate, 'yyyy-MM-dd');
    setIncomingClaimRowsLoading(true);

    getIncomingCallRequestsPerDay(
      pageSize * currentPage,
      pageSize,
      filterIncommingTasks,
      formattedDate
    )
      .then(({ data }) => setIncomingClaimRows(data))
      .catch(fetchCatch)
      .finally(() => setIncomingClaimRowsLoading(false));
  }, [selectedDate, pageSize, currentPage]);

  useEffect(() => {
    if (!selectedDate || !availableDates) return;

    setCurrentPage(0);

    const selectedDateStr = format(selectedDate, 'yyyy-MM-dd');
    const matchedDate = availableDates.find((availableDate) => {
      return availableDate.date === selectedDateStr;
    });
    if (!matchedDate) return;
    setTotalRows(matchedDate.count);
  }, [selectedDate, availableDates]);

  const isDateDisabled = (date: Date) => {
    return !availableDates.some((availableDate) => {
      return isSameDay(date, parse(availableDate.date, 'yyyy-MM-dd', new Date()));
    });
  };

  //  Рендеринг ячейки с Badge
  const renderDay = (day: Date, _selectedDays: Date[], pickersDayProps: any) => {
    const matchedDate = availableDates.find((availableDate) =>
      isSameDay(day, parse(availableDate.date, 'yyyy-MM-dd', new Date()))
    );

    return (
      <Badge
        key={day.toString()}
        badgeContent={
          matchedDate ? <span style={{ pointerEvents: 'none' }}>{matchedDate.count}</span> : null
        }
        color='error'
        overlap='circular'
      >
        <PickersDay {...pickersDayProps} />
      </Badge>
    );
  };

  return (
    <CallingMapIncomingTasksWrapper>
      <LocalizationProvider
        dateAdapter={AdapterDateFns}
        adapterLocale={ru}
      >
        <div style={{ position: 'relative' }}>
          <CalendarPicker
            date={selectedDate}
            onChange={(date) => setSelectedDate(date)}
            shouldDisableDate={isDateDisabled}
            onMonthChange={handleMonthChange}
            renderDay={renderDay} // Передаем кастомный рендеринг дней
            views={['day']}
          />
          <CallingMapIncomingTasksBackdrop open={isLoadingAvailableDates}>
            <CircularProgress />
          </CallingMapIncomingTasksBackdrop>
        </div>
      </LocalizationProvider>
      <CallingMapIncomingClaimTable />
    </CallingMapIncomingTasksWrapper>
  );
};

export default CallingMapIncomingTasks;
