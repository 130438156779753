import React, { FC, useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import { Button, Grid, Typography } from '@mui/material';
import { TextField } from '@/components/fixUI';
import { useFormContext } from 'react-hook-form';
import DateField from '@/components/DateField';
import {
  deletePUItem,
  editPUItem,
  getPUItem,
  getTaskNubmerForPU,
} from '@/services/CatalogService/CatalogsService';
import { ActionLogType, useActionLog } from '@/hooks/ActionLogHook';
import { Dialog, DialogActions, DialogTitle } from '@material-ui/core';
import { AutocompleteValue } from '@/components/inputs/AutocompleteValue/AutocompleteValue';
import { useCatalog } from '@/hooks/CatalogHook';
import { color } from '@/styles/mixins';
import { LoadingButton } from '@mui/lab';
import { useQuery } from 'react-query';
import {
  CatalogEditorButtonsLoading,
  CatalogEditorTitleLoading,
} from '../EquipmentEditor/CatalogEquipmentEditor.styled';
import { Loading } from '@/components/ui/Loading';

const getIsInstalledOpt = (): Map<string, string> => {
  const m = new Map();
  m.set('true', 'Да');
  m.set('false', 'Нет');
  return m;
};

interface ICatalogPuEditor {
  puId: string;
}

const CatalogPuEditor: FC<ICatalogPuEditor> = ({ puId }) => {
  const { getCatalogMapWithErr } = useCatalog((state) => ({
    getCatalogMapWithErr: state.getCatalogMapWithErr,
  }));
  const { fetchCatch, addActionLog } = useActionLog();
  const optMap = getIsInstalledOpt();
  const methods = useFormContext();
  const { register, handleSubmit, watch, setValue } = methods;

  const [removeDialogOpen, setRemoveDialogOpen] = useState<boolean>(false);
  const handleRemoveDialogClose = () => setRemoveDialogOpen(false);
  const handleRemoveDialogOpen = () => setRemoveDialogOpen(true);
  const [loadingDelete, setLoadingDelete] = useState(false);
  const handleDelete = () => {
    handleRemoveDialogClose();
    setLoadingDelete(true);
    deletePUItem(puId)
      .then((res) => {
        addActionLog(ActionLogType.SUCCESS, 'ПУ удален');
        setTimeout(() => window.open(`/catalogs/pu`, '_self'), 1000);
      })
      .catch((err) => {
        fetchCatch(err, 'Ошибка удаления ПУ');
      })
      .finally(() => setLoadingDelete(false));
  };
  const [loadingSave, setLoadingSave] = useState(false);
  const handleSave = handleSubmit((data) => {
    setLoadingSave(true);
    editPUItem(puId, { ...data[puId] })
      .then((res) => {
        addActionLog(ActionLogType.SUCCESS, 'Изменения ПУ сохранены');
      })
      .catch((err) => {
        fetchCatch(err, 'Ошибка сохранения ПУ');
      })
      .finally(() => setLoadingSave(false));
  });

  const { data: dataTaskNumber, isLoading: loadingTaskNumber } = useQuery({
    queryKey: ['taskNumber'],
    refetchOnWindowFocus: false,
    enabled: !!puId,
    queryFn: () => getTaskNubmerForPU(puId),
  });

  const { data, isLoading: loading } = useQuery({
    queryKey: ['equipment'],
    refetchOnWindowFocus: false,
    enabled: !!puId,
    queryFn: () => getPUItem(puId),
    onError: (error) => fetchCatch(error, 'Ошибка загрузки прибора учета'),
  });

  const defaultData = data?.data;
  const disableDeletePuButton = !dataTaskNumber?.data.taskNumber && !dataTaskNumber?.data.taskId;

  useEffect(() => {
    if (defaultData) {
      setValue(`${puId}.number`, defaultData.number);
      setValue(`${puId}.mac`, defaultData.mac);
      setValue(`${puId}.imei`, defaultData.imei);
      setValue(`${puId}.releaseDate`, defaultData.releaseDate);
      setValue(`${puId}.verificationDate`, defaultData.verificationDate);
      setValue(`${puId}.nextVerificationDate`, defaultData.nextVerificationDate);
      setValue(`${puId}.verificationInterval`, defaultData.verificationInterval);
      setValue(`${puId}.accClass`, defaultData.accClass);
      setValue(`${puId}.networkAddress`, defaultData.networkAddress);
      setValue(`${puId}.ship`, defaultData.ship);
      setValue(`${puId}.pnr`, defaultData.pnr);
      setValue(`${puId}.ivkIsUploadedUp`, defaultData.ivkIsUploadedUp);
      setValue(`${puId}.ivkLastMeasureDate`, defaultData.ivkLastMeasureDate);
      setValue(`${puId}.ivkLastMeasure`, defaultData.ivkLastMeasure);
      setValue(`${puId}.terminal`, defaultData.terminal);
      setValue(`${puId}.tariffTask`, defaultData.tariffTask);
      setValue(`${puId}.tariffChangeDate`, defaultData.tariffChangeDate);
    }
  }, [defaultData, puId]);

  return (
    <>
      <Grid
        container
        spacing={3}
      >
        <Grid
          item
          xs={12}
        >
          <CatalogEditorTitleLoading loading={loadingTaskNumber}>
            <Typography variant={'h5'}>
              {dataTaskNumber?.data.taskNumber ? (
                <>
                  {`Пу привязан к заявке №`}
                  <Link
                    to={`/task/edit/${dataTaskNumber?.data.taskId}`}
                    style={{ textDecoration: 'none' }}
                  >
                    <span
                      style={{ color: color('muiBlue') }}
                    >{`${dataTaskNumber.data.taskNumber}`}</span>
                  </Link>
                </>
              ) : (
                'ПУ не привязан к заявке'
              )}
            </Typography>
          </CatalogEditorTitleLoading>
        </Grid>
        <Grid
          item
          xs={12 / 5}
        >
          <Loading loading={loading}>
            <AutocompleteValue
              title='Тип ПУ'
              defaultValue={defaultData?.meterType ? `${defaultData.meterType}` : undefined}
              fieldName={`${puId}.meterType`}
              values={getCatalogMapWithErr('meterType')}
              disabled={loading}
            />
          </Loading>
          <Loading loading={loading}>
            <TextField
              label='№ ПУ'
              variant='standard'
              sx={{ width: '100%' }}
              {...register(`${puId}.number`, { value: defaultData?.number })}
              disabled={loading}
              InputLabelProps={{ shrink: !!defaultData?.number }}
            />
          </Loading>
          <Loading loading={loading}>
            <TextField
              label='МАС-адрес'
              variant='standard'
              sx={{ width: '100%' }}
              {...register(`${puId}.mac`, { value: defaultData?.mac })}
              disabled={loading}
              InputLabelProps={{ shrink: !!defaultData?.mac }}
            />
          </Loading>
          <Loading loading={loading}>
            <TextField
              label='IMEI'
              disabled={defaultData?.imei != undefined || loading}
              variant='standard'
              sx={{ width: '100%' }}
              {...register(`${puId}.imei`, { value: defaultData?.imei })}
              InputLabelProps={{ shrink: !!defaultData?.imei }}
            />
          </Loading>
        </Grid>
        <Grid
          item
          xs={12 / 5}
        >
          <Loading loading={loading}>
            <DateField
              defaultValue={defaultData?.releaseDate}
              hookFormString={`${puId}.releaseDate`}
              label={'Дата выпуска'}
              disabled={loading}
            />
          </Loading>
          <Loading loading={loading}>
            <DateField
              defaultValue={defaultData?.verificationDate}
              hookFormString={`${puId}.verificationDate`}
              label={'Дата поверки'}
              disabled={loading}
            />
          </Loading>
          <Loading loading={loading}>
            <DateField
              defaultValue={defaultData?.nextVerificationDate}
              hookFormString={`${puId}.nextVerificationDate`}
              label={'Дата следующей поверки'}
              disabled={loading}
            />
          </Loading>
          <Loading loading={loading}>
            <TextField
              disabled
              label='Межповерочный интервал'
              variant='standard'
              sx={{ width: '100%' }}
              {...register(`${puId}.verificationInterval`, {
                value: defaultData?.verificationInterval,
              })}
              InputLabelProps={{ shrink: !!defaultData?.verificationInterval }}
            />
          </Loading>
        </Grid>
        <Grid
          item
          xs={12 / 5}
        >
          <Loading loading={loading}>
            <TextField
              label='Класс точности'
              variant='standard'
              sx={{ width: '100%' }}
              {...register(`${puId}.accClass`, { value: defaultData?.accClass })}
              disabled={loading}
              InputLabelProps={{ shrink: !!defaultData?.accClass }}
            />
          </Loading>
          <Loading loading={loading}>
            <TextField
              label='Сетевой адрес'
              variant='standard'
              sx={{ width: '100%' }}
              {...register(`${puId}.networkAddress`, { value: defaultData?.networkAddress })}
              disabled={loading}
            />
          </Loading>
          <Loading loading={loading}>
            <TextField
              label='Поставка SHIP'
              variant='standard'
              sx={{ width: '100%' }}
              {...register(`${puId}.ship`, { value: defaultData?.ship })}
              disabled={loading}
              InputLabelProps={{ shrink: !!defaultData?.ship }}
            />
          </Loading>
          <Loading loading={loading}>
            <TextField
              label='Статус ПНР (Бот)'
              variant='standard'
              sx={{ width: '100%' }}
              {...register(`${puId}.pnr`, { value: defaultData?.pnr })}
              disabled={loading}
              InputLabelProps={{ shrink: !!defaultData?.pnr }}
            />
          </Loading>
        </Grid>
        <Grid
          item
          xs={12 / 5}
        >
          <Loading loading={loading}>
            <AutocompleteValue
              title='Наличие в ИВК'
              defaultValue={
                defaultData?.ivkIsUploadedUp != undefined
                  ? `${defaultData.ivkIsUploadedUp}`
                  : undefined
              }
              fieldName={`${puId}.ivkIsUploadedUp`}
              values={optMap}
              disabled={loading}
            />
          </Loading>
          <Loading loading={loading}>
            <DateField
              defaultValue={defaultData?.ivkLastMeasureDate}
              hookFormString={`${puId}.ivkLastMeasureDate`}
              label={'Дата последних показаний из ИВК'}
              disabled={loading}
            />
          </Loading>
          <Loading loading={loading}>
            <TextField
              label='Последние показания из ИВК'
              variant='standard'
              sx={{ width: '100%' }}
              {...register(`${puId}.ivkLastMeasure`, { value: defaultData?.ivkLastMeasure })}
              disabled={loading}
              InputLabelProps={{ shrink: !!defaultData?.ivkLastMeasure }}
            />
          </Loading>
          <Loading loading={loading}>
            <TextField
              label='Шлюз последнего опроса'
              variant='standard'
              sx={{ width: '100%' }}
              {...register(`${puId}.terminal`, { value: defaultData?.terminal })}
              disabled={loading}
              InputLabelProps={{ shrink: !!defaultData?.terminal }}
            />
          </Loading>
        </Grid>
        <Grid
          item
          xs={12 / 5}
        >
          <Loading loading={loading}>
            <AutocompleteValue
              title='Задача на установку тарифа'
              defaultValue={
                defaultData?.tariffTask != undefined ? `${defaultData.tariffTask}` : undefined
              }
              fieldName={`${puId}.tariffTask`}
              values={optMap}
              disabled={loading}
            />
          </Loading>
          <Loading loading={loading}>
            <DateField
              defaultValue={defaultData?.tariffChangeDate}
              hookFormString={`${puId}.tariffChangeDate`}
              label={'Дата изменения тарифа'}
              disabled={loading}
            />
          </Loading>
        </Grid>
        <Grid
          item
          xs={12}
        >
          <CatalogEditorButtonsLoading loading={loading}>
            <>
              <LoadingButton
                onClick={handleRemoveDialogOpen}
                loading={loadingDelete}
                disabled={disableDeletePuButton || loading}
              >
                Удалить ПУ
              </LoadingButton>
              <LoadingButton
                onClick={handleSave}
                loading={loadingSave}
                disabled={loading}
              >
                Сохранить
              </LoadingButton>
            </>
          </CatalogEditorButtonsLoading>
        </Grid>
      </Grid>
      <Dialog
        open={removeDialogOpen}
        onClose={handleRemoveDialogClose}
      >
        <DialogTitle disableTypography>
          <Typography style={{ alignItems: 'center' }}>
            Вы действительно хотите удалить ПУ?
          </Typography>
        </DialogTitle>
        <DialogActions>
          <Button onClick={handleDelete}>Да, удалить</Button>
          <Button onClick={handleRemoveDialogClose}>Отмена</Button>
        </DialogActions>
      </Dialog>
    </>
  );
};

export default CatalogPuEditor;
