import { HOST, YodaRestClient } from '@/services/YodaAuthService';
import { ApiResponse } from '@/types/api';

export async function CreatePu(data: any): ApiResponse<any> {
  const url = HOST + `/rest/meter/`;
  return YodaRestClient.post(url, data);
}
export async function DuplicatePu(mcId: string, data: any): ApiResponse<any> {
  const url = HOST + `/rest/replacement/${mcId}/create-duplicate-meter`;
  return YodaRestClient.post(url, data);
}
