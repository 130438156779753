import { Button, Stack, Box } from '@mui/material';
import React, { useState } from 'react';
import { ComplaintReportContainer, ComplaintReportModal } from './ComplaintReportButton.styled';
import { LoadingButton } from '@mui/lab';
import { CheckBoxLabel } from '../ComplaintBrowseFilter/ComplaintBrowseFilter.styled';
import shallow from 'zustand/shallow';
import { useComplaintStore } from '../store';

const ComplaintReportButtonDownload = () => {
  const { includeActs, setBooleanValue, downloadComplaintsByFilter, isDownloadingReport } =
    useComplaintStore(
      (state) => ({
        includeActs: state.includeActs,
        setBooleanValue: state.setBooleanValue,
        downloadComplaintsByFilter: state.downloadComplaintsByFilter,
        isDownloadingReport: state.isDownloadingReport,
      }),
      shallow
    );
  const [isOpen, setIsOpen] = useState(false);

  const handleClose = () => {
    setIsOpen(false);
  };

  return (
    <>
      <Button onClick={() => setIsOpen(true)}>выгрузить жалобы</Button>
      <ComplaintReportModal
        onClose={handleClose}
        open={isOpen}
      >
        <ComplaintReportContainer>
          <Box>
            <Stack
              alignItems={'center'}
              gap={2}
            >
              <span>
                <CheckBoxLabel
                  value={includeActs}
                  onChange={setBooleanValue('includeActs')}
                  label={'включить акты в очет'}
                />
              </span>
              <LoadingButton
                disabled={isDownloadingReport}
                loading={isDownloadingReport}
                onClick={downloadComplaintsByFilter}
              >
                выгрузить жалобы
              </LoadingButton>
            </Stack>
          </Box>
        </ComplaintReportContainer>
      </ComplaintReportModal>
    </>
  );
};

export default ComplaintReportButtonDownload;
