import { createStore } from '@/utils/createStore';
import {
  getMapCount,
  getTaskWithoutCoordinatesCount,
} from '@/services/CallingMapService/CallingMapService';

interface CallingMapTabData {
  mapCount: number | 'pending' | null;
  taskWithoutCoordinatesCount: number | 'pending' | null;
}

interface CallingMapTabFunctions {
  updateMapCount: (filter: any) => void;
  updateTaskWithoutCoordinatesCount: (filter: any) => void;
}

type CallingMapTabStore = CallingMapTabData & CallingMapTabFunctions;

export const useCallingMapTabCount = createStore<CallingMapTabStore>(
  (set) => ({
    mapCount: null,
    taskWithoutCoordinatesCount: null,

    updateMapCount: (filter) => {
      set({ mapCount: 'pending' });
      getMapCount(filter).then(({ data }) => {
        set({ mapCount: data });
      });
    },
    updateTaskWithoutCoordinatesCount: (filter) => {
      set({ taskWithoutCoordinatesCount: 'pending' });
      getTaskWithoutCoordinatesCount(filter).then(({ data }) => {
        set({ taskWithoutCoordinatesCount: data });
      });
    },
  }),
  'useCallingMapTabCount'
);
