import React, { useEffect } from 'react';
import { TaskEditor } from '@/components/sections/TaskEditor/TaskEditor';
import { useAccess } from '@/hooks/useAccess';
import { AccessEnum } from '@/app/auth/roles';
import { useGalleryData } from '@/components/Gallery/store';
import { YodaRestClient } from '@/services/YodaAuthService';
import { findDuplicateList } from '@/components/Gallery/utils';

const TaskEditorPage = () => {
  const clearDuplicateFileList = useGalleryData((state) => state.clearDuplicateFileList);
  const addDuplicateFiles = useGalleryData((state) => state.addDuplicateFiles);

  const hasAccess = useAccess({
    access: [AccessEnum.TasksBrowse, AccessEnum.DeliveryPointScreen],
  });

  // Интерсептор для рекурсивного обхода респонза гет методов и сета дубликатов файлов
  useEffect(() => {
    const interceptor = YodaRestClient.interceptors.response.use((response) => {
      if (response.config.method === 'get') {
        const duplicateList = findDuplicateList(response.data);
        if (duplicateList.length > 0) {
          addDuplicateFiles(duplicateList);
        }
      }
      return response;
    });

    return () => {
      clearDuplicateFileList();
      YodaRestClient.interceptors.response.eject(interceptor);
    };
  }, []);

  return <>{hasAccess && <TaskEditor />}</>;
};
export default TaskEditorPage;
