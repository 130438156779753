import { Button, styled } from '@mui/material';
import { color, mediaBreakpointUp, font } from '@/styles/mixins';

export const NewGalleryWrapper = styled('div')({
  position: 'relative',
  display: 'flex',
  padding: 30,
  gap: 20,
  alignItems: 'flex-start',

  [mediaBreakpointUp('md')]: {
    gap: 100,
  },
});

export const NewGalleryStackWrapper = styled('div')({
  position: 'relative',
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'flex-start',
  width: '100%',
});

export const NewGalleryTop = styled('div')({
  position: 'relative',
  width: '100%',
  height: '70%',
});

export const NewGalleryBottom = styled('div')({
  marginTop: 32,
  display: 'flex',
  flexDirection: 'row',
  alignItems: 'center',
  position: 'relative',
  width: '100%',
  gap: 10,
});

export const NewGalleryLeft = styled('div')({
  position: 'relative',
  width: '50%',
  flexShrink: 0,
  paddingTop: 6,
});

export const NewGalleryRight = styled('div')({
  position: 'relative',
  display: 'flex',
  flexWrap: 'wrap',
  justifyContent: 'center',
  gap: 40,
  overflowY: 'auto',
  maxHeight: '75svh',
  scrollbarWidth: 'auto',
  zIndex: 1,
  width: '100%',
  scrollPaddingTop: 36,
});

export const NewGalleryHeader = styled('div')({
  display: 'flex',
  gap: 20,
  alignItems: 'center',
});

export const NewGalleryTitle = styled('span')({
  ...font('title2'),
});

export const NewGallerySubTitle = styled(NewGalleryTitle)({
  marginBottom: 16,
  fontSize: '16px',
});

export const NewGalleryResetButton = styled(Button)({
  color: color('blue'),
});

export const NewGalleryActiveImageHeader = styled('div')({
  display: 'flex',
  alignItems: 'center',
  gap: 10,
  justifyContent: 'flex-end',
});

export const NewGalleryPreviewImage = styled('img')({
  minHeight: 150,
  objectFit: 'cover',
  borderRadius: 20,
});

export const NewGalleryActiveImageHeaderWrapper = styled('div')({
  display: 'flex',
  justifyContent: 'space-between',
  alignItems: 'center',
  gap: 10,
});

interface NewGalleryPreviewItemProps {
  active: boolean;
}

export const NewGalleryPreviewItem = styled('div')<NewGalleryPreviewItemProps>(({ active }) => ({
  width: 100,
  height: 160,
  opacity: active ? 1 : 0.6,
  transition: '0.3s',
  cursor: 'pointer',
  overflow: 'hidden',
  borderRadius: 20,
  border: '2px solid transparent',
  borderColor: active ? color('muiBlue') : 'transparent',

  '&:hover': {
    opacity: 1,
  },

  [mediaBreakpointUp('md')]: {
    width: 200,
    height: 260,
  },
}));

export const NewGalleryRightWrapper = styled('div')({
  display: 'flex',
  flexDirection: 'column',
  gap: 10,
  alignItems: 'center',
  justifyContent: 'center',
  width: '100%',
});

export const NewPreviewWrapper = styled('div')({
  position: 'relative',
  display: 'flex',
  flexWrap: 'wrap',
  gap: 40,
  width: '100%',
});

export const NewPreviewMultiGroupWrapper = styled('div')({
  display: 'flex',
  flexDirection: 'column',
  width: '100%',
});

export const NewGalleryActiveImageRow = styled('div')({
  display: 'flex',
  alignItems: 'center',
  gap: 10,
});

interface NewGalleryActiveImageFixedControlsProps {
  top: number;
}

export const NewGalleryActiveImageFixedControls = styled(
  'div'
)<NewGalleryActiveImageFixedControlsProps>(({ top }) => ({
  position: 'fixed',
  left: 0,
  top: `${top}px`,
  transform: 'translate(-15px, 0)',
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  zIndex: 100,
  backgroundColor: 'white',
  border: `2px solid ${color('muiBlue')}`,

  gap: 10,
  justifyContent: 'center',
  padding: '15px 5px 15px 5px',
  borderTopRightRadius: 20,
  borderBottomRightRadius: 20,
  transition: '0.5s',

  '&:hover': {
    transform: 'translate(0px, 0)',
  },
}));
