/* eslint-disable */ //TODO: fix error  'tabType' is missing in props validation   react/prop-types precommit error
import React, { MutableRefObject, useEffect, useRef, useState } from 'react';
import { FixedSizeList, FixedSizeList as List } from 'react-window';
import AutoSizer from 'react-virtualized-auto-sizer';

import { SelectedOs } from './SupplyOSComponent';
import {
  osDupErrorMap,
  focusOnCell,
  OSExistErrorMap,
  useStorehouseWorkerAccess,
  FULL_COMPLETENESS_OPTION,
  FULL_PU_STATE_OPTION,
} from '../SupplyPUComponent/utils';
import {
  SupplyPUComponentTableHeader,
  SupplyPUComponentTableWrapper,
  SupplyPUReturnScannerWrapper,
  SupplyPUTableHeaderColumn,
} from '../SupplyPUComponent/SupplyPUComponent.styled';
import { SupplyNetworkElementItem } from '@/dto/taskmap/Dto';
import SupplyOsRow from './SupplyOsRow';
import { SupplyNetworkElementScanField } from '@/dto/SupplyBillServiceDto';
import { Button, TextField } from '@mui/material';
import { SupplyTabType } from '../StampComponent/StampComponent';
import { useActionLog } from '@/hooks/ActionLogHook';
import useSupplyStore from '../SupplyStore';
import { SupplyBillApiCheckTaskNumberList } from '@/services/SupplyBillService/SupplyBillService';
import {
  SaveOsItem,
  SavePuItem,
  SupplyReturnBillExtraColumnsProps,
} from '../SupplyReturnBillExtraColumns/SupplyReturnBillExtraColumns';
import { SupplyFilterButton } from '../SupplyFilterButton/SupplyFilterButton';
import { EquipmentTypes } from '../utils';
import shallow from 'zustand/shallow';

export interface SupplyOsModalProps {
  osListItems: SupplyNetworkElementItem[];
  disabledOrDraftCheck?: boolean;
  selectedOs?: SelectedOs;
  osDupFieldError: osDupErrorMap;
  osExistsFieldError: OSExistErrorMap;
  returnBill: boolean;
  relocationGetBill: boolean;
  billWithConfirm: boolean;
  accessProject?: boolean;
  loadingCheckExist: boolean;
}

export interface SupplyOsVirtualizedTableProps
  extends SupplyOsModalProps,
    Pick<SupplyReturnBillExtraColumnsProps, 'handleSave' | 'showMessage'>,
    SupplyTabType {
  removeScanRow: (rowId: number) => void;
  handleSave: (osItem: SaveOsItem | SavePuItem) => void;
  onEditStart: (record?: SupplyNetworkElementItem) => void;
  showMessage: (text: string, alert?: boolean) => void;
  confirmScanRow: (rowId: number) => void;
  confirmScanedNumber: (scanedNumber: string) => void;
  viewMode?: boolean;
  setOsListItems: (value: React.SetStateAction<SupplyNetworkElementItem[]>) => void;
}

const SupplyOsVirtualizedTable = ({
  osListItems,
  removeScanRow,
  handleSave,
  onEditStart,
  disabledOrDraftCheck,
  selectedOs,
  osDupFieldError,
  osExistsFieldError,
  returnBill,
  showMessage,
  confirmScanRow,
  confirmScanedNumber,
  viewMode,
  billWithConfirm,
  relocationGetBill,
  tabType,
  setOsListItems,
  accessProject,
  loadingCheckExist,
}: SupplyOsVirtualizedTableProps) => {
  const [itemSize, setItemSize] = useState(50);

  const workerAccess = useStorehouseWorkerAccess();
  const { checkedTaskNumbers, filters } = useSupplyStore(
    (state) => ({
      checkedTaskNumbers: state.checkedTaskNumbers,
      filters: state.filters,
    }),
    shallow
  );
  const { fetchCatch } = useActionLog();

  const [filteredData, setFilteredData] = useState(osListItems);

  useEffect(() => {
    if (!Object.keys(filters).length) {
      setFilteredData(osListItems);
      return;
    }
    const filteredData = [...osListItems].filter((row) => {
      return Object.keys(filters).every((key) => {
        if (!filters[key].length) return true;
        const filterValue = filters[key].map((item) => item.toString().toLowerCase());
        const rowItem = row[key as keyof SupplyNetworkElementItem];
        const value = typeof rowItem !== 'object' ? rowItem : rowItem?.id;
        const rowValue = rowItem && value ? value?.toString().toLowerCase() : '';

        return filterValue?.includes(rowValue);
      });
    });

    setFilteredData(filteredData);
  }, [filters, osListItems]);

  useEffect(() => {
    if (returnBill && !viewMode) {
      const taskNumbersToCheck: string[] = [];
      osListItems.forEach((pu) => {
        if (pu.taskNumber) {
          const stringifiedTaskNumber = pu.taskNumber.toString();
          if (
            !checkedTaskNumbers.has(stringifiedTaskNumber) ||
            checkedTaskNumbers.get(stringifiedTaskNumber) === 'checking'
          )
            taskNumbersToCheck.push(stringifiedTaskNumber);
        }
      });
      if (taskNumbersToCheck.length) {
        taskNumbersToCheck.forEach((number) => {
          checkedTaskNumbers.set(number, 'checking');
        });
        SupplyBillApiCheckTaskNumberList(taskNumbersToCheck)
          ?.then(({ data }) => {
            data?.forEach((task) => {
              const stringifiedTaskNumber = task?.number?.toString() || '-1';

              if (taskNumbersToCheck.includes(stringifiedTaskNumber)) {
                checkedTaskNumbers.set(stringifiedTaskNumber, 'success');
              } else {
                checkedTaskNumbers.set(stringifiedTaskNumber, 'error');
              }
            });
          })
          .catch((err) => fetchCatch(err));
      }
    }
  }, []);

  const listRef = useRef() as MutableRefObject<FixedSizeList>;
  const macImeiScan = selectedOs?.typeScanField === SupplyNetworkElementScanField.MAC_AND_IMEI;

  // Скролл и фокус на последней строке
  useEffect(() => {
    if (disabledOrDraftCheck) return;
    let innerTimeout: NodeJS.Timeout;

    const upperTimeout = setTimeout(() => {
      const lastEditableCellId = osListItems[osListItems?.length - 1]?.tmpId;
      listRef?.current?.scrollToItem(lastEditableCellId, 'center');
      innerTimeout = setTimeout(() => {
        focusOnCell({ cellRowId: lastEditableCellId });
      }, 10);
    }, 10);

    return () => {
      upperTimeout && clearTimeout(upperTimeout);
      innerTimeout && clearTimeout(innerTimeout);
    };
  }, [itemSize]);

  // Автоподстройка высоты строки
  useEffect(() => {
    if (selectedOs) {
      const height = (document.querySelector('.SupplyVirtualizedTable_Row') as HTMLElement)
        ?.offsetHeight;

      if (height) {
        setItemSize(height);
      }
    }
  }, [listRef?.current]);

  const handleScan = (e: React.KeyboardEvent<HTMLInputElement>) => {
    if (e.key === 'Enter' && (e.target as HTMLInputElement)?.value?.length && !loadingCheckExist) {
      confirmScanedNumber((e.target as HTMLInputElement).value);
      (e.target as HTMLInputElement).value = '';
    }
  };

  const addEmptyOs = () => onEditStart();

  const returnNewItemsMode = useSupplyStore((state) => state.returnNewItemsMode);

  return (
    <>
      {billWithConfirm && workerAccess && !viewMode && (
        <SupplyPUReturnScannerWrapper>
          <TextField
            name='mac/number'
            onKeyDown={handleScan}
            autoComplete='off'
            label='Введите номер'
            InputLabelProps={{ shrink: true }}
            autoFocus
          />

          {returnBill && (
            <Button
              disabled={loadingCheckExist}
              variant='contained'
              onClick={addEmptyOs}
            >
              Добавить СО без номера
            </Button>
          )}
        </SupplyPUReturnScannerWrapper>
      )}

      <SupplyPUComponentTableWrapper>
        <SupplyPUComponentTableHeader>
          <SupplyPUTableHeaderColumn
            withBorder
            narrow
            withoutFilter
          >
            №
          </SupplyPUTableHeaderColumn>
          <SupplyPUTableHeaderColumn withBorder>
            <SupplyFilterButton
              itemsList={osListItems}
              field={'typeName'}
            />
            <div>Тип прибора</div>
          </SupplyPUTableHeaderColumn>
          {returnBill && (
            <SupplyPUTableHeaderColumn withBorder>
              <SupplyFilterButton
                itemsList={osListItems}
                field={'fullName'}
              />
              <div>Полное наименование</div>
            </SupplyPUTableHeaderColumn>
          )}
          <SupplyPUTableHeaderColumn withBorder>
            <SupplyFilterButton
              itemsList={osListItems}
              field={'imei'}
            />
            <div>IMEI</div>
          </SupplyPUTableHeaderColumn>
          {macImeiScan && (
            <SupplyPUTableHeaderColumn withBorder>
              <SupplyFilterButton
                itemsList={osListItems}
                field={'mac'}
              />
              <div>MAC</div>
            </SupplyPUTableHeaderColumn>
          )}
          {returnBill && (
            <>
              <SupplyPUTableHeaderColumn withBorder>
                <SupplyFilterButton
                  itemsList={osListItems}
                  field={'manufactureYear'}
                />
                <div>Год выпуска</div>
              </SupplyPUTableHeaderColumn>
              <SupplyPUTableHeaderColumn withBorder>
                <SupplyFilterButton
                  itemsList={osListItems}
                  field={'completeness'}
                />
                <div>Комплектность</div>
              </SupplyPUTableHeaderColumn>
              <SupplyPUTableHeaderColumn withBorder>
                <SupplyFilterButton
                  itemsList={osListItems}
                  field={'completenessComment'}
                />
                <div>Комментарий при неполной комплектности</div>
              </SupplyPUTableHeaderColumn>
              <SupplyPUTableHeaderColumn withBorder>
                <SupplyFilterButton
                  itemsList={osListItems}
                  field={'ownerId'}
                />
                <div>Принадлежность</div>
              </SupplyPUTableHeaderColumn>
              <SupplyPUTableHeaderColumn withBorder>
                <SupplyFilterButton
                  itemsList={osListItems}
                  field={'state'}
                />
                <div>Состояние</div>
              </SupplyPUTableHeaderColumn>
              <SupplyPUTableHeaderColumn withBorder>
                <SupplyFilterButton
                  itemsList={osListItems}
                  field={'defectCause'}
                />
                <div>Вид брака</div>
              </SupplyPUTableHeaderColumn>
              <SupplyPUTableHeaderColumn withBorder>
                <SupplyFilterButton
                  itemsList={osListItems}
                  field={'taskNumber'}
                />
                <div>№ Заявки</div>
              </SupplyPUTableHeaderColumn>
              <SupplyPUTableHeaderColumn withBorder>
                <SupplyFilterButton
                  itemsList={osListItems}
                  field={'address'}
                />
                <div>Адрес демонтированного ПУ</div>
              </SupplyPUTableHeaderColumn>
              <SupplyPUTableHeaderColumn withBorder>
                <SupplyFilterButton
                  itemsList={osListItems}
                  field={'generalComment'}
                />
                <div>Общий комментарий</div>
              </SupplyPUTableHeaderColumn>
              <SupplyPUTableHeaderColumn withBorder>
                <SupplyFilterButton
                  itemsList={osListItems}
                  field={'supplyComment'}
                />
                <div>Складской комментарий</div>
              </SupplyPUTableHeaderColumn>
            </>
          )}

          {!viewMode && (
            <>
              {tabType !== 'relocation_get' && (
                <SupplyPUTableHeaderColumn
                  withBorder
                  withoutFilter
                >
                  Удалить
                </SupplyPUTableHeaderColumn>
              )}

              {billWithConfirm && workerAccess && (
                <SupplyPUTableHeaderColumn withoutFilter>Подтвердить</SupplyPUTableHeaderColumn>
              )}
            </>
          )}
        </SupplyPUComponentTableHeader>

        <div
          style={{ height: '90%' }}
          key={`${returnNewItemsMode}`}
        >
          <AutoSizer>
            {({ height, width }: { width: number; height: number }) => (
              <List
                ref={listRef}
                height={height}
                itemCount={filteredData.length}
                itemSize={itemSize}
                width={width}
                itemData={{
                  osListItems: filteredData,
                  removeScanRow,
                  handleSave,
                  onEditStart,
                  disabledOrDraftCheck,
                  selectedOs,
                  osDupFieldError,
                  osExistsFieldError,
                  returnBill,
                  showMessage,
                  confirmScanRow,
                  viewMode,
                  billWithConfirm,
                  relocationGetBill,
                  tabType,
                  accessProject,
                  loadingCheckExist,
                }}
                itemKey={(index) => filteredData[index].tmpId}
                className='SupplyVirtualizedTable_List'
              >
                {SupplyOsRow}
              </List>
            )}
          </AutoSizer>
        </div>
      </SupplyPUComponentTableWrapper>
    </>
  );
};

export default SupplyOsVirtualizedTable;
