import { styled, IconButton, Modal, Paper } from '@mui/material';

export const CommonModalCloseButton = styled(IconButton)(({ theme }) => ({
  position: 'absolute',
  top: 10,
  right: 10,
  zIndex: theme.zIndex.drawer + 1,
}));

export const CommonModalWrapper = styled(Modal)({
  margin: 'auto',
  height: 'fit-content',
  width: 'fit-content',
  maxWidth: '97vw',
});

export const CommonModalInner = styled(Paper)({
  position: 'relative',
  padding: '50px',
  maxHeight: '95svh',
  overflow: 'auto',
  height: '100%',
  scrollbarGutter: 'stable',
});
