import { ApiResponse } from '@/types/api';
import { YodaRestClient } from '@/services/YodaAuthService';
import { PhonebookDataResponseDto } from './dto/PhonebookServiceResponseDto';
import { PhonebookDataCreateRequestDto } from './dto/PhonebookDataRequestDto';

export async function ApiGetPhonebookSuggestions(
  search: string,
  offset: number,
  limit: number,
  options?: { signal?: AbortSignal }
): ApiResponse<PhonebookDataResponseDto[]> {
  const encodedSearch = encodeURIComponent(search);
  const url = `/rest/tp-phonebook?search=${encodedSearch}&offset=${offset}&limit=${limit}`;
  return YodaRestClient.get(url, { signal: options?.signal });
}

export async function ApiGetPhonebookSuggestionsCount(
  search: string,
  offset: number,
  limit: number,
  options?: { signal?: AbortSignal }
): ApiResponse<number> {
  const encodedSearch = encodeURIComponent(search);
  const url = `/rest/tp-phonebook/count?search=${encodedSearch}&offset=${offset}&limit=${limit}`;
  return YodaRestClient.get(url, { signal: options?.signal });
}

export async function ApiCreatePhonebookData(
  phonebookData: PhonebookDataCreateRequestDto
): ApiResponse<PhonebookDataResponseDto> {
  const url = `/rest/tp-phonebook`;
  return YodaRestClient.post(url, phonebookData);
}

export async function ApiUpdatePhonebookData(
  phonebookData: PhonebookDataCreateRequestDto,
  id: string
): ApiResponse<PhonebookDataResponseDto> {
  const url = `/rest/tp-phonebook/${id}`;
  return YodaRestClient.put(url, phonebookData);
}
