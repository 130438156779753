import { Box, Button, Grid } from '@mui/material';
import { useZoning } from '../../hook/ZoningHook';
import { useCatalog } from '@/hooks/CatalogHook';
import { useEffect, useState } from 'react';
import { useScreenHoldHook } from '@/hooks/ScreenHoldHook';
import { assignTasks, findAllZonningGroup } from '@/services/ZoningService/ZoningService';
import { ActionLogType, useActionLog } from '@/hooks/ActionLogHook';
import { LookUpDialog } from '@/components/dialog/LookUpDialog';
import { useSearch } from '../../hook/SearchHook';
import { ZoningItemMap } from './item';
import { ZoningGroupResponse } from '@/services/ZoningService/dto/ZoningServiceResponseDto';

export const ZoningItemPane = () => {
  const {
    addAll,
    addItem,
    getAllGroupId,
    zoningGroupResponses,
    selectedGroupIds,
    setOverlapGroupIds,
  } = useZoning();
  const { getGroupCatalog } = useCatalog((state) => ({
    getGroupCatalog: state.getGroupCatalog,
  }));
  const { setIsInProgress } = useScreenHoldHook();
  const { fetchCatch, addActionLog } = useActionLog();
  const [openLookUpDialog, setOpenLookUpDialog] = useState(false);
  const { zoningResponse, getDuplicate, serch, loading, getCoveredItemsForAssign } = useSearch();

  useEffect(() => {
    setIsInProgress(true);
    findAllZonningGroup()
      .then((res) => addAll(res.data))
      .catch((err) => fetchCatch(err, 'Ошибка при загрузке данных'))
      .finally(() => setIsInProgress(false));
  }, []);

  const sortByTitle = (a: ZoningGroupResponse, b: ZoningGroupResponse) => {
    const titleA = getGroupCatalog()?.get(a.groupId);
    const titleB = getGroupCatalog()?.get(b.groupId);
    return (titleA && titleB && titleA?.localeCompare(titleB)) || 0;
  };

  const onSelectedGroup = (groupId: string) => {
    if (groupId) {
      addItem(groupId);
    }
    setOpenLookUpDialog(false);
  };

  const getGroupValueMap = (): Map<string, string> => {
    const ids: string[] = getAllGroupId();
    return new Map([...getGroupCatalog()].filter(([k, v]) => !ids.includes(k)));
  };

  function onAssingTasks(): void {
    if (zoningResponse?.coveredItemList) {
      const toAssigneCoveredItems = getCoveredItemsForAssign(selectedGroupIds);
      const duplicateGroupIds = getDuplicate(toAssigneCoveredItems);
      setOverlapGroupIds(undefined);
      if (duplicateGroupIds.size > 0) {
        setOverlapGroupIds([...duplicateGroupIds]);
        addActionLog(
          ActionLogType.WARNING,
          'Обнаружены общие точки пересечения у разных подрядчиков'
        );
        return;
      }
      if (toAssigneCoveredItems.length == 0) {
        addActionLog(ActionLogType.WARNING, 'Список заявок для назначения пуст');
      } else {
        setIsInProgress(true);
        assignTasks(toAssigneCoveredItems)
          .then(({ data }) => {
            if (data && Object.entries(data).length > 0) {
              addActionLog(ActionLogType.WARNING, 'Часть заявок не назначена', String(data));
            } else {
              addActionLog(ActionLogType.SUCCESS, 'Заявки успешно назначены');
            }
            serch(fetchCatch);
          })
          .catch((err) => fetchCatch(err, 'Исполнитель не назначен'))
          .finally(() => setIsInProgress(false));
      }
    }
  }

  return (
    <Box
      style={{
        padding: 3,
        overflow: 'auto',
        background: '#F6F1F1',
        borderRadius: 10,
        maxHeight: '70vh',
        border: '1px solid #EFEFEF',
        boxShadow: '0px 4px 10px 0px #00000040',
      }}
    >
      <Grid
        container
        direction='column'
        justifyContent='space-between'
        alignItems='center'
        height={'100%'}
      >
        <Grid
          item
          container
          direction='column'
          justifyContent='flex-start'
          alignItems='flex-start'
        >
          <>
            {zoningGroupResponses.values() &&
              [...zoningGroupResponses.values()].sort(sortByTitle).map((t) => {
                return (
                  <Grid
                    key={`zoning-item-${t.groupId}`}
                    item
                    xs={4}
                  >
                    <ZoningItemMap zoningGroupResponse={t} />
                  </Grid>
                );
              })}
          </>
        </Grid>
        <Grid
          item
          container
          direction='row'
          justifyContent='flex-end'
          alignItems='center'
        >
          <Grid item>
            <Button
              size='small'
              onClick={() => setOpenLookUpDialog(true)}
            >
              добавить бригаду
            </Button>
          </Grid>
          <Grid item>
            <Button
              size='small'
              onClick={() => onAssingTasks()}
            >
              назначить всех
            </Button>
          </Grid>
        </Grid>
        <LookUpDialog
          mapValue={getGroupValueMap()}
          handleCancel={() => setOpenLookUpDialog(false)}
          handleOk={(selected: any) => onSelectedGroup(selected)}
          title='Выберите ответственного подрядчика'
          tooltip='(подрядчик)'
          open={openLookUpDialog}
        ></LookUpDialog>
      </Grid>
    </Box>
  );
};
