import CommonModal from '@/components/common/CommonModal/CommonModal';
import { AutocompleteValue } from '@/components/inputs/AutocompleteValue/AutocompleteValue';
import { BoxShadow } from '@/components/ui/BoxShadow';
import { color, font, removeDataGridCellOutline } from '@/styles/mixins';
import { styled } from '@mui/material';
import { DataGrid } from '@mui/x-data-grid';

export const PnrOrderTabWrapper = styled('div')({
  display: 'flex',
  flexWrap: 'wrap',
  gap: 130,
  marginTop: 15,
});

export const PnrOrderBoxShaddow = styled(BoxShadow)({
  width: 'fit-content',
  paddingRight: 40,
  minWidth: 700,
});

export const PnrOrderAutocompleteValue = styled(AutocompleteValue)({
  width: 250,
});

export const PnrOrderCommentWrapper = styled('div')({
  width: 200,
  cursor: 'pointer',
  overflow: 'hidden',
  textOverflow: 'ellipsis',
  whiteSpace: 'nowrap',
});

export const PnrOrderTitle = styled('h3')`
  ${font('title2')}
`;

export const PnrOrderRow = styled('div')`
  display: flex;
  flex-wrap: wrap;
  gap: 18px;
  align-items: flex-end;
`;

export const PnrOrderRowWrapper = styled('div')({
  borderBottom: `1px solid ${color('chronologyLine')}`,
  display: 'flex',
  paddingBottom: 5,
});

export const PnrOsOrderModalButtonWrapper = styled('div')`
  margin-top: 14px;
  display: inline-block;
`;

export const PnrOrderInputWrapper = styled('div')`
  min-width: 232px;

  .parent {
    padding: 0;
  }
`;

export const PnrOrderDataGrid = styled(DataGrid)`
  height: 500px;
  margin-top: 44px;
  ${removeDataGridCellOutline};
  .MuiDataGrid-row {
    cursor: pointer;
  }
`;

export const PnrOrderCommonModal = styled(CommonModal)`
  min-width: 50vw;
  .CommonModalInner {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    overflow: unset;
  }
`;

export const PnrOrderFormRow = styled(PnrOrderRow)`
  margin-top: 40px;
`;

export const PnrOrderRowHeader = styled('div')`
  ${font('text7')};
  width: 200px;
  overflow: hidden;
  text-overflow: ellipsis;
`;

export const PnrOrderRowsWrapper = styled('div')`
  margin-top: 30px;
  display: flex;
  flex-direction: column;
  gap: 5px;
  justify-content: center;
  align-items: center;
  overflow: auto;
`;
