import { Grid, IconButton, ImageList, Stack, Skeleton } from '@mui/material';
import { InfoComplaintBlockLabel } from '../ComplaintInfo/InfoComplaintBlock.styled';
import { useGalleryDataFromTask } from '../utils';
import ImageSearchRoundedIcon from '@mui/icons-material/ImageSearchRounded';
import { color } from '@/styles/mixins';
import { useGallery } from '../../NewGallery/utils';

import ComplaintImage from './ComplaintImage';

const GalleryBlock = () => {
  const { openGallery } = useGallery();
  const { photosList, isGalleryLoading } = useGalleryDataFromTask();

  const onOpenGalleryClick = () => {
    openGallery({ title: 'Фото жалобы', images: photosList });
  };

  // Если данные загружаются, показываем скелетоны
  if (isGalleryLoading) {
    return (
      <Stack
        style={{ backgroundColor: '#FFF', borderRadius: 10 }}
        p={1}
      >
        <Grid container>
          <Grid
            item
            xs={12}
            mb={1}
            display='flex'
            justifyContent='space-between'
            alignItems='center'
          >
            <Skeleton
              variant='text'
              width={100}
              height={24}
            />
            <Skeleton
              variant='circular'
              width={40}
              height={40}
            />
          </Grid>
        </Grid>
      </Stack>
    );
  }

  // Если после загрузки фотографий нет, возвращаем null
  if (!photosList.length) return null;

  return (
    <Stack
      style={{ backgroundColor: '#FFF', borderRadius: 10 }}
      p={1}
    >
      <Grid container>
        <Grid
          item
          xs={12}
          mb={1}
          display='flex'
          justifyContent='space-between'
          alignItems='center'
        >
          <InfoComplaintBlockLabel>Галерея</InfoComplaintBlockLabel>
          <IconButton
            sx={{ mr: 2 }}
            disabled={!photosList?.length}
            onClick={onOpenGalleryClick}
          >
            <ImageSearchRoundedIcon htmlColor={color('muiBlue')} />
          </IconButton>
        </Grid>
        <Grid
          item
          xs={12}
          maxHeight={300}
          overflow='auto'
        >
          <ImageList
            cols={3}
            rowHeight={160}
            sx={{ mb: 1 }}
          >
            {photosList.map((item) => (
              <ComplaintImage
                key={item.id}
                item={item}
              />
            ))}
          </ImageList>
        </Grid>
      </Grid>
    </Stack>
  );
};

export default GalleryBlock;
