import { getSingleTaskfromCallMap } from '@/services/CallingMapService/CallingMapService';
import { CircularProgress, TablePagination } from '@mui/material';
import { ruRU, GridFooter } from '@mui/x-data-grid';
import { DataGrid } from './CallingMapTable.styled';
import { useCallingMapState } from '../store';
import { useScreenHoldHook } from '@/hooks/ScreenHoldHook';
import { useActionLog } from '@/hooks/ActionLogHook';
import shallow from 'zustand/shallow';
import { columns, makeFilter } from '../utils/CallingMapUtils';
import { GeolessGetDataPayload } from '../types/types';
import { useCallingMapTabCount } from '@/components/maps/callingMap/components/CallingMapTab/store';
import { useHackedDataGridPro } from '@/hooks/useHackedDataGridPro';

export interface GeoLessPointsTableProps {
  getGeoLessTableData: (formatedFilter: any) => void;
}

function GeoLessPointsTable({ getGeoLessTableData }: GeoLessPointsTableProps) {
  useHackedDataGridPro();
  const { taskWithoutCoordinatesCount } = useCallingMapTabCount(
    (state) => ({
      taskWithoutCoordinatesCount: state.taskWithoutCoordinatesCount,
    }),
    shallow
  );
  const {
    geoLess,
    setSingleGeoPoint,
    setTaskId,
    geoLessCount,
    currentPage,
    pageSize,
    setCurrentPage,
    setPageSize,
    isloadingTable,
  } = useCallingMapState(
    (state) => ({
      geoLess: state.geoLess,
      setSingleGeoPoint: state.setSingleGeoPoint,
      setTaskId: state.setTaskId,
      geoLessCount: state.geoLessCount,
      currentPage: state.currentPage,
      pageSize: state.pageSize,
      setCurrentPage: state.setCurrentPage,
      setPageSize: state.setPageSize,
      isloadingTable: state.isloadingTable,
    }),
    shallow
  );
  const { setIsInProgress } = useScreenHoldHook();
  const { fetchCatch } = useActionLog();
  const {
    callCenterRequestType,
    callPeriod,
    requestPeriod,
    installationCallStatus,
    montagePlaceType,
    phase,
    taskType,
  } = useCallingMapState.getState();
  const formatedFilter = makeFilter(
    // {
    //   callCenterRequestType,
    //   taskType,
    //   montagePlaceType,
    //   phase,
    //   installationCallStatus,
    //   callPeriod,
    //
    //   requestPeriod,
    // },
    [],
    undefined
  );

  const handleRowClik = (row: any) => {
    setIsInProgress(true);
    getSingleTaskfromCallMap(row?.id)
      .then(({ data }) => {
        setSingleGeoPoint(data);
        setTaskId(row?.id);
      })
      .catch((error) => fetchCatch(error, 'Ошибка загрузки информации о заявке'))
      .finally(() => setIsInProgress(false));
  };

  function onPageChange(ev: any, newPage: number) {
    setIsInProgress(true);
    setCurrentPage(newPage);
    getGeoLessTableData(formatedFilter);
  }

  function onPageSizeChange(newPageSize: any) {
    setPageSize(Number(newPageSize.target.value));
    setIsInProgress(true);
    getGeoLessTableData(formatedFilter);
  }

  return (
    <div style={{ height: '80vh', scrollBehavior: 'auto' }}>
      <DataGrid
        rows={geoLess}
        columns={columns}
        getRowId={(row) => row.taskId}
        localeText={ruRU.components.MuiDataGrid.defaultProps.localeText}
        disableColumnMenu
        pagination
        pageSize={pageSize}
        loading={isloadingTable}
        rowsPerPageOptions={[5, 10, 25, 50, 100]}
        onRowClick={handleRowClik}
        disableSelectionOnClick
        components={{
          Footer: GridFooter,
          Pagination: () => (
            <>
              <TablePagination
                count={
                  typeof taskWithoutCoordinatesCount == 'number' ? taskWithoutCoordinatesCount : 0
                }
                page={currentPage}
                rowsPerPage={pageSize}
                onRowsPerPageChange={onPageSizeChange}
                labelDisplayedRows={(props) => (
                  <DisplayedRows
                    {...props}
                    isCountLoading={taskWithoutCoordinatesCount == 'pending'}
                  />
                )}
                onPageChange={onPageChange}
                rowsPerPageOptions={[5, 10, 25, 50, 100]}
                labelRowsPerPage={'Заявок на странице:'}
              />
            </>
          ),
        }}
      />
    </div>
  );
}

export const DisplayedRows = ({
  from,
  to,
  count,
  isCountLoading,
}: {
  from: number;
  to: number;
  count: number;
  isCountLoading: boolean;
}) => {
  return (
    <div style={{ display: 'inline-block', padding: 4, minWidth: 18 }}>
      {`${from} - ${to} из `}
      {isCountLoading ? <CircularProgress size={15} /> : count}
    </div>
  );
};

export default GeoLessPointsTable;
