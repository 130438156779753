import { RefObject } from 'react';
import * as d3 from 'd3';
import { colorsSegment } from '../utils';
import { StatisticSummaryResponse } from '../../api/dto/response';

export const normalizeData = (data: StatisticSummaryResponse[], minPercentage = 5) => {
  const total = data.reduce((sum, item) => sum + item.count, 0);
  const minValue = (minPercentage / 100) * total;

  const updatedData = data.map((item) => ({
    ...item,
    // Модифицируем значение, для учета "минимальной" ширины сегмента
    count: item.count < minValue ? minValue : item.count,
    // Используем исходное значение для отрисовки  в тултипе
    totalCount: item.count,
  }));

  const adjustedTotal = updatedData.reduce((sum, item) => sum + item.count, 0);
  const scale = total / adjustedTotal;

  // Масштабируем значения обратно к исходной сумме
  return updatedData.map((item) => ({
    ...item,
    count: Math.round(item.count * scale),
  }));
};

export const height = 870;
export const padding = 120;

export const paddingChart = (data: StatisticSummaryResponse[]) => {
  if (data.some((item) => item.segments.some((segment) => segment > item.count))) {
    return 120;
  } else {
    return 0;
  }
};

export const createSvg = (svgRef: RefObject<SVGSVGElement>, width: number) => {
  const height = width;
  return d3
    .select(svgRef.current)
    .attr('width', width)
    .attr('height', height)
    .append('g')
    .attr('transform', `translate(${width / 2}, ${width / 2})`);
};

export const createPie = () => {
  return d3
    .pie<StatisticSummaryResponse>()
    .value((d) => d.count)
    .padAngle(0.01)
    .sort(null);
};

export const createArc = (radius: number) => {
  return d3
    .arc<d3.PieArcDatum<StatisticSummaryResponse>>()
    .innerRadius(radius * 0.4)
    .outerRadius(radius * 1)
    .cornerRadius(8);
};

export const TOOLTIP_POSITION_GAP = 15;

export const getMouseCordsWithGap = (event: any) => ({
  top: event.clientY + TOOLTIP_POSITION_GAP,
  left: event.clientX + TOOLTIP_POSITION_GAP,
});

export const drawSegments = (
  d: d3.PieArcDatum<StatisticSummaryResponse>,
  svg: d3.Selection<SVGGElement, unknown, null, undefined>,
  equipmentsData: StatisticSummaryResponse[],
  i: number,
  handleMouseover: (event: MouseEvent, d: d3.PieArcDatum<StatisticSummaryResponse>) => void,
  handleMousemove: (event: MouseEvent) => void,
  handleMouseout: (event: MouseEvent) => void,
  handleOnClick: (
    event: React.MouseEvent<HTMLDivElement, MouseEvent>,
    d: d3.PieArcDatum<StatisticSummaryResponse>
  ) => void,
  radius: number
) => {
  const offsetAngle = equipmentsData.length > 1 ? 0.006 : 0;
  const startAngle = d.startAngle + offsetAngle;
  const endAngle = d.endAngle - offsetAngle;

  const segments = [...d.data.segments];
  segments.splice(1, 1);

  const countPlan = d.data.totalCount ? d.data.totalCount : d.data.count;

  segments.forEach((item, index) => {
    if (segments[index] > 0) {
      if ((segments[0] * 100) / countPlan < 5 && (segments[1] * 100) / countPlan < 5) {
        segments[0] = countPlan * 0.05;
        segments[1] = 0;
      } else if ((segments[index] * 100) / countPlan < 5) {
        segments[index] = countPlan * 0.05;
      } else {
        if (
          segments[1] > 0 &&
          (segments[1] * 100) / countPlan < 5 &&
          (segments[0] * 100) / countPlan < 10
        ) {
          segments[0] = countPlan * 0.1;
          segments[1] = countPlan * 0.05;
        } else {
          segments[index] = item;
        }
      }
    }
  });

  segments.forEach((element, index) => {
    const innerArc: any = d3
      .arc()
      .innerRadius(radius * (0.4 + ((segments[index] * 100) / countPlan / 100) * 0.6))
      .outerRadius(radius * 0.4)
      .startAngle(startAngle)
      .endAngle(endAngle);

    const title = equipmentsData[i]?.title as keyof typeof colorsSegment;
    svg
      .append('path')
      .attr('d', innerArc)
      .attr('fill', colorsSegment[title][index + 1])
      .attr('stroke', '#FFFFFF')
      .attr('stroke-width', 1)
      .attr('class', () => `sector`)
      .style('cursor', 'pointer')
      .on('mouseover', (event) => handleMouseover(event, d))
      .on('mousemove', handleMousemove)
      .on('mouseout', (event) => handleMouseout(event))
      .on('click', (event) => handleOnClick(event, d));
  });
};

export const drawSectorsDoughnut = (
  arcs: d3.PieArcDatum<StatisticSummaryResponse>[],
  svg: d3.Selection<SVGGElement, unknown, null, undefined>,
  equipmentsData: StatisticSummaryResponse[],
  handleMouseover: (event: MouseEvent, d: d3.PieArcDatum<StatisticSummaryResponse>) => void,
  handleMousemove: (event: MouseEvent) => void,
  handleMouseout: (event: MouseEvent) => void,
  handleOnClick: (
    event: React.MouseEvent<HTMLDivElement, MouseEvent>,
    d: d3.PieArcDatum<StatisticSummaryResponse>
  ) => void,
  radius: number
) => {
  const arc = createArc(radius);

  svg
    .selectAll('path.sector')
    .data(arcs)
    .enter()
    .append('path')
    .attr('class', (d, i) => `sector sector-${i}`)
    .style('cursor', 'pointer')
    .attr('d', arc)
    .attr('fill', (d, i) => {
      const title = equipmentsData[i]?.title as keyof typeof colorsSegment;
      return colorsSegment[title][0];
    })
    .each(function (d, i) {
      drawSegments(
        d,
        svg,
        equipmentsData,
        i,
        handleMouseover,
        handleMousemove,
        handleMouseout,
        handleOnClick,
        radius
      );
    })
    .on('mouseover', (event, d) => handleMouseover(event, d))
    .on('mousemove', handleMousemove)
    .on('mouseout', (event, d) => handleMouseout(event))
    .on('click', (event, d) => handleOnClick(event, d));
};

export const adjustSvgSizeForGroup = (svgRef: React.RefObject<SVGSVGElement>) => {
  const svgElement = svgRef.current;

  const groupElement = svgElement?.querySelector('g');
  if (svgElement && groupElement) {
    const rectSvg = svgElement.getBoundingClientRect();

    const leftSvg = rectSvg.left;
    const topSvg = rectSvg.top;

    const bbox = groupElement.getBBox();
    const rect = groupElement.getBoundingClientRect();

    const left = rect.left;
    const top = rect.top;

    const offsetTop = Math.abs(top - topSvg);
    const offsetLeft = Math.abs(left - leftSvg);

    const currentWidth = svgElement.clientWidth;
    const currentHeight = svgElement.clientHeight;

    if (bbox.height > currentHeight || bbox.width > currentWidth) {
      const scaleX = currentWidth / bbox.width;
      const scaleY = currentHeight / bbox.height;

      const scale = Math.min(scaleX, scaleY);

      const transformWidth = bbox.width * scale;
      const transformHeight = bbox.height * scale;

      const centeredOffsetX = (currentWidth - transformWidth) / 2;
      const centeredOffsetY = (currentHeight - transformHeight) / 2;

      groupElement.setAttribute(
        'transform',
        `scale(${scale}) translate(${currentWidth / 2 + offsetLeft + centeredOffsetX}, ${
          currentHeight / 2 + offsetTop + centeredOffsetY
        })`
      );
    }
  }
};
