// Универсальная функция для обновления выбранных строк в DataGrid
export const updateSelection = <T>(
  newSelection: string[],
  selectedRowsData: T[],
  dataSource: T[],
  idKey: keyof T
): T[] => {
  // Оставляем только те строки, идентификатор которых есть в новом выборе
  const updatedSelectedRows = selectedRowsData.filter((row) =>
    newSelection.includes(String(row[idKey]))
  );

  // Для каждого нового идентификатора пытаемся найти строку в dataSource
  // Если не находим, берем уже выбранную строку из selectedRowsData
  const newlySelectedRows = newSelection
    .map(
      (id) =>
        dataSource.find((item) => String(item[idKey]) === id) ||
        selectedRowsData.find((item) => String(item[idKey]) === id)
    )
    .filter(Boolean) as T[];

  // Объединяем найденные строки и удаляем дубликаты по идентификатору
  const finalSelectedRows = [...updatedSelectedRows, ...newlySelectedRows].filter(
    (row, index, self) => self.findIndex((r) => String(r[idKey]) === String(row[idKey])) === index
  );

  return finalSelectedRows;
};
