import React, { FC, MouseEvent, useState } from 'react';
import { TextField } from '@/components/fixUI';
import { Grid, IconButton, InputBase, Typography } from '@mui/material';
import { useFormContext } from 'react-hook-form';
import { PpoVruTaskResponse, ResultImage } from '@/dto/taskmap/task/TaskResultDto';
import { AutocompleteValue } from '@/components/inputs/AutocompleteValue/AutocompleteValue';
import { PpoLockerWrapper } from './ppoLocker/PpoLocker';
import { IInput, useLockerPopup } from '@/components/popups/lockerPopup/store';
import { useGalleryData } from '@/components/Gallery/store';
import { getPpoVruInputResImageGroup } from '../panel/utils';
import { ActionLogType, useActionLog } from '@/hooks/ActionLogHook';
import { useCatalog } from '@/hooks/CatalogHook';
import { AccessPopup } from '@/components/popups/AccessPopup/AccessPopup';
import DeleteOutlineRoundedIcon from '@mui/icons-material/DeleteOutlineRounded';
import { deleteODPUVru, deletePPOODPUVru } from '@/services/TaskService/TaskService';

interface PpoVruInputProps extends PpoVruTaskResponse, Partial<ResultImage> {
  index: number;
  loadAll: any;
}

export const PpoVruInput: FC<PpoVruInputProps> = (props) => {
  const { register, setValue } = useFormContext();
  const setImageGroups = useGalleryData(({ setImageGroups }) => setImageGroups);
  const { index, id, installationPlace, ppoOdpuVruResponse, number, inputResponseList, loadAll } =
    props;
  const {
    id: ppoOdpuVruId,
    powerScheme,
    comment,
    hasAvrBeforeProtection,
    hasCableConnection,
    tpNumber,
  } = ppoOdpuVruResponse || {};

  const { fetchCatch, addActionLog } = useActionLog();
  const { getCatalogMapWithErr } = useCatalog((state) => ({
    getCatalogMapWithErr: state.getCatalogMapWithErr,
  }));
  const [isOpen, setIsOpenPopup] = useState<boolean>(false);
  const handleDelete = () => {
    setIsOpenPopup(false);
    deletePPOODPUVru(ppoOdpuVruId)
      .then((res) => {
        loadAll();
        addActionLog(ActionLogType.SUCCESS, 'ВРУ успешно удален');
      })
      .catch((err) => fetchCatch(err, `Ошибка удаления ВРУ`));
  };

  const handleClick = (e: MouseEvent<HTMLElement>) => {
    e.stopPropagation();
    setImageGroups(getPpoVruInputResImageGroup(props));
  };

  const lockerArr = inputResponseList
    ?.filter((elem) => elem.lockerResponse)
    ?.map((elem) => ({
      ...elem.lockerResponse,
    }))
    .filter((el, ind, arr) => arr.findIndex((elem) => elem.id === el.id) === ind)
    .map((e) => {
      const inputTmp = inputResponseList
        ?.filter((element) => element?.lockerResponse?.id === e.id)
        .map((e) => ({ id: e.id, inputNumber: e.inputNumber }));
      return { ...e, array: inputTmp };
    });

  const inputs = inputResponseList
    ? (inputResponseList
        .map((e) => {
          if (e.id && e.inputNumber) return { id: e.id, number: e.inputNumber };
          return;
        })
        .filter((f) => f !== undefined) as IInput[])
    : [];

  setValue(`vruRequestList.${index}.id`, id);
  setValue(`vruRequestList.${index}.ppoOdpuVruRequest.id`, ppoOdpuVruId);
  // setValue(`vruRequestList.${index}.installationPlace`, installationPlace);
  // setValue(`vruRequestList.${index}.number`, number);

  const yesNoMap = new Map([
    ['true', 'Да'],
    ['false', 'Нет'],
  ]);

  const { setIsOpen, setInputs, setLockerData } = useLockerPopup((state) => ({
    setIsOpen: state.setIsLockerPopupOpen,
    setInputs: state.setInputs,
    setLockerData: state.setLockerData,
  }));

  const doubleClickHandler = (i: any) => {
    setInputs(
      inputResponseList
        ? (inputResponseList
            .map((e) => {
              if (e.id && e.inputNumber) return { id: e.id, number: e.inputNumber };
              return;
            })
            .filter((f) => f !== undefined) as IInput[])
        : []
    );
    setLockerData(i);
    setIsOpen(true);
  };

  return (
    <>
      <Grid
        item
        xs={9}
        container
        spacing={2}
      >
        <Grid
          item
          xs={12}
        >
          <Typography variant='h5'>
            <TextField
              id='tf-enter'
              variant='standard'
              {...register(`vruRequestList.${index}.number`, {
                value: number,
              })}
              sx={{ width: '100%', display: 'inline' }}
            />
            {/*{number} */}
            место установки: {/*{installationPlace}*/}
            <TextField
              id='tf-enter'
              variant='standard'
              {...register(`vruRequestList.${index}.installPlace`, {
                value: installationPlace,
              })}
              sx={{ width: '100%', display: 'inline' }}
            />
            <IconButton onClick={() => setIsOpenPopup(true)}>
              <DeleteOutlineRoundedIcon />
            </IconButton>
          </Typography>
        </Grid>
        <Grid
          item
          xs={3}
        >
          <TextField
            id='vru-tp-number'
            label='ТТ/Кабельный киоск'
            variant='standard'
            {...register(`vruRequestList.${index}.ppoOdpuVruRequest.tpNumber`, { value: tpNumber })}
            sx={{ width: '100%' }}
          />
        </Grid>
        <Grid
          item
          xs={3}
        >
          <AutocompleteValue
            title='Шлейфовое подключение других ВРУ'
            defaultValue={hasCableConnection ? `${hasCableConnection}` : undefined}
            fieldName={`vruRequestList.${index}.ppoOdpuVruRequest.hasCableConnection`}
            values={yesNoMap}
          />
        </Grid>
        <Grid
          item
          xs={3}
        >
          <AutocompleteValue
            title='АВР до рубильника'
            defaultValue={hasAvrBeforeProtection ? `${hasAvrBeforeProtection}` : undefined}
            fieldName={`vruRequestList.${index}.ppoOdpuVruRequest.hasAvrBeforeProtection`}
            values={yesNoMap}
          />
        </Grid>
        <Grid
          item
          xs={3}
        >
          <AutocompleteValue
            title='Схема питания'
            defaultValue={powerScheme ? `${powerScheme}` : undefined}
            fieldName={`vruRequestList.${index}.ppoOdpuVruRequest.powerScheme`}
            values={getCatalogMapWithErr('powerScheme')}
          />
        </Grid>
      </Grid>

      <Grid
        item
        xs={3}
        container
        spacing={2}
        style={{
          justifyContent: 'flex-end',
        }}
      >
        {lockerArr?.map((e: any, i: number) => (
          <Grid
            key={e.id}
            item
            style={{
              display: 'flex',
              alignItems: 'flex-end',
              justifyContent: 'flex-end',
            }}
          >
            <div onDoubleClick={() => doubleClickHandler({ ...e, isNew: false })}>
              <PpoLockerWrapper
                array={e.array}
                count={e.puCount}
              />
            </div>
          </Grid>
        ))}
        {lockerArr && lockerArr?.length < 1 && (
          <Grid
            item
            style={{
              display: 'flex',
              alignItems: 'flex-end',
              justifyContent: 'flex-end',
            }}
          >
            <div onDoubleClick={() => doubleClickHandler({ puCount: 1, array: [], isNew: true })}>
              <PpoLockerWrapper
                array={inputs}
                isNew
              />
            </div>
          </Grid>
        )}
      </Grid>
      <AccessPopup
        headerText={'После удаления восстановить данные будет невозможно'}
        titleText={'Продолжить?'}
        successText={'Да'}
        closeText={'Отменить'}
        onSuccess={handleDelete}
        onClose={() => setIsOpenPopup(false)}
        isOpen={isOpen}
      />
    </>
  );
};
