import { color } from '@/styles/mixins';
import { BaseUI } from '@/types/ui';
import { Box, Button, styled, SxProps, Typography } from '@mui/material';
import { FC } from 'react';

export const SupplyPuDataTypography: FC<BaseUI & { sx?: SxProps }> = ({ ...props }) => (
  <Typography
    fontSize={14}
    sx={{ mt: 1 }}
    variant='caption'
    gutterBottom
    {...props}
  />
);

export const SupplyPUModalInner = styled(Box)({
  width: '100%',
  height: '100%',
  border: `1px solid ${color('trueBlack')}`,
  backgroundColor: color('trueWhite'),
  padding: 32,
  display: 'flex',
  flexDirection: 'column',

  '.MuiToolbar-root': {
    p: {
      marginBottom: 0,
    },
  },
});

export const PURemoveScannerModalInner = styled(Box)({
  width: 500,
  border: `1px solid ${color('trueBlack')}`,
  backgroundColor: color('trueWhite'),
  padding: 32,
  position: 'absolute' as const,
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
});

export const PURemoveScannerModalFormWrapper = styled('div')({
  width: '100%',
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'center',
  alignItems: 'center',
  marginBottom: '20px',
  marginTop: '10px',
});

export const PURemoveScannerModalButtonsWrapper = styled('div')({
  width: '100%',
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'flex-end',
  marginTop: '10px',
  gap: 10,
});

export const PURemoveScannerScannButton = styled(Button)({
  height: 30,
  borderRadius: '4px',
  boxShadow:
    '0px 3px 1px -2px rgba(0,0,0,0.2), 0px 2px 2px 0px rgba(0,0,0,0.14), 0px 1px 5px 0px rgba(0,0,0,0.12)',
});
