import React, { FC, ReactNode, useEffect, useState } from 'react';
import { BoxShadow } from '@/components/ui/BoxShadow';
import { Grid, Stack, Typography } from '@mui/material';
import CopyButton from '@/components/button/CopyButton/CopyButton';
import { TextField } from '@/components/fixUI';
import { FormProvider, useForm } from 'react-hook-form';
import { ExpandButton } from '@/components/button/ExpandButton/ExpandButton';
import { ModeViewerResult } from '@/components/features/taskEditor/types';
import { useGalleryData } from '@/components/Gallery/store';
import { TASK_EDITOR_BIG_PREVIEW } from '../item/TaskEditorItem.styled';
import { IdPopup } from '../panel/IdPopup';
import { FILE_TYPES } from '@/components/features/taskEditor/adpu/ActPopup';
import { SaveButton } from '@/components/ui/SaveButton/SaveButton';
import DateField from '@/components/DateField';
import { updateAdpu } from '@/components/features/taskEditor/adpu/api/AdpuService';
import { useActionLog } from '@/hooks/ActionLogHook';
import { useCatalog } from '@/hooks/CatalogHook';
import { AdpuResponse } from './api/dto/AdpuDtoResponse';

interface IItem extends AdpuResponse {
  mode?: ModeViewerResult;
  updateFunction: (newAdpu: AdpuResponse) => void;
  isNew?: boolean;
}

export const Item: FC<IItem> = ({
  actStatus,
  actOn,
  subscriberName,
  id,
  montageName,
  imageList,
  mode = ModeViewerResult.LARGE,
  updateFunction,
}) => {
  const { getCatalogMapWithErr } = useCatalog((state) => ({
    getCatalogMapWithErr: state.getCatalogMapWithErr,
  }));
  const { fetchCatch } = useActionLog();
  const methods = useForm();
  const { register, setValue, handleSubmit } = methods;
  useEffect(() => {
    setValue(`${id}.montageName`, montageName);
    setValue(`${id}.signedADPU`, subscriberName);
  }, [montageName, subscriberName]);
  const { setImageGroups } = useGalleryData();

  const [innerMode, setInnerMode] = useState<ModeViewerResult>(mode);
  const handleMode = () => {
    setInnerMode(
      innerMode === ModeViewerResult.SMALL ? ModeViewerResult.LARGE : ModeViewerResult.SMALL
    );
  };

  const handleDoubleClick = (e: any) => {
    e.stopPropagation();
    setImageGroups([{ title: 'АДПУ', imageList: imageList, obj: id, filterIds: FILE_TYPES }]);
  };

  const onSubmit = (b: any) => {
    console.log(b[id]);
    updateAdpu(id, b[id])
      .then(({ data }) => {
        console.log(data);
        updateFunction(data);
      })
      .catch((res) => {
        fetchCatch(res, 'Ошибка редактирования АДПУ');
      });
    // console.log(b);
  };

  const getPreview = (): ReactNode => {
    return (
      <Grid {...TASK_EDITOR_BIG_PREVIEW.inner}>
        <Grid {...TASK_EDITOR_BIG_PREVIEW.lastItem}>
          <Stack
            direction={'row'}
            justifyContent='flex-end'
          >
            <Typography>АДПУ</Typography>
            <ExpandButton
              onAction={handleMode}
              sx={{ marginLeft: 1 }}
            />
          </Stack>
        </Grid>
      </Grid>
    );
  };

  return (
    <Grid
      item
      xs={12}
    >
      <FormProvider {...methods}>
        <BoxShadow onDoubleClick={handleDoubleClick}>
          {innerMode === ModeViewerResult.SMALL && <>{getPreview()}</>}
          {(innerMode === ModeViewerResult.MEDIUM || innerMode === ModeViewerResult.LARGE) && (
            <>
              <Grid
                container
                spacing={2}
              >
                <Grid
                  item
                  xs={12}
                >
                  <Stack
                    direction={'row'}
                    sx={{ alignItems: 'center' }}
                  >
                    <Typography>
                      <span style={{ fontWeight: 'bold' }}>Статус:</span>{' '}
                      {getCatalogMapWithErr('actStatus')?.get(actStatus)}
                    </Typography>
                    <div style={{ marginLeft: 'auto', display: 'flex', alignItems: 'center' }}>
                      <IdPopup sx={{ color: 'gray' }}>
                        {id && (
                          <div>
                            <CopyButton textToCopy={id} />
                            id АДПУ {id}
                          </div>
                        )}
                      </IdPopup>
                    </div>
                  </Stack>
                </Grid>
                <Grid
                  item
                  xs={3}
                >
                  <DateField
                    label={'Дата подписания акта'}
                    defaultValue={actOn}
                    hookFormString={`${id}.actOn`}
                  />
                </Grid>
                <Grid
                  item
                  xs={3}
                >
                  <TextField
                    id='montageName'
                    label='Подписано кем от подрядчика'
                    variant='standard'
                    {...register(`${id}.montageName`, {
                      value: montageName,
                    })}
                    // disabled
                    sx={{ width: '100%' }}
                  />
                </Grid>
                <Grid
                  item
                  xs={3}
                >
                  <TextField
                    id='montageName'
                    label='Подписано кем от потребителя'
                    variant='standard'
                    {...register(`${id}.signedADPU`, {
                      value: subscriberName,
                    })}
                    // disabled
                    sx={{ width: '100%' }}
                  />
                </Grid>
              </Grid>
              <Stack
                direction={'row'}
                sx={{ alignItems: 'center', flexWrap: 'wrap' }}
              >
                <Grid sx={{ ml: 'auto' }}></Grid>
                <Grid>
                  <SaveButton onSave={handleSubmit(onSubmit)} />
                  <ExpandButton
                    expand={innerMode === ModeViewerResult.LARGE}
                    onAction={handleMode}
                  />
                </Grid>
              </Stack>
            </>
          )}
        </BoxShadow>
      </FormProvider>
    </Grid>
  );
};
