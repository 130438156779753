import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Typography,
  Skeleton,
  Button,
} from '@mui/material';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import React, { FC, useEffect, useState } from 'react';
import { useTags } from '@/components/Tags/useTags';
import { Tag } from '@/components/Tags/Tag';
import { TagsWrapper } from '@/components/Tags/Tags.styled';
import AddRoundedIcon from '@mui/icons-material/AddRounded';
import { AddTagPopup } from '@/components/Tags/AddTagPopup';
import { TagsResponse } from '@/components/Tags/api/dto/TagsDto';
import { BoxShadow } from '../ui/BoxShadow';

interface ITags {
  taskId: string;
}

export const TagsList: FC<ITags> = ({ taskId }) => {
  const { isFetching, data } = useTags(taskId);
  const [tagsData, setTagsData] = useState<TagsResponse[]>([]);
  useEffect(() => {
    setTagsData(data);
  }, [isFetching]);
  const [isOpenAddPopup, setIsOpenAddPopup] = useState(false);
  return (
    <>
      {isFetching ? (
        <Skeleton animation='wave' />
      ) : (
        <>
          {tagsData.length > 0 && (
            <TagsWrapper>
              {tagsData.map((tag) => (
                <Tag
                  id={tag.id}
                  key={tag.id}
                  tagName={tag.tag}
                  handleUpdate={() => setTagsData(tagsData.filter((elem) => elem.id !== tag.id))}
                />
              ))}
            </TagsWrapper>
          )}
          <Button
            sx={{ display: 'flex', margin: '0 auto' }}
            onClick={() => setIsOpenAddPopup(true)}
            variant={'text'}
            startIcon={<AddRoundedIcon />}
          >
            Добавить тег
          </Button>
          <AddTagPopup
            isOpen={isOpenAddPopup}
            onClose={() => setIsOpenAddPopup(false)}
            handleUpdate={(tag) => {
              const isExsistTag = tagsData.find((elem) => elem.id === tag[0].id);
              !isExsistTag && setTagsData([...tagsData, ...tag]);
            }}
            taskIds={[taskId]}
          />
        </>
      )}
    </>
  );
};
