import { GRID_CHECKBOX_SELECTION_COL_DEF, GridRenderCellParams } from '@mui/x-data-grid';
import { CellWithTooltip } from '../../tasksBrowse/constants';
import moment from 'moment';
import { useMemo } from 'react';
import { IconButton } from '@mui/material';
import BorderColorRoundedIcon from '@mui/icons-material/BorderColorRounded';
import { useOdpuManagementCompanyStore } from '../store';

export const useOdpuManagmentCompanyColumns = () => {
  const editCompany = useOdpuManagementCompanyStore((state) => state.editCompany);
  return useMemo(
    () => [
      {
        ...GRID_CHECKBOX_SELECTION_COL_DEF,
        hide: true,
      },

      {
        field: 'title',
        headerName: `Наименование`,
        minWidth: 180,
        flex: 1,
        renderCell: (params: GridRenderCellParams) => <CellWithTooltip params={params} />,
      },
      {
        field: 'legalAddress',
        headerName: 'Адрес',
        minWidth: 280,
        flex: 2,
        renderCell: (params: GridRenderCellParams) => <CellWithTooltip params={params} />,
      },
      {
        field: 'director',
        headerName: 'Руководитель',
        minWidth: 180,
        renderCell: (params: GridRenderCellParams) => <CellWithTooltip params={params} />,
      },
      {
        field: 'phoneNumber',
        headerName: 'Тел. номер',
        minWidth: 180,
        renderCell: (params: GridRenderCellParams) => <CellWithTooltip params={params} />,
      },
      {
        field: 'createdBy',
        headerName: 'Создано кем',
        minWidth: 180,
        renderCell: (params: GridRenderCellParams) => <CellWithTooltip params={params} />,
      },
      {
        field: 'createTs',
        headerName: 'Дата создания',
        minWidth: 150,
        renderCell: (params: GridRenderCellParams) => {
          const paramsCopy = params;
          paramsCopy.value = params.value && moment(params.value).format('DD-MM-yyyy');
          return <CellWithTooltip params={paramsCopy} />;
        },
      },
      {
        field: 'updatedBy',
        headerName: 'Обновлено кем',
        minWidth: 180,
        renderCell: (params: GridRenderCellParams) => <CellWithTooltip params={params} />,
      },
      {
        field: 'updateTs',
        headerName: 'Дата обновления',
        minWidth: 150,
        renderCell: (params: GridRenderCellParams) => {
          const paramsCopy = params;
          paramsCopy.value = params.value && moment(params.value).format('DD-MM-yyyy');
          return <CellWithTooltip params={paramsCopy} />;
        },
      },

      {
        field: 'id',
        headerName: 'ID',
        minWidth: 180,
        flex: 1,
        renderCell: (params: GridRenderCellParams) => <CellWithTooltip params={params} />,
      },
      {
        field: '_edit',
        headerName: 'Ред.',
        renderCell: (params: GridRenderCellParams) => {
          return (
            <IconButton
              color='info'
              size='small'
              onClick={() => editCompany(params.row)}
            >
              <BorderColorRoundedIcon fontSize='inherit' />
            </IconButton>
          );
        },
      },
    ],
    []
  );
};

const REQUIRED = {
  required: 'Обязательно для заполнения',
};

const ONLY_RUSSIANS_LETTERS = {
  pattern: {
    value: /^[^a-zA-Z\t\n\r\v\f]*$/,
    message: 'Используйте только буквы русского алфавита',
  },
};

const PHONE_PATTERN = {
  pattern: {
    value: /^(?:\+7|8|7)?[-\s]?\(?\d{3}\)?[-\s]?\d{3}[-\s]?\d{2}[-\s]?\d{2}$/,
    message: 'Введите номер в формате +7ХХХХХХХХХХ',
  },
};

const validatePhoneNumber = (value: any) => {
  const cleanedValue = value?.replace(/\s+/g, '');
  const isValidPattern = PHONE_PATTERN.pattern.value.test(cleanedValue);
  // Если значение не соответствует паттерну
  if (cleanedValue && !isValidPattern) {
    return PHONE_PATTERN.pattern.message;
  }
  return true; // Валидация прошла успешно
};

export const validationRules = {
  phoneNumber: {
    ...PHONE_PATTERN,
    validate: validatePhoneNumber,
  },

  title: {
    ...REQUIRED,
    ...ONLY_RUSSIANS_LETTERS,
  },
};
