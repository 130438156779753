import CommonModal from '@/components/common/CommonModal/CommonModal';
import { color } from '@/styles/mixins';
import { Backdrop, IconButton, styled, TablePagination } from '@mui/material';

export const PhonebookCommonModal = styled(CommonModal)({
  minWidth: '95%',
});

export const PhonebookContainer = styled('div')({
  display: 'flex',
  flexDirection: 'column',
  gap: 20,
  alignItems: 'center',
});

export const PhonebookScrollContainer = styled('div')({
  display: 'flex',
  flexDirection: 'column',
  gap: 14,
  overflow: 'auto',
  minHeight: 200,
  maxHeight: '48svh',
  padding: '10px 16px 16px 0',
  alignItems: 'center',
  scrollbarGutter: 'stable',
  position: 'relative',
});

export interface PnrElementWrapperProps {
  hover?: boolean;
}

export const PhonebookRowWrapper = styled('div')<PnrElementWrapperProps>(({ hover }) => ({
  display: 'flex',
  alignItems: 'flex-start',
  gap: 10,
  width: '100%',
  padding: 10,
  border: 'solid',
  borderColor: color('grayLabel', 0.5),
  borderRadius: 15,
  transition: '0.3s',
  transitionProperty: 'border-color, transform',

  ...(hover && {
    borderColor: color('muiBlue', 1),
    transform: 'translateY(-2px) ',
    cursor: 'pointer',
  }),
}));

export const PhonebookBackdrop = styled(Backdrop)({
  position: 'absolute',
  top: 0,
  left: 0,
  width: '100%',
  height: '100%',
  zIndex: 1,
  backgroundColor: color('trueWhite', 0.5),
});

export interface PhonebookClearIconPros {
  show?: boolean;
}
export const PhonebookClearIcon = styled(IconButton)<PhonebookClearIconPros>(({ show }) => ({
  opacity: show ? 1 : 0,
  transition: 'opacity 0.3s',
  pointerEvents: show ? 'auto' : 'none',
}));

export const PhonebookTablePagination = styled(TablePagination)({
  display: 'block',
  p: {
    marginBottom: 0,
  },
});

export interface PhonebookCreateWrapper {
  loading?: boolean;
}
export const PhonebookCreateWrapper = styled('div')<PhonebookCreateWrapper>(({ loading }) => ({
  width: '100%',
  paddingRight: '32px',
  opacity: loading ? 0.5 : 1,
  transition: '0.3s',
  pointerEvents: loading ? 'none' : 'auto',
}));

export const PhonebookRowContainer = styled(PhonebookCreateWrapper)({
  paddingRight: 0,
});

export const PhonebookIconButtonWrapper = styled('div')({
  flexShrink: 0,
  width: 40,
  height: 40,
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
});

export const PhonebookIconsRow = styled('div')({
  flexShrink: 0,
  gap: 4,
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
});
