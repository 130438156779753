import React from 'react';
import { Placemark, withYMaps } from 'react-yandex-maps';
import { RouterPointBallon } from './RoutertPointBallon';
import { RouterType } from '@/components/maps/ppoMap/dto';
import routerIcon from '../delivery-point/delivery-point-icons/router.svg';

interface DeliveryPointPlacemarkProps {
  geoObject: RouterType;
}

export const RouterPointPlacemark = (props: DeliveryPointPlacemarkProps) => {
  const { geoObject } = props;

  const handleEditRouterPoint = (id: string) => {
    window.open(`/task/edit/${id}`);
  };

  const PlacemarkCore = React.memo(function Y({ ymaps }: any) {
    const makeLayout = (layoutFactory: any) => {
      const Layout = layoutFactory.createClass(RouterPointBallon(geoObject), {
        build: function () {
          Layout.superclass.build.call(this);
          this.element = document.querySelector('.map__router-point-balloon');
          this.element = this.element
            .querySelector('#router-point-balloon_id_' + geoObject.taskId)
            .addEventListener('click', this.myClick);
        },

        myClick: () => handleEditRouterPoint(geoObject.taskId),
      });
      return Layout;
    };

    return (
      <Placemark
        geometry={[geoObject.longitude, geoObject.latitude]}
        options={{
          balloonContentLayout: makeLayout(ymaps.templateLayoutFactory),
          balloonPanelMaxMapArea: 0,
          iconLayout: 'default#image',
          iconImageHref: routerIcon,
          iconImageSize: [24, 24],
          iconImageOffset: [-12, -23],
        }}
      />
    );
  });

  const RouterPointPlacemark = React.useMemo(() => {
    return withYMaps(PlacemarkCore, true, ['geoObject.addon.balloon', 'templateLayoutFactory']);
  }, [PlacemarkCore]);

  return (
    <>
      <RouterPointPlacemark />
    </>
  );
};
