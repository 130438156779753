import { HouseLayoutDto, HouseSchemaType } from '@/dto/taskmap/Dto';
import { color } from '@/styles/mixins';
import CheckCircleOutlineRoundedIcon from '@mui/icons-material/CheckCircleOutlineRounded';
import ErrorOutlineRoundedIcon from '@mui/icons-material/ErrorOutlineRounded';
import ComputerIcon from '@mui/icons-material/Computer';
import PersonRoundedIcon from '@mui/icons-material/PersonRounded';
import { usePNRStore } from '../store';
import { useMemo } from 'react';
import { KEYS } from '@/hooks/useQuery/Keys';
import { useIsMutating } from 'react-query';
import shallow from 'zustand/shallow';

// Фронтовские статусы
export enum IncorrectHouseShemaType {
  NO_HOUSE_ENTRANCE_DATA = 'NO_HOUSE_ENTRANCE_DATA',
  PENDING_ENTRANCE_DATA = 'PENDING_ENTRANCE_DATA',
}

// Конфигурация для каждого типа схемы
export const schemaTypeConfig = {
  [HouseSchemaType.TWO_GIS_INCORRECT]: {
    tooltip:
      'Внимание! Данные с картографических сервисов могут быть неточными. Рекомендуется проверить информацию',
    icon: <ErrorOutlineRoundedIcon color='warning' />,
    border: `2px solid ${color('muiWarning')}`,
  },
  [HouseSchemaType.TWO_GIS]: {
    tooltip: 'Всё в порядке. Данные с картографических сервисов проверены и актуальны',
    icon: <CheckCircleOutlineRoundedIcon color='success' />,
    border: `2px solid ${color('muiSuccess')}`,
  },
  [HouseSchemaType.CUSTOM]: {
    tooltip: 'Схема дома отредактирована пользователем',
    icon: <PersonRoundedIcon color='success' />,
    border: `2px solid ${color('muiSuccess')}`,
  },
  [HouseSchemaType.DEFAULT]: {
    tooltip: 'Схема дома сгенерирована автоматически',
    icon: <ComputerIcon color='info' />,
    border: `2px solid ${color('muiBlue')}`,
  },
  [IncorrectHouseShemaType.NO_HOUSE_ENTRANCE_DATA]: {
    tooltip: 'Внимание! Для работы с схемой и планировщиками необходимо заполнить данные по дому',
    icon: <ErrorOutlineRoundedIcon color='error' />,
    border: `2px solid ${color('muiError')}`,
  },
  [IncorrectHouseShemaType.PENDING_ENTRANCE_DATA]: {
    tooltip: '',
    icon: <ErrorOutlineRoundedIcon color='disabled' />,
    border: `2px solid ${color('gray')}`,
  },
};

export const useShemaTypeView = () => {
  const isIncorrectHouseShema = useIsIncorrectHouseShema();
  const schemaType = usePNRStore((state) => state.backendLayoutData)?.schemaType;
  const isFetchingHouseData = !!useIsMutating(KEYS.pnrHouseData);

  const shemaTypeView = useMemo(() => {
    if (isFetchingHouseData) {
      return schemaTypeConfig[IncorrectHouseShemaType.PENDING_ENTRANCE_DATA];
    }
    if (isIncorrectHouseShema) {
      return schemaTypeConfig[IncorrectHouseShemaType.NO_HOUSE_ENTRANCE_DATA];
    }
    if (schemaType && schemaTypeConfig[schemaType]) {
      return schemaTypeConfig[schemaType];
    } else {
      return schemaTypeConfig[HouseSchemaType.DEFAULT];
    }
  }, [schemaType, isIncorrectHouseShema, isFetchingHouseData]);

  return shemaTypeView;
};

export const useIsIncorrectHouseShema = () => {
  const flatCount = usePNRStore((state) => state.flatCount);
  const floorCount = usePNRStore((state) => state.floorCount);
  const entranceCount = usePNRStore((state) => state.entranceCount);

  const isIncorrectHouseShema = useMemo(
    () => !flatCount || !floorCount || !entranceCount,
    [flatCount, floorCount, entranceCount]
  );

  return isIncorrectHouseShema;
};

export const useLayoutData = () => {
  const { setSelectedIndicator, setLayoutDataFromBackend, setCurrentEntranceLayout } = usePNRStore(
    (state) => ({
      setSelectedIndicator: state.setSelectedIndicator,
      setLayoutDataFromBackend: state.setLayoutDataFromBackend,
      setCurrentEntranceLayout: state.setCurrentEntranceLayout,
    }),
    shallow
  );
  const processLayoutData = (data: HouseLayoutDto) => {
    setLayoutDataFromBackend(data);
    let selectIndicator = -1;
    for (let i = 0; i < data?.entrances.length; i++) {
      const entrance = data?.entrances[i];
      if (entrance.number >= 1) {
        selectIndicator = entrance.number;
        break;
      }
    }
    setSelectedIndicator(selectIndicator);
    const selectedEntrance = data?.entrances.find((it) => it.number === selectIndicator);
    if (selectedEntrance) {
      setCurrentEntranceLayout(selectedEntrance);
    }
  };

  return { processLayoutData };
};
