import { allBreakpointValue, mediaBreakpointUp } from '@/styles/mixins';
import { styled } from '@mui/material';

interface NavbarLinksWrapper {
  isMobile?: boolean;
}

export const NavbarLinksWrapper = styled('div')<NavbarLinksWrapper>`
  display: flex;
  justify-content: flex-start;
  flex: 1 1 auto;
  align-items: start;
  flex-direction: column;
  padding: 10px;
  width: 80vw;
  flex-wrap: wrap;

  ${allBreakpointValue('gap', 36, 12, 4, 8)}

  ${mediaBreakpointUp('xl')} {
    justify-content: center;
    flex-direction: row;
    align-items: center;
  }
`;
