import * as React from 'react';
import Dialog from '@mui/material/Dialog';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import Paper, { PaperProps } from '@mui/material/Paper';
import Draggable from 'react-draggable';
import { SxProps } from '@mui/material';

function PaperComponent(props: PaperProps) {
  return (
    <Draggable
      handle='#draggable-dialog-title'
      cancel={'[class*="MuiDialogContent-root"]'}
    >
      <Paper {...props} />
    </Draggable>
  );
}
export interface IDraggablePopup {
  onSuccess?: () => void;
  isOpen: boolean;
  onClose: () => void;
  children?: any;
  customStyle?: SxProps;
  backdropStyles?: SxProps;
  textToGrabToDrag?: string;
  maxWidth?: 'xl' | 'md' | 'sm' | 'xs' | 'lg' | false;
  customTitleStyle?: any;
}

export default function DraggablePopup({
  isOpen,
  onClose,
  children,
  textToGrabToDrag = '',
  customStyle = {},
  maxWidth = 'md',
  customTitleStyle = {},
}: IDraggablePopup) {
  return (
    <React.Fragment>
      <Dialog
        fullWidth={true}
        maxWidth={maxWidth}
        open={isOpen}
        onClose={onClose}
        PaperComponent={PaperComponent}
        aria-labelledby='draggable-dialog-title'
      >
        <DialogTitle
          style={{ ...customTitleStyle, cursor: 'move' }}
          id='draggable-dialog-title'
        >
          {textToGrabToDrag}
        </DialogTitle>

        <DialogContent sx={{ ...customStyle }}>
          <>{children}</>
        </DialogContent>
      </Dialog>
    </React.Fragment>
  );
}
