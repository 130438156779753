import { FC, ReactNode, useEffect, useState } from 'react';
import { BoxShadow } from '@/components/ui/BoxShadow';
import { Grid, Stack, Typography } from '@mui/material';
import { TextField } from '@/components/fixUI';
import { ExpandButton } from '@/components/button/ExpandButton/ExpandButton';
import { ModeViewerResult } from '@/components/features/taskEditor/types';
import { useGalleryData } from '@/components/Gallery/store';
import { TASK_EDITOR_BIG_PREVIEW } from '../../item/TaskEditorItem.styled';
import { Letter } from '@/components/features/taskEditor/adpu/mailNotifications/Letter';
import { useForm } from 'react-hook-form';
import { useParams } from 'react-router-dom';
import { useActionLog } from '@/hooks/ActionLogHook';
import { getMailNotification } from './api/MailNotificationService';
import { MailNotificationResponse } from './api/dto/MailNotificationDtoResponse';

interface IMailNotification {
  mode?: ModeViewerResult;
}

export const MailNotification: FC<IMailNotification> = ({ mode = ModeViewerResult.LARGE }) => {
  const { fetchCatch } = useActionLog();
  const { register, setValue } = useForm();
  const { taskId } = useParams();
  const { setImageGroups } = useGalleryData();
  const [mailNotification, setMailNotification] = useState<MailNotificationResponse>();

  useEffect(() => {
    taskId &&
      getMailNotification(taskId)
        .then((res) => {
          setMailNotification(res.data);
        })
        .catch((res) => {
          fetchCatch(res, 'Ошибка загрузки почтовых уведомлений');
        });
  }, [taskId]);

  const [innerMode, setInnerMode] = useState<ModeViewerResult>(mode);
  const handleMode = () => {
    setInnerMode(
      innerMode === ModeViewerResult.SMALL ? ModeViewerResult.LARGE : ModeViewerResult.SMALL
    );
  };

  const handleDoubleClick = (e: any) => {
    e.stopPropagation();
    mailNotification?.letterResponseList &&
      setImageGroups(
        mailNotification.letterResponseList.map((elem) => ({
          title: `Письмо №${elem.postalNumber}`,
          imageList: elem.imageList ?? [],
        })) ?? []
      );
  };

  const getPreview = (): ReactNode => {
    return (
      <Grid
        container
        item
        spacing={1}
      >
        <Grid
          item
          xs={6}
        >
          <Typography>Почтовые уведомления</Typography>
        </Grid>
        <Grid {...TASK_EDITOR_BIG_PREVIEW.lastItem}>
          <Stack
            direction={'row'}
            justifyContent='flex-end'
          >
            <ExpandButton
              onAction={handleMode}
              sx={{ marginLeft: 1 }}
            />
          </Stack>
        </Grid>
      </Grid>
    );
  };

  return (
    <Grid
      item
      xs={12}
    >
      <BoxShadow onDoubleClick={handleDoubleClick}>
        {innerMode === ModeViewerResult.SMALL && <>{getPreview()}</>}
        {(innerMode === ModeViewerResult.MEDIUM || innerMode === ModeViewerResult.LARGE) && (
          <>
            <Grid
              container
              spacing={2}
            >
              <Grid
                item
                xs={12}
              >
                <Stack
                  direction={'row'}
                  sx={{ alignItems: 'center' }}
                >
                  <Typography>Почтовые уведомления</Typography>
                </Stack>
              </Grid>
              <Grid
                item
                xs={12}
              >
                <TextField
                  id='montageName'
                  label='Адрес отправления'
                  variant='standard'
                  {...register(`addressDeparture`, {
                    value: mailNotification?.addressDeparture,
                  })}
                  disabled
                  sx={{ width: '100%' }}
                />
              </Grid>
            </Grid>
            <Grid
              mt={2}
              container
              spacing={2}
            >
              {mailNotification?.letterResponseList &&
                mailNotification.letterResponseList?.map((e) => (
                  <Letter
                    {...e}
                    key={e.id}
                  />
                ))}
            </Grid>
            <Stack
              direction={'row'}
              sx={{ alignItems: 'center', flexWrap: 'wrap' }}
            >
              <Grid sx={{ ml: 'auto' }}></Grid>
              <Grid>
                <ExpandButton
                  expand={innerMode === ModeViewerResult.LARGE}
                  onAction={handleMode}
                />
              </Grid>
            </Stack>
          </>
        )}
      </BoxShadow>
    </Grid>
  );
};
