import { IconButton, Popover, Switch } from '@mui/material';
import TuneIcon from '@mui/icons-material/Tune';
import { ShowInfoControllLabel } from '../InvestStatistic.styled';
import { useState } from 'react';
import { useInvestStatisticStore } from '../../store';
import shallow from 'zustand/shallow';

interface SwitchOptionsPopoverProps {
  showTechDesicion?: boolean;
}

export const SwitchOptionsPopover = ({ showTechDesicion }: SwitchOptionsPopoverProps) => {
  const [anchorEl, setAnchorEl] = useState<HTMLButtonElement | null>(null);
  const handleClose = () => {
    setAnchorEl(null);
  };

  const {
    displayManufacturer,
    setDisplayManufacturer,
    displayTechDesicion,
    setDisplayTechDesicion,
  } = useInvestStatisticStore(
    (state) => ({
      displayManufacturer: state.displayManufacturer,
      setDisplayManufacturer: state.setDisplayManufacturer,
      displayTechDesicion: state.displayTechDesicion,
      setDisplayTechDesicion: state.setDisplayTechDesicion,
    }),
    shallow
  );

  return (
    <>
      <IconButton onClick={(event) => setAnchorEl(event.currentTarget)}>
        <TuneIcon />
      </IconButton>
      <Popover
        open={Boolean(anchorEl)}
        anchorEl={anchorEl}
        onClose={handleClose}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'right',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'right',
        }}
        sx={{ marginTop: '12px' }}
      >
        <div style={{ display: 'flex', flexDirection: 'column' }}>
          <ShowInfoControllLabel
            active={displayManufacturer.nartis}
            control={
              <Switch
                checked={displayManufacturer.nartis}
                onChange={(e) =>
                  setDisplayManufacturer((prev) => ({ ...prev, nartis: e.target.checked }))
                }
              />
            }
            label={'установлено нартис'}
          />
          <ShowInfoControllLabel
            active={displayManufacturer.lenenergo}
            control={
              <Switch
                checked={displayManufacturer.lenenergo}
                onChange={(e) =>
                  setDisplayManufacturer((prev) => ({ ...prev, lenenergo: e.target.checked }))
                }
              />
            }
            label={'установлено ленэнерго'}
          />
          {showTechDesicion && (
            <ShowInfoControllLabel
              active={displayTechDesicion}
              control={
                <Switch
                  checked={displayTechDesicion}
                  onChange={(e) => setDisplayTechDesicion(e.target.checked)}
                />
              }
              label={'показывать тех решения'}
            />
          )}
        </div>
      </Popover>
    </>
  );
};
