import { Autocomplete, TextField } from '@mui/material';
import React, { useEffect, useMemo, useState } from 'react';
import 'react-datepicker/dist/react-datepicker.css';
import moment from 'moment';
import ru from 'date-fns/locale/ru';
import { Controller, useForm } from 'react-hook-form';
import { useCatalog } from '@/hooks/CatalogHook';
import DatePicker from 'react-datepicker';
import styles from './Popup.module.scss';
import { ApiAssignRequest } from '@/services/YodaRestService/YodaRestService';
import { LoadingButton } from '@mui/lab';

import { ActionLogType, useActionLog } from '@/hooks/ActionLogHook';
import { IPopup, Popup } from './Popup';
import { taskMapToArray } from '@/components/features/tasksBrowse/utils';

interface Props extends IPopup {
  taskIds: string[];
}

export const AssignExecAndDatePopup: React.FC<Props> = (props) => {
  const { taskIds, onClose, onSuccess } = props;
  const { control, reset, handleSubmit, watch } = useForm();
  const [isLoading, setIsLoading] = useState(false);

  const { taskGroupMap, getGroupCatalog } = useCatalog((state) => ({
    getGroupCatalog: state.getGroupCatalog,
    taskGroupMap: state.taskGroupMap,
  }));
  const contractOptions = useMemo(() => taskMapToArray(getGroupCatalog()), [taskGroupMap]);

  const { fetchCatch, addActionLog } = useActionLog();

  useEffect(() => {
    reset();
  }, [props.isOpen]);

  const isValid = watch('executorId') && watch('plannedDate') && !isLoading;
  const onSubmit = handleSubmit((values) => {
    setIsLoading(true);
    const fixedValues = {
      ...values,
      plannedDate: values.plannedDate ? moment(values.plannedDate).format('YYYY-MM-DD') : '',
    };
    ApiAssignRequest({
      taskIds,
      ...(fixedValues as any),
    })
      .then(() => {
        onClose();
        addActionLog(ActionLogType.SUCCESS, 'Назначение бригады и плановой даты прошло успешно');
        onSuccess && onSuccess();
      })
      .catch((error) => {
        fetchCatch(error);
      })
      .finally(() => setIsLoading(false));
  });

  return (
    <Popup
      {...props}
      customStyle={{ overflow: 'visible' }}
    >
      <div className={styles.item}>
        <span>Бригада</span>
        <Controller
          control={control}
          name='executorId'
          render={({ field }) => (
            <Autocomplete
              style={{ width: '37%' }}
              options={contractOptions}
              value={{ value: field.value ?? '', label: getGroupCatalog()?.get(field.value) ?? '' }}
              onChange={(event: any, newValue: any) => {
                if (!newValue) return;
                field?.onChange(newValue.value);
              }}
              renderInput={(params) => (
                <TextField
                  name='executorId'
                  {...params}
                  label={'Бригада'}
                  variant='standard'
                />
              )}
            />
          )}
        />
      </div>
      <div className={styles.item}>
        <span>Плановая дата</span>
        <div>
          <Controller
            control={control}
            name='plannedDate'
            render={({ field }) => (
              <DatePicker
                dateFormat='dd.MM.yyyy'
                locale={ru}
                className={styles.input}
                selected={field.value}
                onChange={field.onChange}
              />
            )}
          />
        </div>
      </div>
      <div className={styles['modal-buttons']}>
        <LoadingButton
          loading={isLoading}
          disabled={!isValid}
          onClick={onSubmit}
          variant='contained'
        >
          Назначить
        </LoadingButton>
      </div>
    </Popup>
  );
};
