import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Grid,
  Typography,
} from '@mui/material';
import { useEffect, useState } from 'react';
import { ReplacementResponse } from '@/dto/taskmap/task/TaskResultDto';
import { ActionLogType, useActionLog } from '@/hooks/ActionLogHook';
import { useScreenHoldHook } from '@/hooks/ScreenHoldHook';
import { toInstalledIncorrect } from '@/services/ReplacementService/ReplacementService';

interface ReplacementIncorectProps {
  replacementId: string;
  openDialog: boolean;
  closeDialog(): void;
  handleChangeReplacement(replacementResponse: ReplacementResponse): void;
}

export const ReplacementIncorrect = (props: ReplacementIncorectProps) => {
  const { openDialog, closeDialog, replacementId, handleChangeReplacement } = props;
  const [open, setOpen] = useState(false);
  const { setIsInProgress } = useScreenHoldHook();
  const { addActionLog, fetchCatch } = useActionLog();

  useEffect(() => {
    setOpen(openDialog);
  }, [openDialog]);

  const handleClose = () => {
    setOpen(false);
    closeDialog();
  };

  const handleAction = (reason: string) => {
    if (replacementId && reason) {
      setIsInProgress(true);
      toInstalledIncorrect(replacementId, reason)
        .then((res) => {
          handleChangeReplacement(res.data);
          addActionLog(ActionLogType.SUCCESS, 'Данные по монтажу ПУ скорректированы');
        })
        .catch((err) => fetchCatch(err, 'Ошибка при корректировки двнных по монтажу'))
        .finally(() => setIsInProgress(false));
    }
    closeDialog();
  };

  return (
    <Dialog
      open={open}
      onClose={handleClose}
      aria-labelledby='incorrect-dialog-title'
      aria-describedby='incorrect-dialog-description'
      maxWidth={'lg'}
    >
      <DialogTitle id='incorrect-dialog-title'>Некорректно установленное ПУ</DialogTitle>
      <DialogContent>
        <Typography>
          Если прибор смонтирован у Вас, выбирите причину или нажмите 'отмена'
        </Typography>
      </DialogContent>
      <DialogActions>
        <Grid
          container
          direction='row'
          justifyContent='space-between'
          alignItems='center'
        >
          <Grid item>
            <Button
              onClick={handleClose}
              size='small'
            >
              Отмена
            </Button>
          </Grid>
          <Grid item>
            <Button
              onClick={() => handleAction('guarantee')}
              size='small'
            >
              Демонтирован по гарантии
            </Button>
          </Grid>
          <Grid item>
            <Button
              onClick={() => handleAction('address-incorrect')}
              size='small'
            >
              Предыдущий адрес не верный
            </Button>
          </Grid>
        </Grid>
      </DialogActions>
    </Dialog>
  );
};
