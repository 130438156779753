import { ImageListItem, Stack, Typography } from '@mui/material';
import { useEffect, useState } from 'react';
import { getImageUrl, getUrlHeaders } from '../../NewGallery/utils';
import { IImage } from '@/dto/taskmap/task/TaskResultDto';
import DownloadSingleFile from '@/components/button/DownloadSingleFile/DownloadSingleFile';

const ComplaintImage = ({ item }: { item: IImage }) => {
  const [contentType, setContentType] = useState('');

  useEffect(() => {
    if (item?.url) {
      // Выполняем HEAD-запрос, чтобы получить заголовки без загрузки всего файла
      getUrlHeaders(item.url)
        .then((response) => {
          const type = response.headers['content-type'];
          setContentType(type);
        })
        .catch((err) => {
          console.error('HEAD request error:', err);
        });
    }
  }, [item?.url]);

  return (
    <Stack
      key={item?.id}
      p={1}
    >
      <ImageListItem>
        <img
          src={getImageUrl(item?.url, contentType)}
          srcSet={getImageUrl(item?.url, contentType)}
          alt={item?.typeList?.at(0) ?? 'неизвестно'}
          loading='lazy'
          height='150'
          style={{ minHeight: 130 }}
        />
      </ImageListItem>
      <Stack
        flexDirection='row'
        justifyContent='space-between'
        alignItems='center'
      >
        <Typography
          textAlign='center'
          variant='subtitle2'
        >
          {`${item?.typeList?.slice(0, 12) ?? 'неизвестно'}`}
        </Typography>
        <DownloadSingleFile url={item?.url} />
      </Stack>
    </Stack>
  );
};
export default ComplaintImage;
