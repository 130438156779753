import React, { FC } from 'react';
import { Grid } from '@mui/material';
import { GSMItemNew, GSMItemNewProps } from '@/components/GSM/GSMItemNew';

interface IGSMBlockNew {
  data: GSMItemNewProps[];
}

export const GSMBlockNew: FC<IGSMBlockNew> = ({ data }) => {
  return (
    <>
      {data.map((el) => (
        <Grid
          key={el.id}
          container
          spacing={2}
        >
          <Grid
            item
            xs={6}
          >
            <GSMItemNew {...el} />
          </Grid>
        </Grid>
      ))}
    </>
  );
};
