import CommonModal from '@/components/common/CommonModal/CommonModal';
import { styled } from '@mui/material';
import { MultiSelect } from '@/components/inputs/MultiSelectNew';
import { DataGrid } from '@mui/x-data-grid';

export const OdpuManagementCompanyCatalogWrapper = styled('div')({
  padding: 20,
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'flex-start',
  gap: 10,
  p: {
    marginBottom: 0,
  },
});

export const OdpuManagementCompanyCommonModal = styled(CommonModal)({ minWidth: '40%' });

export const OdpuManagementCompanySelect = styled(MultiSelect)({
  display: 'flex',
});

export const OdpuManagementCompanyDataGrid = styled(DataGrid)({
  '& .MuiDataGrid-cell:focus': {
    outline: 'none',
  },
  '& .MuiDataGrid-cell:focus-within': {
    outline: 'none',
  },

  '& .MuiDataGrid-row': {
    cursor: 'pointer',
  },
});

export const OdpuManagementCompanyFilterForm = styled('form')({
  display: 'flex',
  flexWrap: 'wrap',
  alignItems: 'flex-end',
  position: 'relative',
  rowGap: 10,
});

export const OdpuManagementCompanyForm = styled('form')({
  display: 'flex',
  gap: 20,
  marginTop: 30,
  justifyContent: 'center',
  flexDirection: 'column',
  alignItems: 'center',
});
