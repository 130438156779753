import { Button, Grid, Typography } from '@mui/material';
import React, { useEffect } from 'react';
import styles from '../Popup.module.scss';
import { Popup } from '../Popup';
import { FormProvider, useForm } from 'react-hook-form';
import { IInput, useLockerPopup } from './store';
import { AutocompleteValue } from '../../inputs/AutocompleteValue/AutocompleteValue';
import {
  createLockerPpoOdpu,
  deleteLockerPpoOdpu,
  findAllResultByTaskIdAndType,
  saveLockerPpoOdpu,
} from '@/services/TaskService/TaskService';
import { ActionLogType, useActionLog } from '@/hooks/ActionLogHook';
import { useDisabledAll } from '@/hooks/useAccess';
import { useTaskEditorOnlyShow } from '@/components/features/taskEditor/useTaskEditorOnlyShow';
import { TaskResultType } from '@/dto/taskmap/task/TaskResultDto';

interface ILockerPopup {
  setPpoResponse: (e: any) => void;
  taskId: string;
}

export const LockerPopup: React.FC<ILockerPopup> = ({ setPpoResponse, taskId }) => {
  const onlyShow = useTaskEditorOnlyShow();
  const disabledAll = useDisabledAll();
  const methods = useForm<any>();
  const { setValue, watch } = methods;
  const { fetchCatch, addActionLog } = useActionLog();

  const { isOpen, setIsOpen, inputs, lockerData } = useLockerPopup((state) => ({
    isOpen: state.isLockerPopupOpen,
    setIsOpen: state.setIsLockerPopupOpen,
    inputs: state.inputs,
    lockerData: state.lockerData,
  }));
  const watchCountPu = watch<string>('countPu');
  const watchInput1 = watch('ppoInputRequestList.0.id');
  const watchInput2 = watch('ppoInputRequestList.1.id');

  setValue(`id`, lockerData?.id);
  const inputsForMap: [string, string][] = inputs?.map((e: IInput) => [e.id, `Ввод: ${e.number}`]);
  const map = new Map(inputsForMap);

  const onDelete = () => {
    lockerData?.id &&
      deleteLockerPpoOdpu(lockerData.id)
        .then((res) => {
          setIsOpen(false);
          return findAllResultByTaskIdAndType(taskId, TaskResultType.PPO);
        })
        .then((res) => {
          setPpoResponse(res.data);
          addActionLog(ActionLogType.SUCCESS, 'Шкаф успешно удален');
        })
        .catch((err) => fetchCatch(err, `Ошибка удаления шкафа`));
  };

  const onSave = (data: any) => {
    const tmpData = {
      ...data,
      countPu: Number(data.countPu),
      ppoInputRequestList: data.ppoInputRequestList.filter(
        (el: any) => el.id && el.id != 'undefined'
      ),
    };
    if (lockerData.isNew) {
      createLockerPpoOdpu(tmpData)
        .then((res) => {
          setIsOpen(false);
          return findAllResultByTaskIdAndType(taskId, TaskResultType.PPO);
        })
        .then((res) => {
          setPpoResponse(res.data);
          addActionLog(ActionLogType.SUCCESS, 'Шкаф успешно добавлен');
        })
        .catch((err) => fetchCatch(err, `Ошибка добавления шкафа`));
    } else {
      saveLockerPpoOdpu(taskId, tmpData.id, tmpData)
        .then((res) => {
          setPpoResponse(res.data);
          setIsOpen(false);
          addActionLog(ActionLogType.SUCCESS, 'Сохранено успешно');
        })
        .catch((err) => fetchCatch(err, `Ошибка сохранения шкафа`));
    }
  };

  useEffect(() => {
    if (watchCountPu == 1) setValue(`ppoInputRequestList.1.id`, undefined);
  }, [watchCountPu]);

  return (
    <Popup
      isOpen={isOpen}
      onClose={() => setIsOpen(false)}
    >
      <FormProvider {...methods}>
        <Grid
          container
          spacing={2}
        >
          <Grid
            item
            xs={12}
          >
            <Typography variant='h2'>Шкаф</Typography>
          </Grid>
          <Grid
            item
            xs={4}
          >
            <AutocompleteValue
              title='Количество вводов'
              defaultValue={`${lockerData.puCount}`}
              fieldName={`countPu`}
              values={['1', '2']}
            />
          </Grid>
          <Grid
            item
            xs={4}
          >
            <AutocompleteValue
              title='Ввод 1'
              defaultValue={
                lockerData?.array && lockerData.array[0]?.id
                  ? `${lockerData.array[0].id}`
                  : undefined
              }
              fieldName={`ppoInputRequestList.0.id`}
              values={map}
            />
          </Grid>

          {watchCountPu > 1 && (
            <Grid
              item
              xs={4}
            >
              <AutocompleteValue
                title='Ввод 2'
                defaultValue={
                  lockerData?.array && lockerData.array[1]?.id
                    ? `${lockerData.array[1].id}`
                    : undefined
                }
                fieldName={`ppoInputRequestList.1.id`}
                values={map}
              />
            </Grid>
          )}
          <Grid
            item
            xs={12}
          >
            <div className={styles['modal-buttons']}>
              <Button
                disabled={
                  disabledAll || onlyShow || !watchCountPu || (!watchInput1 && !watchInput2)
                }
                onClick={methods?.handleSubmit(onSave)}
                variant='contained'
              >
                {lockerData.isNew ? 'Создать' : 'Сохранить'}
              </Button>
              <Button
                disabled={
                  lockerData.isNew ||
                  disabledAll ||
                  onlyShow ||
                  !watchCountPu ||
                  (!watchInput1 && !watchInput2)
                }
                onClick={onDelete}
                variant='contained'
              >
                Удалить
              </Button>
            </div>
          </Grid>
        </Grid>
      </FormProvider>
    </Popup>
  );
};
