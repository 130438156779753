import { PnrPlanningModalDateWrapper } from '@/components/blocks/PnrPlanningModalSteps/PnrPlanningModalSteps.styled';
import { FilterArgEnum } from '@/components/filter/MultySelectFilter';
import { CreateHouseOrderResponseDto } from '@/dto/taskmap/Dto';
import { fetchCatch, syncErrorCatch } from '@/hooks/ActionLogHook';
import { useCatalog } from '@/hooks/CatalogHook';
import { CreateHouseOrderPuDto } from '@/services/HouseDataService/dto/HouseDataServiceRequestDto';
import { ApiCreateHousePuOrder } from '@/services/HouseDataService/HouseDataService';
import { DateRange } from '@/components/inputs/DateRange';

import { LoadingButton } from '@mui/lab';
import moment from 'moment';
import React, { useState } from 'react';
import { useForm, FormProvider } from 'react-hook-form';
import { useParams } from 'react-router-dom';
import TaskCreationResult from '../../ui/TaskCreationResult/TaskCreationResult';
import {
  PnrOsOrderModalButtonWrapper,
  PnrOrderCommonModal,
  PnrOrderTitle,
  PnrOrderFormRow,
  PnrOrderAutocompleteValue,
} from '../PnrOrderTab.styled';
import { PnrOrderBaseForm } from '../PnrOsOrder/PnrOsOrderModal';
import PnrPuOrderRows from './PnrPuOrderRows';
import { PuElementRow } from './PnrPuOrder';
import { Button, Tooltip } from '@mui/material';

export interface PnrPuOrderModalProps {
  selectedRowsData: PuElementRow[];
}

const PnrPuOrderModal = ({ selectedRowsData }: PnrPuOrderModalProps) => {
  const { houseDtoId } = useParams();
  const { getCatalogMapWithErr, getContractCatalog, getGroupCatalog } = useCatalog((state) => ({
    getCatalogMapWithErr: state.getCatalogMapWithErr,
    getContractCatalog: state.getContractCatalog,
    getGroupCatalog: state.getGroupCatalog,
  }));
  const contractorIds = getGroupCatalog();
  const contractIdsMap = getContractCatalog('convertIntoMap');

  const methods = useForm<PnrOrderBaseForm>();
  const { handleSubmit, reset } = methods;

  const [isOpen, setIsOpen] = useState(false);
  const [plannedDate, setPlannedDate] = useState<Date | null>(null);
  const [userEditedElements, setUserEditedElements] = useState<PuElementRow[]>([]);
  const [isLoading, setIsLoading] = useState(false);
  const [createdOrdersResponse, setCreatedOrdersResponse] =
    useState<CreateHouseOrderResponseDto | null>(null);

  const onHandlePlannedDate = (newValue: Date[] | null) => {
    if (newValue && newValue[0]) {
      setPlannedDate(newValue[0]);
    }
  };

  const closeModal = () => {
    reset();
    setPlannedDate(null);
    setIsOpen(false);
    setCreatedOrdersResponse(null);
  };

  const onSubmit = (data: PnrOrderBaseForm) => {
    if (!houseDtoId) return;
    if (!userEditedElements.length) {
      syncErrorCatch('Отсутствуют элементы для создания наряда');
      return;
    }
    if (!data.contractId || !data.contractorId || !data.priority || !plannedDate) {
      syncErrorCatch('Необходимо заполнить все поля');
      return;
    }

    const orderData: CreateHouseOrderPuDto[] = userEditedElements.map((smrTask) => ({
      contractId: data.contractId,
      contractorId: data.contractorId,
      plannedDate: moment(plannedDate).format('YYYY-MM-DD'),
      taskPriority: data.priority,
      taskType: 'PU_ORDER',
      taskComment: smrTask.comment,
      parentTaskId: smrTask.id,
    }));

    setIsLoading(true);
    ApiCreateHousePuOrder(houseDtoId, orderData)
      .then(({ data }) => setCreatedOrdersResponse(data))
      .catch(fetchCatch)
      .finally(() => setIsLoading(false));
  };

  const handleOpenModal = () => {
    setIsOpen(true);
    setUserEditedElements(structuredClone(selectedRowsData));
  };

  return (
    <>
      <Tooltip
        title={!selectedRowsData.length ? 'Выберите оборудование для создания наряда' : ''}
        placement='top'
      >
        <PnrOsOrderModalButtonWrapper>
          <Button
            variant='outlined'
            disabled={!selectedRowsData.length}
            onClick={handleOpenModal}
          >
            Создать наряд на ПУ
          </Button>
        </PnrOsOrderModalButtonWrapper>
      </Tooltip>
      <PnrOrderCommonModal
        open={isOpen}
        onClose={closeModal}
      >
        {createdOrdersResponse ? (
          <TaskCreationResult
            closeCallback={closeModal}
            tasksCreationResults={createdOrdersResponse}
            title='В результате успешно созданы заявки:'
          />
        ) : (
          <>
            <PnrOrderTitle>Выберете необходимые параметры для создания наряда</PnrOrderTitle>

            <FormProvider {...methods}>
              <PnrOrderFormRow>
                <PnrOrderAutocompleteValue
                  title='Контракт'
                  fieldName={'contractId'}
                  values={contractIdsMap}
                />

                <PnrOrderAutocompleteValue
                  title='Ответственный подрядчик'
                  fieldName={'contractorId'}
                  values={contractorIds}
                />

                <PnrOrderAutocompleteValue
                  title='Приоритет'
                  fieldName={'priority'}
                  values={getCatalogMapWithErr('taskPriority')}
                />

                <PnrPlanningModalDateWrapper>
                  <DateRange
                    label={'Плановая дата'}
                    onChange={onHandlePlannedDate}
                    value={[plannedDate]}
                    argValue={FilterArgEnum.EQUAL}
                    showSelect={false}
                    showCalenderIcon={true}
                    showClearButton={false}
                    showPalceHolder={false}
                  />
                </PnrPlanningModalDateWrapper>
              </PnrOrderFormRow>

              <PnrPuOrderRows
                userEditedElements={userEditedElements}
                setUserEditedElements={setUserEditedElements}
              />

              <PnrOrderFormRow>
                <LoadingButton
                  loading={isLoading}
                  variant='outlined'
                  onClick={handleSubmit(onSubmit)}
                >
                  Создать наряд на ПУ
                </LoadingButton>
                <Button
                  variant='outlined'
                  onClick={closeModal}
                >
                  Отмена
                </Button>
              </PnrOrderFormRow>
            </FormProvider>
          </>
        )}
      </PnrOrderCommonModal>
    </>
  );
};

export default PnrPuOrderModal;
