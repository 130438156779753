import { Box, Button, Grid, IconButton, Typography } from '@mui/material';
import React, { FC, useEffect, useMemo, useState } from 'react';
import { TextField } from '@/components/fixUI';
import { useFormContext } from 'react-hook-form';
import { FormPU } from './FormPU';
import { FormTT } from './FormTT';
import { DeliveryPointResponse } from '@/dto/taskmap/task/TaskResultDto';
import { FormPUNew } from './FormPUNew';
import { SaveButton } from '@/components/ui/SaveButton/SaveButton';
import { deleteDeliveryPointById, saveDeliveryPointById } from '@/services/TaskService/TaskService';
import { ActionLogType, useActionLog } from '@/hooks/ActionLogHook';
import { AccessPopup } from '@/components/popups/AccessPopup/AccessPopup';
import ImageSearchRoundedIcon from '@mui/icons-material/ImageSearchRounded';
import CopyButton from '@/components/button/CopyButton/CopyButton';
import {
  convertStringToBooleanOrNull,
  getFeedersByDeliveryPointId,
  getParentIdInDeliveryPoints,
  isChangeInput,
  removeDeliveryPoint,
} from './utils';
import { AutocompleteValue } from '@/components/inputs/AutocompleteValue/AutocompleteValue';
import EditLocationRoundedIcon from '@mui/icons-material/EditLocationRounded';
import LocationOffRoundedIcon from '@mui/icons-material/LocationOffRounded';
import BorderColorIcon from '@mui/icons-material/BorderColor';
import EditOffIcon from '@mui/icons-material/EditOff';
import { color } from '@/styles/mixins';
import { usePillar } from '@/components/maps/ppoMap/resCard/pillarStore';
import { IdPopup } from '../panel/IdPopup';
import { FormPUInput } from './FormInput';
import { useCoords } from '@/components/maps/ppoMap/coordsStore';
import { useDeliveryPoints } from '@/components/maps/ppoMap/placemark/delivery-point/store';
import { usePpoMap } from '@/components/maps/ppoMap/store';
import { findAllDeliveryPoint } from '@/components/maps/ppoMap/service';
import { useGallery } from '../../NewGallery/utils';
import { SmrCard } from '@/components/features/taskEditor/deliveryPoint/SMRCard';
import { useScreenHoldHook } from '@/hooks/ScreenHoldHook';

interface DeliveryPointItemProps {
  id: string;
  data: DeliveryPointResponse;
  disabledAll?: boolean;
}

export const DeliveryPointItem: FC<DeliveryPointItemProps> = ({ id, data }) => {
  const { openGallery } = useGallery();
  const [isOpenDel, setIsOpenDel] = useState<boolean>(false);
  const [isLodingDel, setIsLodingDel] = useState<boolean>(false);

  const { fetchCatch, addActionLog } = useActionLog();
  const { clearTmpDeliveryPoint } = useDeliveryPoints();

  const { register, handleSubmit, getValues, setValue, reset } = useFormContext();
  const { setGeoTransStation, tmpTaskId, geoTransStations } = usePpoMap();
  const feeders = getFeedersByDeliveryPointId(geoTransStations, id);
  const { setTmpDeliveryPoint } = useDeliveryPoints();
  const { setIsInProgress } = useScreenHoldHook();

  const onSave = async (formData: any) => {
    const parentId = getParentIdInDeliveryPoints(geoTransStations, data.id);
    if (!id) return;
    try {
      setIsInProgress(true);
      const payload = {
        exPuPlace: data.exPuPlace,
        ...formData[id],
        isChangeNeeded: convertStringToBooleanOrNull(formData[id].isChangeNeeded),
        isIkkExists: convertStringToBooleanOrNull(formData[id].isIkkExists),
        isNeededSeparateInput: convertStringToBooleanOrNull(formData[id].isNeededSeparateInput),
        isReplacementInput: convertStringToBooleanOrNull(formData[id].isReplacementInput),
        isLockerNeeded: convertStringToBooleanOrNull(formData[id].isLockerNeeded),
        newPuType: convertStringToBooleanOrNull(formData[id].newPuType),
      };

      const response = await saveDeliveryPointById(payload, id);

      setTmpDeliveryPoint(id, response.data);

      reset(response.data, {
        keepDefaultValues: true,
        keepDirty: false,
      });

      addActionLog(ActionLogType.SUCCESS, 'Сохранено');

      if (parentId) {
        const { data: updatedData } = await findAllDeliveryPoint(parentId);
        setGeoTransStation({
          geoTransStation: updatedData,
          parentId,
        });
      }
    } catch (err) {
      fetchCatch(err, 'Не сохранено');
    } finally {
      setIsInProgress(false);
    }
  };

  const handleClick = (e: any) => {
    e.stopPropagation();
  };

  const deleteDeliveryPoint = () => {
    setIsLodingDel(true);
    const parentId = getParentIdInDeliveryPoints(geoTransStations, data.id);
    deleteDeliveryPointById(id)
      .then(() => {
        const { newStations, modifiedKeys } = removeDeliveryPoint(geoTransStations, id);
        modifiedKeys.forEach((key) => {
          setGeoTransStation({
            geoTransStation: newStations[key],
            parentId: key,
            isUpdate: true,
          });
        });

        addActionLog(ActionLogType.SUCCESS, 'Точка удалена');
        clearTmpDeliveryPoint();
      })
      .catch((err) => fetchCatch(err, 'Точка не найдена'))
      .finally(() => {
        setIsLodingDel(false);
        setIsOpenDel(false);
      });
  };

  const str = id;

  const onCopy = () => {
    const latitude = getValues(`${str}.latitudeY`);
    const longitude = getValues(`${str}.longitudeX`);
    navigator.clipboard
      .writeText(`${latitude}, ${longitude}`)
      .then(() => addActionLog(ActionLogType.SUCCESS, `Координаты скопированы`))
      .catch((err) => fetchCatch(err, 'Something went wrong'));
  };

  const feederOptions = useMemo(
    () => new Map(feeders?.map((elem) => [elem.id, elem.num])),
    [feeders]
  );

  const onOpenGalleryClick = () => {
    openGallery({ images: data.imageList, title: 'Точка поставки' });
  };

  const { isEditMode, changeMode, latitude, longitude } = useCoords();
  const { isEditPillarMode, changeModePillar, pillar } = usePillar();

  useEffect(() => {
    if (!isEditMode && latitude && longitude) {
      setValue(`${str}.latitudeY`, latitude?.toFixed(6));
      setValue(`${str}.longitudeX`, longitude?.toFixed(6));
    }
  }, [isEditMode]);

  useEffect(() => {
    if (data?.latitudeY && data?.longitudeX) {
      setValue(`${str}.latitudeY`, data?.latitudeY.toFixed(6));
      setValue(`${str}.longitudeX`, data?.longitudeX.toFixed(6));
    }
  }, [data]);

  useEffect(() => {
    setValue(`${str}.ppoComment`, data?.ppoComment);
    setValue(`${str}.isChangeNeeded`, `${data.isChangeNeeded}`);
  }, [data]);

  return (
    <Box
      onDoubleClick={handleClick}
      sx={{ position: 'relative' }}
    >
      <Grid
        container
        spacing={4}
      >
        <Grid
          item
          xs={12}
          sx={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}
        >
          <Typography
            variant={'h6'}
            sx={{ flex: '1 1 0' }}
          >
            Точка Поставки
          </Typography>
          <IconButton
            sx={{ mr: 2 }}
            disabled={!data.imageList || data.imageList.length === 0}
            onClick={onOpenGalleryClick}
          >
            <ImageSearchRoundedIcon htmlColor={color('muiBlue')} />
          </IconButton>
          <IdPopup>
            <div>
              <CopyButton textToCopy={data.id} />
              id Точки Поставки: {data.id}
            </div>
          </IdPopup>
        </Grid>
        <Grid
          item
          xs={5}
        >
          <TextField
            id='latitudeY'
            label='Широта'
            variant='standard'
            sx={{ width: '100%' }}
            {...register(`${str}.latitudeY`, {
              value: data?.latitudeY,
            })}
          />
        </Grid>
        <Grid
          item
          xs={5}
        >
          <TextField
            id='longitudeX'
            label='Долгота'
            variant='standard'
            sx={{ width: '100%' }}
            {...register(`${str}.longitudeX`, {
              value: data?.longitudeX,
            })}
          />
        </Grid>
        <Grid
          item
          xs={2}
        >
          <IconButton onClick={() => changeMode()}>
            {isEditMode ? (
              <LocationOffRoundedIcon htmlColor={color('muiBlue')} />
            ) : (
              <EditLocationRoundedIcon htmlColor={color('muiBlue')} />
            )}
          </IconButton>
        </Grid>
        <Grid
          item
          xs={6}
        >
          <AutocompleteValue
            title='Фидер 0,4 кВ'
            sx={{ width: '100%' }}
            defaultValue={data?.feeder?.id}
            fieldName={`${str}.feeder.id`}
            values={feederOptions}
          />
        </Grid>
        <Grid
          item
          xs={4}
        >
          <TextField
            id='trns-number'
            label='Опора'
            variant='standard'
            disabled
            sx={{ width: '100%' }}
            value={data?.pillar?.number}
          />
        </Grid>
        <Grid
          item
          xs={2}
        >
          <IconButton onClick={() => changeModePillar()}>
            {isEditPillarMode ? (
              <BorderColorIcon htmlColor={color('muiBlue')} />
            ) : (
              <EditOffIcon htmlColor={color('muiBlue')} />
            )}
          </IconButton>
        </Grid>
        <FormPU
          keyStr={str}
          meter={data}
          // disabledAll
        />
        <FormPUNew
          keyStr={str}
          meter={data}
          // disabledAll
        />
        <FormPUInput
          keyStr={str}
          meter={data}
          // disabledAll
        />

        <Grid
          item
          xs={12}
        />

        <FormTT
          keyStr={`${str}.ppoStolbiTransformator`}
          meter={data?.ppoStolbiTransformator}
          // disabledAll
        />
        <Grid
          item
          xs={12}
        >
          <TextField
            id='comment'
            label='Комментарий'
            maxRows={3}
            minRows={3}
            multiline
            variant='outlined'
            sx={{ width: '100%' }}
            {...register(`${str}.ppoComment`, {
              value: data?.ppoComment,
            })}
            InputLabelProps={{ shrink: true }}
          />
        </Grid>
        <Grid
          item
          xs={6}
        >
          <AutocompleteValue
            title='На данной точке монтаж планируется'
            sx={{ width: '100%' }}
            fieldName={`${str}.isChangeNeeded`}
            defaultValue={`${data.isChangeNeeded}`}
            values={isChangeInput}
            disabled={!!data.smrTaskId}
          />
        </Grid>

        <Grid
          item
          xs={12}
        >
          <SmrCard
            id={id}
            data={data}
          />
        </Grid>

        <Grid
          item
          xs={12}
          sx={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}
        >
          <Button onClick={() => setIsOpenDel(true)}>Удалить точку поставки</Button>
          <SaveButton onSave={handleSubmit(onSave)} />
        </Grid>
      </Grid>
      <AccessPopup
        isOpen={isOpenDel}
        isLoading={isLodingDel}
        onClose={() => setIsOpenDel(false)}
        onSuccess={deleteDeliveryPoint}
        headerText={'ВНИМАНИЕ! УДАЛЕНИЕ ТОЧКИ ПОСТАВКИ'}
        successText={'удалить точку поставки'}
        btnVariant={'text'}
        titleText={
          'ВЫ УВЕРЕНЫ ЧТО ХОТИТЕ УДАЛИТЬ ЭТУ ТОЧКУ ПОСТАВКИ? ВСЕ СВЯЗАНЫЕ ДАННЫЕ, ВКЛЮЧАЯ УЖЕ ПРОВЕДЕННЫЕ МОНТАЖИ, БУДУТ УДАЛЕНЫ БЕЗВОЗВРАТНО. ПОЖАЛУЙСТА УБЕДИТЕСЬ В НЕОБХОДИМОСТИ ЭТОГО ДЕЙСТВИЯ.'
        }
      />
    </Box>
  );
};
