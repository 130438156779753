import { coorfinateToGeoBounds } from '../utils/GeoUtils';
import { createStore } from '@/utils/createStore';
import { findAllByZoningByFilter } from '@/services/ZoningService/ZoningService';
import { GeoTask } from '@/dto/taskmap/Dto';
import {
  CoveredItem,
  GeoBoundsNew,
  ZoningBoundResponse,
  ZoningResponse,
} from '@/services/ZoningService/dto/ZoningServiceResponseDto';
import { ZoningTaskFilter } from '@/services/ZoningService/dto/ZoningServiceRequestDto';

export interface ViewMode {
  total: boolean;
  covered: boolean;
  notCovered: boolean;
}

export interface SearchStore {
  loading: false;
  filter?: ZoningTaskFilter;
  geoBounds?: GeoBoundsNew;
  zoningResponse?: ZoningResponse;
  zoningBoundResponse?: ZoningBoundResponse[];
  isAutoUpdate: boolean;
  viewMode: ViewMode;
  serch: (errorHandler: (message: string, err: any) => void) => void;
  setZoningResponse: (zoningResponse?: ZoningResponse) => void;
  setZoningBoundResponse: (zoningboundResponse?: ZoningBoundResponse[]) => void;
  setFilter: (filter?: ZoningTaskFilter) => void;
  setGeoBounds: (coords: number[][]) => void;
  setAutoUpdate: (zoomValue: number) => void;
  setViewMode: (viewMode: ViewMode) => void;
  getFilter: () => ZoningTaskFilter | undefined;
  getCount: () => number;
  getCoveredItemsForAssign: (groupIds: string[]) => CoveredItem[];
  getDuplicate: (coveredItem: CoveredItem[]) => Set<string>;
  getUniqueAssignableList: () => GeoTask[];
}

export const useSearch = createStore<SearchStore>(
  (set: any, get: any) => ({
    loading: false,
    isAutoUpdate: false,
    viewMode: { total: true, covered: true, notCovered: true },

    serch: (errorHandler) => {
      get().setZoningResponse(undefined);
      const filter = get().getFilter();
      if (filter) {
        set(() => ({ loading: true }));
        findAllByZoningByFilter(filter)
          .then(({ data }) => get().setZoningResponse(data))
          .catch((err) => errorHandler('Ошибка при поиске', err))
          .finally(() => set(() => ({ loading: false })));
      }
    },

    setFilter: (filter?: ZoningTaskFilter) => {
      set({ filter: filter });
      set({ taskAssignmentStatistic: undefined });
    },

    setGeoBounds: (coords: number[][]) => {
      set({ geoBounds: coorfinateToGeoBounds(coords) });
    },

    setAutoUpdate: (zoomValue: number) => {
      set({ isAutoUpdate: zoomValue > 14 });
    },

    setViewMode: (viewMode: ViewMode) => {
      set({ viewMode: viewMode });
    },

    getFilter: () => {
      const filter = get().filter;
      if (!filter) {
        return undefined;
      }
      const isObjectEmpty: boolean = filter && Object.keys(filter || '').length == 0;

      if (isObjectEmpty) {
        return undefined;
      }
      const isObjectValueEmpty = Object.values(filter).filter((t) => t && t != null).length == 0;

      return !isObjectValueEmpty ? filter : undefined;
    },

    setZoningResponse: (zoningResponse?: ZoningResponse) => {
      set({ zoningResponse: zoningResponse });
    },

    setZoningBoundResponse: (zoningBoundResponse?: ZoningBoundResponse[]) => {
      set({ zoningBoundResponse: zoningBoundResponse });
    },

    getCount: () => {
      const zoningBoundResponse: ZoningBoundResponse[] = get().zoningBoundResponse;
      const unique = zoningBoundResponse
        ?.flatMap((t) => t.geoTaskList)
        .filter((value, index, self) => index === self.findIndex((t) => t.taskId == value.taskId));
      return unique?.length || 0;
    },

    getCoveredItemsForAssign: (groupIds: string[]) => {
      const coveredItemList: CoveredItem[] = get().zoningResponse.coveredItemList;
      if (!coveredItemList) {
        return [];
      }
      const assignableList: CoveredItem[] = coveredItemList.filter(
        (t) => groupIds?.includes(t.groupId) && t.assignableList?.length > 0
      );

      return assignableList;
    },

    getDuplicate: (coveredItem: CoveredItem[]) => {
      const duplicateGoupIdSet = new Set<string>();
      for (let i = 1; i < coveredItem.length; i++) {
        for (const source of coveredItem.slice(i, coveredItem.length)) {
          const target = coveredItem[i - 1];
          const arr = [...target.assignableList].filter((t) =>
            source.assignableList.map((tt) => tt.taskId).includes(t.taskId)
          );
          if (arr.length > 0) {
            duplicateGoupIdSet.add(source.groupId);
            duplicateGoupIdSet.add(target.groupId);
          }
        }
      }
      return duplicateGoupIdSet;
    },

    //get unique geoTask with prefer 'reassignable' flag
    getUniqueAssignableList: () => {
      const zoningBoundResponse: ZoningBoundResponse[] = get().zoningBoundResponse;
      const sorted = [
        ...zoningBoundResponse.flatMap((t) => t.geoTaskList).sort((t) => (t.reassignable ? -1 : 1)),
      ];
      return sorted.filter(
        (value, index, self) => index === self.findIndex((t) => t.taskId == value.taskId)
      );
    },
  }),
  'Search'
);
