import React, { useMemo, useState } from 'react';
import { Box, Grid, LinearProgress, TablePagination } from '@mui/material';
import { FilterArgEnum, SelectFilterOptType } from '../../filter/MultySelectFilter';
import { DataGrid, GridEventListener, ruRU } from '@mui/x-data-grid';
import { useMutation } from 'react-query';
import {
  ApiSaveCallCenterTaskComment,
  getCallCenterRequests,
  getCountOfCallCenterRequests,
} from '@/services/CallCenterService/CallCenterService';
import { useActionLog } from '@/hooks/ActionLogHook';
import { ApiFindAllOperators } from '@/services/YodaRestService/YodaRestService';
import '../bashkortostan/CallCenterBashkortostanComponentStyle.css';
import { useCallCenterBrowseFilter } from './store';
import { CallCenterBashkortostanFilter } from './CallCenterBashkortostanFilter';
import { converter } from './utils';
import { getColumn } from './CallCenterBashkortostanComponentHelper';
import { useCatalog } from '@/hooks/CatalogHook';
import { arrowLabel, taskMapToArray } from '@/components/features/tasksBrowse/utils';
import { CallCenterRequestTableItem } from '@/services/CallCenterService/dto/CallCenterServiceResponseDto';
import { CallCenterFilterParam } from '@/services/CallCenterService/dto/CallCenterServiceRequestDto';

function convertUserToFilter(data: any): any {
  return data.map((user: any) => {
    return { value: user.id, label: user.fullName };
  });
}

const COUNT = 10000000;

export const CallCenterBashkortostanComponent = () => {
  const { fetchCatch } = useActionLog();
  const [reloadVar, setReloadVar] = useState(false);
  const reloadThis = () => {
    setReloadVar(!reloadVar);
  };

  const filter = useCallCenterBrowseFilter();

  const [activeFilter, setActiveFilter] = useState<any>({});
  const [isLoadingPag, setIsLoadingPag] = useState<boolean>(true);
  const [page, setPage] = React.useState(0);
  const [pageSize, setPageSize] = React.useState(5);
  const [callRequestCount, setCallRequestCount] = React.useState<number>(COUNT);
  const [operators, setOperators] = React.useState<SelectFilterOptType[]>([]);
  const [callCenterRequests, setCallCenterRequests] = React.useState<CallCenterRequestTableItem[]>(
    []
  );
  const handleRowEditStop: GridEventListener<'rowEditStop'> = (params, event) => {
    event.defaultMuiPrevented = true;
  };

  const { getCatalogMapWithErr, taskGroupMap, getGroupCatalog } = useCatalog((state) => ({
    getCatalogMapWithErr: state.getCatalogMapWithErr,
    taskGroupMap: state.taskGroupMap,
    getGroupCatalog: state.getGroupCatalog,
  }));
  const executors = useMemo(() => taskMapToArray(getGroupCatalog()), [taskGroupMap]);

  const save = (
    taskId: string,
    requestId: string,
    resultJobLog: string,
    planDate?: string,
    executor?: string,
    comment?: string
  ) => {
    ApiSaveCallCenterTaskComment({
      taskId: taskId,
      requestId: requestId,
      comment: comment!,
      planDate: planDate!,
      executorId: executor!,
      resultJobLog: resultJobLog,
    })
      .then(function (value) {
        if (value) {
          mutGetCallCenterBashkortostanRequests.mutate(activeFilter);
        }
      })
      .catch((err) => {
        fetchCatch(err, 'Ошибка при сохранении комментариев');
      });
  };

  const mutGetCallCenterBashkortostanRequests = useMutation<void, any, CallCenterFilterParam>(
    (filter) => {
      const offset = page * pageSize;

      return getCallCenterRequests(filter, offset, pageSize)
        .then((data) => {
          const requests = data.data;
          setCallCenterRequests(requests);
        })
        .catch((error) => {
          fetchCatch(error);
        });
    }
  );

  const updateCount = () => {
    getCountOfCallCenterRequests(activeFilter)
      .then((data) => {
        setCallRequestCount(data.data);
        setIsLoadingPag(false);
      })
      .catch((error) => {
        fetchCatch(error);
      });
  };

  React.useEffect(() => {
    const filterB: CallCenterFilterParam = activeFilter;

    filterB.onlyBashkortostan = true;
    filterB.taskStatus = ['assigned'];
    filterB.taskStatusArg = FilterArgEnum.IN_LIST;
    if (!filterB.operatorArg) {
      filterB.operatorArg = FilterArgEnum.IN_LIST;
    }
    mutGetCallCenterBashkortostanRequests.mutate(filterB);
  }, [page, pageSize, reloadVar, activeFilter]);

  React.useEffect(() => {
    ApiFindAllOperators()
      .then((data) => {
        setOperators(convertUserToFilter(data.data));
      })
      .catch((error) => fetchCatch(error, 'Ошибка получения списка операторов'));
  }, []);

  const onSubmit = () => {
    const fixedValues = converter(filter);
    setActiveFilter(fixedValues);
  };

  return (
    <Box>
      <Grid
        direction='column'
        container
      >
        {mutGetCallCenterBashkortostanRequests.isLoading ? (
          <Box sx={{ width: '100%' }}>
            <LinearProgress />
          </Box>
        ) : (
          <div />
        )}
        <CallCenterBashkortostanFilter
          operators={operators}
          onSubmit={onSubmit}
        />
        <div style={{ height: '650px' }}>
          <DataGrid
            style={{ marginTop: '3vh' }}
            localeText={ruRU.components.MuiDataGrid.defaultProps.localeText}
            rows={callCenterRequests}
            // rowCount={callRequestCount}
            // rowBuffer={pageSize}
            // rowThreshold={pageSize}
            // rowsPerPageOptions={[5, 10, 25, 50]}
            // pagination
            // page={page}
            // pageSize={pageSize}
            // paginationMode='server'
            // onPageChange={(newPage) => setPage(newPage)}
            // onPageSizeChange={(newPageSize) => setPageSize(newPageSize)}
            columns={getColumn({
              refreshParentFunc: reloadThis,
              save: save,
              executors: executors,
              callStatusMap: getCatalogMapWithErr('callStatus'),
            })}
            onRowEditStop={handleRowEditStop}
            components={{
              // Header: Toolbar,
              // Footer: GridFooter,
              Pagination: () => (
                <TablePagination
                  count={callRequestCount}
                  page={page}
                  rowsPerPage={pageSize}
                  onRowsPerPageChange={(newPageSize) =>
                    setPageSize(Number(newPageSize.target.value))
                  }
                  labelDisplayedRows={({ from, to, count }) => (
                    <>
                      {`${from} - ${to} из `}
                      {isLoadingPag ? (
                        <span
                          style={{ cursor: 'pointer' }}
                          onClick={updateCount}
                        >
                          ?
                        </span>
                      ) : (
                        count
                      )}
                    </>
                  )}
                  onPageChange={(ev, newPage) => setPage(newPage)}
                  rowsPerPageOptions={[5, 10, 25, 50]}
                  labelRowsPerPage={'Заявок на странице:'}
                  getItemAriaLabel={arrowLabel}
                />
              ),
            }}
          />
        </div>
      </Grid>
    </Box>
  );
};
