import React, { FC, useState } from 'react';
import { getSMRText } from '@/components/features/taskEditor/deliveryPoint/utils';
import { Button, Collapse, Stack } from '@mui/material';
import { Link } from 'react-router-dom';
import {
  SMRBox,
  SMRCollapse,
  SMRExpandIcon,
  SMRSmallText,
} from '@/components/features/taskEditor/deliveryPoint/index.styled';
import { Loading } from '@/components/ui/Loading';
import { DeliveryPointResponse } from '@/dto/taskmap/task/TaskResultDto';
import { TaskSibling } from '@/dto/taskmap/Dto';
import {
  createTaskByDeliveryPointId,
  findKidsAndParents,
} from '@/services/TaskService/TaskService';
import { ActionLogType, useActionLog } from '@/hooks/ActionLogHook';
import { SmrButton } from '@/components/features/taskEditor/deliveryPoint/SMRButton';

interface ISmrCard {
  id: string;
  data: DeliveryPointResponse;
}

export const SmrCard: FC<ISmrCard> = ({ id, data }) => {
  const { fetchCatch, addActionLog } = useActionLog();
  const [hide, setHide] = useState(true);
  const [smrTaskList, setSmrTaskList] = useState<TaskSibling[] | null>(null);

  const handleHide = () => {
    if (hide) {
      if (data?.smrTaskId) {
        findKidsAndParents(data.smrTaskId)
          .then(({ data }) => setSmrTaskList(data.childTaskList))
          .catch((err) => fetchCatch(err));
      }
    }
    setHide(!hide);
  };

  const createTask = () => {
    createTaskByDeliveryPointId(id)
      .then((res) => {
        addActionLog(ActionLogType.SUCCESS, 'Заявка создана');
      })
      .catch((err) => fetchCatch(err, 'Заявка не создана'));
  };

  return (
    <SMRBox>
      <div>
        СМР:{' '}
        {getSMRText(data.smrTaskId, data.meterChangeIdList, data.tkoIdList, data.isChangeNeeded)}
      </div>

      {data?.smrTaskId || (data?.tkoIdList && data.tkoIdList.length > 0) ? (
        <Button>
          <Link
            to={`/task/edit/${data.smrTaskId}`}
            style={{ textDecoration: 'none', color: 'inherit' }}
            target={'_blank'}
          >
            {'Открыть заявку СМР'}
          </Link>
        </Button>
      ) : (
        <Button onClick={createTask}>{'Создать заявку СМР'}</Button>
      )}
      <div style={{ width: '100%' }}>
        <Collapse in={!hide}>
          <SMRCollapse>
            {!smrTaskList && <Loading loading />}
            {smrTaskList?.length == 0 && <Button disabled>{'Нет связаных заявок'}</Button>}
            {smrTaskList?.map((elem) => (
              <SmrButton
                key={elem.id}
                {...elem}
              />
            ))}
          </SMRCollapse>
        </Collapse>
        <Stack
          direction='row'
          alignItems='center'
          justifyContent='flex-end'
          onClick={handleHide}
        >
          <SMRSmallText hide={hide}>{hide ? 'Связаные заявки' : 'Скрыть заявки'}</SMRSmallText>
          <SMRExpandIcon hide={hide} />
        </Stack>
      </div>
    </SMRBox>
  );
};
