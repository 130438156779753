import React from 'react';
import {
  ImageItemDuplicateInfoRow,
  ImageItemDuplicateInfoWrapper,
  ImageItemDuplicateTaskLink,
} from './ImageItemDuplicateInfo.styled';
import { IDuplicateFileResponseItem } from '@/dto/taskmap/task/TaskResultDto';
import WarningAmberRoundedIcon from '@mui/icons-material/WarningAmberRounded';
import { Divider, Fade, Popper, Typography } from '@mui/material';

export interface ImageItemDuplicateInfoProps extends Partial<IDuplicateFileResponseItem> {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  anchorEl?: any;
  id: string;
  open: boolean;
}

const ImageItemDuplicateInfo = ({
  duplicateFileLinkList,
  id,
  open,
  anchorEl,
}: ImageItemDuplicateInfoProps) => {
  return (
    <Popper
      id={id}
      open={open}
      anchorEl={anchorEl}
      transition
      placement='left'
    >
      {({ TransitionProps }) => (
        <Fade
          {...TransitionProps}
          timeout={350}
        >
          <div>
            <ImageItemDuplicateInfoWrapper>
              <ImageItemDuplicateInfoRow>
                <WarningAmberRoundedIcon color='error' />
                <Typography variant='subtitle2'>
                  Данный файл имеет дубликаты в следующих заявках:
                </Typography>
              </ImageItemDuplicateInfoRow>

              {duplicateFileLinkList?.map((duplicate, index) => (
                <ImageItemDuplicateTaskLink
                  onClick={() => window.open(`/task/edit/${duplicate.taskId}`)}
                  key={index}
                >
                  {duplicate.taskNumber}
                </ImageItemDuplicateTaskLink>
              ))}

              <Divider />
              <Typography variant='subtitle2'>
                Общее количество: {duplicateFileLinkList?.length}
              </Typography>
            </ImageItemDuplicateInfoWrapper>
          </div>
        </Fade>
      )}
    </Popper>
  );
};

export default ImageItemDuplicateInfo;
