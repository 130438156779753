import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Grid,
  Tooltip,
} from '@mui/material';
import React, { FC, useEffect, useState } from 'react';
import { ActionLogType, useActionLog } from '@/hooks/ActionLogHook';
import { useScreenHoldHook } from '@/hooks/ScreenHoldHook';
import DateField from '@/components/DateField';
import { FormProvider, useForm } from 'react-hook-form';
import { TextField } from '@/components/fixUI';
import FileInput from '@/components/inputs/FileInput/FileInput';
import { uploadFile } from '@/services/FileService';
import { CreateRegistryADPU } from '@/services/RegistryADPUService/RegistryADPUService';
import { stringToArray } from '@/utils/stringFunctions';

interface IRegistryADPUPopup {
  openDialog: boolean;
  onClose: () => void;
  updateFunction: () => void;
}

export const FILE_TYPES = [
  'a13321cd-99e4-1494-f2e8-ef8789c2bb8d', // Реестр передачи АДПУ
];

export const RegistryADPUPopup: FC<IRegistryADPUPopup> = ({
  openDialog,
  onClose,
  updateFunction,
}) => {
  const { fetchCatch, addActionLog } = useActionLog();
  const methods = useForm();
  const { register, setValue, handleSubmit, watch } = methods;
  const [open, setOpen] = useState(false);
  const { setIsInProgress } = useScreenHoldHook();

  useEffect(() => {
    setOpen(openDialog);
  }, [openDialog]);

  const unlockSave =
    watch('createRegistryADPU.files')?.length > 0 &&
    watch('createRegistryADPU.date') > 0 &&
    watch('createRegistryADPU.number') != '' &&
    watch('createRegistryADPU.taskList') != '';

  const handleClose = () => {
    setOpen(false);
    onClose();
  };

  const handleSave = (b: any) => {
    setIsInProgress(true);
    CreateRegistryADPU({
      number: b.createRegistryADPU.number,
      signingDate: b.createRegistryADPU.date,
      taskIdList: stringToArray(b.createRegistryADPU.taskList),
    })
      .then(({ data }) => {
        uploadFile(data.id, FILE_TYPES, '', b.createRegistryADPU.files)
          .then(() => {
            updateFunction();
            addActionLog(ActionLogType.SUCCESS, 'АДПУ успешно подписан');
          })
          .catch((res) => {
            fetchCatch(res, 'Ошибка загрузки файла');
          });
      })
      .catch((res) => {
        fetchCatch(res, 'Ошибка подписания АДПУ');
      })
      .finally(() => {
        setIsInProgress(false);
        onClose();
      });
  };

  return (
    <Dialog
      fullWidth
      maxWidth={'md'}
      open={open}
      onClose={handleClose}
      aria-labelledby='cancel-dialog-title'
      aria-describedby='cancel-dialog-description'
    >
      <DialogTitle id='replacement-reason-dialog-title'>Внесите необходимые данные</DialogTitle>
      <DialogContent>
        <FormProvider {...methods}>
          <Grid
            container
            spacing={2}
          >
            <Grid
              item
              xs={6}
            >
              <TextField
                id='montageName'
                label='№ реестра'
                variant='standard'
                multiline
                {...register(`createRegistryADPU.number`, { required: true })}
                sx={{ width: '100%' }}
              />
            </Grid>
            <Grid
              item
              xs={6}
            >
              <DateField
                label={'Дата передачи'}
                required
                hookFormString={`createRegistryADPU.date`}
              />
            </Grid>
            <Grid
              item
              xs={12}
            >
              <TextField
                id='montageName'
                label='id заявок списком'
                variant='standard'
                multiline
                {...register(`createRegistryADPU.taskList`, { required: true })}
                sx={{ width: '100%' }}
              />
            </Grid>
            <Grid
              item
              xs={12}
            >
              <FileInput
                maxFilesAmount={100}
                setFormFiles={setValue}
                register={register('createRegistryADPU.files', { required: true })}
              />
            </Grid>
          </Grid>
        </FormProvider>
      </DialogContent>
      <DialogActions>
        <Button onClick={handleClose}>Отмена</Button>
        {!unlockSave ? (
          <Tooltip title={'Необходимо заполнить обязательные поля'}>
            <span>
              <Button
                onClick={handleSubmit(handleSave)}
                autoFocus
                disabled={!unlockSave}
              >
                Сохранить
              </Button>
            </span>
          </Tooltip>
        ) : (
          <Button
            onClick={handleSubmit(handleSave)}
            autoFocus
            disabled={!unlockSave}
          >
            Сохранить
          </Button>
        )}
      </DialogActions>
    </Dialog>
  );
};
