import React from 'react';
import { PieChart } from 'react-minimal-pie-chart';
import LoadSpinner from '@/components/ui/LoadSpinner/LoadSpinner';
import { Paper, Stack } from '@mui/material';
import { SelectFilterOptType } from '../../../filter/MultySelectFilter';
import { TaskFilterParam } from '@/dto/taskmap/Dto';
import FilterButtons from '@/components/filter/FilterButtons/FilterButtons';
import { getDataFromFilter } from '../../../inputs/MultiSelectNew/utils';
import { generateDetailedInfo } from '../../../button/DetailedInfo/utils';
import { MultiSelect } from '@/components/inputs/MultiSelectNew';
import {
  StatisticsPieChartWrapper,
  StatisticsPieWrapper,
  StatisticsPieLegendItem,
  StatisticsPieLegendRectangle,
  StatisticsPieLegendText,
  StatisticsHeader,
  LegendPieWrapper,
} from './StatisticsPie.styled';
import { useCatalog } from '@/hooks/CatalogHook';
import { AsumbTaskStatisticsType } from '@/services/StatisticsService/AsumbStatisticService/dto/AsumbStatisticServiceResponseDto';
import { MainText } from '../../StatisticMain.styled';
import { useMobileSize } from '@/hooks/useMediaQuery/useMobileSize';

interface StatisticsProps {
  data: AsumbTaskStatisticsType[];
  header: string;
  load: boolean;
  updateFunction: (filter: TaskFilterParam) => void;
  withFilter: boolean;
}

const FILTERS_LABELS = {
  contracts: 'Контракт',
};

const StatisticsPie: React.FC<StatisticsProps> = ({
  data,
  header,
  load,
  updateFunction,
  withFilter,
}) => {
  const { getContractCatalog } = useCatalog((state) => ({
    getContractCatalog: state.getContractCatalog,
  }));
  const contractListResponse = getContractCatalog();
  const [selectedContractList, setSelectedContractList] = React.useState<SelectFilterOptType[]>([]);
  const [contractListArg, setContractListArg] = React.useState(0);

  const filtParam: TaskFilterParam = {};

  const onSubmitSearch = () => {
    if (selectedContractList.length) {
      filtParam.contractIds = getDataFromFilter(selectedContractList, 'value');
      filtParam.contractIdsArg = contractListArg;
    }
    updateFunction(filtParam);
  };

  const onResedClick = () => setSelectedContractList([]);

  const detailedInfo = generateDetailedInfo([selectedContractList, FILTERS_LABELS.contracts]);
  const isMobile = useMobileSize();

  return (
    <Paper elevation={6}>
      <MainText className='MainTextStatistc'>{header}</MainText>
      {withFilter ? (
        <Stack
          direction='row'
          alignItems={isMobile ? 'center' : 'flex-end'}
          position='relative'
          paddingLeft={2}
        >
          <MultiSelect
            label={FILTERS_LABELS.contracts}
            options={contractListResponse}
            value={selectedContractList}
            argValue={contractListArg}
            onChange={(value: SelectFilterOptType[]) => setSelectedContractList(value)}
            onArgChange={(arg: number) => setContractListArg(arg)}
          />

          <FilterButtons
            info={detailedInfo}
            onSearchClick={onSubmitSearch}
            onCloseClick={onResedClick}
          />
        </Stack>
      ) : (
        <div></div>
      )}
      {load ? (
        <LoadSpinner />
      ) : (
        <StatisticsPieWrapper isMobile={isMobile}>
          <StatisticsPieChartWrapper>
            <PieChart data={data} />
          </StatisticsPieChartWrapper>
          <LegendPieWrapper isMobile={isMobile}>
            {data?.map((item) => (
              <StatisticsPieLegendItem key={item.title}>
                <StatisticsPieLegendRectangle style={{ backgroundColor: item.color }}>
                  <StatisticsPieLegendText>{item.value}</StatisticsPieLegendText>
                </StatisticsPieLegendRectangle>
                <StatisticsPieLegendText>{item.title}</StatisticsPieLegendText>
              </StatisticsPieLegendItem>
            ))}
          </LegendPieWrapper>
        </StatisticsPieWrapper>
      )}
    </Paper>
  );
};
export default StatisticsPie;
