import { Button, Paper, styled } from '@mui/material';

export const SMRStatisticsWrapper = styled(Paper)({
  paddingBottom: 20,
  minHeight: '60vh',
});

export const SMRStatisticsFiltersWrapper = styled('div')({
  width: '80vw',
  margin: 'auto',
  position: 'relative',
  display: 'flex',
  justifyContent: 'flex-start',
  alignItems: 'flex-end',
  flexWrap: 'wrap',
  gap: 6,
  marginTop: 16,
});
export const TableWrapper = styled('div')({
  overflowX: 'auto',
  maxWidth: '100%',
});

export const SMRStatisticsCurrentButton = styled(Button)({
  marginLeft: 'auto',
  flexShrink: 0,
});
