import axios from 'axios';
import { saveYodaToken, yodaTokenLocal, yodaRefreshTokenLocal } from '@/app/auth/AuthProvider';
import { saveRolesLocal } from '@/hooks/useRole';
import { RolesEnum } from '@/app/auth/roles';
import { YodaToken } from '@/app/auth/types';
import { ApiResponse } from '@/types/api';

export const HOST = getHost();
const TAG = 'YodaService';

export const CONTENT_TYPE = {
  MULTIPART_HEADER: { 'Content-Type': 'multipart/form-data' },
  EXL: { 'Content-Type': 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' },
  PLAIN: { 'Content-Type': 'text/plain' },
  ZIP: { 'Content-Type': 'application/zip' },
};

function getHost() {
  const HOST = import.meta.env.VITE_YODA_BACKEND_BASE_URL;
  if (typeof HOST == 'undefined') {
    alert('Адрес сервера для запросов к API не задан');
  } else return HOST as string;
}

const UNAUTHORIZED_CODE = 401;

export const YodaRestClient = axios.create({
  baseURL: HOST,
  headers: {
    'Content-Type': 'application/json',
  },
});

// Проброс JWT токена во все запросы SupplyBillApi
YodaRestClient.interceptors.request.use(
  async function (config) {
    config.headers = config.headers ?? {};
    const token = await yodaGetAccessToken();
    config.headers['Authorization'] = `Bearer ${token}`;
    return config;
  },
  (error) => Promise.reject(error)
);

const SERCRET =
  'ZW5lcnN0cm95bWFpbi1FcUZHVUx4djpjOTg5ZGYxMGQ2MTk5NDk5MGJiMjBmODBkNDAwNTdlNmYzYzcxNWJmMGRlMGMyNDIyZGNkY2M0ZTY1N2M1ODcx';

export const ydoaAouth = (login: string, psw: string): ApiResponse<YodaToken> => {
  const url = HOST + '/rest/v2/oauth/token';
  return axios.post(url, `grant_type=password&username=${login}&password=${psw}`, {
    headers: {
      Authorization: 'Basic ' + SERCRET,
      'Content-Type': 'application/x-www-form-urlencoded',
    },
  });
};

/**
 * If token overdue invoke yodaFerfeshAouth
 * @returns accessToke or Promise.reject
 */
export async function yodaGetAccessToken(): Promise<string> {
  const token = yodaTokenLocal();
  if (token) return token;

  const refreshToken = yodaRefreshTokenLocal();
  if (!refreshToken) {
    return Promise.reject(new Error('Refresh token is not available'));
  }

  const response = await ydoaRefreshAouth().catch((er) => {
    // alert("Сервер временно недоступен, повторите попытку через 5 минут");
    return Promise.reject(er);
  });

  saveYodaToken(response.data);

  await yodaRefreshRoles();

  if (response.data.access_token) {
    return Promise.resolve(response.data.access_token);
  } else {
    return Promise.reject();
  }
}

export function ydoaRefreshAouth(): ApiResponse<YodaToken> {
  const url = HOST + '/rest/v2/oauth/token';
  return axios.post(url, `grant_type=refresh_token&refresh_token=${yodaRefreshTokenLocal()}`, {
    headers: {
      Authorization: 'Basic ' + SERCRET,
      'Content-Type': 'application/x-www-form-urlencoded',
    },
  });
}

export async function yodaRefreshRoles() {
  return await ApiFindUserRoles()
    .then(({ data }) => {
      saveRolesLocal(data);
      return Promise.resolve(data);
    })
    .catch((er) => {
      return Promise.reject(er);
    });
}

/**
 *
 * @param stop it's a cruthc. Optional attribute for preventing recursion
 * @param entityName
 * @returns  ApiResponse<any>
 */

// ToDo remove limit
export async function yodaGetEntities(entityName: string, stop = false): ApiResponse<any> {
  const url = HOST + '/rest/v2/entities/' + entityName;
  const token = await yodaGetAccessToken();
  console.log(TAG + 'GET ENTITIES ' + url + ' the tokein is' + token);
  return axios.get(url, {
    headers: {
      Authorization: `Bearer  ${token}`,
      'content-type': 'application/json',
    },
    params: {
      limit: 300,
      timeout: 1,
    },
  });
}

export async function ApiFindUserRoles(): ApiResponse<RolesEnum[]> {
  const url = HOST + '/rest/user/role';
  return YodaRestClient.get(url);
}

// TODO: временный метод
export async function ApiSendUserData(data: unknown): ApiResponse<unknown> {
  const url = HOST + '/rest/user-data';
  return YodaRestClient.post(url, { data });
}
