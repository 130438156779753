import React from 'react';
import {
  colorsManufacturer,
  DYNAMIC_SWITCH_LABELS,
  formatCount,
  formatNumberWithSpaces,
} from '../utils';
import { BarShowInfo, ShowInfoCount, ShowInfoItem, TitleShowInfo } from '../InvestStatistic.styled';
import { transformValue } from './utils';
import { useInvestStatisticStore } from '../../store';
import { DefaultStatistic, ManufacturerStatisticSegment } from '../../types/types';

interface StringBudgetStatisticTooltipProps {
  itemValue: DefaultStatistic | ManufacturerStatisticSegment;
  max: number;
  indexTooltipData: number;
  indexDataItem: number;
  lengthTitle: number;
}

export const StringBudgetStatisticTooltip = ({
  itemValue,
  max,
  indexTooltipData,
  indexDataItem,
  lengthTitle,
}: StringBudgetStatisticTooltipProps) => {
  const countAsMoney = useInvestStatisticStore((state) => state.countAsMoney);

  const backgroundColors = (title: string) => {
    if (title.toLowerCase() === DYNAMIC_SWITCH_LABELS.NARTIS.toLowerCase()) {
      return colorsManufacturer.nartis;
    } else if (title.toLowerCase() === DYNAMIC_SWITCH_LABELS.LENENERGO.toLowerCase()) {
      return colorsManufacturer.lenenergo;
    } else {
      return '#959595';
    }
  };

  const count = 'amount' in itemValue ? itemValue.amount : itemValue?.count;

  return (
    <ShowInfoItem
      style={{ marginTop: indexTooltipData > 0 && indexDataItem === 0 ? '15px' : 0 }}
      key={indexDataItem}
    >
      <TitleShowInfo width={lengthTitle}>{itemValue.title}</TitleShowInfo>
      <div style={{ gridColumn: 2 / 4, display: 'flex', gap: '5px' }}>
        <BarShowInfo
          width={(transformValue(count, max) * 170) / max}
          background={backgroundColors(itemValue.title)}
        />
        <ShowInfoCount>
          <div>
            {countAsMoney
              ? formatCount(formatNumberWithSpaces(count))
              : formatNumberWithSpaces(count)}
          </div>
          {'percentage' in itemValue && <div>{itemValue.percentage}%</div>}
        </ShowInfoCount>
      </div>
    </ShowInfoItem>
  );
};
