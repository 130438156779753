import React, { useMemo } from 'react';
import { Grid } from '@mui/material';
import LoadSpinner from '@/components/ui/LoadSpinner/LoadSpinner';
import { FormProvider, useForm } from 'react-hook-form';
import { MultiSelect } from '../../../inputs/MultiSelectNew';
import { SmsStatusStatisticsMainTable } from './SmsStatusStatisticsMainTable';
import { SmsStatusStatisticsSecondTable } from './SmsStatusStatisticsSecondTable';
import { useCatalog } from '@/hooks/CatalogHook';
import FilterButtons from '@/components/filter/FilterButtons/FilterButtons';
import { SelectFilterOptType } from '../../../filter/MultySelectFilter';
import { getDataFromFilter } from '../../../inputs/MultiSelectNew/utils';
import { generateDetailedInfo } from '../../../button/DetailedInfo/utils';
import { taskMapToArray } from '@/components/features/tasksBrowse/utils';
import { useSaveFilterButton } from '../../../filter/FilterButtons/utils';
import {
  SmsStatusStatisticsFiltersWrapper,
  SmsStatusStatisticsWrapper,
} from './SmsStatusStatistics.styled';
import { SmsStatusStatisticsItem } from '@/services/StatisticsService/CustomerInteractionStatisticsService/dto/CustomerInteractionStatisticsServiceResponseDto';
import { FilterSmsStatisitc } from '@/services/StatisticsService/CustomerInteractionStatisticsService/dto/CustomerInteractionStatisticsServiceRequestDto';
import { MainText } from '../../StatisticMain.styled';
import { useMobileSize } from '@/hooks/useMediaQuery/useMobileSize';

interface SmsStatusStatisticsProps {
  header: string;
  load: boolean;
  data: SmsStatusStatisticsItem[];
  updateFunction: (filter: FilterSmsStatisitc) => void;
}

const SAVE_FILTER_KEY = 'SmsStatusStatistics';

export const SmsStatusStatistics: React.FC<SmsStatusStatisticsProps> = ({
  header,
  load,
  data,
  updateFunction,
}) => {
  const { getFilters } = useSaveFilterButton(SAVE_FILTER_KEY);

  const methods = useForm();
  const { handleSubmit } = methods;
  const { getContractCatalog } = useCatalog((state) => ({
    getContractCatalog: state.getContractCatalog,
  }));
  const contractListResponse = getContractCatalog();
  const [selectedContractList, setSelectedContractList] = React.useState<SelectFilterOptType[]>([]);
  const [selectedResponsables, setSelectedResponsables] = React.useState<SelectFilterOptType[]>([]);

  const { taskGroupMap, getGroupCatalog } = useCatalog((state) => ({
    getGroupCatalog: state.getGroupCatalog,
    taskGroupMap: state.taskGroupMap,
  }));
  const responsables = useMemo(() => taskMapToArray(getGroupCatalog()), [taskGroupMap]);
  const [extraDataTables, setExtraDataTables] = React.useState({ first: [], second: [] });

  const onSubmitSearch = () => {
    const newFilter: FilterSmsStatisitc = { contractIds: [] };
    if (selectedContractList.length) {
      newFilter.contractIds = getDataFromFilter(selectedContractList, 'value');
    }
    if (selectedResponsables.length) {
      newFilter.contractorIds = getDataFromFilter(selectedResponsables, 'value');
    }
    updateFunction(newFilter);
  };

  const onResetClick = () => {
    setSelectedContractList([]);
    setSelectedResponsables([]);
    updateFunction({ contractIds: [] });
    setExtraDataTables({ ...extraDataTables, first: [], second: [] });
  };

  const renderExtradDataTables = (data: any, tableNumber: number) => {
    if (tableNumber === 1) {
      setExtraDataTables({ ...extraDataTables, first: data });
    } else {
      setExtraDataTables({ ...extraDataTables, second: data });
    }
  };

  const detailedInfo = generateDetailedInfo(
    [selectedContractList, 'Контракт'],
    [selectedResponsables, 'Ответственный подрядчик']
  );

  const onSelectSavedFilter = (filterKey: string) => {
    const savedFilters = getFilters<SelectFilterOptType[]>(filterKey);
    savedFilters.selectedContractList && setSelectedContractList(savedFilters.selectedContractList);
    savedFilters.selectedResponsables && setSelectedResponsables(savedFilters.selectedResponsables);
  };

  const isMobile = useMobileSize();

  return (
    <SmsStatusStatisticsWrapper elevation={6}>
      <MainText className='MainTextStatistc'>{header}</MainText>
      <Grid>
        <FormProvider {...methods}>
          <form onSubmit={handleSubmit(onSubmitSearch)}>
            <SmsStatusStatisticsFiltersWrapper isMobile={isMobile}>
              <MultiSelect
                label={'Контракт'}
                value={selectedContractList}
                onlyInListArgument
                options={contractListResponse}
                onChange={(sel: SelectFilterOptType[]) => setSelectedContractList(sel)}
                sx={{ width: isMobile ? 300 : 200 }}
              />
              <MultiSelect
                label={'Ответственный'}
                value={selectedResponsables}
                onlyInListArgument
                options={responsables}
                onChange={(sel: SelectFilterOptType[]) => setSelectedResponsables(sel)}
                sx={{ width: isMobile ? 300 : 200 }}
              />
              <FilterButtons
                info={detailedInfo}
                onSearchClick={() => handleSubmit(onSubmitSearch)}
                onCloseClick={onResetClick}
                disable={load}
                saveFilter={{
                  filterType: SAVE_FILTER_KEY,
                  infoToSave: { selectedContractList, selectedResponsables },
                  selectCallback: onSelectSavedFilter,
                }}
              />
            </SmsStatusStatisticsFiltersWrapper>
          </form>
        </FormProvider>

        {load ? (
          <LoadSpinner />
        ) : (
          <>
            <SmsStatusStatisticsMainTable
              data={data}
              renderExtradDataTables={renderExtradDataTables}
            />
            {!!extraDataTables.first.length && (
              <SmsStatusStatisticsSecondTable
                className={'custom-column-class-yellow'}
                data={extraDataTables.first}
              />
            )}
            {!!extraDataTables.second.length && (
              <SmsStatusStatisticsSecondTable
                className={'custom-column-class-blue'}
                data={extraDataTables.second}
                withExtraColumns
              />
            )}
          </>
        )}
      </Grid>
    </SmsStatusStatisticsWrapper>
  );
};
export default SmsStatusStatistics;
