import React from 'react';
import { PnrOrderTabWrapper } from './PnrOrderTab.styled';
import PnrOsOrder from './PnrOsOrder/PnrOsOrder';
import PnrPuOrder from './PnrPuOrder/PnrPuOrder';

const PnrOrderTab = () => {
  return (
    <PnrOrderTabWrapper>
      <PnrOsOrder />
      <PnrPuOrder />
    </PnrOrderTabWrapper>
  );
};

export default React.memo(PnrOrderTab);
