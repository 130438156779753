import React, { useEffect } from 'react';
import { color } from '@/styles/mixins';
import { Controller, useFormContext } from 'react-hook-form';
import { Autocomplete, Box, Grid, IconButton, TextField, Typography } from '@mui/material';
import LocationOffRoundedIcon from '@mui/icons-material/LocationOffRounded';
import EditLocationRoundedIcon from '@mui/icons-material/EditLocationRounded';
import { useCoords } from '@/components/maps/ppoMap/coordsStore';
import { usePillar } from '@/components/maps/ppoMap/resCard/pillarStore';
import { SaveButton } from '@/components/ui/SaveButton/SaveButton';
import { savePillarById } from '@/services/TaskService/TaskService';
import { addSuccessActionLog, fetchCatch } from '@/hooks/ActionLogHook';
import { useCatalog } from '@/hooks/CatalogHook';
import { taskMapToArray } from '@/components/features/tasksBrowse/utils';
import { usePpoMap } from '@/components/maps/ppoMap/store';
import { findAllDeliveryPoint } from '@/components/maps/ppoMap/service';
import ImageSearchRoundedIcon from '@mui/icons-material/ImageSearchRounded';
import { useGallery } from '../../NewGallery/utils';
import { useScreenHoldHook } from '@/hooks/ScreenHoldHook';
import { getParentIdInPillar } from '../deliveryPoint/utils';

export const PillarItem = () => {
  const { openGallery } = useGallery();
  const { infoPillar } = usePillar();
  const { isEditMode, changeMode, latitude, longitude } = useCoords();
  const { register, handleSubmit, setValue, reset, control } = useFormContext();
  const getCatalogMapWithErr = useCatalog((state) => state.getCatalogMapWithErr);
  const catalog = taskMapToArray(getCatalogMapWithErr('pillarType'));
  const { setGeoTransStation, tmpTaskId, geoTransStations } = usePpoMap();
  const { setIsInProgress } = useScreenHoldHook();

  useEffect(() => {
    if (!isEditMode && latitude && longitude) {
      setValue(`${infoPillar?.id}.latitudeY`, latitude?.toFixed(6));
      setValue(`${infoPillar?.id}.longitudeX`, longitude?.toFixed(6));
    }
  }, [isEditMode]);

  useEffect(() => {
    if (infoPillar?.number) {
      setValue(`${infoPillar?.id}.number`, infoPillar?.number);
    }
  }, [infoPillar]);

  const onSave = async (data: any) => {
    if (!infoPillar) return;
    const type = { id: data.typeId };
    const parentId = getParentIdInPillar(geoTransStations, infoPillar.id);
    setIsInProgress(true);
    try {
      await savePillarById(infoPillar.id, { ...data[infoPillar.id], type });
      addSuccessActionLog('Сохранено');
      if (parentId) {
        try {
          const { data } = await findAllDeliveryPoint(parentId);
          setGeoTransStation({
            geoTransStation: data,
            parentId,
          });
        } catch (err) {
          fetchCatch(err, 'Ошибка при загрузке точек поставки');
        }
      }
    } catch (err) {
      fetchCatch(err, 'Не сохранено');
    } finally {
      setIsInProgress(false);
    }
  };

  const onOpenGalleryClick = () => {
    openGallery({ images: infoPillar?.imageList, title: 'Опора' });
  };

  useEffect(() => {
    reset();
    setValue(`typeId`, infoPillar?.typeId);
  }, [infoPillar]);

  return (
    <Box sx={{ position: 'relative' }}>
      <Grid
        container
        spacing={4}
      >
        <Grid
          item
          xs={12}
          sx={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}
        >
          <Typography
            variant={'h6'}
            sx={{ flex: '1 1 0' }}
          >
            Опора
          </Typography>
          <IconButton
            sx={{ mr: 2 }}
            disabled={!infoPillar?.imageList || infoPillar?.imageList.length === 0}
            onClick={onOpenGalleryClick}
          >
            <ImageSearchRoundedIcon htmlColor={color('muiBlue')} />
          </IconButton>
        </Grid>
        <Grid
          item
          xs={5}
        >
          <TextField
            id='trns-number'
            label='Номер'
            variant='standard'
            sx={{ width: '100%' }}
            {...register(`${infoPillar?.id}.number`, {
              value: infoPillar?.number,
            })}
          />
        </Grid>
        <Grid
          item
          xs={6}
        >
          <Controller
            name={`typeId`}
            defaultValue={infoPillar?.typeId ? `${infoPillar.typeId}` : undefined}
            control={control}
            render={({ field }) => (
              <Autocomplete
                componentsProps={{
                  paper: {
                    sx: {
                      width: 190,
                    },
                  },
                }}
                options={catalog}
                value={{
                  value: field?.value ?? '',
                  label: catalog.find((el) => el.value === field?.value)?.label || '',
                }}
                onChange={(event: any, newValue: any) => field?.onChange(newValue?.value)}
                renderInput={(params) => (
                  <TextField
                    name={`typeId`}
                    {...params}
                    label={'Тип'}
                    variant='standard'
                  />
                )}
              />
            )}
          />
        </Grid>
        <Grid
          item
          xs={5}
        >
          <TextField
            id='latitudeY'
            label='Широта'
            variant='standard'
            sx={{ width: '100%' }}
            {...register(`${infoPillar?.id}.latitudeY`, {
              value: infoPillar?.latitude,
            })}
          />
        </Grid>
        <Grid
          item
          xs={5}
        >
          <TextField
            id='longitudeX'
            label='Долгота'
            variant='standard'
            sx={{ width: '100%' }}
            {...register(`${infoPillar?.id}.longitudeX`, {
              value: infoPillar?.longitude,
            })}
          />
        </Grid>
        <Grid
          item
          xs={2}
        >
          <IconButton onClick={() => changeMode()}>
            {isEditMode ? (
              <LocationOffRoundedIcon htmlColor={color('muiBlue')} />
            ) : (
              <EditLocationRoundedIcon htmlColor={color('muiBlue')} />
            )}
          </IconButton>
        </Grid>
        <Grid
          item
          xs={12}
          sx={{ display: 'flex', alignItems: 'center', justifyContent: 'end' }}
        >
          <SaveButton onSave={handleSubmit(onSave)} />
        </Grid>
      </Grid>
    </Box>
  );
};
