import { TextField } from '@/components/fixUI';
import React, { FC, useEffect } from 'react';
import { useFormContext } from 'react-hook-form';
import { AutocompleteValue } from '@/components/inputs/AutocompleteValue/AutocompleteValue';
import { Grid, Typography } from '@mui/material';
import { DeliveryPointResponse } from '@/dto/taskmap/task/TaskResultDto';
import { useCatalog } from '@/hooks/CatalogHook';
import { isChangeInput } from './utils';

interface IFormPU {
  keyStr: string;
  meter?: DeliveryPointResponse;
  disabledAll?: boolean;
}

export const FormPU: FC<IFormPU> = ({ meter, keyStr, disabledAll = false }) => {
  const { register, setValue } = useFormContext();
  const { getCatalogMapWithErr } = useCatalog((state) => ({
    getCatalogMapWithErr: state.getCatalogMapWithErr,
  }));

  useEffect(() => {
    if (meter?.puType !== undefined) {
      setValue(`${keyStr}.puType`, meter?.puType);
    }
    if (meter?.puNumber !== undefined) {
      setValue(`${keyStr}.puNumber`, meter?.puNumber);
    }
  }, [meter?.puType, meter?.puNumber, keyStr]);

  return (
    <Grid
      item
      xs={12}
      container
      spacing={2}
    >
      <Grid
        item
        xs={12}
      >
        <Typography variant={'body1'}>Существующий ПУ</Typography>
      </Grid>
      <Grid
        item
        xs={6}
      >
        <TextField
          id='tpTypeEnum'
          label='Тип сущ. ПУ'
          variant='standard'
          sx={{ width: '100%' }}
          defaultValue={meter?.puType}
          {...register(`${keyStr}.puType`)}
          disabled={disabledAll}
          InputLabelProps={{ shrink: true }}
        />
      </Grid>
      <Grid
        item
        xs={6}
      >
        <TextField
          id='puNumber'
          label='№ сущ. ПУ'
          variant='standard'
          sx={{ width: '100%' }}
          defaultValue={meter?.puNumber}
          {...register(`${keyStr}.puNumber`)}
          disabled={disabledAll}
          InputLabelProps={{ shrink: true }}
        />
      </Grid>
      <Grid
        item
        xs={6}
      >
        <AutocompleteValue
          title='Место установки сущ. ПУ'
          sx={{ width: '100%' }}
          defaultValue={meter?.exPuPlace}
          fieldName={`${keyStr}.exPuPlace`}
          values={getCatalogMapWithErr('exPuPlace')}
          disabled={disabledAll}
        />
      </Grid>
      <Grid
        item
        xs={6}
      >
        <AutocompleteValue
          title='Наличие ИКК'
          sx={{ width: '100%' }}
          fieldName={`${keyStr}.isIkkExists`}
          defaultValue={
            meter?.isIkkExists == undefined || meter?.isIkkExists == null
              ? undefined
              : `${meter?.isIkkExists}`
          }
          values={isChangeInput}
          disabled={disabledAll}
        />
      </Grid>
    </Grid>
  );
};
