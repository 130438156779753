import { Popup } from '@/components/popups';
import {
  SupplyPUCommentModalButtonsWrapper,
  SupplyPUCommentModalWrapper,
} from './SupplyCommentModal.styled';
import { Button, TextField, Typography } from '@mui/material';
import { onChangeFunc } from '../SupplyPUComponent/utils';

export interface SupplyCommentModalProps {
  isOpen: boolean;
  onCloseHandler: () => void;
  title: string | null;
  value: string | undefined;
  onChangeHandler: onChangeFunc | undefined;
  error: boolean;
  disabled: boolean | undefined;
  onKeyDownHandler: (event: React.KeyboardEvent<HTMLInputElement>) => void;
  saveHandler: () => void;
}

export const SupplyCommentModal = ({
  isOpen,
  onCloseHandler,
  title,
  value,
  onChangeHandler,
  error,
  disabled,
  onKeyDownHandler,
  saveHandler,
}: SupplyCommentModalProps) => {
  return (
    <Popup
      isOpen={isOpen}
      onClose={onCloseHandler}
    >
      <SupplyPUCommentModalWrapper>
        <Typography variant='h6'>{title}</Typography>

        <TextField
          variant='standard'
          value={value}
          sx={{ width: '75%' }}
          multiline
          autoFocus
          maxRows={6}
          onChange={onChangeHandler}
          error={error}
          disabled={disabled}
          onKeyDown={onKeyDownHandler}
        />

        <SupplyPUCommentModalButtonsWrapper>
          <Button
            onClick={saveHandler}
            variant='contained'
            disabled={disabled}
          >
            Сохранить
          </Button>

          <Button
            onClick={onCloseHandler}
            variant='contained'
          >
            Отменить
          </Button>
        </SupplyPUCommentModalButtonsWrapper>
      </SupplyPUCommentModalWrapper>
    </Popup>
  );
};
