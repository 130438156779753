import React from 'react';
import { Grid } from '@mui/material';

import { CatalogTypes } from '@/components/sections/CatalogsBrowse/constants';
import CatalogsBrowseDragOrder from './CatalogsBrowseDragOrder/CatalogsBrowseDragOrder';

interface ICatalogHeader {
  type: CatalogTypes;
}

const CatalogHeader = ({ type }: ICatalogHeader) => {
  return (
    <div style={{ margin: '8px 10px -8px 10px' }}>
      <Grid
        container
        columnSpacing={2}
        alignItems='center'
        justifyContent='flex-end'
      >
        <Grid item>
          <CatalogsBrowseDragOrder type={type} />
        </Grid>
      </Grid>
    </div>
  );
};

export default React.memo(CatalogHeader);
