import { GRID_CHECKBOX_SELECTION_COL_DEF, GridColDef } from '@mui/x-data-grid';
import moment from 'moment';
import React from 'react';
import { CellWithTooltip } from '@/components/features/tasksBrowse/constants';
import { Checkbox } from '@mui/material';

//1795382

export enum CatalogTypes {
  STAMP = 'stamp',
  SIM = 'sim',
  KOO = 'network-equipment',
  PU = 'pu',
}

export const getFilterLabels = (type: CatalogTypes) => {
  const tmp = {
    number: '',
    id: 'ID',
    mac: 'МАС',
    imei: 'IMEI',
    phase: 'Фазность',
    typeId: 'Тип',
    model: 'Модель',
    simIccid: 'ICCID',
    simNum: 'SIM-карта',
    ip: 'IP',
    deny: 'IP',
    inSession: 'IP',
    usingMB: 'IP',
    owner: 'IP',
  };
  switch (type) {
    case CatalogTypes.PU:
      tmp.number = '№ ПУ';
      tmp.id = 'ID ПУ';
      tmp.mac = 'МАС ПУ';
      tmp.typeId = 'Тип ПУ';
      break;
    case CatalogTypes.STAMP:
      tmp.number = '№ пломбы';
      tmp.id = 'ID пломбы';
      break;
    case CatalogTypes.KOO:
      tmp.number = 'Заводской №';
      tmp.typeId = 'Тип оборудования';
      break;
    case CatalogTypes.SIM:
      tmp.number = 'Номер';
      tmp.id = 'id';
      break;
  }
  return tmp;
};

export const getColumns = (type: CatalogTypes): GridColDef[] => {
  const arrStamp = [
    {
      ...GRID_CHECKBOX_SELECTION_COL_DEF,
      width: 0,
      renderHeader: () => '',
      hide: true,
    },
    {
      field: 'number',
      headerName: '№ пломбы',
      minWidth: 180,
    },
    {
      field: 'id',
      headerName: 'ID',
      minWidth: 180,
    },
  ];
  const arrKoo = [
    {
      ...GRID_CHECKBOX_SELECTION_COL_DEF,
      width: 0,
      renderHeader: () => '',
      hide: true,
    },
    {
      field: 'manufacturer',
      headerName: 'Производитель',
      minWidth: 180,
    },
    {
      field: 'type',
      headerName: 'Тип оборудования',
      minWidth: 180,
    },
    {
      field: 'model',
      headerName: `Модель`,
      minWidth: 180,
    },
    {
      field: 'number',
      headerName: `Заводской №`,
      minWidth: 180,
    },
    {
      field: 'versionNumber',
      headerName: '№ прошивки',
      minWidth: 80,
    },
    {
      field: 'hermesIp',
      headerName: 'IP Гермеса',
      minWidth: 80,
    },
    {
      field: 'mac',
      headerName: 'МАС счетчика',
      width: 180,
    },
    {
      field: 'imei',
      headerName: 'IMEI',
      minWidth: 80,
    },
    {
      field: 'id',
      headerName: 'ID',
      minWidth: 180,
    },
  ];
  const arrSim = [
    {
      ...GRID_CHECKBOX_SELECTION_COL_DEF,
      width: 0,
      renderHeader: () => '',
      hide: true,
    },
    {
      field: 'isUsed',
      headerName: 'В эксплуатации',
      minWidth: 180,
    },
    {
      field: 'iccid',
      headerName: `ICCID`,
      minWidth: 180,
    },
    {
      field: 'ip',
      headerName: `IP`,
      minWidth: 180,
    },
    {
      field: 'number',
      headerName: `Номер`,
      minWidth: 180,
    },
    {
      field: 'imei',
      headerName: 'IMEI',
      minWidth: 80,
    },
    {
      field: 'owner',
      headerName: 'Владелец',
      minWidth: 180,
    },
    {
      field: 'usingMB',
      headerName: 'Использование до настоящего времени',
      minWidth: 80,
    },
    {
      field: 'inSession',
      headerName: 'В сеансе',
      minWidth: 80,
    },
    {
      field: 'deny',
      headerName: 'Запрещена',
      minWidth: 80,
    },
  ];
  const arrPU = [
    {
      ...GRID_CHECKBOX_SELECTION_COL_DEF,
      hide: true,
    },
    {
      field: 'number',
      headerName: `№ ПУ`,
      minWidth: 180,
    },
    {
      field: 'mac',
      headerName: 'МАС ПУ',
      width: 180,
    },
    {
      field: 'imei',
      headerName: 'IMEI',
      minWidth: 80,
    },
    {
      field: 'type',
      headerName: 'Тип ПУ',
      minWidth: 180,
    },
    {
      field: 'networkAddress',
      headerName: 'Сетевой адрес',
      minWidth: 80,
    },
    {
      field: 'phase',
      headerName: 'Фазность',
      minWidth: 80,
    },
    {
      field: 'releaseDate',
      headerName: 'Дата выпуска',
      minWidth: 180,
    },
    {
      field: 'verificationDate',
      headerName: 'Дата поверки',
      minWidth: 180,
    },
    {
      field: 'nextVerificationDate',
      headerName: 'Дата следующей поверки',
      minWidth: 180,
    },
    {
      field: 'id',
      headerName: 'ID',
      minWidth: 180,
    },
  ];
  switch (type) {
    // case CatalogTypes.METER:
    //   return arrMeter;
    case CatalogTypes.STAMP:
      return arrStamp;
    case CatalogTypes.SIM:
      return arrSim;
    case CatalogTypes.KOO:
      return arrKoo;
    case CatalogTypes.PU:
      return arrPU;
    default:
      return [];
  }
};

const getColumnsRenderCell = (type: CatalogTypes) => {
  const arrStamp = [
    {
      ...GRID_CHECKBOX_SELECTION_COL_DEF,
      hide: true,
    },
    {
      field: 'number',
      renderCell: (params: any) => <CellWithTooltip params={params} />,
    },
    {
      field: 'id',
      renderCell: (params: any) => <CellWithTooltip params={params} />,
    },
  ];
  const arrKoo = [
    {
      ...GRID_CHECKBOX_SELECTION_COL_DEF,
      hide: true,
    },
    {
      field: 'manufacturer',
      renderCell: (params: any) => <CellWithTooltip params={params} />,
    },
    {
      field: 'type',
      renderCell: (params: any) => <CellWithTooltip params={params} />,
    },
    {
      field: 'model',
      renderCell: (params: any) => <CellWithTooltip params={params} />,
    },
    {
      field: 'number',
      renderCell: (params: any) => <CellWithTooltip params={params} />,
    },
    {
      field: 'versionNumber',
      renderCell: (params: any) => <CellWithTooltip params={params} />,
    },
    {
      field: 'hermesIp',
      renderCell: (params: any) => <CellWithTooltip params={params} />,
    },
    {
      field: 'mac',
      renderCell: (params: any) => <CellWithTooltip params={params} />,
    },
    {
      field: 'imei',
      renderCell: (params: any) => <CellWithTooltip params={params} />,
    },
    {
      field: 'id',
      renderCell: (params: any) => <CellWithTooltip params={params} />,
    },
  ];
  const arrSim = [
    {
      ...GRID_CHECKBOX_SELECTION_COL_DEF,
      hide: true,
    },
    {
      field: 'isUsed',
      renderCell: (params: any) => (
        <Checkbox
          disabled
          checked={params.value}
        />
      ),
    },
    {
      field: 'iccid',
      renderCell: (params: any) => <CellWithTooltip params={params} />,
    },
    {
      field: 'ip',
      renderCell: (params: any) => <CellWithTooltip params={params} />,
    },
    {
      field: 'number',
      renderCell: (params: any) => <CellWithTooltip params={params} />,
    },
    {
      field: 'imei',
      renderCell: (params: any) => <CellWithTooltip params={params} />,
    },
    {
      field: 'owner',
      renderCell: (params: any) => <CellWithTooltip params={params} />,
    },
    {
      field: 'usingMB',
      renderCell: (params: any) => <CellWithTooltip params={params} />,
    },
    {
      field: 'inSession',
      renderCell: (params: any) => (
        <Checkbox
          disabled
          checked={params.value}
        />
      ),
    },
    {
      field: 'deny',
      renderCell: (params: any) => (
        <Checkbox
          disabled
          checked={params.value}
        />
      ),
    },
  ];
  const arrPU = [
    {
      ...GRID_CHECKBOX_SELECTION_COL_DEF,
      hide: true,
    },
    {
      field: 'number',
      renderCell: (params: any) => <CellWithTooltip params={params} />,
    },
    {
      field: 'mac',
      renderCell: (params: any) => <CellWithTooltip params={params} />,
    },
    {
      field: 'imei',
      renderCell: (params: any) => <CellWithTooltip params={params} />,
    },
    {
      field: 'type',
      renderCell: (params: any) => <CellWithTooltip params={params} />,
    },
    {
      field: 'networkAddress',
      renderCell: (params: any) => <CellWithTooltip params={params} />,
    },
    {
      field: 'phase',
      renderCell: (params: any) => <CellWithTooltip params={params} />,
    },
    {
      field: 'releaseDate',
      renderCell: (params: any) => {
        const paramsCopy = params;
        paramsCopy.value = params.value && moment(params.value).format('DD-MM-yyyy');
        return <CellWithTooltip params={paramsCopy} />;
      },
    },
    {
      field: 'verificationDate',
      renderCell: (params: any) => {
        const paramsCopy = params;
        paramsCopy.value = params.value && moment(params.value).format('DD-MM-yyyy');
        return <CellWithTooltip params={paramsCopy} />;
      },
    },
    {
      field: 'nextVerificationDate',
      renderCell: (params: any) => {
        const paramsCopy = params;
        paramsCopy.value = params.value && moment(params.value).format('DD-MM-yyyy');
        return <CellWithTooltip params={paramsCopy} />;
      },
    },
    {
      field: 'id',
      renderCell: (params: any) => <CellWithTooltip params={params} />,
    },
  ];

  switch (type) {
    case CatalogTypes.STAMP:
      return arrStamp;
    case CatalogTypes.SIM:
      return arrSim;
    case CatalogTypes.KOO:
      return arrKoo;
    case CatalogTypes.PU:
      return arrPU;
    default:
      return [];
  }
};

export const getColumnsWithRenderCell = (
  columns: GridColDef<any, any, any>[],
  type: CatalogTypes
) => {
  const renderCellColumns = getColumnsRenderCell(type);
  // Свойство renderCell невозможно сохранить в localStorage
  const columnsWithRenderCells = columns?.map((column) => {
    const columnRenderCell = renderCellColumns.find(
      (columnWithRender) => columnWithRender.field === column.field
    );
    return columnRenderCell ? { ...column, ...columnRenderCell } : column;
  });

  return columnsWithRenderCells;
};
