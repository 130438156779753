import { useAllBillTypes } from '@/hooks/useQuery/useAllBillTypes';
import { useAllNetworkElementTypes } from '@/hooks/useQuery/useAllNetworkElementTypes';
import { useAllPuTypes } from '@/hooks/useQuery/useAllPuTypes';
import {
  useAllStorehouses,
  useAllResponsables,
  useAllStatisticStates,
} from '@/hooks/useQuery/useAllSupplyCatalogs';
import { Tooltip, Typography } from '@mui/material';
import { GridColDef, GridRenderCellParams } from '@mui/x-data-grid';
import { useMemo } from 'react';
import { convertBillTypesIntoFilter } from '../SupplyBillBrowse/utils';
import SupplyStatisticCell from './SupplyStatisticCell';
import * as XLSX from 'xlsx';
import { FilterArgEnum } from '@/components/inputs/ArgumentSelectNew/types';
import { SupplyStatisticResponseDTO } from '@/services/SupplyBillService/dto/SupplyBillServiceResponse';

export const SUPPLY_STATISTIC_FILTER_KEY = 'supplyStatistic';

export const useSupplyStatisticFormOptions = () => {
  const { data: billTypesData } = useAllBillTypes();
  const billTypesOptions = useMemo(
    () => convertBillTypesIntoFilter(billTypesData ?? []),
    [billTypesData]
  );

  const { storehousesOptions } = useAllStorehouses();

  const { puTypesResponse } = useAllPuTypes();
  const puTypesOptions = useMemo(
    () => convertBillTypesIntoFilter(puTypesResponse ?? []),
    [puTypesResponse]
  );

  const { networkElementTypesResponse } = useAllNetworkElementTypes();
  const osTypesOptions = useMemo(
    () => convertBillTypesIntoFilter(networkElementTypesResponse ?? []),
    [networkElementTypesResponse]
  );

  const { responsablesOptions } = useAllResponsables();

  return {
    billTypesOptions,
    storehousesOptions,
    puTypesOptions,
    osTypesOptions,
    responsablesOptions,
  };
};

const COLUMNS_STANDART_OPTIONS: Pick<GridColDef, 'disableColumnMenu' | 'sortable'> = {
  disableColumnMenu: true,
  sortable: false,
};

export const useSupplyStatisticColumns = () => {
  const { statisticStatesOptions } = useAllStatisticStates();
  const { responsablesMap } = useAllResponsables();
  const getContractorName = (contractorId?: string) => responsablesMap.get(contractorId) ?? '';

  const columns: GridColDef[] = useMemo(
    () => [
      {
        field: 'contractorId',
        headerName: 'Кому выдано',
        flex: 1,
        ...COLUMNS_STANDART_OPTIONS,
        renderCell: (params: GridRenderCellParams<string>) => (
          <Tooltip title={getContractorName(params.value)}>
            <Typography
              fontSize={14}
              sx={{ mt: 1 }}
              variant='caption'
            >
              {getContractorName(params.value)}
            </Typography>
          </Tooltip>
        ),
      },
      ...statisticStatesOptions.map((stateOption) => ({
        field: stateOption.value,
        headerName: stateOption.label,
        flex: 1,
        ...COLUMNS_STANDART_OPTIONS,
        renderCell: (params: GridRenderCellParams<string>) => {
          return <SupplyStatisticCell cellParams={params} />;
        },
      })),
    ],
    [statisticStatesOptions]
  );

  return columns;
};

// FIXME: жуткий велосипед, но в скором времени генерация переедет на бэк, сори 🙃
export const useSupplyStatisticExcelFile = (data: SupplyStatisticResponseDTO[]) => {
  const { responsablesMap } = useAllResponsables();
  const getContractorName = (contractorId?: string) => responsablesMap.get(contractorId) ?? '';

  const generateExcelFile = () => {
    const columns = [
      { field: 'contractorId', headerName: 'Подрядчик' },
      { field: 'MOVED-name', headerName: 'Перемещенное оборудование' },
      { field: 'MOVED-count', headerName: '' },
      { field: 'MOVED-total', headerName: '' },

      { field: 'RELEASED-name', headerName: 'Выданное оборудование' },
      { field: 'RELEASED-count', headerName: '' },
      { field: 'RELEASED-total', headerName: '' },

      { field: 'RETURNED-name', headerName: 'Возвращенное оборудование' },
      { field: 'RETURNED-count', headerName: '' },
      { field: 'RETURNED-total', headerName: '' },

      {
        field: 'INSTALLED-name',
        headerName: 'Установленное оборудование \n (зафиксированное в YODA)',
      },
      { field: 'INSTALLED-count', headerName: '' },
      { field: 'INSTALLED-total', headerName: '' },

      { field: 'BALANCE-name', headerName: 'Итого на складе подрядчика' },
      { field: 'BALANCE-count', headerName: '' },
      { field: 'BALANCE-total', headerName: '' },
    ];

    const dataForExcel: (string | number)[][] = [columns.map((column) => column.headerName)];

    dataForExcel.push([
      '',
      'Наименование',
      'кол-во',
      'всего',
      'Наименование',
      'кол-во',
      'всего',
      'Наименование',
      'кол-во',
      'всего',
      'Наименование',
      'кол-во',
      'всего',
      'Наименование',
      'кол-во',
      'всего',
    ]);

    const groupedRows: any = {};
    data.forEach((row) => {
      const tableData: any[] = [];

      Object.keys(row.detailData).forEach((key) => {
        const { count, data: subData } = row.detailData[key];
        Object.keys(subData).forEach((subKey) => {
          tableData.push({
            [`${key}-name`]: subKey,
            [`${key}-count`]: subData[subKey],
          });
        });
      });

      const groupedArr = tableData.reduce((acc, curr) => {
        const name =
          curr['MOVED-name'] ||
          curr['RELEASED-name'] ||
          curr['RETURNED-name'] ||
          curr['INSTALLED-name'] ||
          curr['BALANCE-name'];
        if (!acc[name]) {
          acc[name] = {
            'MOVED-name': name,
            'MOVED-count': 0,
            'RELEASED-name': name,
            'RELEASED-count': 0,
            'RETURNED-name': name,
            'RETURNED-count': 0,
            'INSTALLED-name': name,
            'INSTALLED-count': 0,
            'BALANCE-name': name,
            'BALANCE-count': 0,
          };
        }
        if (curr['RELEASED-name']) {
          acc[name]['RELEASED-count'] = curr['RELEASED-count'];
        } else if (curr['RETURNED-name']) {
          acc[name]['RETURNED-count'] = curr['RETURNED-count'];
        } else if (curr['BALANCE-name']) {
          acc[name]['BALANCE-count'] = curr['BALANCE-count'];
        } else if (curr['MOVED-name']) {
          acc[name]['MOVED-count'] = curr['MOVED-count'];
        } else if (curr['INSTALLED-name']) {
          acc[name]['INSTALLED-count'] = curr['INSTALLED-count'];
        }
        return acc;
      }, {});

      groupedRows[row.contractorId] = groupedArr;
    });

    Object.entries(groupedRows).forEach(([key, value]) => {
      Object.entries(value as { [key: string]: { [key: string]: string | number } }).forEach(
        ([subKey, subValue], index) => {
          const newRow = [
            index > 0 ? '' : getContractorName(key),
            subValue['MOVED-count'] ? subValue['MOVED-name'] : '',
            subValue['MOVED-count'] ? subValue['MOVED-count'] : '',
            index > 0
              ? ''
              : data.find((innerData) => innerData.contractorId === key)?.detailData['MOVED']?.count
              ? data.find((innerData) => innerData.contractorId === key)?.detailData['MOVED']
              : '',

            subValue['RELEASED-count'] ? subValue['RELEASED-name'] : '',
            subValue['RELEASED-count'] ? subValue['RELEASED-count'] : '',
            index > 0
              ? ''
              : data.find((innerData) => innerData.contractorId === key)?.detailData['RELEASED']
                  ?.count
              ? data.find((innerData) => innerData.contractorId === key)?.detailData['RELEASED']
              : '',

            subValue['RETURNED-count'] ? subValue['RETURNED-name'] : '',
            subValue['RETURNED-count'] ? subValue['RETURNED-count'] : '',
            index > 0
              ? ''
              : data.find((innerData) => innerData.contractorId === key)?.detailData['RETURNED']
                  ?.count
              ? data.find((innerData) => innerData.contractorId === key)?.detailData['RETURNED']
              : '',

            subValue['INSTALLED-count'] ? subValue['INSTALLED-name'] : '',
            subValue['INSTALLED-count'] ? subValue['INSTALLED-count'] : '',
            index > 0
              ? ''
              : data.find((innerData) => innerData.contractorId === key)?.detailData['INSTALLED']
                  ?.count
              ? data.find((innerData) => innerData.contractorId === key)?.detailData['INSTALLED']
              : '',

            subValue['BALANCE-count'] ? subValue['BALANCE-name'] : '',
            subValue['BALANCE-count'] ? subValue['BALANCE-count'] : '',
            index > 0
              ? ''
              : data.find((innerData) => innerData.contractorId === key)?.detailData['BALANCE']
                  ?.count
              ? data.find((innerData) => innerData.contractorId === key)?.detailData['BALANCE']
              : '',
          ];
          dataForExcel.push(newRow);
        }
      );
    });

    const workbook = XLSX.utils.book_new();
    const worksheet = XLSX.utils.aoa_to_sheet(dataForExcel);

    //ширина колонок
    worksheet['!cols'] = [
      { wpx: 120 },
      { wpx: 90 },
      { wpx: 50 },
      { wpx: 50 },
      { wpx: 90 },
      { wpx: 50 },
      { wpx: 50 },
      { wpx: 90 },
      { wpx: 50 },
      { wpx: 50 },
      { wpx: 90 },
      { wpx: 50 },
      { wpx: 50 },
      { wpx: 90 },
      { wpx: 50 },
      { wpx: 50 },
    ];

    // Объединения для колонок
    worksheet['!merges'] = [{ s: { r: 0, c: 0 }, e: { r: 1, c: 0 } }];

    worksheet['!merges'].push({
      s: { r: 0, c: 1 },
      e: { r: 0, c: 3 },
    });

    worksheet['!merges'].push({
      s: { r: 0, c: 4 },
      e: { r: 0, c: 6 },
    });

    worksheet['!merges'].push({
      s: { r: 0, c: 7 },
      e: { r: 0, c: 9 },
    });

    worksheet['!merges'].push({
      s: { r: 0, c: 10 },
      e: { r: 0, c: 12 },
    });

    worksheet['!merges'].push({
      s: { r: 0, c: 13 },
      e: { r: 0, c: 15 },
    });

    XLSX.utils.book_append_sheet(workbook, worksheet, 'Лист1');
    XLSX.writeFile(workbook, `Статистика накладных.xlsx`);
  };
  return { generateExcelFile };
};
type FILTERS_LABELS_TYPE_STATISTIC = {
  [key: string]: string;
};

export const FILTERS_LABELS_STATISTIC: FILTERS_LABELS_TYPE_STATISTIC = {
  contractorIdList: 'Кому',
  billTypeList: 'Тип накладной',
  puNumber: 'Номер ПУ',
  billDate: 'Период',
  puTypeIdList: 'Тип ПУ',
  networkElementTypeIdList: 'Тип СО',
  supplyIdList: 'Склад',
};

export const defaultArgInput = {
  TAG: {
    argList: [FilterArgEnum.IN_LIST],
    defaultArgNum: 0,
  },
  TAG_2: {
    argList: [FilterArgEnum.IN_LIST, FilterArgEnum.NOT_IN_LIST],
    defaultArgNum: 0,
  },

  DATE: {
    argList: [FilterArgEnum.RANGE],
    defaultArgNum: 8,
  },
};

const statisicInputData: { id: string; dType: string }[] = [
  {
    id: 'contractorIdList',
    dType: 'TAG',
  },
  {
    id: 'billTypeList',
    dType: 'TAG_2',
  },

  {
    id: 'billDate',
    dType: 'DATE',
  },
  {
    id: 'puTypeIdList',
    dType: 'TAG_2',
  },
  {
    id: 'networkElementTypeIdList',
    dType: 'TAG_2',
  },
  {
    id: 'supplyIdList',
    dType: 'TAG_2',
  },
];

export const storeHouseStatisticData = statisicInputData
  .map((elem) => ({
    ...elem,
    value: elem.id,
    label: FILTERS_LABELS_STATISTIC[elem.id],
    isActive: false,
  }))
  .sort((a, b) => a.label.localeCompare(b.label));
