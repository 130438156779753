import { useEffect, useState } from 'react';
import StatisticsPie from '@/components/statistics/AsumbStatistic/StatisticsPie/StatisticsPie';
import StatisticsChart from '@/components/blocks/StatisticsChart/StatisticsChart';
import { TaskFilterParam } from '@/dto/taskmap/Dto';
import { useActionLog } from '@/hooks/ActionLogHook';
import MiniatureSelect from '@/components/blocks/MiniatureSelect/MiniatureSelect';
import {
  ASUMB_CHART_INIT_DATA,
  ASUMB_STATISTICS_CHART_TYPE,
  ASUMB_STATISTICS_INIT_DATA,
} from '../DashboardInitConst';
import { AsumbTaskStatisticsType } from '@/services/StatisticsService/AsumbStatisticService/dto/AsumbStatisticServiceResponseDto';
import {
  GetASUMBTaskStatisticsByFilter,
  GetASUMBTaskStatisticsForDate,
} from '@/services/StatisticsService/AsumbStatisticService/AsumbStatisticService';

const AsumbStatistic = () => {
  const [data, setData] = useState<AsumbTaskStatisticsType[]>([ASUMB_STATISTICS_INIT_DATA]);
  const { fetchCatch } = useActionLog();
  const [loadPie, setLoadPie] = useState(false);
  const [loadChart, setLoadChart] = useState(false);
  const timeFrom = new Date(Date.now() - 86400000 * 10).toISOString().split('T')[0];
  const timeTo = new Date().toISOString().split('T')[0];

  const [dataChart, setDataChart] = useState<ASUMB_STATISTICS_CHART_TYPE>(ASUMB_CHART_INIT_DATA);

  const [dataInRange, setDateInRange] = useState<number[] | Date[]>([
    new Date(Date.now() - 86400000 * 14),
    new Date(),
  ]);

  const updatePieWithFilter = (filter: TaskFilterParam) => {
    setLoadPie(true);
    GetASUMBTaskStatisticsByFilter(filter)
      .then((data) => setData(data.data))
      .catch((error) => fetchCatch(error))
      .finally(() => setLoadPie(false));
  };
  const updateChart = () => {
    setLoadChart(true);
    const from = new Date(dataInRange[0] || timeFrom)?.toISOString()?.split('T')[0];
    const to = new Date(dataInRange[1] || timeTo)?.toISOString()?.split('T')[0];
    GetASUMBTaskStatisticsForDate(from, to)
      .then((valueChart) => setDataChart(valueChart.data))
      .catch((error) => fetchCatch(error))
      .finally(() => setLoadChart(false));
  };

  useEffect(() => {
    setLoadPie(true);
    setLoadChart(true);
    updatePieWithFilter({});
    updateChart();
  }, []);

  const statistics = [
    {
      title: 'Состояние задач АСУМБ',
      component: (
        <StatisticsPie
          data={data}
          header={'Состояние задач АСУМБ'}
          load={loadPie}
          updateFunction={updatePieWithFilter}
          withFilter={true}
        />
      ),
    },
    {
      title: 'Статистика задач АСУМБ в динамике',
      component: (
        <StatisticsChart
          data={dataChart}
          header={'Статистика задач АСУМБ в динамике'}
          load={loadChart}
          updateFunction={updateChart}
          dataInRange={dataInRange}
          setDateInRange={setDateInRange}
        />
      ),
    },
  ];

  return <MiniatureSelect components={statistics} />;
};
export default AsumbStatistic;
