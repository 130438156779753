import { useState } from 'react';
import { syncErrorCatch } from './ActionLogHook';

export const useDownload = () => {
  const [isLoading, setIsLoading] = useState(false);

  const download = async (url?: string) => {
    if (!url) return;
    setIsLoading(true);
    fetch(url)
      .then((response) => {
        if (!response.ok) {
          syncErrorCatch('Ошибка сети');
          throw new Error();
        }
        return response.blob();
      })
      .then((blob) => {
        const blobUrl = window.URL.createObjectURL(blob);
        const link = document.createElement('a');
        // Имя файла из URL или задаем значение по умолчанию
        link.download = new URL(url).pathname.split('/').pop() || 'file';
        link.href = blobUrl;
        document.body.appendChild(link);
        link.click();
        link.remove();
        window.URL.revokeObjectURL(blobUrl);
      })
      .catch((error) => {
        syncErrorCatch('Ошибка скачивания файла:', error);
      })
      .finally(() => setIsLoading(false));
  };

  return { download, isLoading };
};
