import { HouseEntrance, HouseLayoutDto, RequredNetworkElementHouse } from '@/dto/taskmap/Dto';
import { SelectFilterOptType } from '@/components/filter/MultySelectFilter';
import { createStore } from '@/utils/createStore';
import { HermesGateway, TerminalMap } from '@/components/PnrHermes/utils';

import { NetworkElementsWithoutEntranceCount } from '@/components/features/pnr/HouseEntranceEdit/utils';
import { SCHEME_LAYER_OPTIONS, ShemeLayerOption } from './constants';
import {
  HouseDataDto,
  MKDNetworkElementHouseDto,
  ReformaGkhTaskDto,
} from '@/services/HouseDataService/dto/HouseDataServiceResponseDto';

export interface IPNRStore {
  selectedIndicator: number;
  houseData?: HouseDataDto;
  layoutData?: HouseLayoutDto;
  backendLayoutData?: HouseLayoutDto;

  networkElements?: MKDNetworkElementHouseDto[];
  currentEntranceLayout?: HouseEntrance;

  addedOsData?: Array<RequredNetworkElementHouse>;
  entranceCount: number;
  floorCount: number;
  flatCount: number;
  address: string;
  entranceIdx: number[];
  floorInEntranceIdx: Map<number, number[]>;
  entranceToFlatCountMap: Map<number, number>;
  floorToFlats: Map<number, Map<number, string[]>>;
  selectedOsType: string;
  selectedMontagePlace: string;
  selectedComment: string;
  valueRadio: string;
  smrTaskToAppartment: Map<string, ReformaGkhTaskDto>;
  selectedContract: SelectFilterOptType | null;
  selectedContractor: SelectFilterOptType | null;
  osPlannedDate: string;
  puConnectionType: string;
  odpuManagementCompanyValue?: number | string;
  networkElementsSheme: Map<number, Map<string, MKDNetworkElementHouseDto[]>>;

  hermesGateways: HermesGateway[];
  shemeLayer: ShemeLayerOption;
  hermesPositiveMacs: Set<string>;
  terminalMap: TerminalMap;
  hermesBackendCalled: boolean;
  houseRelevantTasksApartmentsPreviousYears: string[];
  houseRelevantTasksApartmentsCurrentYear: string[];
  networkElementsWithoutEntranceCount: NetworkElementsWithoutEntranceCount;
  visibleIndicators: number[];
}
export interface IPNRStoreFunc {
  setSelectedIndicator: (selectedIndicator: number) => void;
  setHouseData: (houseData: HouseDataDto) => void;

  setNetworkElements: (networkElements: MKDNetworkElementHouseDto[]) => void;
  setCurrentEntranceLayout: (currentEntranceLayout: HouseEntrance) => void;
  setAddedOsData: (addedOsData?: Array<RequredNetworkElementHouse>) => void;
  setPuConnectionType: (puConnectionType: string) => void;
  setEntranceCount: (entranceCount: number) => void;
  setFloorCount: (floorCount: number) => void;
  setFlatCount: (flatCount: number) => void;
  setAddress: (address: string) => void;
  setEntranceIdx: (entranceIdx: number[]) => void;
  setFloorInEntranceIdx: (floorInEntranceIdx: Map<number, number[]>) => void;
  setEntranceToFlatCountMap: (entranceToFlatCountMap: Map<number, number>) => void;
  setFloorToFlats: (floorToFlats: Map<number, Map<number, string[]>>) => void;
  setSelectedOsType: (selectedOsType: string) => void;
  setSelectedMontagePlace: (selectedMontagePlace: string) => void;
  setSelectedComment: (selectedComment: string) => void;
  setValueRadio: (valueRadio: string) => void;
  setSmrTaskToAppartment: (smrTaskToAppartment: Map<string, ReformaGkhTaskDto>) => void;
  setSelectedContract: (selectedContract: SelectFilterOptType | null) => void;
  setSelectedContractor: (selectedContractor: SelectFilterOptType | null) => void;
  setOsPlannedDate: (osPlannedDate: string) => void;
  setNetworkElementsWithoutEntranceCount: (
    networkElementsWithoutEntranceCount: NetworkElementsWithoutEntranceCount
  ) => void;

  setNetworkElementsSheme: (
    networkElementsSheme: Map<number, Map<string, MKDNetworkElementHouseDto[]>>
  ) => void;
  setHermesGateways: (hermesGateways: HermesGateway[]) => void;
  setShemeLayer: (shemeLayer: ShemeLayerOption) => void;
  addHermesPositiveMac: (hermesPositiveMac?: string) => void;
  removeHermesPositiveMac: (hermesPositiveMac?: string) => void;
  setTerminalMap: (fn: (prev: TerminalMap) => TerminalMap) => void;
  setHermesBackendCalled: (hermesBackendCalled: boolean) => void;
  setHouseRelevantTaskApartmentsPrevious: (
    houseRelevantTasksApartmentsPreviousYears: string[]
  ) => void;
  setHouseRelevantTaskApartmentsCurrent: (
    houseRelevantTasksApartmentsCurrentYear: string[]
  ) => void;
  setVisibleIndicators: (visibleIndicators: number[]) => void;
  setLayoutData: (layoutData: HouseLayoutDto) => void;
  setLayoutDataFromBackend: (layoutData: HouseLayoutDto) => void;
  setOdpuManagementCompany: (odpuManagementCompanyValue: number | string) => void;
}

export const usePNRStore = createStore<IPNRStore & IPNRStoreFunc>(
  (set, get) => ({
    selectedIndicator: -1,
    entranceCount: 0,
    floorCount: 0,
    flatCount: 0,
    address: '',
    puConnectionType: '',
    entranceIdx: [],
    floorInEntranceIdx: new Map<number, number[]>(),
    entranceToFlatCountMap: new Map<number, number>(),
    floorToFlats: new Map<number, Map<number, string[]>>(),
    selectedOsType: '',
    selectedMontagePlace: '',
    selectedComment: '',
    valueRadio: 'mac',
    smrTaskToAppartment: new Map<string, ReformaGkhTaskDto>(),
    selectedContract: null,
    selectedContractor: null,
    selectedExecutor: null,
    osPlannedDate: '',
    networkElementsSheme: new Map<number, Map<string, MKDNetworkElementHouseDto[]>>(),
    hermesGateways: [],
    shemeLayer: SCHEME_LAYER_OPTIONS[0],
    hermesPositiveMacs: new Set<string>(),
    terminalMap: new Map(),
    hermesBackendCalled: false,
    houseRelevantTasksApartmentsPreviousYears: [],
    houseRelevantTasksApartmentsCurrentYear: [],
    networkElementsWithoutEntranceCount: { router: 0, gateway: 0 },
    visibleIndicators: [],

    setCurrentEntranceLayout: (currentEntranceLayout) => set(() => ({ currentEntranceLayout })),

    setVisibleIndicators: (visibleIndicators) => set(() => ({ visibleIndicators })),

    setSelectedIndicator: (selectedIndicator) => set(() => ({ selectedIndicator })),
    setHouseData: (houseData) => set(() => ({ houseData })),

    setNetworkElements: (networkElements) => set(() => ({ networkElements })),
    setAddedOsData: (addedOsData) => set(() => ({ addedOsData })),
    setPuConnectionType: (puConnectionType) => set(() => ({ puConnectionType })),
    setEntranceCount: (entranceCount) => set(() => ({ entranceCount })),
    setFloorCount: (floorCount) => set(() => ({ floorCount })),
    setFlatCount: (flatCount) => set(() => ({ flatCount })),
    setAddress: (address) => set(() => ({ address })),
    setEntranceIdx: (entranceIdx) => set(() => ({ entranceIdx })),
    setFloorInEntranceIdx: (floorInEntranceIdx) => set(() => ({ floorInEntranceIdx })),
    setEntranceToFlatCountMap: (entranceToFlatCountMap) => set(() => ({ entranceToFlatCountMap })),
    setFloorToFlats: (floorToFlats) => set(() => ({ floorToFlats })),
    setSelectedOsType: (selectedOsType) => set(() => ({ selectedOsType })),
    setSelectedMontagePlace: (selectedMontagePlace) => set(() => ({ selectedMontagePlace })),
    setSelectedComment: (selectedComment) => set(() => ({ selectedComment })),
    setValueRadio: (valueRadio) => set(() => ({ valueRadio })),
    setSmrTaskToAppartment: (smrTaskToAppartment) => set(() => ({ smrTaskToAppartment })),
    setSelectedContract: (selectedContract) => set(() => ({ selectedContract })),
    setSelectedContractor: (selectedContractor) => set(() => ({ selectedContractor })),
    setOsPlannedDate: (osPlannedDate) => set(() => ({ osPlannedDate })),
    setNetworkElementsSheme: (networkElementsSheme) => set(() => ({ networkElementsSheme })),
    setHermesGateways: (hermesGateways) => set(() => ({ hermesGateways })),
    setShemeLayer: (shemeLayer) => set(() => ({ shemeLayer })),
    setLayoutData: (layoutData) => set(() => ({ layoutData })),
    setNetworkElementsWithoutEntranceCount: (networkElementsWithoutEntranceCount) =>
      set(() => ({ networkElementsWithoutEntranceCount })),
    setOdpuManagementCompany: (odpuManagementCompanyValue) =>
      set(() => ({ odpuManagementCompanyValue })),

    setTerminalMap: (fn: (prev: TerminalMap) => TerminalMap) => {
      set((state) => ({ terminalMap: fn(state.terminalMap) }));
    },
    setHermesBackendCalled: (hermesBackendCalled) => set(() => ({ hermesBackendCalled })),
    setHouseRelevantTaskApartmentsPrevious: (houseRelevantTasksApartmentsPreviousYears) =>
      set(() => ({ houseRelevantTasksApartmentsPreviousYears })),
    setHouseRelevantTaskApartmentsCurrent: (houseRelevantTasksApartmentsCurrentYear) =>
      set(() => ({ houseRelevantTasksApartmentsCurrentYear })),

    addHermesPositiveMac: (hermesPositiveMac) => {
      if (hermesPositiveMac) return get().hermesPositiveMacs.add(hermesPositiveMac);
    },
    removeHermesPositiveMac: (hermesPositiveMac) => {
      if (hermesPositiveMac) return get().hermesPositiveMacs.delete(hermesPositiveMac);
    },

    setLayoutDataFromBackend: (layoutData) => {
      const sortedFloors = layoutData?.entrances?.map((entrance) => ({
        ...entrance,
        floors: entrance.floors?.sort((a, b) => b.number - a.number),
      }));
      const sortedLayout = { ...layoutData, entrances: sortedFloors };

      set(() => ({
        layoutData: sortedLayout,
        backendLayoutData: JSON.parse(JSON.stringify(sortedLayout)),
      }));
    },
  }),
  'Pnr'
);
