import React from 'react';
import { OdpuManagementCompanyCatalogWrapper } from './OdpuManagementCompanyCatalog.styled';
import OdpuManagementCompanyCatalogFilter from './OdpuManagementCompanyCatalogFilter';
import OdpuManagementCompanyAdd from './OdpuManagementCompanyModal';
import OdpuManagementCompanyCatalogTable from './OdpuManagementCompanyCatalogTable';

const OdpuManagementCompanyCatalog = () => {
  return (
    <OdpuManagementCompanyCatalogWrapper>
      <OdpuManagementCompanyCatalogFilter />
      <OdpuManagementCompanyAdd />
      <OdpuManagementCompanyCatalogTable />
    </OdpuManagementCompanyCatalogWrapper>
  );
};

export default OdpuManagementCompanyCatalog;
