import { styled } from '@mui/material';

export const StatisticWithoutActivationFiltersWrapper = styled('div')(
  ({ isMobile }: { isMobile: boolean }) => ({
    margin: '5px 0',
    padding: '0 0 0 5vw',
    position: 'relative',
    display: 'flex',
    flexWrap: isMobile ? 'wrap' : 'nowrap',
    justifyContent: isMobile ? 'center' : 'flex-start',
    alignItems: isMobile ? 'center' : 'flex-end',
    gap: 16,
    marginRight: isMobile ? 10 : 50,
  })
);
export const StatisticWithoutActivationTableWrapper = styled('div')({
  display: 'flex',
  width: '89%',
  margin: 'auto',
  overflowX: 'auto',
  marginTop: 15,
  minHeight: 300,
  overflowY: 'auto',
  height: 500,
});
