import { HOST, YodaRestClient } from '@/services/YodaAuthService';
import { ApiResponse } from '@/types/api';
import {
  DownloadFileByIdRequest,
  DownloadFileByOtherIdsRequest,
  DownloadFilesRequest,
  GetFilesRequest,
  GetResRequest,
  SmrDateRequest,
} from '@/components/features/drive/api/dto/DriveDtoRequest';
import { GetResponse } from '@/components/features/drive/api/dto/DriveDtoResponse';

export async function GetTechPris(): ApiResponse<GetResponse> {
  const url = HOST + '/rest/electric-networks-enterprise/tech-pris';
  return YodaRestClient.get(url);
}

export async function GetRes({ id }: GetResRequest): ApiResponse<GetResponse> {
  const url = HOST + `/rest/electric-networks-enterprise/${id}/res`;
  return YodaRestClient.get(url);
}

export async function GetSmrDate({ id, offset, limit }: SmrDateRequest): ApiResponse<string[]> {
  const url =
    HOST + `/rest/electric-networks-enterprise/${id}/smr-dates?offset=${offset}&limit=${limit}`;
  return YodaRestClient.get(url);
}

export async function GetFiles({ id, sqlDate }: GetFilesRequest): ApiResponse<GetResponse> {
  const url = HOST + `/rest/electric-networks-enterprise/${id}/all-files/${sqlDate}`;
  return YodaRestClient.get(url);
}

export async function DownloadFiles({
  energyCompanyId,
  sqlDate,
  meterChangeIds,
}: DownloadFilesRequest): Promise<any> {
  const url =
    HOST +
    `/rest/report/zip?energyCompanyId=${energyCompanyId}&date=${sqlDate}&meterChangeIds=${meterChangeIds.join(
      ';'
    )}`;
  return YodaRestClient.get(url, { responseType: 'blob' });
}

export async function DownloadFileByOtherIds({
  ids,
  sqlDate,
}: DownloadFileByOtherIdsRequest): Promise<any> {
  const url = HOST + `/rest/report/stolby/reestr-adpu/${sqlDate}`;
  return YodaRestClient.post(url, JSON.stringify(ids), { responseType: 'blob' });
}

export async function DownloadFileById({ objectId }: DownloadFileByIdRequest): Promise<any> {
  const url = HOST + `/rest/report/adpu`;
  return YodaRestClient.post(
    url,
    {
      objectId,
      reportTemplate: 'Исполнительная документация столбы',
    },
    { responseType: 'blob' }
  );
}
