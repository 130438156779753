import React, { useMemo } from 'react';
import { BoxShadow } from '@/components/ui/BoxShadow';
import DynamicInput from '@/components/inputs/DynamicFilter';
import { defaultArgInput, SAVE_FILTER_KEY_ISSUES, useComplaintFilerData } from '../utils';
import { useComplaintStore } from '../store';
import shallow from 'zustand/shallow';
import { DataTypeConvert } from '@/components/inputs/DynamicFilter/utils';
import { converToNumber } from '@/components/statistics/StatisticWithDateForRP/utils';
import { taskMapToArray } from '@/components/features/tasksBrowse/utils';
import { useCatalog } from '@/hooks/CatalogHook';
import { Tooltip } from '@mui/material';
import { MainFilterTKOTooltipTitle } from '../../tasksBrowse/MainFilter/MainFilter.styled';
import { CheckBoxLabel, ExtraFilters } from './ComplaintBrowseFilter.styled';
import usePressEnterButton from '@/hooks/usePressEnterButton';
import { useComplaintActStore } from '../ComplaintActivation/store';

const ComplaintBrowseFilter = () => {
  const {
    setNewPage,
    setPageSize,
    setFilterParamsToSend,
    handleSearch,
    setBooleanValue,
    stageTypeList,
    complaintTypeList,
    sorceList,
    overdueResponseDate,
    overduePlannedDate,
    classificationList,
  } = useComplaintStore(
    (state) => ({
      setNewPage: state.setNewPage,
      setPageSize: state.setPageSize,
      setFilterParamsToSend: state.setFilterParamsToSend,
      handleSearch: state.handleSearch,
      setBooleanValue: state.setBooleanValue,
      stageTypeList: state.stageTypeList,
      complaintTypeList: state.complaintTypeList,
      sorceList: state.sorceList,
      overdueResponseDate: state.overdueResponseDate,
      overduePlannedDate: state.overduePlannedDate,
      classificationList: state.classificationList,
    }),
    shallow
  );
  const { compalintActStatuses, compalintActClassifications, compalintActStatusReasons } =
    useComplaintActStore(
      (state) => ({
        compalintActStatuses: state.compalintActStatuses,
        compalintActClassifications: state.compalintActClassifications,
        compalintActStatusReasons: state.compalintActStatusReasons,
      }),
      shallow
    );
  const complaintFilerData = useComplaintFilerData();
  const callBackFn = (queryFields: any, filterSetting: DataTypeConvert[]) => {
    const objectKeys = Object.keys(queryFields);
    const kyes = filterSetting.map((el: any) => el.value);
    objectKeys.forEach((key) => {
      if (!kyes.includes(key)) {
        delete queryFields[key];
      }
    });

    const result = Object.keys(queryFields)
      .filter(
        (item) =>
          queryFields[item]?.values?.length && queryFields[item]?.values.some((el: any) => el)
      )
      .map((el) => ({
        name: el,
        arg: queryFields[el]?.arg,
        values: queryFields[el]?.values.map((val: string) =>
          converToNumber(queryFields[el]?.type) ? +val : val
        ),
        type: queryFields[el]?.type,
      }));

    setFilterParamsToSend(result);
  };

  const searchFn = () => {
    setPageSize(5);
    setNewPage(0);
    handleSearch();
  };
  usePressEnterButton(searchFn);
  const {
    getCatalogMapWithErr,
    energyCompany,
    regionalElectricNetwork,
    taskGroupMap,
    getGroupCatalog,
    getContractCatalog,
  } = useCatalog((state) => ({
    getContractCatalog: state.getContractCatalog,
    getCatalogMapWithErr: state.getCatalogMapWithErr,
    getGroupCatalog: state.getGroupCatalog,
    energyCompany: state.energyCompany,
    odpuManagementCompany: state.odpuManagementCompany,
    regionalElectricNetwork: state.regionalElectricNetwork,
    taskGroupMap: state.taskGroupMap,
  }));

  const getTaskBrowseCatalogList = (key: string): { label: string; value: string }[] => {
    switch (key) {
      case 'res':
        return useMemo(
          () => taskMapToArray(getCatalogMapWithErr('regionalElectricNetwork')),
          [regionalElectricNetwork]
        );
      case 'responsibleId':
        return useMemo(() => taskMapToArray(getGroupCatalog()), [taskGroupMap]);
      case 'contractId':
        return getContractCatalog();
      case 'executorId':
        return useMemo(() => taskMapToArray(getGroupCatalog()), [taskGroupMap]);
      case 'pes':
        return useMemo(
          () => taskMapToArray(getCatalogMapWithErr('energyCompany')),
          [energyCompany]
        );
      case 'source':
        return sorceList.map((el) => ({ value: el.name, label: el.title }));
      case 'stage':
        return stageTypeList.map((el) => ({ value: el.name, label: el.title }));
      case 'type':
        return complaintTypeList.map((el) => ({ value: el.name, label: el.title }));
      case 'classification':
        return classificationList.map((el) => ({ value: el.name, label: el.title }));
      // врмемено закоментировано 1536
      case 'actLowClassificationId':
        return compalintActClassifications.map((el) => ({
          value: el.id.toString(),
          label: el.title,
        }));
      case 'actLowStatusReasonId':
        return compalintActStatusReasons.map((el) => ({
          value: el.id.toString(),
          label: el.title,
        }));
      case 'actLowStatus':
        return compalintActStatuses;
      default:
        return [];
    }
  };

  return (
    <BoxShadow>
      <DynamicInput
        data={complaintFilerData}
        callBackFn={callBackFn}
        searchFn={searchFn}
        justifyContent={'space-between'}
        saveFilterKey={SAVE_FILTER_KEY_ISSUES}
        disabledSearch={true}
        defaultArgInput={defaultArgInput}
        getCatalogList={getTaskBrowseCatalogList}
        shuldRefresh
      >
        <ExtraFilters>
          <Tooltip
            title={
              <MainFilterTKOTooltipTitle
                dangerouslySetInnerHTML={{
                  __html: 'Будут показаны заявки,\n в которых просрочена плановая дата',
                }}
              />
            }
          >
            <span>
              <CheckBoxLabel
                value={overdueResponseDate}
                onChange={setBooleanValue('overdueResponseDate')}
                label={'Просрочка по плановой дате'}
              />
            </span>
          </Tooltip>
          <Tooltip
            title={
              <MainFilterTKOTooltipTitle
                dangerouslySetInnerHTML={{
                  __html: 'Будут показаны заявки,\n в которых просрочен срок ответа',
                }}
              />
            }
          >
            <span>
              <CheckBoxLabel
                value={overduePlannedDate}
                onChange={setBooleanValue('overduePlannedDate')}
                label={'Просрочка по сроку ответа'}
              />
            </span>
          </Tooltip>
        </ExtraFilters>
      </DynamicInput>
    </BoxShadow>
  );
};

export default ComplaintBrowseFilter;
