import useDebounce from '@/components/maps/zoningMap/hook/useDebounce';
import { Button, InputAdornment, TextField, Typography } from '@mui/material';
import React, { useEffect, useRef } from 'react';
import SearchIcon from '@mui/icons-material/Search';
import { PhonebookClearIcon, PhonebookCommonModal, PhonebookContainer } from './Phonebook.styled';
import CloseIcon from '@mui/icons-material/Close';
import { usePhonebookStore } from './store';
import shallow from 'zustand/shallow';
import PhonebookDataTable from './PhonebookDataTable';
import PhonebookCreate from './PhonebookCreate';
import {
  ApiGetPhonebookSuggestions,
  ApiGetPhonebookSuggestionsCount,
} from '@/services/PhonebookService/PhonebookService';
import { fetchCatch } from '@/hooks/ActionLogHook';
import { REQUEST_ABORT_ERROR_NAME } from '@/utils/heplers';
export interface PhonebookProps {
  disabled?: boolean;
}

const Phonebook = ({ disabled }: PhonebookProps) => {
  const {
    searchTerm,
    currentPage,
    pageSize,
    setSearchTerm,
    setCurrentPage,
    setIsLoading,
    setPhonebookData,
    setCount,
    open,
    setOpen,
  } = usePhonebookStore(
    (state) => ({
      searchTerm: state.searchTerm,
      currentPage: state.currentPage,
      pageSize: state.pageSize,
      setSearchTerm: state.setSearchTerm,
      setCurrentPage: state.setCurrentPage,
      setPageSize: state.setPageSize,
      setIsLoading: state.setIsLoading,
      setPhonebookData: state.setPhonebookData,
      setCount: state.setCount,
      open: state.open,
      setOpen: state.setOpen,
    }),
    shallow
  );

  const currentFetchController = useRef<AbortController | null>(null);

  const debounceSearchTerm = useDebounce(searchTerm, 700);

  const onSearchTermChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setSearchTerm(e.target.value);
  };

  const fetchTableData = () => {
    // Отменяем предыдущий запрос, если он еще выполняется
    if (currentFetchController.current) {
      currentFetchController.current.abort();
    }
    const controller = new AbortController();
    currentFetchController.current = controller;

    setIsLoading(true);
    const offset = currentPage * pageSize;
    ApiGetPhonebookSuggestions(debounceSearchTerm, offset, pageSize, { signal: controller.signal })
      .then(({ data }) => {
        setPhonebookData(data);
        setIsLoading(false);
      })
      .catch((err) => {
        if (err.name === REQUEST_ABORT_ERROR_NAME) {
          return;
        }
        fetchCatch(err);
        setIsLoading(false);
      });

    ApiGetPhonebookSuggestionsCount(debounceSearchTerm, offset, pageSize, {
      signal: controller.signal,
    })
      .then(({ data }) => setCount(data))
      .catch((err) => {
        if (err.name === REQUEST_ABORT_ERROR_NAME) {
          return;
        }
        fetchCatch(err);
      });
  };

  useEffect(() => {
    if (!open) return;
    fetchTableData();

    return () => {
      if (currentFetchController.current) {
        currentFetchController.current.abort();
      }
    };
  }, [debounceSearchTerm, currentPage, pageSize, open]);

  useEffect(() => {
    setCurrentPage(0);
  }, [debounceSearchTerm]);

  const resetSearch = () => {
    setCurrentPage(0);
    setSearchTerm('');
  };

  const closeModal = () => {
    setOpen(false);
    resetSearch();
  };
  return (
    <div style={{ flexShrink: 0 }}>
      <Button
        disabled={disabled}
        variant='outlined'
        onClick={() => setOpen(true)}
      >
        Телефонный справочник
      </Button>

      <PhonebookCommonModal
        open={open}
        onClose={closeModal}
      >
        <PhonebookContainer>
          <Typography variant='h6'>Выберете необходимый контакт</Typography>

          <TextField
            placeholder='Введите для поиска ФИО или номер телефона'
            fullWidth
            autoComplete='off'
            value={searchTerm}
            onChange={onSearchTermChange}
            label='поиск'
            InputProps={{
              startAdornment: (
                <InputAdornment position='start'>
                  <SearchIcon />
                </InputAdornment>
              ),
              endAdornment: (
                <InputAdornment position='end'>
                  <PhonebookClearIcon
                    onClick={resetSearch}
                    show={!!searchTerm?.length}
                  >
                    <CloseIcon />
                  </PhonebookClearIcon>
                </InputAdornment>
              ),
            }}
            variant='outlined'
          />
          <PhonebookCreate />
          <PhonebookDataTable />
        </PhonebookContainer>
      </PhonebookCommonModal>
    </div>
  );
};

export default Phonebook;
