import { createStore } from '@/utils/createStore';
import { PillarMapResponse } from '@/services/PillarMapService/dto/PillarMapDtoResponse';

interface Actions {
  setDefaultValue: () => void;
  setActivePoint: (activePoint?: PillarMapResponse) => void;
  setGeoPoints: (geoPoints: PillarMapResponse[]) => void;
  setGeoPointsCount: (geoPointsCount?: number) => void;
  setGeoPointsWithoutCoordinateCount: (geoPointsWithoutCoordinateCount?: number) => void;
  setCheckedPoints: (checkedPoints: PillarMapResponse[]) => void;
  setBounds: (bounds: number[][]) => void;
  setMapZoom: (mapZoom: number) => void;
  setPolygon: (polygon: string | undefined) => void;
}
interface PillarMapState {
  geoPoints: PillarMapResponse[];
  geoPointsCount?: number;
  geoPointsWithoutCoordinateCount?: number;
  checkedPoints: PillarMapResponse[];
  activePoint?: PillarMapResponse;
  bounds: number[][];
  initBounds: number[][];
  mapZoom: number;
  mapAPI: null | any;
  limit: number;
  currentPage: number;
  pageSize: number;
  mapCenterCoordinats: number[];
  polygon?: string;
}

const defaultValue: PillarMapState = {
  geoPoints: [],
  checkedPoints: [],
  bounds: [],
  initBounds: [],
  mapZoom: 5,
  mapAPI: null,
  mapCenterCoordinats: [37.57, 55.75],
  limit: 10,
  currentPage: 0,
  pageSize: 10,
  polygon:
    'POLYGON ((-0.6988944520082032 25.074614921896607, 80.59993367299182 25.074614921896607, 80.59993367299182 69.38354338210885, -0.6988944520082032 69.38354338210885, -0.6988944520082032 25.074614921896607))',
};

export type IPillarMapStore = Actions & PillarMapState;

export const usePillarMapState = createStore<IPillarMapStore>(
  (set, get) => ({
    ...defaultValue,
    setDefaultValue: () => set(() => ({ ...defaultValue })),

    setBounds: (bounds) => set(() => ({ bounds })),
    setPolygon: (polygon) => set(() => ({ polygon })),
    setMapZoom: (mapZoom) => set(() => ({ mapZoom })),
    setGeoPoints: (geoPoints) => set(() => ({ geoPoints })),
    setGeoPointsCount: (geoPointsCount) => set(() => ({ geoPointsCount })),
    setGeoPointsWithoutCoordinateCount: (geoPointsWithoutCoordinateCount) =>
      set(() => ({ geoPointsWithoutCoordinateCount })),
    setCheckedPoints: (checkedPoints) => set(() => ({ checkedPoints })),
    setActivePoint: (activePoint) => set(() => ({ activePoint })),
  }),
  'PillarMap'
);
