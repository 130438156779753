import { HOST, YodaRestClient } from '@/services/YodaAuthService';
import { ApiResponse } from '@/types/api';
import { CreateRegistryADPUFilterRequest } from './dto/RegistryADPUServiceRequestDto';
import {
  RegistryADPUFileResponse,
  RegistryADPUFolderResponse,
} from './dto/RegistryADPUServiceResponseDto';

export async function GetRegistryADPUFolders(
  data?: CreateRegistryADPUFilterRequest
): ApiResponse<RegistryADPUFolderResponse[]> {
  const url = HOST + `/rest/adpu/registry/find-by-param?offset=0&limit=1000`;
  return YodaRestClient.post(url, { ...data });
}

export async function GetRegistryADPUFiles(id: string): ApiResponse<RegistryADPUFileResponse> {
  const url = HOST + `/rest/adpu/registry/${id}`;
  return YodaRestClient.get(url);
}

export async function CreateRegistryADPU(data: {
  number: string;
  signingDate: Date;
  taskIdList: string[];
}): ApiResponse<RegistryADPUFolderResponse> {
  const url = HOST + `/rest/adpu/registry`;
  return YodaRestClient.post(url, data);
}
