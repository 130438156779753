import { Button, MenuItem, Select, Typography } from '@mui/material';
import React from 'react';
import { Controller, FormProvider, useForm } from 'react-hook-form';
import styles from './Popup.module.scss';
import { useActionLog } from '../../hooks/ActionLogHook';
import { IPopup, Popup } from './Popup';
import { createNewRegistry } from '@/services/RegistryService/RegistryService';
import { Months } from '@/components/features/registryBrowse/constants';

type Props = IPopup;

export const CreateRegistryPopup: React.FC<Props> = (props) => {
  const { onClose } = props;
  const methods = useForm();
  const { control, handleSubmit } = methods;

  const { fetchCatch } = useActionLog();

  const habdleAdding = (values: any) => {
    createNewRegistry({ month: values.month, year: new Date().getFullYear() })
      .then(({ data }) => {
        window.open(`/registry/browse/${data.id}`, '_self');
      })
      .catch((err) => fetchCatch(err, 'Ошибка добавления реестра'));
    onClose();
  };

  return (
    <Popup {...props}>
      <FormProvider {...methods}>
        <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
          <Typography variant='h3'>Создать реестр</Typography>
          <Controller
            control={control}
            name='month'
            render={({ field }) => (
              <Select
                label='Месяц'
                variant='standard'
                className={styles.input}
                // value={field.value}
                onChange={(event) => {
                  field.onChange(event.target.value);
                }}
              >
                {Months.map((value, key) => (
                  <MenuItem
                    value={key}
                    key={key}
                  >
                    {value}
                  </MenuItem>
                ))}
              </Select>
            )}
          />

          <div
            style={{
              display: 'flex',
              marginTop: 10,
              justifyContent: 'space-evenly',
              width: '100%',
            }}
          >
            <Button
              onClick={handleSubmit((values) => habdleAdding(values))}
              variant='contained'
            >
              ОК
            </Button>
            <Button
              onClick={handleSubmit((values) => {
                onClose();
              })}
              variant='contained'
            >
              Отмена
            </Button>
          </div>
        </div>
      </FormProvider>
    </Popup>
  );
};
