import { coordinateToGeoCoordinate } from '../utils/GeoUtils';
import { createStore } from '@/utils/createStore';
import { ZoningGroupResponse } from '@/services/ZoningService/dto/ZoningServiceResponseDto';

export interface ZoningStore {
  mapRef?: any;
  zoningGroupResponses: ZoningGroupResponse[];
  editingGroup?: ZoningGroupResponse;
  selectedGroupIds: string[];
  overlapGroupIds: string[];
  setMapRef: (mapRef: any) => void;
  addAll: (itemList: ZoningGroupResponse[]) => void;
  addItem: (id: string) => void;
  getItem: (groupId: string) => ZoningGroupResponse | undefined;
  selectedGroupId: (groupId: string) => void;
  deselectGroupId: (groupId: string) => void;
  updateItem: (groupId: string, polygonRef: any) => ZoningGroupResponse;
  removeItem: (groupId: string) => void;
  getAllGroupId: () => string[];
  setEditing: (groupId?: string) => void;
  setOverlapGroupIds: (groupIds?: string[]) => void;
}

export const useZoning = createStore<ZoningStore>(
  (set: any, get: any) => ({
    zoningGroupResponses: [],
    selectedGroupIds: [],
    overlapGroupIds: [],

    setMapRef: (mapRef: any) => {
      const exists = get().mapRef;
      if (!exists) {
        set({ mapRef: mapRef });
      }
    },

    addAll: (zoningGroupResponses: ZoningGroupResponse[]) => {
      set({ zoningGroupResponses: zoningGroupResponses });
    },

    addItem: (groupId: string) => {
      const item: ZoningGroupResponse = get().getItem(groupId);
      if (!item) {
        const zoningGroupResponses: ZoningGroupResponse[] = get().zoningGroupResponses;
        set({ zoningGroupResponses: [...zoningGroupResponses, { groupId: groupId }] });
      }
    },

    getItem: (groupId: string) => {
      const zoningGroupResponses: ZoningGroupResponse[] = get().zoningGroupResponses;
      return zoningGroupResponses?.find((t) => t.groupId === groupId);
    },

    updateItem: (groupId: string, polygonRef: any) => {
      const zoningGroupResponses = get().zoningGroupResponses;
      const item = zoningGroupResponses?.find((t: ZoningGroupResponse) => t.groupId === groupId);

      if (item) {
        item.coordinateList = coordinateToGeoCoordinate(polygonRef.geometry.getCoordinates());
        item.polygonRef = polygonRef;
      }
      return item;
    },

    removeItem: (groupId: string) => {
      const zoningGroupResponses: ZoningGroupResponse[] = get().zoningGroupResponses;
      set({ zoningGroupResponses: zoningGroupResponses.filter((t) => t.groupId !== groupId) });
    },

    getAllGroupId: (): string[] => {
      const zoningGroupResponses: ZoningGroupResponse[] = get().zoningGroupResponses;
      return zoningGroupResponses.map((t) => t.groupId);
    },

    setEditing: (groupId?: string) => {
      const zoningGroup = get().getItem(groupId);
      set({ editingGroup: zoningGroup });
    },

    selectedGroupId: (groupId: string) => {
      let selectedGroupIds: string[] = get().selectedGroupIds;
      if (selectedGroupIds.includes(groupId)) {
        selectedGroupIds = selectedGroupIds.filter((t) => t != groupId);
      } else {
        selectedGroupIds.push(groupId);
      }
      set({ selectedGroupIds: selectedGroupIds });
    },

    deselectGroupId: (groupId: string) => {
      let selectedGroupIds: string[] = get().selectedGroupIds;
      selectedGroupIds = selectedGroupIds.filter((t) => t != groupId);
      set({ selectedGroupIds: selectedGroupIds });
    },

    setOverlapGroupIds: (groupIds?: string[]) => {
      set({ overlapGroupIds: groupIds });
    },
  }),
  'Zoning'
);
