import { GeoTransStation, PpoFilterRequest, ResultType, Subscriber } from './dto';
import { GeoBounds, GeoTaskInBounds } from '@/dto/taskmap/Dto';
import { createStore } from '@/utils/createStore';
import { FilterArgEnum } from '@/components/filter/MultySelectFilter';
import { addParentId } from '@/components/features/taskEditor/deliveryPoint/utils';
interface SetGeoTransStationParams {
  geoTransStation: Record<string, GeoTransStation> | GeoTransStation;
  parentId?: string;
  isUpdate?: boolean;
}
interface PpoMapStore {
  activeSub: Subscriber[];
  activeRes: ResultType[];
  tmpTaskId?: string;
  setTmpTaskId: (tmpTaskId: string) => void;
  filter: PpoFilterRequest;
  geoTaskInBounds: GeoTaskInBounds[];
  geoTransStations: Record<string, GeoTransStation>;
  setFilterParam: (filter: PpoFilterRequest) => void;
  setGeoBounds: (geoBounds: GeoBounds) => void;
  getFilter: () => PpoFilterRequest | undefined;
  setGeoResonse: (response: GeoTaskInBounds[]) => void;
  setGeoTransStation: (params: SetGeoTransStationParams) => void;
  clearFilter: () => void;
  setActive: ({
    activeSub,
    activeRes,
  }: {
    activeSub?: Subscriber[];
    activeRes?: ResultType[];
  }) => void;
  selectedTaskIds: string[]; //
  addTaskSelection: (taskId: string) => void; // новый метод
  clearSelections: (taskIds?: string | string[]) => void;
}

export const DEFAULT_PPO_MAP_FILTER = {
  dispatcherName: '',
  dispatcherNameArg: FilterArgEnum.EQUAL,
  contractorIdList: [],
  contractorIdArg: FilterArgEnum.IN_LIST,
  executorIdList: [],
  executorIdArg: FilterArgEnum.IN_LIST,
  resIdList: [],
  resIdArg: FilterArgEnum.IN_LIST,
  electricNetworksEnterpriseIdList: [],
  electricNetworksEnterpriseIdArg: FilterArgEnum.IN_LIST,
};

export const usePpoMap = createStore<PpoMapStore>(
  (set: any, get: any) => ({
    filter: {},
    geoTaskInBounds: [],
    activeRes: [],
    activeSub: [],
    selectedTaskIds: [],
    geoTransStations: {},

    setActive: ({ activeSub, activeRes }) => {
      set({ activeSub: activeSub ?? [], activeRes: activeRes ?? [] });
    },

    setFilterParam: (param: PpoFilterRequest) => {
      const filter: PpoFilterRequest = {
        ...get().filter,
        dispatcherName: param.dispatcherName,
        dispatcherNameArg: param.dispatcherNameArg,
        contractorIdList: param.contractorIdList,
        contractorIdArg: param.contractorIdArg,
        executorIdList: param.executorIdList,
        executorIdArg: param.executorIdArg,
        resIdList: param.resIdList,
        resIdArg: param.resIdArg,
        electricNetworksEnterpriseIdList: param.electricNetworksEnterpriseIdList,
        electricNetworksEnterpriseIdArg: param.electricNetworksEnterpriseIdArg,
      };
      set({ filter: filter });
    },

    setTmpTaskId: (tmpTaskId) => set({ tmpTaskId }),

    clearFilter: () => {
      const filter: PpoFilterRequest = {
        ...get().filter,
        ...DEFAULT_PPO_MAP_FILTER,
      };
      set({ filter: filter });
    },

    setGeoBounds: (geoBounds: GeoBounds) => {
      const filter: PpoFilterRequest = {
        ...get().filter,
        geoBounds: geoBounds,
      };
      set({ filter: filter });
    },

    getFilter: () => {
      const filter = get().filter;
      return filter && Object.entries(filter).filter((t) => t[0] !== 'geoBounds' && t[1]).length > 0
        ? filter
        : undefined;
    },

    setGeoResonse: (response: GeoTaskInBounds[]) => {
      set({ geoTaskInBounds: response });
    },

    addTaskSelection: (taskId: string) => {
      const currentIds = get().selectedTaskIds;
      if (!currentIds.includes(taskId)) {
        set({ selectedTaskIds: [...currentIds, taskId] });
      }
    },

    setGeoTransStation: ({ geoTransStation, parentId, isUpdate }) => {
      const { tmpTaskId, geoTransStations: currentStations } = get();
      const targetParentId = parentId || tmpTaskId;
      if (!targetParentId) return; // Нет parentId или tmpTaskId
      const geoTransStationWithId = addParentId(geoTransStation, targetParentId);
      if (isUpdate) {
        set({
          geoTransStations: {
            ...currentStations,
            [targetParentId]: geoTransStationWithId,
          },
        });
      } else {
        set({
          geoTransStations: {
            ...currentStations,
            [targetParentId]: geoTransStationWithId,
          },
        });
      }
    },
    clearSelections: (taskIds?: string | string[]) => {
      if (!taskIds) {
        set({ selectedTaskIds: [], geoTransStations: {} });
      } else {
        const currentIds = get().selectedTaskIds;
        const idsToRemove = Array.isArray(taskIds) ? taskIds : [taskIds];
        const newIds = currentIds.filter((id: string) => !idsToRemove.includes(id));
        set({ selectedTaskIds: newIds });
        const currentStations = get().geoTransStations;
        const updatedStations = { ...currentStations };
        idsToRemove.forEach((id) => {
          delete updatedStations[id];
        });
        set({ geoTransStations: updatedStations });
      }
    },
  }),

  'Ppo'
);
