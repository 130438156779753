import React from 'react';
import { ShowInfoTableTd } from './BudgetStatisticDoughnut.styled';
import { DefaultStatistic, ManufacturerStatisticSegment } from '../../types/types';
import { formatCount, formatNumberWithSpaces } from '../utils';

interface StringDoughnutShowInfoProps {
  item: DefaultStatistic | ManufacturerStatisticSegment;
  countAsMoney: boolean;
}

export const StringDoughnutShowInfo = ({ item, countAsMoney }: StringDoughnutShowInfoProps) => {
  const count = 'amount' in item ? item?.amount : item?.count;
  return (
    <React.Fragment key={item.title}>
      <tr>
        <ShowInfoTableTd>{item.title}</ShowInfoTableTd>
        <ShowInfoTableTd>{item.percentage}%</ShowInfoTableTd>
        <ShowInfoTableTd>
          {countAsMoney
            ? formatCount(formatNumberWithSpaces(count))
            : formatNumberWithSpaces(count)}
        </ShowInfoTableTd>
        <ShowInfoTableTd>{countAsMoney ? '₽' : 'ШТ'}</ShowInfoTableTd>
      </tr>
    </React.Fragment>
  );
};
