import { useEffect, useState } from 'react';

export default function useDebounce(data: any, delay: number) {
  const [value, setValue] = useState<any>(() => data);

  useEffect(() => {
    const timerId = setTimeout(() => {
      if (JSON.stringify(value) !== JSON.stringify(data)) {
        setValue(data);
      }
    }, delay);
    return () => {
      clearTimeout(timerId);
    };
  }, [data, delay]);
  return value;
}
