import { useCatalog } from '@/hooks/CatalogHook';
import {
  ContractOption,
  StatisticWithoutActivationDTO,
} from '@/services/StatisticsService/RequestStatisticService/dto/RequestStatisticServiceRequestDto';
import { color } from '@/styles/mixins';
import { GridColDef, GridColumnGroupingModel, GridCellParams } from '@mui/x-data-grid';

const renderNumberCell = (params: GridCellParams) => (
  <span
    style={{
      color: params.value ? color('muiBlue') : color('trueBlack'),
      cursor: params.value ? 'pointer' : 'not-allowed',
    }}
  >
    {params.value}
  </span>
);
const COLUMNS_STANDART_OPTIONS: Pick<
  GridColDef,
  'disableColumnMenu' | 'sortable' | 'headerAlign' | 'align'
> = {
  disableColumnMenu: true,
  sortable: false,
  headerAlign: 'center',
  align: 'center',
};

export const columnsExtra: GridColumnGroupingModel = [
  {
    groupId: 'Смонтировано, но не закрыто',
    headerAlign: 'center',

    children: [
      { field: '30-60', headerName: '30-60 к.д.' },
      { field: '60-90', headerName: '60-90 к.д.' },
      { field: '90-null', headerName: 'более 90 к.д.' },
    ],
  },
];

export const columns = [
  {
    field: 'contractId',
    headerName: 'Наименование проекта',
    ...COLUMNS_STANDART_OPTIONS,
    minWidth: 300,
  },
  {
    field: '30-60',
    headerName: '30-60 к.д.',
    renderCell: renderNumberCell,
    flex: 1,
    minWidth: 300,
    ...COLUMNS_STANDART_OPTIONS,
  },
  {
    field: '60-90',
    headerName: '61-90 к.д.',
    renderCell: renderNumberCell,
    minWidth: 300,
    flex: 1,
    ...COLUMNS_STANDART_OPTIONS,
  },
  {
    field: '90-null',
    headerName: 'более 91 к.д.',
    renderCell: renderNumberCell,
    minWidth: 300,
    flex: 1,
    ...COLUMNS_STANDART_OPTIONS,
  },
];

export interface TransformedCatalogData {
  id: string;
  contractId: string;
  '30-60': number;
  '60-90': number;
  '90-null': number;
}

export const useTransformedCatalogData = (
  data: StatisticWithoutActivationDTO[]
): TransformedCatalogData[] => {
  const { getContractCatalog } = useCatalog((state) => ({
    getContractCatalog: state.getContractCatalog,
  }));
  const contractListResponse: ContractOption[] = getContractCatalog();

  const transformedData: TransformedCatalogData[] = data.map((item) => {
    const defaultPeriods: Record<'30-60' | '60-90' | '90-null', number> = {
      '30-60': 0,
      '60-90': 0,
      '90-null': 0,
    };
    const periods = item.periods.reduce(
      (acc, period) => {
        const key = `${period.daysFrom}-${period.daysTo}` as keyof typeof defaultPeriods;
        if (key in acc) {
          acc[key] = period.count ?? 0;
        }
        return acc;
      },
      { ...defaultPeriods }
    );
    const suffix = item.type == 'OS' ? 'ОС' : '';
    const contract =
      contractListResponse.find((contract) => contract.value === item.contractId)?.label ?? '';
    const contractId = `${contract} ${suffix}`;
    const id = `${item.contractId}/${item.type}`;
    return {
      id,
      contractId,
      ...periods,
    };
  });

  return transformedData;
};

export const PERIODS = [
  {
    daysFrom: '30',
    daysTo: '60',
  },
  {
    daysFrom: '60',
    daysTo: '90',
  },
  {
    daysFrom: '90',
    daysTo: null,
  },
];
