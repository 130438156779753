interface YandexMetricaCounter {
  reachGoal(goal: string, params?: Record<string, unknown>): void;
}

declare global {
  interface Window {
    yaCounter99055086?: YandexMetricaCounter;
  }
}

export const analytics = {
  trackEvent: (eventName: string, payload: Record<string, unknown> = {}): void => {
    const counter = window.yaCounter99055086;
    if (counter) {
      counter.reachGoal(eventName, payload);
    }
  },
};
