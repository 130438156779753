import { color } from '@/styles/mixins';
import { useCatalog } from '@/hooks/CatalogHook';

export function getHint(obj: any): string {
  const { getCatalogMapWithErr, getGroupCatalog } = useCatalog((state) => ({
    getCatalogMapWithErr: state.getCatalogMapWithErr,
    getGroupCatalog: state.getGroupCatalog,
  }));
  if (obj) {
    return ` <div style="margin-bottom: 2px;"
                    <div style="display: flex; margin-bottom: 2px;">
                      <div style="flex: 2;">
                        <Typography style="color: ${color(
                          'typographyColorMap2'
                        )}">Заявка №: </Typography>
                        <Typpography style="color: ${color('typographyColorMap1')}">
                        ${obj.number}
                        </Tyography>
                      </div>
                    </div>
                    <div style="display: flex;">
                      <div style="flex: 2;">
                        <Typography style="color: ${color(
                          'typographyColorMap2'
                        )}">Статаус: </Typography>
                        <Typpography style="color: ${color('typographyColorMap1')}">
                        ${getCatalogMapWithErr('taskStatus').get(obj.status) ?? ''}
                        </Tyography>
                      </div>
                      
                    </div>
                    
                    <div style="display: flex;">
                      <div style="flex: 2;">
                        <Typography style="color: ${color(
                          'typographyColorMap2'
                        )}">Ответственный: </Typography>
                      </div>
                      <div style="flex: 12;">
                      ${getGroupCatalog().get(obj.contractorId) ?? ''}
                      </div>
                    </div>
                    
                    <div style="display: flex;">
                      <div style="flex: 2;">
                        <Typography style="color: ${color(
                          'typographyColorMap2'
                        )}">Исполнитель: </Typography>
                      </div>
                      <div style="flex: 12;">
                      ${getGroupCatalog().get(obj.executorId) ?? ''}
                      </div>
                    </div>
                    
                    </div>
                  <div> <p style="cursor: pointer;  color: ${color('muiBlue')}" data-info="${
      obj.taskId
    }" style="color: ${color('typographyColorMap1')}">
                  <a href="/task/edit/${
                    obj.id
                  }" target="_blank" style="text-decoration: none">Редактировать</a>
                  </p></div>
</div></div>`;
  }
  return '';
}
