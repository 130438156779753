import { LoadingButton } from '@mui/lab';
import { Button, TextField, Typography } from '@mui/material';
import React from 'react';
import {
  OdpuManagementCompanyCommonModal,
  OdpuManagementCompanyForm,
} from './OdpuManagementCompanyCatalog.styled';
import { useOdpuManagementCompanyStore } from '../store';
import shallow from 'zustand/shallow';
import { Controller, FormProvider, useForm } from 'react-hook-form';
import { ManagementCompanyCreateRequestDto } from '../api/dto/ManagementCompanyRequestDto';
import { validationRules } from './utils';
import { formatPhoneNumberMask } from '@/utils/heplers';
import { addSuccessActionLog, useActionLog } from '@/hooks/ActionLogHook';
import {
  createOdpuManagementCompany,
  updateOdpuManagementCompany,
} from '../api/OdpuManagementCompanyService';
import { useCatalog } from '@/hooks/CatalogHook';

const OdpuManagementCompanyModal = () => {
  const { fetchCatch } = useActionLog();

  const {
    modalOpen,
    closeModal,
    openModal,
    selectedCompany,
    setUpdatingCompanyData,
    updatingCompanyData,
    updateCompanyData,
    addNewCompany,
  } = useOdpuManagementCompanyStore(
    (state) => ({
      modalOpen: state.modalOpen,
      closeModal: state.closeModal,
      openModal: state.openModal,
      selectedCompany: state.selectedCompany,
      setUpdatingCompanyData: state.setUpdatingCompanyData,
      updatingCompanyData: state.updatingCompanyData,
      updateCompanyData: state.updateCompanyData,
      addNewCompany: state.addNewCompany,
    }),
    shallow
  );
  const { updateCatalogMapWithErr } = useCatalog((state) => ({
    updateCatalogMapWithErr: state.updateCatalogMapWithErr,
  }));
  const methods = useForm<ManagementCompanyCreateRequestDto>();
  const { handleSubmit, reset, control } = methods;

  const handleClose = () => {
    reset();
    closeModal();
  };

  const onSubmit = (data: ManagementCompanyCreateRequestDto) => {
    const correctPhoneNumber = data?.phoneNumber?.replace(/\s+/g, '');
    if (correctPhoneNumber) {
      data.phoneNumber = correctPhoneNumber;
    }

    const apiCall = selectedCompany
      ? updateOdpuManagementCompany({ ...data, id: selectedCompany.id })
      : createOdpuManagementCompany(data);

    setUpdatingCompanyData(true);
    apiCall
      .then(({ data }) => {
        addSuccessActionLog(
          `Управляющая компания ${selectedCompany ? 'отредактирована' : 'создана'} успешно`
        );
        if (selectedCompany) {
          updateCompanyData(selectedCompany.id, data);
        } else {
          addNewCompany(data);
        }
        handleClose();
      })
      .catch(fetchCatch)
      .finally(() => {
        setUpdatingCompanyData(false);
        updateCatalogMapWithErr('odpuManagementCompany');
      });
  };

  return (
    <>
      <Button onClick={openModal}>+ Добавить</Button>

      <OdpuManagementCompanyCommonModal
        open={modalOpen}
        onClose={handleClose}
      >
        <Typography
          variant='h6'
          align='center'
        >
          {selectedCompany ? 'Редактирование управляющей компании' : 'Новая управляющая компания'}
        </Typography>
        <FormProvider {...methods}>
          <OdpuManagementCompanyForm onSubmit={handleSubmit(onSubmit)}>
            <Controller
              name='title'
              control={control}
              rules={validationRules.title}
              defaultValue={selectedCompany?.title}
              render={({ field, fieldState }) => (
                <TextField
                  id='title'
                  {...field}
                  fullWidth
                  label='Наименование'
                  variant='standard'
                  error={!!fieldState.error}
                  helperText={fieldState.error?.message}
                />
              )}
            />

            <Controller
              name='legalAddress'
              control={control}
              defaultValue={selectedCompany?.legalAddress}
              render={({ field, fieldState }) => (
                <TextField
                  id='legalAddress'
                  {...field}
                  fullWidth
                  label='Адрес'
                  variant='standard'
                  error={!!fieldState.error}
                  helperText={fieldState.error?.message}
                />
              )}
            />

            <Controller
              name='director'
              control={control}
              defaultValue={selectedCompany?.director}
              render={({ field, fieldState }) => (
                <TextField
                  id='director'
                  {...field}
                  fullWidth
                  label='Руководитель'
                  variant='standard'
                  error={!!fieldState.error}
                  helperText={fieldState.error?.message}
                />
              )}
            />

            <Controller
              name='phoneNumber'
              defaultValue={selectedCompany?.phoneNumber}
              control={control}
              rules={validationRules.phoneNumber}
              render={({ field: { onChange, value }, fieldState }) => (
                <TextField
                  id='phoneNumber'
                  onChange={(e) => onChange(formatPhoneNumberMask(e.target.value))}
                  value={value}
                  fullWidth
                  placeholder='+7'
                  label='Телефон'
                  variant='standard'
                  error={!!fieldState.error}
                  helperText={fieldState.error?.message}
                />
              )}
            />

            <div style={{ display: 'flex', gap: 20, marginTop: 20 }}>
              <LoadingButton
                variant='contained'
                type='submit'
                loading={updatingCompanyData}
              >
                {selectedCompany ? 'Сохранить' : 'Создать'}
              </LoadingButton>
              <Button
                variant='contained'
                onClick={handleClose}
              >
                Отмена
              </Button>
            </div>
          </OdpuManagementCompanyForm>
        </FormProvider>
      </OdpuManagementCompanyCommonModal>
    </>
  );
};

export default OdpuManagementCompanyModal;
