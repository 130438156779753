import { Box, Grid, Link, Modal, Skeleton, Typography } from '@mui/material';
import { useSearch } from '@/components/maps/zoningMap/hook/SearchHook';
import { useState } from 'react';
import { prettyNumber } from '../../utils/GeoUtils';

export const ZoningInfo = () => {
  const [showModal, setShowModal] = useState(false);
  const { zoningResponse, loading } = useSearch();

  return (
    <Grid
      container
      direction='column'
      justifyContent='space-between'
      alignItems='center'
    >
      <>
        <Modal
          onClose={() => setShowModal(false)}
          open={showModal}
        >
          <Box
            style={{
              width: '40%',
              margin: 'auto',
              height: 300,
              overflow: 'hidden',
              overflowY: 'scroll',
              paddingBottom: 2,
              position: 'absolute' as const,
              top: '50%',
              left: '50%',
              transform: 'translate(-50%, -100%)',
              minWidth: 300,
              backgroundColor: '#fff',
              border: '1px solid #000',
              padding: 20,
            }}
          >
            <Typography
              textAlign={'center'}
              marginBottom={3}
            >
              Всего: {prettyNumber(zoningResponse?.withoutGeoPointList?.length || 0)}
            </Typography>
            {zoningResponse?.withoutGeoPointList?.map((t) => (
              <li
                style={{ listStyle: 'none', margin: 2 }}
                key={t.taskId}
              >
                {t.taskId}
              </li>
            ))}
          </Box>
        </Modal>
        <Grid
          item
          container
          direction={'row'}
        >
          <Typography>
            Всего найдено: {loading || prettyNumber(zoningResponse?.total || 0)}
          </Typography>
          {loading && (
            <Skeleton
              sx={{ marginX: 1 }}
              animation='wave'
              width={'40px'}
            ></Skeleton>
          )}
        </Grid>
        <Grid
          item
          container
          direction={'row'}
        >
          <Typography
            sx={{ textAlign: 'end' }}
            variant='body2'
            align='right'
          >
            Заявок без координат:
            {loading || (
              <>
                {zoningResponse?.withoutGeoPointList &&
                zoningResponse?.withoutGeoPointList?.length > 0 ? (
                  <Link
                    marginX={1}
                    href='#'
                    onClick={() => setShowModal(true)}
                  >
                    {prettyNumber(zoningResponse?.withoutGeoPointList?.length || 0)}
                  </Link>
                ) : (
                  0
                )}
              </>
            )}
          </Typography>
          {loading && (
            <Skeleton
              sx={{ marginX: 1 }}
              animation='wave'
              width={'40px'}
            ></Skeleton>
          )}
        </Grid>
      </>
    </Grid>
  );
};
