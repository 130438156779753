import { color } from '@/styles/mixins';
import { styled } from '@mui/material';

export const GSMInfoTitle = styled('div')({
  fontFamily: 'Roboto',
  fontSize: 16,
  fontWeight: 700,
  lineHeight: '20px',
  letterSpacing: 0.4,
  textAlign: 'right',

  color: color('fontLightGray'),
});

export const GSMInfoValue = styled('div')({
  fontFamily: 'Roboto',
  fontSize: 16,
  fontWeight: 500,
  lineHeight: '20px',
  letterSpacing: 0.4,
  textAlign: 'left',

  color: color('trueBlack', 0.87),
});
