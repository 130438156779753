import { createStore } from '@/utils/createStore';
import { DisplayManufacturer, TimePeriod } from './types/types';
import { SelectFilterOptType } from '@/components/filter/MultySelectFilter';
import { timePeriod } from './ui/utils';
import {
  ChartBarDataResponse,
  DynamicStatisticResponse,
  StatisticSummaryResponse,
} from './api/dto/response';

interface InvestStatisticData {
  equipmentsData: StatisticSummaryResponse[];
  showInfo: boolean;
  selectedContract: SelectFilterOptType[];
  selectedEnergyCompany: SelectFilterOptType[];
  activeIndex: number | null;
  selectedColumn: number | null;
  checkedEnergyCompany: boolean;
  dataColumns: ChartBarDataResponse[];
  dataDynamicStatistic: DynamicStatisticResponse[];
  selectedTimePeriod: TimePeriod;
  countAsMoney: boolean;
  displayManufacturer: DisplayManufacturer;
  displayTechDesicion: boolean;
}

interface InvestStatisticFunctions {
  setEquipmentsData: (equipmentsData: StatisticSummaryResponse[]) => void;
  setShowInfo: (showInfo: boolean) => void;
  setSelectedContract: (selectedContract: SelectFilterOptType[]) => void;
  setSelectedEnergyCompany: (selectedEnergyCompany: SelectFilterOptType[]) => void;
  setActiveIndex: (activeIndex: number | null) => void;
  setSelectedColumn: (selectedColumn: number | null) => void;
  setCheckedEnergyCompany: (checkedEnergyCompany: boolean) => void;
  setDataColumns: (dataColumns: ChartBarDataResponse[]) => void;
  setDataDynamicStatistic: (dataDynamicStatistic: DynamicStatisticResponse[]) => void;
  setSelectedTimePeriod: (selectedTimePeriod: TimePeriod) => void;
  setCountAsMoney: (countAsMoney: boolean) => void;
  setDisplayManufacturer: (updater: (prev: DisplayManufacturer) => DisplayManufacturer) => void;
  setDisplayTechDesicion: (displayTechDesicion: boolean) => void;
}
export interface InvestStatisticStore extends InvestStatisticData, InvestStatisticFunctions {}

export const useInvestStatisticStore = createStore<InvestStatisticStore>(
  (set, get) => ({
    equipmentsData: [],
    showInfo: false,
    selectedContract: [],
    selectedEnergyCompany: [],
    activeIndex: null,
    selectedColumn: null,
    checkedEnergyCompany: false,
    dataColumns: [],
    dataDynamicStatistic: [],
    selectedTimePeriod: timePeriod[2],
    countAsMoney: false,
    displayManufacturer: {
      nartis: false,
      lenenergo: false,
    },
    displayTechDesicion: false,

    setEquipmentsData: (equipmentsData: StatisticSummaryResponse[]) => set({ equipmentsData }),
    setShowInfo: (showInfo: boolean) => set({ showInfo }),
    setSelectedContract: (selectedContract: SelectFilterOptType[]) => set({ selectedContract }),
    setSelectedEnergyCompany: (selectedEnergyCompany: SelectFilterOptType[]) =>
      set({ selectedEnergyCompany }),
    setActiveIndex: (activeIndex: number | null) => set({ activeIndex }),
    setSelectedColumn: (selectedColumn: number | null) => set({ selectedColumn }),
    setCheckedEnergyCompany: (checkedEnergyCompany: boolean) => set({ checkedEnergyCompany }),
    setDataColumns: (dataColumns: ChartBarDataResponse[]) => set({ dataColumns }),
    setDataDynamicStatistic: (dataDynamicStatistic: DynamicStatisticResponse[]) =>
      set({ dataDynamicStatistic }),
    setSelectedTimePeriod: (selectedTimePeriod: TimePeriod) => set({ selectedTimePeriod }),
    setCountAsMoney: (countAsMoney: boolean) => set({ countAsMoney }),
    setDisplayManufacturer: (updater: (prev: DisplayManufacturer) => DisplayManufacturer) => {
      set((state) => ({
        displayManufacturer: updater(state.displayManufacturer),
      }));
    },
    setDisplayTechDesicion: (displayTechDesicion: boolean) => set({ displayTechDesicion }),
  }),
  'InvestStatistic'
);
