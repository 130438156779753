import { styled, TextField as TextField_ } from '@mui/material';
import { useFormContext, useWatch } from 'react-hook-form';
import CopyButton from '@/components/button/CopyButton/CopyButton';

export const TextField = styled(TextField_)({
  '&.MuiTextField-root .MuiInputLabel-root.Mui-disabled': {
    color: 'rgba(0, 0, 0, 0.6)',
  },
  '& .MuiInputBase-input.Mui-disabled': {
    color: '#000',
    opacity: 1,
    textFillColor: 'rgba(0, 0, 0, 1)',
    cursor: 'not-allowed',
  },
  '&.MuiTextField-root [data-shrink="true"] ~ .MuiInput-root::before': {
    borderBottom: '2px solid #000000',
  },

  '&[contenteditable="false"] > .MuiInput-root::before': {
    borderBottom: '1px solid #000000 !important',
  },
});

interface TextFieldCopy {
  registerName: string;
  value?: string;
  label: string;
  disabled?: boolean;
  isChange?: (flag: boolean) => void;
}

export const TextFieldCopy = (props: TextFieldCopy) => {
  const { registerName, value, label, disabled, isChange } = props;
  const { register, control } = useFormContext();
  const firstName = useWatch({ control, name: registerName });

  return (
    <TextField
      id={`tf-${label}`}
      label={label}
      variant='standard'
      disabled={disabled}
      {...register(registerName, { value: value })}
      onChange={(e) => {
        register(registerName, { value: value }).onChange(e);
        isChange && isChange(true);
      }}
      sx={{ width: '100%' }}
      InputProps={{
        startAdornment:
          firstName ?? value ? (
            <div style={{ order: 2 }}>
              <CopyButton
                onlyIcon
                tooltipText={'копировать'}
                textToCopy={firstName ?? value}
              />
            </div>
          ) : null,
      }}
    />
  );
};
