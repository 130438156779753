import { useQuery } from 'react-query';
import { KEYS } from './Keys';
import { useActionLog } from '@/hooks/ActionLogHook';
import { findKidsAndParents } from '@/services/TaskService/TaskService';

export function useKidsAndParents(taskId?: string, enabled = false) {
  const { fetchCatch } = useActionLog();

  const {
    data: axiosResponse,
    isFetching,
    error,
  } = useQuery({
    queryKey: [...KEYS.taskSiblings, taskId],
    enabled: enabled && !!taskId,
    queryFn: () => findKidsAndParents(taskId),
    staleTime: Infinity,
    onError: (error) => {
      fetchCatch(error);
    },
  });

  const siblings = axiosResponse?.data;

  return {
    data: siblings,
    isFetching,
    error,
  };
}
