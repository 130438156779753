import { HOST, YodaRestClient } from '@/services/YodaAuthService';
import { ApiResponse } from '@/types/api';
import { CableDescentRequest } from './dto/CableDescentDtoRequest';
import { CableDescentResponse } from './dto/CableDescentDtoResponse';

export async function saveCableDescent(
  id: string,
  cableDescent: CableDescentRequest
): ApiResponse<CableDescentResponse> {
  const url = HOST + `/rest/cable-descent/${id}`;
  return YodaRestClient.put(url, cableDescent);
}
