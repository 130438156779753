import { FC } from 'react';
import { TagName, TagWrapper } from '@/components/Tags/Tag.styled';
import { RemoveTagIcon } from '@/components/Tags/RemoveTagIcon';
import { useCatalog } from '@/hooks/CatalogHook';
import { deleteTag } from '@/components/Tags/api/TagsService';
import { useActionLog } from '@/hooks/ActionLogHook';
import { TagsResponse } from '@/components/Tags/api/dto/TagsDto';

interface ITag {
  id: string;
  tagName: string;
  handleUpdate: () => void;
}

export const Tag: FC<ITag> = ({ id, tagName, handleUpdate }) => {
  const { fetchCatch } = useActionLog();
  const clickHandler = () => {
    deleteTag(id)
      .then(({ data }) => {
        handleUpdate();
      })
      .catch((err) => fetchCatch(err, 'Ошибка удаления тега'));
  };
  return (
    <TagWrapper>
      <TagName>{tagName}</TagName>
      <RemoveTagIcon onClick={clickHandler} />
    </TagWrapper>
  );
};
