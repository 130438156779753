import { useState } from 'react';
import VisibilityIcon from '@mui/icons-material/RemoveRedEyeOutlined';
import VisibilityOffIcon from '@mui/icons-material/VisibilityOff';
import CloseIcon from '@mui/icons-material/Close';
import { DetailedInfoItem } from './DetailedInfoItem';
import { color } from '@/styles/mixins';
import { Rnd } from 'react-rnd';
import { Portal, IconButton } from '@mui/material';
import {
  DetailedInfoButtonBox,
  DetailedInfoButtonIcon,
  DetailedInfoButtonPortalBox,
} from './DetailedInfoButton.styled';

export interface DetailedInfoButtonProps {
  info: DetailedInfoItem[];
  disable?: boolean;
}

export const DetailedInfoButton = ({ info, disable }: DetailedInfoButtonProps) => {
  const [open, setOpen] = useState(false);
  const defaultPosition = {
    x: 10,
    y: 100,
    width: 'auto',
    height: 'auto',
  };

  return (
    <>
      <IconButton
        onClick={() => {
          if (!disable) setOpen((prev) => !prev);
        }}
        style={{
          cursor: disable ? 'not-allowed' : 'pointer',
        }}
      >
        {open ? (
          <VisibilityOffIcon
            fontSize='large'
            style={{ color: color('muiBlue') }}
          />
        ) : (
          <VisibilityIcon
            fontSize='large'
            style={{ color: color('muiBlue') }}
          />
        )}
      </IconButton>

      {open && (
        <Portal>
          <DetailedInfoButtonPortalBox
            onClick={(e) => e.target === e.currentTarget && setOpen(false)}
          >
            <Rnd
              default={defaultPosition}
              minWidth={1}
              minHeight={1}
              bounds='window'
            >
              <DetailedInfoButtonBox onClick={(e) => e.stopPropagation()}>
                {info?.map((infoElement, index) => (
                  <DetailedInfoItem
                    key={infoElement.title}
                    index={index}
                    {...infoElement}
                  />
                ))}

                <DetailedInfoButtonIcon
                  onTouchStart={() => setOpen(false)}
                  onClick={() => setOpen(false)}
                >
                  <CloseIcon />
                </DetailedInfoButtonIcon>
              </DetailedInfoButtonBox>
            </Rnd>
          </DetailedInfoButtonPortalBox>
        </Portal>
      )}
    </>
  );
};
