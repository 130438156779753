import { ApiResponse } from '@/types/api';
import { HOST, YodaRestClient } from '@/services/YodaAuthService';
import {
  CallCenterTaskCommentDto,
  CallCenterSendedToBillingDto,
  CallCenterScheduledDateDto,
  CallCenterRemoteSolutionDto,
  CallCenterRefundDto,
  CallCenterFilterParam,
  CallCenterRequest,
} from './dto/CallCenterServiceRequestDto';
import {
  CallCenterBashkortostanResponse,
  CallCenterWorkType,
  CallCenterCallType,
  CallCenterRequestTableItem,
} from './dto/CallCenterServiceResponseDto';

export async function ApiGetWorkTypes(callTypeIds: string[]): ApiResponse<CallCenterWorkType[]> {
  const url = HOST + `/rest/callcentre/getWorkTypes` + '?callTypeIds=' + callTypeIds;
  return YodaRestClient.get(url);
}

export async function ApiSaveCallCenterTaskComment(
  taskComment: CallCenterTaskCommentDto
): ApiResponse<string> {
  const url = HOST + `/rest/callcentre/save-task-comment`;
  return YodaRestClient.post(url, taskComment);
}

export async function ApiSaveSendToBilling(
  refundDto: CallCenterSendedToBillingDto
): ApiResponse<string> {
  const url = HOST + `/rest/callcentre/send-to-billing`;
  return YodaRestClient.post(url, refundDto);
}

export async function UpdateCallCenterRequestScheduledDate(
  request: CallCenterScheduledDateDto
): ApiResponse<any> {
  const url = HOST + '/rest/callcentre/bashkortostan/updateScheduledDate';
  return YodaRestClient.post(url, request);
}

export async function ApiSaveCallCenterRequest(
  callCenterRequest: CallCenterRequest
): ApiResponse<string> {
  const url = HOST + `/rest/callcentre/saveCallCenterRequest`;
  return YodaRestClient.post(url, callCenterRequest);
}

export async function ApiSaveRemoteSolution(
  refundDto: CallCenterRemoteSolutionDto
): ApiResponse<string> {
  const url = HOST + `/rest/callcentre/remote-solution`;
  return YodaRestClient.post(url, refundDto);
}

export async function ApiSaveCallCenterRefund(refundDto: CallCenterRefundDto): ApiResponse<string> {
  const url = HOST + `/rest/callcentre/save-refund`;
  return YodaRestClient.post(url, refundDto);
}

export async function ApiGetCallTypes(): ApiResponse<CallCenterCallType[]> {
  const url = HOST + `/rest/callcentre/getCallTypes`;
  return YodaRestClient.get(url);
}

export async function GetCallCenterRequestBashkortostanbyTaskId(
  taskId: string
): ApiResponse<CallCenterBashkortostanResponse> {
  const url = HOST + `/rest/callcentre/bashkortostan/${taskId}`;
  return YodaRestClient.get(url);
}

export async function getCallCenterRequests(
  filter: CallCenterFilterParam,
  offset: number,
  limit: number
): ApiResponse<CallCenterRequestTableItem[]> {
  const url =
    HOST +
    `/rest/callcentre/find-callcenter-requests-byparam` +
    '?offset=' +
    offset +
    '&limit=' +
    limit;
  return YodaRestClient.post(url, filter);
}

export async function getCountOfCallCenterRequests(
  filter: CallCenterFilterParam
): ApiResponse<number> {
  const url = HOST + `/rest/callcentre/get-count-callcenter-requests-byparam`;
  return YodaRestClient.post(url, filter);
}
