import React, { FC, useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { findAllResultByTaskIdAndType } from '@/services/TaskService/TaskService';
import { ChronologyResponse, TaskResultType } from '@/dto/taskmap/task/TaskResultDto';
import ChronologyRow from './ChronologyRow';
import { Loading } from '@/components/ui/Loading';
import { Colors } from '@/styles/colors';
import { useActionLog } from '@/hooks/ActionLogHook';
import { ChronologyLine } from './Chronology.styled';

const getBorderColor = ({ title, comment }: ChronologyResponse): Colors | undefined => {
  switch (title) {
    case 'TKO':
      return 'chronologyTKO';
    case 'Замена':
      return 'chronologyReplacement';
  }
  if (comment === 'Заявка создана') {
    return 'chronologyCreate';
  }
  return undefined;
};

const Chronology: FC = () => {
  const { taskId } = useParams();

  const [chronologyList, setChronologyList] = useState([]);
  const [loading, setLoading] = useState<boolean>(false);
  const { fetchCatch } = useActionLog();

  useEffect(() => {
    setLoading(true);
    taskId &&
      findAllResultByTaskIdAndType(taskId, TaskResultType.EVENT)
        .then((r) => setChronologyList(r.data))
        .catch((r) => fetchCatch(r, 'Ошибка загрузки хронологии событий'))
        .finally(() => setLoading(false));
  }, []);

  return (
    <div>
      <Loading loading={loading}>
        {chronologyList.map((elem: ChronologyResponse, index, arr) => (
          <div key={index}>
            {!getBorderColor(elem) && index !== 0 && !getBorderColor(arr[index - 1]) && (
              <ChronologyLine />
            )}
            <ChronologyRow
              {...elem}
              borderColor={getBorderColor(elem)}
            />
          </div>
        ))}
      </Loading>
    </div>
  );
};

export default Chronology;
