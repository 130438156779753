import {
  Backdrop,
  Box,
  Button,
  CircularProgress,
  Dialog,
  Grid,
  Stack,
  Typography,
} from '@mui/material';
import { FC, useEffect, useState } from 'react';
import { ReplacementResponse } from '@/dto/taskmap/task/TaskResultDto';
import { useActionLog } from '@/hooks/ActionLogHook';
import { ReplacementViewItem } from './ReplacementViewItem';
import { TextField } from '@/components/fixUI';
import { fidnReplacementById } from '@/services/ReplacementService/ReplacementService';
import { getSingleTask } from '@/services/TaskService/TaskService';
import { TaskResponse } from '@/dto/taskmap/task/TasksDto';
import FilterButtons from '@/components/filter/FilterButtons/FilterButtons';
import { getNetworkById } from '@/services/NetworkService/NetworkService';
import { NetworkElementResponse } from '@/services/NetworkService/dto/NetworkResponseDto';

export type CombinedDto = (ReplacementResponse & TaskResponse) | NetworkElementResponse;

export enum SearchEnam {
  SEARCH_BY_TASK_ID,
  SEARCH_WITHOUT_TASK,
  SEARCH_BY_NETWORK_ID,
}

interface ReplacementViewDialogProps {
  toSwap?(id?: string): void;
  swapByTaskId?(taskId?: string): void;
  handleMoveNetworkElement?(meterChanngeId?: string): void;
  handleSwapNetworkElement?(toSwapnetworkElementId?: string): void;
  closeDialog(): void;
  openDialog: boolean;
  isNetworkElement?: boolean;
}

export const ReplacementViewDialog: FC<ReplacementViewDialogProps> = ({
  toSwap,
  closeDialog,
  openDialog,
  swapByTaskId,
  isNetworkElement,
  handleMoveNetworkElement,
  handleSwapNetworkElement,
}) => {
  const { fetchCatch } = useActionLog();
  const [loading, setLoading] = useState<boolean>(false);
  const [open, setOpen] = useState<boolean>(false);
  const [searchParam, setSearchParam] = useState<SearchEnam>(
    isNetworkElement ? SearchEnam.SEARCH_BY_NETWORK_ID : SearchEnam.SEARCH_WITHOUT_TASK
  );
  const [filterId, setFilterId] = useState<string>('');
  const [dataResponse, setDataResponse] = useState<CombinedDto | null>(null);

  useEffect(() => {
    setOpen(openDialog);
  }, [openDialog]);

  useEffect(() => {
    setFilterId('');
    setDataResponse(null);
  }, [searchParam]);

  const handleRequest = (promise: Promise<any>, errorMessage: string) => {
    promise
      .then(({ data }) => setDataResponse(data))
      .catch((err) => {
        fetchCatch(err, errorMessage);
        setDataResponse(null);
      })
      .finally(() => setLoading(false));
  };

  const onClickHandler = () => {
    setLoading(true);
    switch (searchParam) {
      case SearchEnam.SEARCH_BY_TASK_ID:
        handleRequest(getSingleTask(filterId), 'Замена не найдена');
        break;
      case SearchEnam.SEARCH_WITHOUT_TASK:
        handleRequest(fidnReplacementById(filterId), 'Замена не найдена');
        break;
      case SearchEnam.SEARCH_BY_NETWORK_ID:
        handleRequest(getNetworkById(filterId), 'GSM-модем не найден');
        break;
      default:
        setLoading(false);
        break;
    }
  };

  const handleClose = () => {
    setOpen(false);
    closeDialog();
  };

  const handleClick = () => {
    setFilterId('');
    setDataResponse(null);
  };

  const renderText = () => {
    if (searchParam === SearchEnam.SEARCH_BY_NETWORK_ID) {
      return 'GSM-модема';
    } else if (searchParam === SearchEnam.SEARCH_BY_TASK_ID) {
      return 'заявки';
    } else {
      return 'замены';
    }
  };

  const handleBtnWihoutReplacment = () => {
    if (isNetworkElement) {
      if (searchParam === SearchEnam.SEARCH_WITHOUT_TASK) {
        setSearchParam(SearchEnam.SEARCH_BY_NETWORK_ID);
      } else {
        setSearchParam(SearchEnam.SEARCH_WITHOUT_TASK);
      }
    } else {
      if (searchParam === SearchEnam.SEARCH_BY_TASK_ID) {
        setSearchParam(SearchEnam.SEARCH_WITHOUT_TASK);
      } else {
        setSearchParam(SearchEnam.SEARCH_BY_TASK_ID);
      }
    }
  };

  return (
    <Dialog
      open={open}
      onClose={handleClose}
      fullWidth
      maxWidth={'lg'}
    >
      <Backdrop
        sx={{ color: '#fff' }}
        open={loading}
      >
        <CircularProgress color='inherit' />
      </Backdrop>

      <Stack
        spacing={3}
        padding={4}
      >
        <Grid
          container
          spacing={2}
          alignItems={'center'}
          justifyContent={'center'}
        >
          <Grid item>
            <Typography
              textAlign={'center'}
              variant='h5'
            >
              {`Введите id ${renderText()}`}
            </Typography>
          </Grid>
          <Grid item>
            {isNetworkElement ? (
              <Button
                onClick={handleBtnWihoutReplacment}
                variant='outlined'
              >
                Нет {renderText()}
              </Button>
            ) : (
              <Button
                onClick={handleBtnWihoutReplacment}
                variant='outlined'
              >
                Нет {renderText()}
              </Button>
            )}
          </Grid>
        </Grid>
        <Grid
          container
          spacing={4}
          alignItems={'center'}
          justifyContent={'center'}
        >
          <Grid
            item
            xs={9}
          >
            <TextField
              id='search-id'
              label={`id ${renderText()}`}
              value={filterId}
              onChange={(e) => setFilterId(e.target.value)}
              style={{ width: '100%' }}
            />
          </Grid>
          <Grid
            item
            container
            xs={2}
          >
            <FilterButtons
              onCloseClick={handleClick}
              onSearchClick={onClickHandler}
            />
          </Grid>
        </Grid>
        <Grid>
          {dataResponse && typeof dataResponse === 'object' && (
            <Box
              key={`replacement-item-${dataResponse.id}`}
              sx={{ p: 2, border: '1px solid black', marginY: 2 }}
            >
              <ReplacementViewItem
                dataResponse={dataResponse}
                toSwap={toSwap}
                searchResult={searchParam}
                query={filterId}
                swapByTaskId={swapByTaskId}
                handleMoveNetworkElement={handleMoveNetworkElement}
                handleSwapNetworkElement={handleSwapNetworkElement}
              />
            </Box>
          )}
          {dataResponse !== null && !dataResponse && (
            <Typography
              textAlign={'center'}
              variant='h5'
              paddingTop={3}
            >
              Ничего не найдено
            </Typography>
          )}
        </Grid>
      </Stack>
    </Dialog>
  );
};
