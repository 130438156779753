import React, { FC, useState } from 'react';
import { Form } from './Form';
import { FormProvider, useForm } from 'react-hook-form';
import { BoxShadow } from '@/components/ui/BoxShadow';
import AddRoundedIcon from '@mui/icons-material/AddRounded';
import { addTKO } from '@/services/TaskService/TaskService';
import { convertFormData } from './utils';
import { ActionLogType, useActionLog } from '@/hooks/ActionLogHook';

import CloseIcon from '@mui/icons-material/Close';
import { SaveButton } from '@/components/ui/SaveButton/SaveButton';
import { useTaskEditorOnlyShow } from '@/components/features/taskEditor/useTaskEditorOnlyShow';
import { useDisabledAll } from '@/hooks/useAccess';

interface IAddTKO {
  contractId?: string;
  afterAdd: () => void;
  taskId?: string;
}

export const AddTKO: FC<IAddTKO> = ({ contractId, afterAdd, taskId }) => {
  const [isOpenForm, setIsOpenForm] = useState<boolean>(false);
  const { fetchCatch, addActionLog } = useActionLog();

  const methods = useForm();

  const onSubmit = (e: any) => {
    addTKO(convertFormData(e), taskId)
      .then(() => {
        addActionLog(ActionLogType.SUCCESS, 'Создан ТКО');
        afterAdd();
        setIsOpenForm(false);
        methods.reset({
          TKOForm: {
            exMeterType: '',
            meterState: '',
            meterNum: '',
            stdoBasicTask: '',
            stampNum: '',
            t1: '',
            t2: '',
            t3: '',
            comment: '',
          },
        });
      })
      .catch((err) => fetchCatch(err, 'ТКО не создано, попробуйте заполните пропущенные поля'));
  };
  const onlyShow = useTaskEditorOnlyShow();
  const disabledAll = useDisabledAll();
  const disabled = disabledAll || onlyShow || !methods.watch(`TKOForm.statusId`);
  return (
    <BoxShadow sx={{ mt: 2, p: 1.4, pl: 2, width: isOpenForm ? 'calc(50% - 8px)' : '20%' }}>
      {isOpenForm ? (
        <div>
          <FormProvider {...methods}>
            <Form
              isAddedNewTKO
              contractId={contractId}
            />
            <div
              style={{
                marginTop: 16,
                display: 'flex',
                justifyContent: 'flex-end',
                alignItems: ' center',
              }}
            >
              <CloseIcon
                onClick={() => setIsOpenForm(false)}
                style={{ cursor: 'pointer' }}
              />
              <SaveButton
                onSave={methods.handleSubmit(onSubmit)}
                disabled={disabled}
              />
            </div>
          </FormProvider>
        </div>
      ) : (
        <div
          onClick={() => setIsOpenForm(true)}
          style={{ display: 'flex', alignItems: 'center', cursor: 'pointer' }}
        >
          <span>Создать ТКО</span>
          <AddRoundedIcon
            fontSize={'large'}
            sx={{ ml: 2 }}
          />
        </div>
      )}
    </BoxShadow>
  );
};
