import React, { FC } from 'react';
import { Grid } from '@mui/material';
import { AutocompleteValue } from '@/components/inputs/AutocompleteValue/AutocompleteValue';
import { useCatalog } from '@/hooks/CatalogHook';
import { useStatus } from '@/components/features/taskEditor/panel/store';
import VoltageTn, { TNMount } from './VoltageTn';
import { TransformerVoltageResponseDto } from '@/services/TransformerService/dto/TransformerResponseDto';

interface IVoltageBlock {
  data: TransformerVoltageResponseDto;
}
export const VoltageBlock: FC<IVoltageBlock> = ({ data }) => {
  const { getCatalogMapWithErr } = useCatalog((state) => ({
    getCatalogMapWithErr: state.getCatalogMapWithErr,
  }));
  const isDisabledAll = useStatus((state) => state.isDisabledAll);

  return (
    <>
      <Grid
        item
        xs={3}
      >
        <AutocompleteValue
          title='KTН'
          defaultValue={data?.ktnId}
          sx={{ minWidth: '130px' }}
          fieldName={`ktnId`}
          values={getCatalogMapWithErr('ktnType')}
          disabled={isDisabledAll()}
        />
      </Grid>
      <Grid
        container
        xs={12}
        item
      >
        <Grid
          item
          xs={6}
        >
          <VoltageTn
            mountType={TNMount.UNMOUNT}
            transformerVoltage={data.unmount}
          />
        </Grid>
        <Grid
          item
          xs={6}
        >
          <VoltageTn
            mountType={TNMount.MOUNT}
            transformerVoltage={data.mount}
          />
        </Grid>
      </Grid>
    </>
  );
};
