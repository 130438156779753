import React, { useEffect, useState } from 'react';
import {
  ComplaintActionButtonsWrapper,
  ComplaintCommentButton,
  ComplaintCommentIconButton,
  ComplaintCommentsWrapper,
  ComplaintCommentWrapper,
} from './ComplaintComment.styled';
import CommentOutlinedIcon from '@mui/icons-material/CommentOutlined';
import { Grid, Stack, TextField, Typography, CircularProgress, IconButton } from '@mui/material';
import { color } from '@/styles/mixins';
import CheckCircleOutlineIcon from '@mui/icons-material/CheckCircleOutline';
import { ReactComponent as ActIcon } from '@/images/icons/actIcon.svg';
import {
  createComment,
  getAllCommentsByComplaintId,
} from '@/services/ComplaintService/ComplaintService';
import { ActionLogType, useActionLog } from '@/hooks/ActionLogHook';
import { useComplaintStore } from '../store';
import shallow from 'zustand/shallow';
import moment from 'moment';
import { CommentCompalaint } from '../types';
import { useComplaintsEditAccess } from '../roles';
import ComplaintCommentSkeleton from './ComplaintCommentSkeleton';
import {
  ComplaintActivationButton,
  ComplaintActivationWrapper,
} from '../ComplaintActivation/ComplaintActivation.styled';
import { useMobileSize } from '@/hooks/useMediaQuery/useMobileSize';

import { isTypeEnergyService } from '../utils';

import ClearRoundedIcon from '@mui/icons-material/ClearRounded';

import ComplaintActivationComponent from '../ComplaintActivation/ComplaintActivationComponent';
import Slide from '@material-ui/core/Slide';
import { useComplaintActStore } from '../ComplaintActivation/store';
import { useHasAnyRoleSet } from '@/hooks/useAccess';
import { RolesEnum } from '@/app/auth/roles';

const ComplaintComment = () => {
  const hasAccessToEdit = useComplaintsEditAccess();
  const isNotComplaintCustomer = useHasAnyRoleSet([
    [RolesEnum.ComplaintManager, RolesEnum.ComplaintEnergo],
  ]);

  const isMobile = useMobileSize();
  const [open, setOpen] = useState(!isMobile);
  const [isActivationOpen, setisActivationOpen] = useState(false);
  const [text, setText] = useState('');
  const [comments, setComments] = useState<CommentCompalaint[]>([]);
  const [isLoading, setIsLoading] = useState(false);
  const { addActionLog, fetchCatch } = useActionLog();

  const { selectedCompliant, setIsCommentsLoading, isCommentsLoading } = useComplaintStore(
    (state) => ({
      selectedCompliant: state.selectedCompliant,
      setIsCommentsLoading: state.setIsCommentsLoading,
      isCommentsLoading: state.isCommentsLoading,
    }),
    shallow
  );

  const { compalintAct, fetchAct } = useComplaintActStore(
    (state) => ({
      compalintAct: state.compalintAct,
      fetchAct: state.fetchAct,
    }),
    shallow
  );
  const selectedCompliantId = selectedCompliant?.complaint?.base?.id;

  const handleChange = (event: any) => {
    setText(event.target.value);
  };
  const onKeyPress = (event: any) => {
    if (event.key === 'Enter') {
      handleSaveComment();
    }
  };

  const handleSaveComment = () => {
    if (!selectedCompliantId || !hasAccessToEdit || !text) return;
    setIsLoading(true);
    createComment({ text }, selectedCompliantId)
      .then(() => {
        addActionLog(ActionLogType.SUCCESS, 'Комментарий сохранен');
        getComments();
      })
      .catch((err) => fetchCatch(err))
      .finally(() => {
        setText('');
        setIsLoading(false);
      });
  };

  const getComments = () => {
    if (selectedCompliantId) {
      setIsCommentsLoading(true);
      getAllCommentsByComplaintId(selectedCompliantId)
        .then(({ data }) => {
          setComments(data.content);
        })
        .catch((error) => fetchCatch(error))
        .finally(() => setIsCommentsLoading(false));
    }
  };

  const handleClick = () => setOpen(!open);
  const handleClickActivation = () => {
    setisActivationOpen(!isActivationOpen);
  };

  useEffect(() => {
    getComments();
    if (selectedCompliantId && isNotComplaintCustomer) fetchAct(selectedCompliantId);
  }, [selectedCompliantId]);

  useEffect(() => {
    if (!compalintAct) {
      setisActivationOpen(false);
    }
  }, [compalintAct]);

  return (
    <ComplaintCommentWrapper open={open}>
      <ComplaintActionButtonsWrapper>
        {isTypeEnergyService(selectedCompliant?.complaint.type) && compalintAct && (
          <ComplaintActivationButton
            open={isActivationOpen}
            onClick={handleClickActivation}
          >
            <ActIcon />
          </ComplaintActivationButton>
        )}

        <ComplaintCommentButton
          open={open}
          onClick={handleClick}
        >
          <CommentOutlinedIcon />
        </ComplaintCommentButton>
      </ComplaintActionButtonsWrapper>
      <Slide
        direction='up'
        in={open}
        mountOnEnter
        unmountOnExit
      >
        <Stack>
          <ComplaintCommentsWrapper
            isMobile={isMobile}
            open={open}
          >
            <Stack>
              <Stack
                flexDirection={'row'}
                justifyContent={'space-between'}
                alignItems={'baseline'}
              >
                <Typography
                  variant='subtitle1'
                  color={color('trueBlack')}
                >
                  Комментарии
                </Typography>
                <IconButton onClick={handleClick}>
                  <ClearRoundedIcon />
                </IconButton>
              </Stack>

              <Stack
                maxHeight={isMobile ? 250 : 300}
                overflow={'auto'}
              >
                {isCommentsLoading ? (
                  <ComplaintCommentSkeleton />
                ) : (
                  <>
                    {comments.map((el) => (
                      <Stack key={el.base.id}>
                        <Grid
                          container
                          spacing={1}
                        >
                          <Grid
                            item
                            xs={6}
                          >
                            <Typography
                              variant='subtitle2'
                              color={color('muiBlue')}
                            >
                              {moment(el.base.createAt).format('DD.MM.YYYY')}
                            </Typography>
                          </Grid>
                          <Grid
                            item
                            xs={6}
                          >
                            <Typography
                              textAlign={'end'}
                              variant='subtitle2'
                              color={color('muiBlue')}
                            >
                              {el.base.createBy || 'автор'}
                            </Typography>
                          </Grid>
                          <Grid
                            item
                            xs={12}
                          >
                            <Typography
                              style={{ whiteSpace: 'pre-wrap', wordBreak: 'break-word' }}
                              variant='subtitle2'
                            >
                              {el.text}
                            </Typography>
                          </Grid>
                        </Grid>
                      </Stack>
                    ))}
                    {comments.length === 0 && (
                      <Typography
                        variant='body2'
                        color='textSecondary'
                      >
                        Нет комментариев
                      </Typography>
                    )}
                  </>
                )}
                <Grid
                  item
                  xs={10}
                  display={'flex'}
                  alignItems={'flex-end'}
                  paddingBottom={3}
                >
                  <TextField
                    multiline
                    variant='standard'
                    fullWidth
                    value={text}
                    disabled={!hasAccessToEdit || isCommentsLoading}
                    onChange={handleChange}
                    onKeyPress={onKeyPress}
                  />

                  <ComplaintCommentIconButton
                    onClick={handleSaveComment}
                    color='success'
                    disabled={!text || !hasAccessToEdit}
                  >
                    {isLoading ? <CircularProgress size={24} /> : <CheckCircleOutlineIcon />}
                  </ComplaintCommentIconButton>
                </Grid>
              </Stack>
            </Stack>
          </ComplaintCommentsWrapper>
        </Stack>
      </Slide>

      {compalintAct && (
        <Slide
          direction='up'
          in={isActivationOpen}
          mountOnEnter
          unmountOnExit
        >
          <Stack>
            <ComplaintActivationWrapper isMobile={isMobile}>
              <ComplaintActivationComponent handleClickActivation={handleClickActivation} />
            </ComplaintActivationWrapper>
          </Stack>
        </Slide>
      )}
    </ComplaintCommentWrapper>
  );
};

export default ComplaintComment;
