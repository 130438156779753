import { styled } from '@mui/material';
import { DataGridPro as DataGrid_ } from '@mui/x-data-grid-pro';

export const DataGrid = styled(DataGrid_)({
  '&.MuiDataGrid-root': {
    display: 'flex',
  },

  '.MuiTablePagination-selectLabel': {
    paddingTop: 11,
  },
  '.MuiTablePagination-displayedRows': {
    paddingTop: 16,
  },
});
