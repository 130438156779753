import { ResultType, Subscriber } from '@/components/maps/ppoMap/dto';

interface ICheckActive {
  subscriber: Subscriber;
  result: ResultType;
  subscriberActive: Subscriber[];
  resultActive: ResultType[];
  tmpTaskId?: string;
  parentId?: string;
}

export const checkActive = ({
  subscriberActive,
  resultActive,
  subscriber,
  result,
  tmpTaskId,
  parentId,
}: ICheckActive): boolean => {
  let activeSub = false;
  let activeRes = false;

  if (tmpTaskId === parentId && subscriberActive.find((elem) => elem === subscriber)) {
    activeSub = true;
  }
  if (tmpTaskId === parentId && resultActive.find((elem) => elem === result)) {
    activeRes = true;
  }

  return activeSub || activeRes;
};
