import React, { useState } from 'react';
import {
  MiniatureBox,
  MiniatureCloseButton,
  MiniaturePopover,
  MiniaturePopoverBox,
  MiniatureSelectActiveWrapper,
  MiniatureSelectList,
  MiniatureSelectWrapper,
  MiniatureWrapper,
} from './MiniatureSelect.styled';
import CloseIcon from '@mui/icons-material/Close';
import { useMobileSize } from '@/hooks/useMediaQuery/useMobileSize';
export interface MiniatureSelectProps {
  components: { component: React.ReactNode; title: string }[];
}

const MiniatureSelect = ({ components }: MiniatureSelectProps) => {
  const [activeMiniatures, setActiveMiniatures] = useState<number[] | null>(null);
  const [containerEl, setContainerEl] = useState<Element | null>(null);
  const [hoverTitle, setHoverTitle] = useState('');
  const open = Boolean(containerEl);

  const handleOpen = (event: React.MouseEvent, title: string) => {
    setContainerEl(event.currentTarget);
    setHoverTitle(title);
  };

  const handleHidePopover = () => setContainerEl(null);
  const isMobile = useMobileSize();
  const handleMiniatureClick = (miniature: number) => {
    handleHidePopover();
    setActiveMiniatures((prev) => {
      if (prev === null) return [miniature];
      return [...prev, miniature];
    });
  };

  const handleCloseClick = (index: number) => {
    const filteredMiniatures = activeMiniatures?.filter((miniature) => miniature !== index) ?? null;
    setActiveMiniatures(filteredMiniatures);
  };

  return (
    <MiniatureSelectWrapper>
      {activeMiniatures?.map((miniatureIndex) => (
        <MiniatureSelectActiveWrapper key={components[miniatureIndex]?.title ?? miniatureIndex}>
          {components[miniatureIndex]?.component}
          <MiniatureCloseButton onClick={() => handleCloseClick(miniatureIndex)}>
            <CloseIcon />
          </MiniatureCloseButton>
        </MiniatureSelectActiveWrapper>
      ))}

      <MiniatureSelectList isMobile={isMobile}>
        {components?.map((miniature, index) => {
          if (!activeMiniatures?.includes(index))
            return (
              <MiniatureBox
                isMobile={isMobile}
                key={miniature.title ?? index}
                onClick={() => handleMiniatureClick(index)}
                onMouseEnter={(event) => handleOpen(event, miniature.title)}
                onMouseLeave={handleHidePopover}
              >
                <MiniatureWrapper>{miniature.component}</MiniatureWrapper>
              </MiniatureBox>
            );
        })}
        <MiniaturePopover
          open={open}
          anchorEl={containerEl}
          placement='bottom'
        >
          <MiniaturePopoverBox>{hoverTitle}</MiniaturePopoverBox>
        </MiniaturePopover>
      </MiniatureSelectList>
    </MiniatureSelectWrapper>
  );
};

export default MiniatureSelect;
