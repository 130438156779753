export enum NetworkPlanningTypes {
  SECONDLY = 'SECONDLY',
  FIRSTLY = 'FIRSTLY',
  PREPLAN = 'PREPLAN',
}

export enum HouseLevelFloorType {
  //чердак
  ATTIC = 'ATTIC',
  //этаж
  FLOOR = 'FLOOR',
  //подвал
  BASEMENT = 'BASEMENT',
  //не определен
  UNDEFINED = 'UNDEFINED',
}

// Используется для кривых мест установки
export const NOT_DEFINED_MONTAGE_PLACE = 'не определен';
