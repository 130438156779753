import { HOST, YodaRestClient } from '@/services/YodaAuthService';
import { ApiResponse } from '@/types/api';
import { PillarMapResponse } from '@/services/PillarMapService/dto/PillarMapDtoResponse';

export async function getPillarGeoPoints(params: {
  filter?: any;
  polygon?: string;
}): ApiResponse<PillarMapResponse[]> {
  const url = HOST + `/rest/task-browser/geo/search`;
  return YodaRestClient.post(url, { ...params, limit: 500 });
}
