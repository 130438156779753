import { HouseDataBrowseDto } from '@/services/HouseDataService/dto/HouseDataServiceResponseDto';
import { createStore } from '@/utils/createStore';

export interface IPnrBrowseStore {
  editAddressModalOpen: boolean;
  mergeAddressModalOpen: boolean;
  selectedRowsData: HouseDataBrowseDto[];
}
export interface IPnrBrowseFunc {
  closeEditAddressModal: () => void;
  openEditAddressModal: () => void;
  closeMergeAddressModal: () => void;
  openMergeAddressModal: () => void;
  setSelectedRowsData: (selectedRowsData: HouseDataBrowseDto[]) => void;
  resetSelection: () => void;
}

export const usePnrBrowseStore = createStore<IPnrBrowseStore & IPnrBrowseFunc>(
  (set, get) => ({
    editAddressModalOpen: false,
    mergeAddressModalOpen: false,
    selectedRowsData: [],

    closeEditAddressModal: () => set(() => ({ editAddressModalOpen: false })),
    openEditAddressModal: () => set(() => ({ editAddressModalOpen: true })),
    closeMergeAddressModal: () => set(() => ({ mergeAddressModalOpen: false })),
    openMergeAddressModal: () => set(() => ({ mergeAddressModalOpen: true })),

    setSelectedRowsData: (selectedRowsData) => set(() => ({ selectedRowsData })),
    resetSelection: () => set(() => ({ selectedRowsData: [] })),
  }),
  'PnrBrowse'
);
