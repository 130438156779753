import React, { FC, ReactNode, useState } from 'react';
import { BoxShadow } from '@/components/ui/BoxShadow';
import { Button, Grid, Stack, Typography } from '@mui/material';
import { FormProvider, useForm } from 'react-hook-form';
import { ExpandButton } from '@/components/button/ExpandButton/ExpandButton';
import { ModeViewerResult } from '@/components/features/taskEditor/types';
import { TASK_EDITOR_BIG_PREVIEW } from '../item/TaskEditorItem.styled';
import AddIcon from '@mui/icons-material/Add';
import { ActPopup } from '@/components/features/taskEditor/adpu/ActPopup';
import { AdpuResponse } from './api/dto/AdpuDtoResponse';

interface INewItem {
  mode?: ModeViewerResult;
  updateFunction: (newAdpu: AdpuResponse) => void;
}

export const NewItem: FC<INewItem> = ({ mode = ModeViewerResult.LARGE, updateFunction }) => {
  const methods = useForm();

  const [innerMode, setInnerMode] = useState<ModeViewerResult>(mode);
  const handleMode = () => {
    setInnerMode(
      innerMode === ModeViewerResult.SMALL ? ModeViewerResult.LARGE : ModeViewerResult.SMALL
    );
  };

  const [openSubscribe, setOpenSubscribe] = useState(false);
  const handleSubscribe = () => {
    setOpenSubscribe(true);
  };

  const getPreview = (): ReactNode => {
    return (
      <Grid {...TASK_EDITOR_BIG_PREVIEW.inner}>
        <Grid {...TASK_EDITOR_BIG_PREVIEW.lastItem}>
          <Stack
            direction={'row'}
            justifyContent='flex-end'
          >
            <Typography>АДПУ</Typography>
            <ExpandButton
              onAction={handleMode}
              sx={{ marginLeft: 1 }}
            />
          </Stack>
        </Grid>
      </Grid>
    );
  };

  return (
    <Grid
      item
      xs={12}
    >
      <FormProvider {...methods}>
        <BoxShadow>
          {innerMode === ModeViewerResult.SMALL && <>{getPreview()}</>}
          {(innerMode === ModeViewerResult.MEDIUM || innerMode === ModeViewerResult.LARGE) && (
            <>
              <Grid
                container
                spacing={2}
              >
                <Grid
                  item
                  xs={12}
                >
                  <Stack
                    direction={'row'}
                    sx={{ alignItems: 'center' }}
                  >
                    <Typography>
                      <span style={{ fontWeight: 'bold' }}>Статус:</span> {'Отсутствует'}
                    </Typography>
                  </Stack>
                </Grid>
              </Grid>
              <Grid
                mt={2}
                item
                xs={4}
              >
                <Button
                  startIcon={<AddIcon />}
                  sx={{ alignSelf: 'flex-start' }}
                  onClick={handleSubscribe}
                >
                  Подписать акт
                </Button>
              </Grid>

              <Stack
                direction={'row'}
                sx={{ alignItems: 'center', flexWrap: 'wrap' }}
              >
                <Grid sx={{ ml: 'auto' }} />
                <Grid>
                  <ExpandButton
                    expand={innerMode === ModeViewerResult.LARGE}
                    onAction={handleMode}
                  />
                </Grid>
              </Stack>
            </>
          )}
        </BoxShadow>
        <ActPopup
          onClose={() => {
            setOpenSubscribe(false);
          }}
          updateFunction={updateFunction}
          openDialog={openSubscribe}
        />
      </FormProvider>
    </Grid>
  );
};
