import { Grid, Switch } from '@mui/material';
import { ButtonClose, LabelSwitch, WrapperSwitch } from '../InvestStatistic.styled';
import CloseIcon from '@mui/icons-material/Close';
import { useInvestStatisticStore } from '../../store';
import shallow from 'zustand/shallow';
import { useState } from 'react';
import { DisplayManufacturer } from '../../types/types';
import { SwitchOptionsPopover } from '../SwitchOptionsPopover/SwitchOptionsPopover';

interface BudgetStatisticBarControlProps {
  displayManufacturer: DisplayManufacturer;
  setDisplayManufacturer: (updater: (prev: DisplayManufacturer) => DisplayManufacturer) => void;
  displayTechDesicion: boolean;
  setDisplayTechDesicion: (displayTechDesicion: boolean) => void;
}

export const BudgetStatisticBarControl = ({
  displayManufacturer,
  setDisplayManufacturer,
  displayTechDesicion,
  setDisplayTechDesicion,
}: BudgetStatisticBarControlProps) => {
  const {
    setActiveIndex,
    checkedEnergyCompany,
    setCheckedEnergyCompany,
    setDataColumns,
    activeIndex,
    equipmentsData,
  } = useInvestStatisticStore(
    (state) => ({
      setActiveIndex: state.setActiveIndex,
      checkedEnergyCompany: state.checkedEnergyCompany,
      setCheckedEnergyCompany: state.setCheckedEnergyCompany,
      setDataColumns: state.setDataColumns,
      activeIndex: state.activeIndex,
      equipmentsData: state.equipmentsData,
    }),
    shallow
  );

  const closeHandler = () => {
    setActiveIndex(null);
    setDataColumns([]);
  };

  const [anchorEl, setAnchorEl] = useState<HTMLButtonElement | null>(null);
  const handleClose = () => {
    setAnchorEl(null);
  };

  const type = activeIndex !== null ? equipmentsData[activeIndex].title : '';
  const dysplaySwitch = type === 'РОУТЕР' || type === 'ШЛЮЗ' ? false : true;

  return (
    <Grid
      sx={{
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'space-between',
        marginBottom: '11px',
      }}
    >
      <WrapperSwitch>
        <LabelSwitch
          checked={!checkedEnergyCompany}
          sx={{ cursor: 'pointer' }}
          onClick={() => setCheckedEnergyCompany(false)}
        >
          КОНТРАКТ
        </LabelSwitch>
        <Switch
          checked={checkedEnergyCompany}
          onChange={(e) => setCheckedEnergyCompany(e.target.checked)}
        />
        <LabelSwitch
          checked={checkedEnergyCompany}
          sx={{ cursor: 'pointer' }}
          onClick={() => setCheckedEnergyCompany(true)}
        >
          СБЫТ
        </LabelSwitch>
      </WrapperSwitch>
      <div>
        <SwitchOptionsPopover showTechDesicion />
        <ButtonClose onClick={closeHandler}>
          <CloseIcon />
        </ButtonClose>
      </div>
    </Grid>
  );
};
