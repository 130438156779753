import { useEffect, useState } from 'react';
import { InputAdornment } from '@mui/material';
import { useFormContext, useWatch } from 'react-hook-form';
import { YungaAnalyzeTextFieldIcon } from '@/components/YungaAnalyzeTextField/YungaAnalyzeTextFieldIcon';
import { TextField } from '../fixUI/TextField';
import CopyButton from '@/components/button/CopyButton/CopyButton';

interface YungaAnalyzeTextFieldProps {
  type?: 'done' | 'cancel' | 'unknown';
  registerName: string;
  value?: string;
  label: string;
  disabled?: boolean;
  isChange?: (flag: boolean) => void;
  showCopyButton?: boolean;
}

export const YungaAnalyzeTextField = (props: YungaAnalyzeTextFieldProps) => {
  const { registerName, value, label, disabled, isChange, showCopyButton = false } = props;
  const { register, control } = useFormContext();
  const firstName = useWatch({ control, name: registerName });
  const [type, setType] = useState<'done' | 'cancel' | 'unknown' | undefined>();

  useEffect(() => {
    setType(props.type);
  }, [props]);

  useEffect(() => {
    if (firstName != value) {
      setType(undefined);
    } else {
      setType(props.type);
    }
  }, [firstName]);

  return (
    <TextField
      id={`tf-${label}`}
      label={label}
      variant='standard'
      disabled={disabled}
      {...register(registerName, { value: value })}
      onChange={(e) => {
        register(registerName, { value: value }).onChange(e);
        isChange && isChange(true);
      }}
      sx={{ width: '100%' }}
      InputProps={{
        endAdornment: (
          <InputAdornment position='start'>
            <YungaAnalyzeTextFieldIcon type={type} />
          </InputAdornment>
        ),
        startAdornment:
          showCopyButton && (firstName ?? value) ? (
            <div style={{ order: 2 }}>
              <CopyButton
                onlyIcon
                tooltipText={'копировать'}
                textToCopy={firstName ?? value}
              />
            </div>
          ) : null,
      }}
    />
  );
};
