import { styled } from '@mui/material';

export const StatisticByInstallationLocationFiltersWrapper = styled('div')({
  padding: '0 0 0 5vw',
  position: 'relative',
  display: 'flex',
  justifyContent: 'flex-start',
  alignItems: 'flex-end',
  gap: 16,
});

export const StatisticByInstallationLocationTableWrapper = styled('div')({
  height: 700,
  width: '100%',
  marginTop: 15,
  // overflowX: 'auto',
});
