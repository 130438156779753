import {
  Accordion,
  AccordionDetails as AccordionDetails_,
  AccordionSummary,
  CircularProgress,
  Collapse,
  Divider,
  Grid,
  IconButton,
  Skeleton,
  styled,
  TextField,
  Typography,
} from '@mui/material';
import { TaskCollCenterCommentResponse, TaskCommentResponse } from '@/dto/taskmap/task/TasksDto';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { useEffect, useState } from 'react';
import { findAllTaskCommentById, saveTaskComment } from '@/services/TaskService/TaskService';
import { ActionLogType, useActionLog } from '@/hooks/ActionLogHook';
import CheckCircleOutlineIcon from '@mui/icons-material/CheckCircleOutline';
import { BoxShadow } from '@/components/ui/BoxShadow';
import { useLastComment } from '@/components/features/taskEditor/hooks/useLastComment';
import { useDisabledAll } from '@/hooks/useAccess';
import { useTaskEditorOnlyShow } from '../../useTaskEditorOnlyShow';
import TaskComment from './TaskComment';
import { TransitionGroup } from 'react-transition-group';

interface TaskCommentPanelProps {
  taskId: string;
  callCenterComments?: TaskCollCenterCommentResponse[];
}

const AccordionDetails = styled(AccordionDetails_)(() => ({
  padding: 0,
}));

export const TaskCommentPanel = (props: TaskCommentPanelProps) => {
  const onlyShow = useTaskEditorOnlyShow();
  const disabledAll = useDisabledAll();
  const { taskId, callCenterComments } = props;
  const setLastComment = useLastComment((state) => state.setLastComment);

  const [loading, setLoading] = useState(false);
  const [saving, setSaving] = useState(false);
  const { addActionLog, fetchCatch } = useActionLog();
  const [comments, setComments] = useState<TaskCommentResponse[]>();
  const [message, setMessage] = useState('');

  useEffect(() => {
    getComments();
  }, [taskId]);

  useEffect(() => {
    setLastComment(comments?.[comments.length - 1]?.message || '');
  }, [comments]);

  const getComments = () => {
    if (taskId) {
      setLoading(true);
      findAllTaskCommentById(taskId)
        .then((res) => {
          setComments(res.data);
        })
        .catch((err) => fetchCatch(err, 'Ошибка загрузки комментариев к заявке'))
        .finally(() => setLoading(false));
    }
  };

  const filterComment = (commentId?: string) => {
    const filteredComments = comments?.filter((comment) => comment.id !== commentId);

    setComments(filteredComments);
  };

  const handleChange = (event: any) => {
    setMessage(event.target.value);
  };

  const handleSaveComment = () => {
    setSaving(true);
    saveTaskComment(taskId, message)
      .then(({ data }) => {
        addActionLog(ActionLogType.SUCCESS, 'Комментарий сохранен');
        setComments(comments ? [...comments, data] : [data]);
      })
      .catch((err) => fetchCatch(err, 'Ошибка при сохранении комментария'))
      .finally(() => {
        setSaving(false);
        setMessage('');
      });
  };

  const onKeyPress = (event: any) => {
    if (event.key === 'Enter') {
      handleSaveComment();
    }
  };

  return (
    <>
      {loading ? (
        <Skeleton animation='wave' />
      ) : (
        <BoxShadow>
          <Accordion
            elevation={0}
            defaultExpanded
          >
            <AccordionSummary
              expandIcon={<ExpandMoreIcon />}
              sx={{ padding: 0, paddingRight: '8px' }}
            >
              <Typography>Комментарии</Typography>
            </AccordionSummary>

            <AccordionDetails>
              {comments && (
                <TransitionGroup>
                  {comments?.map((comment) => (
                    <Collapse key={comment?.id}>
                      <TaskComment
                        key={`comment-${comment?.eventAt || comment?.createdOn}`}
                        comment={comment}
                        withDeletion
                        filterComment={() => filterComment(comment.id)}
                      />
                    </Collapse>
                  ))}
                </TransitionGroup>
              )}
              <Grid
                container
                direction='row'
                justifyContent='space-around'
                alignItems='flex-end'
                spacing={1}
              >
                <Grid
                  item
                  xs={12}
                >
                  <Divider />
                </Grid>
                <Grid
                  item
                  xs={10}
                >
                  <TextField
                    value={message}
                    multiline
                    variant='standard'
                    label='Комментарий'
                    fullWidth
                    disabled={disabledAll || onlyShow}
                    onChange={handleChange}
                    onKeyPress={onKeyPress}
                  />
                </Grid>
                <Grid
                  item
                  xs={2}
                  sx={{ display: 'flex', justifyContent: 'flex-end' }}
                >
                  {message.length > 0 && (
                    <IconButton
                      disabled={saving}
                      onClick={handleSaveComment}
                      sx={{ width: 40, height: 40 }}
                    >
                      {saving ? (
                        <CircularProgress size={18} />
                      ) : (
                        <CheckCircleOutlineIcon color='success' />
                      )}
                    </IconButton>
                  )}
                </Grid>
              </Grid>
            </AccordionDetails>
          </Accordion>
        </BoxShadow>
      )}
      {!!callCenterComments?.length && (
        <>
          <BoxShadow>
            <Accordion
              elevation={0}
              defaultExpanded
            >
              <AccordionSummary
                expandIcon={<ExpandMoreIcon />}
                sx={{ padding: 0, paddingRight: '8px' }}
              >
                <Typography>Колл-Центр</Typography>
              </AccordionSummary>
              <AccordionDetails>
                {callCenterComments &&
                  [...callCenterComments].map((comment) => (
                    <TaskComment
                      key={`comment-${comment?.eventAt || comment?.createdOn}`}
                      comment={comment}
                    />
                  ))}
              </AccordionDetails>
            </Accordion>
          </BoxShadow>
        </>
      )}
    </>
  );
};
