import { TransformerAmperageRequestDto } from '@/services/TransformerService/dto/TransformerRequestDto';

export const convertToOldApi = (newData: any): TransformerAmperageRequestDto => {
  const phaseIdList = newData.mount.phaseList.map((elem: any) => elem.id);
  const transformerRequestList = phaseIdList.map((elem: any) => {
    const tmpMount = newData.mount.phaseList.find((el: any) => el.id == elem);
    const tmpUnmount = newData.unmount.phaseList.find((el: any) => el.id == elem);
    return {
      id: elem,
      prevFactoryYear: tmpUnmount?.year,
      prevSerialNumber: tmpUnmount?.serial,
      serialNumber: tmpMount?.serial,
      stampNumber: tmpMount?.stamp,
      type: newData.mount.type,
    };
  });
  return {
    kttId: newData.kttId,
    checkOn: newData.mount.checkDate,
    nextCheckOn: newData.mount.nextCheckDate,
    prevType: newData.unmount.type,
    transformerRequestList: transformerRequestList,
    exPuInfoResponse: newData.exPuInfoResponse,
  };
};
