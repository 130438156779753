import { HOST, YodaRestClient } from '../YodaAuthService';
import { CatalogTypes } from '@/components/sections/CatalogsBrowse/constants';
import { ApiResponse } from '@/types/api';
import {
  EquipmentEditorResponse,
  KooBrowseResponse,
  PuBrowseResponse,
  PuEditorResponse,
  SimBrowseResponse,
  StampBrowseResponse,
  TaskNumberForEquipmentResponse,
} from './dto/CatalogsDtoResponse';
import {
  CatalogsBrowseCountRequest,
  CatalogsBrowseRequest,
  KooBrowseFilterParam,
  PUBrowseFilterParam,
  SimBrowseFilterParam,
  StampBrowseFilterParam,
} from '@/services/CatalogService/dto/CatalogsDtoRequest';

type FilterRes =
  | ApiResponse<StampBrowseResponse[]>
  | ApiResponse<SimBrowseResponse[]>
  | ApiResponse<PuBrowseResponse[]>
  | ApiResponse<KooBrowseResponse[]>;

export const getBrowse = (
  catalogType: CatalogTypes
): { filter: (e: any) => FilterRes; count: (e: any) => ApiResponse<number> } => {
  switch (catalogType) {
    case CatalogTypes.STAMP:
      return { filter: getStampBrowse, count: getStampBrowseCount };
    case CatalogTypes.KOO:
      return { filter: getKooBrowse, count: getKooBrowseCount };
    case CatalogTypes.SIM:
      return { filter: getSimBrowse, count: getSimBrowseCount };
    case CatalogTypes.PU:
      return { filter: getPUBrowse, count: getPUBrowseCount };
  }
};

export async function getStampBrowse({
  page,
  pageSize,
  filters,
}: CatalogsBrowseRequest<StampBrowseFilterParam>): ApiResponse<StampBrowseResponse[]> {
  const url = HOST + `/rest/stamp/find?offset=${page * pageSize}&limit=${pageSize}`;
  return YodaRestClient.post(url, filters);
}

export async function getStampBrowseCount({
  filters,
}: CatalogsBrowseCountRequest<StampBrowseFilterParam>): ApiResponse<number> {
  const url = HOST + `/rest/stamp/find/count`;
  return YodaRestClient.post(url, filters);
}

export async function getKooBrowse({
  page,
  pageSize,
  filters,
}: CatalogsBrowseRequest<KooBrowseFilterParam>): ApiResponse<KooBrowseResponse[]> {
  const url = HOST + `/rest/network-equipment/find?offset=${page * pageSize}&limit=${pageSize}`;
  return YodaRestClient.post(url, filters);
}

export async function getKooBrowseCount({
  filters,
}: CatalogsBrowseCountRequest<KooBrowseFilterParam>): ApiResponse<number> {
  const url = HOST + `/rest/network-equipment/count`;
  return YodaRestClient.post(url, filters);
}
export async function getSimBrowse({
  page,
  pageSize,
  filters,
}: CatalogsBrowseRequest<SimBrowseFilterParam>): ApiResponse<SimBrowseResponse[]> {
  const url = HOST + `/rest/sim/find?offset=${page * pageSize}&limit=${pageSize}`;
  return YodaRestClient.post(url, filters);
}

export async function getSimBrowseCount({
  filters,
}: CatalogsBrowseCountRequest<SimBrowseFilterParam>): ApiResponse<number> {
  const url = HOST + `/rest/sim/find/count`;
  return YodaRestClient.post(url, filters);
}

export async function getPUBrowse({
  page,
  pageSize,
  filters,
}: CatalogsBrowseRequest<PUBrowseFilterParam>): ApiResponse<PuBrowseResponse[]> {
  const url = HOST + `/rest/meter/find?offset=${page * pageSize}&limit=${pageSize}`;
  return YodaRestClient.post(url, filters);
}

export async function getPUBrowseCount({
  filters,
}: CatalogsBrowseCountRequest<PUBrowseFilterParam>): ApiResponse<number> {
  const url = HOST + `/rest/meter/find/count`;
  return YodaRestClient.post(url, filters);
}

export async function getPUItem(meterId: string): ApiResponse<PuEditorResponse> {
  const url = HOST + `/rest/meter/${meterId}`;
  return YodaRestClient.get(url);
}

export async function editPUItem(
  meterId: string,
  data: PuEditorResponse
): ApiResponse<PuEditorResponse> {
  const url = HOST + `/rest/meter/${meterId}`;
  return YodaRestClient.put(url, data);
}

export async function deletePUItem(meterId: string): ApiResponse<PuEditorResponse> {
  const url = HOST + `/rest/meter/${meterId}`;
  return YodaRestClient.delete(url);
}

export async function getTaskNubmerForPU(
  meterId: string
): ApiResponse<TaskNumberForEquipmentResponse> {
  const url = HOST + `/rest/meter/${meterId}/usage`;
  return YodaRestClient.get(url);
}

export async function getEquipmentItem(equipmentId: string): ApiResponse<EquipmentEditorResponse> {
  const url = HOST + `/rest/network-equipment/${equipmentId}`;
  return YodaRestClient.get(url);
}

export async function getTaskNubmerForEquipment(
  equipmentId: string
): ApiResponse<TaskNumberForEquipmentResponse> {
  const url = HOST + `/rest/network-equipment/${equipmentId}/usage`;
  return YodaRestClient.get(url);
}

export async function editEquipmentItem(
  equipmentId: string,
  data: EquipmentEditorResponse
): ApiResponse<EquipmentEditorResponse> {
  const url = HOST + `/rest/network-equipment/${equipmentId}`;
  return YodaRestClient.put(url, data);
}

export async function deleteEquipmentItem(
  equipmentId: string
): ApiResponse<EquipmentEditorResponse> {
  const url = HOST + `/rest/network-equipment/${equipmentId}`;
  return YodaRestClient.delete(url);
}
