import { Box, Checkbox, Grid, Link, Skeleton, Tooltip, Typography } from '@mui/material';
import { useCatalog } from '@/hooks/CatalogHook';
import EditOutlinedIcon from '@mui/icons-material/EditOutlined';
import DeleteOutlineIcon from '@mui/icons-material/DeleteOutline';
import DoDisturbIcon from '@mui/icons-material/DoDisturb';
import WarningAmberIcon from '@mui/icons-material/WarningAmber';
import { SaveButton } from '@/components/ui/SaveButton/SaveButton';
import { create, remove } from '@/services/ZoningService/ZoningService';
import { useScreenHoldHook } from '@/hooks/ScreenHoldHook';
import { ActionLogType, useActionLog } from '@/hooks/ActionLogHook';
import { useZoning } from '@/components/maps/zoningMap/hook/ZoningHook';
import { useSearch } from '@/components/maps/zoningMap/hook/SearchHook';
import { useEffect, useState } from 'react';
import { GeoTask } from '@/dto/taskmap/Dto';
import { TypographyReducer } from '@/components/output/TypographyReducer';
import {
  geoCoordinateToCoordinate,
  toColor,
  calculateCenter,
  calculateBounds,
} from '@/components/maps/zoningMap/utils/GeoUtils';
import { ZoningGroupResponse } from '@/services/ZoningService/dto/ZoningServiceResponseDto';

interface ZoningItemMapProps {
  zoningGroupResponse: ZoningGroupResponse;
}

export const ZoningItemMap = (props: ZoningItemMapProps) => {
  const { zoningGroupResponse } = props;
  const { addActionLog, fetchCatch } = useActionLog();
  const { setIsInProgress } = useScreenHoldHook();
  const { getGroupCatalog } = useCatalog((state) => ({
    getGroupCatalog: state.getGroupCatalog,
  }));
  const {
    mapRef,
    overlapGroupIds,
    removeItem,
    editingGroup,
    setEditing,
    selectedGroupIds,
    selectedGroupId,
    deselectGroupId,
  } = useZoning();
  const { zoningResponse, loading, serch } = useSearch();
  const [assignableList, setAssignableList] = useState<GeoTask[] | undefined>();
  const [notAssignableList, setNotAssignableList] = useState<GeoTask[] | undefined>();

  useEffect(() => {
    const coveredItem = zoningResponse?.coveredItemList?.find(
      (t) => t.groupId == zoningGroupResponse.groupId
    );
    if (coveredItem) {
      setAssignableList(coveredItem.assignableList);
      setNotAssignableList(coveredItem.notAssignableList);
    }
  }, [zoningResponse]);

  const handleEdit = () => {
    if (zoningGroupResponse?.polygonRef) {
      setEditing(zoningGroupResponse.groupId);
    }
  };

  const onSave = () => {
    if (zoningGroupResponse.coordinateList) {
      setIsInProgress(true);
      create(zoningGroupResponse.groupId, zoningGroupResponse.coordinateList)
        .then(() => {
          setEditing(undefined);
          addActionLog(ActionLogType.SUCCESS, 'Сохранено');
          serch(fetchCatch);
        })
        .catch((err) => fetchCatch(err, 'Ошибка при сохранении'))
        .finally(() => setIsInProgress(false));
    }
  };

  const handleOnClick = () => {
    if (mapRef && zoningGroupResponse?.coordinateList?.length > 0) {
      const coordinates = geoCoordinateToCoordinate(zoningGroupResponse.coordinateList);
      const center = calculateCenter(coordinates);
      mapRef.setCenter(center);
      const bounds = calculateBounds(coordinates);
      mapRef.setBounds(bounds);
    }
  };

  const onRemove = () => {
    if (!zoningGroupResponse.id) {
      removeItem(zoningGroupResponse.groupId);
      return;
    }
    setIsInProgress(true);
    remove(zoningGroupResponse.groupId)
      .then((res) => {
        addActionLog(ActionLogType.SUCCESS, 'Удалено');
        removeItem(zoningGroupResponse.groupId);
      })
      .catch((err) => fetchCatch(err, 'Ошибка при удалении'))
      .finally(() => setIsInProgress(false));
  };

  const onCancelEditing = () => {
    setEditing(undefined);
  };

  const handleSelectGroup = (event: any) => {
    if (event.target.checked) {
      selectedGroupId(zoningGroupResponse.groupId);
    } else {
      deselectGroupId(zoningGroupResponse.groupId);
    }
  };

  return (
    <>
      <Grid
        container
        direction='row'
        justifyContent='flex-start'
        alignItems='center'
        spacing={1}
      >
        <Grid item>
          <Checkbox
            style={{ padding: '1px' }}
            checked={selectedGroupIds.includes(zoningGroupResponse.groupId)}
            onChange={handleSelectGroup}
            name='total'
          />
        </Grid>
        {loading ? (
          <Skeleton
            animation='wave'
            width={30}
          />
        ) : (
          <Grid item>
            <Tooltip title={'В зоне покрытия'}>
              <Typography
                display='inline'
                color={'silver'}
              >
                {notAssignableList?.length || 0}
              </Typography>
            </Tooltip>
            {' / '}
            <Tooltip title={'Можно назначить'}>
              <Typography
                display='inline'
                color={'silver'}
              >
                {assignableList?.length || 0}
              </Typography>
            </Tooltip>
          </Grid>
        )}
        <Grid item>
          <Box
            sx={{
              display: 'flex',
              backgroundColor: toColor(
                getGroupCatalog()?.get(zoningGroupResponse?.groupId) || 'none'
              ),
              borderRadius: '50%',
              width: 15,
              height: 15,
            }}
          ></Box>
        </Grid>
        {zoningGroupResponse && zoningGroupResponse.coordinateList?.length == 0 && (
          <Grid item>
            <Tooltip title='Координаты не заданы'>
              <WarningAmberIcon
                sx={{ width: 16, cursor: 'pointer' }}
                data-tip
              />
            </Tooltip>
          </Grid>
        )}
        <Grid item>
          <Link
            sx={{ cursor: 'pointer' }}
            onClick={handleOnClick}
            underline={zoningGroupResponse?.coordinateList?.length > 0 ? 'hover' : 'none'}
            color={overlapGroupIds?.includes(zoningGroupResponse.groupId) ? 'red' : 'primary'}
          >
            <TypographyReducer
              value={getGroupCatalog()?.get(zoningGroupResponse.groupId) || '(none)'}
              length={20}
            ></TypographyReducer>
          </Link>
        </Grid>
        {editingGroup?.groupId === zoningGroupResponse.groupId ? (
          <>
            <Grid item>
              <SaveButton
                fontSize={16}
                onSave={() => onSave()}
                disabled={false}
              />
            </Grid>
            <Grid item>
              <DoDisturbIcon
                sx={{ width: 16, cursor: 'pointer' }}
                onClick={() => onCancelEditing()}
                data-tip
              />
            </Grid>
          </>
        ) : (
          <>
            <Grid item>
              <EditOutlinedIcon
                sx={{ width: 16, cursor: 'pointer' }}
                onClick={() => handleEdit()}
                data-tip
              />
            </Grid>
            <Grid item>
              <DeleteOutlineIcon
                sx={{ width: 16, cursor: 'pointer' }}
                onClick={() => onRemove()}
                data-tip
              />
            </Grid>
          </>
        )}
      </Grid>
    </>
  );
};
