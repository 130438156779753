import FileInput from '@/components/inputs/FileInput/FileInput';
import { SupplyBillApiGetFileById } from '@/services/SupplyBillService/SupplyBillService';
import { TextField, Autocomplete } from '@mui/material';
import React, { useMemo, useState } from 'react';
import {
  IssuedStampFilterWrapper,
  IssuedStampFileInputWrapper,
  IssuedStampHeaderWrapper,
} from './IssuedStamp.styled';
import IssuedStampUpdateFileConfirm from './IssuedStampUpdateFileConfirm';
import IssuedStampUpdateInfo from './IssuedStampUpdateInfo';
import useSupplyStore from '../SupplyStore';
import shallow from 'zustand/shallow';
import { SelectFilterOptType } from '@/components/inputs/MultiSelectNew/types';
import { SupplyConstant } from '../SupplyConstant';
import {
  LS_PU_TYPE,
  useDisabledStorehouseField,
  useStorehouseWorkerAccess,
} from '../SupplyPUComponent/utils';
import { useAllProjects, useAllStorehouses } from '@/hooks/useQuery/useAllSupplyCatalogs';
import moment from 'moment';
import { useFormContext } from 'react-hook-form';
import { BillSaveOptions, SUCCESS_MESSAGES, useResponsableOptions } from './utils';
import { useAllowedAccessByRole } from '@/hooks/useAccess';
import { RolesEnum } from '@/app/auth/roles';

export interface IssuedStampHeaderProps {
  nonEditableBill?: boolean;
  selectedResponsable: SelectFilterOptType | null;
  tabType: LS_PU_TYPE;
  handleResponsableSelect: (_event: React.SyntheticEvent<Element, Event>, responsable: any) => void;
  supplyGroupsBills: boolean;
  relocationGetBill: boolean;
  factoryPostBill: boolean;
  resetFileInputFlag: boolean;
  saveFiles: () => void;
  selectedComponent: number;
  selectedStorehouse: SelectFilterOptType | null;
  handleStorehouseSelect: (_event: React.SyntheticEvent<Element, Event>, storehouse: any) => void;
  checkAndSaveAllData: ({ saveBillType, isDraft }: BillSaveOptions) => void;
}

const IssuedStampHeader = ({
  nonEditableBill,
  selectedResponsable,
  tabType,
  handleResponsableSelect,
  supplyGroupsBills,
  relocationGetBill,
  factoryPostBill,
  resetFileInputFlag,
  saveFiles,
  selectedComponent,
  selectedStorehouse,
  handleStorehouseSelect,
  checkAndSaveAllData,
}: IssuedStampHeaderProps) => {
  const { register, setValue: setFileValue } = useFormContext();

  const workerAccess = useStorehouseWorkerAccess();
  const disableByRole = useDisabledStorehouseField();

  const {
    setBillNumber,
    setBillDate,
    billNumber,
    contractorId,
    supplyId,
    billDate,
    supplyBillDto,
    m15FormNumber,
    setM15FormNumber,
    billType,
    projectId,
    setProjectId,
  } = useSupplyStore(
    (state) => ({
      setBillNumber: state.setBillNumber,
      setBillDate: state.setBillDate,
      billNumber: state.billNumber,
      contractorId: state.contractorId,
      supplyId: state.supplyId,
      billDate: state.billDate,
      supplyBillDto: state.supplyBillDto,
      m15FormNumber: state.m15FormNumber,
      setM15FormNumber: state.setM15FormNumber,
      billType: state.billType,
      projectId: state.projectId,
      setProjectId: state.setProjectId,
    }),
    shallow
  );

  const { responsableLabel, responsableOptions } = useResponsableOptions(
    billType,
    tabType,
    supplyGroupsBills,
    relocationGetBill,
    factoryPostBill
  );

  const billTypeName = supplyBillDto?.billTypeName;

  const { storehousesOptions } = useAllStorehouses();
  const { projectsOptions } = useAllProjects();

  const storehouseName: string = storehousesOptions?.find(
    (type) => type.value === supplyBillDto?.supplyId
  )?.label;

  const [billNumberError, setBillNumberError] = useState<string>('');
  const [contractorIdError, setContractorIdError] = useState<string>('');
  const [supplyIdError, setSupplyIdError] = useState<string>('');
  const [billDateError, setBillDateError] = useState<string>('');

  const projectAccess = useAllowedAccessByRole({
    allowRoles: [RolesEnum.StorehouseProject],
  });

  React.useEffect(() => {
    checkErrors();
  }, [billNumber, contractorId, billDate, supplyId]);

  const enableCreatedBillFileEdit = useMemo(() => {
    if (!supplyBillDto?.releaseDate || billType !== SupplyConstant.ISSUED_TYPE) return false;
    const currentDate = moment();
    const createdDateWithLag = moment(supplyBillDto?.releaseDate).add(8, 'hours');

    return currentDate.isBefore(createdDateWithLag);
  }, [supplyBillDto?.releaseDate]);

  const checkErrors = () => {
    setBillNumberError(billNumber && billNumber.length > 0 ? '' : 'Обязательное поле');
    setContractorIdError(contractorId && contractorId.length > 0 ? '' : 'Обязательное поле');
    setSupplyIdError(supplyId && supplyId.length > 0 ? '' : 'Обязательное поле');
    setBillDateError(billDate && billDate.length > 0 ? '' : 'Обязательное поле');
  };

  const initialOrDraftBillOrBillWithLag = nonEditableBill && !enableCreatedBillFileEdit;
  const disableSupplyInfoDraftIssue = !workerAccess && billType === SupplyConstant.DRAFT_TYPE_ISSUE;

  const disableStorehouseField = supplyBillDto && billType !== SupplyConstant.DRAFT_TYPE_RETURN;

  const handleProjectSelect = (_event: React.SyntheticEvent<Element, Event>, project: any) => {
    setProjectId(project?.value);
    checkAndSaveAllData({
      saveBillType: SupplyConstant.ISSUED_TYPE,
      isDraft: false,
      successMessage: SUCCESS_MESSAGES.addProject,
    });
  };

  return (
    <IssuedStampHeaderWrapper>
      <IssuedStampFilterWrapper>
        <TextField
          label='Накладная'
          required
          value={billNumber ? billNumber : ''}
          onChange={(e) => setBillNumber(e.target.value.toLocaleLowerCase())}
          error={billNumberError.length > 0}
          helperText={billNumberError}
          disabled={nonEditableBill}
        />
        <TextField
          label='Номер М15'
          value={m15FormNumber ? m15FormNumber : ''}
          onChange={(e) => setM15FormNumber(e.target.value.toLowerCase())}
          disabled={nonEditableBill}
        />
        <Autocomplete
          disablePortal
          value={selectedResponsable}
          isOptionEqualToValue={(option, value) => option.value === value.value}
          renderOption={(props, option) => (
            <li
              {...props}
              key={option?.value || option?.label}
            >
              {option?.label ?? ''}
            </li>
          )}
          options={responsableOptions}
          disabled={nonEditableBill || tabType === 'factory_get'}
          sx={{ width: 300 }}
          onChange={handleResponsableSelect}
          renderInput={(params) => (
            <TextField
              {...params}
              label={responsableLabel}
              error={contractorIdError.length > 0}
              helperText={contractorIdError}
            />
          )}
        />
        <TextField
          required
          type='date'
          label='Дата выдачи'
          InputLabelProps={{ shrink: true }}
          value={billDate ? billDate : ''}
          disabled={nonEditableBill}
          onChange={(e) => setBillDate(e.target.value)}
          error={billDateError.length > 0}
          helperText={billDateError}
        />
        {supplyBillDto && billTypeName && (
          <TextField
            label='Тип накладной'
            disabled
            value={billTypeName}
            title={billTypeName}
            sx={{ width: 350 }}
          />
        )}
        {(supplyBillDto || (!disableByRole && billType === SupplyConstant.RETURNED_TYPE)) && (
          <Autocomplete
            disablePortal
            value={selectedStorehouse}
            isOptionEqualToValue={(option, value) => option.value === value.value}
            options={storehousesOptions}
            disabled={disableStorehouseField || disableByRole}
            sx={{ width: 300 }}
            onChange={handleStorehouseSelect}
            renderInput={(params) => (
              <TextField
                {...params}
                label={'Склад'}
                error={supplyIdError.length > 0}
                helperText={supplyIdError}
              />
            )}
          />
        )}
        {supplyBillDto && billType === SupplyConstant.ISSUED_TYPE && (
          <Autocomplete
            disablePortal
            value={projectId ? projectsOptions.find((item) => item.value === projectId) : null}
            isOptionEqualToValue={(option, value) => option?.value === value?.value}
            options={projectsOptions}
            disabled={!projectAccess}
            sx={{ width: 300 }}
            onChange={handleProjectSelect}
            renderInput={(params) => (
              <TextField
                {...params}
                label={'Проект'}
              />
            )}
          />
        )}
        <IssuedStampUpdateInfo />
      </IssuedStampFilterWrapper>
      <IssuedStampFileInputWrapper>
        {enableCreatedBillFileEdit && <IssuedStampUpdateFileConfirm saveFiles={saveFiles} />}
        <FileInput
          register={register('files')}
          setFormFiles={setFileValue}
          disableEdit={initialOrDraftBillOrBillWithLag || disableSupplyInfoDraftIssue}
          filesFromBackend={supplyBillDto?.fileList}
          filesFromBackendGetFunction={SupplyBillApiGetFileById}
          resetFlag={resetFileInputFlag}
        />
      </IssuedStampFileInputWrapper>
    </IssuedStampHeaderWrapper>
  );
};

export default React.memo(IssuedStampHeader);
