import { HouseCommentResponseDto } from '@/services/HouseDataService/dto/HouseDataServiceResponseDto';
import { toFormateDate } from '@/utils/heplers';
import { CircularProgress, IconButton } from '@mui/material';
import React, { useState } from 'react';
import {
  HouseCommentsRowWrapper,
  HouseCommentsRowColumn,
  HouseCommentsSmallText,
  HouseCommentsInfoRow,
  HouseCommentsIconButtonWrapper,
  HouseCommentsRowCallMessage,
} from './HouseComments.styled';
import DeleteForeverOutlinedIcon from '@mui/icons-material/DeleteForeverOutlined';
import { ApiDeleteHouseComment } from '@/services/HouseDataService/HouseDataService';
import { useParams } from 'react-router-dom';
import { fetchCatch } from '@/hooks/ActionLogHook';

export interface HouseCommentRowProps {
  comment: HouseCommentResponseDto;
  deleteComment: (id: string) => void;
}

const HouseCommentRow = ({ comment, deleteComment }: HouseCommentRowProps) => {
  const { houseDtoId } = useParams();

  const [deleting, setDeleting] = useState(false);

  const handleDelete = () => {
    if (!houseDtoId) return;
    setDeleting(true);

    ApiDeleteHouseComment(houseDtoId, comment.id)
      .then(() => deleteComment(comment.id))
      .catch(fetchCatch)
      .finally(() => setDeleting(false));
  };
  return (
    <HouseCommentsRowWrapper>
      <HouseCommentsIconButtonWrapper>
        {comment?.isDeletable && (
          <HouseCommentsIconButtonWrapper>
            {deleting ? (
              <CircularProgress size={16} />
            ) : (
              <IconButton onClick={handleDelete}>
                <DeleteForeverOutlinedIcon />
              </IconButton>
            )}
          </HouseCommentsIconButtonWrapper>
        )}
      </HouseCommentsIconButtonWrapper>

      <HouseCommentsRowColumn>
        <HouseCommentsInfoRow>
          <HouseCommentsSmallText>{toFormateDate(comment.eventAt)}</HouseCommentsSmallText>
          <HouseCommentsSmallText>{comment.author}</HouseCommentsSmallText>
        </HouseCommentsInfoRow>
        {comment?.callMessage && (
          <HouseCommentsRowCallMessage>{comment.callMessage}</HouseCommentsRowCallMessage>
        )}

        <div>{comment?.message}</div>
      </HouseCommentsRowColumn>
    </HouseCommentsRowWrapper>
  );
};

export default HouseCommentRow;
