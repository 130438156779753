import axios from 'axios';
import { HOST, yodaGetAccessToken, YodaRestClient } from '@/services/YodaAuthService';
import {
  MailNotificationsFileResponse,
  MailNotificationsFolderResponse,
} from '@/services/MailNotificationsService/dto/MailNotificationsResponseDto';
import { ApiResponse } from '@/types/api';

export async function GetMailNotificationsFolders(): ApiResponse<
  MailNotificationsFolderResponse[]
> {
  const url = HOST + `/rest/file/postal-departure`;
  return YodaRestClient.get(url);
}

export async function GetMailNotificationsFiles(
  fileId: string
): ApiResponse<MailNotificationsFileResponse[]> {
  const url = HOST + `/rest/file/postal-departure/${fileId}/file`;
  return YodaRestClient.get(url);
}

export async function GetADPUFolders(): ApiResponse<MailNotificationsFolderResponse[]> {
  const url = HOST + `/rest/file/adpu`;
  return YodaRestClient.get(url);
}

export async function GetADPUFiles(fileId: string): ApiResponse<MailNotificationsFileResponse[]> {
  const url = HOST + `/rest/file/adpu/${fileId}/file`;
  return YodaRestClient.get(url);
}

export async function UploadZipFile(file: File): ApiResponse<any> {
  const url = HOST + `/rest/file/postal-departure`;
  const token = await yodaGetAccessToken();
  const formData = new FormData();
  formData.append('zip', file);
  return axios.post(url, formData, {
    headers: {
      Authorization: `Bearer  ${token}`,
      'Content-Type': 'multipart/form-data',
      Accept: '*/*',
    },
  });
}

export async function UploadZipFileById(id: string, file: File): ApiResponse<any> {
  const url = HOST + `/rest/file/postal-departure/${id}`;
  const token = await yodaGetAccessToken();
  const formData = new FormData();
  formData.append('zip', file);
  return axios.post(url, formData, {
    headers: {
      Authorization: `Bearer  ${token}`,
      'Content-Type': 'multipart/form-data',
      Accept: '*/*',
    },
  });
}

export async function UploadZipFileADPU(file: File): ApiResponse<any> {
  const url = HOST + `/rest/file/adpu`;
  const token = await yodaGetAccessToken();
  const formData = new FormData();
  formData.append('zip', file);
  return axios.post(url, formData, {
    headers: {
      Authorization: `Bearer  ${token}`,
      'Content-Type': 'multipart/form-data',
      Accept: '*/*',
    },
  });
}

export async function UploadZipFileADPUById(id: string, file: File): ApiResponse<any> {
  const url = HOST + `/rest/file/adpu/${id}`;
  const token = await yodaGetAccessToken();
  const formData = new FormData();
  formData.append('zip', file);
  return axios.post(url, formData, {
    headers: {
      Authorization: `Bearer  ${token}`,
      'Content-Type': 'multipart/form-data',
      Accept: '*/*',
    },
  });
}
