import { activeIcon, mainIcon, topIcon } from './icons';
import { ResultType, Subscriber } from '@/components/maps/ppoMap/dto';
import { checkActive } from './utils';

export interface IDeliveryPointLayout {
  id: string;
  ymaps: any;

  newPuTypeMarker: string;
  subscriber: Subscriber;
  result: ResultType;
  subscriberActive?: Subscriber[];
  resultActive?: ResultType[];
  isChangeNeeded: boolean;
  tmpTaskId?: string;
  parentId?: string;
}

export const deliveryPointLayout = ({
  id,
  ymaps,
  subscriber,
  newPuTypeMarker,
  result,
  subscriberActive = [],
  resultActive = [],
  isChangeNeeded,
  tmpTaskId,
  parentId,
}: IDeliveryPointLayout): any => {
  const width = 34;
  return {
    iconLayout: ymaps.templateLayoutFactory
      .createClass(`<div class="delivery-point-balloon_id_${id}" style="position: relative">
            <div style="
              position: absolute; 
              width: ${(width * 22) / 15}px; 
              top: ${(width * 7) / 15}px; 
              left: ${(width * -7) / 30}px"
            >
                ${activeIcon(
                  checkActive({
                    subscriber,
                    result,
                    subscriberActive,
                    resultActive,
                    tmpTaskId,
                    parentId,
                  })
                )}
            </div>
            <div style="
              position: absolute; 
              width: ${width}px; 
              top: ${(width * 19) / 30}px"
            >
                ${mainIcon(subscriber)}
            </div>
            <div style="
              position: absolute;
              width: ${width}px; 
              top: ${newPuTypeMarker?.length > 2 ? 1.05 * width : width}px; 
              font-size: ${width / (newPuTypeMarker?.length > 2 ? 4 : 3)}px; 
              text-align: center;
              "
            >
                ${newPuTypeMarker ?? ''}
            </div>
            <div style="
              position: absolute; 
              width: ${width * (5 / 6)}px; 
              left: ${width * (1 / 12)}px"
            >
                ${topIcon(result, isChangeNeeded)}
            </div>
          </div>`),
    iconShape: {
      type: 'Circle',
      // Круг описывается в виде центра и радиуса
      coordinates: [0.5 * width, width],
      radius: 0.8 * width,
    },
    iconOffset: [-0.5 * width, -1.95 * width],
  };
};
