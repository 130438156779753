import {
  Autocomplete,
  Badge,
  Box,
  Checkbox,
  createFilterOptions,
  IconButton,
  Popover,
  TextField,
} from '@mui/material';
import FilterAltIcon from '@mui/icons-material/FilterAlt';
import CloseIcon from '@mui/icons-material/Close';
import { useEffect, useRef, useState } from 'react';
import { useAllPuOwner } from '@/hooks/useQuery/useAllSupplyCatalogs';
import { SupplyNetworkElementItem, SupplyPUItem } from '@/dto/taskmap/Dto';
import { FilterButtonWrapper, StyledPopper } from './SupplyFilterButton.styled';
import { SelectFilterOptType } from '@/components/filter/MultySelectFilter';
import { useAnchorElement } from '@/hooks/useAnchorElement';
import { isReturnBill } from '../utils';
import { FILTER_OPTIONS } from './utils';
import shallow from 'zustand/shallow';
import useSupplyStore from '../SupplyStore';

interface SupplyFilterButtonProps {
  itemsList: SupplyPUItem[] | SupplyNetworkElementItem[];
  field: string;
}

export const SupplyFilterButton = ({ itemsList, field }: SupplyFilterButtonProps) => {
  const { filters, setFilters } = useSupplyStore(
    (state) => ({
      filters: state.filters,
      setFilters: state.setFilters,
    }),
    shallow
  );

  const { puOwnerOptions } = useAllPuOwner();

  const getTitle = (item: string) => {
    switch (field) {
      case 'ownerId':
        return puOwnerOptions.find((it) => it.value.toString() === item)?.label;
      default:
        return item;
    }
  };

  const optionFilter: SelectFilterOptType[] = itemsList
    .map((item) => {
      if (field === 'taskNumber' && !item.taskNumber) {
        return { label: 'Не устанавливался', value: null };
      }
      if ('imei' in item) {
        const fieldItemNetwork = item[field as keyof SupplyNetworkElementItem];
        return typeof fieldItemNetwork !== 'object'
          ? { label: getTitle(fieldItemNetwork?.toString() ?? ''), value: fieldItemNetwork }
          : { label: fieldItemNetwork?.name, value: fieldItemNetwork?.id };
      } else {
        const fieldItemPu = item[field as keyof SupplyPUItem];
        return typeof fieldItemPu !== 'object'
          ? { label: getTitle(fieldItemPu?.toString() ?? ''), value: fieldItemPu }
          : { label: fieldItemPu?.name, value: fieldItemPu?.id };
      }
    })
    .filter((item, index, self) => index === self.findIndex((t) => t.value === item.value))
    .filter((item) => item.label);

  const inputRef = useRef<HTMLInputElement>(null);

  const returnBill = isReturnBill();

  const { anchorEl, handleOpen, handleClose, open } = useAnchorElement();

  const [filterItem, setFilterItem] = useState<SelectFilterOptType[]>([]);

  const handleClearFilter = () => {
    setFilterItem([]);
  };

  useEffect(() => {
    if (anchorEl) {
      setTimeout(() => {
        inputRef.current?.focus();
      }, 200);
    }
  }, [anchorEl]);

  useEffect(() => {
    if (filterItem.length) {
      setFilters((prevFilters) => ({
        ...prevFilters,
        [field]: filterItem.map((item) => item?.value),
      }));
    } else {
      if (!filters[field]) return;
      setFilters((prevFilters) => {
        const newState: Record<string, string[]> = {};
        for (const key in prevFilters) {
          if (key !== field) {
            newState[key] = prevFilters[key];
          }
        }
        return newState;
      });
    }
  }, [filterItem]);

  return (
    <>
      {returnBill && optionFilter.length > 0 ? (
        <FilterButtonWrapper>
          {filterItem.length > 0 && (
            <IconButton
              sx={{ padding: '4px' }}
              onClick={handleClearFilter}
            >
              <CloseIcon sx={{ width: '16px', height: '16px' }} />
            </IconButton>
          )}
          <IconButton
            sx={{ padding: '5px' }}
            onClick={handleOpen}
          >
            <Badge
              color='primary'
              variant='dot'
              invisible={!filterItem.length}
            >
              <FilterAltIcon sx={{ width: '16px', height: '16px' }} />
            </Badge>
          </IconButton>
        </FilterButtonWrapper>
      ) : (
        <div style={{ height: '26px' }}></div>
      )}
      <Popover
        open={Boolean(anchorEl)}
        anchorEl={anchorEl}
        onClose={handleClose}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'left',
        }}
      >
        <Box
          padding={2}
          style={{ minWidth: '250px' }}
        >
          <Autocomplete<SelectFilterOptType, true>
            multiple
            value={filterItem}
            filterOptions={FILTER_OPTIONS}
            sx={{ width: '100%', minWidth: '160px' }}
            includeInputInList
            onChange={(event, value: SelectFilterOptType[]) => {
              setFilterItem(value);
            }}
            disableCloseOnSelect
            renderInput={(params) => (
              <TextField
                {...params}
                inputRef={inputRef}
                variant='outlined'
                label={'Введите текст'}
                placeholder={'Введите текст'}
              />
            )}
            renderOption={(props, option, { selected }) => (
              <li
                {...props}
                key={option.value}
              >
                <Checkbox
                  edge='start'
                  checked={selected}
                  tabIndex={-1}
                  disableRipple
                />
                {option.label}
              </li>
            )}
            PopperComponent={(props) => <StyledPopper {...props} />}
            options={optionFilter}
            openOnFocus
            getOptionLabel={(option) => option.label}
            isOptionEqualToValue={(option, value) => option.value === value.value}
            renderTags={(elements: unknown[]) => (
              <span style={{ paddingRight: 3 }}>{`выбрано ${elements?.length}`}</span>
            )}
          />
        </Box>
      </Popover>
    </>
  );
};
