import { Carousel } from 'react-responsive-carousel';
import { Grid, Tooltip } from '@mui/material';
import React, { FC, useMemo } from 'react';

import {
  PnrMainTableCarouselInner,
  PnrMainTableEntranceWrapper,
  PnrMainTableHeader,
  PnrMainTableInfoWrapper,
  PnrMainTableItem,
  PnrMainTableEntrance,
  PnrMainTableInfoText,
} from './PnrMainTable.styled';
import PnrPlanningModal from '@/components/blocks/PnrPlanningModal/PnrPlanningModal';
import { getAllEntranceMacedApartmentsCount } from './utils';
import PnrElementsInfo from '@/components/blocks/PnrElementsInfo/PnrElementsInfo';
import shallow from 'zustand/shallow';
import { usePNRStore } from '@/components/features/pnr/store';
import { MKDNetworkElementHouseDto } from '@/services/HouseDataService/dto/HouseDataServiceResponseDto';

export const MainCarousel: FC = () => {
  const {
    selectedIndicator,
    houseData,
    setNetworkElements,
    currentEntranceLayout,
    entranceToFlatCountMap,
    entranceIdx,
    floorInEntranceIdx,
    floorToFlats,
    valueRadio,
    smrTaskToAppartment,
  } = usePNRStore(
    (state) => ({
      selectedIndicator: state.selectedIndicator,
      houseData: state.houseData,
      floorCount: state.floorCount,
      setNetworkElements: state.setNetworkElements,
      currentEntranceLayout: state.currentEntranceLayout,
      entranceToFlatCountMap: state.entranceToFlatCountMap,
      entranceIdx: state.entranceIdx,
      floorInEntranceIdx: state.floorInEntranceIdx,
      floorToFlats: state.floorToFlats,
      valueRadio: state.valueRadio,
      smrTaskToAppartment: state.smrTaskToAppartment,
    }),
    shallow
  );

  React.useEffect(() => {
    if (currentEntranceLayout) {
      setNetworkElements([]);
      const netElements: MKDNetworkElementHouseDto[] = [];
      houseData?.osTasks?.forEach((it) => {
        it.networkElements?.forEach((elem) => {
          if (elem.houseLevel?.entranceNumber === selectedIndicator) {
            netElements.push(elem);
          }
        });
      });
      setNetworkElements(netElements);
    }
  }, [currentEntranceLayout]);

  const allEntranceMacedApartmentsCount = useMemo(
    () => getAllEntranceMacedApartmentsCount(floorToFlats, houseData),
    [floorToFlats, houseData]
  );

  const floorsCount = currentEntranceLayout?.floors?.filter((it) => it.number >= 1)?.length;

  return (
    <div style={{ width: '100%' }}>
      <Carousel
        showArrows={false}
        dynamicHeight
        showThumbs={false}
        showIndicators={false}
        selectedItem={entranceIdx.indexOf(selectedIndicator)}
      >
        {entranceIdx.map((entranceNumber) => {
          return (
            <PnrMainTableCarouselInner key={entranceNumber}>
              <PnrMainTableHeader>
                <PnrMainTableEntranceWrapper>
                  <PnrMainTableEntrance>{entranceNumber}</PnrMainTableEntrance>
                  <PnrMainTableInfoWrapper>
                    <PnrMainTableInfoText secondary>Этажей</PnrMainTableInfoText>
                    <PnrMainTableInfoText>{floorsCount}</PnrMainTableInfoText>
                  </PnrMainTableInfoWrapper>

                  <PnrMainTableInfoWrapper>
                    <PnrMainTableInfoText secondary>Квартир</PnrMainTableInfoText>
                    <PnrMainTableInfoText>
                      {entranceToFlatCountMap.get(entranceNumber)}
                    </PnrMainTableInfoText>
                  </PnrMainTableInfoWrapper>

                  <PnrMainTableInfoWrapper>
                    <PnrMainTableInfoText secondary>ИПУ</PnrMainTableInfoText>
                    <PnrMainTableInfoText>
                      {allEntranceMacedApartmentsCount.get(entranceNumber)}
                    </PnrMainTableInfoText>
                  </PnrMainTableInfoWrapper>
                </PnrMainTableEntranceWrapper>
              </PnrMainTableHeader>

              <PnrElementsInfo />
              <div style={{ height: '25rem', overflow: 'auto', marginTop: '10px' }}>
                {floorInEntranceIdx &&
                  floorInEntranceIdx
                    .get(entranceNumber)!
                    .sort((a, b) => b - a)
                    .map((it) => {
                      return (
                        <Grid
                          key={it}
                          style={{ marginBottom: '4px', marginTop: '0px' }}
                          container
                          rowSpacing={1}
                          columnSpacing={{ xs: 0.4, sm: 0.4, md: 0.4 }}
                        >
                          <Grid
                            direction='row'
                            container
                            columnSpacing={{ xs: 0.6, sm: 0.6, md: 0.6 }}
                          >
                            <Grid
                              item
                              xs={0.2}
                            >
                              <div
                                style={{
                                  paddingTop: '3px',
                                  color: '#a3a2a2',
                                  fontWeight: '700',
                                }}
                              >
                                {it}
                              </div>
                            </Grid>
                            <Grid
                              style={
                                currentEntranceLayout &&
                                currentEntranceLayout.floors &&
                                currentEntranceLayout.floors.find((floor) => floor.number === it)
                                  ?.flats?.length
                                  ? {}
                                  : { background: 'lightgray' }
                              }
                              item
                              container
                              xs={11.8}
                              direction='row'
                            >
                              {floorToFlats
                                .get(entranceNumber)
                                ?.get(it)
                                ?.map((flatNumber) => {
                                  return (
                                    <Grid
                                      key={flatNumber}
                                      item
                                      sx={{
                                        flexBasis: 'max-content',
                                        flex: 'auto',
                                      }}
                                    >
                                      <PnrMainTableItem
                                        style={
                                          currentEntranceLayout &&
                                          currentEntranceLayout.floors &&
                                          currentEntranceLayout.floors.find(
                                            (floor) => floor.number === it
                                          )?.flats?.length
                                            ? {}
                                            : { background: 'lightgray' }
                                        }
                                      >
                                        <div
                                          style={{
                                            display: 'flex',
                                            flexDirection: 'row',
                                          }}
                                        >
                                          <div
                                            style={{
                                              width: '40px',
                                              marginRight: '5px',
                                              borderRight: '1px solid #ccc',
                                              paddingRight: '3px',
                                            }}
                                          >
                                            {flatNumber}
                                          </div>
                                          <Tooltip
                                            title={
                                              smrTaskToAppartment.get(flatNumber.toString()) &&
                                              smrTaskToAppartment.get(flatNumber.toString())!
                                                .meterType
                                                ? smrTaskToAppartment.get(flatNumber.toString())!
                                                    .meterType!
                                                : ''
                                            }
                                          >
                                            <div
                                              style={{
                                                overflow: 'hidden',
                                                minWidth: '180px',
                                                flex: 'auto',
                                              }}
                                            >
                                              {valueRadio === 'mac'
                                                ? smrTaskToAppartment.get(flatNumber.toString())
                                                    ?.mac
                                                : valueRadio === 'number'
                                                ? smrTaskToAppartment.get(flatNumber.toString())
                                                    ?.meterNumber
                                                : smrTaskToAppartment.get(flatNumber.toString())
                                                    ?.meterType
                                                ? smrTaskToAppartment
                                                    .get(flatNumber.toString())
                                                    ?.meterType?.slice(0, 15) + '...'
                                                : ''}
                                            </div>
                                          </Tooltip>
                                        </div>
                                      </PnrMainTableItem>
                                    </Grid>
                                  );
                                })}
                            </Grid>
                          </Grid>
                        </Grid>
                      );
                    })}
              </div>
            </PnrMainTableCarouselInner>
          );
        })}
      </Carousel>
      <PnrPlanningModal />
    </div>
  );
};
