import { ReformaGkhTaskDto } from '@/services/HouseDataService/dto/HouseDataServiceResponseDto';
import React, { useEffect, useMemo, useState } from 'react';
import { PnrOrderItem } from '../PnrOsOrder/PnrOsOrder';
import { FilterArgEnum } from '@/components/filter/MultySelectFilter';
import { usePNRStore } from '../../store';
import { v4 as uuid } from 'uuid';
import { useHouseEquipmentPu } from '../utils';
import FilterButtons from '@/components/filter/FilterButtons/FilterButtons';
import { SingleLine } from '@/components/inputs/SingleLineNew';
import { ruRU } from '@mui/x-data-grid';
import {
  PnrOrderBoxShaddow,
  PnrOrderTitle,
  PnrOrderRow,
  PnrOrderInputWrapper,
  PnrOrderDataGrid,
} from '../PnrOrderTab.styled';
import PnrPuOrderModal from './PnrPuOrderModal';
import { updateSelection } from '../../utils';

export type PuElementRow = ReformaGkhTaskDto & PnrOrderItem;
type Filter = { mac?: string; number?: string };

const PnrPuOrder = () => {
  const puColumns = useHouseEquipmentPu();
  const smrTasks = usePNRStore((state) => state.houseData?.smrTasks);
  const puElements = useMemo(
    () => smrTasks?.filter((el) => !!el).map((el) => ({ ...el, tmpId: uuid() })),
    [smrTasks]
  );
  const [filteredElements, setFilteredElements] = useState<PuElementRow[]>([]);
  const [mac, setMac] = useState('');
  const [number, setNumber] = useState('');
  const [filter, setFilter] = useState<Filter>();
  const [selectedRowsData, setSelectedRowsData] = useState<PuElementRow[]>([]);

  const selectedRowsIds = useMemo(
    () => selectedRowsData.map((row) => row.tmpId),
    [selectedRowsData]
  );

  useEffect(() => {
    if (!puElements) return;
    if (filter?.mac || filter?.number) {
      const filteredElements = puElements.filter((el) =>
        Object.entries(filter).every(([key, value]) =>
          value ? el[key as keyof Filter]?.toString() === value : true
        )
      );

      setFilteredElements(filteredElements);
    } else {
      setFilteredElements(puElements);
    }
  }, [puElements, filter]);

  const handleSelectionChange = (newSelection: string[]) => {
    const finalRows = updateSelection(newSelection, selectedRowsData, filteredElements, 'tmpId');
    setSelectedRowsData(finalRows);
  };

  const clearSearch = () => {
    setMac('');
    setNumber('');
  };

  const handleSearch = () => setFilter({ number, mac });

  const rowsWithSelectedRows = [
    ...selectedRowsData,
    ...(filteredElements
      ? filteredElements.filter((el) => !selectedRowsData?.find((f) => el.tmpId === f.tmpId))
      : []),
  ];

  return (
    <PnrOrderBoxShaddow>
      <PnrOrderTitle>ПУ на доме</PnrOrderTitle>

      <PnrOrderRow>
        <PnrOrderInputWrapper>
          <SingleLine
            argList={[FilterArgEnum.EQUAL]}
            onChange={(value: string) => setNumber(value)}
            label={'№ ПУ'}
            value={number}
            argValue={FilterArgEnum.EQUAL}
          />
        </PnrOrderInputWrapper>

        <PnrOrderInputWrapper>
          <SingleLine
            argList={[FilterArgEnum.EQUAL]}
            onChange={(value: string) => setMac(value)}
            label={'MAC'}
            value={mac}
            argValue={FilterArgEnum.EQUAL}
          />
        </PnrOrderInputWrapper>

        <div style={{ marginLeft: 'auto' }}>
          <FilterButtons
            onSearchClick={handleSearch}
            onCloseClick={clearSearch}
          />
        </div>
      </PnrOrderRow>

      <PnrOrderDataGrid
        checkboxSelection
        columns={puColumns}
        getRowId={(row) => row.tmpId}
        disableColumnFilter={true}
        localeText={ruRU.components.MuiDataGrid.defaultProps.localeText}
        hideFooter
        disableColumnMenu
        rows={rowsWithSelectedRows}
        selectionModel={selectedRowsIds}
        onSelectionModelChange={(newSelection) => handleSelectionChange(newSelection as string[])}
      />

      <PnrPuOrderModal selectedRowsData={selectedRowsData} />
    </PnrOrderBoxShaddow>
  );
};

export default PnrPuOrder;
