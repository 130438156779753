import { styled } from '@mui/material';
import { DataGrid } from '@mui/x-data-grid';

export const StatisticMoreThanOneApealFiltersWrapper = styled('div')(
  ({ isMobile }: { isMobile: boolean }) => ({
    padding: '0 0 0 5vw',
    paddingBottom: 30,
    position: 'relative',
    display: 'flex',
    flexWrap: isMobile ? 'wrap' : 'nowrap',
    justifyContent: isMobile ? 'center' : 'flex-start',
    alignItems: isMobile ? 'center' : 'flex-end',
    gap: 6,
    marginTop: 16,
    marginRight: 30,
  })
);

export const StatisticMoreThanOneApealDataGrid = styled(DataGrid)({
  '& .MuiDataGrid-columnHeaderTitle': {
    textOverflow: 'clip',
    whiteSpace: 'break-spaces',
    lineHeight: 1,
    textAlign: 'center',
    fontWeight: 'bold',
  },
});
