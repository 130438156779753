import React from 'react';
import { Paper, Grid, Typography, Box, Stack } from '@mui/material';
import EmbeddedStatisticsBar from '../../Embedded/EmbeddedStatisticsBar';
import EmbeddedStatisticsPie from '../../Embedded/EmbeddedStatisticsPie';
import LoadSpinner from '@/components/ui/LoadSpinner/LoadSpinner';
import { SelectFilterOptType } from '../../../filter/MultySelectFilter';
import { TaskFilterParam } from '@/dto/taskmap/Dto';
import FilterButtons from '@/components/filter/FilterButtons/FilterButtons';
import { MultiSelect } from '@/components/inputs/MultiSelectNew';
import { getDataFromFilter } from '../../../inputs/MultiSelectNew/utils';
import { generateDetailedInfo } from '../../../button/DetailedInfo/utils';
import { getLabels, getData, getColors } from './utils';
import { useCatalog } from '@/hooks/CatalogHook';
import { AsumbTaskStatisticsForCheckChartType } from '@/services/StatisticsService/RequestStatisticService/dto/RequestStatisticServiceResponseDto';
import { MainText } from '../../StatisticMain.styled';
import { useMobileSize } from '@/hooks/useMediaQuery/useMobileSize';

interface CheckStatisticsProps {
  data: AsumbTaskStatisticsForCheckChartType;
  header: string;
  load: boolean;
  updateFunction: (filter: TaskFilterParam) => void;
}

const FILTERS_LABELS = {
  contracts: 'Контракт',
};

const CheckStatistics: React.FC<CheckStatisticsProps> = ({
  data,
  header,
  load,
  updateFunction,
}) => {
  let all = 0;
  for (let i = 0; i < data?.all?.datasets?.length; i++) {
    for (let j = 0; j < data?.all?.datasets[i]?.data?.length; j++) {
      all += Number(data?.all?.datasets[i]?.data[j]);
    }
  }

  const { getContractCatalog } = useCatalog((state) => ({
    getContractCatalog: state.getContractCatalog,
  }));
  const contractListResponse = getContractCatalog();
  const [selectedContractList, setSelectedContractList] = React.useState<SelectFilterOptType[]>([]);
  const [contractListArg, setContractListArg] = React.useState(0);

  const filtParam: TaskFilterParam = {};
  const onSubmitSearch = () => {
    if (selectedContractList.length) {
      filtParam.contractIds = getDataFromFilter(selectedContractList, 'value');
      filtParam.contractIdsArg = contractListArg;
    }
    updateFunction(filtParam);
  };

  const onResedClick = () => setSelectedContractList([]);
  const detailedInfo = generateDetailedInfo([selectedContractList, FILTERS_LABELS.contracts]);

  const meterChangeLabels: string[] = getLabels(data?.meterChange);
  const meterChangeData: string[] = getData(data?.meterChange);
  const meterChangeColors: string[] = getColors(data?.meterChange);
  const tkoLabels: string[] = getLabels(data?.tko);
  const tkoData: string[] = getData(data?.tko);
  const tkoColors: string[] = getColors(data?.tko);

  const isMobile = useMobileSize();

  return (
    <Paper
      elevation={6}
      sx={{ p: 1 }}
    >
      <MainText className='MainTextStatistc'>{header}</MainText>

      <Stack
        direction='row'
        spacing={2}
        alignItems='flex-end'
        sx={{ mb: 2 }}
      >
        <MultiSelect
          label={FILTERS_LABELS.contracts}
          options={contractListResponse}
          value={selectedContractList}
          argValue={contractListArg}
          onChange={(value: SelectFilterOptType[]) => setSelectedContractList(value)}
          onArgChange={(arg: number) => setContractListArg(arg)}
        />
        <FilterButtons
          info={detailedInfo}
          onSearchClick={onSubmitSearch}
          onCloseClick={onResedClick}
        />
      </Stack>
      {load ? (
        <LoadSpinner />
      ) : (
        <Grid
          container
          spacing={2}
        >
          <Grid
            item
            xs={12}
            md={2}
          >
            <Box
              sx={{
                border: '1px solid #ccc',
                borderRadius: 1,
                p: 1,
                mb: 2,
              }}
            >
              <Box sx={{ display: 'flex', justifyContent: 'space-between', mb: 1 }}>
                <Typography variant='body1'>Всего :</Typography>
                <Typography variant='body1'>{all}</Typography>
              </Box>
              {data.all.labels?.map((label, index) => (
                <Box
                  key={index}
                  sx={{ display: 'flex', justifyContent: 'space-between', mb: 0.5 }}
                >
                  <Typography variant='body2'>{label as string}:</Typography>
                  <Typography variant='body2'>
                    {<> {data.all.datasets?.[index]?.data[0]}</>}
                  </Typography>
                </Box>
              ))}
            </Box>
          </Grid>
          <Grid
            item
            xs={12}
            md={2}
          >
            <Box
              sx={{
                height: 350,
                width: isMobile ? 600 : 300,
              }}
            >
              <EmbeddedStatisticsBar data={data.all} />
            </Box>
          </Grid>

          <Grid
            item
            xs={12}
            md={4}
          >
            <Typography
              variant='h6'
              align='center'
              gutterBottom
            >
              {data.tko.datasets?.[0]?.label}
            </Typography>

            <Box
              display='flex'
              flexDirection={isMobile ? 'column' : 'row'}
              alignItems={isMobile ? 'center' : 'flex-start'}
              justifyContent='center'
            >
              <Box
                sx={{
                  flex: 1,
                  minWidth: 300,
                  maxWidth: 300,
                  height: 300,
                  mb: isMobile ? 2 : 0,
                }}
              >
                <EmbeddedStatisticsPie data={data.tko} />
              </Box>

              <Box sx={{ ml: isMobile ? 0 : 2 }}>
                {tkoData.map((value, index) => (
                  <Box
                    key={index}
                    display='flex'
                    alignItems='center'
                    mb={1}
                  >
                    <Box
                      sx={{
                        width: 20,
                        height: 20,
                        bgcolor: tkoColors[index],
                        mr: 1,
                      }}
                    />
                    {value && (
                      <Typography variant='body2'>
                        {tkoLabels[index]}: {value}
                      </Typography>
                    )}
                  </Box>
                ))}
              </Box>
            </Box>
          </Grid>

          <Grid
            item
            xs={12}
            md={4}
          >
            <Typography
              variant='h6'
              align='center'
              gutterBottom
            >
              {data.meterChange.datasets?.[0]?.label}
            </Typography>
            <Box
              display='flex'
              flexDirection={isMobile ? 'column' : 'row'}
              alignItems={isMobile ? 'center' : 'flex-start'}
              justifyContent='center'
            >
              <Box
                sx={{
                  flex: 1,
                  minWidth: 200,
                  maxWidth: 300,
                  height: 300,
                  mb: isMobile ? 2 : 0,
                }}
              >
                <EmbeddedStatisticsPie data={data.meterChange} />
              </Box>

              <Box sx={{ ml: isMobile ? 0 : 2 }}>
                {meterChangeData.map((value, index) => (
                  <Box
                    key={index}
                    display='flex'
                    alignItems='center'
                    mb={1}
                  >
                    <Box
                      sx={{
                        width: 20,
                        height: 20,
                        bgcolor: meterChangeColors[index],
                        mr: 1,
                      }}
                    />
                    {value && (
                      <Typography variant='body2'>
                        {meterChangeLabels[index]}: {value}
                      </Typography>
                    )}
                  </Box>
                ))}
              </Box>
            </Box>
          </Grid>
        </Grid>
      )}
    </Paper>
  );
};

export default CheckStatistics;
