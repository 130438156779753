import { HOST, YodaRestClient } from '../YodaAuthService';
import { GeoCoordinate } from '@/components/maps/types';
import { ApiResponse } from '@/types/api';
import {
  AssignTasksResponse,
  ZoningBoundResponse,
  ZoningGroupResponse,
  ZoningResponse,
} from './dto/ZoningServiceResponseDto';
import { CoveredItem, ZoningBoundRequest, ZoningTaskFilter } from './dto/ZoningServiceRequestDto';

export async function create(
  groupId: string,
  coordinate: GeoCoordinate[]
): ApiResponse<ZoningGroupResponse> {
  const url = HOST + `/rest/group/${groupId}/zoning`;
  return YodaRestClient.post(url, coordinate);
}

export async function remove(zoningId: string): ApiResponse<any> {
  const url = HOST + `/rest/group/zoning/${zoningId}`;
  return YodaRestClient.delete(url);
}

export async function findAllZonningGroup(): ApiResponse<ZoningGroupResponse[]> {
  const url = HOST + `/rest/group/zoning`;
  return YodaRestClient.get(url);
}

export async function findAllZoningBound(
  request: ZoningBoundRequest
): ApiResponse<ZoningBoundResponse[]> {
  const url = HOST + `/rest/geo/zoning/map`;
  return YodaRestClient.post(url, request);
}

export async function findAllByZoningByFilter(
  filter: ZoningTaskFilter
): ApiResponse<ZoningResponse> {
  const url = HOST + `/rest/geo/zoning/search`;
  return YodaRestClient.post(url, filter);
}

export async function assignTasks(
  coveredItemList: CoveredItem[]
): ApiResponse<AssignTasksResponse> {
  const url = HOST + `/rest/geo/zoning/assign`;
  return YodaRestClient.post(url, coveredItemList);
}
