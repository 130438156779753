import { el } from 'date-fns/locale';
import { StatisticSummaryResponse } from '../api/dto/response';
import {
  DefaultStatistic,
  DisplayManufacturer,
  ManufacturerDoughnutStatistic,
  ManufacturerShowInfo,
  ManufacturerStatisticSegment,
  NamedStatisticInvestShowInfo,
  SegmentPart,
  ShowInfo,
} from '../types/types';
import { ChartOptions } from 'chart.js';
import { colorsLine } from './DynamicStatistic/utils';
import { useInvestStatisticStore } from '../store';

export const doughnutSectorOptions: ChartOptions<'doughnut'> = {
  layout: {
    padding: {
      left: 150,
      right: 150,
    },
  },
  spacing: 5,
};

type FilteredShowInfo = {
  default: DefaultStatistic[];
  manufacturer?: ManufacturerDoughnutStatistic | ManufacturerShowInfo;
  techDecision?: ManufacturerStatisticSegment[];
  otherPU?: CountOtherPU;
};
export const filterShowInfo = (
  displayManufacturer: DisplayManufacturer,
  count: number,
  showInfo?: ShowInfo | NamedStatisticInvestShowInfo,
  displayTechDesicion?: boolean
) => {
  if (!showInfo) {
    return undefined;
  }

  const { default: defaultData, manufacturer } = showInfo;
  const techDecision = 'techDecision' in showInfo && showInfo.techDecision;

  const result: FilteredShowInfo = {
    default: (defaultData as DefaultStatistic[]) || [],
  };

  const manufacturerObj = manufacturer as ManufacturerDoughnutStatistic | ManufacturerShowInfo;

  Object.entries(displayManufacturer).forEach(([key, value]) => {
    if (value && manufacturer && manufacturerObj[key]) {
      result.manufacturer = result.manufacturer || {};

      result.manufacturer[key] = manufacturerObj[key];
    }
  });

  if (result.manufacturer) {
    const percentage = Number(
      countOtherPU(count, showInfo, displayManufacturer).percentage?.toFixed(1)
    );
    result.manufacturer.otherPU = [
      {
        title: 'ПРОЧИЕ',
        count: countOtherPU(count, showInfo, displayManufacturer).count,
      },
    ];

    result.manufacturer.otherPU[0].percentage = percentage;
  }

  if (displayTechDesicion && Array.isArray(techDecision)) {
    result.techDecision = techDecision;
  }

  return Object.fromEntries(
    Object.entries(result).filter(([_, value]) => {
      if (Array.isArray(value)) {
        return value && value.length > 0;
      } else if (typeof value === 'object') {
        return (
          Object.entries(value).filter(([_, valueItem]) => valueItem && valueItem.length > 0)
            .length > 0
        );
      } else {
        return false;
      }
    })
  );
};

type CountOtherPU = {
  count: number;
  percentage: number | null;
};

export const countOtherPU = (
  count: number,
  showInfo: ShowInfo | NamedStatisticInvestShowInfo,
  displayManufacturer: DisplayManufacturer
) => {
  const result: CountOtherPU = { count: count, percentage: 100 };

  const { manufacturer } = showInfo;

  const manufacturerObj = manufacturer as ManufacturerDoughnutStatistic | ManufacturerShowInfo;

  Object.entries(displayManufacturer).forEach(([key, value]) => {
    if (value && manufacturerObj[key]) {
      const manufacturerData = manufacturerObj[key];
      if (manufacturerData && Array.isArray(manufacturerData) && manufacturerData.length > 0) {
        const firstItem = manufacturerData[0];

        result.count -= ('amount' in firstItem ? firstItem.amount : firstItem.count) || 0;

        if ('percentage' in firstItem && !firstItem.percentage && firstItem.percentage !== 0) {
          result.percentage = null;
        } else {
          result.percentage = result.percentage || 0;
          result.percentage -= firstItem.percentage ?? 0;
        }
      }
    }
  });
  return result;
};

export const FILTERS_LABELS = {
  contract: 'Контракт',
  energyCompany: 'Сбыт',
};

export enum DYNAMIC_SWITCH_LABELS {
  NARTIS = 'НАРТИС',
  LENENERGO = 'ЛЕНЭНЕРГО',
}

export const timePeriod = [
  {
    title: 'ДЕНЬ',
    name: 'day',
  },
  {
    title: 'НЕДЕЛЯ',
    name: 'week',
  },
  {
    title: 'МЕСЯЦ',
    name: 'month',
  },
];

export const colorsSegment = {
  '1Ф': ['#2C3E50', '#556679', '#1A2A38'],
  '3Ф': ['#1ABC9C', '#5BDBC2', '#16A085'],
  ШЛЮЗ: ['#FF5733', '#FF9C75', '#C0392B'],
  РОУТЕР: ['#F39C12', '#FDC05D', '#D35400'],
  'ОДПУ ЮЛ': ['#BDC3C7', '#D5DBDB', '#7F8C8D'],
  ОДПУ: ['#9B59B6', '#B78BC9', '#8E44AD'],
};

export type ColorKeys = keyof typeof colorsSegment;

export const formatCount = (number: number | string | undefined) => {
  const formatNumber = typeof number === 'string' ? Number(number?.replace(/\s/g, '')) : number;
  if (!formatNumber) {
    return 0;
  }

  if (formatNumber >= 1000000) {
    const billions = (formatNumber / 1000000).toFixed(3);
    return billions + ' МЛРД';
  } else if (formatNumber >= 1000) {
    const millions = (formatNumber / 1000).toFixed(3);
    return millions + ' МЛН';
  } else {
    return formatNumber + ' ТЫС';
  }
};

export const formatNumberWithSpaces = (number: number) => number?.toLocaleString('ru-RU');

export const colorsManufacturer = { nartis: '#56CCF2', lenenergo: '#479B36' };
export type ColorKey = keyof typeof colorsManufacturer;

export const colorLines = (title: string, idx: number) => {
  if (title === DYNAMIC_SWITCH_LABELS.NARTIS) {
    return colorsManufacturer.nartis;
  } else if (title === DYNAMIC_SWITCH_LABELS.LENENERGO) {
    return colorsManufacturer.lenenergo;
  } else {
    return colorsLine[idx];
  }
};
