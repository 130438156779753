import { ConfirmationDialog } from '@/components/common/ConfirmationDialog';
import { ActionLogType, useActionLog } from '@/hooks/ActionLogHook';
import { Autocomplete, Button, TextField, Typography } from '@mui/material';
import { useState } from 'react';
import { useFormContext } from 'react-hook-form';
import { SupplyTabType } from '../StampComponent/StampComponent';
import { StorehouseBillType, SupplyConstant } from '../SupplyConstant';
import { useStorehouseWorkerAccess, useStorehouseChiefAccess } from '../SupplyPUComponent/utils';
import useSupplyStore from '../SupplyStore';
import {
  SupplyButtonPanelModalContent,
  SupplyButtonPanelWrapper,
} from './SupplyButtonPanel.styled';
import {
  SaveDialog,
  ISSUE_TEXT,
  RETURN_TEXT,
  FACTORY_TEXT,
  RELOCATION_TEXT,
  RELOCATION_TEXT_GET,
  unconfirmedCheckText,
  FACTORY_TEXT_POST,
  getAcceptActionText,
} from './utils';
import CommonModal from '@/components/common/CommonModal/CommonModal';
import { SelectFilterOptType } from '@/components/filter/MultySelectFilter';
import {
  useAllContractors,
  useAllFactories,
  useAllSupplyGroups,
} from '@/hooks/useQuery/useAllSupplyCatalogs';
import moment from 'moment';

interface SupplyButtonPanelProps extends SupplyTabType {
  onBackClick: () => any;
  onSaveDraftClick: (billType: StorehouseBillType | null) => any;
  onSaveClick: (billType: StorehouseBillType) => any;
  accessProject: boolean;
}

export const SupplyButtonPanel = (props: SupplyButtonPanelProps) => {
  const { addActionLog } = useActionLog();
  const methods = useFormContext<{ files: File[] }>();
  const workerAccess = useStorehouseWorkerAccess();
  const storehouseChiefAccess = useStorehouseChiefAccess();

  const [saveAlert, setSaveAlert] = useState<SaveDialog>({ open: false, action: 1 });
  const { supplyBillDto, supplyMeterTypes, supplyOsTypes, setContractorId, billType, setBillDate } =
    useSupplyStore((state) => ({
      supplyBillDto: state.supplyBillDto,
      supplyMeterTypes: state.supplyMeterTypes,
      supplyOsTypes: state.supplyOsTypes,
      setContractorId: state.setContractorId,
      billType: state.billType,
      setBillDate: state.setBillDate,
    }));

  const { factoriesOptions } = useAllFactories(true);
  const { contractorsOptions } = useAllContractors();
  const { supplyGroupsOptions } = useAllSupplyGroups(true);

  const [openModal, setOpenModal] = useState<SaveDialog>({ open: false, action: 1 });
  const [selectedResponsable, setSelectedResponsable] = useState<SelectFilterOptType | null>(null);
  const [activeOptions, setActiveOptions] = useState<SelectFilterOptType[]>([]);
  const disableButton =
    supplyBillDto &&
    billType !== SupplyConstant.DRAFT_TYPE_ISSUE &&
    billType !== SupplyConstant.DRAFT_TYPE_RETURN &&
    billType !== SupplyConstant.DRAFT_TYPE_RELOCATION_POST &&
    billType !== SupplyConstant.DRAFT_TYPE_FACTORY_TYPE_POST &&
    billType !== SupplyConstant.DRAFT_FACTORY_TYPE_GET;

  const disableButtonReturn = billType !== SupplyConstant.RETURNED_TYPE_AGREED_PROJECT;

  const relocationTypePostChief =
    supplyBillDto && billType === SupplyConstant.RELOCATION_TYPE_POST && storehouseChiefAccess;
  const disableRelocationButton = disableButton && !relocationTypePostChief;
  const handleCloseSaveAlert = () => setSaveAlert((prev) => ({ ...prev, open: false }));
  const handleOpenSaveAlert = (billType: StorehouseBillType) => {
    const allStore = useSupplyStore.getState();
    const stampList = allStore?.stampList.filter((el) => el.from && el.to);
    const puList = allStore?.puList.filter((el) => el.meterTypeId && el.puItems.length > 0);
    const osList = allStore?.osList.filter((el) => el.typeId && el.networkElementItems.length > 0);

    if (workerAccess && billType === SupplyConstant.RELOCATION_TYPE_GET) {
      const unConfirmedText = unconfirmedCheckText(stampList, puList, osList);
      if (unConfirmedText) {
        addActionLog(ActionLogType.ERROR, unConfirmedText);
        return;
      }
    }

    const files = methods.getValues('files');

    if (stampList.length || puList.length || osList.length) {
      setSaveAlert({
        open: true,
        action: billType,
        stamps: stampList,
        pus: puList,
        os: osList,
        files: files,
      });
    } else {
      addActionLog(ActionLogType.ERROR, `В накладной отсутствуют данные`);
    }
  };

  const handleOpenModal = (billType: StorehouseBillType) => {
    setOpenModal({ open: true, action: billType });

    if (billType === SupplyConstant.DRAFT_TYPE_FACTORY_TYPE_POST) {
      setActiveOptions(factoriesOptions);
    }

    if (billType === SupplyConstant.DRAFT_TYPE_ISSUE) {
      setActiveOptions(contractorsOptions);
    }

    if (billType === SupplyConstant.DRAFT_TYPE_RELOCATION_POST) {
      setActiveOptions(supplyGroupsOptions);
    }
  };

  const handleCloseModal = () => setOpenModal((prev) => ({ ...prev, open: false }));

  const handleResponsableSelect = (responsable: any) => {
    setContractorId(responsable?.value);
    setSelectedResponsable(responsable);
  };

  const handleSave = (billType: StorehouseBillType) => {
    props.onSaveClick(billType);
    handleCloseSaveAlert();
  };

  const handleSaveCopyDraft = (billType: StorehouseBillType) => {
    setBillDate(moment().format('yyyy-MM-DD'));
    props.onSaveDraftClick(billType);
    handleCloseModal();
  };

  const handleSendForReview = (billType: StorehouseBillType) => {
    const allStore = useSupplyStore.getState();
    const stampList = allStore?.stampList.filter((el) => el.from && el.to);
    const puList = allStore?.puList.filter((el) => el.meterTypeId && el.puItems.length > 0);
    const osList = allStore?.osList.filter((el) => el.typeId && el.networkElementItems.length > 0);

    const unConfirmedText = unconfirmedCheckText(stampList, puList, osList);
    if (unConfirmedText) {
      addActionLog(ActionLogType.ERROR, unConfirmedText);
      return;
    }
    props.onSaveClick(billType);
  };

  return (
    <SupplyButtonPanelWrapper>
      <>
        {billType !== SupplyConstant.RELOCATION_TYPE_GET && (
          <Button
            variant='contained'
            disabled={disableButton}
            onClick={() => props.onSaveDraftClick(null)}
          >
            Сохранить как черновик
          </Button>
        )}

        {(billType === SupplyConstant.FACTORY_TYPE_GET ||
          billType === SupplyConstant.DRAFT_FACTORY_TYPE_GET) && (
          <Button
            variant='contained'
            disabled={disableButton}
            onClick={() => handleOpenSaveAlert(SupplyConstant.FACTORY_TYPE_GET)}
          >
            {FACTORY_TEXT}
          </Button>
        )}

        {(billType === SupplyConstant.ISSUED_TYPE ||
          billType === SupplyConstant.DRAFT_TYPE_ISSUE) && (
          <Button
            variant='contained'
            disabled={disableButton}
            onClick={() => handleOpenSaveAlert(SupplyConstant.ISSUED_TYPE)}
          >
            {ISSUE_TEXT}
          </Button>
        )}

        {(billType === SupplyConstant.RETURNED_TYPE ||
          billType === SupplyConstant.DRAFT_TYPE_RETURN ||
          billType === SupplyConstant.RETURNED_TYPE_WAITING_APPROVAL_PROJECT ||
          billType === SupplyConstant.RETURNED_TYPE_AGREED_PROJECT) && (
          <Button
            variant='contained'
            disabled={!workerAccess || disableButtonReturn}
            onClick={() => handleOpenSaveAlert(SupplyConstant.RETURNED_TYPE)}
          >
            {RETURN_TEXT}
          </Button>
        )}

        {(billType === SupplyConstant.RETURNED_TYPE ||
          billType === SupplyConstant.DRAFT_TYPE_RETURN) && (
          <Button
            variant='contained'
            disabled={!workerAccess || disableButton}
            onClick={() =>
              handleSendForReview(SupplyConstant.RETURNED_TYPE_WAITING_APPROVAL_PROJECT)
            }
          >
            Отправить на проверку Руководителю проекта
          </Button>
        )}
        {billType === SupplyConstant.RETURNED_TYPE_WAITING_APPROVAL_PROJECT && (
          <>
            <Button
              variant='contained'
              disabled={!props.accessProject}
              onClick={() => props.onSaveClick(SupplyConstant.RETURNED_TYPE_AGREED_PROJECT)}
            >
              Ожидает согласования Руководителя проекта
            </Button>
            <Button
              variant='contained'
              disabled={!props.accessProject}
              onClick={() => props.onSaveClick(SupplyConstant.DRAFT_TYPE_RETURN)}
            >
              Отправить на доработку
            </Button>
          </>
        )}
        {billType === SupplyConstant.RETURNED_TYPE_AGREED_PROJECT && (
          <Button
            variant='contained'
            disabled
          >
            Согласовано Руководителем проекта
          </Button>
        )}

        {(billType === SupplyConstant.RELOCATION_TYPE_POST ||
          billType === SupplyConstant.DRAFT_TYPE_RELOCATION_POST) && (
          <Button
            variant='contained'
            disabled={disableRelocationButton}
            onClick={() => handleOpenSaveAlert(SupplyConstant.RELOCATION_TYPE_POST)}
          >
            {RELOCATION_TEXT}
          </Button>
        )}

        {billType === SupplyConstant.RELOCATION_TYPE_GET && (
          <Button
            variant='contained'
            disabled={disableButton && props.tabType !== 'relocation_get'}
            onClick={() => handleOpenSaveAlert(SupplyConstant.RELOCATION_TYPE_GET)}
          >
            {RELOCATION_TEXT_GET}
          </Button>
        )}

        {(billType === SupplyConstant.FACTORY_TYPE_POST ||
          billType === SupplyConstant.DRAFT_TYPE_FACTORY_TYPE_POST) && (
          <Button
            variant='contained'
            disabled={disableButton}
            onClick={() => handleOpenSaveAlert(SupplyConstant.FACTORY_TYPE_POST)}
          >
            {FACTORY_TEXT_POST}
          </Button>
        )}
      </>

      {props.tabType === 'archive' && billType === SupplyConstant.RETURNED_TYPE && (
        <Button
          variant='contained'
          disabled={!workerAccess}
          onClick={() => handleOpenModal(SupplyConstant.DRAFT_TYPE_FACTORY_TYPE_POST)}
        >
          Копировать в "Отправка на завод. Черновик"
        </Button>
      )}
      {props.tabType === 'archive' && billType === SupplyConstant.FACTORY_TYPE_GET && (
        <Button
          variant='contained'
          disabled={!workerAccess}
          onClick={() => handleOpenModal(SupplyConstant.DRAFT_TYPE_RELOCATION_POST)}
        >
          Копировать в "Внутреннее перемещение. Черновик"
        </Button>
      )}
      {props.tabType === 'archive' &&
        (billType === SupplyConstant.RETURNED_TYPE ||
          billType === SupplyConstant.FACTORY_TYPE_GET) && (
          <Button
            variant='contained'
            disabled={!workerAccess}
            onClick={() => handleOpenModal(SupplyConstant.DRAFT_TYPE_ISSUE)}
          >
            Копировать в "Выдача. Черновик"
          </Button>
        )}

      {props.tabType === 'archive' && (
        <Button
          variant='contained'
          onClick={props.onBackClick}
        >
          Назад
        </Button>
      )}

      <ConfirmationDialog
        title={`Внимание!`}
        handleOk={() => handleSave(saveAlert.action)}
        handleCancel={handleCloseSaveAlert}
        open={saveAlert.open}
        textForOk='Да'
        textForCancel='Нет'
      >
        {!!saveAlert.stamps?.length && (
          <>
            <Typography variant='subtitle1'>Пломбы:</Typography>
            {saveAlert.stamps.map((stamp, index) => (
              <Typography
                key={index}
                variant='subtitle2'
              >
                {stamp.from} - {stamp.to}
                {stamp.count && <> | {stamp.count} шт.</>}
              </Typography>
            ))}
          </>
        )}

        {!!saveAlert.pus?.length && (
          <>
            <Typography
              variant='subtitle1'
              sx={{ mt: 2 }}
            >
              Приборы учета:
            </Typography>
            {saveAlert.pus.map((pu, index) => (
              <Typography
                key={index}
                variant='subtitle2'
              >
                {supplyMeterTypes.get(pu.meterTypeId ?? -1)?.name} - {pu.puItems.length} шт.
              </Typography>
            ))}
          </>
        )}

        {!!saveAlert.os?.length && (
          <>
            <Typography
              variant='subtitle1'
              sx={{ mt: 2 }}
            >
              Сетевое оборудование:
            </Typography>
            {saveAlert.os.map((os, index) => (
              <Typography
                key={index}
                variant='subtitle2'
              >
                {supplyOsTypes.get(os.typeId ?? -1)?.name} - {os.networkElementItems.length} шт.
              </Typography>
            ))}
          </>
        )}

        {!!saveAlert.files?.length && (
          <>
            <Typography
              variant='subtitle1'
              sx={{ mt: 2 }}
            >
              Файлы:
            </Typography>
            {saveAlert.files.map((file, index) => (
              <Typography
                key={index}
                variant='subtitle2'
              >
                {file.name}
              </Typography>
            ))}
          </>
        )}

        <Typography
          variant='subtitle1'
          sx={{ mt: 2 }}
        >
          Вы подтверждаете {getAcceptActionText(saveAlert.action)}?
        </Typography>
      </ConfirmationDialog>
      <CommonModal
        open={openModal.open}
        onClose={handleCloseModal}
      >
        <SupplyButtonPanelModalContent>
          <Autocomplete
            options={activeOptions ?? []}
            value={selectedResponsable}
            onChange={(event: any, newValue: any) => handleResponsableSelect(newValue)}
            renderInput={(params) => (
              <TextField
                name='selectedResponsable'
                {...params}
                label={'Кому'}
                variant='standard'
              />
            )}
          />
          <Button
            variant='contained'
            onClick={() => handleSaveCopyDraft(openModal.action)}
            style={{
              alignSelf: 'center',
              marginTop: 10,
            }}
          >
            Сохранить
          </Button>
        </SupplyButtonPanelModalContent>
      </CommonModal>
    </SupplyButtonPanelWrapper>
  );
};
