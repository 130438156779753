import React, { FC, useMemo, useState } from 'react';
import { BoxShadow } from '@/components/ui/BoxShadow';
import { color } from '@/styles/mixins';
import { Button, IconButton, Stack, Typography } from '@mui/material';
import ResRow from '@/components/maps/ppoMap/resCard/resRow';
import Point from '@/components/Point';
import { MoreInfo } from '@/components/maps/ppoMap/resCard/index.styled';
import VisibilityOutlinedIcon from '@mui/icons-material/VisibilityOutlined';
import VisibilityOffOutlinedIcon from '@mui/icons-material/VisibilityOffOutlined';
import { GeoTask } from '@/dto/taskmap/Dto';
import { DeliveryPointGeo, ResultType, Subscriber } from '@/components/maps/ppoMap/dto';
import { usePpoMap } from '@/components/maps/ppoMap/store';
import BorderColorRoundedIcon from '@mui/icons-material/BorderColorRounded';
import FileDownloadRoundedIcon from '@mui/icons-material/FileDownloadRounded';
import { useCatalog } from '@/hooks/CatalogHook';
import { findTaskById } from '@/services/TaskService/TaskService';
import { useActionLog } from '@/hooks/ActionLogHook';
import { useRouterStore } from './routerStore';
import { useScreenHoldHook } from '@/hooks/ScreenHoldHook';
import { useUSPDStore } from './uspdStore';
import CloseIcon from '@mui/icons-material/Close';

interface IResCard extends GeoTask {
  deliveryPoints?: DeliveryPointGeo[];
  t: GeoTask;
}

const ResCard: FC<IResCard> = ({
  electricNetworksEnterpriseName,
  resName,
  tpName,
  deliveryPoints,
  t,
}) => {
  const [showInfo, setShowInfo] = useState<boolean>(false);
  const [showInfoRouters, setShowInfoRouters] = useState<boolean>(false);
  const [showInfoUSPD, setShowInfoUSPD] = useState<boolean>(false);
  const { setIsInProgress } = useScreenHoldHook();

  const toggleShow = () => setShowInfo(!showInfo);
  const toggleShowRouters = () => setShowInfoRouters(!showInfoRouters);
  const toggleShowUSPD = () => setShowInfoUSPD(!showInfoUSPD);
  const getCatalogMapWithErr = useCatalog((state) => state.getCatalogMapWithErr);
  const { fetchCatch } = useActionLog();

  const { setActive, tmpTaskId, geoTransStations, clearSelections, setTmpTaskId } = usePpoMap();
  const { changeRouterMode, setShowRouters, setTempDataRouter } = useRouterStore();
  const { changeUSPDMode, setShowUSPDs, setTempDataUSPD } = useUSPDStore();

  const noTaskCount = useMemo(
    () => deliveryPoints?.filter((elem) => elem.result === ResultType.NO_TASK).length ?? 0,
    [deliveryPoints]
  );
  const smrDoneWithActCount = useMemo(
    () =>
      deliveryPoints?.filter((elem) => elem.result === ResultType.SMR_DONE_WITH_ACT).length ?? 0,
    [deliveryPoints]
  );
  const smrDoneWithoutActCount = useMemo(
    () =>
      deliveryPoints?.filter((elem) => elem.result === ResultType.SMR_DONE_WITHOUT_ACT).length ?? 0,
    [deliveryPoints]
  );
  const smrCompleteCount = useMemo(
    () => smrDoneWithActCount + smrDoneWithoutActCount,
    [deliveryPoints]
  );
  const smrNotCompleteCount = useMemo(
    () => deliveryPoints?.filter((elem) => elem.result === ResultType.SMR_NOT_COMPLETE).length ?? 0,
    [deliveryPoints]
  );
  const tkoCount = useMemo(
    () => deliveryPoints?.filter((elem) => elem.result === ResultType.TKO).length ?? 0,
    [deliveryPoints]
  );
  const smrCount = useMemo(
    () => smrNotCompleteCount + smrCompleteCount + tkoCount,
    [deliveryPoints]
  );

  const allCount = useMemo(() => deliveryPoints?.length ?? 0, [deliveryPoints]);

  const routersCount = useMemo(
    () =>
      Object.values(geoTransStations || {}).length
        ? geoTransStations[t.taskId]?.routerList?.length
        : 0,
    [geoTransStations]
  );
  const uspdCount = useMemo(
    () =>
      Object.values(geoTransStations || {}).length
        ? geoTransStations[t.taskId]?.uspdList?.length
        : 0,
    [geoTransStations]
  );
  const fromDBCount = useMemo(
    () =>
      deliveryPoints?.filter((elem) => elem.subscriberSource === Subscriber.FROM_DB).length ?? 0,
    [deliveryPoints]
  );
  const createdByUserCount = useMemo(
    () =>
      deliveryPoints?.filter((elem) => elem.subscriberSource === Subscriber.CREATED_BY_USER)
        .length ?? 0,
    [deliveryPoints]
  );
  const noCount = useMemo(
    () => deliveryPoints?.filter((elem) => elem.subscriberSource === Subscriber.NO).length ?? 0,
    [deliveryPoints]
  );

  const getTempDataFromTask = (id: string, type: 'Router' | 'USPD') => {
    setIsInProgress(true);
    setTmpTaskId(t.taskId);
    findTaskById(id)
      .then(({ data }) => {
        type == 'Router' && setTempDataRouter(data);
        type == 'Router' && changeRouterMode(true);
        type == 'USPD' && setTempDataUSPD(data);
        type == 'USPD' && changeUSPDMode(true, t.taskId);
      })
      .catch((res) => {
        fetchCatch(res);
        type == 'Router' && setTempDataRouter(null);
        type == 'USPD' && setTempDataUSPD(null);
      })
      .finally(() => setIsInProgress(false));
  };

  return (
    <BoxShadow
      background={color('lightWhite')}
      sx={{ mb: 2 }}
    >
      <Stack>
        <Stack
          flexDirection={'row'}
          justifyContent={'space-between'}
          alignItems={'center'}
        >
          <Typography
            variant={'h5'}
            fontWeight={'500'}
          >
            {electricNetworksEnterpriseName} - {resName} - {tpName}
          </Typography>
          <IconButton onClick={() => clearSelections(t.taskId)}>
            <CloseIcon />
          </IconButton>
        </Stack>

        <ResRow
          title={'Точек Поставки'}
          count={allCount}
          onCountClick={() =>
            setActive({
              activeSub: [Subscriber.FROM_DB, Subscriber.CREATED_BY_USER, Subscriber.NO],
            })
          }
        >
          <Stack
            direction='row'
            flexWrap={'wrap'}
            spacing={2}
          >
            <Point
              count={fromDBCount}
              type={Subscriber.FROM_DB}
              onClick={() => {
                setTmpTaskId(t.taskId);
                setActive({ activeSub: [Subscriber.FROM_DB] });
              }}
            />
            <Point
              count={createdByUserCount}
              type={Subscriber.CREATED_BY_USER}
              onClick={() => {
                setTmpTaskId(t.taskId);
                setActive({ activeSub: [Subscriber.CREATED_BY_USER] });
              }}
            />
            <Point
              count={noCount}
              type={Subscriber.NO}
              onClick={() => {
                setTmpTaskId(t.taskId);
                setActive({ activeSub: [Subscriber.NO] });
              }}
            />
          </Stack>
        </ResRow>
        <ResRow
          title={'Точки без заявки'}
          count={noTaskCount}
          onCountClick={() => {
            setTmpTaskId(t.taskId);
            setActive({ activeRes: [ResultType.NO_TASK] });
          }}
        />
        <ResRow
          title={'Заявки СМР'}
          count={smrCount}
          onCountClick={() => {
            setTmpTaskId(t.taskId);
            setActive({
              activeRes: [
                ResultType.SMR_DONE_WITHOUT_ACT,
                ResultType.SMR_NOT_COMPLETE,
                ResultType.SMR_DONE_WITH_ACT,
                ResultType.TKO,
              ],
            });
          }}
        >
          {showInfo ? (
            <VisibilityOutlinedIcon
              sx={{ cursor: 'pointer' }}
              onClick={toggleShow}
            />
          ) : (
            <VisibilityOffOutlinedIcon
              sx={{ cursor: 'pointer' }}
              onClick={toggleShow}
            />
          )}
        </ResRow>

        {showInfo && (
          <MoreInfo>
            <ResRow
              title={'СМР проведено'}
              count={smrCompleteCount}
              onCountClick={() => {
                setTmpTaskId(t.taskId);
                setActive({
                  activeRes: [ResultType.SMR_DONE_WITHOUT_ACT, ResultType.SMR_DONE_WITH_ACT],
                });
              }}
            />
            <ResRow
              title={'АДПУ+'}
              count={smrDoneWithActCount}
              onCountClick={() => {
                setTmpTaskId(t.taskId);
                setActive({ activeRes: [ResultType.SMR_DONE_WITH_ACT] });
              }}
            />
            <ResRow
              title={'АДПУ-'}
              count={smrDoneWithoutActCount}
              onCountClick={() => {
                setTmpTaskId(t.taskId);
                setActive({ activeRes: [ResultType.SMR_DONE_WITHOUT_ACT] });
              }}
            />
            <ResRow
              title={'СМР не проведено'}
              count={smrNotCompleteCount}
              onCountClick={() => {
                setTmpTaskId(t.taskId);
                setActive({ activeRes: [ResultType.SMR_NOT_COMPLETE] });
              }}
            />
            <ResRow
              title={'ТКО'}
              count={tkoCount}
              onCountClick={() => {
                setTmpTaskId(t.taskId);
                setActive({ activeRes: [ResultType.TKO] });
              }}
            />
          </MoreInfo>
        )}
        <ResRow
          title={'Монтаж УСПД'}
          count={uspdCount ?? 0}
          onCountClick={setShowUSPDs}
        >
          <>
            {showInfoUSPD ? (
              <VisibilityOutlinedIcon
                sx={{ cursor: 'pointer' }}
                onClick={toggleShowUSPD}
              />
            ) : (
              <VisibilityOffOutlinedIcon
                sx={{ cursor: 'pointer' }}
                onClick={toggleShowUSPD}
              />
            )}
            <Button
              onClick={() => {
                setTmpTaskId(t.taskId);
                changeUSPDMode(true);
              }}
            >
              {'Создать заявку'}
            </Button>
          </>
        </ResRow>

        {showInfoUSPD && !!uspdCount && (
          <MoreInfo>
            {geoTransStations[t.taskId]?.uspdList?.length &&
              geoTransStations[t.taskId]?.uspdList?.map((uspd, index) => {
                return (
                  <Stack
                    flexDirection={'row'}
                    gap={5}
                    justifyContent={'space-between'}
                    key={index}
                  >
                    <Button onClick={() => window.open(`/task/edit/${uspd.taskId}`)}>
                      {uspd.taskNumber}
                    </Button>

                    <Typography>
                      {getCatalogMapWithErr('taskStatus').get(uspd.taskStatus)}
                    </Typography>
                    <Button
                      onClick={() => getTempDataFromTask(uspd.taskId, 'USPD')}
                      startIcon={<BorderColorRoundedIcon />}
                    >
                      координаты
                    </Button>
                  </Stack>
                );
              })}
          </MoreInfo>
        )}

        <ResRow
          title={'Монтаж роутеров'}
          count={routersCount ?? 0}
          onCountClick={setShowRouters}
        >
          <>
            {showInfoRouters ? (
              <VisibilityOutlinedIcon
                sx={{ cursor: 'pointer' }}
                onClick={toggleShowRouters}
              />
            ) : (
              <VisibilityOffOutlinedIcon
                sx={{ cursor: 'pointer' }}
                onClick={toggleShowRouters}
              />
            )}
            <Button
              onClick={() => {
                changeRouterMode(true);
                setTmpTaskId(t.taskId);
              }}
            >
              {'Создать заявку'}
            </Button>
          </>
        </ResRow>
        {showInfoRouters && !!routersCount && (
          <MoreInfo>
            {geoTransStations[t.taskId]?.routerList?.length &&
              geoTransStations[t.taskId]?.routerList?.map((router, index) => {
                return (
                  <Stack
                    flexDirection={'row'}
                    gap={5}
                    justifyContent={'space-between'}
                    key={index}
                  >
                    <Button onClick={() => window.open(`/task/edit/${router.taskId}`)}>
                      {router.taskNumber}
                    </Button>

                    <Typography>
                      {getCatalogMapWithErr('taskStatus').get(router.taskStatus)}
                    </Typography>
                    <Button
                      onClick={() => getTempDataFromTask(router.taskId, 'Router')}
                      startIcon={<BorderColorRoundedIcon />}
                    >
                      координаты
                    </Button>
                  </Stack>
                );
              })}
          </MoreInfo>
        )}
      </Stack>
      <Stack
        direction='row-reverse'
        spacing={2}
      >
        <Button
          startIcon={<FileDownloadRoundedIcon />}
          // onClick={() => tmpTaskId && getReportEnergyservice(tmpTaskId)} /// пока это в разработке
          disabled
        >
          Отчет энергосервис
        </Button>
        <Button
          startIcon={<BorderColorRoundedIcon />}
          onClick={() => window.open(`/task/edit/${tmpTaskId}`)}
        >
          ППО тп
        </Button>
      </Stack>
    </BoxShadow>
  );
};

export default ResCard;
