export type CatalogResponse<CatalogNameEnumValues> = {
  title: string;
  name: CatalogNameEnumValues;
}[];

export enum ComplaintStage {
  MODERATED = 'MODERATED', //Модерация
  CALL_CENTERED = 'CALL_CENTERED', //Колл-центр
  RETURNED = 'RETURNED', //Возврат жалобы
  MODERATED_AFTER_CC = 'MODERATED_AFTER_CC', //Модерация после КЦ
  IN_PROGRESS = 'IN_PROGRESS', //В работе
  DUPLICATED = 'DUPLICATED', //Дублирована
  TRANSFERRED_TO_RESPONSIBLE = 'TRANSFERRED_TO_RESPONSIBLE', //Передано ответственному
  MODERATED_AFTER_RESPONSIBLE = 'MODERATED_AFTER_RESPONSIBLE', //Модерация после ответственного
  COMPLETED = 'COMPLETED', //Завершена
  NOT_SEC = 'NOT_SEC', //Не относиться к СЭК
}

export enum ComplaintEnergyClassification {
  PU_NOT_WORK = 'PU_NOT_WORK', //ПУ не исправен
  ISSUE_DD = 'ISSUE_DD', //Выдать ДД
  INCORRECT_BINDING = 'INCORRECT_BINDING', //Некорректная привязка
  TRANSFER_CONSUMER_INTO_ACCOUNT = 'TRANSFER_CONSUMER_INTO_ACCOUNT', //Перевести потребителя в расчет
  MKD = 'MKD', //МКД
  CORRECTION_SWITCHING_CIRCUIT = 'CORRECTION_SWITCHING_CIRCUIT', //Исправление схемы включения
  COORECTION_SMR = 'COORECTION_SMR', //Исправление СМР
}

export interface SetNotSecStateRequestDto {
  response?: string;
  workType?: string | null;
}

export interface ComplaintGetReportRequestDto {
  startDate: string;
  endDate: string;
}
export interface ComplaintActStatusesResponse {
  OPEN: string;
  NO_CLOSURE: string;
  READY_TO_CLOSE: string;
  CLOSED_BY_KS: string;
}
export interface ComplaintActStatusReasonOrClassifiication {
  id: number;
  title: string;
}

export type ComplaintActStatusReasonsResponse = ComplaintActStatusReasonOrClassifiication[];
export type ComplaintActClassificationsResponse = ComplaintActStatusReasonOrClassifiication[];

export interface ComplaintActBase {
  id: number;
  createAt: string;
  updateAt: string;
  createBy: string;
  updateBy: string;
}

export interface BaseEntity {
  id: number;
  title: string;
}

export type ComplaintActClassification = BaseEntity;
export type ComplaintActStatusReason = BaseEntity;

export interface ComplaintActResponse {
  base: ComplaintActBase;
  ksDate: string;
  paymentRequestNumber: string;
  registerDate: string;
  classification: ComplaintActClassification;
  statusReason: ComplaintActStatusReason;
  status: string;
}

export type ComplaintActResponseNullable = ComplaintActResponse | null;

export interface UpdateComplaintActRequest {
  status?: string | null;
  ksDate?: string | null;
  paymentRequestNumber?: string | null;
  registerDate?: string | null;
  classificationId?: number | null;
  statusReasonId?: number | null;
}
