export const getStatisticsChartOptions = (
  legendPosition: 'top' | 'bottom' | 'left' | 'right' = 'bottom',
  labelFontSize = 8,
  maintainAspectRatio = true
) => ({
  responsive: true,
  maintainAspectRatio: maintainAspectRatio,
  interaction: {
    mode: 'index' as const,
    intersect: false,
  },
  stacked: false,
  plugins: {
    title: {
      display: true,
    },
    legend: {
      display: true,
      position: legendPosition,
      usePointStyle: true,
      labels: {
        boxWidth: labelFontSize * 1.25,
        boxHeight: labelFontSize * 1.25,
        color: '#000000',
        font: {
          size: labelFontSize,
          family: 'Segoe UI',
          weight: 'bold',
        },
      },
    },
  },
  scales: {
    y: {
      type: 'linear' as const,
      display: true,
      position: 'left' as const,
    },
  },
});
