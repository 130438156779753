import { Skeleton } from '@mui/material';
import React from 'react';
import {
  HouseCommentsRowWrapper,
  HouseCommentsRowColumn,
  HouseCommentsSmallText,
  HouseCommentsIconButtonWrapper,
  HouseCommentsInfoRow,
} from './HouseComments.styled';

const HouseCommentsSkeleton = () => {
  return (
    <>
      <HouseCommentsRowWrapper>
        <HouseCommentsIconButtonWrapper>
          <Skeleton
            width={30}
            height={30}
            variant='circular'
          />
        </HouseCommentsIconButtonWrapper>

        <HouseCommentsRowColumn>
          <HouseCommentsInfoRow>
            <HouseCommentsSmallText>
              <Skeleton
                variant='text'
                width='100px'
                height={24}
              />
            </HouseCommentsSmallText>
            <HouseCommentsSmallText>
              <Skeleton
                variant='text'
                width='100px'
                height={24}
              />
            </HouseCommentsSmallText>
          </HouseCommentsInfoRow>
          <div>
            <Skeleton
              variant='text'
              width='100%'
              height={24}
            />
          </div>
          <div>
            <Skeleton
              variant='text'
              width='100%'
              height={24}
            />
          </div>
        </HouseCommentsRowColumn>
      </HouseCommentsRowWrapper>
    </>
  );
};

export default HouseCommentsSkeleton;
