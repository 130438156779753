import { Order } from '../constants';
import { findManagementCompanyById, getEmails } from '@/services/RegistryService/RegistryService';
import { createStore } from '@/utils/createStore';

export interface IRegistryMonitoringEditItem {
  id: string;
  managementCompany: string;
  // attached: boolean;
  file: File[];
  fileId: string;
}
export interface Email {
  id: number | string;
  email: string;
}

interface IRegistryStore {
  registryResultTable: IRegistryMonitoringEditItem[];
  emails: Email[];
  order: Order;
  orderBy: keyof IRegistryMonitoringEditItem;
}

interface IRegistryStoreFunc {
  setRegistryResultTable: (registryResultTable: IRegistryMonitoringEditItem[]) => void;
  setOrder: (order: Order) => void;
  setOrderBy: (orderBy: keyof IRegistryMonitoringEditItem) => void;
  addFileById: (file: File, id: string) => void;
  updateTable: (id: string) => void;
  getEmails: () => void;
}

export const useRegistryStore = createStore<IRegistryStore & IRegistryStoreFunc>(
  (set, get) => ({
    registryResultTable: [],
    order: 'asc',
    orderBy: 'managementCompany',
    emails: [],
    // selected: [],

    addFileById: (file, id) =>
      set(() => ({
        registryResultTable: get().registryResultTable.map((elem) => ({
          ...elem,
          file: elem.id === id ? [...elem.file, file] : elem.file,
        })),
      })),

    setRegistryResultTable: (registryResultTable) => set(() => ({ registryResultTable })),
    setOrder: (order) => set(() => ({ order })),
    setOrderBy: (orderBy) => set(() => ({ orderBy })),

    updateTable: async (id) => {
      const result = await findManagementCompanyById(id);
      set(() => ({
        registryResultTable: [
          ...result.data.map((elem) => ({
            id: elem.id,
            // status: 'elem.status',
            // attached: elem.attached,
            fileId: elem.fileId,
            managementCompany: elem.managementCompanyTitle,
            // file: elem?.file ? elem.file : [],
            file: [],
          })),
        ],
      }));
    },

    getEmails: async () => {
      const result = await getEmails();
      set(() => ({
        emails: result.data,
      }));
    },
  }),
  'Registry'
);
