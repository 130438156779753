import { Box, Button, Grid, Paper, Stack, Typography } from '@mui/material';
import React, { useMemo, useState } from 'react';
import {
  BarElement,
  BubbleDataPoint,
  CategoryScale,
  Chart as ChartJS,
  ChartData,
  ChartOptions,
  Legend,
  LinearScale,
  LineElement,
  PointElement,
  ScatterDataPoint,
  Title,
  Tooltip as TooltipT,
} from 'chart.js';
import LoadSpinner from '@/components/ui/LoadSpinner/LoadSpinner';
import { Bar, Line } from 'react-chartjs-2';
import './MeterChangeForRevisionStyle.css';
import { FormProvider, useForm } from 'react-hook-form';
import { TaskFilterParam } from '@/dto/taskmap/Dto';
import { DateRange } from '@/components/inputs/DateRange';
import { Tooltip } from '@mui/material';
import FilterButtons from '@/components/filter/FilterButtons/FilterButtons';
import { generateDetailedInfo } from '@/components/button/DetailedInfo/utils';
import { getDataFromFilter } from '@/components/inputs/MultiSelectNew/utils';
import { MultiSelect } from '@/components/inputs/MultiSelectNew';
import { SelectFilterOptType } from '@/components/inputs/MultiSelectNew/types';
import { useCatalog } from '@/hooks/CatalogHook';
import { taskMapToArray } from '@/components/features/tasksBrowse/utils';
import { getMeterChangeForRevisionOptions } from './utils';
import {
  MeterChangeForRevisionButton,
  MeterChangeForRevisionFiltersWrapper,
} from './MeterChangeForRevision.styled';
import { useStatisticTeamManagerVoormanAndExecutorAcces } from '../../hooks/useStatisticTeamManagerVoormanAndExecutorAcces';
import { MeterChangeForRevisionData } from '@/services/StatisticsService/RequestStatisticService/dto/RequestStatisticServiceResponseDto';
import { ApiFindCurrentGroup } from '@/services/YodaRestService/YodaRestService';
import { MainText } from '../../StatisticMain.styled';
import { useMobileSize } from '@/hooks/useMediaQuery/useMobileSize';

interface MeterChangeForRevisionProps {
  data: MeterChangeForRevisionData;
  header: string;
  load: boolean;

  updateFunction: (
    standardFilter: TaskFilterParam,
    dateFrom: string,
    dateTo: string,
    datparentContractorName: string
  ) => void;
  setParentContractorName: (parentContractorName: string) => void;
  groupName: string;
}

ChartJS.register(
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  TooltipT,
  Legend,
  BarElement
);

const FILTERS_LABELS = {
  contracts: 'Контракт',
  responsables: 'Ответственный подрядчик',
};

const MeterChangeForRevision: React.FC<MeterChangeForRevisionProps> = ({
  data,
  header,
  load,
  updateFunction,
  setParentContractorName,
  groupName,
}) => {
  let filterParam: TaskFilterParam = {};
  const [previousGroupName, setPreviousGroupName] = React.useState('ПОДРЯДЧИКИ');
  const [currentGrupName, setCurrentGrupName] = React.useState('');

  let selectedGroupName: string = groupName;
  const setSelectedGroupName = (groupName: string) => {
    selectedGroupName = groupName;
    setParentContractorName(selectedGroupName);
  };

  const barOptionsArray: ChartOptions<'bar'>[] =
    data.dataBar === null
      ? new Array<ChartOptions<'bar'>>()
      : Object.assign(
          [],
          data.dataBar.map((item) => ({
            responsive: true,
            maintainAspectRatio: false,
            width: 150,
            height: 1000,
            plugins: {
              title: {
                display: false,
              },
              legend: {
                display: false,
              },
              tooltip: {
                enabled: false,
              },
            },
            scales: {
              x: {
                display: false,
                stacked: true,
              },
              y: {
                stacked: true,
                display: false,
              },
            },
            onClick: (event: any, elements: any) => {
              if (disableButton) return;
              if (elements.length != 0) {
                setSelectedGroupName('' + item.datasets[elements[0].datasetIndex].label);
                updateFunction(
                  filterParam,
                  new Date(dataInRange[0])?.toISOString()?.split('T')[0],
                  new Date(dataInRange[1])?.toISOString()?.split('T')[0],
                  selectedGroupName
                );
              }
            },
          }))
        );

  const [dataInRange, setDateInRange] = useState<number[] | Date[]>([
    new Date(Date.now() - 86400000 * 14),
    new Date(),
  ]);
  const itemWidth: string = (63 / (15 * data.dataBar?.length - 1)) * 10 + 'vw';

  const itemMarginLeft: string = (63 / (15 * data.dataBar?.length - 1)) * 3 + 'vw';
  const methods = useForm();
  const { handleSubmit } = methods;

  const { taskGroupMap, getGroupCatalog } = useCatalog((state) => ({
    getGroupCatalog: state.getGroupCatalog,
    taskGroupMap: state.taskGroupMap,
  }));
  const responsables = useMemo(() => taskMapToArray(getGroupCatalog()), [taskGroupMap]);
  const [selectedResponsables, setSelectedResponsables] = React.useState<SelectFilterOptType[]>([]);
  const [responsablesArg, setResponsablesArg] = React.useState(0);

  const { getContractCatalog } = useCatalog((state) => ({
    getContractCatalog: state.getContractCatalog,
  }));
  const contractListResponse = getContractCatalog();
  const [selectedContractList, setSelectedContractList] = React.useState<SelectFilterOptType[]>([]);
  const [contractListArg, setContractListArg] = React.useState(0);

  const onSubmitSearch = (data: any) => {
    filterParam = data;
    if (selectedContractList.length) {
      filterParam.contractIds = getDataFromFilter(selectedContractList, 'value');
      filterParam.contractIdsArg = contractListArg;
    }
    if (selectedResponsables.length) {
      filterParam.contractorIds = getDataFromFilter(selectedResponsables, 'value');
      filterParam.contractorIdsArg = responsablesArg;
    }
    updateFunction(
      filterParam,
      new Date(dataInRange[0])?.toISOString()?.split('T')[0],
      new Date(dataInRange[1])?.toISOString()?.split('T')[0],
      currentGrupName || selectedGroupName
    );
  };

  const onResetClick = () => {
    setSelectedContractList([]);
    setSelectedResponsables([]);
    setParentContractorName('ПОДРЯДЧИКИ');
  };

  React.useEffect(() => {
    ApiFindCurrentGroup().then((res) => {
      if (res.data.title === null) setPreviousGroupName('ПОДРЯДЧИКИ');
      else {
        setPreviousGroupName(res.data.title + '');
        setParentContractorName(selectedGroupName);
        setCurrentGrupName(res.data.title);
      }
    });
  }, [data, selectedGroupName, previousGroupName]);

  const getMaxDataSetSize = () => {
    if (data.dataBar != null) {
      let maxSize = 0;
      data.dataBar.map((item) => {
        if (maxSize < item.datasets.length) maxSize = item.datasets.length;
      });
      return maxSize;
    }
    return 0;
  };
  getMaxDataSetSize();
  const getMaxSizeDataBar = () => {
    if (data.dataBar != null) {
      let maxSize = 0;
      let maxSizeDataBar: ChartData<
        'bar',
        (number | ScatterDataPoint | BubbleDataPoint | null)[],
        unknown
      > = data.dataBar[0];
      data.dataBar.map((item) => {
        if (maxSize < item.datasets.length) {
          maxSize = item.datasets.length;
          maxSizeDataBar = item;
        }
      });
      return maxSizeDataBar;
    }
    return null;
  };
  const isInRange = dataInRange.length !== 2;
  const disableButton = dataInRange.length < 2;

  const detailedInfo = generateDetailedInfo(
    [selectedContractList, 'Контракт'],
    [selectedResponsables, 'Ответственный подрядчик']
  );
  const isTeamManagerVoormanAndExecutor = useStatisticTeamManagerVoormanAndExecutorAcces();

  const disableButtonSearch = () => {
    if (isTeamManagerVoormanAndExecutor && !selectedResponsables.length) {
      return true;
    }
    return false;
  };

  const isMobile = useMobileSize();

  return (
    <Paper elevation={6}>
      <MainText className='MainTextStatistc'>{header}</MainText>
      <FormProvider {...methods}>
        <form onSubmit={handleSubmit(onSubmitSearch)}>
          <MeterChangeForRevisionFiltersWrapper isMobile={isMobile}>
            <MultiSelect
              label={FILTERS_LABELS.contracts}
              options={contractListResponse ?? []}
              value={selectedContractList}
              argValue={contractListArg}
              onChange={(value: SelectFilterOptType[]) => setSelectedContractList(value)}
              onArgChange={(arg: number) => setContractListArg(arg)}
            />

            <MultiSelect
              label={FILTERS_LABELS.responsables}
              options={responsables}
              value={selectedResponsables}
              argValue={responsablesArg}
              onChange={(value: SelectFilterOptType[]) => setSelectedResponsables(value)}
              onArgChange={(arg: number) => setResponsablesArg(arg)}
            />

            <Tooltip title={'Нажмите обновить для отображения изменений'}>
              <MeterChangeForRevisionButton
                type={'reset'}
                onClick={() => setSelectedGroupName('ПОДРЯДЧИКИ')}
              >
                Сбросить фильтр групп
              </MeterChangeForRevisionButton>
            </Tooltip>
            <DateRange
              label={'выбрать диапазон'}
              isInRange={isInRange}
              onChange={setDateInRange}
              value={dataInRange}
              argValue={8} // set interval by default
              showSelect={false} // hide select option
            />
            <FilterButtons
              info={detailedInfo}
              onSearchClick={() => handleSubmit(onSubmitSearch)}
              onCloseClick={onResetClick}
              disable={disableButtonSearch() || load}
            />
          </MeterChangeForRevisionFiltersWrapper>
        </form>
      </FormProvider>

      {load ? (
        <LoadSpinner />
      ) : (
        <>
          <Grid
            container
            spacing={2}
            mt={2}
          >
            <Grid
              item
              xs={12}
              md={3}
            >
              <p style={{ margin: '1.5vh 0 0', textAlign: 'center', padding: '5px 0' }}>
                Текущая група: {currentGrupName}
              </p>
              <button
                onClick={(event) => {
                  setSelectedGroupName(previousGroupName);
                  updateFunction(
                    filterParam,
                    new Date(dataInRange[0]).toISOString().split('T')[0],
                    new Date(dataInRange[1]).toISOString().split('T')[0],
                    previousGroupName
                  );
                }}
                className={'getGroupBtn'}
              >
                Вернуться к предыдущей группе
              </button>
              <Stack
                spacing={1}
                maxHeight={450}
                overflow={'auto'}
              >
                {getMaxSizeDataBar()?.datasets.map((item) => (
                  <Button
                    key={item.label}
                    fullWidth
                    onClick={(event) => {
                      setSelectedGroupName('' + item.label);
                      updateFunction(
                        filterParam,
                        new Date(dataInRange[0]).toISOString().split('T')[0],
                        new Date(dataInRange[1]).toISOString().split('T')[0],
                        selectedGroupName
                      );
                    }}
                    size='small'
                    startIcon={
                      <Box
                        sx={{
                          width: 16,
                          height: 16,
                          bgcolor: item.backgroundColor as string,
                          borderRadius: '2px',
                        }}
                      />
                    }
                  >
                    <Typography
                      variant='body2'
                      noWrap
                      textAlign='left'
                      sx={{
                        flex: 1,
                      }}
                    >
                      {`${item.data[0]}: ${item.label}`}
                    </Typography>
                  </Button>
                ))}
              </Stack>
            </Grid>

            <Grid
              item
              xs={12}
              md={9}
            >
              <Box sx={{ height: isMobile ? 300 : 400 }}>
                <Line
                  options={{
                    ...getMeterChangeForRevisionOptions(isMobile ? 'bottom' : 'right'),
                    responsive: true,
                    maintainAspectRatio: false,
                  }}
                  data={data.all}
                />
              </Box>
            </Grid>
          </Grid>
          <ul style={{ padding: 0, marginLeft: '17.4vw' }}>
            {data.dataBar != null ? (
              data.dataBar.map((item, number) => (
                <li
                  className='two'
                  style={{ marginLeft: itemMarginLeft }}
                  key={Math.random()}
                >
                  <div
                    className='content'
                    style={{ width: itemWidth }}
                  >
                    <Tooltip
                      id='registerTip'
                      placement='top'
                      arrow={true}
                      key={Math.random()}
                      title={item.datasets.map((label, count) => (
                        <div key={Math.random()}>
                          {count < 24 ? (
                            <div key={Math.random()}>
                              <div style={{ padding: '1px' }}>
                                <div
                                  className={'barLegendColorRectangle'}
                                  style={{ backgroundColor: '' + label.backgroundColor }}
                                ></div>
                                <div style={{ display: 'table-cell' }}>
                                  <p
                                    style={{
                                      margin: ' 0px 6px 0px 6px',
                                      padding: 0,
                                    }}
                                  >
                                    {label.data[0] + ': ' + label.label}
                                  </p>
                                </div>
                              </div>
                            </div>
                          ) : (
                            <div></div>
                          )}
                        </div>
                      ))}
                    >
                      <div>
                        <Bar
                          data={item}
                          options={barOptionsArray[number]}
                        />
                      </div>
                    </Tooltip>
                  </div>
                </li>
              ))
            ) : (
              <></>
            )}
          </ul>
        </>
      )}
    </Paper>
  );
};
export default React.memo(MeterChangeForRevision);
