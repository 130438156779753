import React, { useEffect, useState } from 'react';
import { Grid, Tab, Tabs } from '@mui/material';

import { SelectFilterOptType } from '@/components/filter/MultySelectFilter';
import StampComponent, { SupplyTabType } from '../StampComponent/StampComponent';
import SupplyPUComponent from '../SupplyPUComponent/SupplyPUComponent';
import useSupplyStore, { initialOsList, initialPuList } from '../SupplyStore';
import { StorehouseBillType, SupplyConstant } from '../SupplyConstant';
import { ActionLogType, syncErrorCatch, useActionLog } from '@/hooks/ActionLogHook';
import SupplyOSComponent from '../SupplyOsComponent/SupplyOSComponent';
import moment from 'moment';
import shallow from 'zustand/shallow';

import { SupplyButtonPanel } from '../SupplyButtonPanel/SupplyButtonPanel';
import {
  getSavePuListId,
  useDisabledStorehouseField,
  useStorehouseLS,
} from '../SupplyPUComponent/utils';
import {
  SupplyBillApiCheckSupplyBillExists,
  SupplyBillApiGetReturnBillById,
  SupplyBillApiGetSupplyBillById,
  SupplyBillApiSaveNewReturnBill,
  SupplyBillApiSaveNewSupplyBill,
  SupplyBillApiUpdateReturnBill,
  SupplyBillApiUpdateSupplyBill,
} from '@/services/SupplyBillService/SupplyBillService';
import { useScreenHoldHook } from '@/hooks/ScreenHoldHook';
import { getSuccessText } from '../utils';
import {
  useAllResponsables,
  useAllAcceptedTransitBills,
  useUserSupplyGroup,
  useAllFactories,
  useAllStorehouses,
  useAllProjects,
} from '@/hooks/useQuery/useAllSupplyCatalogs';
import { FormProvider, useForm } from 'react-hook-form';
import {
  BillSaveDataOptions,
  BillSaveOptions,
  checkAndPrepareData,
  DEFAULT_SUPPLY_ID,
  validateBillDate,
} from './utils';
import { FileInputFiles } from '@/components/inputs/FileInput/utils';
import { SupplyBillFilterParam } from '@/dto/SupplyBillServiceDto';
import StoreHouseTabPanel from '../StoreHouseComponent/StoreHouseTabPanel';
import { useStorehouseChiefAccess } from '../SupplyPUComponent/utils';
import IssuedStampHeader from './IssuedStampHeader';
import { useAllowedAccessByRole } from '@/hooks/useAccess';
import { RolesEnum } from '@/app/auth/roles';
import { useNavigate, useParams, useSearchParams } from 'react-router-dom';

export const IssuedStamp = (props: SupplyTabType) => {
  const { tabType } = props;
  const { billId } = useParams();
  const [searchParams, setSearchParams] = useSearchParams();
  const storehouseChiefAccess = useStorehouseChiefAccess();
  const disableByRole = useDisabledStorehouseField();
  const navigate = useNavigate();

  const {
    setBillNumber,
    setContractorId,
    setSupplyId,
    setBillDate,
    resetStampList,
    supplyBillDto,
    setSupplyBillDto,
    setWholeCount,
    setDeclaredCount,
    setPUList,
    setOSList,
    resetState,
    setM15FormNumber,
    billType,
    setBillType,
    setProjectId,
  } = useSupplyStore(
    (state) => ({
      setBillNumber: state.setBillNumber,
      setContractorId: state.setContractorId,
      setSupplyId: state.setSupplyId,
      setBillDate: state.setBillDate,
      resetStampList: state.resetStampList,
      supplyBillDto: state.supplyBillDto,
      setSupplyBillDto: state.setSupplyBillDto,
      setWholeCount: state.setWholeCount,
      setDeclaredCount: state.setDeclaredCount,
      setPUList: state.setPUList,
      setOSList: state.setOSList,
      resetState: state.resetState,
      setM15FormNumber: state.setM15FormNumber,
      billType: state.billType,
      setBillType: state.setBillType,
      setProjectId: state.setProjectId,
    }),
    shallow
  );

  const returnBill =
    searchParams.get('returnBill') === 'true' ||
    billType === SupplyConstant.RETURNED_TYPE ||
    billType === SupplyConstant.DRAFT_TYPE_RETURN ||
    billType === SupplyConstant.RETURNED_TYPE_WAITING_APPROVAL_PROJECT ||
    billType === SupplyConstant.RETURNED_TYPE_AGREED_PROJECT;

  const relocationGetBill = billType === SupplyConstant.RELOCATION_TYPE_GET;
  const relocationPostBill =
    billType === SupplyConstant.RELOCATION_TYPE_POST ||
    billType === SupplyConstant.DRAFT_TYPE_RELOCATION_POST;
  const relocationAcceptedBill = billType === SupplyConstant.RELOCATION_TYPE_ACCEPTED;
  const supplyGroupsBills = relocationPostBill || relocationAcceptedBill;

  const factoryPostBill =
    billType === SupplyConstant.FACTORY_TYPE_POST ||
    billType === SupplyConstant.DRAFT_TYPE_FACTORY_TYPE_POST;

  const methods = useForm();
  const { setValue: setFileValue, getValues } = methods;
  const [resetFileInputFlag, setResetFileInputFlag] = useState(false);
  const [componentRerenderKey, setComponentRerenderKey] = useState(1);

  const { setIsInProgress } = useScreenHoldHook();
  const { addActionLog, fetchCatch } = useActionLog();
  const { responsablesOptions } = useAllResponsables();
  const { userSupplyGroupOptions } = useUserSupplyGroup(tabType === 'factory_get');

  const { acceptedTransitBills } = useAllAcceptedTransitBills();
  const { factoriesOptions } = useAllFactories(factoryPostBill);
  const { storehousesOptions } = useAllStorehouses();

  const { deleteValue } = useStorehouseLS();

  // FIXME: при рефакторинге - проверить нельзя ли 1) поместить стейты в стор, обойтись 1 стейтом,  а не отдельным для опции и значении опции
  const [selectedResponsable, setSelectedResponsable] = useState<SelectFilterOptType | null>(null);
  const [selectedStorehouse, setSelectedStorehouse] = useState<SelectFilterOptType | null>(null);

  const validateCommon = (): boolean => {
    const allStore = useSupplyStore.getState();

    return (
      allStore.billNumber.length > 0 &&
      allStore.contractorId.length > 0 &&
      allStore.billDate.length > 0
    );
  };

  const clearPUScreen = () => {
    setPUList([...initialPuList]);
  };

  const clearOSScreen = () => {
    setOSList([...initialOsList]);
  };

  const clearStampScreen = () => {
    resetStampList();
    setWholeCount('');
    setDeclaredCount('');
  };

  const onSaveClick = (isDraftSave: boolean, saveBillTypeButton: StorehouseBillType | null) => {
    const allStore = useSupplyStore.getState();

    if (!validateCommon()) {
      addActionLog(ActionLogType.ERROR, `Необходимо заполнить обязательные поля`);
      return;
    }
    if (!validateBillDate(isDraftSave, allStore.billDate)) {
      return;
    }
    if (supplyBillDto && supplyBillDto.billNumber === allStore.billNumber) {
      checkAndSaveAllData({ isDraft: isDraftSave, saveBillType: saveBillTypeButton });
    } else {
      setIsInProgress(true);
      SupplyBillApiCheckSupplyBillExists(allStore.billNumber, moment().format('yyyy-MM-DD'))
        .then(({ data }) => {
          if (data) {
            addActionLog(
              ActionLogType.ERROR,
              `Накладная с таким номером уже существует, пожалуйста найдите ее в Ранее выданных`
            );
            setIsInProgress(false);
          } else {
            checkAndSaveAllData({ isDraft: isDraftSave, saveBillType: saveBillTypeButton });
          }
        })
        .catch((err) => {
          fetchCatch(err, 'Ошибка во время проверки накладной');
          setIsInProgress(false);
        });
    }
  };

  const onBackClick = () => {
    // FIXME: при рефакторинге - проверить нельзя ли использовать только resetState
    const savePuListId = getSavePuListId(tabType, supplyBillDto);
    deleteValue(savePuListId);
    clearStampScreen();
    clearPUScreen();
    clearOSScreen();
    resetState();
    setComponentRerenderKey((prev) => prev + 1);
    navigate(`/storehouse`);
  };

  const saveData = ({ data, formData, successMessage }: BillSaveDataOptions) => {
    const successText = getSuccessText(data.billTypeId, successMessage);
    const updateCall = returnBill ? SupplyBillApiUpdateReturnBill : SupplyBillApiUpdateSupplyBill;
    const saveNewBillCall = returnBill
      ? SupplyBillApiSaveNewReturnBill
      : SupplyBillApiSaveNewSupplyBill;
    const apiCall = data.id ? updateCall : saveNewBillCall;
    apiCall(formData)
      .then((data) => {
        if (data) {
          const savePuListId = getSavePuListId(tabType, supplyBillDto);
          deleteValue(savePuListId);

          onBackClick();
          setSelectedResponsable(null);
          setResetFileInputFlag(!resetFileInputFlag);
          addActionLog(ActionLogType.SUCCESS, successText);
          setComponentRerenderKey((prev) => prev + 1);
        } else {
          syncErrorCatch('Ошибка при сохранении накладной');
        }
      })

      .catch((err) => fetchCatch(err, 'Ошибка во время сохранения'))
      .finally(() => setIsInProgress(false));
  };

  const checkAndSaveAllData = ({ saveBillType, isDraft, successMessage }: BillSaveOptions) => {
    setIsInProgress(true);

    const files: FileInputFiles = getValues('files');
    const { data, formData, isValid } = checkAndPrepareData({
      saveBillType,
      isDraft,
      billType,
      supplyBillDto,
      tabType,
      files,
    });

    if (isValid) {
      saveData({ data, formData, successMessage });
    } else {
      setIsInProgress(false);
      addActionLog(ActionLogType.ERROR, 'Необходимо исправить некорректные данные');
    }
  };

  React.useEffect(() => {
    if (billId) {
      setIsInProgress(true);
      const getSupplyBillCall = returnBill
        ? SupplyBillApiGetReturnBillById
        : SupplyBillApiGetSupplyBillById;

      getSupplyBillCall(billId)
        .then(({ data }) => {
          setSupplyBillDto(data);
          setBillType(data.billTypeId);
        })
        .catch((err) => fetchCatch(err))
        .finally(() => setIsInProgress(false));
    }
  }, []);

  React.useEffect(() => {
    if (supplyBillDto && tabType === 'archive') {
      setBillNumber(supplyBillDto.billNumber);
      setBillDate(supplyBillDto.billDate);

      const storehouseName = storehousesOptions?.find((it) => it.value === supplyBillDto.supplyId);
      setSupplyId(supplyBillDto.supplyId || '');
      setSelectedStorehouse(storehouseName || null);

      setProjectId(supplyBillDto.projectId || '');

      if (supplyBillDto?.m15FormNumber) {
        setM15FormNumber(supplyBillDto.m15FormNumber);
      }

      if (supplyBillDto?.stampList?.length) {
        setSelectedComponent(0);
      }
      if (supplyBillDto?.puList?.length) {
        setSelectedComponent(1);
      }

      if (supplyBillDto?.networkElementList?.length) {
        setSelectedComponent(2);
      }

      const setContractor = (options?: SelectFilterOptType[]) => {
        const contractor = options?.find((it) => it.value === supplyBillDto.contractorId);
        if (contractor) {
          setContractorId(supplyBillDto.contractorId);
          setSelectedResponsable(contractor);
        } else {
          setContractorId('');
          setSelectedResponsable(null);
        }
      };

      if (responsablesOptions && responsablesOptions?.length > 0) {
        setContractor(responsablesOptions);
      }

      if (relocationGetBill && acceptedTransitBills && acceptedTransitBills?.length > 0) {
        setContractor(acceptedTransitBills);
      }

      if (factoryPostBill && factoriesOptions && factoriesOptions?.length > 0) {
        setContractor(factoriesOptions);
      }

      if (supplyBillDto?.fileList?.length) {
        setFileValue('files', supplyBillDto?.fileList);
      }
    } else {
      if (tabType === 'factory_get') {
        const userSupplyOption = userSupplyGroupOptions[0];
        if (userSupplyOption) {
          setSelectedResponsable(userSupplyOption);
          setContractorId(userSupplyOption?.value);
        }
        return;
      }
      if (tabType === 'return' && !disableByRole) {
        const storehouseName = storehousesOptions?.find((it) => it.value === DEFAULT_SUPPLY_ID);

        if (storehouseName) {
          setSupplyId(DEFAULT_SUPPLY_ID);
          setSelectedStorehouse(storehouseName);
        } else {
          setSupplyId('');
          setSelectedStorehouse(null);
        }
      }
      if (relocationGetBill) return;
      setBillNumber('');
      setBillDate(moment().format('yyyy-MM-DD'));
      setContractorId('');
      setSelectedResponsable(null);
    }
  }, [
    supplyBillDto,
    responsablesOptions,
    acceptedTransitBills,
    userSupplyGroupOptions,
    factoriesOptions,
    storehousesOptions,
    disableByRole,
  ]);

  const [selectedComponent, setSelectedComponent] = React.useState(0);
  const handleChange = (_event: unknown, newValue: number) => setSelectedComponent(newValue);

  const relocationTypePostChief =
    supplyBillDto && billType === SupplyConstant.RELOCATION_TYPE_POST && storehouseChiefAccess;

  const allowAccess = useAllowedAccessByRole({
    allowRoles: [RolesEnum.StorehouseProject],
  });
  const accessProject =
    billType === SupplyConstant.RETURNED_TYPE_WAITING_APPROVAL_PROJECT && allowAccess;

  const allowEditReturtBill = useAllowedAccessByRole({
    allowRoles: [RolesEnum.Storehouse, RolesEnum.StorehouseChief],
  });

  const accessEditReturtBill =
    (billType === SupplyConstant.RETURNED_TYPE_WAITING_APPROVAL_PROJECT ||
      billType === SupplyConstant.RETURNED_TYPE_AGREED_PROJECT) &&
    allowEditReturtBill;

  const nonEditableBill =
    supplyBillDto &&
    billType !== SupplyConstant.DRAFT_TYPE_ISSUE &&
    billType !== SupplyConstant.DRAFT_TYPE_RETURN &&
    billType !== SupplyConstant.DRAFT_TYPE_RELOCATION_POST &&
    billType !== SupplyConstant.DRAFT_TYPE_FACTORY_TYPE_POST &&
    billType !== SupplyConstant.DRAFT_FACTORY_TYPE_GET &&
    tabType !== 'relocation_get' &&
    !relocationTypePostChief &&
    !accessProject &&
    !accessEditReturtBill;

  const saveFiles = () => {
    checkAndSaveAllData({ isDraft: false, saveBillType: SupplyConstant.ISSUED_TYPE });
  };

  const handleResponsableSelect = (
    _event: React.SyntheticEvent<Element, Event>,
    responsable: any
  ) => {
    setContractorId(responsable?.value);
    setSelectedResponsable(responsable);

    if (relocationGetBill) {
      const savePuListId = getSavePuListId(tabType);
      deleteValue(savePuListId);
      clearStampScreen();
      clearPUScreen();
      clearOSScreen();
      setComponentRerenderKey((prev) => prev + 1);

      if (!responsable?.value) return;

      setIsInProgress(true);
      const getSupplyBillCall = returnBill
        ? SupplyBillApiGetReturnBillById
        : SupplyBillApiGetSupplyBillById;
      getSupplyBillCall(responsable?.value)
        .then(({ data }) => {
          setSupplyBillDto(data);
          setContractorId(data?.contractorId);
        })
        .catch((err) => fetchCatch(err))
        .finally(() => setIsInProgress(false));
    }
  };

  const handleStorehouseSelect = (
    _event: React.SyntheticEvent<Element, Event>,
    storehouse: any
  ) => {
    setSupplyId(storehouse?.value);
    setSelectedStorehouse(storehouse);
  };

  return (
    <div>
      <FormProvider {...methods}>
        <Grid
          direction='column'
          container
          style={{ width: '100%', padding: '10px' }}
        >
          <IssuedStampHeader
            nonEditableBill={nonEditableBill}
            selectedResponsable={selectedResponsable}
            selectedStorehouse={selectedStorehouse}
            tabType={tabType}
            handleResponsableSelect={handleResponsableSelect}
            handleStorehouseSelect={handleStorehouseSelect}
            supplyGroupsBills={supplyGroupsBills}
            relocationGetBill={relocationGetBill}
            factoryPostBill={factoryPostBill}
            resetFileInputFlag={resetFileInputFlag}
            saveFiles={saveFiles}
            selectedComponent={selectedComponent}
            checkAndSaveAllData={checkAndSaveAllData}
          />
          <Tabs
            centered
            value={selectedComponent}
            onChange={handleChange}
          >
            <Tab label='Пломбы' />
            <Tab label='Приборы учета' />
            <Tab label='Сетевое оборудование' />
          </Tabs>

          <StoreHouseTabPanel
            index={0}
            value={selectedComponent}
          >
            <StampComponent
              supplyBillDto={supplyBillDto}
              tabType={tabType}
              key={componentRerenderKey}
              withoutLsSave={tabType === 'relocation_get'}
            />
          </StoreHouseTabPanel>

          <StoreHouseTabPanel
            index={1}
            value={selectedComponent}
          >
            <SupplyPUComponent
              supplyBillDto={supplyBillDto}
              tabType={tabType}
              key={componentRerenderKey}
              nonEditableBill={nonEditableBill}
              withoutLsSave={tabType === 'relocation_get'}
              accessProject={accessProject}
            />
          </StoreHouseTabPanel>

          <StoreHouseTabPanel
            index={2}
            value={selectedComponent}
          >
            <SupplyOSComponent
              supplyBillDto={supplyBillDto}
              tabType={tabType}
              key={componentRerenderKey}
              nonEditableBill={nonEditableBill}
              withoutLsSave={tabType === 'relocation_get'}
              accessProject={accessProject}
            />
          </StoreHouseTabPanel>

          <SupplyButtonPanel
            tabType={tabType}
            onBackClick={onBackClick}
            onSaveDraftClick={(billTypeButton) => onSaveClick(true, billTypeButton)}
            onSaveClick={(billTypeButton) => onSaveClick(false, billTypeButton)}
            accessProject={accessProject}
          />
        </Grid>
      </FormProvider>
    </div>
  );
};
