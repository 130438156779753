import { color } from '@/styles/mixins';
import { Box, IconButton, styled } from '@mui/material';

export const DetailedInfoButtonBox = styled(Box)({
  backgroundColor: '#FFF',
  border: '1px solid gray',
  borderRadius: '5px',
  position: 'relative',
  padding: 16,
  paddingRight: '30px',
});

export const DetailedInfoButtonPortalBox = styled(Box)({
  position: 'fixed',
  top: 0,
  left: 0,
  zIndex: 1000,
  backgroundColor: 'transparent',
  overflowY: 'auto',
  height: '100vh',
  width: '100vw',
});

export const DetailedInfoButtonIcon = styled(IconButton)({
  cursor: 'pointer',
  position: 'absolute',
  top: 0,
  right: 0,
  color: color('trueBlack'),
});
