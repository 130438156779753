import { HOST, YodaRestClient } from './YodaAuthService';
import { TaskTransformator, WarrantyWorkType } from '@/dto/taskmap/Dto';
import { ApiResponse } from '@/types/api';

export async function getWaranty(taskId: string): ApiResponse<WarrantyWorkType[]> {
  const url = HOST + `/rest/warranty-work/task/${taskId}`;
  return YodaRestClient.get(url);
}

export async function getAutomaticBreaker(
  contractId: string
): ApiResponse<{ id: string; automaticBreakerName: string }[]> {
  const url = HOST + `/rest/automatic-breaker/contract/${contractId}`;
  return YodaRestClient.get(url);
}

export async function updateWaranty(id: string, data: any): ApiResponse<WarrantyWorkType> {
  const url = HOST + `/rest/warranty-work/${id}`;
  return YodaRestClient.patch(url, data);
}
export async function canceledWaranty(id: string): ApiResponse<WarrantyWorkType> {
  const url = HOST + `/rest/warranty-work/${id}/canceled`;
  return YodaRestClient.post(url, {});
}
export async function upDateTaskTransformer(body: TaskTransformator): ApiResponse<any> {
  const url = HOST + `/rest/task-transformer`;
  return YodaRestClient.post(url, body);
}
