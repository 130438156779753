import { color } from '@/styles/mixins';
import { styled } from '@mui/material';

export const ImageItemDuplicateInfoWrapper = styled('div')({
  padding: 16,
  backgroundColor: color('trueWhite'),
  border: '1px solid red',
  borderRadius: 5,
  marginRight: 4,
  display: 'flex',
  flexDirection: 'column',
  gap: 4,
  maxHeight: '50vh',
  overflow: 'auto',
});

export const ImageItemDuplicateInfoRow = styled('div')({
  display: 'flex',
  alignItems: 'center',
  gap: 4,
});

export const ImageItemDuplicateTaskLink = styled('span')({
  fontWeight: 500,
  cursor: 'pointer',
  color: color('muiBlue'),
  display: 'inline-flex',
  fontSize: 14,
});

export const ImageItemDuplicateScrollContainer = styled('div')({
  fontWeight: 500,
  cursor: 'pointer',
  color: color('muiBlue'),
  display: 'inline-flex',
  fontSize: 14,
});
