import axios from 'axios';
import {
  CCContractDto,
  DadataResponseDto,
  GeoBounds,
  GeoRequest,
  GeoResponse,
  GuaranteeBodyDto,
  NetworkElementsTaskWithComment,
  StatisticUrgentAndSuperUrgentTasksDto,
  TaskAddressDetail,
  TaskCC,
  TaskCCTko,
  TkoStatusDto,
  TkoToSaveDto,
  HouseOrderDto,
  CreateHouseOrderDto,
  CreateHouseOrderResponseDto,
  PlannedInstalationsDto,
  SaveHousePlanningsDto,
  HouseLayoutDto,
  PnrAddressDetail,
  TwoGisLayoutResponseDto,
} from '@/dto/taskmap/Dto';
import {
  UserResponse,
  CallingMapOperatorCredentials,
  ApiFindAllContractResponse,
} from './dto/YodaRestServiceResponseDto';
import { UnAttachmentDto as UnAttachmentDtoYoda } from '@/dto/YodaRestDto';
import { NetworkResponse } from '@/components/sections/NetworkMap/UspdDto';
import { OdpuManagementCompanyResponse } from '@/dto/taskmap/task/TasksDto';
import { FilterStatisticDistribution } from '@/components/statistics/StatisticDistributionOfInstallationByConctror/utils';
import { saveRolesLocal } from '@/hooks/useRole';
import { RolesEnum } from '@/app/auth/roles';
import { HermesAnswer, HermesListItem, HermesLog } from '@/dto/HermesDto';
import { ApiResponse } from '@/types/api';
import { CONTENT_TYPE, HOST, yodaGetAccessToken, YodaRestClient } from '../YodaAuthService';
import { TaskByMeterResponseDTO } from '../SupplyBillService/dto/SupplyBillServiceResponse';
import { TaskByMeterRequestDTO } from '@/services/SupplyBillService/dto/SupplyBillServiceRequest';
import {
  AssignRequestDTO,
  CopyRequestDTO,
  GetRequestsDTO,
  AssignContractRequestDTO,
  AssignContractorRequestDTO,
} from './dto/YodaRestServiceRequestDto';
import { TaskHouseLinkResponseDto } from '@/components/features/taskEditor/HouseLink/HouseLink';

const TAG = 'YodaService';

export async function yodaRefreshRoles() {
  return await ApiFindUserRoles()
    .then(({ data }) => {
      saveRolesLocal(data);
      return Promise.resolve(data);
    })
    .catch((er) => {
      return Promise.reject(er);
    });
}

/**
 *
 * @param stop it's a cruthc. Optional attribute for preventing recursion
 * @param entityName
 * @param body
 * @returns  ApiResponse<any>
 */
export async function yodaPostEntities(
  entityName: string,
  body: any,
  stop = false
): ApiResponse<any> {
  const url = HOST + '/rest/v2/entities/' + entityName;
  return YodaRestClient.post(url, body);
}

export async function ApiFindAllContract(): ApiResponse<ApiFindAllContractResponse> {
  const url = HOST + '/rest/contract';
  return YodaRestClient.get(url);
}

export async function ApiFindUserRoles(): ApiResponse<RolesEnum[]> {
  const url = HOST + '/rest/user/role';
  return YodaRestClient.get(url);
}

export async function ApiFindAllGroup(): ApiResponse<any> {
  const url = HOST + '/rest/group';
  return YodaRestClient.get(url);
}

export async function ApiFindAllOperators(): ApiResponse<UserResponse[]> {
  const url = HOST + '/rest/user/operators';
  return YodaRestClient.get(url);
}

export async function ApiGetRequests(params: GetRequestsDTO) {
  const url =
    HOST +
    `/rest/task/find-tasks-byparam?offset=${params.page * params.pageSize}&limit=${
      params.pageSize
    }`;
  return YodaRestClient.post(url, params.filters || {});
}

export async function ApiGetRequestsCount(filters?: any) {
  const url = HOST + '/rest/task/get-task-count-byparam';
  return YodaRestClient.post(url, filters || {});
}

export async function ApiGetAllUUID(filters?: any) {
  const url = HOST + '/rest/task/find-ids';
  return YodaRestClient.post(url, filters || {});
}

export async function ApiAssignContractRequest(data: AssignContractRequestDTO) {
  const url = HOST + '/rest/task/assign-contract';
  return YodaRestClient.post(url, data);
}

export async function ApiAssignContractorRequest(data: AssignContractorRequestDTO) {
  const url = HOST + '/rest/task/assign-contractor';
  return YodaRestClient.post(url, data);
}

export async function ApiAssignRequest(data: AssignRequestDTO) {
  const url = HOST + '/rest/task/assign-executor-and-planneddate';
  return YodaRestClient.post(url, data);
}

export async function ApiCancelRequest(data: string[]) {
  const url = HOST + '/rest/task/cancel-tasks';
  return YodaRestClient.post(url, data);
}

export async function ApiCopyRequest(data: CopyRequestDTO) {
  const url = HOST + '/rest/task/copy-tasks';
  return YodaRestClient.post(url, data);
}

export async function ApiTrailScreen(coord: any): ApiResponse<any> {
  const params = new URLSearchParams(coord);
  const url = HOST + `/rest/geo?${params}`;
  return YodaRestClient.get(url);
}

export async function ApiFindAllTaskGeoInBoundByParam(
  geoRequest: GeoRequest
): ApiResponse<GeoResponse> {
  const url = HOST + `/rest/geo`;
  return YodaRestClient.post(url, geoRequest);
}

export async function ApiFindAllTaskGeoByTaskIdList(
  taskIdList: string[]
): ApiResponse<GeoResponse> {
  const url = HOST + `/rest/geo/find-by-task-id`;
  return YodaRestClient.post(url, taskIdList);
}

export async function ApiFindAllPnrTag(taskId: string): ApiResponse<GeoResponse> {
  const url = HOST + `/rest/pnr/tag/${taskId}`;
  return YodaRestClient.get(url);
}

export async function ApiFindAllPnrHermes(): ApiResponse<HermesListItem[]> {
  const url = HOST + `/rest/pnr/hermes/list`;
  return YodaRestClient.get(url);
}

export async function ApiGetPnrHermesTerminal(
  hermesId: string,
  terminalNumber: string,
  houseDataId: string
): ApiResponse<HermesAnswer> {
  const url = HOST + `/rest/pnr/hermes/${hermesId}/discover/${terminalNumber}/${houseDataId}`;
  return YodaRestClient.get(url);
}

export async function ApiGetPnrHermesLog(
  terminalNumber: string,
  houseDataId: string
): ApiResponse<HermesLog> {
  const url = HOST + `/rest/pnr/hermes/${terminalNumber}/${houseDataId}`;
  return YodaRestClient.get(url);
}

export async function ApiDeletePnrHermesLog(hermesSurveyLogId: string): ApiResponse<HermesLog> {
  const url = HOST + `/rest/pnr/hermes/${hermesSurveyLogId}`;
  return YodaRestClient.delete(url);
}

export async function ApiFindAlPnrTransformerStation(geoBounds: GeoBounds): ApiResponse<any> {
  const url = HOST + `/rest/pnr/transformer-station`;
  return YodaRestClient.post(url, geoBounds);
}

export async function ApiFindAllTaskIdAroundTaskByPnrTag(
  taskId: string,
  geoRequest: GeoRequest
): ApiResponse<string[]> {
  const url = HOST + `/rest/pnr/tag/task/${taskId}`;
  return YodaRestClient.post(url, geoRequest);
}

export async function ApiFindAllTaskAttribute(): ApiResponse<any> {
  const url = HOST + '/rest/task/attribute';
  return YodaRestClient.get(url);
}

export async function ApiFindTaskHintByTaskId(id: string): ApiResponse<any> {
  const url = HOST + `/rest/task/hint/${id}`;
  return YodaRestClient.get(url);
}

export async function ApiFindTaskElementByTaskId(id: string): ApiResponse<any> {
  const url = HOST + `/rest/task/element/${id}`;
  return YodaRestClient.get(url);
}

export async function ApiFindTaskHintNetworkEquipmentByTaskId(id: string): ApiResponse<any> {
  const url = HOST + `/rest/network-equipment/hint/${id}`;
  return YodaRestClient.get(url);
}

export async function ApiFindAllPnrInfoByTaskIdList(
  pnrInfoType: string,
  taskIdList: string[]
): ApiResponse<any> {
  const url = HOST + `/rest/pnr/info/${pnrInfoType}`;
  return YodaRestClient.post(url, taskIdList);
}

export async function ApiFindAllUnownedPu(): ApiResponse<any> {
  const url = HOST + `/rest/pnr/unowned-pu`;
  return YodaRestClient.get(url);
}

export async function ApiFindUnAttachmentByFilter(
  filter: string
): ApiResponse<UnAttachmentDtoYoda[]> {
  const url = HOST + '/rest/v2/entities/enerstroymain$UnAttachment/search';
  return YodaRestClient.post(url, filter);
}

export async function GetFileUrlByFileId(id: string): Promise<string> {
  const token = await yodaGetAccessToken();
  return HOST + '/rest/v2/files/' + id + '?access_token=' + token;
}

export async function ApiGetFileById(id: string): ApiResponse<any> {
  const token = await yodaGetAccessToken();
  const url = HOST + '/rest/v2/files/' + id + '?access_token=' + token;
  return YodaRestClient.get(url);
}

export async function ApiGetFileHeadersByUrlS3(url: string): ApiResponse<any> {
  return axios.head(url);
}

export async function ApiFindTasksByAccNum(
  accountNum: string,
  address: string,
  flatNumber: string
): ApiResponse<TaskCC[]> {
  const url =
    HOST +
    `/rest/callcentre/get` +
    '?accountNumber=' +
    accountNum +
    '&address=' +
    address +
    '&apartment=' +
    flatNumber;
  return YodaRestClient.get(url);
}

export async function ApiFindSuggestionsAddress(address: string): ApiResponse<string[]> {
  const url = HOST + `/rest/callcentre/getSuggestionsAddress` + '?address=' + address;
  return YodaRestClient.get(url);
}

export async function ApiFindSuggestionsAddressForPNR(address: string): ApiResponse<string[]> {
  const url = HOST + `/rest/house/address/get-suggestions-address` + '?address=' + address;
  return YodaRestClient.get(url);
}

export async function ApiGetTaskInfoById(taskInfoId: string): ApiResponse<TaskCC> {
  const url = HOST + `/rest/callcentre/getById` + '?taskInfoId=' + taskInfoId;
  return YodaRestClient.get(url);
}

export async function ApiGetTaskTkoByTaskInfoId(taskInfoId: string): ApiResponse<TaskCCTko[]> {
  const url = HOST + `/rest/callcentre/getTkoByTaskInfoId` + '?taskInfoId=' + taskInfoId;
  return YodaRestClient.get(url);
}

export async function ApiGetTkoStatusList(): ApiResponse<TkoStatusDto[]> {
  const url = HOST + `/rest/callcentre/get-task-tko-statuses`;
  return YodaRestClient.get(url);
}

export async function ApiGetCCContracts(): ApiResponse<CCContractDto[]> {
  const url = HOST + `/rest/callcentre/getContracts`;
  return YodaRestClient.get(url);
}

export async function ApiCreateGuarantee(guaranteeBodyDto: GuaranteeBodyDto): ApiResponse<string> {
  const url = HOST + `/rest/callcentre/create-guarantee`;
  return YodaRestClient.post(url, guaranteeBodyDto);
}

export async function ApiSaveCallCenterTko(tkoToSave: TkoToSaveDto): ApiResponse<string> {
  const url = HOST + `/rest/callcentre/save-tko`;
  return YodaRestClient.post(url, tkoToSave);
}

export async function ApiFindGroupByChildName(childName: string): ApiResponse<any> {
  const url = HOST + '/rest/group/parent' + '?childName=' + childName;
  return YodaRestClient.get(url);
}
export async function ApiFindCurrentGroup(): ApiResponse<any> {
  const url = HOST + '/rest/user/current/group';
  return YodaRestClient.get(url);
}

export async function findAllNetworkLocationResponse(
  geoBounds: any,
  toCleanCache: boolean
): ApiResponse<NetworkResponse[]> {
  const url = HOST + `/rest/network-polling/search?toCleanCache=${toCleanCache}`;
  return YodaRestClient.post(url, geoBounds);
}
export async function GetUSPDXlsx(filter: {
  polygon?: null | string;
  networkPollingUspdId?: null | number;
}): ApiResponse<any> {
  const url = HOST + '/rest/network-polling/report';
  return YodaRestClient.post(url, filter, { responseType: 'blob' });
}
export async function updateUSPD(uspdId: number, body: any): ApiResponse<NetworkResponse[]> {
  const url = HOST + `/rest/network-polling/${uspdId}`;
  return YodaRestClient.put(url, body);
}

export async function createNewUSPD(newUSPD: any): ApiResponse<any> {
  const url = HOST + `/rest/network-polling`;
  return YodaRestClient.post(url, newUSPD);
}
export async function deleteUSPD(id: any): ApiResponse<any> {
  const url = HOST + `/rest/network-polling/${id}`;
  return YodaRestClient.delete(url);
}

export async function ApiFindHouseOrders(id: string): ApiResponse<HouseOrderDto[]> {
  const url = HOST + `/rest/house/${id}/orders`;
  return YodaRestClient.get(url);
}

export async function ApiPnrFindAddressDetails(id: string): ApiResponse<PnrAddressDetail> {
  const url = HOST + `/rest/house/address/${id}/main-address`;
  return YodaRestClient.get(url);
}

export async function ApiPnrChangeAddressDetail(
  id: string,
  newDetails: PnrAddressDetail
): ApiResponse<PnrAddressDetail> {
  const url = HOST + `/rest/house/address/${id}/main-address`;
  return YodaRestClient.put(url, newDetails);
}

export async function ApiCreateHouseOrders(
  houseId: string,
  order: CreateHouseOrderDto
): ApiResponse<CreateHouseOrderResponseDto> {
  const url = HOST + `/rest/house/${houseId}/order`;
  return YodaRestClient.post(url, order);
}

export async function ApiFindHousePlannings(id: string): ApiResponse<PlannedInstalationsDto[]> {
  const url = HOST + `/rest/house/${id}/plannings`;
  return YodaRestClient.get(url);
}

export async function ApiFindHouseLayout(id: string): ApiResponse<HouseLayoutDto> {
  const url = HOST + `/rest/house/${id}/layout`;
  return YodaRestClient.get(url);
}

export async function ApiFindHouseTwoGisSheme(id: string): ApiResponse<TwoGisLayoutResponseDto> {
  const url = HOST + `/rest/house/${id}/two-gis-schema`;
  return YodaRestClient.post(url, {});
}

export async function ApiUpdateHouseLayout(
  id: string,
  layout: HouseLayoutDto
): ApiResponse<HouseLayoutDto> {
  const url = HOST + `/rest/house/${id}/layout`;
  return YodaRestClient.put(url, layout);
}

export async function ApiResetHouseLayout(id: string): ApiResponse<HouseLayoutDto> {
  const url = HOST + `/rest/house/${id}/layout`;
  return YodaRestClient.delete(url);
}

export async function ApiUpdateHouseEntranceEnumeration(
  id: string,
  startingNumber: string
): ApiResponse<HouseLayoutDto> {
  const url = HOST + `/rest/house/${id}/layout/enumeration?startingNumber=${startingNumber}`;
  return YodaRestClient.put(url, {});
}

export async function ApiFormHousePlannings(id: string): ApiResponse<PlannedInstalationsDto[]> {
  const url = HOST + `/rest/house/${id}/blueprint/primary`;
  return YodaRestClient.post(url, {});
}

export async function ApiFormHousePrePlannings(id: string): ApiResponse<PlannedInstalationsDto[]> {
  const url = HOST + `/rest/house/${id}/blueprint/secondary`;
  return YodaRestClient.post(url, {});
}

export async function ApiSaveHousePlannings(
  houseId: string,
  plannings: SaveHousePlanningsDto
): ApiResponse<PlannedInstalationsDto[]> {
  const url = HOST + `/rest/house/${houseId}/plannings`;
  return YodaRestClient.post(url, plannings);
}

export async function ApiDeleteHousePlannings(
  id: string,
  secondary?: boolean
): ApiResponse<PlannedInstalationsDto[]> {
  const url = HOST + `/rest/house/${id}/blueprint${secondary ? '/secondary' : '/primary'}`;
  return YodaRestClient.delete(url);
}

export async function ApiSaveAddedNetworkOS(
  networkElementOS: NetworkElementsTaskWithComment
): ApiResponse<string> {
  const url = HOST + `/rest/task/network-elements-with-comments`;
  return YodaRestClient.post(url, networkElementOS);
}

export async function ApiUpdateAddressDetail(
  taskAddressDetail: TaskAddressDetail
): ApiResponse<TaskAddressDetail> {
  const url = HOST + `/rest/address-table/detail-address`;
  return YodaRestClient.put(url, taskAddressDetail);
}

export async function ApiMergeAddresses(
  houseIdsForMerge: string[],
  houseId: string
): ApiResponse<TaskAddressDetail[]> {
  const url = HOST + `/rest/house/address/${houseId}/merge`;
  return YodaRestClient.post(url, houseIdsForMerge);
}

export async function ApiRefreshAddressDetail(
  taskAddressDetail: TaskAddressDetail
): ApiResponse<TaskAddressDetail> {
  const url = HOST + `/rest/address-table/refresh-address`;
  return YodaRestClient.post(url, taskAddressDetail);
}

export async function ApiGetAddressDetailByTaskId(taskId: string): ApiResponse<TaskAddressDetail> {
  const url = HOST + `/rest/address-table/detail-address/${taskId}`;
  return YodaRestClient.get(url);
}

export async function ApiGetAddressDetailCoordsByTaskId(
  taskId: string
): ApiResponse<DadataResponseDto> {
  const url = HOST + `/rest/address-table/detail-address-coord/${taskId}`;
  return YodaRestClient.get(url);
}

export async function ApiGetOdpuManagementCompanyById(
  id: string
): ApiResponse<OdpuManagementCompanyResponse> {
  const url = HOST + `/rest/odpu-management-company/${id}`;
  return YodaRestClient.get(url);
}

export async function DownloadHouseDataReport(houseDataId: string): ApiResponse<ArrayBuffer> {
  const url = HOST + `/rest/report/house_data/${houseDataId}/installed_equipments`;
  return YodaRestClient.get(url, { headers: CONTENT_TYPE.EXL, responseType: 'blob' });
}

export async function GetCurrentUser(): ApiResponse<UserResponse> {
  const url = HOST + '/rest/user/current';
  return YodaRestClient.get(url);
}
export async function GetScheduleDate(body: any): ApiResponse<any> {
  const url = HOST + '/rest/task/customer-schedule-date';
  return YodaRestClient.post(url, body);
}

export async function DownloadStatisticDistributionExel(
  body: FilterStatisticDistribution
): ApiResponse<ArrayBuffer> {
  const url = HOST + `/rest/statistics/distribution/contract/month/report`;
  return YodaRestClient.post(url, JSON.stringify(body), { responseType: 'blob' });
}
export async function DownloadStatisticUrgentAndSuperUrgentExel(
  body: StatisticUrgentAndSuperUrgentTasksDto[]
): ApiResponse<ArrayBuffer> {
  const url = HOST + `/rest/statistics/task-urgent-excel`;
  return YodaRestClient.post(url, JSON.stringify(body), { responseType: 'blob' });
}

export async function GetCallingMapOperator(): ApiResponse<CallingMapOperatorCredentials> {
  const url = HOST + '/rest/installation-call/operator';
  return YodaRestClient.get(url);
}

export async function GetJWT(): ApiResponse<string> {
  const url = HOST + '/rest/jwt';
  return YodaRestClient.get(url);
}

export async function GetTaskByMeter(
  data: TaskByMeterRequestDTO[]
): ApiResponse<TaskByMeterResponseDTO> {
  const url = HOST + `/rest/storehouse/tasks/old-meters`;

  return YodaRestClient.post(url, data);
}

export const ApiGetHouseByTaskId = async (
  taskId: string
): ApiResponse<TaskHouseLinkResponseDto> => {
  const url = HOST + `/rest/house/task/${taskId}`;
  return YodaRestClient.get(url);
};

export const ApiSetTaskHouseLink = async (
  houseId: string,
  taskId: string
): ApiResponse<TaskHouseLinkResponseDto> => {
  const url = HOST + `/rest/house/${houseId}/task/${taskId}/link`;
  return YodaRestClient.post(url, {});
};
