import { color } from '@/styles/mixins';
import { IconButton, styled } from '@mui/material';
import Popper from '@mui/material/Popper';

export const MiniatureSelectActiveWrapper = styled('div')({
  position: 'relative',
});

export const MiniatureSelectWrapper = styled('div')({
  display: 'flex',
  flexDirection: 'column',
  gap: '40px',
  paddingBottom: '40px',
});

export const MiniatureCloseButton = styled(IconButton)({
  color: color('trueBlack'),
  position: 'absolute',
  top: 0,
  right: 0,
  margin: '10px',
});

export const MiniatureSelectList = styled('div')(({ isMobile }: { isMobile: boolean }) => ({
  display: 'flex',
  flexWrap: 'wrap',
  flexDirection: isMobile ? 'column' : 'row',
  justifyContent: isMobile ? 'center' : 'space-around',
  alignItems: isMobile ? 'center' : 'inherit',
  gap: '60px',
}));

export const MiniatureBox = styled('div')(({ isMobile }: { isMobile: boolean }) => ({
  width: isMobile ? '100%' : '30%',
  height: isMobile ? '150px' : '200px',
  border: `1px solid ${color('gray')}`,
  overflow: 'hidden',
  cursor: 'pointer',
  transition: '0.4s',
  opacity: 0.9,
  '&:hover': {
    opacity: 1,
    transform: 'scale(1.05)',
  },
}));

export const MiniatureWrapper = styled('div')`
  transform: scale(0.35);
  width: 300%;
  transform-origin: 0 0;
  pointer-events: none;
  p.MainTextStatistc {
    font-size: 38px;
  }
`;

export const MiniaturePopover = styled(Popper)({
  pointerEvents: 'none',
  transformOrigin: 'top center',
});

export const MiniaturePopoverBox = styled('div')({
  padding: '10px',
});
