import React from 'react';
import { Placemark, withYMaps } from 'react-yandex-maps';
import { getHint } from './Hint';
import { pillarLayout } from './Balloon';
import { PillarMapResponse } from '@/services/PillarMapService/dto/PillarMapDtoResponse';
import { usePillarMapState } from '@/components/maps/pillarMap/store';
import shallow from 'zustand/shallow';

interface PillarPlacemarkProps {
  geoObject: PillarMapResponse;
}

export const PillarPlacemark = (props: PillarPlacemarkProps) => {
  const { geoObject } = props;
  const { setCheckedPoints, setActivePoint } = usePillarMapState(
    (state) => ({
      setActivePoint: state.setActivePoint,
      setCheckedPoints: state.setCheckedPoints,
    }),
    shallow
  );

  const PlacemarkCore = React.memo(function Y({ ymaps }: any) {
    return (
      <Placemark
        geometry={[geoObject.longitudeX, geoObject.latitudeY]}
        options={pillarLayout({
          ymaps: ymaps,
          id: geoObject.id,
          status: geoObject.status,
        })}
        properties={{
          point: geoObject,
          balloonContentBody: getHint(geoObject),
        }}
        instanceRef={(ref: any) => {
          ref &&
            ref.events.add('balloonopen', (e: any) => {
              setActivePoint(e.originalEvent.currentTarget.properties._data.point);
            });
          // ref &&
          //   ref.events.add('balloonclose', (e: any) => {
          //     setActivePoint(undefined);
          //   });
        }}
      />
    );
  });

  const PillarPlacemark = React.useMemo(() => {
    return withYMaps(PlacemarkCore, true, ['geoObject.addon.balloon', 'templateLayoutFactory']);
  }, [PlacemarkCore]);

  return <PillarPlacemark />;
};
