import React from 'react';
import { Table, TableBody, TableContainer, TableHead, TableRow } from '@mui/material';

import { getDataLines, getFirstHeaderLine, getSecondHeaderLine } from './utils';
import './CallCenterStatisticsStyle.css';
import { CallCenterStatisticsDto } from '@/services/StatisticsService/CustomerInteractionStatisticsService/dto/CustomerInteractionStatisticsServiceResponseDto';
import { CallCenterStatisticsFilter } from '@/services/StatisticsService/CustomerInteractionStatisticsService/dto/CustomerInteractionStatisticsServiceRequestDto';
import { useMobileSize } from '@/hooks/useMediaQuery/useMobileSize';

export interface CallCenterStatisticsTableProps {
  data: CallCenterStatisticsDto[];
  showOnlyTotals?: boolean;
  setFilter?: (filter: CallCenterStatisticsFilter) => void;
  isReadyToDownload?: boolean;
  setIsReadyToDownload?: (flag: boolean) => void;
  filter?: CallCenterStatisticsFilter;
}
const CallCenterStatisticsTable = ({
  data,
  showOnlyTotals,
  setFilter,
  setIsReadyToDownload,
  filter,
}: CallCenterStatisticsTableProps) => {
  const isMobile = useMobileSize();
  return (
    <TableContainer
      className={'rootTableContainer'}
      style={{ overflowX: 'auto', maxWidth: '90%' }}
    >
      <Table
        stickyHeader
        aria-label='sticky table'
      >
        <TableHead>
          <TableRow key={new Date().getDate() + '' + Math.random()}>
            {getFirstHeaderLine(data)}
          </TableRow>
          <TableRow key={new Date().getDate() + '' + Math.random()}>
            {getSecondHeaderLine(data, isMobile)}
          </TableRow>
        </TableHead>
        <TableBody>
          {data &&
            getDataLines({
              data,
              showOnlyTotals: !!showOnlyTotals,
              setFilter,
              setIsReadyToDownload,
              filter,
              isMobile,
            })}
        </TableBody>
      </Table>
    </TableContainer>
  );
};

export default CallCenterStatisticsTable;
